import React from 'react';
import { DateRowStartEnd, PriceRow, LinkRow, ApptFlags, SimpleTooltip, DisplayIdRow, DateRow, Aging, DownloadLink, TextRow, } from 'components';
import { Checkbox } from '@material-ui/core';
import { Images, PermissionList, RolePermission } from 'utils';
import { AgingRow } from '../../../components/table/tableRows';
import { NumericFormat } from 'react-number-format';

const label = { inputProps: { 'aria-label': 'Color switch demo' } };

export const pendingClaimHead = (handleSelect, fullList, selected) => {
    return [
        RolePermission([PermissionList.CLAIMS_UPDATE?.code]) &&
        {
            renderView: <SimpleTooltip
                title={<p>Select All</p>}
                placement="top-start"
            >
                <Checkbox
                    {...label}
                    onClick={(e) => handleSelect(e, '', 'all')}
                    name={'checkBox'}
                    style={{ padding: 0, color: '#347AF0' }}
                    checked={fullList?.length === selected?.length}
                />
            </SimpleTooltip>,
            width: '50px',
        },
        { name: 'displayId', searchKey: 'DISPLAY_ID', title: 'Claim ID', custom: false, width: '220px' },
        { name: 'dateRange', title: 'DOS', icon: 'date', disabled: true, custom: false, width: '200px' },
        { name: 'funder', title: 'Funding Source' },
        { name: 'client', title: 'Client Code' },
        { name: 'totalAmount', title: 'Billed Amount', custom: false },
    ];
};

export const pendingClaimBody = (handleSelect, fullList, selected) => {
    return [
        RolePermission([PermissionList.CLAIMS_UPDATE?.code]) &&
        {
            button: (handle, item) => (
                <SimpleTooltip
                    title={<p>Select</p>}
                    placement="top-start"
                >
                    <Checkbox
                        {...label}
                        onClick={(e) => handleSelect(e, item)}
                        disabled={item?.incompleteUnitFlag}
                        // disabled={item?.incompleteUnitFlag || item?.updateFlag}
                        name={'checkBox'}
                        style={{
                            padding: 0,
                            color: '#347AF0',
                            opacity: item?.incompleteUnitFlag ? 0.5 : 1,
                            // opacity: item?.incompleteUnitFlag || item?.updateFlag ? 0.5 : 1,
                        }}
                        checked={selected?.includes(item?.id)}
                    />
                </SimpleTooltip>
            ), notClickable: true,
        },
        {
            rowText: (item) =>
                <ApptFlags
                    id={item?.displayId}
                    updateFlag={item?.updateFlag}
                    incomplete={item?.incompleteUnitFlag}
                    removed={item?.removedFromClaimFlag}
                />,
        },
        { rowText: (item) => <DateRowStartEnd start={item?.dos?.early} end={item?.dos?.latest} /> },
        {
            rowText: (item) => <LinkRow
                name={item?.funderName ? item.funderName : 'N/A'}
                href={RolePermission([PermissionList.FS_READ.code]) ? `/fundingSource/${item?.funderId}` : ''}
                checkPermission={item?.funderId ? RolePermission([PermissionList.FS_READ.code]) : null}
            />,
        },
        {
            rowText: (item) => <LinkRow
                name={item?.clientCode ? item?.clientCode : 'N/A'}
                href={RolePermission([PermissionList.CLIENT_READ?.code]) ? `/client/${item?.clientId}` : ''}
                checkPermission={item?.clientCode ? RolePermission([PermissionList.CLIENT_READ?.code]) : null}
            />, notClickable: true,
        },
        { rowText: (item) => <PriceRow info={item?.billedAmount ? item?.billedAmount : 0} /> },
    ];
};

export const ACTION_TYPE = 'GET_CLAIMS';

export const pendingClaimNotYet = {
    title: 'No Pending Claims',
    subTitle: 'There are no claims waiting for submission or processing at this time.',
    image: Images.noPendingClaim,
};

export const billedClaimNotYet = {
    title: 'No Billed Claims',
    subTitle: 'There are no claims that have been billed. Once claims are submitted, they will be listed here.',
    image: Images.noPendingClaim,
};

export const claimTabEnums = {
    BILLABLE: 'BILLABLE',
    PENDING: 'PENDING',
    SUBMITTED: 'SUBMITTED',
};
export const claimTabTypes = [
    { title: 'Billable Appt', tabValue: claimTabEnums.BILLABLE },
    { title: 'Pending Claims', tabValue: claimTabEnums.PENDING },
    { title: 'Submitted Claims', tabValue: claimTabEnums.SUBMITTED },
];


export const submittedByClaimHead =
    [
        { name: 'displayId', searchKey: 'DISPLAY_ID', title: 'Claim ID', custom: false },
        { name: 'dateRange', title: 'DOS', iconType: 'date', disabled: true, width: '200px' },
        { name: '', title: 'Sub. Date', disabled: true, custom: false, width: '100px' },
        // { name: '', title: 'Submitted Date', disabled: true, custom: false,  width: '100px'  },
        { name: 'funder', rowName: 'name', title: 'Funding Source', disabled: true },
        { name: 'client', rowName: 'code', title: 'Client Code', disabled: true, width: '140px' },
        { name: '', title: 'Billed AMT', custom: false },
        { name: '', title: 'Allowed AMT', custom: false },
        { name: '', title: 'Paid AMT', custom: false },
        {
            name: 'aging', title: 'Aging', width: '100px', iconType: 'date',
            // filterList: agingEnumsSelector,
        },
    ];

export const submittedByClaimBody = [
    { rowText: (item) => <DisplayIdRow id={item?.displayId} /> },
    { rowText: (item) => <DateRowStartEnd start={item?.dos?.early} end={item?.dos?.latest} /> },
    { rowText: (item) => <DateRow date={item?.submittedDate} /> },
    {
        rowText: (item) => <LinkRow
            name={item?.funderName ? item.funderName : 'N/A'}
            href={RolePermission([PermissionList.FS_READ.code]) ? `/fundingSource/${item?.funderId}` : ''}
            checkPermission={item?.funderId ? RolePermission([PermissionList.FS_READ.code]) : null}
        />,
    },
    {
        rowText: (item) => <LinkRow
            name={item?.clientCode ? item?.clientCode : 'N/A'}
            href={RolePermission([PermissionList.CLIENT_READ?.code]) ? `/client/${item?.clientId}` : ''}
            checkPermission={item?.clientCode ? RolePermission([PermissionList.CLIENT_READ?.code]) : null}
        />, notClickable: true,
    },
    { rowText: (item) => <PriceRow info={item?.billedAmount ? item?.billedAmount : 0} /> },
    { rowText: (item) => <PriceRow info={item?.allowedAmount ? item?.allowedAmount : 0} /> },
    { rowText: (item) => <PriceRow info={item?.paidAmount ? item?.paidAmount : 0} /> },

    { rowText: (item) => <AgingRow count={item?.aging} />, padding: '0' },
    // { rowText: (item) => <div>{item?.aging ? item?.aging  : 0}</div>, background: AgingRowBackground(item?.aging) },
];


export const submittedHeader = [
    { title: 'Batch ID', name: 'displayId', searchKey: 'DISPLAY_ID', custom: false, width: '15%' },
    { title: 'Submitted Date', name: 'submittedDate', custom: false, disabled: true, width: '20%' },
    { title: 'Submitted By', name: 'submittedByFullName', disabled: true, custom: false, width: '25%' },
    { title: 'DOS', name: 'dos', custom: false, disabled: true, width: '20%' },
    { title: 'Claims Form', name: 'claimsFormsFile', disabled: true, custom: false, width: '20%' },
];


export const generateClaimHead = (handleSelect, fullList, selected) => {
    return [
        {
            renderView: <SimpleTooltip
                title={<p>Select All</p>}
                placement="top-start"
            >
                <Checkbox
                    onClick={(e) => handleSelect(e, '', 'all')}
                    name={'checkBox'}
                    style={{ padding: 0, color: '#0C66E4' }}
                    checked={fullList?.length === selected?.length}
                />
            </SimpleTooltip>, width: '30px',
        },
        { name: '', title: 'Appt ID',  custom: false },
        { name: '', title: 'CPT Code', width: '100px',  custom: false },
        { name: '', title: 'DOS', icon: 'date',  custom: false },
        { name: '', title: 'Place of Service', custom: false },
        { name: '', title: 'Funding Source',  custom: false },
        { name: 'client', title: 'Client',  },
        { name: '', title: 'Staff', custom: false },
        { name: '', title: 'Units',  custom: false },
        { name: '', title: 'Signature',  custom: false },
        RolePermission([PermissionList.CLAIMS_UPDATE?.code]) &&
        { name: '', title: 'Action',  custom: false, width: '80px' },
    ];
};

function convertToHoursAndMinutes(decimalHours) {
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 60);
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    return `${formattedHours}h ${formattedMinutes}m`;
}

export const generateClaimBody = (handleSelect, fullList, selected, handleEdit) => {
    return [
        {
            button: (handle, item) => (
                <SimpleTooltip
                    title={<p>Select</p>}
                    placement="top-start"
                >
                    <Checkbox
                        onClick={(e) => handleSelect(e, item)}
                        name={'checkBox'}
                        style={{ padding: 0, color: '#0C66E4' }}
                        checked={selected?.includes(item?.id)}
                    />
                </SimpleTooltip>
            ), notClickable: true,
        },
        {
            rowText: (item) => <ApptFlags
                id={item?.displayId}
                fromAppt={true}
                incomplete={item?.incompleteUnitFlag}
                removed={item?.removedFromClaimFlag}
            />,
        },
        {
            rowText: (item) => <TextRow
                name={item?.authorizedService?.service?.cptCode ? item?.authorizedService?.service?.cptCode : 'N/A'} />,
        },
        { rowText: (item) => <div><DateRow date={item?.startDate} /></div> },
        { rowText: (item) => <TextRow name={item?.placeService?.name} /> },
        { rowText: (item) => <TextRow name={item?.payer?.name} /> },
        {
            rowText: (item) => <LinkRow
                client={item?.client}
                href={
                    RolePermission([PermissionList.CLIENT_READ?.code]) ?
                        `/client/${item?.client?.id || item?.client?._id}` : ''}
                checkPermission={item?.client ? RolePermission([PermissionList.CLIENT_READ?.code]) : null}
                textWidth={8}
            />,
        },
        {
            rowText: (item) => <LinkRow
                staff={item?.staff}
                href={RolePermission([PermissionList.STAFF_READ.code]) ? `/staff/${item?.staff?._id}` : ''}
                checkPermission={item?.staff?._id ? RolePermission([PermissionList.STAFF_READ.code]) : null}
            />,
        },
        {
            rowText: (item) => <div className="flex-align-center">
                <NumericFormat value={item?.units ? item?.units.toFixed(2) : 0} displayType={'text'}
                               thousandSeparator={true} />
                {item?.hours && <p style={{
                    whiteSpace: 'nowrap',
                    marginLeft: '4px',
                }}>({convertToHoursAndMinutes(item?.hours)})</p>}
            </div>,
        },
        {
            rowText: (item) => item?.signature?.url ?
                <div style={{ gap: 8 }} className="flex-align-center">
                    <div>
                        <img
                            style={{ objectFit: 'cover', width: 50, height: 28, margin: '-2px 0 -8px 0' }}
                            src={item?.signature?.url} alt="icon" />
                    </div>
                    <div style={{ width: 22, margin: '2px 0 -3px 0' }}>
                        <DownloadLink
                            linkClassName={'reset-download-btn'}
                            downloadLoadStyle={{ margin: 0 }}
                            iconStyles={{ marginLeft: 0 }}
                            small={true}
                            file={item?.signature}
                        />
                    </div>
                    <a href={item?.signature?.url} target={'_blank'} style={{ marginTop: 2 }}>
                        <img src={Images.show} alt={'download'} />
                    </a>
                </div>
                :
                <p>N/A</p>
            , padding: '0',
        },
        RolePermission([PermissionList.CLAIMS_UPDATE?.code]) &&
        {
            rowText: (item) => (
                <button onClick={() => handleEdit(item)} className="reset-btn" style={{ height: '20px' }}>
                    <img src={Images.edit} alt={'edit'} />
                </button>
            ),
        },
    ];
};

export const BILLABLE_ACTION_TYPE = 'GET_APPOINTMENT';