import {makeStyles} from "@material-ui/core/styles";


export const reportsStyle = makeStyles(() => ({

    reportTableWrapper: {
        background: '#FFFFFF',
        width: '100%',
        height: '100%',

    },
    reportFor: {
        fontWeight: '700',
        fontSize: '18px',
        lineHeight: '25px',
        color: '#4B5C68',
    },
    reportForWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    filterSection: {
        margin: '24px 0',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        width: '100%',
    },
    dateInput: {
        background: '#FFFFFF',
        border: '1px solid #347AF0',
        borderRadius: '4px',
        width: '140px',
        height: '37px',
        margin: '0 24px',
        padding: '0 12px',
        marginBottom: '1px'
    },
    searchButton: {
        background: '#347AF0',
        border: 'none',
        borderRadius: '8px',
        width: '101px',
        height: '37px',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#FFFFFF',
    },
    flexAble: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    downloadPdf: {
        width: '174px',
        height: '37px',
        background: '#FFFFFF',
        border: '1px solid rgba(163, 178, 189, 0.5)',
        borderRadius: '4px',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#172B4D',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
            marginRight: '8px',
        },
    },
    filterDropStyle: {
        width: "250px",
        "&.smallSize": {
            "@media(max-width: 1720px)": {
                width: "250px",
            },
        },
        "& h6:before, & li:before": {content: "unset"},
    },

    dateStyleWrapper:{
        display:'flex',
        alignItems:'center',
        marginLeft:'24px',
    },

    dateStyle: {
        fontWeight: '700',
        fontSize: '18px',
        lineHeight: '24px',
        color: '#347AF0',
        marginRight:'18px',
    }

}))