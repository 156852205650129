import {
    CREATE_AVAILABILITY_SCHEDULE_GLOBAL,
    GET_AVAILABILITY_SCHEDULE_GLOBAL, GET_CLIENT_SCHEDULE,
    GET_STAFF_SCHEDULE, REMOVE_CLIENT_SCHEDULE, REMOVE_STAFF_SCHEDULE,
} from './availabilitySchedule.type';

export const getAvailabilitySchedule = (id, onModel) => {
    return {
        type: GET_AVAILABILITY_SCHEDULE_GLOBAL,
        payload: { id, onModel }
    }
}


export const createAvailabilitySchedule = (data, id, onModel) => {
    return {
        type: CREATE_AVAILABILITY_SCHEDULE_GLOBAL,
        payload: { data, id, onModel }
    }
}

export const getStaffSchedule = (id) => {
    return {
        type: GET_STAFF_SCHEDULE,
        payload: { id }
    }
}
export const removeStaffSchedule = () => {
    return {
        type: REMOVE_STAFF_SCHEDULE,
    }
}

export const getClientSchedule = (id) => {
    return {
        type: GET_CLIENT_SCHEDULE,
        payload: { id }
    }
}

export const removeClientSchedule = () => {
    return {
        type: REMOVE_CLIENT_SCHEDULE,
    }
}
