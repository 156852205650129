import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { myProfileFragment } from './style';
import {
    PasswordValidator,
    ErrorText,
    Images,
    isNotEmpty,
    FindSuccess,
    FindLoad,
    FindErrorItem,
} from 'utils';
import { CheckPassword, MinLoader, PasswordInput } from 'components';
import { authActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import { checkPassword } from '../../login/core/constants';

const ACTION_TYPE = 'CHANGE_PASSWORD_REQUEST';
export const ChangePassword = ({}) => {
    const classes = myProfileFragment();
    const dispatch = useDispatch();
    const [error, setError] = useState('');
    const [showInputs, setShowInputs] = useState(false);
    const [inputs, setInputs] = useState({
        password: '',
        newPassword: '',
        confirmation: '',
    });
    const successType = FindSuccess(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const beckError = FindErrorItem(ACTION_TYPE);

    useEffect(() => {
        if (!!successType.length) {
            setShowInputs(false);
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [successType]);

    const handleChange = (e) => {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        error === e.target.name && setError(''),
        error === 'confirmationNotEqual' && setError('');
        if (e.target.name === 'accountPassword' && beckError?.error === 'user password does not match') {
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        }
    };

    const handleChangePassword = () => {
        const changePassDataIsValid =
            isNotEmpty(inputs?.password) &&
            isNotEmpty(inputs?.newPassword) &&
            isNotEmpty(inputs?.confirmation);

        if (changePassDataIsValid && !error) {
            const data = {
                password: inputs.password,
                newPassword: inputs.newPassword,
                confirmation: inputs.confirmation,
            };
            if (inputs?.newPassword === inputs?.confirmation) {
                dispatch(authActions.changePassword(data));
            } else {
                setError('confirmationNotEqual');
            }
        } else {

            if (!isNotEmpty(inputs.password)) {
                setError('password');
            } else if (!isNotEmpty(inputs.newPassword)) {
                setError('newPassword');
            } else if (!isNotEmpty(inputs.confirmation)) {
                setError('confirmation');
            }
        }
    };
    const handleCheck = (bool, name) => {
        if (bool === true) {
            setError(name);
        } else {
            setError('');
        }
    };

    const handleChancel = () => {
        setShowInputs(false);
        setError('');
        setInputs({
            password: '',
            newPassword: '',
            confirmation: '',
        });
        dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
    };

    return (
        <div className={classes.infoBoxWrapper}>
            <div className={classes.changePasswordContent}>
                <p className={classes.infoBoxTitle}> Change Password </p>
                {!showInputs &&
                    <div onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setShowInputs(true)
                    }}>
                        <img className={classes.editBlack} src={Images.edit} alt="edit" />
                    </div>
                }
            </div>
            {showInputs && (
                <div>
                    <div style={{ marginTop: '24px' }}>
                        <PasswordInput
                            handleChangePassword={handleChange}
                            type={'password'}
                            name={'password'}
                            disabled={false}
                            variant={'accountPassword'}
                            value={inputs.password}
                            onChange={handleChange}
                            typeError={
                                error === 'password' ? 'Password is required.' :
                                    beckError?.error === 'user password does not match' ? 'User password does not match' : ''
                            }
                            placeholder={'Current Password*'}
                        />
                        <PasswordInput
                            validator={PasswordValidator}
                            sendBoolean={(bool) => handleCheck(bool, 'newPasswordNotValid')}
                            handleChangePassword={handleChange}
                            type={'password'}
                            name={'newPassword'}
                            disabled={false}
                            variant={'accountPassword'}
                            value={inputs.newPassword}
                            onChange={handleChange}
                            typeError={
                                error === 'newPasswordNotValid' ? ErrorText.passwordValid
                                    : error === 'newPassword' && 'New password is required.'
                            }
                            placeholder={'New Password*'}
                        />
                        <PasswordInput
                            validator={PasswordValidator}
                            sendBoolean={(bool) => handleCheck(bool, 'confirmationNotValid')}
                            handleChangePassword={handleChange}
                            type={'password'}
                            name={'confirmation'}
                            disabled={false}
                            variant={'accountPassword'}
                            value={inputs.confirmation}
                            onChange={handleChange}
                            typeError={
                                error === 'confirmationNotEqual' ? 'Passwords do not match.' :
                                    error === 'confirmationNotValid' ? ErrorText.passwordValid :
                                        error === 'confirmation' && 'Password confirmation is required.'
                            }
                            placeholder={'Retype New Password*'}
                        />
                        <CheckPassword
                            fields={checkPassword}
                            text={inputs.newPassword}
                            isActive={inputs.newPassword}
                        />
                    </div>

                    <div className={classes.actionsBox} style={{ marginTop: '12px' }}>
                        <button
                            type={'button'}
                            className={classes.cancelButton}
                            onClick={handleChancel}
                        >
                            Cancel
                        </button>
                        <button
                            type={'button'}
                            className={classes.saveButton} onClick={handleChangePassword}>
                            {!!loader.length ? (
                                <MinLoader margin={'0'} color={'white'} position={'relative'} />
                            ) : (
                                'Save'
                            )}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};
