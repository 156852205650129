export const payCodeBtn = {
   width: "100%",
   height: "48px",
};

export const applyOvertimeData = [
   { label: "No", value: "No" },
   { label: "Yes", value: "Yes" },
];

export const ptoData = [
   { label: "No", value: "No" },
   { label: "Yes", value: "Yes" },
];

export const checkboxStyle = {
   display: "flex",
   alignItems: "center",
   flexDirection: "row",
};
