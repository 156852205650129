import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SimpleTabs, Notes, FilesComponent } from 'components';
import { FundingSourceServices, FundingSourceSingleGeneral, FundingSourceSingleHeader } from './core';
import { PermissionList, RolePermission } from 'utils';
import { HistoryTab } from '../../history';
import { BillingSettings } from './core/billingSettings';

const tabsLabels = [
    RolePermission([PermissionList.FS_READ?.code, PermissionList.FS_CREATE?.code, PermissionList.FS_STATUS?.code]) &&
    { label: 'General' },
    RolePermission([PermissionList.FS_SERVICE_READ?.code, PermissionList.FS_SERVICE_CREATE?.code]) &&
    { label: 'Services' },
    RolePermission([PermissionList.FS_CREATE?.code])  &&
    { label: 'Billing Settings' },
    RolePermission([PermissionList.FS_NOTE_READ?.code, PermissionList.FS_NOTE_MANAGE?.code]) &&
    { label: 'Notes' },
    RolePermission([PermissionList.FS_HISTORY_READ?.code]) &&
    { label: 'History' },
    RolePermission([PermissionList.FS_FILE_READ?.code, PermissionList.FS_FILE_MANAGE?.code]) &&
    { label: 'Files' },
];

export const FundingSourceItem = ({}) => {
    const params = useParams();
    const fsDocuments = useSelector((state) => state.fundingSource.fsDocuments);
    const data = useSelector((state) => state.fundingSource.fundingSourceItem);
    const servicesData = useSelector((state) => state.fundingSource.fundingSourceServices);

    const tabsContent = [
        RolePermission([PermissionList.FS_READ?.code, PermissionList.FS_CREATE?.code, PermissionList.FS_STATUS?.code]) &&
        {
            tabComponent: (
                <FundingSourceSingleGeneral data={data} />
            ),
            tab: 'General',
        },
        RolePermission([PermissionList.FS_SERVICE_READ?.code, PermissionList.FS_SERVICE_CREATE?.code]) &&
        {
            tabComponent: (
                <FundingSourceServices
                    services={servicesData}
                />
            ),
            tab: 'Services',
        },
        RolePermission([PermissionList.FS_CREATE?.code])  &&
        {
            tabComponent: (
                <BillingSettings />
            ),
            tab: 'Billing Settings',
        },
        RolePermission([PermissionList.FS_NOTE_READ?.code, PermissionList.FS_NOTE_MANAGE?.code]) &&
        {
            tabComponent: (
                <Notes
                    id={data?.id}
                    onModel={'Funder'}
                    permissions={[PermissionList.FS_NOTE_MANAGE?.code]}
                />
            ),
            tab: 'Notes',
        },
        RolePermission([PermissionList.FS_HISTORY_READ?.code]) &&
        {
            tabComponent: <HistoryTab currentId={params?.id} onModel={'Funder'} />,
            tab: 'History',
        },
        RolePermission([PermissionList.FS_FILE_READ?.code, PermissionList.FS_FILE_MANAGE?.code]) &&
        {
            tabComponent: (
                <FilesComponent
                    currentId={params?.id}
                    onResource={'fs'}
                    documents={fsDocuments}
                    loadTable={'GET_FS_DOCUMENT'}
                    permissions={[PermissionList.FS_FILE_MANAGE?.code]}
                />
            ),
            tab: 'Files',
        },
    ];

    return (
        <div className="tab-box-wrapper">
            <FundingSourceSingleHeader />
            <SimpleTabs
                defaultTab={
                    RolePermission([PermissionList.FS_READ?.code, PermissionList.FS_CREATE?.code, PermissionList.FS_STATUS?.code]) ?
                        'General'
                        :
                        ''
                }
                tabsLabels={tabsLabels}
                tabsContent={tabsContent}
            />
        </div>
    );
};
