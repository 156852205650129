/**Get Global Notes */
export const GET_GLOBAL_NOTES = 'GET_GLOBAL_NOTES';
export const GET_GLOBAL_NOTES_SUCCESS = 'GET_GLOBAL_NOTES_SUCCESS';

/**create Global Notes */
export const CREATE_GLOBAL_NOTE = 'CREATE_GLOBAL_NOTE';
export const CREATE_GLOBAL_NOTES_SUCCESS = 'CREATE_GLOBAL_NOTES_SUCCESS';

/**edit Global Notes */
export const EDIT_GLOBAL_NOTE = 'EDIT_GLOBAL_NOTE';

/**delete Global Notes */
export const DELETE_GLOBAL_NOTE = 'DELETE_GLOBAL_NOTE';