import { AddButton, LinkRow, MinLoader, PriceRow, SimpleTooltip, TextRow, TypeRow } from 'components';
import {
    ActiveInactiveStatusReverse, AntSwitch, Colors,
    Images,
    makeCapitalize,
    manageType, PermissionList, RolePermission,
} from 'utils';
import React from 'react';

export function getFundingSourceGeneralInfo(info) {
    return [
        { title: 'Name', value: makeCapitalize(info?.name) },
        { title: 'Email Address', value: info?.email },
        {
            title: 'Phone Number',
            value: info?.phoneNumber ? '(' + info?.phoneNumber.substr(0, 3) + ') ' + info?.phoneNumber.substr(3, 3) + '-' + info?.phoneNumber.substr(6, 6) : '',
        },
        { title: 'Status', value: ActiveInactiveStatusReverse(info?.status) },
    ].filter((item) => !!item.value);
}

export function getFundingSourceContactInfo(info) {
    return [
        { title: 'Type', value: manageType(info?.type) },
        { title: 'Contact Title', value: info?.contactTitle },
        { title: 'Contact Email', value: info?.contactEmail },
        { title: 'Contact Person', value: info?.contact },
        { title: 'Payor ID', value: info?.payorId },
        { title: 'Vendor ID', value: info?.vendor },
        { title: 'Website', value:  info?.website  ? info?.website :  null, type: 'link' },

    ].filter((item) => !!item.value);
}
export function getFundingSourceClients(info) {
    const fullData = [{type :'Total Clients', count: info?.totalCount === 0 ? '0' : info?.totalCount }, ...info?.clients, ]
    const updatedData = fullData?.map(item => ({
        title: item.type === 'FBA' ? 'FBA' : makeCapitalize(item.type),
        value: item.count,
    }));

    return updatedData.filter((item) => !!item.value);
}

export function getFundingSourceAddressInfo(info) {
    return [
        { title: 'Street Address', value: info?.address?.street },
        { title: 'Country', value: info?.address?.country },
        { title: 'City', value: info?.address?.city },
        { title: 'State', value: info?.address?.state },
        { title: 'Zip Code', value: info?.address?.zip },
        { title: 'Unit/Suite #', value: info?.address?.unit ? info?.address?.unit : 'Not Set' },
    ].filter((item) => !!item.value);
}

// Funding Source Service Table config
export const fundingSourceServiceHead = [
    { name: 'serviceTypeId', rowName: 'name', title: 'Service', custom: false },
    { name: 'cptCode', title: 'CPT Code', custom: false },
    {
        name: 'type', title: 'Type', disabled: true,
        filterList: [
            { name: 'All', id: 'All' },
            { name: 'Direct', id: 'DIRECT' },
            { name: 'Indirect', id: 'INDIRECT' },
        ],
    },
    { name: 'unitSize', title: 'Unit Size (minutes)', custom: false },
    { name: 'min', title: 'Min Unit', custom: false },
    { name: 'max', title: 'Max Unit', custom: false },
    RolePermission([PermissionList.FS_SERVICE_CREATE?.code]) &&
    { name: 'action', title: 'Action', custom: false, disabled: true },
];

export const fundingSourceServiceBody = [
    { rowText: (item) => <TextRow name={item?.serviceTypeId?.name} textWidth={8} /> },
    { rowText: (item) => <TextRow name={item?.cptCode} /> },
    { rowText: (item) => <TypeRow text={item?.type ? item?.type : 'Not Set'} /> },
    { rowText: (item) => <TextRow name={item?.unitSize} /> },
    { rowText: (item) => <TextRow name={item?.min} /> },
    { rowText: (item) => <TextRow name={item?.max} /> },
    RolePermission([PermissionList.FS_SERVICE_CREATE?.code]) &&
    {
        button: (fn, item) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {RolePermission([PermissionList.FS_SERVICE_CREATE?.code]) &&
                    <img
                        style={{ cursor: 'pointer' }}
                        src={Images.edit}
                        alt="edit"
                        onClick={() => fn('editFundingSourseService')}
                    />
                }
                <AddButton
                    styles={{ marginLeft: '16px', padding: '9px 16px', whiteSpace: 'nowrap' }}
                    Icon={false}
                    text={'Charge Rates'}
                    handleClick={() => fn('openModifiersDrawer')}
                />
            </div>
        ),
        notClickable: true,
    },
];

export const ACTION_TYPE = 'GET_FUNDING_SOURCE_SERVICE_BY_ID';

export const FS_STATUSES = [
    { label: 'Active', id: 'ACTIVE' },
    { label: 'Inactive', id: 'INACTIVE' },
];

export const serviceNotYet = {
    title: 'No Services Added',
    subTitle: 'Add services to this funding source.',
    image: Images.noService,
};
