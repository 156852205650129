import React from 'react';
import { motion } from 'framer-motion';
import { electronicRecordsContent } from './constants';
import { useWidth } from 'utils';
import { landingImages } from 'assets';
import { animationParams, animationVariants } from 'fragments';


export const ElectronicRecords = () => {
    const width = useWidth();

    const images = {
        desktop: {
            electronic: landingImages.electronic,
        },
        tablet: {
            electronic: landingImages.electronicWeb,
        },
        mobile: {
            electronic: landingImages.electronic,
        },
    };


    const renderImage = (src) => {
        if (width > 1280) {
            return images.desktop[src];
        } else if (width > 768) {
            return images.tablet[src];
        } else {
            return images.mobile[src];
        }
    };
    return (
        <div className='electronic-records-wrapper'>
            <div className='container'>
                <div className='electronic-records-box'>
                    <div className='electronic-records-content'>
                        <div className='electronic-records-content_box'>
                            <p className='blue-text'>
                                MAKE IT SIMPLE
                            </p>

                            <div style={{ overflow: 'hidden' }}>
                                <motion.h3
                                    className='electronic-records-title'
                                    {...animationParams}
                                    variants={animationVariants.titleVariantMobile}
                                >
                                    EMR: Electronic Medical Records
                                </motion.h3>
                            </div>

                            <div style={{ overflow: 'hidden' }}>
                                <motion.p
                                    className='electronic-records-desc'
                                    {...animationParams}
                                    variants={animationVariants.titleVariantMobile}
                                >
                                    Simplify data entry, automate reminders, and manage patient records efficiently
                                    with our user-friendly EMR system. Ensure data integrity and streamline your practice’s
                                    administrative tasks
                                </motion.p>
                            </div>

                            <div className='electronic-mini-card-wrapper'>
                                {electronicRecordsContent.map(({ Icon, text }, key) => (
                                    <motion.div
                                        className='electronic-mini-card_item' key={key}
                                        {...animationParams}
                                        variants={animationVariants.cardsVariant}
                                    >
                                        <Icon className='electronic-mini-card_svg' />

                                        <p className='electronic-mini-card-text'>
                                            {text}
                                        </p>
                                    </motion.div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <motion.div
                        className='electronic-records-img'
                        {...animationParams}
                        variants={animationVariants.cardsVariant}
                    >
                        <img src={renderImage('electronic')} alt='Elect Img' />
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

