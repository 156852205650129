import { call, put, takeLatest } from "redux-saga/effects";
import { systemService } from "./system.service";
import {
   GET_CREDENTIAL_GLOBAL_SUCCESS,
   CREATE_CREDENTIAL_GLOBAL,
   GET_CREDENTIAL_GLOBAL,
   EDIT_CREDENTIAL_BY_ID_GLOBAL,
   DELETE_CREDENTIAL_BY_ID_GLOBAL,
   CREATE_SERVICE_GLOBAL,
   GET_SERVICES_SUCCESS,
   GET_SERVICES,
   EDIT_SERVICE_BY_ID_GLOBAL,
   DELETE_SERVICE_BY_ID_GLOBAL,
   GET_DEPARTMENTS,
   GET_DEPARTMENTS_SUCCESS,
   CREATE_DEPARTMENT_GLOBAL,
   EDIT_DEPARTMENT_BY_ID_GLOBAL,
   DELETE_DEPARTMENT_BY_ID_GLOBAL,
   CREATE_JOB_GLOBAL,
   GET_JOBS,
   EDIT_JOB_BY_ID_GLOBAL,
   DELETE_JOB_BY_ID_GLOBAL,
   GET_JOBS_SUCCESS,
   CREATE_PLACE_GLOBAL,
   GET_PLACES,
   EDIT_PLACE_BY_ID_GLOBAL,
   DELETE_PLACE_BY_ID_GLOBAL,
   GET_PLACES_SUCCESS,
   CREATE_COVERED_CREDENTIAL,
   SELECTED_CREDENTIAL,
   EDIT_COVERED_CREDENTIAL,
   CREATE_COMPANY,
   GET_COMPANY,
   EDIT_COMPANY,
   DELETE_COMPANY,
   GET_COMPANY_SUCCESS,
   GET_CONNECT_CALENDARS,
   GET_CONNECT_CALENDARS_SUCCESS,
   DISCONNECT_CALENDAR,
   CHANGE_CALENDAR_STATUS,
   GET_OFFICE_ALLY,
   GET_OFFICE_ALLY_SUCCESS,
   CONNECT_OFFICE_ALLY,
   DISCONNECT_OFFICE_ALLY, EDIT_OFFICE_ALLY_CONNECTION,
} from './system.type';
import { httpRequestsOnLoadActions } from "../http_requests_on_load";
import { httpRequestsOnSuccessActions } from "../http_requests_on_success";
import { httpRequestsOnErrorsActions } from "../http_requests_on_errors";

/** Service Type */

function* createServiceGlobal(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(systemService.createServiceGlobalService, action.payload.body);
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* getServices(action) {
   if(action?.payload?.load !== 'noLoad') {
      yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   }
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   try {
      const pageConfig = action.payload?.data
      const res = yield call(systemService.getServicesService, pageConfig);
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put({
         type: GET_SERVICES_SUCCESS,
         payload: { services: res.data },
      });
   } catch (err) {
      yield put({
         type: GET_SERVICES_SUCCESS,
         payload: { services: { services: [], count: 0 } },
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      if (err?.data?.message === "Internal server error") {
         yield put(
             httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message)
         );
      }
   }
}

function* editServiceByIdGlobal(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(systemService.editServiceByIdGlobalService, action.payload.id, action.payload.body);
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* deleteServiceByIdGlobal(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(systemService.deleteServiceByIdService, action.payload.id);
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

/** End */

/** Credentials */

function* createCredentialGlobal(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(systemService.createCredentialGlobalService, action.payload.body);
      yield put({
         type: GET_CREDENTIAL_GLOBAL,
         payload: {load:'noLoad'}
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* createCoveredCredential(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      const res = yield call(systemService.createCoveredCredentialService, action?.payload?.id, action?.payload?.body);
      yield put({
         type: SELECTED_CREDENTIAL,
         payload: res?.data
      });
      yield put({
         type: GET_CREDENTIAL_GLOBAL,
         payload: { load:'noLoad' }
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}
function* editCoveredCredential(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      const res = yield call(systemService.editCoveredCredentialService, action?.payload);
      yield put({
         type: SELECTED_CREDENTIAL,
         payload: res?.data
      });
      yield put({
         type: GET_CREDENTIAL_GLOBAL,
         payload: { load:'noLoad' }
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* getCredentialGlobal(action) {
   if(action?.payload?.load !== 'noLoad') {
      yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   }
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   try {
      const res = yield call(systemService.getCredentialGlobalService);
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put({
         type: GET_CREDENTIAL_GLOBAL_SUCCESS,
         payload: res.data.reverse(),
      });
   } catch (err) {
      yield put({
         type: GET_CREDENTIAL_GLOBAL_SUCCESS,
         payload: "",
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      if (err?.data?.message === "Internal server error") {
         yield put(
            httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message)
         );
      }
   }
}

function* editCredentialById(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      const res = yield call(systemService.editCredentialByIdGlobalService, action.payload.id, action.payload.body);
      yield put({
         type: SELECTED_CREDENTIAL,
         payload: res?.data
      });
      yield put({
         type: GET_CREDENTIAL_GLOBAL,
         payload: { load:'noLoad' }
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* deleteCredentialByIdGlobal(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(systemService.deleteCredentialByIdService, action.payload.id);
      yield put({
         type: GET_CREDENTIAL_GLOBAL
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

/** End */

/** Departments */

function* createDepartmentGlobal(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(systemService.createDepartmentGlobalService, action.payload.body);
      yield put({
         type: GET_DEPARTMENTS,
         payload: { load:'noLoad'}
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* getDepartments(action) {
   if(action?.payload?.load !== 'noLoad') {
      yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   }
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   try {
      const res = yield call(systemService.getDepartmentsService);
      yield put({
         type: GET_DEPARTMENTS_SUCCESS,
         payload: res.data.reverse(),
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
   } catch (err) {
      yield put({
         type: GET_DEPARTMENTS_SUCCESS,
         payload: "",
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      if (err?.data?.message === "Internal server error") {
         yield put(
            httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message)
         );
      }
   }
}

function* editDepartmentByIdGlobal(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(systemService.editDepartmentByIdGlobalService, action.payload.id, action.payload.body);
      yield put({
         type: GET_DEPARTMENTS,
         payload: { load:'noLoad' }
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));

   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* deleteDepartmentByIdGlobal(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(systemService.deleteDepartmentByIdService, action.payload.id);
      yield put({
         type: GET_DEPARTMENTS
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

/** End */

/** Job */

function* createJobGlobal(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(systemService.createJobGlobalService, action?.payload?.body);
      yield put({
         type: GET_JOBS,
         payload: {load:'noLoad'}
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* getJobs(action) {
   if(action?.payload?.load !== 'noLoad') {
      yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   }
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   try {
      const res = yield call(systemService.getJobsService);
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put({
         type: GET_JOBS_SUCCESS,
         payload: res.data.reverse(),
      });
   } catch (err) {
      yield put({
         type: GET_JOBS_SUCCESS,
         payload: "",
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      if (err?.data?.message === "Internal server error") {
         yield put(
            httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message)
         );
      }
   }
}

function* editJobByIdGlobal(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(systemService.editJobByIdGlobalService, action.payload.id, action.payload.body);
      yield put({
         type: GET_JOBS,
         payload: {load:'noLoad'}
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* deleteJobByIdGlobal(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(systemService.deleteJobByIdService, action.payload.id);
      yield put({
         type: GET_JOBS
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

/** End */

/** Places */

function* createPlaceGlobal(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(systemService.createPlaceGlobalService, action.payload.body);
      yield put({
         type: GET_PLACES,
         payload: { load:'noLoad' }
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* getPlaces(action) {
   if(action?.payload?.load !== 'noLoad') {
      yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   }
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   try {
      const res = yield call(systemService.getPlacesService);
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put({
         type: GET_PLACES_SUCCESS,
         payload: res.data.reverse(),
      });
   } catch (err) {
      yield put({
         type: GET_PLACES_SUCCESS,
         payload: "",
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      if (err?.data?.message === "Internal server error") {
         yield put(
            httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message)
         );
      }
   }
}

function* editPlaceByIdGlobal(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(systemService.editPlaceByIdGlobalService, action.payload.id, action.payload.body);
      yield put({
         type: GET_PLACES,
         payload: {load:'noLoad'}
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* deletePlaceByIdGlobal(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(systemService.deletePlaceByIdService, action.payload.id);
      yield put({
         type: GET_PLACES
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

/** End */

/** Company */

function* createCompany(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(systemService.createCompanyService, action?.payload?.params);
      yield put({
         type: GET_COMPANY,
         payload: {load:'noLoad'}
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* getCompany(action) {
   if(action?.payload?.load !== 'noLoad') {
      yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   }
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   try {
      const res = yield call(systemService.getCompanyService);
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put({
         type: GET_COMPANY_SUCCESS,
         payload: res.data,
      });
   } catch (err) {
      yield put({
         type: GET_COMPANY_SUCCESS,
         payload: null,
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      if (err?.data?.message === "Internal server error") {
         yield put(
             httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message)
         );
      }
   }
}

function* editCompany(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(systemService.editCompanyService, action?.payload?.id, action?.payload?.params);
      yield put({
         type: GET_COMPANY,
         payload: {load:'noLoad'}
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* deleteCompany(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(systemService.deleteCompanyService, action?.payload?.id);
      yield put({
         type: GET_COMPANY,
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

/** End */

/** Calendars */
function* getConnectCalendars(action) {
   if(action?.payload?.load !== 'noLoad') {
      yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   }
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      const res = yield call(systemService.getConnectCalendarsService);
      yield put({
         type: GET_CONNECT_CALENDARS_SUCCESS,
         payload: res.data,
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* disconnectCalendar(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(systemService.disconnectCalendarService, action?.payload);
      yield put({
         type: GET_CONNECT_CALENDARS,
         payload: { load:'noLoad' },
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* changeCalendarStatus(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      const res = yield call(systemService.changeCalendarStatusService, action?.payload);
      yield put({
         type: GET_CONNECT_CALENDARS_SUCCESS,
         payload: res.data,
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

/** End */


/** Office Ally */
function* getOfficeAlly(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   try {
      const res = yield call(systemService.getOfficeAllyService);
      yield put({
         type: GET_OFFICE_ALLY_SUCCESS,
         payload: res.data,
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
   }
}

function* connectOfficeAlly(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   try {
      yield call(systemService.connectOfficeAllyService, action?.payload);
      yield put({
         type: GET_OFFICE_ALLY,
      });
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
   } catch (err) {
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
      yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
   }
}

function* disconnectOfficeAlly(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(systemService.disconnectOfficeAllyService);
      yield put({
         type: GET_OFFICE_ALLY,
      });
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
   } catch (err) {
      yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
   }
}
function* editOfficeAlly(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(systemService.editOfficeAllyService, action?.payload);
      yield put({
         type: GET_OFFICE_ALLY,
      });
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
   } catch (err) {
      yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
   }
}

/** End */

export const watchSystem = function* watchSystemSaga() {
   /** Service Type */
   yield takeLatest(CREATE_SERVICE_GLOBAL, createServiceGlobal);
   yield takeLatest(GET_SERVICES, getServices);
   yield takeLatest(EDIT_SERVICE_BY_ID_GLOBAL, editServiceByIdGlobal);
   yield takeLatest(DELETE_SERVICE_BY_ID_GLOBAL, deleteServiceByIdGlobal);
   /** End */

   /** Credentials */
   yield takeLatest(CREATE_CREDENTIAL_GLOBAL, createCredentialGlobal);
   yield takeLatest(CREATE_COVERED_CREDENTIAL, createCoveredCredential);
   yield takeLatest(EDIT_COVERED_CREDENTIAL, editCoveredCredential);
   yield takeLatest(GET_CREDENTIAL_GLOBAL, getCredentialGlobal);
   yield takeLatest(EDIT_CREDENTIAL_BY_ID_GLOBAL, editCredentialById);
   yield takeLatest(DELETE_CREDENTIAL_BY_ID_GLOBAL, deleteCredentialByIdGlobal);
   /** End */

   /** Department */
   yield takeLatest(CREATE_DEPARTMENT_GLOBAL, createDepartmentGlobal);
   yield takeLatest(GET_DEPARTMENTS, getDepartments);
   yield takeLatest(EDIT_DEPARTMENT_BY_ID_GLOBAL, editDepartmentByIdGlobal);
   yield takeLatest(DELETE_DEPARTMENT_BY_ID_GLOBAL, deleteDepartmentByIdGlobal);
   /** End */

   /** Job */
   yield takeLatest(CREATE_JOB_GLOBAL, createJobGlobal);
   yield takeLatest(GET_JOBS, getJobs);
   yield takeLatest(EDIT_JOB_BY_ID_GLOBAL, editJobByIdGlobal);
   yield takeLatest(DELETE_JOB_BY_ID_GLOBAL, deleteJobByIdGlobal);
   /** End */

   /** Places */
   yield takeLatest(CREATE_PLACE_GLOBAL, createPlaceGlobal);
   yield takeLatest(GET_PLACES, getPlaces);
   yield takeLatest(EDIT_PLACE_BY_ID_GLOBAL, editPlaceByIdGlobal);
   yield takeLatest(DELETE_PLACE_BY_ID_GLOBAL, deletePlaceByIdGlobal);
   /** End */

   /** Company */
   yield takeLatest(CREATE_COMPANY, createCompany);
   yield takeLatest(GET_COMPANY, getCompany);
   yield takeLatest(EDIT_COMPANY, editCompany);
   yield takeLatest(DELETE_COMPANY, deleteCompany);
   /** End */

   /** Calendars */
   yield takeLatest(GET_CONNECT_CALENDARS, getConnectCalendars);
   yield takeLatest(DISCONNECT_CALENDAR, disconnectCalendar);
   yield takeLatest(CHANGE_CALENDAR_STATUS, changeCalendarStatus);
   /** End */

   /** Office Ally */
   yield takeLatest(GET_OFFICE_ALLY, getOfficeAlly);
   yield takeLatest(CONNECT_OFFICE_ALLY, connectOfficeAlly);
   yield takeLatest(DISCONNECT_OFFICE_ALLY, disconnectOfficeAlly);
   yield takeLatest(EDIT_OFFICE_ALLY_CONNECTION, editOfficeAlly);
   /** End */

};
