import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CreateChancel, DateTypeSelector, ErrMessage } from 'components';
import { modalsStyle } from 'components/modal/styles';
import { scheduleModalsStyle } from './styles';
import { appointmentActions } from 'store';
import { ErrorText, FindLoad, useModal } from 'utils';
import { DailyPattern, MonthlyPattern, WeeklyPattern } from './modePatterns';
import { Mode } from './common';
import { ConfMultiple } from './confMultiple';
import moment from 'moment/moment';
import { renderUnitWarnings, RenderWarnings } from './constants';

const initialInputs = {
    mode: 'DAILY',
    startDate: '',
    endDate: '',
    repeatCountWeek: '1',
    repeatDayMonth: '',
    repeatMonth: '1',
    repeatConsecutive: '',
    repeatCount: '1',
    repeatCountCheckbox: 'repeatCountCheckbox',
};

export const Recur = ({ date, staffTb, currentService }) => {
    const global = modalsStyle();
    const classes = scheduleModalsStyle();
    const RECUR_ACTION_TYPE = 'APPOINTMENT_REPEAT';
    const dispatch = useDispatch();
    const loader = FindLoad(RECUR_ACTION_TYPE);
    const [inputs, setInputs] = useState(initialInputs);
    const [occurrence, setOccurrence] = useState(0);
    const [state, setState] = React.useState([]);
    const [error, setError] = React.useState('');
    const { close, open } = useModal();
    const { uniteSizeCorrect, unitSizeCountIsCorrect, maxUnit, minUnit, currentApptUnites, unitSize, } = renderUnitWarnings(currentService, {
        ...date,
        startTime: date?.startTime?.slice(11, 16),
        endTime: date?.endTime?.slice(11, 16),
    });
    const checkAvailableUnits = date?.authService ? currentService?.availableUnits < 0 ? -1 : (currentService?.availableUnits - currentApptUnites) : '';


    function handleChange(e) {
        e.target.name === 'mode' && setOccurrence(0);
        e.target.name === 'repeatCountCheckbox' && setOccurrence(0)
        delete inputs['repeatConsecutive'];

        if (e.target.name === 'repeatCountCheckbox') {
            inputs.repeatCount = '1';
        }

        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        if (error === ErrorText.globalError || error === ErrorText.dateError || error === e.target.name) setError('');
    }

    useEffect(() => {
        if (inputs?.mode === 'DAILY' && inputs.startDate && inputs.endDate && inputs?.repeatCount) {
            handleDaily();
        }
        if (inputs?.mode === 'WEEKLY' && inputs.startDate && inputs.endDate && inputs?.repeatCountWeek) {
            handleWeekly();
        }
        if (inputs?.mode === 'MONTHLY' && inputs.startDate && inputs.endDate && inputs?.repeatDayMonth && inputs?.repeatMonth) {
            handleMonthly(+inputs?.repeatDayMonth, +inputs?.repeatMonth);
        }
    }, [inputs, state]);

    const handleDaily = (e) => {
        const startDate = new Date(inputs.startDate);
        const endDate = new Date(new Date(inputs.endDate).setHours(23, 59, 59));
        let count = 0;
        let dates = [],
            x;

        const enteredDateCount = e ? +e.target.value : +inputs?.repeatCount;

        for (let d = startDate; d <= endDate; d.setDate(d.getDate() + enteredDateCount)) {
            if (enteredDateCount !== 0) count++;
            if (enteredDateCount === 0) break;
            x = new Date(d.getTime());
            dates.push(x);
        }
        setOccurrence(count);
    };

    function handleChangeDay(e) {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value === '0' ? 1 : e.target.value }));
        // setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value, }));
        handleDaily(e);
    }

    function handleChangeConsecutive(e) {
        e.target.name === 'repeatConsecutive' && setOccurrence(0),
        delete inputs['repeatCount'] && delete inputs['repeatCountCheckbox'];
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));

        const startDate = new Date(inputs.startDate);
        const endDate = new Date(new Date(inputs.endDate).setHours(23, 59, 59));
        const curDate = new Date(startDate);
        let dates = [];
        while (curDate <= endDate) {
            const dayOfWeek = curDate.getUTCDay();
            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                dates.push(new Date(curDate));
            }
            curDate.setDate(curDate.getDate() + 1);
        }
        setOccurrence(dates.length);
    }

    function handleChangeWeek(e) {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value === '0' ? 1 : e.target.value }));
        handleWeekly(e);
    }

    const handleWeekly = (e) => {
        const weekdays = [...state];
        const repeatCount = e ? +e?.target?.value : inputs.repeatCountWeek ? inputs.repeatCountWeek : 1;
        let occurrences = 0;
        const momentStart = moment.utc(inputs.startDate).startOf('day');
        const momentEnd = moment.utc(inputs.endDate).endOf('day');
        const current = momentStart.clone();
        while (current.isSameOrBefore(momentEnd)) {
            const dayOfWeek = current.day();
            if (
                weekdays.includes(dayOfWeek) &&
                (repeatCount === 1 || current.diff(momentStart, 'weeks') % repeatCount === 0)
            ) {
                ++occurrences;
            }
            current.add(1, 'day');
        }
        setOccurrence(occurrences);
    };

    function handleChangeWeeks(e) {
        const newState = [...state];
        if (e.target.checked) {
            newState.push(+e.target.value);
        } else {
            for (let i = 0; i < state.length; i++) {
                if (state[i] === +e.target.value) {
                    newState.splice(i, 1);
                }
            }
        }
        setState(newState);
    }

    function handleChangeMonth(e) {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value === '0' ? 1 : e.target.value }));
    }

    const handleMonthly = (repeatDayMonth, repeatMonth) => {
        let count = 0;
        const start = moment.utc(inputs.startDate);
        const end = moment.utc(inputs.endDate);
        const current = moment.utc(start);

        while (current <= end) {
            current.date(repeatDayMonth);
            if (current > end) {
                break;
            }
            if (current >= start) {
                count++;
            }
            current.month(current.month() + repeatMonth);
        }
        setOccurrence(count);
    };


    function handleRecur() {
        const repeat = !!inputs.repeatCount
            ? inputs.repeatCount
            : !!inputs.repeatConsecutive
                ? inputs.repeatConsecutive
                : '';

        const dateComparingIsValid = new Date(inputs.startDate).getTime() < new Date(inputs.endDate).getTime();

        const typeBool =
            inputs.mode === 'DAILY'
                ? !!inputs.startDate && !!inputs.endDate && dateComparingIsValid && !!repeat
                : inputs.mode === 'WEEKLY'
                    ? !!inputs.startDate &&
                    !!inputs.endDate &&
                    dateComparingIsValid &&
                    !!inputs.repeatCountWeek &&
                    !!state.length
                    : !!inputs.startDate &&
                    !!inputs.endDate &&
                    dateComparingIsValid &&
                    !!inputs.repeatDayMonth &&
                    !!inputs.repeatMonth;

        const week = {
            startDate: new Date(inputs.startDate),
            endDate: new Date(inputs.endDate),
            mode: inputs.mode,
            repeatCountWeek: +inputs.repeatCountWeek,
            repeatCheckWeek: [...state],
        };

        const mounthObject = {
            startDate: new Date(inputs.startDate),
            endDate: new Date(inputs.endDate),
            mode: inputs.mode,
            repeatDayMonth: +inputs.repeatDayMonth,
            repeatMonth: +inputs.repeatMonth,
        };

        !inputs.repeatDayMonth ? delete mounthObject['repeatDayMonth'] : '';
        !inputs.repeatMonth ? delete mounthObject['repeatMonth'] : '';

        const newObject =
            inputs.repeatConsecutive === 'repeatConsecutive'
                ? {
                    startDate: new Date(inputs.startDate),
                    endDate: new Date(inputs.endDate),
                    mode: inputs.mode,
                    repeatConsecutive: true,
                }
                : {
                    startDate: new Date(inputs.startDate),
                    endDate: new Date(inputs.endDate),
                    mode: inputs.mode,
                    repeatCount: +inputs.repeatCount,
                };

        const obj =
            inputs.mode === 'WEEKLY' ? week
                : inputs.mode === 'MONTHLY' ? mounthObject :
                    newObject;

        if (typeBool) {
            const startTime = moment.utc(inputs?.startDate);
            startTime.set({
                hour: date?.startTime?.slice(11, 13),
                minute: date?.startTime?.slice(14, 16),
                second: '00',
            });
            const endTime = moment.utc(inputs?.endDate);
            endTime.set({
                hour: date?.endTime?.slice(11, 13),
                minute: date?.endTime?.slice(14, 16),
                second: '00',
            });

            const checkParams = {
                staffId: date?.staff?._id,
                startTime: startTime.format(),
                endTime: endTime.format(),
            };


            open(
                <ConfMultiple
                    recurInfo={obj}
                    handleSubmit={() => dispatch(appointmentActions.appointmentRepeat({
                        pattern: { ...obj },
                        apptId: date?.id,
                    }))}
                    loadType={RECUR_ACTION_TYPE}
                    occurrence={occurrence}
                    checkParams={checkParams}
                    staffTb={staffTb}
                />,
            );

        } else {
            setError(
                !inputs.startDate ? 'startDate' :
                    !inputs.endDate ? 'endDate' :
                        !dateComparingIsValid ? ErrorText.dateError :
                            ErrorText.globalError,
            );
        }
    }

    const changeDates = (value) => {
        const params = {
            ...inputs,
        };

        value?.start ? params.startDate = value.start : delete params.startDate;
        value?.end ? params.endDate = value.end : delete params.endDate;

        setInputs(params);
        if (error === ErrorText.dateError || error === 'startDate' || error === 'endDate') {
            setError('');
        }
    };

    return (
        <div className={classes.recurModalWrapper}>
            <p className={global.availableScheduleTitle}>Recur Event</p>
            <div className={classes.recurBody}>
                <>
                    <div className={classes.serviceInputsWrapper}>
                        <p className="date-time-text">Date Range</p>
                        <DateTypeSelector
                            startName={'start'}
                            endName={'end'}
                            type={'modalInput'}
                            outLabel={'Date Range*'}
                            handleGetDates={changeDates}
                            filters={{
                                start: inputs?.startDate,
                                end: inputs?.endDate,
                            }}
                            error={
                                error === 'startDate' ? `Start date ${ErrorText.isRequired}` :
                                    error === 'endDate' ? `End date ${ErrorText.isRequired}` :
                                        error === ErrorText.dateError ? ErrorText.dateError :
                                            ''
                            }
                        />
                    </div>
                    {staffTb && inputs?.endDate > moment.utc(staffTb?.expireDate).format('YYYY-MM-DD') &&
                        <div className={classes.confirmModalErrBox}>
                            <p style={{ margin: 0 }}>
                                <span>The staff TB expires on</span>
                                <span style={{ fontWeight: 600, margin: '0 4px' }}>
                                        {moment.utc(staffTb?.expireDate).format('MM/DD/YYYY')}
                                    </span>
                                <span>Please enter an earlier date or adjust TB expiration.</span>
                            </p>
                        </div>
                    }
                </>

                <>
                    <RenderWarnings
                        uniteSizeCorrect={uniteSizeCorrect}
                        unitSizeCountIsCorrect={unitSizeCountIsCorrect}
                        unitSize={unitSize}
                        minUnit={minUnit}
                        maxUnit={maxUnit}
                        currentApptUnites={currentApptUnites}
                        checkAvailableUnits={checkAvailableUnits}
                        warnAuthorization={currentService}
                        availableUnits={currentService?.availableUnits}
                    />
                </>

                <div className="flex-align-start" style={{ gap: 24, marginTop: 16 }}>

                    <Mode inputs={inputs} handleChange={handleChange} />

                    <div className="full-width">
                        <p className={classes.recurTitle}>Patterns</p>
                        <div className={classes.dayWeekMounth}>
                            <div>
                                {inputs.mode === 'DAILY' ? (
                                    <DailyPattern
                                        handleChange={handleChange}
                                        handleChangeDay={handleChangeDay}
                                        handleChangeConsecutive={handleChangeConsecutive}
                                        inputs={inputs}
                                    />
                                ) : inputs.mode === 'WEEKLY' ? (
                                    <WeeklyPattern
                                        inputs={inputs}
                                        handleChangeWeek={handleChangeWeek}
                                        handleChangeWeeks={handleChangeWeeks}
                                    />
                                ) : inputs.mode === 'MONTHLY' ? (
                                    <MonthlyPattern
                                        inputs={inputs}
                                        handleChangeMounth={handleChangeMonth}
                                        handleChangeMounthDay={handleChangeMonth}
                                    />
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>

                {error &&
                    error !== ErrorText.timeError &&
                    error !== 'startDate' &&
                    error !== 'endDate' &&
                    error !== 'startTime' &&
                    error !== 'endTime' &&
                    <ErrMessage text={error ? error : ''} />
                }

                <div className={classes.actionBtnBox}>
                    <CreateChancel
                        disabled={occurrence === 0}
                        loader={!!loader.length}
                        create={`Add ${occurrence ? occurrence : 0} Appointments`}
                        chancel={'Cancel'}
                        onCreate={handleRecur}
                        onClose={close}
                        buttonWidth="48%"
                    />
                </div>
            </div>
        </div>
    );
};