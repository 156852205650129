import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { claimActions } from 'store';
import { Aging, FullTable } from 'components';
import { billedClaimNotYet, submittedByClaimBody, submittedByClaimHead, } from './constants';

const ACTION_TYPE = 'GET_CLAIMS';
export const SubmittedClaim = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const submittedClaimsData = useSelector((state) => state.claim.submittedClaims);
    const submittedClaims = useSelector((state) => state.claim.claims);

    const renderParams = () => {
        const sendInfo = {
            ...info,
            status: 'SUBMITTED',
        };
        delete sendInfo.page;
        delete sendInfo.tabType;
        sendInfo.skip = info?.skip ? info?.skip : 0;
        sendInfo.limit = 50;
        return sendInfo;
    };

    const getPendingClaim = (loading) => {
        dispatch(claimActions.getPendingClaims({ ...renderParams(), loading }));
    };

    useEffect(() => {
        getPendingClaim();
    }, [info]);

    return (
        <div>
            <Aging aging={submittedClaims?.aging} />
            <FullTable
                head={submittedByClaimHead}
                body={submittedByClaimBody}
                loadingType={ACTION_TYPE}
                list={submittedClaims?.claims}
                listCount={submittedClaimsData?.count}
                handleClick={(id) =>
                    history.push(`/billedClaim/${id}`, { tabType: info?.tabType })
                }
                notYet={billedClaimNotYet}
                activeRowId={''}
            />
        </div>
    );
};
