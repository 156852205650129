

// firebase.js
// import { initializeApp } from 'https://www.gstatic.com/firebasejs/9.0.0/firebase-app.js';
// import { getMessaging, getToken, onMessage } from 'https://www.gstatic.com/firebasejs/9.0.0/firebase-messaging.js';

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';


const firebaseConfig = {
    apiKey: "AIzaSyA0ViM3EnVCk8uFespNR3lthZoHpWaCZo0",
    authDomain: "therapylake-edd6d.firebaseapp.com",
    projectId: "therapylake-edd6d",
    storageBucket: "therapylake-edd6d.appspot.com",
    messagingSenderId: "679990278879",
    appId: "1:679990278879:web:206ab9347d4f5c7651076a",
    measurementId: "G-WLMDMD76VD"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };
