import React from 'react';
import { buttonsStyle } from './styles';
import { Colors, Images, DownloadFile } from 'utils';
import { MinLoader } from '../loader';

export const DownloadLink = ({
                                 linkClassName,
                                 linkInnerText,
                                 file,
                                 customDownload,
                                 id,
                                 small,
                                 iconStyles,
                                 otherIcon,
                                 downloadLoadStyle,
                                 loadColor,
                                 buttonStyles,
                             }) => {
    const classes = buttonsStyle();
    const downloadLinkClassName = `${classes.downloadLinkStyle} ${linkClassName}`;
    const { loadDownload, handleDownload, download1500Form, download1500Forms } = DownloadFile();

    const download = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (customDownload === '1500Form') {
            download1500Form(file, id);
        } else if (customDownload === '1500Forms') {
            download1500Forms(id);
        } else {
            handleDownload(file);
        }
    };

    return (
        <button
            style={{ border: 'none', background: 'transparent', cursor: 'transparent', padding: 0, ...buttonStyles }}
            className={downloadLinkClassName}
            onClick={download}
            disabled={loadDownload}
        >
            {loadDownload ?
                <>
                    {linkInnerText}
                    <MinLoader
                        margin={downloadLoadStyle ? downloadLoadStyle : '0 0 0 11px'}
                        color={loadColor ? loadColor : Colors.BackgroundBlue}
                        position={'relative'}
                        small={small}
                    />
                </>
                :
                <>
                    {linkInnerText}
                    <img src={otherIcon ? otherIcon : Images.downloadCloudBlue} alt="" style={{ ...iconStyles }} />
                </>
            }
        </button>
    );
};
