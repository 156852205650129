import React, { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel } from '@material-ui/core';

export const CheckboxCustom = forwardRef((
    { control, name, rules, label, color, disabled, wrapperStyles, ...rest }, ref) => {
    return (

        <Controller
            key={control}
            control={control}
            name={name}
            disabled={disabled}
            rules={rules}
            render={({ field }) => {
               return <FormControlLabel
                    className='checkbox-wrapper'
                    label={label}
                    {...rest}
                    style={wrapperStyles}
                    ref={ref}
                    control={
                        <Checkbox
                            {...field}
                            defaultValue={field?.value}
                            checked={!!field?.value}
                            style={{ color: color ? color : '#347AF0' }}
                            onChange={({ target }) => field?.onChange(target.checked)}
                        />
                    }
                />
            }}
        />
    );
});

CheckboxCustom.displayName = 'CheckboxCustom';


