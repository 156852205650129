/** Claim Payment*/
export const GET_CLAIM_PAYMENTS = "GET_CLAIM_PAYMENTS";
export const GET_CLAIM_PAYMENTS_SUCCESS = "GET_CLAIM_PAYMENTS_SUCCESS";

export const EDIT_CLAIM_PAYMENT_INFO = "EDIT_CLAIM_PAYMENT_INFO";

export const APPEAL_CLAIM_PAYMENT = "APPEAL_CLAIM_PAYMENT";

export const PAY_IN_FULL_CLAIM = "PAY_IN_FULL_CLAIM";

export const PAY_IN_FULL_CLAIM_BILLING = "PAY_IN_FULL_CLAIM_BILLING";
/** End */