import React from "react";
import { Main } from "fragments";
import { PrivacyPolicyFragment } from "./fragments";

export const PrivacyPolicy = () => {
   return (
      <Main>
         <PrivacyPolicyFragment />
      </Main>
   );
};
