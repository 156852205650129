import {
    GET_CLAIM_PAYMENTS,
    APPEAL_CLAIM_PAYMENT,
    PAY_IN_FULL_CLAIM,
    PAY_IN_FULL_CLAIM_BILLING,
    EDIT_CLAIM_PAYMENT_INFO,
} from "./claimPayment.type";


/** Claim Payment */

export const getClaimPayments = (data) => {
    return {
        type: GET_CLAIM_PAYMENTS,
        payload: {data},
    };
};

export const editClaimPaymentInfo = (body, getParams) => {
    return {
        type: EDIT_CLAIM_PAYMENT_INFO,
        payload: { body, getParams }
    }
}

export const appealClaimPayment = (body, getParams) => {
    return {
        type: APPEAL_CLAIM_PAYMENT,
        payload: { body, getParams }
    }
}

export const payInFullClaim = (body, getParams) => {
    return {
        type: PAY_IN_FULL_CLAIM,
        payload: { body, getParams }
    }
}

export const payInFullClaimBill = (body, getParams) => {
    return {
        type: PAY_IN_FULL_CLAIM_BILLING,
        payload: { body, getParams }
    }
}

/** End */