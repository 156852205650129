import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ValidationInput,
    CreateChancel,
    ErrMessage,
    MinLoader,
    NoYet, SelectTypeAutocomplete, NumericFormatCustom, CustomNumericFormat,
} from 'components';
import { createClientStyle } from './styles';
import {
    Colors,
    ErrorText,
    FindErrorItem,
    FindLoad,
    FindSuccess,
    hooksForErrors,
    isNotEmpty, useModal,
} from 'utils';
import {
    clientActions,
    fundingSourceActions,
    httpRequestsOnErrorsActions,
    httpRequestsOnSuccessActions,
} from 'store';
import axios from 'axios';
import { FormControlLabel, Radio } from '@material-ui/core';
import { frequencyEnumsConvert, frequencyList } from './constants';

const SERVICE_ACTION = 'GET_FUNDING_SOURCE_SERVICE_BY_ID';
export const AddAuthorizationService = ({ info, fundingId, authId, services }) => {
    const classes = createClientStyle();
    const dispatch = useDispatch();
    const ACTION_TYPE = !!info ? 'EDIT_CLIENT_AUTHORIZATION_SERV' : 'CREATE_CLIENT_AUTHORIZATION_SERV';
    const fSelect = useSelector((state) => state.fundingSource.fundingSourceServices);
    const [error, setError] = useState('');
    const [inputs, setInputs] = useState(info ? {
        ...info,
        total: info?.totalUnits,
        name: info?.service?.cptCode,
        modifiers: info?.service?._id,
    } : {});
    const [modCheck, setModCheck] = useState([]);
    const [modif, setModif] = useState([]);
    const loading = FindLoad(SERVICE_ACTION);
    const [checkModifiersLoader, setCheckModifiersLoader] = useState(false);
    const success = FindSuccess(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const backError = FindErrorItem(ACTION_TYPE);
    const authServiceDefaultErrorText = hooksForErrors.getAuthServiceDefaultErrorText(error, backError);
    const { close } = useModal();
    const checkIfZero = info?.billedUnits === 0 && info?.completedUnits === 0 && info?.reservedUnits === 0;
    const [frequency, setFrequency] = useState(info ? {
        limit: info?.frequencyLimit?.limit,
        mode: info?.frequencyLimit?.mode,
    } : {});

    useEffect(() => {
        if (info) {
            axios.post(`/authService/authorizations/${authId}/fundingService/${info?.service?._id}/checkChargeRates`, null, { auth: true })
                .then((res) => {
                    setModif([...info?.chargeRates, ...res.data]);
                }).finally(() => {
                setCheckModifiersLoader(false);
            });
            setModCheck(info?.chargeRates);
        }
    }, [info]);

    useEffect(() => {
        // dispatch(fundingSourceActions.getFoundingSourceServiceById(fundingId, ));
        dispatch(fundingSourceActions.getFoundingSourceServiceById(fundingId, { active: true }));
    }, []);

    useEffect(() => () => {
        httpRequestsOnErrorsActions.removeError(ACTION_TYPE);
    }, []);

    useEffect(() => {
        if (!!success.length) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);


    function handleChange(e) {

        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        if (e.target.name === 'modifiers' && e.target.value) {
            if (e.target.value === '') return;
            setCheckModifiersLoader(true);
            setModCheck([]);
            const _fundingService = fSelect.find((item) => item.id === e.target.value);

            axios.post(`/authService/authorizations/${authId}/fundingService/${_fundingService?.id}/checkChargeRates`, null, { auth: true })
                .then((res) => {
                    setModif(res.data);
                }).finally(() => {
                setCheckModifiersLoader(false);
            });
        } else {
            setModCheck([]);
            setModif([]);
        }


        if (error === e.target.name || !!backError) {
            setError('');
        }
        if (!!backError) {
            dispatch(httpRequestsOnErrorsActions.removeError(backError.type));
        }
    }

    function handleChangeTotal(e) {
        if (e.target.value >= 0 && e.target.value.indexOf('.') === -1 && e.target.value <= 10000) {
            setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
            if (error === e.target.name || !!backError) {
                setError('');
            }
            if (!!backError) {
                dispatch(httpRequestsOnErrorsActions.removeError(backError.type));
            }
        }
    }

    function handleCreate() {
        let modifiersPost = modCheck?.map((i) => i?.id) || [];
        const modifiersAreValid = !!modifiersPost?.length;
        const authServiceDataIsValid = isNotEmpty(inputs.totalUnits) && modifiersAreValid;
        const checkFrequency = !!frequency?.mode && (frequency?.mode === frequencyEnumsConvert.ENTIRE_TERM ? true : frequency?.limit > 0);

        if (authServiceDataIsValid && frequency?.mode && checkFrequency) {
            const createAuthServiceData = {
                totalUnits: +inputs.totalUnits,
                chargeRateIds: modifiersPost,
                frequencyLimit: {
                    limit: +frequency?.limit,
                    mode: frequency?.mode,
                },
            };

            if (!!info) {
                const editAuthServiceData = {
                    ...createAuthServiceData,
                    authorizationId: authId,
                    serviceId: inputs?.service?._id,
                    frequencyLimit: {
                        limit: +frequency?.limit,
                        mode: frequency?.mode,
                    },
                };
                dispatch(clientActions.editClientsAuthorizationsServ(editAuthServiceData, info.id, authId));
            } else {
                dispatch(clientActions.createClientsAuthorizationsServ(createAuthServiceData, authId, inputs.modifiers));
            }
        } else {

            setError(!inputs.modifiers ? 'modifiers' :
                !modifiersPost?.length ? 'modifiersPost' :
                    !isNotEmpty(inputs.totalUnits) ? 'totalUnits' :
                        !frequency?.mode ? 'mode' :
                            !frequency?.limit ? 'limit' :
                                frequency?.limit !== 0 ? 'limit' :
                                    '');
        }
    }

    function onModifier(item) {
        error === 'modifiersPost' && setError('');
        let newList = [...modCheck];
        const ifHas = modCheck.find((i) => i?.id === item?.id);

        if (ifHas) {
            newList = newList.filter((i) => i?.id !== item?.id);
        } else {
            newList.push(item);
        }
        setModCheck(newList);
    }

    const changeLimitMode = (e) => {
        const newParams = {
            ...frequency,
            [e.target.name]: e.target.value,
        };
        newParams.limit = 0;
        setFrequency(newParams);

        if (error === 'mode' || error === 'limit') {
            setError('');
        }
    };

    const changeLimit = (value) => {
        setFrequency((prevState) => ({ ...prevState, ['limit']: value?.floatValue }));
        if (error === 'limit') {
            setError('');
        }
    };


    return (
        <div style={{ width: 400 }}>
            <p className="modal-header-title">{info ? 'Edit Authorization Service' : 'Add Authorized Service'}</p>
            <div className="modal-body-wrapper">
                <SelectTypeAutocomplete
                    disabled={!!info}
                    loadType={SERVICE_ACTION}
                    title={'Service Code*'}
                    name={'modifiers'}
                    handleSelect={handleChange}
                    defaultValue={inputs.modifiers}
                    list={fSelect?.length ? fSelect : []}
                    error={error}
                    typeError={error === 'modifiers' ? `Service Code ${ErrorText.isRequired}` : ''}
                    renderValue={(i) => `${i?.serviceTypeId?.name} - ${i?.cptCode}`}
                />

                <div
                    className={`${classes.displayCodeBlock2} ${error === 'modifiersPost' || !!authServiceDefaultErrorText ? 'error' : ''}`}>
                    <p className={classes.displayCodeBlockText}>Charge Rates</p>
                    <div
                        className={`${classes.availableModfiers} ${!inputs.modifiers || checkModifiersLoader === true ? 'notApplicable' : ''}`}>
                        {loading?.length || checkModifiersLoader ? (
                            <div className={classes.loaderBoxStyle}>
                                <MinLoader margin={'0'} color={Colors.ThemeBlue} position={'relative'} />
                            </div>
                        ) : (
                            <div className={classes.serviceModifiersContainerStyle}>
                                <div>
                                    {!!modif?.length ? modif.map((item, index) => {
                                            const check = modCheck?.find((i) => i?.id === item?.id);
                                            const alreadyUsed = info?.chargeRates?.find((i) => i?.id === item?.id);
                                            const canEdit = info?.id ? alreadyUsed?.id ? !checkIfZero : false : false;
                                            const modifierBtnStyle = `${classes.availableModfier} ${check ? 'checked' : ''}`;

                                            // `${classes.availableModfier} ${check ? 'checked' : modifierExists ? 'chosen' : ''}`;

                                            if (!!item?.status) {
                                                return (
                                                    <button
                                                        key={index}
                                                        className={modifierBtnStyle}
                                                        onClick={() => onModifier(item)}
                                                        disabled={canEdit}
                                                    >
                                                        {item.name}
                                                    </button>
                                                );
                                            }
                                        })
                                        :

                                        <div className={`${classes.notApplicableStyle} ${!!info ? 'hidden' : ''}`}>
                                            <NoYet text="No charge rates available" />
                                        </div>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <ErrMessage text={authServiceDefaultErrorText} />
                <ValidationInput
                    variant={'outlined'}
                    onChange={handleChangeTotal}
                    value={inputs.totalUnits === 0 ? '0' : inputs.totalUnits}
                    label={'Total Available Units*'}
                    name="totalUnits"
                    typeError={error === 'totalUnits' ? `Available units ${ErrorText.isRequired}` : ''}
                />
                {error !== 'totalUnits' && <p className={classes.maxList}>Max 10,000</p>}

                <div className="frequency-wrapper">
                    <p className="frequency-title">Set Frequency Limits</p>
                    {frequencyList.map((i, j) => (
                        <div className="frequency-box">
                            <FormControlLabel
                                key={j}
                                onChange={changeLimitMode}
                                name="mode"
                                value={i?.value}
                                label={<span
                                    style={{
                                        fontWeight: frequency?.mode === i?.value ? 600 : 400,
                                        color: error === 'mode' ? '#F07379' : '#172B4D',
                                    }}>{i?.title}</span>}
                                control={
                                    <Radio
                                        style={{
                                            color: frequency?.mode === i?.value ? '#347AF0' : '#D0D5DD',
                                        }}
                                        checked={frequency?.mode === i?.value}
                                    />
                                }
                            />
                            {i?.count && frequency?.mode === i?.value && frequency?.mode !== frequencyEnumsConvert?.ENTIRE_TERM &&
                                <>
                                    <p>Enter Unit Limit</p>
                                    <CustomNumericFormat
                                        name={'limit'}
                                        value={frequency?.limit}
                                        handleChangeMiles={changeLimit}
                                        thousandSeparator={false}
                                        styles={{ borderColor: error === 'limit' ? '#F07379' : '#D0D5DD' }}
                                    />
                                </>
                            }
                        </div>
                    ))}

                    <ErrMessage
                        text={
                            error === 'mode' ? `Frequency mode ${ErrorText.isRequired}` :
                                error === 'limit' ? `Frequency limit ${ErrorText.isRequired}` :
                                    ''}
                    />
                </div>
            </div>

            <CreateChancel
                loader={!!loader.length}
                create={!!info ? 'Save' : 'Add'}
                chancel={'Cancel'}
                onCreate={handleCreate}
                onClose={close}
                buttonWidth="100%"
            />

        </div>
    );
};
