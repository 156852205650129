import React from 'react';
import { useHistory } from 'react-router-dom';
import { breadcrumbsStyle } from './styles';
import { Breadcrumbs, Typography, Link } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeCapitalize } from 'utils';

export const CustomBreadcrumbs = ({ parent, parentLink, child, className }) => {
    const classes = breadcrumbsStyle();
    const history = useHistory();
    const info = history?.location?.state;

    const handleClick = (event) => {
        event.preventDefault();
        if (parentLink === '/claims') {
            history.push(parentLink, { tabType: info?.tabType });
        } else {
            history.push(parentLink);
        }
    };

    return (
        <div>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
            >
                <Link className={classes.parent} href={parentLink} onClick={handleClick}>
                    {parent}
                </Link>
                <Typography className={classes.child}>{makeCapitalize(child)}</Typography>
            </Breadcrumbs>
        </div>
    );
};
