import React from "react";
import {Button} from "@material-ui/core";
import {Colors, useGlobalTextStyles} from "utils";
import {modalsStyle} from "./styles";
import {MinLoader} from "../loader";

export const DeleteElement = ({
                                  text,
                                  info,
                                  handleDel,
                                  handleClose,
                                  loader,
                                  innerText = "",
                                  body,
                                  align,
                              }) => {
    const classes = modalsStyle();
    const globalText = useGlobalTextStyles();
    return (
        <div className={classes.deleteModal}>
            <div
                style={align ? {textAlign:align} : {}}
                className={classes.deleteModalWrapper}>
                <p className={globalText.modalTitle}>{info}</p>
                <p className={globalText.modalText}>{text}</p>

                {body && body}
                <div className={classes.buttons}>

                    <button className="close-btn-box" onClick={handleClose}>
                        Cancel
                    </button>
                    <button className="delete-btn-box" onClick={handleDel}>
                        {loader === true ? (
                            <MinLoader margin={"0"} color={Colors.TextWhite} />
                        ) : (
                            innerText || "Delete"
                        )}
                    </button>

                    {/*<Button*/}
                    {/*    style={{*/}
                    {/*        textTransform: "capitalize",*/}
                    {/*        width: "100%",*/}
                    {/*        height: "48px",*/}
                    {/*        background: Colors.ThemeGray,*/}
                    {/*        borderRadius: "8px",*/}
                    {/*        fontSize: "16px",*/}
                    {/*        fontWeight: "600",*/}
                    {/*        color: Colors.TextPrimary,*/}
                    {/*    }}*/}
                    {/*    onClick={handleClose}*/}
                    {/*>*/}
                    {/*    Cancel*/}
                    {/*</Button>*/}
                    {/*<Button*/}
                    {/*    style={{*/}
                    {/*        textTransform: "capitalize",*/}
                    {/*        width: "100%",*/}
                    {/*        height: "48px",*/}
                    {/*        background: Colors.ThemeRed,*/}
                    {/*        borderRadius: "8px",*/}
                    {/*        marginLeft: "16px",*/}
                    {/*        fontSize: "16px",*/}
                    {/*        fontWeight: "600",*/}
                    {/*        color: Colors.TextWhite,*/}
                    {/*    }}*/}
                    {/*    onClick={handleDel}*/}
                    {/*>*/}
                    {/*    {loader === true ? (*/}
                    {/*        <MinLoader margin={"0"} color={Colors.TextWhite} />*/}
                    {/*    ) : (*/}
                    {/*        innerText || "Delete"*/}
                    {/*    )}*/}
                    {/*</Button>*/}
                </div>
            </div>
        </div>
    );
};
