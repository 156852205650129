import { PasswordValidator } from '../../../utils';

export const checkPassword = [
    {
        type: 'password',
        message: 'Must be at least 8 characters long',
        regex: /.{8,}$/,
    },

    {
        type: 'password',
        message: 'Include at least one uppercase letter (A-Z)',
        regex: /(?=.*[A-Z])/,
    },

    {
        type: 'password',
        message: 'Include at least one lowercase letter (a-z)',
        regex: /(?=.*[a-z])/,
    },

    {
        type: 'password',
        message: 'Include at least one number (0-9)',
        regex: /(?=.*\d)/,
    },
    {
        type: 'password',
        message: 'Include at least one special character (e.g., !@#$%^&*)',
        regex: /(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/,
    },
];