import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { CreateChancel, ToolInfo, SelectAutocompleteMultiple, Input, SwitchCustomized } from 'components';
import { fundingSourceActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import { enumValues, ErrorText, FindLoad, FindSuccessItem, useModal } from 'utils';
import { foundingSourceModalStyle } from './styles';

export const FundingSourceServiceAdd = ({ info }) => {
    const { serviceTypes } = useSelector((state) => state.system.services);
    const fsSource = useSelector((state) => state.fundingSource.fundingSourceItem);
    const ACTION_TYPE = !!info ? 'EDIT_FUNDING_SOURCE_SERVICE' : 'CREATE_FUNDING_SOURCE_SERVICE_BY_ID';
    const classes = foundingSourceModalStyle();
    const dispatch = useDispatch();
    const success = FindSuccessItem(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const history = useHistory();
    const pushInfo = history?.location?.state;
    const { close } = useModal();
    const { handleSubmit, control, clearErrors, watch, reset } = useForm({ trim: true });
    const currentService = watch('serviceTypeId');

    useEffect(() => {
        return () => {
            clearErrors();
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        };
    }, []);

    useEffect(() => {
        if (success) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    useEffect(() => {
        const result = {
            ...info,
        };
        reset(result);
    }, [info]);

    function handleCreate(data) {
        const serviceIdKey = !!info ? 'globServiceId' : 'serviceTypeId';
        const result = _.omitBy(data, value => (typeof value === 'undefined')
            || (typeof value === 'string' && !value?.trim())
            || (typeof value === 'object' && _.isEmpty(value)),
        );

        const sandedData = {
            ...result,
            unitSize: +result.unitSize,
            min: +result.min,
            max: +result.max,
            rate: 0,
            name: result?.serviceTypeId?.name,
            [serviceIdKey]: result?.serviceTypeId?.id || result?.serviceTypeId?._id,
        };

        const sendInfo = {
            ...pushInfo,
        };
        sendInfo.active = (typeof (pushInfo?.active) === 'boolean' ? pushInfo?.active : true);

        delete sendInfo.page;
        delete sendInfo.activeTab;
        delete sendInfo.limit;
        delete sendInfo.skip;

        if (!!info) {
            dispatch(fundingSourceActions.editFoundingSourceServiceById(info?.id, sandedData, sendInfo));
        } else {
            dispatch(fundingSourceActions.createFoundingSourceServiceById(fsSource?.id, sandedData, sendInfo));
        }
    }

    return (
        <div style={{ width: 400 }}>
            <p className="modal-header-title">{info ? 'Edit Service' : 'Add a New Service'}</p>
            <form onSubmit={handleSubmit(handleCreate)}>
                <div className="modal-body-wrapper">
                    <SelectAutocompleteMultiple
                        options={serviceTypes}
                        label={'Service*'}
                        name="serviceTypeId"
                        control={control}
                        rules={{
                            required: 'Please select at least one service.',
                        }}
                        renderValue={(option) => option?.name}
                        fullValue={true}
                    />

                    <div className={classes.displayCodeBlock} style={{ height: 'fit-content', padding: '12px 16px' }}>
                        <p className={classes.displayCodeBlockText}>
                            Display Code:{' '}
                            <span className={classes.displayCode}>
                               {currentService?.displayCode ? currentService?.displayCode : 'N/A'}
                            </span>
                        </p>
                        <p
                            className={classes.displayCodeBlockText}
                            style={{ marginTop: 16 }}
                        >
                            Category:{' '}
                            <span className={classes.displayCode}>
                               {currentService?.category ? currentService?.category : 'N/A'}
                            </span>
                        </p>
                    </div>
                    <Input
                        name="cptCode"
                        label="CPT Code*"
                        variant="outlined"
                        control={control}
                        maxLength={15}
                        rules={{
                            maxLength: {
                                value: 15,
                                message: 'Max length is 15 digits.',
                            },
                            required: 'CPT Code is required.',
                            validate: (value) => {
                                return !!value.trim() || 'CPT Code is required';
                            },
                        }}
                    />
                    <SelectAutocompleteMultiple
                        options={enumValues.FUNDING_MODIFIER_SERVICE_TYPES}
                        label={'Type'}
                        name={'type'}
                        control={control}
                    />
                    <Input
                        variant={'outlined'}
                        type={'number'}
                        control={control}
                        label={'Unit Size (Minutes)*'}
                        name={'unitSize'}
                        maxLength={3}
                        rules={{
                            required: 'Unit size is required',
                            maxLength: {
                                value: 3,
                                message: 'Max length is 3',
                            },
                        }}
                    />
                    <div className={classes.foundingSourceModalsBodyBlock} style={{ gap: '16px' }}>
                        <Input
                            name={'min'}
                            variant={'outlined'}
                            type={'number'}
                            label={'Min Unit*'}
                            control={control}
                            rules={{
                                required: 'Min unit is required',
                                validate: {
                                    positive: v => parseInt(v) > 0 || 'Should be greater than 0',
                                    validateNumber: (_, formValues) => {
                                        if (formValues.max) return +formValues.min < +formValues.max || ErrorText.equalityError('Min Unit', 'Max Unit');
                                    },
                                },
                            }
                            }
                        />
                        <Input
                            name={'max'}
                            variant={'outlined'}
                            type={'number'}
                            label={'Max Unit*'}
                            control={control}
                            rules={{
                                required: 'Max unit is required',
                                validate: {
                                    positive: v => parseInt(v) > 0 || 'Should be greater than 0',
                                },
                            }
                            }
                        />
                    </div>
                    <div className={classes.switchesWrapper}>
                        <div className={classes.requireWrapper}>
                            <ToolInfo
                                text={'If this toggle is on, all appointments that use this service will by default require signature upload before rendering the session'} />
                            <p className={classes.requireText}>Require Signature</p>
                            <SwitchCustomized
                                name="requireSignature"
                                control={control}
                            />
                        </div>

                        <div className={classes.requireWrapper}>
                            <ToolInfo
                                text={'If this toggle is on, all appointments that use this service will by default require signature at least one session note before rendering the session'} />
                            <p className={classes.requireText}>Require Notes</p>
                            <SwitchCustomized
                                name="requireNotes"
                                control={control}
                            />
                        </div>
                        {info?.id &&
                            <div className={classes.requireWrapper}>
                                <ToolInfo text={
                                    !watch('active') ?
                                        'Inactive services cannot be used to create new appointments.'
                                        :
                                        'Active services can be used to create new appointments.'
                                } />
                                <p className={classes.requireText}>Service Status</p>
                                <SwitchCustomized
                                    name="active"
                                    control={control}
                                />
                            </div>
                        }
                    </div>
                </div>
                <CreateChancel
                    type="submit"
                    loader={!!loader.length}
                    create={info ? 'Save' : 'Add'}
                    chancel={'Cancel'}
                    onClose={close}
                    buttonWidth={'100%'}
                />
            </form>
        </div>
    );
};