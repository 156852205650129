import React from 'react';
import { DateTypeSelector } from '../inputs';

export const SearchHistory = ({ }) => {

    return (
        <div className="flex-align-center" style={{ gap: '16px' }}>
            <DateTypeSelector
                startName={'start'}
                endName={'end'}
                buttonStyles={{margin: 0}}
            />
        </div>
    );
};