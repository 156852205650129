import {
    GET_COMPANY_SUCCESS, GET_CONNECT_CALENDARS_SUCCESS,
    GET_CREDENTIAL_GLOBAL_SUCCESS,
    GET_DEPARTMENTS_SUCCESS,
    GET_JOBS_SUCCESS, GET_OFFICE_ALLY_SUCCESS,
    GET_PLACES_SUCCESS,
    GET_SERVICES_SUCCESS, REMOVE_SELECTED_CREDENTIAL, SELECTED_CREDENTIAL,
} from './system.type';

const initialState = {
    credentials: [],
    services: [],
    departments: [],
    jobs: [],
    places: [],
    selectedCredential: null,
    company: null,
    calendars: null,
    officeAlly: null,
};

export const systemReducer = (state = initialState, action) => {
    switch (action.type) {

        /** Service Type */
        case GET_SERVICES_SUCCESS:
            return {
                ...state,
                services: action.payload.services,
            };
        /** End */

        /** Credentials */
        case GET_CREDENTIAL_GLOBAL_SUCCESS:
            return {
                ...state,
                credentials: action.payload,
            };

        case SELECTED_CREDENTIAL:
            return {
                ...state,
                selectedCredential: action.payload,
            };

        case REMOVE_SELECTED_CREDENTIAL:
            return {
                ...state,
                selectedCredential: null,
            };

        /** End */

        /** Departments */
        case GET_DEPARTMENTS_SUCCESS:
            return {
                ...state,
                departments: action.payload,
            };
        /** End */

        /** Job */
        case GET_JOBS_SUCCESS:
            return {
                ...state,
                jobs: action.payload,
            };
        /** End */

        /** Place */
        case GET_PLACES_SUCCESS:
            return {
                ...state,
                places: action.payload,
            };
        /** End */

        /** Company */
        case GET_COMPANY_SUCCESS:
            return {
                ...state,
                company: action.payload,
            };
        /** End */

        /** Calendars */
        case GET_CONNECT_CALENDARS_SUCCESS:
            return {
                ...state,
                calendars: action.payload,
            };
        /** End */

        /** Office Ally */
        case GET_OFFICE_ALLY_SUCCESS:
            return {
                ...state,
                officeAlly: action.payload,
            };
        /** End */

        default:
            return state;
    }
};
