import { makeStyles } from '@material-ui/core/styles';
import { Colors } from 'utils';

export const createClientStyle = makeStyles(() => ({
    editTitle: {
        color: '#4B5C68',
        fontSize: '32px',
        fontWeight: '700',
        lineHeight: '43px',
        marginBottom: '40px',
    },
    editSubTitle: {
        color: '#4B5C68',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
        margin: '16px 0 40px',
    },
    alignItem: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            marginLeft: '16px',
        },
    },
    createFoundingSource: {
        width: 543,
        background: Colors.BackgroundWhite,
        borderRadius: '8px',
        overflow: 'hidden',
    },
    createFoundingSourceBig: {
        width: 1004,
        height: '500px',
        background: Colors.BackgroundWhite,
        borderRadius: '8px',
        overflow: 'hidden',
    },
    createFoundingSourceHeader: {
        width: '100%',
        background: Colors.BackgroundPrimary,
        padding: '8px 0 24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    createFoundingSourceHeaderTop: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: '8px',
        marginBottom: '8px',
    },
    createFoundingSourceBody: {
        width: '100%',
        padding: '40px',
        '@media (max-width: 1280px)': { padding: '32px' },
    },
    modalBody: {
        width: '100%',
        // padding: "40px",
        // "@media (max-width: 1280px)": {padding: "32px"},
    },
    clientModalBlock: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: '40px',
    },
    clientModalBox: {
        // maxWidth: "479px",
        width: '100%',
        '&.hidden': { display: 'none' },
    },
    inputInfo: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 16,
    },
    displayCodeBlock: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '12px 16px',
        background: Colors.BackgroundPrimary,
        margin: '0 0 16px 0',
        borderRadius: '4px',
    },
    displayCodeBlock2: {
        width: '100%',
        padding: '16px',
        boxShadow: '0px 0px 6px #347AF03D',
        border: `1px solid transparent`,
        borderRadius: '4px',
        '&.error': { border: `1px solid ${Colors.ThemeRed}` },
    },
    displayCodeBlockText: {
        color: Colors.TextPrimary,
        fontSize: 14,
        fontWeight: 600,
    },
    availableModfiers: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        '&.notApplicable': { justifyContent: 'center' },
        marginTop: '16px',
    },
    modifiersStyle: {
        margin: '4px',
        borderRadius: 14,
        padding: '4px 16px',
        background: '#347AF080',
        color: '#fff',
        border: 'none',
        cursor: 'default',
    },
    modifierNamesStyle: {
        width: 19,
        height: 20,
        overflow: 'hidden',
    },
    availableModfier: {
        margin: '4px',
        border: '1px solid #4B5C6880',
        borderRadius: 14,
        padding: '4px 16px',
        cursor: 'pointer',
        background: 'white',
        '&.checked': {
            backgroundColor: '#347AF0',
            color: '#fff',
            border: '1px solid #347AF0',
        },
        '&.chosen': {
            backgroundColor: '#347AF080',
            cursor: 'default',
        },
        '&.hidden': { display: 'none' },
        '&:disabled': {
            backgroundColor: '#347AF080',
            color: '#fff',
        },
    },
    loaderBoxStyle: {
        width:'100%',
        justifySelf: 'center',
        height: '36px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    displayCode: {
        color: '#4B5C68B3',
    },
    authorizationFileForm: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    curently: {
        color: Colors.TextPrimary,
        fontSize: 16,
        marginLeft: 10,
    },
    curentlyCheckbox: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 16,
    },
    serviceModifiersContainerStyle: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'inherit',
    },
    notApplicableStyle: {
        marginLeft: '8px',
        height: '36px',
        display: 'flex',
        alignItems: 'center',
        '&.hidden': { display: 'none' },
    },
    employmentModalStyle: {
        backgroundColor: Colors.BackgroundWhite,
        textAlign: 'left',
        '& p': { textAlign: 'left' },
        '& button': { backgroundColor: Colors.BackgroundWater },
        paddingBottom: '0',
    },
    modalHeader: {
        padding: '40px 40px 0 40px',
        '@media (max-width: 1280px)': { padding: '32px 32px 0 32px' },
    },
    title: {
        fontWeight: '700',
        fontSize: '32px',
        lineHeight: '43px',
        color: '#4B5C68',
    },
    subTitle: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#4B5C68',
        marginTop: '16px',
    },
    tHead: {
        height: '36px',
        background: '#EBF2FD',
        width: '100%',
        borderRadius: '8px',
        padding: '0 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& div': {
            width: '20%',
            fontWeight: '700',
            fontSize: '14px',
            lineHeight: '19px',
            padding: '0 8px',
            color: '#4B5C68',
        },
    },
    tBody: {
        height: '300px',
        overflow: 'auto',
    },
    tBodyItem: {
        display:'flex',
        justifyContent:'space-between',
        height: '36px',
        background: '#F2F4F8',
        width: '100%',
        borderRadius: '8px',
        padding: '0 16px',
        alignItems: 'center',
        marginTop: '4px',
    },
    item: {
        width: '20%',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '19px',
        color: '#4B5C68',
        padding:'0 8px',
    },
    addEnrollment: {
        width: '450px',
    },
    maxList: {
        color: '#4B5C68',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '17px',
        margin: '-20px 0px 0 14px',
        height: '20px',
    },
}));
