import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DeleteButton, TextRow, CustomDelete, PaginationFillTable, AddButton, AddCircleBlue } from 'components';
import { Images, PermissionList, RolePermission, useModal, SendPageSave } from 'utils';
import { roleActions } from 'store';
import { managementFragments } from './style';
import { AddRoleModal } from './addRoleModal';
import { CreateCredential } from '../../system/core/credentials/createCredential';

const DELETE_ACTION = 'DELETE_ROLE';
export const Role = ({

                         roleInfo,

                         rolesCount,
                         info,
                         afterSuccess,
                     }) => {
    const classes = managementFragments();
    const history = useHistory();
    const dispatch = useDispatch();
    const { open } = useModal();
    const [activeRole, setActiveRole] = useState('');
    const [page, setPage] = useState(1);

    useEffect(() => {
        setActiveRole('');
    }, []);

    const handleDelete = (e, item) => {
        e.preventDefault();
        e.stopPropagation();
        open(
            <CustomDelete
                text={`This action is not reversible.`}
                info="Are you sure you want to delete this role?"
                handleDel={() => dispatch(roleActions.deleteRole(item?.id))}
                handleClose={() => close()}
                actionType={DELETE_ACTION}
                afterSuccess={afterSuccess}
            />,
        );
    };

    const changePage = (number) => {
        setPage(number);
        SendPageSave(number, info, history, location);
    };

    const openRolePermission = (role) => {
        dispatch(roleActions.openRole(role));
        setActiveRole(role.title);
    };

    return (
        <>
            <div className={classes.tableStyle}>
                <div className={classes.roleListHeaderBox}>
                    <div className={classes.roleListHeader}>
                        <p className={classes.roleHeaderTitle}>Role</p>
                        <div>
                            <AddCircleBlue text={'Add Role'} handleClick={() => open(<AddRoleModal />)} />
                        </div>
                    </div>
                </div>

                {/*<div>*/}
                {/*    <div className={classes.tableHeadStyle}>*/}
                {/*        <p>Role</p>*/}
                {/*    </div>*/}
                {/*    {RolePermission([PermissionList.ROLES_CREATE?.code]) &&*/}
                {/*        <AddButton*/}
                {/*            text={'Add Role'}*/}
                {/*            handleClick={() => open(<AddRoleModal />)}*/}
                {/*        />*/}
                {/*    }*/}
                {/*</div>*/}

                <div className={classes.scroll}>
                    <div style={{ height: '455px', overflow: 'auto' }}>
                        {!!roleInfo?.length && (
                            roleInfo.map((item, j) => (
                                <div
                                    onClick={() => openRolePermission(item)}
                                    key={j}
                                    className={activeRole === item.title ? classes.tableBodyBottomActive : classes.tableBodyBottom}
                                >
                                    <div className={classes.tableBodyStyle}>
                                        <div className="full-width">
                                            <img
                                                src={
                                                    activeRole === item.title ?
                                                        Images.accessManagementOutline
                                                        :
                                                        Images.accessManagementOutlineGray
                                                }
                                                alt={'accessManagementUser'}
                                            />
                                            <p className={classes.roleTitle}>
                                                <TextRow name={item?.title} />
                                            </p>
                                        </div>
                                        {RolePermission([PermissionList.ROLES_CREATE?.code]) &&
                                            <div className="flex-align-center">
                                                <button
                                                    onClick={() => open(<AddRoleModal item={item} />)}
                                                    className={classes.editBtn}
                                                >
                                                    <img src={Images.edit} alt="icon" />
                                                </button>

                                                <DeleteButton
                                                    toolTipTitle={'Remove Role'}
                                                    handleClick={(e) => handleDelete(e, item)}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            ))
                        )}
                    </div>

                    <div>
                        {rolesCount > 0 &&
                            <div style={{ height: '76px' }}>
                                <PaginationFillTable
                                    hideGoTo={true}
                                    listLength={roleInfo?.length}
                                    page={page}
                                    handleReturn={(number) => changePage(number)}
                                    count={rolesCount}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};
