import { makeStyles } from '@material-ui/core/styles';
import { Backgrounds, Colors } from 'utils';

export const roleStyles = makeStyles(() => ({
    roleWrapper: {
        width: '100%',
    },

    roleItemContainer: {
        maxHeight: '50vh',
        overflow: 'auto',
    },

    noItem: {
        fontSize: 18,
        color: Colors.TextLightGray,
        fontWeight: 'bold',
        position: 'relative',
        marginLeft: '5px',
    },

    roleItemHover: {
        backgroundColor: Colors.BackgroundCatskillWhite,
        borderRadius: 8,
        marginBottom: '8px',
        border: '1px solid #F2F4F8',

    },

    roleItemActive: {
        backgroundColor: Backgrounds.whiteModal,
        borderRadius: 8,
        marginBottom: '8px',
        border: `1px solid ${Colors.BackgroundCatskillWhite}`,

        '& button': {
            background: 'transparent',
            border: 'none',
        },

        '& :hover': {
            backgroundColor: Colors.BackgroundCatskillWhite,
            border: '1px solid #A3B2BD80',
            borderRadius: 8,
            '& p': {
                border: 'none',
            },
            '& img': {
                border: 'none',
            },
            '& div': {
                border: 'none',
            },
            '& button': {
                border: 'none',
            },
            '& span': {
                border: 'none',
            },
        },

    },

    roleItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '12px 16px',
        cursor: 'pointer',
        border: `1px solid ${Colors.BackgroundCatskillWhite}`,
        borderRadius: 8,
        '& div': {
            display: 'flex',
            alignItems: 'center',
        },
        '&:not(:last-child)': {
            marginBottom: 8,
        },
    },
    roleItemName: {
        fontSize: 14,
        color: Colors.TextSecondary,
        fontWeight: 600,
        paddingLeft: 8,
    },
    removeIcon: {
        cursor: 'pointer',
        border: 'none',
        background: 'none',
    },
    editButton: {
        background: 'transparent',
        border: 'none',
        padding: 0,
        marginRight: '16px',

    },
    name: {
        fontSize: '14px',
        lineHeight: '22px',
        fontWeight: '600',
        color: Colors.TextSecondary,
        marginLeft: '12px',
        width: '140px',
        minWidth: '140px',
    },
    description: {
        fontSize: '14px',
        lineHeight: '22px',
        fontWeight: '400',
        color: Colors.TextSecondary,
        marginLeft: '12px',
        width: '100%',
    },
}));
