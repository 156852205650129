import {
    CREATE_PAY_CODE_GLOBAL,
    GET_PAY_CODE_GLOBAL,
    EDIT_PAY_CODE_BY_ID_GLOBAL,
    DELETE_PAY_CODE_BY_ID_GLOBAL,
    CREATE_OVERTIME_SETTINGS_GLOBAL,
    GET_OVERTIME_SETTINGS_GLOBAL,
    EDIT_OVERTIME_SETTINGS_BY_ID_GLOBAL,
    DELETE_OVERTIME_SETTINGS_BY_ID_GLOBAL,
    CREATE_MILEAGE,
    EDIT_MILEAGE,
    GET_MILEAGES,
    DELETE_MILEAGE,
    TERMINATE_MILEAGE,
} from "./payroll.type";

/** PayCode types */

export const createPayCodeGlobal = (body) => {
    return {
        type: CREATE_PAY_CODE_GLOBAL,
        payload: {body}
    }
}

export const getPayCodeGlobal = (params) => {
    return {
        type: GET_PAY_CODE_GLOBAL,
        payload: {params}
    }
}

export const editPayCodeByIdGlobal = (body, id) => {
    return {
        type: EDIT_PAY_CODE_BY_ID_GLOBAL,
        payload: {body, id}
    }
}

export const deletePayCodeByIdGlobal = (id) => {
    return {
        type: DELETE_PAY_CODE_BY_ID_GLOBAL,
        payload: {id}
    }
}

/** End */

/** Overtime Settings */

export const createOvertimeSettingsGlobal = (body) => {
    return {
        type: CREATE_OVERTIME_SETTINGS_GLOBAL,
        payload: {body}
    }
}

export const getOvertimeSettingsGlobal = (params) => {
    return {
        type: GET_OVERTIME_SETTINGS_GLOBAL,
        payload: {params}
    }
}

export const editOvertimeSettingsByIdGlobal = (body, id) => {
    return {
        type: EDIT_OVERTIME_SETTINGS_BY_ID_GLOBAL,
        payload: {body, id}
    }
}

export const deleteOvertimeSettingsByIdGlobal = (id) => {
    return {
        type: DELETE_OVERTIME_SETTINGS_BY_ID_GLOBAL,
        payload: {id}
    }
}

/** End */

/** Mileage Compensation*/

export const createMileage = (body) => {
    return {
        type: CREATE_MILEAGE,
        payload: {body},
    };
};

export const editMileage = (id, body) => {
    return {
        type: EDIT_MILEAGE,
        payload: {id, body},
    };
};

export const getMileages = () => {
    return {
        type: GET_MILEAGES,
    };
};

export const deleteMileage = (id) => {
    return {
        type: DELETE_MILEAGE,
        payload: {id},
    };
};

export const terminateMileage = (mileageId) => {
    return {
        type: TERMINATE_MILEAGE,
        payload: {mileageId},
    };
};

/** End */