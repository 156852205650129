import { v4 as uuidv4 } from 'uuid';
import { activeInactiveEnums, clientStatuses, enumValues } from 'utils';
import { CLIENT_ACTION, INVOICE_STATUSES, renderClientName, renderStaffName } from './constants';

export const utilizationReport = () => {
    return {
        title: 'Authorization Utilization Report',
        url: '/reports/billing/auth/utilization',
        list:
            [
                {
                    type: 'selectMultiple',
                    name: 'clientIds',
                    label: 'Client',
                    loadType: CLIENT_ACTION,
                    renderValue: (option) => renderClientName(option),
                },
                {
                    type: 'selectMultiple',
                    name: 'clientStatuses',
                    label: 'Client Status',
                    renderValue: (option) => option?.name,
                    selectList: [
                        ...clientStatuses,
                    ],
                },
                {
                    type: 'selectMultiple',
                    name: 'payers',
                    label: 'Payer',
                    loadType: 'GET_FUNDING_SOURCE',
                    renderValue: (option) => option?.name,
                },
                {
                    type: 'dos',
                    startDate: 'authServiceStartDate',
                    endDate: 'authServiceEndDate',
                    startLabel: 'Auth. Start Date',
                    endLabel: 'Auth. End Date',
                },
            ],
    };
};
export const authorizationServicesSnapshotReport = () => {
    return {
        title: 'Authorization Utilization by Service Report',
        url: '/reports/billing/auth/utilizationByService',
        list:
            [
                {
                    type: 'selectMultiple',
                    name: 'payers',
                    label: 'Payer',
                    loadType: 'GET_FUNDING_SOURCE',
                    renderValue: (option) => option?.name,
                },
                {
                    type: 'selectMultiple',
                    name: 'clientIds',
                    label: 'Client',
                    loadType: CLIENT_ACTION,
                    renderValue: (option) => renderClientName(option),
                },
                {
                    type: 'selectMultiple',
                    name: 'clientStatuses',
                    label: 'Client Status',
                    renderValue: (option) => option?.name,
                    selectList: [
                        ...clientStatuses,
                    ],
                },
                {
                    type: 'dos',
                    startDate: 'authServiceStartDate',
                    endDate: 'authServiceEndDate',
                    startLabel: 'Auth. Start Date',
                    endLabel: 'Auth. End Date',
                },
            ],
    };
};

export const payerServiceReport = () => {
    return {
        title: 'Payer Services Snapshot',
        url: '/reports/billing/payer/services',
        list:
            [
                {
                    type: 'select',
                    name: 'payer',
                    label: 'Payer',
                    loadType: 'GET_FUNDING_SOURCE',
                    renderValue: (option) => option?.name,
                },
                {
                    type: 'select',
                    name: 'serviceStatus',
                    label: 'Service Status',
                    selectList: activeInactiveEnums,
                    renderValue: (option) => option?.name,
                },
                {
                    type: 'select',
                    name: 'serviceType',
                    label: 'Service Type',
                    selectList: [
                        { id: 'DIRECT', name: 'Direct' },
                        { id: 'INDIRECT', name: 'Indirect' },
                    ],
                    renderValue: (option) => option?.name,
                },
            ],
    };
};

export const payerInfoReport = () => {
    return {
        title: 'Payer Info Details',
        url: '/reports/billing/payer/details',
        list:
            [
                {
                    type: 'select',
                    name: 'payer',
                    label: 'Payer',
                    loadType: 'GET_FUNDING_SOURCE',
                    renderValue: (option) => option?.name,
                },
                {
                    type: 'select',
                    name: 'serviceStatus',
                    label: 'Payer Status',
                    selectList: activeInactiveEnums,
                    renderValue: (option) => option?.name,
                },
                {
                    type: 'select',
                    name: 'type',
                    label: 'Payer Type',
                    selectList: enumValues.FUNDING_SOURCE_TYPES,
                    renderValue: (option) => option?.label,
                },
            ],
    };
};

export const clientBillingReport = () => {
    return {
        title: 'Client Billing Summary',
        url: '/reports/billing/summary/client',
        list:
            [
                {
                    type: 'select',
                    name: 'client',
                    label: 'Client',
                    loadType: CLIENT_ACTION,
                    renderValue: (option) => renderClientName(option),
                },

                {
                    type: 'select',
                    name: 'clientStatus',
                    label: 'Client Status',
                    renderValue: (option) => option?.name,
                    selectList: [
                        ...clientStatuses,
                    ],
                },

                {
                    type: 'dos',
                    startDate: 'from',
                    endDate: 'to',
                    startLabel: 'Invoice Start Date',
                    endLabel: 'Invoice End Date',
                },
                {
                    type: 'select',
                    name: 'status',
                    label: 'Invoice Status',
                    renderValue: (option) => option?.name,
                    selectList: INVOICE_STATUSES
                },
                {
                    type: 'select',
                    name: 'agingType',
                    label: 'Aging Bucket',
                    renderValue: (option) => option?.name,
                    selectList: [
                        { name: 'Current', id: 'CURRENT' },
                        { name: 'Due', id: 'DUE' },
                        { name: 'Past Due', id: 'PAST_DUE' },
                    ],
                },
            ],
    };
};

export const billingSummaryReport = () => {
    return {
        title: 'Client Billing Summary',
        url: '/reports/billing/summary',
        list:
            [

                {
                    type: 'dos',
                    startDate: 'from',
                    endDate: 'to',
                    startLabel: 'Start Date',
                    endLabel: 'End Date',
                },
                {
                    type: 'select',
                    name: 'payer',
                    label: 'Payer',
                    loadType: 'GET_FUNDING_SOURCE',
                    renderValue: (option) => option?.name,
                },
                {
                    type: 'select',
                    name: 'serviceType',
                    label: 'Service Type',
                    selectList: [
                        { id: 'DIRECT', name: 'Direct' },
                        { id: 'INDIRECT', name: 'Indirect' },
                    ],
                    renderValue: (option) => option?.name,
                },
            ],
    };
};

export const billingStatusReport = () => {
    return {
        title: 'Claims Status Report',
        url: '/reports/billing/claim/status',
        list:
            [
                {
                    type: 'select',
                    name: 'status',
                    label: 'Claim Status',
                    renderValue: (option) => option?.name,
                    selectList: [
                        { name: 'Pending', id: 'PENDING' },
                        { name: 'Open', id: 'OPEN' },
                        { name: 'Paid', id: 'PAID' },
                        { name: 'Appeal', id: 'APPEAL' },
                        { name: 'Closed', id: 'CLOSED' },
                        { name: 'Completed', id: 'COMPLETED' },
                        { name: 'Submitted', id: 'SUBMITTED' },
                    ],
                },

                {
                    type: 'dos',
                    startDate: 'submittedFrom',
                    endDate: 'submittedTo',
                    startLabel: 'Claim Submitted From',
                    endLabel: 'Claim Submitted To',
                },
                {
                    type: 'dos',
                    startDate: 'generatedFrom',
                    endDate: 'generatedTo',
                    startLabel: 'Claim Generated From',
                    endLabel: 'Claim Generated To',
                },
                {
                    type: 'select',
                    name: 'client',
                    label: 'Client',
                    loadType: CLIENT_ACTION,
                    renderValue: (option) => renderClientName(option),
                },
                {
                    type: 'select',
                    name: 'staff',
                    label: 'Staff',
                    loadType: 'GET_ADMINS',
                    renderValue: (option) => renderStaffName(option),
                },
                {
                    type: 'dos',
                    startDate: 'from',
                    endDate: 'to',
                    startLabel: 'DoS Start',
                    endLabel: 'DoS End',
                },
            ],
    };
};
export const agingReceivablesReport = () => {
    return {
        title: 'Aging Receivables Report',
        url: '/reports/billing/aging/receivables',
        list:
            [
                {
                    type: 'select',
                    name: 'client',
                    label: 'Client',
                    loadType: CLIENT_ACTION,
                    renderValue: (option) => renderClientName(option),
                },

                {
                    type: 'dos',
                    startDate: 'from',
                    endDate: 'to',
                    startLabel: 'Invoice Start Date',
                    endLabel: 'Invoice End Date',
                },
                {
                    type: 'select',
                    name: 'status',
                    label: 'Invoice Status',
                    renderValue: (option) => option?.name,
                    selectList: INVOICE_STATUSES,
                },
                {
                    type: 'select',
                    name: 'agingType',
                    label: 'Aging Bucket',
                    renderValue: (option) => option?.name ,
                    selectList: [
                        { name: 'Current', id: 'CURRENT' },
                        { name: 'Due', id: 'DUE' },
                        { name: 'Past Due', id: 'PAST_DUE' },
                    ],
                },
            ],
    };
};

export const billingReportEnums = () => {

    return [
        {
            id: uuidv4(),
            name: 'Authorization Utilization Report',
            description: 'Client authorizations with utilization of authorized units and hours.',
            generateInfo: utilizationReport(),
        },
        {
            id: uuidv4(),
            name: 'Authorization Utilization by Service Report',
            description: 'In-depth view of authorized services, details, and utilization metrics.',
            generateInfo: authorizationServicesSnapshotReport(),
        },
        {
            id: uuidv4(),
            name: 'Payer Services Snapshot',
            description: 'Snapshot of payer services with codes, statuses, and requirements.',
            generateInfo: payerServiceReport(),
        },
        {
            id: uuidv4(),
            name: 'Payer Info Details',
            description: 'Provides general information on all payers, including contact details, IDs, status, and other key data.',
            generateInfo: payerInfoReport(),
        },
        {
            id: uuidv4(),
            name: 'Client Billing Summary',
            description: 'Client billing summary with charges, payments, balances, and statuses.',
            generateInfo: clientBillingReport(),
        },
        // {
        //     id: uuidv4(),
        //     name: 'Billing Summary Report',
        //     description: 'Provides an aggregated view of billing activities on a weekly basis, showing total amounts billed, payments received, adjustments, and outstanding balances for each week. Useful for tracking revenue trends over time and making informed financial decisions.',
        //     generateInfo: billingSummaryReport(),
        // },

        {
            id: uuidv4(),
            name: 'Claims Status Report',
            description: 'Status of claims including billing information.',
            generateInfo: billingStatusReport(),
        },
        {
            id: uuidv4(),
            name: 'Aging Receivables Report',
            description: 'Invoice payment and aging data by client.',
            generateInfo: agingReceivablesReport(),
        },
    ];
};