import {makeStyles} from "@material-ui/core/styles";
import {Backgrounds, Colors} from "utils";

export const createStaffModalStyle = makeStyles(() => ({
    modalDimensions: {
        width: "543px",
        background: "white",
        position: "relative",
        borderRadius: 8,
        overflow: "hidden",
        "@media (max-width: 1400px)": {
            width: "527px",
        },
    },
    positionedButton: {
        position: "absolute",
        right: "8px",
        top: "8px",
    },
    titleAndSubtitleWrapper: {
        padding: '32px 32px 0',
        "@media (min-width: 1919px)": {
            padding: '40px 40px 0',
        },
    },
    modalTitle: {
        textAlign: "start",
        marginBottom: '16px',
    },
}));

export const stepStyles = makeStyles(() => ({
    stepHeader: {
        background: Backgrounds.headerLightBlue,
        padding: "24px 0 36px 0",
    },
    stepPaddings: {
        padding: "32px",
        "@media (min-width: 1919px)": {
            padding: "40px",
        },
    },
    titleHead: {
        padding: "32px 32px 0",
        "@media (min-width: 1919px)": {
            padding: "40px 40px 0",
        },
    },

    buttonsContainer: {
        marginTop: 0,
    },
}));

export const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: "#ccc",
        zIndex: 1,
        color: "#fff",
        width: 36,
        height: 36,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        "&::after": {
            content: `''`,
            position: "absolute",
            left: "1px",
            top: "1px",
            width: "34px",
            height: "34px",
            backgroundColor: "transparent",
            borderRadius: "50%",
            border: "1px solid white",
        },
    },
    active: {
        backgroundColor: "#347AF0",
    },
    completed: {
        backgroundColor: "#347AF0",
    },
});

export const staffCoreCommonStyle = makeStyles(() => ({
    otherDetailsTitle: {
        fontSize: "16px",
        color: Colors.TextSecondary,
        fontWeight: "600",
        lineHeight: "22px",
        paddingBottom: "18px",
    },
    flexContainer: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
    },
    selectMargin: {
        width: "100%",
        marginRight: "16px",
        "& .MuiFormLabel-root": {
            fontSize: "16px",
            color: `${Colors.TextPrimary}`,
        },
        "& .MuiInput-underline.Mui-error:after": {
            borderBottomColor: `${Colors.ThemeRed}`,
        },
    },
    titlePadding: {paddingTop: "3px"},
    flexAble:{
        display:'flex',
    },
    checkbox:{
        width: '75px',
        minWidth: '75px'
    },
    inputBox:{
        marginLeft:'16px',
        width:'100%'
    }
}));
