/**Create Admins */
export const CREATE_ADMIN = "CREATE_ADMIN";
export const CREATE_ADMIN_DETAILS = "CREATE_ADMIN_DETAILS";

export const REINVITE_ADMIN = "REINVITE_ADMIN";
/**Edit Admin by id */
export const EDIT_ADMIN_BY_ID = "EDIT_ADMIN_BY_ID";
export const UPLOAD_STAFF_AVATAR = "UPLOAD_STAFF_AVATAR";

/**Get Admins */
export const GET_ADMINS = "GET_ADMINS";
export const GET_ADMINS_SUCCESS = "GET_ADMINS_SUCCESS";

/**Get Admins */
export const GET_ALL_ADMINS = "GET_ALL_ADMINS";
export const GET_ALL_ADMINS_SUCCESS = "GET_ALL_ADMINS_SUCCESS";
export const GET_BASIC_ADMINS = "GET_BASIC_ADMINS";
export const GET_BASIC_ADMINS_SUCCESS = "GET_BASIC_ADMINS_SUCCESS";

/**Get Admin by id */
export const GET_ADMIN_BY_ID = "GET_ADMIN_BY_ID";
export const GET_ADMIN_BY_ID_SUCCESS = "GET_ADMIN_BY_ID_SUCCESS";

/**Change Admin Status */
export const CHANGE_ADMIN_STATUS = "CHANGE_ADMIN_STATUS";

/**Create Credential */
export const CREATE_CREDENTIAL = "CREATE_CREDENTIAL";

/**Get Credential by id */
export const GET_CREDENTIAL = "GET_CREDENTIAL";
export const GET_CREDENTIAL_SUCCESS = "GET_CREDENTIAL_SUCCESS";

/**Edit Credential by id */
export const EDIT_CREDENTIAL_BY_ID = "EDIT_CREDENTIAL_BY_ID";

/**Remove Credential by id */
export const DELETE_CREDENTIAL_BY_ID = "DELETE_CREDENTIAL_BY_ID";

/**get employment */
export const GET_EMPLOYMENT = "GET_EMPLOYMENT";
export const GET_EMPLOYMENT_SUCCESS = "GET_EMPLOYMENT_SUCCESS";
export const DELETE_EMPLOYMENT = "DELETE_EMPLOYMENT";
export const CREATE_EMPLOYMENT = "CREATE_EMPLOYMENT";
export const EDIT_EMPLOYMENT = "EDIT_EMPLOYMENT";

/**get Pay Code */
export const GET_PAY_CODE = "GET_PAY_CODE";
export const GET_PAY_CODE_SUCCESS = "GET_PAY_CODE_SUCCESS";

/**create Pay Code */
export const CREATE_PAY_CODE = "CREATE_PAY_CODE";

/**edit Pay Code */
export const EDIT_PAY_CODE = "EDIT_PAY_CODE";

/**terminate PayCode */
export const TERMINATE_PAY_CODE = "TERMINATE_PAY_CODE";

/** create staff service */
export const CREATE_STAFF_SERVICE = "CREATE_STAFF_SERVICE";

/** get staff service */
export const GET_STAFF_SERVICE = "GET_STAFF_SERVICE";
export const GET_STAFF_SERVICE_SUCCESS = "GET_STAFF_SERVICE_SUCCESS";

/** delete staff service */
export const DELETE_STAFF_SERVICE = "DELETE_STAFF_SERVICE";

/** is Clinician */
export const IS_CLINICIAN = "IS_CLINICIAN";

/** get timesheet  */
export const GET_TIMESHEET = "GET_TIMESHEET";
export const GET_TIMESHEET_BY_ID = "GET_TIMESHEET_BY_ID";
export const REMOVE_TIMESHEET_BY_ID = "REMOVE_TIMESHEET_BY_ID";
export const GET_TIMESHEET_BY_ID_SUCCESS = "GET_TIMESHEET_BY_ID_SUCCESS";
export const GET_TIMESHEET_SUCCESS = "GET_TIMESHEET_SUCCESS";

/** create timesheet */
export const CREATE_TIMESHEET = "CREATE_TIMESHEET";

/** edit timesheet */
export const EDIT_TIMESHEET = "EDIT_TIMESHEET";

/** PayCode  */
export const GET_ALL_PAY_CODES = "GET_ALL_PAY_CODES";
export const CLEAR_ALL_PAY_CODES = "CLEAR_ALL_PAY_CODES";
export const GET_ALL_PAY_CODES_SUCCESS = "GET_ALL_PAY_CODES_SUCCESS";
export const GET_ALL_PAY_CODES_FAIL = "GET_ALL_PAY_CODES_FAIL";

/** Staff Documents */
export const UPLOAD_STAFF_DOCUMENT = "UPLOAD_STAFF_DOCUMENT";
export const EDIT_STAFF_DOCUMENT = "EDIT_STAFF_DOCUMENT";
export const GET_STAFF_DOCUMENT = "GET_STAFF_DOCUMENT";
export const GET_STAFF_DOCUMENT_SUCCESS = "GET_STAFF_DOCUMENT_SUCCESS";
export const DELETE_STAFF_DOCUMENT = "DELETE_STAFF_DOCUMENT";


export const GET_FOUNDING_ALL_SERVICES = "GET_FOUNDING_ALL_SERVICES";
export const GET_FOUNDING_ALL_SERVICES_SUCCESS = "GET_FOUNDING_ALL_SERVICES_SUCCESS";
export const GET_STAFF_FOR_APPT = "GET_STAFF_FOR_APPT";
export const GET_STAFF_FOR_APPT_SUCCESS = "GET_STAFF_FOR_APPT_SUCCESS";

