export const privacyPolicyTitle = "Privacy Policy";

export const privacyPolicyParagraphs = [
    `Privacy Policy
    
  This Privacy Policy ("Policy") outlines how THERAPYLAKE LLC ("Company") collects, uses, discloses, and protects the personal information of clients and their patients in connection with the use of the Company's practice management software ("Software").
1. Information Collection
- Personal Information: The Company may collect personal information from clients, including names, contact information, billing information, and any other information necessary for the provision of the Software.
- Patient Information: The Company may also collect information about clients’ patients, including names, contact information, and other relevant health information required for practice management.
- Usage Data: The Company may collect information on how the Software is accessed and used, including device information, IP addresses, and log data.
2. Use of Information
- Provision of Services: The Company uses the collected information to provide, operate, and maintain the Software.
- Billing and Payments: The Company uses personal information to manage billing and payments for the Software.
- Communication: The Company may use contact information to communicate with clients about the Software, including updates, maintenance, and support.
- Improvement: The Company may use usage data to improve the Software and develop new features.
3. Information Sharing and Disclosure
- Third-Party Service Providers: The Company may share information with third-party service providers that perform services on the Company’s behalf, such as payment processing, data storage, and customer support. These service providers are obligated to protect the confidentiality and security of the information.
- Legal Requirements: The Company may disclose information if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).
- Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of the Company's assets, the Company may transfer information to the acquiring entity.
4. Data Security
- Security Measures: The Company implements appropriate administrative, technical, and physical measures to protect the personal information of clients and their patients from unauthorized access, use, or disclosure.
- Data Breach Response: In the event of a data breach, the Company will notify affected clients and take necessary steps to mitigate the impact.
5. Data Retention
- Retention Period: The Company will retain personal information only for as long as necessary to fulfill the purposes outlined in this Policy, unless a longer retention period is required or permitted by law.
- Deletion: Upon termination of the subscription or upon request, the Company will delete personal information in accordance with applicable laws and regulations.
6. Client Rights
- Access and Correction: Clients have the right to access and correct their personal information held by the Company. Requests for access or correction should be directed to info@therapylake.com.
- Data Portability: Clients have the right to request a copy of their personal information in a structured, commonly used, and machine-readable format.
- Withdrawal of Consent: Clients may withdraw their consent to the collection, use, and disclosure of their personal information at any time, subject to legal or contractual restrictions. Withdrawal of consent may affect the Company’s ability to provide the Software.
7. Changes to this Policy
- Updates: The Company may update this Policy from time to time. The Company will notify clients of any significant changes by posting the new Policy on its website and, if applicable, by sending a notification through the Software.
8. Contact Information
- Questions and Concerns: If clients have any questions or concerns about this Policy or the Company's privacy practices, they may contact the Company at info@therapylake.com.
`
];