import React from 'react';
import { Checkbox } from '@material-ui/core';
import { Images, PermissionList, RolePermission } from 'utils';
import {
    DateRow,
    DownloadLink,
    LinkRow,
    SimpleTooltip,
    TextRow,
    ApptFlags, UnitsRow,
} from 'components';
import { NumericFormat } from 'react-number-format';

export const generateClaimHead = (handleSelect, fullList, selected) => {
    return [
        {
            renderView: <SimpleTooltip
                title={<p>Select All</p>}
                placement="top-start"
            >
                <Checkbox
                    onClick={(e) => handleSelect(e, '', 'all')}
                    name={'checkBox'}
                    style={{ padding: 0, color: '#0C66E4' }}
                    checked={fullList?.length === selected?.length}
                />
            </SimpleTooltip>, width: '30px',
        },
        { name: '', title: 'Appt ID', noSearch: true, custom: false },
        { name: '', title: 'CPT Code', width: '100px', noSearch: true, custom: false },
        { name: '', title: 'DOS', icon: 'date', noSearch: true, custom: false },
        { name: '', title: 'Place of Service', noSearch: true, custom: false },
        { name: '', title: 'Funding Source', noSearch: true, custom: false },
        { name: 'client', title: 'Client', noSearch: true },
        { name: '', title: 'Staff', noSearch: true, custom: false },
        { name: '', title: 'Units', noSearch: true, custom: false },
        { name: '', title: 'Signature', noSearch: true, custom: false },
        RolePermission([PermissionList.CLAIMS_UPDATE?.code]) &&
        { name: '', title: ' ', noSearch: true, custom: false, width: '50px' },
    ];
};

function convertToHoursAndMinutes(decimalHours) {
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 60);
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    return `${formattedHours}h ${formattedMinutes}m`;
}

export const generateClaimBody = (handleSelect, fullList, selected, handleEdit) => {
    return [
        {
            button: (handle, item) => (
                <SimpleTooltip
                    title={<p>Select</p>}
                    placement="top-start"
                >
                    <Checkbox
                        onClick={(e) => handleSelect(e, item)}
                        name={'checkBox'}
                        style={{ padding: 0, color: '#0C66E4' }}
                        checked={selected?.includes(item?.id)}
                    />
                </SimpleTooltip>
            ), notClickable: true,
        },
        {
            rowText: (item) => <ApptFlags
                id={item?.displayId}
                fromAppt={true}
                incomplete={item?.incompleteUnitFlag}
                removed={item?.removedFromClaimFlag}
            />,
        },
        {
            rowText: (item) => <TextRow
                name={item?.authorizedService?.service?.cptCode ? item?.authorizedService?.service?.cptCode : 'N/A'} />,
        },
        { rowText: (item) => <div><DateRow date={item?.startDate} /></div> },
        { rowText: (item) => <TextRow name={item?.placeService?.name} /> },
        { rowText: (item) => <TextRow name={item?.payer?.name} /> },
        {
            rowText: (item) => <LinkRow
                client={item?.client}
                href={
                    RolePermission([PermissionList.CLIENT_READ?.code]) ?
                        `/client/${item?.client?.id || item?.client?._id}` : ''}
                checkPermission={item?.client ? RolePermission([PermissionList.CLIENT_READ?.code]) : null}
                textWidth={8}
            />,
        },
        {
            rowText: (item) => <LinkRow
                staff={item?.staff}
                href={RolePermission([PermissionList.STAFF_READ.code]) ? `/staff/${item?.staff?._id}` : ''}
                checkPermission={item?.staff?._id ? RolePermission([PermissionList.STAFF_READ.code]) : null}
            />,
        },
        {
            rowText: (item) => <div className="flex-align-center">
                <NumericFormat value={item?.units ? item?.units.toFixed(2) : 0} displayType={'text'}
                               thousandSeparator={true} />
                {item?.hours && <p style={{
                    whiteSpace: 'nowrap',
                    marginLeft: '4px',
                }}>({convertToHoursAndMinutes(item?.hours)})</p>}
            </div>,
        },
        {
            rowText: (item) => item?.signature?.url ?
                <div style={{ gap: 8 }} className="flex-align-center">
                    <div>
                        <img
                            style={{ objectFit: 'cover', width: 50, height: 28, margin: '-2px 0 -8px 0' }}
                            src={item?.signature?.url} alt="icon" />
                    </div>
                    <div style={{ width: 22, margin: '2px 0 -3px 0' }}>
                        <DownloadLink
                            linkClassName={'reset-download-btn'}
                            downloadLoadStyle={{ margin: 0 }}
                            iconStyles={{ marginLeft: 0 }}
                            small={true}
                            file={item?.signature}
                        />
                    </div>
                    <a href={item?.signature?.url} target={'_blank'} style={{ marginTop: 2 }}>
                        <img src={Images.show} alt={'download'} />
                    </a>
                </div>
                :
                <p>N/A</p>
            , padding: '0',
        },
        RolePermission([PermissionList.CLAIMS_UPDATE?.code]) &&
        {
            rowText: (item) => (
                <button onClick={() => handleEdit(item)} className="reset-btn" style={{ height: '20px' }}>
                    <img src={Images.edit} alt={'edit'} />
                </button>
            ),
        },
    ];
};

export const ACTION_TYPE = 'GET_APPOINTMENT';