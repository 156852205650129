import moment from 'moment/moment';
import { Images } from '../../utils';


export const notificationTypesEnums = {
    CREDENTIALS_EXPIRATION: 'CREDENTIALS_EXPIRATION',
    LICENSE_EXPIRATION: 'LICENSE_EXPIRATION',
    SERVICE_ADD: 'SERVICE_ADD',
    SERVICE_DELETE: 'SERVICE_DELETE',
    APPT_GROUP_CREATE: 'APPT_GROUP_CREATE',
    APPT_CREATE: 'APPT_CREATE',
    APPT_UPDATE: 'APPT_UPDATE',
    APPT_CANCEL: 'APPT_CANCEL',
    APPT_LOCK: 'APPT_LOCK',
    APPT_UNLOCK: 'APPT_UNLOCK',
    APPT_RENDER: 'APPT_RENDER',
    APPT_UNRENDER: 'APPT_UNRENDER'
};


export const renderIcon = (type) => {

    if(type === notificationTypesEnums.CREDENTIALS_EXPIRATION || type === notificationTypesEnums.LICENSE_EXPIRATION){
        return Images.danger
    }
    else if(type === notificationTypesEnums.SERVICE_DELETE){
        return Images.redTrash
    }
    else if(type === notificationTypesEnums.SERVICE_ADD){
        return Images.newServiceAdded
    }
    else if(type === notificationTypesEnums.APPT_CREATE){
        return Images.newApptCreated
    }
    else if(type === notificationTypesEnums.APPT_UPDATE){
        return Images.apptEdited
    }
    else if(type === notificationTypesEnums.APPT_CANCEL){
        return Images.apptCancelled
    }
    else if(type === notificationTypesEnums.APPT_LOCK){
        return Images.lock
    }
    else if(type === notificationTypesEnums.APPT_UNLOCK){
        return Images.unlock
    }
    else if(type === notificationTypesEnums.APPT_RENDER){
        return Images.apptComplete
    }


    else if(type === notificationTypesEnums.APPT_UNRENDER){
        return Images.apptAlert
    }
    else if(type === notificationTypesEnums.APPT_GROUP_CREATE){
        return Images.newApptCreated
    }


    else{
        return Images.newServiceAdded
    }
}