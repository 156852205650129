import {
    GET_MILEAGES_SUCCESS,
    GET_OVERTIME_SETTINGS_GLOBAL_SUCCESS,
    GET_PAY_CODE_GLOBAL_SUCCESS,
} from "./payroll.type";

const initialState = {
    payCodes: [],
    overtimeSettings: [],
    mileages: [],
};

export const payrollReducer = (state = initialState, action) => {

    switch (action.type) {
        /** PayCode types */
        case GET_PAY_CODE_GLOBAL_SUCCESS :
            return {
                ...state,
                payCodes: action.payload
            }
        /** End */

        /** Overtime Settings */
        case GET_OVERTIME_SETTINGS_GLOBAL_SUCCESS :
            return {
                ...state,
                overtimeSettings: action.payload
            }
        /** End */

        /** Mileage Compensation*/
        case GET_MILEAGES_SUCCESS:
            return {
                ...state,
                mileages: action.payload,
            }
        /** End */

        default:
            return state;
    }
};