import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { Address, AddressInput, CreateChancel, ValidationInput } from 'components';
import { ErrorText, FindErrorItem, FindLoad, FindSuccessItem, useModal } from 'utils';
import { clientActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';

export const ClientAddressModal = ({ currentAddress }) => {
    const clientById = useSelector((state) => state.client.clientItemInfo);
    const ACTION_TYPE = currentAddress ? 'EDIT_CLIENT_ADDRESS' : 'ADD_CLIENT_ADDRESS';
    const dispatch = useDispatch();
    const [error, setError] = useState('');
    const [enteredAddress, setEnteredAddress] = useState(currentAddress?.address ? currentAddress?.address : '');
    const [handeDefault, setHandeDefault] = React.useState(false);
    const [name, setName] = React.useState(null);
    const loader = FindLoad(ACTION_TYPE);
    const success = FindSuccessItem(ACTION_TYPE);
    const backError = FindErrorItem(ACTION_TYPE);
    const { close } = useModal();

    useEffect(() => {
        if (currentAddress) {
            setHandeDefault(currentAddress?.default);

            if (currentAddress?.name) {
                setName(currentAddress?.name);
            }
        }
    }, [currentAddress]);

    const removeToDefault = () => {
        setError('');
        setName('');
        setHandeDefault(false);
    };

    useEffect(() => {
        if (success) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            removeToDefault();
        }
    }, [success]);

    const handleAddressChange = (selectedAddress) => {
        backError?.type === ACTION_TYPE && dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        setEnteredAddress(selectedAddress);
        error === 'enteredAddress' && setError('');
    };

    const handleStatusChange = () => {
        setHandeDefault(!handeDefault);
    };

    const handleAddAddress = () => {
        const addressIsValid = enteredAddress?.street && enteredAddress?.city && enteredAddress?.country;

        if (addressIsValid) {
            const sendAddress = {
                address: enteredAddress,
                default: handeDefault,
            };
            name ? sendAddress.name = name : delete sendAddress.name;

            if (currentAddress) {
                dispatch(clientActions.editAddress(clientById?.id, currentAddress?.id, sendAddress));
            } else {
                dispatch(clientActions.addAddress(clientById?.id, sendAddress));
            }
        } else {
            setError('address');
        }
    };

    return (
        <div style={{ width: 463 }}>
            <p className="modal-header-title">{currentAddress ? 'Edit Address' : 'Add Address'}</p>
            <div className="modal-body-wrapper">
                <ValidationInput
                    variant={'outlined'}
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    type={'text'}
                    label={'Name'}
                    name="name"
                    Length={20}
                />
                <AddressInput
                    placeholder={'Street Address*'}
                    selectedAddress={currentAddress?.address}
                    setCurrentAddress={handleAddressChange}
                    errMessage={error === 'address'}
                />
                <FormGroup
                    style={{ marginBottom: '20px' }}
                    onChange={handleStatusChange}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                style={{ color: '#347AF0' }}
                                checked={handeDefault}
                            />
                        } label="Set as Default"
                    />
                </FormGroup>
            </div>
            <CreateChancel
                loader={!!loader.length}
                create={currentAddress ? 'Save' : 'Add'}
                chancel={'Cancel'}
                onCreate={handleAddAddress}
                onClose={close}
                buttonWidth="224px"
            />
        </div>
    );
};