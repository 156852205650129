import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CreateChancel, CustomSelect, ValidationInput } from 'components';
import { ErrorText, FindLoad, FindSuccessItem, isNotEmpty, useModal } from 'utils';
import { credentialTypes } from '../constants';
import { credentialsStyles } from './styles';
import { httpRequestsOnSuccessActions, systemActions } from 'store';

export const CreateCredential = ({ item }) => {
    const ACTION_TYPE = item ? 'EDIT_CREDENTIAL_BY_ID_GLOBAL' : 'CREATE_CREDENTIAL_GLOBAL';
    const classes = credentialsStyles();
    const [error, setError] = useState('');
    const [inputs, setInputs] = useState({});
    const loader = FindLoad(ACTION_TYPE);
    const success = FindSuccessItem(ACTION_TYPE);
    const dispatch = useDispatch();
    const { close } = useModal();

    useEffect(() => {
        if (item?.id) {
            setInputs({
                ...item,
            });
        }
    }, [item]);

    useEffect(() => {
        if (success?.type === ACTION_TYPE) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            close();
        }
    }, [success]);
    const handleChange = (e) => {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        error === e.target.name && setError('');
    };

    const handleCreate = () => {
        const dataIsValid = isNotEmpty(inputs.name) && isNotEmpty(inputs.type);
        if (dataIsValid) {
            const data = {
                name: inputs.name,
                type: inputs.type,
            };
            if (item) {
                dispatch(systemActions.editCredentialByIdGlobal(data, item?.id));
            } else {
                dispatch(systemActions.createCredentialGlobal(data));
            }
        } else {
            const dataErrorText = !isNotEmpty(inputs.name)
                ? 'name'
                : !isNotEmpty(inputs.type)
                    ? 'type'
                    : '';
            setError(dataErrorText);
        }
    };

    return (
        <div style={{ width: 400 }}>
            <p className="modal-header-title">{item ? 'Edit Credential' : 'Add Credential'}</p>
            <div className="modal-body-wrapper">
                <ValidationInput
                    label={'Credential Name*'}
                    onChange={handleChange}
                    value={inputs.name}
                    variant={'outlined'}
                    name={'name'}
                    type={'text'}
                    placeholder={'Name*'}
                    typeError={error === 'name' ? `Credential name ${ErrorText.isRequired}` : ''}
                    Length={50}
                />
                <CustomSelect
                    selectedValue={'id'}
                    renderShowValue={(i) => i?.name}
                    label={'Credential Type*'}
                    name={'type'}
                    handleSelect={handleChange}
                    value={inputs?.type}
                    list={credentialTypes}
                    typeError={error === 'type' ? `Credential type ${ErrorText.isRequired}` : ''}
                />
            </div>

            <CreateChancel
                loader={!!loader.length}
                create={item ? 'Save' : 'Add'}
                chancel={'Cancel'}
                onCreate={handleCreate}
                onClose={() => close()}
                buttonWidth="224px"
            />

        </div>
    );
};