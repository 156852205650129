import React from 'react';
import { motion } from 'framer-motion';
import { landingImages } from 'assets';
import { animationParams, animationVariants } from 'fragments';

export const TheMission = () => {
    return (
        <div className='the-mission-wrapper'>
            <div className='container'>
                <div className='the-mission-box'>
                    <div className='the-mission-content'>
                        <div style={{ overflow: 'hidden' }}>
                            <motion.h3
                                className='the-mission-title'
                                {...animationParams}
                                variants={animationVariants.titleVariant}
                            >
                                What is the mission behind TherapyLake?
                            </motion.h3>
                        </div>

                        <div style={{ overflow: 'hidden' }}>
                            <motion.h4
                                className='the-mission-sub_title'
                                {...animationParams}
                                variants={animationVariants.leftToRightVariant}
                            >
                                Mission Statement
                            </motion.h4>
                        </div>

                        <div style={{ overflow: 'hidden' }}>
                            <motion.p
                                className='the-mission-description'
                                {...animationParams}
                                variants={animationVariants.titleVariant}
                            >
                                At TherapyLake, our mission is to empower ABA practices with innovative,
                                reliable and user-friendly management solutions. We strive to simplify administrative
                                tasks, improve operational efficiency and enable practitioners to focus more on client
                                care.
                            </motion.p>

                        </div>
                    </div>

                    <div className='the-mission-image-block'>
                        <img src={landingImages.theMissionImg} alt='The Mission Img' />
                    </div>
                </div>
            </div>
        </div>
    );
};

