import React from 'react';
import { LinkRow } from 'components';
import { FindLoad, PermissionList, RolePermission } from 'utils';

const ACTION_TYPE = 'GET_APPOINTMENT_INFORMATION';
export const ApptAccounting = ({ item }) => {
    const loader = FindLoad(ACTION_TYPE);

    if (loader?.length) {
        return <div />;
    }

    return (
        <>
            <div className="signature-process">
                <p className="schedule-details-title">Accounting Details</p>
                <div className="flex-align-center" style={{ gap: 8 }}>
                    {!!item?.timesheets?.length &&
                        <div className="signature-detail-box">
                            <div className="flex-align-center" style={{ gap: 4 }}>
                                <p className="signature-detail-box-title">Timesheet:</p>
                                {item?.timesheets?.map((timesheet, index) => (
                                    <p className="signature-detail-name" key={index}>
                                        <LinkRow
                                            name={timesheet?.displayId ? timesheet?.displayId : 'N/A'}
                                            href={RolePermission([PermissionList.TIMESHEET_READ?.code]) ?
                                                timesheet?.status === 'ACTIVE' ? `/timesheet/${timesheet?.id}` : `/processed/${timesheet?.id}`
                                                : ''}
                                            checkPermission={timesheet?.id ? RolePermission([PermissionList.TIMESHEET_READ?.code]) : null}
                                        />
                                    </p>
                                ))}
                            </div>
                        </div>
                    }

                    {item?.claim?.id &&
                        <div className="signature-detail-box">
                            <div className="flex-align-center" style={{ gap: 4 }}>
                                <p className="signature-detail-box-title">Claim:</p>
                                <p className="signature-detail-name">
                                    <LinkRow
                                        name={item?.claim?.displayId ? item?.claim?.displayId : 'N/A'}
                                        href={RolePermission([PermissionList.CLAIMS_READ?.code]) ? `/claim/${item?.claim?.id}` : ''}
                                        checkPermission={item?.claim?.id ? RolePermission([PermissionList.CLAIMS_READ?.code]) : null}
                                    />
                                </p>
                            </div>
                        </div>
                    }

                    {item?.bill?.id &&
                        <div className="signature-detail-box">
                            <div className="flex-align-center" style={{ gap: 4 }}>
                                <p className="signature-detail-box-title">Bill:</p>
                                <p className="signature-detail-name">
                                    <LinkRow
                                        name={item?.bill?.displayId ? item?.bill?.displayId : 'N/A'}
                                        href={RolePermission([PermissionList.CLAIMS_READ?.code]) ? `/claim/${item?.claim?.id}` : ''}
                                        checkPermission={item?.claim?.id ? RolePermission([PermissionList.CLAIMS_READ?.code]) : null}
                                    />
                                </p>
                            </div>
                        </div>
                    }

                    {item?.invoice?.id &&
                        <div className="signature-detail-box">
                            <div className="flex-align-center" style={{ gap: 4 }}>
                                <p className="signature-detail-box-title">Invoice:</p>
                                <p className="signature-detail-name">
                                    <LinkRow
                                        name={item?.invoice?.displayId ? item?.invoice?.displayId : 'N/A'}
                                        href={RolePermission([PermissionList.INVOICE_READ?.code]) ? `/invoice/${item?.invoice?.id}` : ''}
                                        checkPermission={item?.invoice?.id ? RolePermission([PermissionList.INVOICE_READ?.code]) : null}
                                    />
                                </p>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
};