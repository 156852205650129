import {
   CLOSE_CLAIM,
   GENERATE_CLAIM,
   GET_CLAIMS,
   GET_CLAIM_BY_ID,
   SUBMIT_CLAIM,
   GET_BILL_INVOICE_PAYMENT,
   GET_BILL_CLAIM_PAYMENT_TRANSACTIONS,
   GET_SUBMITTED_CLAIMS,
   SUBMIT_CLAIM_BY_ID,
   REFRESH_CLAIM,
   RESUBMIT_CLAIM,
   EDIT_CLAIM,
   REMOVE_APPT_FROM_CLAIM,
   UPLOAD_CLAIM_DOCUMENT,
   EDIT_CLAIM_DOCUMENT,
   GET_CLAIM_DOCUMENT,
   DELETE_CLAIM_DOCUMENT,
} from './claim.type';

export const getPendingClaims = (data, load) => {
   return {
      type: GET_CLAIMS,
      payload: { data, load },
   };
};

export const getSubmittedClaims = (data) => {
   return {
      type: GET_SUBMITTED_CLAIMS,
      payload: { data },
   };
};

export const getClaimById = (id, load) => {
   return {
      type: GET_CLAIM_BY_ID,
      payload: { id, load },
   };
};

export const generateClaim = (group, merge) => {
   return {
      type: GENERATE_CLAIM,
      payload: { group, merge },
   };
};

export const editClaim = (id, body) => {
   return {
      type: EDIT_CLAIM,
      payload: { id, body },
   };
};

/**Claim Status Actions */

export const closeClaim = (id, details) => {
   return {
      type: CLOSE_CLAIM,
      payload: { id, details },
   };
};

export const submitClaim = (id, requestParams, type) => {
   return {
      type: SUBMIT_CLAIM,
      payload: { id, requestParams, type },
   };
};

export const submitClaimById = (id) => {
   return {
      type: SUBMIT_CLAIM_BY_ID,
      payload: { id },
   };
};

export const refreshClaim = (id) => {
   return {
      type: REFRESH_CLAIM,
      payload: { id },
   };
};

export const resubmitClaim = (id) => {
   return {
      type: RESUBMIT_CLAIM,
      payload: { id },
   };
};

export const removeFromClaim = (id, reason, claimId) => {
   return {
      type: REMOVE_APPT_FROM_CLAIM,
      payload: { id, reason, claimId },
   };
};

/**Bill Details */

export const bilClaimPmtTr = (id) => {
   return {
      type: GET_BILL_CLAIM_PAYMENT_TRANSACTIONS,
      payload: { id },
   };
};

export const bilInvoicePmt = (ids) => {
   return {
      type: GET_BILL_INVOICE_PAYMENT,
      payload: { ids },
   };
};

/** Claim Documents */

export const uploadClaimDocument = (file, id) => {
   return {
      type: UPLOAD_CLAIM_DOCUMENT,
      payload: {file, id}
   }
}

export const editClaimDocument = (id, dockId, file) => {
   return {
      type: EDIT_CLAIM_DOCUMENT,
      payload: {id, dockId, file}
   }
}

export const getClaimDocument = (id) => {
   return {
      type: GET_CLAIM_DOCUMENT,
      payload: {id}
   }
}

export const deleteClaimDocument = (claimId, id) => {
   return {
      type: DELETE_CLAIM_DOCUMENT,
      payload: {claimId, id}
   }
}

/** End */