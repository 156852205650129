import { scheduleModalsStyle } from './styles';
import { CreateChancel } from 'components';
import React, { useEffect, useState } from 'react';
import { FindLoad, formatAMPM, Images, useModal } from 'utils';
import moment from 'moment/moment';
import { appointmentService } from '../../../../store/appointment/appointment.service';

export const EditMultiple = ({ handleSubmit, loadType, modalDate }) => {
    const classes = scheduleModalsStyle();
    const loader = FindLoad(loadType);
    const { close } = useModal();
    const [overLappList, setOverlappList] = useState([]);
    const [loadList, setLoadList] = useState(true);

    useEffect(() => {
        if (modalDate) {
            const params = {
                staffId: modalDate?.staff?._id,
                templateId: modalDate?.template,
                startTime: modalDate?.startTime,
                endTime: modalDate?.endTime,
            };
            appointmentService.checkApptUpdateOverlapping(params).then((res) => {
                setOverlappList(res?.data);
                setLoadList(false);
            }).catch(() => {
                setLoadList(false);
            });
        }
    }, []);

    return (
        <div className={classes.lockUnlockWrapper}>
            <p className={classes.confirmTitle}>Confirmation</p>
            {overLappList?.startDate &&
                <div className={classes.confirmModalInfoBox}>
                    <p style={{ margin: 0 }}>{`Date Range: ${moment.utc(overLappList?.startDate).format('MM/DD/YYYY')} to ${moment.utc(overLappList?.endDate).format('MM/DD/YYYY')} `}</p>
                </div>
            }

            {!!overLappList?.overlap?.length &&
                <div className={classes.editConfirmModalWornBox}>
                    <div className={classes.confirmModalWornBoxInfo}>
                        <p>
                            <img src={Images.warning} alt="icon" style={{ marginRight: '4px' }} />
                            {`Warning! The following appointments are overlapping. If you proceed, they will be disconnected from the template and their time will not be changed.`}
                        </p>
                    </div>
                    <div className={classes.confirmModalWornBoxDates}>
                        {overLappList?.overlap?.map((i, j) => (
                            <p key={j}>{`${i?.displayID} - ${moment.utc(i?.date).format('MM/DD/YYYY')} (${formatAMPM(i?.startTime)} - ${formatAMPM(i?.endTime)})`}</p>
                        ))}
                    </div>
                </div>
            }

            {!!overLappList?.completedAppts?.length &&
                <div className={classes.editConfirmModalWornBox}>
                    <div className={classes.confirmModalWornBoxInfo}>
                        <p>
                            <img src={Images.warning} alt="icon" style={{ marginRight: '4px' }} />
                            {`Warning! The following appointments are already rendered. If you proceed, they will not effect already processed timesheets and billing.`}
                        </p>
                    </div>
                    <div className={classes.confirmModalWornBoxDates}>
                        {overLappList?.completedAppts?.map((i, j) => (
                            <p key={j}>{`${i?.displayID} - ${moment.utc(i?.date).format('MM/DD/YYYY')} (${formatAMPM(i?.startTime)} - ${formatAMPM(i?.endTime)})`}</p>
                        ))}
                    </div>
                </div>
            }

            <CreateChancel
                disabled={loadList}
                loader={!!loader?.length || loadList}
                create={'Confirm'}
                chancel={'Cancel'}
                onCreate={handleSubmit}
                onClose={() => close()}
                buttonWidth="223px"
                createButnMargin={'16px'}
            />
        </div>
    );
};