import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useForm} from 'react-hook-form';
import {authActions, authService, httpRequestsOnErrorsActions} from 'store';
import {CheckPassword, ErrMessage, InputMain, SignIn} from 'components';
import {FindErrorItem, FindLoad, PasswordValidator} from 'utils';
import {NavLink, useLocation} from 'react-router-dom';
import {Svg} from 'assets';
import {checkPassword} from './constants';
import {Checkbox} from '@material-ui/core';

const ACTION_TYPE = 'RESET_PASSWORD_REQUEST';

export const ResetModal = ({}) => {
    const {handleSubmit, control, setError, clearErrors, watch, formState: {errors},} = useForm({
        defaultValues: {
            newPassword: '',
            confirmation: '',
        },
    });
    const dispatch = useDispatch();
    const [showPasswords, setShowPasswords] = useState({
        newPassword: false,
        confirmation: false,
    });
    const [load, setLoad] = useState(false);
    const [iAgree, setAgree] = useState(false);
    const loader = FindLoad(ACTION_TYPE);
    const backError = FindErrorItem(ACTION_TYPE);
    const {search} = useLocation();
    const searchParams = new URLSearchParams(search);
    const resetToken = searchParams.get('resetToken');
    const register = searchParams.get('token');
    const userEmail = searchParams.get('email');
    const beckErrorMessage =
        backError?.error === 'Your session is expired, please login again or token is invalid' ? 'Your session is expired' :
         backError?.error === 'This profile is not eligable for registration completion at this time' ? backError?.error :
         errors?.identify?.message === 'User Identification with this resource was not found.' ? 'User Identification with this email was not found.' :
            ''

    useEffect(() => {
        dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));

        return () => {
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
            clearErrors();
        };
    }, []);

    useEffect(() => {
        if (watch('newPassword') !== watch('confirmation')) {
            setError('confirmation', {
                message: 'Password and confirm password does not match',
            });
        } else {
            clearErrors('confirmation');
        }

    }, [watch('confirmation')]);


    const resetPassword = (data) => {
        clearErrors('identify');
        const {newPassword, confirmation} = data;

        const passwords = {
            newPassword: data.newPassword,
            confirmation: data?.confirmation,
            token: register ? register : resetToken,
            type: register ? 'register' : 'reset',
        };

        if (newPassword === confirmation) {
            dispatch(authActions.resetPassword(passwords, userEmail));
        } else {
            setError('confirmation', {
                message: 'Password and confirm password does not match',
            });
        }
    };

    return (
        <div className='login-wrapper'>
            <div className='login-main-content'>
                <h3 className='login-title'>
                    {resetToken ? 'Reset Password' : 'Complete Registration'}
                </h3>

                <p className='login-description'>
                    {resetToken
                        ?
                        'Enter your new password below.'
                        : `You have been invited to join TherapyLake. Please create your password to complete your registration.`
                        // +
                    }
                </p>
                <form className='login-form' onSubmit={handleSubmit(resetPassword)}>
                    <InputMain
                        variant='outlined'
                        name='newPassword'
                        type='password'
                        showPassword={showPasswords.newPassword}
                        control={control}
                        placeholder={register ? 'Password' : 'New Password'}
                        labelHeader={register ? 'Password' : 'New Password'}
                        minLength={8}
                        rules={{
                            required: 'New password is required.',
                            pattern: {
                                value: PasswordValidator,
                                message: 'Not valid password',
                            },
                        }}
                        onClickIconEnd={() => setShowPasswords(prev => (
                            {
                                ...prev,
                                newPassword: !prev.newPassword,
                            }
                        ))}
                        startAdornment={<Svg.LockPassSvg/>}
                    />

                    <InputMain
                        variant='outlined'
                        name='confirmation'
                        type='password'
                        showPassword={showPasswords.confirmation}
                        control={control}
                        placeholder={register ? 'Confirm Password' : 'Confirm New Password'}
                        labelHeader={register ? 'Confirm Password' : 'Confirm New Password'}
                        minLength={8}
                        rules={{
                            required: 'Confirm password is required.',
                        }}
                        onClickIconEnd={() => setShowPasswords(prev => (
                            {
                                ...prev,
                                confirmation: !prev.confirmation,
                            }
                        ))}
                        startAdornment={<Svg.LockPassSvg/>}
                    />


                    <CheckPassword
                        fields={checkPassword}
                        text={watch('newPassword')}
                        isActive={watch('newPassword')}
                    />

                    {beckErrorMessage &&
                        <ErrMessage
                            styles={{margin: '10px 0'}}
                            type={'login'}
                            text={beckErrorMessage}
                        />
                    }

                    <div className='i-agree-wrapper'>
                        <Checkbox
                            style={{color: iAgree ? '#1668E8' : '#D7D7D7'}}
                            className='i-agree-checkbox'
                            value={iAgree}
                            onChange={() => setAgree(prev => !prev)}
                        />

                        <p className='i-agree-text'>
                            I have read and agree to the
                            <NavLink
                                className='i-agree-text'
                                style={{margin: '0 4px'}}
                                to='/terms-and-conditions'
                                target={'_blank'}
                            >
                                Terms of Use
                            </NavLink>

                            and

                            <NavLink
                                className='i-agree-text'
                                style={{margin: '0 4px'}}
                                to='privacy-policy'
                                target={'_blank'}
                            >
                                Privacy Policy
                            </NavLink>
                        </p>
                    </div>


                    <SignIn
                        styles={{opacity:iAgree ? 1 : 0.5}}
                        disabled={!iAgree}
                        type='submit'
                        loader={load || !!loader?.length}
                        width={'100%'}
                        text={register ? 'Complete Registration' : 'Reset Password'}
                    />
                </form>
            </div>
        </div>
    );
};
