import { LandingSvg, landingImages } from 'assets';


export const solutionsTab = [
    {
        label: 'For Small Practices',
        path: 'forSmallPractices',
    },

    {
        label: 'For Large Organizations',
        path: 'forLargeOrganizations',
    },

    {
        label: 'For Decision-Makers',
        path: 'forDecisionMakers',
    },
];

export const solutionsCardsContent = {
    forSmallPractices: {
        label: '',
        tabItems: [

            {
                title: 'Affordable Pricing',
                description: 'Scalable plans that grow with your practice.',
            },

            {
                title: 'Easy Onboarding',
                description: 'Simple setup process with dedicated support.',
            },

            {
                title: 'Efficiency Boost',
                description: 'Automate administrative tasks to focus more on client care.',
            },
        ],
    },

    forLargeOrganizations: {
        label: 'Advanced tools and scalability for established practices.',
        tabItems: [

            {
                title: 'Scalability',
                description: 'Robust features that scale with your organization’s needs.',
            },

            {
                title: 'Comprehensive Data Management',
                description: 'Centralized data for easy access and management.',
            },

            {
                title: 'Advanced Reporting:',
                description: 'In-depth analytics to track performance and optimize operations.',
            },
        ],
    },

    forDecisionMakers: {
        label: 'Strategic insights and tools to optimize practice operations.',
        tabItems: [

            {
                title: 'Advanced Reporting',
                description: 'Gain insights into practice performance and financial health.',
            },

            {
                title: 'COperational Efficiency:',
                description: 'CAutomate workflows to reduce manual errors and increase productivity.',
            },

            {
                title: 'Compliance and Security',
                description: 'IEnsure data security and regulatory compliance with HIPAA standards',
            },
        ],
    },
};

export const electronicRecordsContent = [
    {
        Icon: LandingSvg.FundingSourceSvg,
        text: 'Funding Source Management',
    },
    {
        Icon: LandingSvg.ClientManagementSvg,
        text: 'Client Management',
    },
    {
        Icon: LandingSvg.StaffManagementSvg,
        text: 'Staff Management',
    },
];

export const billingPostingCard = [
    {
        img: landingImages.billingDegree,
        title: '360 degree Billing System',
        description: 'Generate, submit, and track the entire billing process without the need for a third-party solution',
        classNameWrapper: 'billing-system-card-wrapper',
        classNameImg: 'billing-system-img',
    },

    {
        img: landingImages.aiPowered,
        title: 'AI-Powered Verification',
        description: 'Our AI-powered agents help you verify and correct claims before submitting them to payers, reducing errors and improving rates',
        classNameWrapper: 'powered-card-wrapper',
        classNameImg: 'powered-img',
    },

    {
        img: landingImages.autoClaims,
        title: 'Automated Claims Submission',
        description: 'Leverage our integrations to automate the claim submission process and minimize manual interventions',
        classNameWrapper: 'automated-card-wrapper',
        classNameImg: 'automated-img',
    },

    {
        img: landingImages.billingDirect,
        title: 'Direct Billing',
        description: 'Manage self-pay clients with automated invoicing and payment tracking',
        classNameWrapper: 'direct-card-wrapper',
        classNameImg: 'direct-img',
    },

    {
        img: landingImages.billingConsulting,
        title: 'Billing Consulting',
        description: 'Let our experts guide you through the complicated billing processes and help you streamline your billing workflows',
        classNameWrapper: 'consulting-card-wrapper',
        classNameImg: 'consulting-img',
    },

    {
        img: landingImages.billingPosting,
        title: 'Posting',
        description: 'Track payments, co-pays, appeals, and pending receivables in one place. Complete with' +
            ' client invoicing and advanced payment applications, TherapyLake simplifies the entire billing process',
        classNameWrapper: 'posting-card-wrapper',
        classNameImg: 'posting-img',
    },

];

export const schedulingInfo = {
    img: landingImages.schedulingImg,
    blueText: 'MAKE IT SIMPLE',
    title: 'Scheduling',
    description: 'Avoid overbooking and ensure availability with automated scheduling tools',
    items: [
        {
            textBold: 'Staff Assignments',
            text: 'Automated assignment based on staff credentials, client assignment status, and availability',
        },
        {
            textBold: 'Appointment Management',
            text: 'Quickly schedule, reschedule, and cancel appointments with ease',
        },
        {
            textBold: 'Recurring Appointments',
            text: 'Create many appointments at once by utilizing system tools for pattern matching',
        },
        {
            textBold: 'Real-Time Availability',
            text: 'Ensure all staff and resources are optimally utilized',
        },
    ],
};

export const payrollInfo = {
    img: landingImages.payrollManag,
    blueText: 'Accurate, Automated Payroll Solutions',
    title: 'Payroll Management',
    description: 'Generate accurate timesheets for staff members with automated pay code and overtime calculations',
    items: [
        {
            textBold: 'Automated Timesheets',
            text: 'The system generates timesheets for staff members using pay codes configured by management and is mostly generated automatically from rendered appointments',
        },
        {
            textBold: 'Overtime Rule Creation',
            text: 'Create custom overtime rules to automate overtime calculations and ensure compliance',
        },
        {
            textBold: 'Time Tracking',
            text: 'Accurately track staff hours, including regular, overtime, and special pay categories',
        },
        {
            textBold: 'Payroll Integration',
            text: 'Easily export timesheets for seamless payroll processing',
        },

    ],
};

export const credentialingInfo = {
    img: landingImages.credentialing,
    blueText: 'Streamline Compliance and Credentialing',
    title: 'Credentialing',
    description: 'Simplify compliance and maintain staff credentials effortlessly',
    items: [
        {
            textBold: 'Contracting Management',
            text: 'Streamline the contracting process with payers and ensure accurate credentialing',
        },
        {
            textBold: 'Credentialing Reminders',
            text: 'Automated alerts for upcoming license and certification renewals',
        },
        {
            textBold: 'Document Management',
            text: 'Automated alerts for upcoming license and certification renewals',
        },
        {
            textBold: 'Appointment Staff Matching',
            text: 'Suggests staff for appointments based on funding source service, CPT code, and credential constraints',
        },

    ],
};

export const powerfulInfo = {
    img: landingImages.powerfulIntegrations,
    blueText: 'Seamless Connectivity',
    title: 'Powerful Integrations',
    description: 'Create reports tailored to your practice\'s unique needs',
    items: [
        {
            textBold: 'Clearinghouses: ',
            text: 'Integrate with Office Ally and other clearinghouses for seamless claims management',
        },
        {
            textBold: 'Payroll Systems:',
            text: ' Export timesheets and payroll data for easy integration with your payroll provider',
        },
        {
            textBold: 'Calendar Integration:',
            text: 'Sync your TherapyLake schedule with external calendars like Google Calendar or Apple Calendar',
        },

    ],
};

export const accessKeyInfo = (width) => {


    return {
        img: width > 1280 ? landingImages.accessKayImage : width > 768 ? landingImages.accessKayImageTablet : landingImages.accessKayImageMobile,
        blueText: 'Mobile app',
        title: 'Access Key Features Anytime, Anywhere',
        description: 'Provide crucial support for therapists on the go with TherapyLake’s fully functional mobile app.',
        items: [
            {
                textBold: 'Online & Offline Support:',
                text: 'Continue working even without an internet connection, and sync data automatically when reconnected',
            },
            {
                textBold: 'Real-Time Sync:',
                text: 'Ensure all client data, schedules, and reports are up-to-date across devices',
            },
            {
                textBold: 'On-the-Go Efficiency:',
                text: 'Access client data, scheduling, and reporting from anywhere',
            },

            {
                textBold: 'Appointment Scheduling:',
                text: 'Schedule, reschedule, and manage appointments directly from the mobile app',
            },

        ],
    };
};

export const dataSecurityInfo = {
    img: landingImages.dataSecurity,
    blueText: 'Ultimate Data Protection',
    title: 'Data Security & Compliance',
    description: 'Stay compliant with HIPAA and other data security standards with TherapyLake’s comprehensive security measures',
    items: [
        {
            textBold: 'HIPAA Compliance ABA Solution:',
            text: 'Full compliance with encryption and role-based access controls',
        },
        {
            textBold: 'Data Encryption:',
            text: 'All data is encrypted both in transit and at rest for maximum security',
        },

        {
            textBold: 'Audit Logs:',
            text: 'Track all activities within the platform for security and compliance purposes',
        },

        {
            textBold: 'Role-Based Access Control:',
            text: 'Restrict data access based on user roles and permissions to ensure confidentiality',
        },

        {
            textBold: 'Secure Data Backup:',
            text: 'Regular backups ensure your data remains safe and recoverable',
        },

    ],
};

export const customizableReportsInfo = {
    items1: [
        {
            textBold: 'Revenue Insights',
            text: 'Identify revenue growth opportunities and track outstanding claims',
        },
        {
            textBold: 'Operational Analytics',
            text: 'Analyze scheduling efficiency, staff performance, and appointment utilization',
        },

        {
            textBold: 'Compliance Reporting',
            text: 'Ensure adherence to HIPAA and other regulatory requirements with automated compliance reports',
        },
    ],

    items2: [
        {
            textBold: 'Claims Analysis',
            text: 'Gain visibility into claims processing, including denials, delays, and payment trends',
        },
        {
            textBold: 'Payment Tracking',
            text: 'Monitor payments, co-pays, and outstanding balances to identify issues in collection processes',
        },

        {
            textBold: 'Service Utilization',
            text: 'Evaluate service delivery to identify underused services and improve operational efficiency',
        },
    ],
};
