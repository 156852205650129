import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { WhyTherapyCard } from './whyTherapyCard';
import { cardsData } from '../constants';
import { useWidth } from 'utils';
import { ScrollWrapper } from 'components';
import { animationParams, animationVariants } from 'fragments';

export const WhyTherapy = () => {
    const [currentCardIndex, setCurrentCardIndex] = useState(1);
    const intervalRef = useRef(null);
    const width = useWidth();

    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        };
    }, []);


    const clickToCard = (index) => {
        if (currentCardIndex === index) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        } else {
            if (!intervalRef.current) {
                intervalRef.current = setInterval(() => {
                    setCurrentCardIndex((prevIndex) => {
                        const nextIndex = prevIndex + 1;
                        return nextIndex < cardsData.length + 1 ? nextIndex : 1;
                    });
                }, 5000);
            }
            setCurrentCardIndex(index);
        }
    };

    return (
        <div className='why-therapy-wrapper'>
            <div className='why-therapy-box'>
                <div className='why-therapy-container'>
                    {/*<div className='lorem-ipsum-button'>*/}
                    {/*    Lorem Ipsum*/}
                    {/*</div>*/}

                    <div style={{ overflow: 'hidden' }}>
                        <motion.h2
                            className='why-therapy-title'
                            {...animationParams}
                            variants={animationVariants.titleVariant}
                        >
                            Why Therapy Lake
                        </motion.h2>
                    </div>

                    <p className='why-therapy-description'>
                        Discover the Benefits of Streamlined Practice Management with TherapyLake
                    </p>

                    <ScrollWrapper
                        className='observer'
                        threshold={0.3}
                        onChange={(inView) => {
                            if (inView) {
                                intervalRef.current = setInterval(() => {
                                    setCurrentCardIndex((prevIndex) => {
                                        const nextIndex = prevIndex + 1;
                                        return nextIndex < cardsData.length + 1 ? nextIndex : 1;
                                    });
                                }, 5000);
                            } else {
                                clearInterval(intervalRef.current);
                                setCurrentCardIndex(1);
                                intervalRef.current = null;
                            }
                        }}>
                        <div className='why-therapy-content'>
                            <div className='why-therapy-content_left-side'>
                                {width > 1280 && cardsData.map((card) => (
                                        <WhyTherapyCard
                                            key={card?.id}
                                            card={card}
                                            active={currentCardIndex === card?.id}
                                            onClick={() => clickToCard(card?.id)}
                                        />
                                    ),
                                )}

                                {width <= 1280 && cardsData.filter((card) => card?.id <= currentCardIndex).map((card, index) => (
                                        <WhyTherapyCard
                                            key={card?.id}
                                            card={card}
                                            active={currentCardIndex === card?.id}
                                            onClick={() => clickToCard(card?.id)}
                                        />
                                    ),
                                )}
                            </div>

                            <div className='why-therapy-content_right-side'>
                                <motion.img
                                    src={cardsData[currentCardIndex - 1].image} alt='Card Image'
                                    {...animationParams}
                                    variants={animationVariants.cardsVariant}
                                />
                            </div>
                            {width <= 1280 &&
                                <div className='why-therapy-content_left-side'>
                                    {cardsData.filter((card) => card?.id > currentCardIndex).map((card, index) => (
                                        (
                                            <WhyTherapyCard
                                                key={card?.id}
                                                card={card}
                                                active={currentCardIndex === card?.id}
                                                onClick={() => clickToCard(card?.id)}
                                            />
                                        )
                                    ))}
                                </div>
                            }
                        </div>
                    </ScrollWrapper>
                </div>
            </div>
        </div>
    );
};

