import React from 'react';
import { MinLoader } from '../loader';
import { Colors } from 'utils';

export const CheckboxComponentBtn = ({ handleClick, list, loadCreate, btnText }) => {
    return (
        <div>
            {list?.length ?
                <button
                    style={{ backgroundColor: list?.length ? '#347AF0' : 'rgba(52, 122, 240, 0.50)' }}
                    disabled={!list?.length}
                    className="checkbox-component-btn"
                    onClick={handleClick}>
                    {loadCreate?.length ?
                        <MinLoader margin={'0'} color={Colors.TextWhite} />
                        :
                        btnText
                    }
                </button>
                :
                <div className="checkbox-component-btn" style={{ backgroundColor: 'rgba(52, 122, 240, 0.50)' }}>
                    {btnText}
                </div>
            }
        </div>
    );
};