import { v4 as uuidv4 } from 'uuid';
import { clientStatuses } from 'utils';
import { CLIENT_ACTION, renderClientName, renderStaffName } from './constants';
import { apptEnums } from '../../../../fragments/schedule/core/constants';
import { BREAK, DRIVE, PAID, SERVICE, SICK_TIME, STAFF, UNPAID } from '../../../../fragments/schedule/constants';

export const clientApptReport = () => {
    return {
        title: 'Client Appointments Report',
        url: '/reports/client/appts',
        list:
            [
                {
                    type: 'selectMultiple',
                    name: 'clients',
                    label: 'Client',
                    loadType: CLIENT_ACTION,
                    renderValue: (option) => renderClientName(option),
                },
                {
                    type: 'select',
                    name: 'clientStatus',
                    label: 'Client Status',
                    renderValue: (option) => option?.name,
                    selectList: [
                        ...clientStatuses,
                    ],
                },
                {
                    type: 'dos',
                    startDate: 'startDate',
                    endDate: 'endDate',
                    startLabel: 'Appt. Start Date',
                    endLabel: 'Appt. End Date',
                },
                {
                    type: 'select',
                    name: 'status',
                    label: 'Appt. Status',
                    selectList: [
                        { id: apptEnums?.COMPLETED, name: 'Completed' },
                        { id: apptEnums?.PENDING, name: 'Pending' },
                        { id: apptEnums?.CANCELLED, name: 'Cancelled' },
                    ],
                    renderValue: (option) => option?.name,
                },
                {
                    type: 'select',
                    name: 'type',
                    label: 'Appt. Type',
                    selectList: [
                        { id: SERVICE, name: 'Service' },
                        { id: STAFF, name: 'Staff' },
                        { id: DRIVE, name: 'Drive' },
                        { id: BREAK, name: 'Break' },
                        { id: PAID, name: 'Paid' },
                        { id: UNPAID, name: 'Unpaid' },
                        { id: SICK_TIME, name: 'Sick' },
                    ],
                    renderValue: (option) => option?.name,
                },
                {
                    type: 'selectMultiple',
                    name: 'staffs',
                    label: 'Staff',
                    loadType: 'GET_ADMINS',
                    renderValue: (option) => renderStaffName(option),
                },
                {
                    type: 'select',
                    name: 'serviceType',
                    label: 'Service Type',
                    selectList: [
                        { id: 'DIRECT', name: 'Direct' },
                        { id: 'INDIRECT', name: 'Indirect' },
                    ],
                    renderValue: (option) => option?.name,
                },
            ],
    };
};

export const noShowCancelApptReport = () => {
    return {
        title: 'Appt. No-Show/Cancellation Report',
        url: '/reports/client/appts/canceled',
        list:
            [
                {
                    type: 'selectMultiple',
                    name: 'clients',
                    label: 'Client',
                    loadType: CLIENT_ACTION,
                    renderValue: (option) => renderClientName(option),
                },
                {
                    type: 'selectMultiple',
                    name: 'staffs',
                    label: 'Staff',
                    loadType: 'GET_ADMINS',
                    renderValue: (option) => renderStaffName(option),
                },
                {
                    type: 'dos',
                    startDate: 'startDate',
                    endDate: 'endDate',
                    startLabel: 'Appt. Start Date',
                    endLabel: 'Appt. End Date',
                },
                {
                    type: 'date',
                    name: 'canceledDate',
                    label: 'Cancelled Date',
                },
                // {
                //     type: 'select',
                //     name: 'status',
                //     label: 'Appt. Status',
                //     selectList: [
                //         { id: apptEnums?.COMPLETED, name: 'Completed' },
                //         { id: apptEnums?.PENDING, name: 'Pending' },
                //         { id: apptEnums?.CANCELLED, name: 'Cancelled' },
                //     ],
                //     renderValue: (option) => option?.name,
                // },
            ],
    };
};
export const clientReportEnums = [
    {
        id: uuidv4(),
        name: 'Client Appointments Report',
        description: 'List of client appointments with dates, statuses, and assigned staff.',
        generateInfo: clientApptReport(),
    },
    {
        id: uuidv4(),
        name: 'Appt. No-Show/Cancellation Report',
        description: 'Canceled and no-show appointments with reasons and cancellation details.',
        generateInfo: noShowCancelApptReport(),
    },
];