import React from 'react';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ButtonLanding } from 'components';
import { animationParams, animationVariants } from 'fragments';

;

export const GetInTouch = () => {
    const history = useHistory();

    return (
        <div className='get-in-touch-wrapper'>
            <div className='container'>
                <div className='get-in-touch-box'>
                    <motion.h3
                        className='get-in-touch-title'
                        {...animationParams}
                        variants={animationVariants.titleVariant}
                    >
                        Get in touch with TherapyLake
                    </motion.h3>

                    <motion.p
                        className='get-in-touch-description'
                        {...animationParams}
                        variants={animationVariants.titleVariant}
                    >
                        We’re here to help. Whether you have questions about our services, need support or want to
                        provide feedback, feel free to reach out to us.
                    </motion.p>

                    <ButtonLanding
                        onClick={() => history.push('/contact-us')}
                        className='get-in-touch-button'
                        text='Contact Us'
                        contained={true}
                        arrowIcon={true}
                    />
                </div>
            </div>
        </div>
    );
};

