import { Colors } from 'utils';
import { makeStyles } from '@material-ui/core/styles';

export const foundingSourceModalStyle = makeStyles(() => ({
    createFoundingSource: {
        background: Colors.BackgroundWhite,
        borderRadius: '8px',
        overflow: 'hidden',
    },

    createFoundingSourceBody: {
        width: '100%',
        padding: '40px',
        '@media (max-width: 1280px)': { padding: '32px' },
    },
    fundingSourceModalsTitle: {
        fontSize: 18,
        color: Colors.TextPrimary,
        fontWeight: 600,
    },
    addMoreModifiersText: {
        marginLeft: 8,
        fontSize: 14,
        color: '#347AF080',
        cursor: 'pointer',
    },
    addmodifiersBlock: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: '20px 0 40px',
        alignItems: 'center',
    },
    displayCodeBlock: {
        width: '100%',
        height: '120px',
        padding: '0px 16px',
        margin: '-4px 0px 16px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        background: Colors.BackgroundWater,
    },
    displayCodeBlockText: {
        color: Colors.TextPrimary,
        fontSize: 14,
        fontWeight: 600,
    },
    displayCode: { color: '#4B5C68B3' },
    ModifiresTitle: {
        fontSize: 18,
        color: Colors.TextPrimary,
        fontWeight: 600,
        marginBottom: 16,
        marginTop: 16,
    },
    iconsCursor: { cursor: 'pointer' },
    foundingSourceModalsBodyBlock: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    modifierBoxStyle: {
        width: '100%',
        marginTop: '40px',

        '@media(min-width: 1919px)': {
            marginTop: '32px',
        },
    },
    serviceInputsContainerStyle: {
        width: '100%',
        display: 'flex',
        marginTop: '16px',
    },
    leftInputsBoxStyle: { width: '400px' },
    rightInputsBoxStyle: {
        width: '400px',
        marginLeft: '40px',
    },

    addModifierWrapper: {
        width: '480px',
    },
    switchesWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        margin:'14px 0 24px'
    },

    requireWrapper: {
        display: 'flex',
        alignItems: 'center',
    },

    requireText: {
        color: '#4B5C68',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '19px',
        marginLeft: '4px',
        width: '130px',
        marginBottom: '2px',
    },
}));

export const inputStyle = {
    marginBottom: 8,
};
