import { makeStyles } from "@material-ui/core";
import { Colors, Shadow } from "utils";

export const clientCommonCoreStyle = makeStyles(() => ({
   tableTheadStyle: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      boxShadow: Shadow.theadRowBoxShadow,
      backgroundColor: Colors.BackgroundWater,
      borderRadius: "8px",
   },
   thStyle: {
      maxWidth: "242px",
      minWidth: "70px",
      width: "100%",
      padding: "8px",
      height: "51px",
      display: "flex",
      alignItems: "center",
      fontSize: "14px",
      fontWeight: 600,
      color: Colors.BackgroundBlue,
      "@media(max-width: 1720px)": { padding: "16px 16px" },
   },
   tbodyContainerStyle: {
      width: "100%",
      height: "50px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderRadius: "8px",
      marginTop: "8px",
      backgroundColor: Colors.BackgroundWhite,
      boxShadow: Shadow.tableTheadShadow,
      cursor: "default",
      "&:hover": { backgroundColor: Colors.BackgroundWater },
   },
   tbodyRowStyle: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderRadius: "8px",
      marginTop: "8px",
      backgroundColor: Colors.BackgroundWhite,
      boxShadow: Shadow.tbodyRowBoxShadow,
      cursor: "pointer",
   },
   tdStyle: {
      maxWidth: "242px",
      minWidth: "70px",
      width: "100%",
      padding: "16px 16px",
      "@media(max-width: 1720px)": { padding: "16px 16px" },
      fontSize: "14px",
      fontWeight: 400,
      color: Colors.TextSecondary,
   },
   contactActionsStyle: {
      display: "flex",
      alignItems: "center",
   },
   iconStyle: { marginRight: "16px" },

   terminationDateTextStyle: { marginLeft: "16px" },
   editModifierIconStyle: {
      width: "24px",
      height: "24px",
      cursor: "pointer",
   },
   loadStyle: {
      display: "flex",
      alignItems: "center",
   },
   radio: {
      color: "#D263E4",
      "&$checked": { color: "#D263E4" },
   },
   checked: { color: "green" },
   percentageBoxStyle: {
      display: "flex",
      alignItems: "center",
      '& .MuiCircularProgress-colorPrimary':{
         color:'#347AF0'
      },
   },
   percTextStyle: { marginRight: "8px", width:'55px' },
   actionBoxStyle: {
      display: "flex",
      alignItems: "center",
   },
   editAuthServiceIconStyle: {
      width: "24px",
      height: "24px",
      cursor: "pointer",
      "& > img": { width: "100%" },
   },
   removeAuthServiceIconStyle: {
      width: "24px",
      height: "24px",
      cursor: "pointer",
      "& > img": { width: "100%" },
      marginLeft: "16px",
   },
}));
