import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import {
    CreateChancel,
    Input, NumericFormatCustom, SelectAutocompleteMultiple,
} from 'components';
import { fundingSourceActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import { foundingSourceModalStyle } from './styles';
import {
    enumValues,
    FindErrorItem,
    FindLoad,
    FindSuccessItem,
    useModal,
} from 'utils';

export const FundingSourceModifiersAdd = ({ info, currentService }) => {
    const ACTION_TYPE = !!info ? 'EDIT_FUNDING_MODIFIER' : 'CREATE_FUNDING_MODIFIER';
    const classes = foundingSourceModalStyle();
    const { close } = useModal();
    const params = useParams();
    const dispatch = useDispatch();
    const success = FindSuccessItem(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const backError = FindErrorItem(ACTION_TYPE);
    const credentials = useSelector((state) => state.system.credentials);
    const {
        handleSubmit,
        control,
        setError,
        clearErrors,
        reset,watch
    } = useForm({
        defaultValues: {
            credentialIds: [],
            type: '',
        },
    });

    useEffect(() => {
        if(info) {
            const result = {
                ...info
            };
            result.credentialIds = info?.credentials?.map((credential) => { return { ...credential, id: credential?._id, name: credential?.name } });
            reset(result)
        }
    }, [info]);

    useEffect(() => {
        if (backError?.error === 'Modifier already exists') {
            setError('name', {
                message: 'Modifier with this name already exists',
            });
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [backError]);

    useEffect(() => {
        if (success) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);


    useEffect(() => {
        return () => {
            clearErrors();
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        };
    }, []);

    function handleCreate(data) {
        const modifierDate = {
            name: data.name,
            billedRate: +data.billedRate,
            allowedRate: +data.allowedRate,
            credentialIds: data?.credentialIds?.map((credential) => credential?.id || credential?._id),
            serviceId: currentService?.id,
            modifierTag: data?.modifierTag ? data?.modifierTag : null,
        };
        data?.region ? modifierDate.region = data?.region : delete modifierDate.region;
        // data.modifierTag ? modifierDate.modifierTag = data.modifierTag : delete modifierDate.modifierTag;
        data.type ? modifierDate.type = data.type : delete modifierDate.type;

        if (!!info) {
            dispatch(fundingSourceActions.editFundingModifier(info?.id, modifierDate, currentService?.id));
        } else {
            dispatch(fundingSourceActions.createFundingModifier(params?.id, modifierDate));
        }
    }

    return (
        <div className={classes.addModifierWrapper}>
            <p className='modal-header-title'>{!!info ? 'Edit Charge Rate' : 'Add Charge Rate'}</p>
            <form
                className={classes.modifierBoxStyle}
                onSubmit={handleSubmit(handleCreate)}
            >
                <Input
                    name='name'
                    label='Title*'
                    variant='outlined'
                    control={control}
                    rules={{
                        maxLength: {
                            value: 40,
                            message: 'Max length is 40 digits.',
                        },
                        required: 'Title is required. Please enter a title for the charge rate.',
                    }}
                />
                <Input
                    name='modifierTag'
                    label='Modifier Name'
                    variant='outlined'
                    maxLength={40}
                    control={control}
                    rules={{
                        maxLength: {
                            value: 40,
                            message: 'Max length is 40 digits.',
                        },
                    }}
                />
                <NumericFormatCustom
                    name={'billedRate'}
                    label={'Billed Rate*'}
                    prefix={'$'}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    control={control}
                    rules={{
                        required: 'Billed Rate is required. Please enter the charge amount.',
                    }}
                />
                <NumericFormatCustom
                    name={'allowedRate'}
                    label={'Allowed Rate*'}
                    prefix={'$'}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    control={control}
                    rules={{
                        required: 'Allowed Rate is required. Please enter the charge amount.',
                    }}
                />
                <Input
                    name='region'
                    label='Region'
                    variant='outlined'
                    control={control}
                    maxLength={50}
                    rules={{
                        maxLength: {
                            value: 50,
                            message: 'Max length is 50 digits.',
                        },
                    }}
                />
                <SelectAutocompleteMultiple
                    options={credentials}
                    multiple={true}
                    label={'Credentials*'}
                    placeholder={'Select the credential'}
                    name='credentialIds'
                    control={control}
                    renderValue={(options) => options?.name}
                    selectedType={'id'}
                    rules={{
                        required: 'Selecting credentials is required. Please choose at least one credential.',
                    }}
                />
                <SelectAutocompleteMultiple
                    options={enumValues.FUNDING_MODIFIER_SERVICE_TYPES}
                    label={'Type'}
                    placeholder={'Type'}
                    name='type'
                    control={control}
                />
                <div className={classes.foundingSourceModalsBodyBlock}>
                    <CreateChancel
                        type='submit'
                        loader={!!loader.length}
                        create={!!info ? 'Save' : 'Add'}
                        chancel={'Cancel'}
                        onClose={close}
                        buttonWidth={'100%'}
                        createButnMargin={'16px'}
                    />
                </div>
            </form>
        </div>
    );
};
