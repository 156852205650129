import React from 'react';
import { motion } from 'framer-motion';
import { animationParams, animationVariants } from 'fragments';

export const AboutUsCard = ({ Icon, title, description }) => {
    return (
        <motion.div
            className='about-us-card-wrapper'
            {...animationParams}
            variants={animationVariants.cardsVariant}
        >
            <div className='about-us-card-header'>
                <Icon className='about-us-card-icon'/>

                <h4 className='about-us-card-title'>
                    {title}
                </h4>
            </div>

            <div className='about-us-card-body'>
                <p className='about-us-card-description'>
                    {description}
                </p>
            </div>
        </motion.div>
    );
};

