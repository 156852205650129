import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { claimDetailsStyle } from './styles';
import {
    AddButton,
    AddNotes,
    UploadModal,
    CustomDelete,
    EditWithIcon, SearchHistory, SimpleModal, ApptFlags, CustomDelByNote,
} from 'components';
import { Images, PermissionList, RolePermission, useModal } from 'utils';
import { claimActions } from 'store';
import { APPEAL, CLOSED_CLAIM, OPEN, PENDING_CLAIM } from './constants';
import { EditClaim } from './core/editClaim';
import { SubmitBillModal } from '../generateClaim.js/submitBillModal';
import { HtmlTooltip } from '../../../../components/tabs/fullTableCore/htmlTool';
import { NumericFormat } from 'react-number-format';
import { SelectSubmissionMethod } from '../../../../pages/billing/claims/core';

const ACTION_TYPE = 'SUBMIT_CLAIM_BY_ID';
const REFRESH_ACTION_TYPE = 'REFRESH_CLAIM';
const CLOS_CLAIM_ACTION_TYPE = 'CLOSE_CLAIM';

export const ClaimDetailHeader = ({}) => {
    const claimById = useSelector((state) => state.claim.claimById);
    const classes = claimDetailsStyle();
    const history = useHistory();
    const info = history?.location?.state;
    const dispatch = useDispatch();
    const { open, close } = useModal();
    const claimIsClosed = claimById?.status === CLOSED_CLAIM;
    const closeButnStyle = `${classes.closeButnStyle} ${claimIsClosed ? 'closed' : ''}`;
    const [openPdf, setOpenPdf] = useState(false);

    return (
        <Fragment>
            <div className="tabs-wrapper">
                <div className="space-between-flex-start" style={{ height: 36 }}>
                    <div className="detail-page-title-box">
                        <p>Claim ID: </p>
                        <span> {claimById?.displayId}</span>
                        {(claimById?.updateFlag || claimById?.incompleteUnitFlag || claimById?.removedFromClaimFlag) && claimById?.status === PENDING_CLAIM &&
                            <ApptFlags id={' '} updateFlag={claimById?.updateFlag}
                                       incomplete={claimById?.incompleteUnitFlag}
                                       removed={claimById?.removedFromClaimFlag} />
                        }
                    </div>
                    <div className="flex-align-start" style={{ gap: 16 }}>
                        {(!info?.activeTab || info?.activeTab === 'Details') &&
                            <div className="flex-align-center" style={{ gap: 16 }}>
                                {
                                    <>
                                        <EditWithIcon handleClick={() => open(<EditClaim claim={claimById} />)} />

                                        {RolePermission([PermissionList.CLAIM_REFRESH?.code]) &&
                                            claimById?.status !== CLOSED_CLAIM &&
                                            claimById?.status !== PENDING_CLAIM &&
                                            <button className={classes.refreshClaimBtn}
                                                    type="button"
                                                    onClick={() => open(
                                                        <CustomDelete
                                                            text={`Are you sure you want to refresh the claim data? All fields will be populated with latest data, but will not be saved until the claim is resubmitted.`}
                                                            info="Refresh Claim Data"
                                                            handleDel={() =>
                                                                dispatch(claimActions.refreshClaim(claimById?.id))
                                                            }
                                                            afterSuccess={() => {
                                                                setOpenPdf(true);
                                                                close();

                                                            }}
                                                            actionType={REFRESH_ACTION_TYPE}
                                                            color={'blue'}
                                                            innerText={'Refresh'}
                                                        />,
                                                    )}
                                            >
                                                <img src={Images.refresh} alt={'icon'} />
                                            </button>
                                        }


                                        {RolePermission([PermissionList.CLAIMS_UPDATE?.code]) && claimById?.status === PENDING_CLAIM &&
                                            <>
                                                    <button
                                                        style={{ opacity: (claimById?.incompleteUnitFlag || claimById?.updateFlag) ? 0.5 : 1 }}
                                                        disabled={claimById?.incompleteUnitFlag || claimById?.updateFlag}
                                                        className={classes.submitClaimBtn}
                                                        type="button"
                                                        onClick={() => open(
                                                            <SelectSubmissionMethod
                                                                afterSuccess={() => {
                                                                    dispatch(claimActions.getClaimById(claimById.id, 'noLoad'));
                                                                }}
                                                                selectedList={[claimById?.id]}/>
                                                            // <CustomDelete
                                                            //     text={`Are you sure you want submit this claim?`}
                                                            //     info="Submit Claim"
                                                            //     handleDel={() => dispatch(claimActions.submitClaimById(claimById?.id))}
                                                            //     actionType={ACTION_TYPE}
                                                            //     color={'blue'}
                                                            //     innerText={'Submit'}
                                                            // />,
                                                        )}
                                                    >
                                                        Submit Claim
                                                    </button>
                                            </>
                                        }


                                        {RolePermission([PermissionList.CLAIMS_CLOSE?.code]) &&
                                            (claimById?.status === OPEN || claimById?.status === APPEAL) &&
                                            <button
                                                className={closeButnStyle}
                                                type="button"
                                                onClick={() => open(
                                                    <CustomDelByNote
                                                        info="Close this claim?"
                                                        text="Please indicate below the reason for closing the claim."
                                                        handleDel={(closingComment) => {
                                                            dispatch(claimActions.closeClaim(claimById?.id, closingComment));
                                                        }}
                                                        innerText={'Close'}
                                                        inputLabel={'Add your comment here ...*'}
                                                        actionType={CLOS_CLAIM_ACTION_TYPE}
                                                    />)
                                                }
                                                disabled={claimIsClosed}
                                            >
                                                {'Close Claim'}
                                            </button>
                                        }
                                        {claimById?.status === CLOSED_CLAIM &&
                                            <button
                                                className={closeButnStyle}
                                                type="button"
                                                disabled={true}
                                            >
                                                {'Closed'}
                                            </button>
                                        }
                                    </>
                                }
                            </div>
                        }


                        {
                            info?.activeTab === 'Notes' && RolePermission([PermissionList.CLAIM_NOTE_MANAGE?.code]) ? (
                                    <AddButton text="Add Note" handleClick={() => open(
                                        <AddNotes
                                            id={claimById?.id}
                                            model="Claim"
                                        />,
                                    )} />
                                ) :
                                info?.activeTab === 'History' && RolePermission([PermissionList.CLAIM_HISTORY_READ?.code]) ? (
                                        <SearchHistory />
                                    ) :
                                    info?.activeTab === 'Files' && RolePermission([PermissionList.CLAIM_FILE_MANAGE?.code]) ?
                                        <AddButton
                                            Icon={true}
                                            customIcon={Images.uploadCloud}
                                            text={'Upload Document'}
                                            handleClick={() => open(
                                                <UploadModal
                                                    currentId={claimById?.id}
                                                    onResource={'claim'}
                                                />,
                                            )}
                                        />
                                        : null
                        }
                    </div>
                </div>
            </div>


            <SimpleModal
                handleOpenClose={() => setOpenPdf(false)}
                openDefault={openPdf}
                flexEnd={true}
                content={
                    <div>
                        <button onClick={() => setOpenPdf(false)} className="close-form-btn">
                            <img src={Images.closeButton} alt="close" />
                        </button>
                        <SubmitBillModal
                            handleClose={() => setOpenPdf(false)}
                        />
                    </div>
                }
            />
        </Fragment>
    );
};
