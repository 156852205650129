import { useState } from 'react';
import axios from 'axios';
import { Images, token } from 'utils';
import { MinLoader } from 'components';

export const Show1500Form = ({ batch, item }) => {
    const [loading, setLoading] = useState(false);

    const handleExport = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setLoading(true);
        axios({
            url: batch ? `/claim/batch/${item?.id}/1500Forms/PDF` : `/claim/${item?.id}/1500Form/PDF`,
            method: 'GET',
            responseType: 'blob',
            headers: { 'access-token': `${token}` },
        }).then((response) => {
            const blob = new Blob([response?.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <div>
            <button onClick={handleExport} className="reset-btn-by-default" style={{ height: 15, width: 15 }}>
                {loading ?
                    <MinLoader color={'#0C66E4'} small={true} margin={'0'} position={'relative'} />
                    :
                    <img src={Images.showAyeBlue} alt="" />
                }
            </button>
        </div>
    );
};