import React from 'react';
import { Images, staffStatuses, tagEnums } from 'utils';
import { PhoneRow, TextRow, FullNameRow, DateRow, TagComponent, StatusCard, FilterTags } from 'components';

export const staffHead = (deps) => {
    return [
        { name: 'fullName', title: 'Full Name', searchKey: 'NAME' },
        {
            name: 'department', title: 'Department', disabled: true,
            filterList: [
                { name: 'All', id: 'All' },
                { name: 'N/A', id: 'N/A' },
                ...deps,
            ],
        },
        { name: 'jobTitle', title: 'Job Title', disabled: true },
        { name: 'email', title: 'Email Address', searchKey: 'EMAIL', smallSize: 6, custom: false },
        { name: 'phone', title: 'Phone Number', searchKey: 'PHONE', custom: false, width: '150px' },
        { name: '', title: 'Hired Date', custom: false, width: '115px' },
        { name: '', title: 'Tags', custom: false, renderView: <FilterTags/>  },
        { name: 'status', title: 'Status', disabled: true, width: '120px',
            filterList:  [
                { name: 'All', id: 'All' },
                ...staffStatuses,
            ],
        },
    ];
};
export const staffBody = (requestParams, afterSuccess ) => [
    {
        rowText: (item) =>
            <div  className="info-and-avatar">
                <div className="avatar-box">
                    {item?.avatar?.url ?
                        <img src={item?.avatar?.url } alt={'avatar'} />
                        :
                        `${item?.firstName?.slice(0, 1)}${item?.lastName?.slice(0, 1)}`
                    }
                </div>
                <FullNameRow
                    wrapperStyle={{fontWeight: 600}}
                    firstName={item?.firstName} lastName={item?.lastName} middleName={item?.middleName}
                />
            </div>
    },
    { rowText: (item) => <TextRow name={item?.employment?.department?.name ? item?.employment?.department?.name : 'N/A'} textWidth={8} />, },
    { rowText: (item) => <TextRow name={item?.employment?.title?.name} textWidth={8} /> },
    { rowText: (item) => <TextRow name={item?.email} /> },
    { rowText: (item) => <PhoneRow phone={item?.phone} /> },
    { rowText: (item) => <DateRow date={item?.hiredDate} /> },
    { rowText: (item) => <TagComponent userInfo={item} module={tagEnums.STAFF} requestParams={requestParams} afterSuccess={afterSuccess} />, notClickable: true },
    { rowText: (item) => <StatusCard status={item?.status}/> },
];

export const ACTION_TYPE = 'GET_ADMINS';

export const staffNotYet = {
    title: 'No Staff Added Yet',
    subTitle: 'Start by adding staff members to manage your clients and services.',
    image: Images.noClientYet,
};
