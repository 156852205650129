import { makeStyles } from '@material-ui/core/styles';
import { Shadow, Backgrounds, Colors } from 'utils';

export const myProfileFragment = makeStyles(() => ({
    deleteAvatarBtn: {
        background: 'transparent',
        border: 'none',
        cursor: 'pointer',
        position: 'absolute',
        width: '16px',
        height: '16px',
        top: '20px',
        right: '130px',
        zIndex: 5,
    },
    online: {
        width: '12px',
        height: '12px',
        borderRadius: '6px',
        border: '1.5px solid #FFF',
        background: '#12B76A',
        position: 'absolute',
        bottom: '0',
        right: '0',
    },
    avatarLoader: {
        width: '100%',
        textAlign: 'center',
        padding: '0 24px',

        '& p': {
            color: '#0C66E4',
            fontWeight: 600,
            marginTop: 22,
        },
        '& .MuiLinearProgress-root': {
            height: 8,
            borderRadius: 4,
        },
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: '#6FD231',
        },
    },
    wrapper: {
        width: '400px',
        maxHeight: '70vh',
        overflowY: 'auto',
        position: 'absolute',
        right: '0',
        top: '50px',
        padding: '24px',
        boxShadow: Shadow.modalShadow,
        background: Backgrounds.whiteModal,
        borderRadius: '8px',
        fontFamily: 'Open Sans, sans-serif !important',

        '@media (min-width: 1920px)': {
            width: '395px',
            padding: '24px',
            right: '20px',
        },
    },
    staffInfoWrapper: {
        marginBottom: '24px',
        display: 'flex',
        alignItems: 'flex-start',
        gap: '16px',
    },
    staffAvatar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        width: '56px',
        height: '56px',
        borderRadius: '50%',
        border: '4px solid rgba(235, 242, 253, 0.50)',
        boxShadow: '0px 0px 0px 4px #EBF2FD',

        '& img': {
            width: '56px',
            height: '56px',
            minWidth: '56px',
            borderRadius: '50%',
            objectFit: 'cover',
        },
    },

    editBlack: {
        filter: 'brightness(0) saturate(100%) invert(36%) sepia(5%) saturate(1723%) hue-rotate(162deg) brightness(90%) contrast(87%)',
        cursor: 'pointer',
    },
    staffName: {
        color: '#4B5C68',
        fontSize: '18px',
        fontWeight: '600',
        lineHeight: '28px',
    },
    staffEmail: {
        color: '#A3B2BD',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
    },
    avatar: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',

        '& p': {
            fontSize: '18px',
            fontWeight: 'bold',
            color: Colors.TextSecondary,
            marginTop: '16px',
            '@media (min-width: 1920px)': {
                marginTop: '24px',
            },
        },
    },

    userInfo: {
        background: Backgrounds.info,
        borderRadius: '8px',
        padding: '16px 16px 6px 16px',
        margin: '16px 0 16px 0',
        '@media (min-width: 1920px)': {
            marginTop: '24px',
        },
    },

    infoSections: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
        '& p': {
            marginLeft: '8px',
            fontSize: '14px',
            color: Colors.TextPrimary,
            lineHeight: '19px',
        },
    },

    infoBoxWrapper: {
        marginBottom: '8px',
        borderRadius: '8px',
        border: '1px solid #ECF1FA',
        background: '#FFF',
        padding: '16px',

        '& .check-password-message': {
            textAlign: 'start !important',
        },
    },

    changePasswordContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        // '& div': {
        //     display: 'flex',
        //     alignItems: 'center',
        //     cursor: 'pointer',
        //
        //     '& p': {
        //         fontSize: '14px',
        //         fontWeight: 600,
        //         color: Colors.ThemeBlue,
        //         marginLeft: '8px',
        //     },
        // },
    },

    infoBoxTitle: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#4B5C68',
        marginTop: '5px',
    },
    infoBoxDesc: {
        fontSize: '12px',
        fontWeight: '400',
        color: '#475467',
        marginTop: '4px',
        lineHeight: '20px',
    },
    changePasswordTextInfo: {
        marginTop: '17px',
        fontSize: '14px',
        lineHeight: '21px',
        color: Colors.TextLight,
    },

    signOutButton: {
        height: '40px',
        width: '100%',
        background: 'transparent',
        borderRadius: '8px',
        marginTop: '24px',
        border: '1px solid #FE7070',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        // '&:hover': {
        //     backgroundColor: Backgrounds.darkBlue,
        //     boxShadow: Shadow.blueButton,
        // },
        '& p': {
            color: '#FE7070',
            fontSize: '14px',
            fontWeight: '600',
            marginLeft: '8px',
        },

        '& img': {
            filter: 'invert(45%) sepia(98%) saturate(380%) hue-rotate(312deg) brightness(104%) contrast(99%)',
        },
    },

    miniLoader: {
        marginRight: '50px',
    },
    actionsBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '16px',

        '& button': {
            padding: '8px 24px',
            borderRadius: '4px',
            height: '36px',
        },
    },
    cancelButton: {
        border: '1px solid #D0D5DD',
        background: '#FFF',
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
        color: '#4B5C68',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '20px',
        width: '92px',
    },
    saveButton: {
        fontSize: '14px',
        fontWeight: '600',
        color: 'white',
        border: '1px solid #347AF0',
        background: Colors.ThemeBlue,
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
        width: '80px',
    },
    newChip: {
        marginLeft: 8,
        width: 44,
        height: 20,
        borderRadius: '16px',
        background: '#F9F5FF',
        padding: '2px 8px',
        color: '#6941C6',
        textAlign: 'center',
        fontSize: '12px',
        fontWeight: '600',
        lineHeight: '18px',
    },
    notificationInfoBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
        gap: '12px',
        width: '100%',
        padding: '12px 0 24px 0',
        borderBottom: '1px solid #E4E7EC',
    },

    pushTitle:{
        color: '#172B4D',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '21px',
    },
    pushSubTitle:{
        color: '#475467',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '20px',
    },
}));
