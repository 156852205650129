import React from 'react';
import { useSelector } from 'react-redux';
import { getInvoiceDetails, invoiceDetailBody, invoiceDetailHead } from './constants';
import { FullTable } from 'components';
import { useModal } from 'utils';
import { ReceivableBillTable } from '../../claims';

export const InvoiceDetailTab = () => {
    const invoiceById = useSelector((state) => state.invoice.invoiceById);
    const filteredDetails = getInvoiceDetails(invoiceById).filter((invoiceDtl) => !!invoiceDtl.detail);
    const { open } = useModal();

    function getDetailDisplay(detail) {
        if (detail && detail !== 'Undefined Undefined') {
            return detail;
        } else {
            return 'N/A';
        }
    }

    return (
        <div>

            <div className="details-wrapper">
                {!!filteredDetails.length && (
                    <ol className="details-list-box">
                        {filteredDetails.map((item, index) => item?.detail && (
                            <li key={index}
                                style={{ background: item?.background ? item?.background : '#F4F5F7' }}
                            >
                                <p className="detail-title-style"> {item.detailText}</p>
                                <p className="detail-value-style">{getDetailDisplay(item.detail)}</p>
                            </li>
                        ))}
                    </ol>
                )}
            </div>

            <div>
                <p className="details-title-box">Bills </p>
                <FullTable
                    head={invoiceDetailHead}
                    body={invoiceDetailBody}
                    list={invoiceById?.bills?.length ? invoiceById?.bills : []}
                    handleClick={(e, item) =>
                        open(<ReceivableBillTable currentId={e} defaultBill={item} from={'invoice'}/>)
                    }
                    noText={'bills'}
                    activeRowId={''}
                    generateTable={true}
                    height={'auto'}

                />
            </div>
        </div>
    );
};