import React from 'react';
import { contactOptions, contactSocial } from './constants';

export const SocialContactUs = () => {
    return (
        <div className='social-contact-us-wrapper'>
            <div className='container'>
                <div className='social-contact-us-box'>

                    <div className='social-contact-us-info'>
                        <h4 className='social-contact-us-title'>
                            Contact Info
                        </h4>

                        <div className='social-contact-us-items-block'>
                            {contactOptions.map(({ Icon, details, label }, index) => (
                                <div className='social-contact-us-items' key={index}>
                                    <Icon className='social-contact-us-svg' />

                                    <a target='_blank' href={details} className='social-contact-us-link'>
                                        {label}
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="contact-us-social">
                        <h4 className="social-contact-us-title">
                            Get in Touch
                        </h4>
                        <div className="social-contact-us-items-block">
                            {contactSocial.map(({ Icon, label, details }, index) => (
                                <div className="social-contact-us-items gap-8" key={index}>
                                    <Icon className="social-contact-us-svg" />

                                    <a target="_blank" href={details} className="social-contact-us-link">
                                        {label}
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>


                    {/*<div className='social-contact-us-experience'>*/}
                    {/*    <div style={{ paddingBottom: '12px', borderBottom: '1px solid rgba(28, 28, 28, 0.08)' }}>*/}
                    {/*        <p className='title-bold'>*/}
                    {/*            10+ years*/}
                    {/*        </p>*/}

                    {/*        <p className='sub-title'>*/}
                    {/*            Field experience*/}
                    {/*        </p>*/}
                    {/*    </div>*/}

                    {/*    <div style={{ paddingTop: '12px' }}>*/}
                    {/*        <p className='title-bold'>*/}
                    {/*            1000+ customers*/}
                    {/*        </p>*/}

                    {/*        <p className='sub-title'>*/}
                    {/*            Around world*/}
                    {/*        </p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
};

