import React from "react";
import {ClientAddress} from "./clientAddress";
import { ClientGeneralInfo } from './clientGeneralInfo';
import { ClientOtherInfo } from './clientOtherInfo';

export const ClientGeneral = ({ }) => {

    return (
        <div className="information-card-wrapper">
            <ClientGeneralInfo />
            <ClientOtherInfo/>
            <ClientAddress/>
        </div>
    );
};
