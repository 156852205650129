import React from 'react';
import { CustomizedSwitch, DateRow, InactivateBtb, PriceRow, SimpleTooltip, TextRow, TypeRow } from 'components';
import { Images } from 'utils';
import moment from 'moment/moment';

export const OVERTIME_ACTION = 'GET_OVERTIME_SETTINGS_GLOBAL';
export const overtimeHead = [
    { name: 'name', title: 'Name', noSearch: true, custom: false },
    {
        name: 'type', title: 'Type', noSearch: true,
        filterList: [
            { name: 'All', id: 'All' },
            { name: 'Daily', id: 'DAILY' },
            { name: 'Weekly', id: 'WEEKLY' },
            { name: 'Consecutive', id: 'CONSECUTIVE' },
        ],
    },
    { name: 'threshold', title: 'Threshold', noSearch: true, custom: false },
    { name: 'multiplier', title: 'Multiplier', noSearch: true, custom: false },
    { name: 'action', title: 'Action', noSearch: true, custom: false, width: '100px' },

];

export const overtimeBody = [
    { rowText: (item) => <TextRow name={item?.name} textWidth={10} /> },
    { rowText: (item) => <TypeRow text={item?.type ? item?.type : ''} textWidth={10} /> },
    {
        rowText: (item) => <TextRow
            name={item?.threshold ? (`${item?.threshold} ${item?.type === 'CONSECUTIVE' ? 'days' : 'hours'}`) : '---'}
            textWidth={10} />,
    },
    { rowText: (item) => <TextRow name={item?.multiplier} textWidth={10} /> },
    {
        button: (cb) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                    style={{ cursor: 'pointer', marginRight: '16px' }}
                    src={Images.edit}
                    alt="edit"
                    onClick={() => cb('edit')}
                />
            </div>
        ),
        notClickable: true,
    },
];

export const PAYCODE_ACTION = 'GET_PAY_CODE_GLOBAL';
export const paycodeHead = [
    { name: 'name', title: 'Name', noSearch: true, custom: false },
    { name: 'code', title: 'Code', noSearch: true, custom: false },
    {
        name: 'type', title: 'Type', noSearch: true,
        filterList: [
            { name: 'All', id: 'All' },
            { name: 'Hourly', id: 'HOURLY' },
            { name: 'Salary', id: 'SALARY' },
            { name: 'Fixed', id: 'FIXED' },
        ],
    },
    {  renderView: <TextRow name={'Overtime Applied'}/> },
    {  renderView: <TextRow name={'PTO Accrued'}/> },
    { name: 'action', title: 'Action', noSearch: true, custom: false, width: '100px' },
];

export const paycodeBody = [
    { rowText: (item) => <TextRow name={item?.name} textWidth={10} /> },
    { rowText: (item) => <TextRow name={item?.code} textWidth={10} /> },
    { rowText: (item) => <TypeRow text={item?.type ? item?.type : ''} textWidth={10} /> },
    { rowText: (item) => <TextRow name={item?.overtime ? 'Yes' : 'No'} textWidth={10} /> },
    { rowText: (item) => <TextRow name={item?.pto ? 'Yes' : 'No'} textWidth={10} /> },
    {
        button: (cb) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                    style={{ cursor: 'pointer', marginRight: '16px' }}
                    src={Images.edit}
                    alt="edit"
                    onClick={() => cb('edit')}
                />
            </div>
        ),
        notClickable: true,
    },
];


export const TERMINATE_MILEAGE = 'TERMINATE_MILEAGE';
export const MILEAGE_ACTION = 'GET_MILEAGES';
export const mileageHead = [
    { renderView: <TextRow name={'Mileage Compensation'} /> },
    { renderView: <TextRow name={'Start Date'} /> },
    { renderView: <TextRow name={'End Date'} /> },
    { name: 'action', title: 'Action', noSearch: true, custom: false, width: '150px' },
];

export const mileageBody = (handleOpen) => {
    return [
        { rowText: (item) => <PriceRow info={item?.compensation} textWidth={10} /> },
        { rowText: (item) => <DateRow date={item?.startDate} /> },
        {
            rowText: (item) => item?.endDate ? <DateRow date={item?.endDate} /> :
                <TextRow name={'Present'} textWidth={10} />,
        },
        {
            button: (cb, item) => (
                <div>
                    {item?.endDate ?
                        <SimpleTooltip title={<p>Inactivated {moment.utc(item?.endDate).format('MM/DD/YYYY')}</p>}
                                       placement="top-start">
                            <div className="flex-align-center" style={{ gap: '8px' }}>
                                <div className="red-circle" />
                                <p>{item?.endDate ? moment.utc(item?.endDate).format('MM/DD/YYYY') : ''}</p>
                            </div>
                        </SimpleTooltip>
                        :
                        <div className="flex-align-center" style={{ gap: '8px' }}>
                            <InactivateBtb handleClick={() => handleOpen(item, 'inactive')} />
                            <button className="reset-btn" onClick={() => handleOpen(item, 'edit')}>
                                <img src={Images.edit} alt="icon" />
                            </button>
                        </div>
                    }
                </div>
            ),
            notClickable: true,
        },
    ]
        ;
};
