import React, { useEffect, useState } from 'react';
import { managementFragments, popperUseStyles } from './style';
import {
    Colors,
    FindLoad,
    FindSuccess,
    Images, PermissionList, RolePermission, useModal,
} from 'utils';
import {
    AddCircle,
    DeleteButton,
    CloseButton,
    CheckboxesTags,
    NoInfoYet, TextRow, MinLoader, CustomDelete, CheckboxComponentBtn,
} from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { httpRequestsOnSuccessActions, roleActions } from 'store';

const ADD_ACTION_TYPE = 'ADD_ROLE_PERMISSION';
const DELETE_ACTION_TYPE = 'DELETE_ROLE_PERMISSION';
export const RolePermissions = ({ permissionsList }) => {
    const { role } = useSelector((state) => ({
        role: state.roles.role,
    }));
    const [show, setShowInput] = useState(false);
    const [permission, sePermission] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const classes = managementFragments();
    const popperClasses = popperUseStyles();
    const dispatch = useDispatch();
    const loadAdd = FindLoad(ADD_ACTION_TYPE);
    const success = FindSuccess(DELETE_ACTION_TYPE);
    const { open } = useModal();

    useEffect(() => {
        if (role?.permissions) {
            const filter = permissionsList?.filter(function(array_el) {
                return role?.permissions?.filter(function(anotherOne_el) {
                    return anotherOne_el.id === array_el.id;
                }).length === 0;
            });
            setFilteredList(filter);
            sePermission([]);
        }
    }, [role]);

    useEffect(() => {
        if (!!success.length) {
            sePermission('');
            dispatch(httpRequestsOnSuccessActions.removeSuccess(DELETE_ACTION_TYPE));
        }
    }, [success]);

    const handleAdd = (event) => {
        event?.stopPropagation();
        event?.preventDefault();
        if (permission.length) {
            const body = {
                roleId: role.id,
                permissionIds: permission?.map((i) => i?.id),
            };

            dispatch(roleActions.addRolePermission(body));
        }
    };

    const changePermissions = (value) => {
        sePermission(value);
    };

    return (
        <div className={classes.tableStyle}>
            <div className={classes.tableRoleHeadStyle}>
                <div className={classes.rolePermissionsStyle}>
                    <div className={classes.permissionIcon}>
                        <img src={Images.accessManagementFill} alt={'accessManagementFill'} />
                    </div>
                    <p>Role Permissions</p>
                </div>
            </div>

            <div style={{ marginTop: '8px' }} className={classes.tableRoleStyle}>
                <div className={classes.roleNameStyle}>
                    <div>
                        <span>{role && role.permissions ? role.title : ''}</span>
                    </div>
                    {RolePermission([PermissionList.ROLES_CREATE?.code]) &&
                    role && role.title ? (
                        <div>
                            {show === false ? (
                                <AddCircle
                                    handleCLic={() => setShowInput(true)}
                                    text={'Add Permissions'}
                                />
                            ) : (
                                <CloseButton
                                    styles={{ backgroundColor: Colors.BackgroundWater }}
                                    handleCLic={() => setShowInput(false)}
                                />
                            )}
                        </div>
                    ) : null}
                </div>

                {show === true && (
                    <div className={classes.tablePermissionsBodyStyleWrapper}>
                        <div className={classes.tablePermissionsBodyStyle}>
                            <CheckboxesTags
                                removeWhenClose={true}
                                name="permissions"
                                handleChange={changePermissions}
                                permissionsList={filteredList}
                                value={permission || []}
                                label={'Select Permissions'}
                                placeholder={'Permissions'}
                                CustomPaperComponent={
                                    <div
                                        onClick={(e) => e.stopPropagation()}
                                        className={popperClasses.footer}
                                    >
                                        <div className={popperClasses.infoIconWrapper}>
                                            <div className={popperClasses.warnIconBox}>
                                                <img src={Images.alertTriangle} alt="icon" />
                                            </div>
                                            <p>Please confirm your selections by clicking 'Add Permissions' →</p>
                                        </div>
                                        {RolePermission([PermissionList.ROLES_CREATE?.code]) &&
                                            <CheckboxComponentBtn
                                                handleClick={handleAdd}
                                                list={permission}
                                                loadCreate={loadAdd}
                                                btnText={'Add Permissions'}
                                            />
                                        }
                                    </div>
                                }
                            />
                        </div>
                    </div>
                )}

                <div className={classes.scrollPermission}
                     style={{ height: show ? '350px' : '400px'}}
                >
                    {role?.permissions?.length ? (
                        role?.permissions?.map((i, j) => (
                            <div key={j} className={classes.tablePermissionsBodyContentStyle}>
                                <div className={'full-width'}>
                                    <img src={Images.checked} alt={'checked'} />
                                    <p className={classes.tablePermissionsTitle}>
                                        <TextRow name={i?.title} textWidth={8} />
                                    </p>
                                    <p className={classes.tablePermissionsDesc}>
                                        <TextRow name={i?.description} textWidth={8} />
                                    </p>
                                </div>
                                <div>
                                    {RolePermission([PermissionList.ROLES_CREATE?.code]) &&
                                        <DeleteButton
                                            toolTipTitle={'Remove Permission'}
                                            handleClick={() => open(
                                                <CustomDelete
                                                    text="Are you sure you want remove this Permission ?"
                                                    info={i?.title}
                                                    handleDel={() => dispatch(roleActions.deleteRolePermission({
                                                        roleId: role?.id,
                                                        permissionId: [i?.id],
                                                    }))}
                                                    handleClose={() => close()}
                                                    actionType={DELETE_ACTION_TYPE}
                                                    innerText={'Remove'}
                                                />,
                                            )}
                                        />
                                    }
                                </div>
                            </div>
                        ))
                    ) : (
                        <NoInfoYet text={'Select Role'} />
                    )}
                </div>
            </div>
        </div>
    );
};