import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Colors,
    ErrorText,
    FindLoad,
    FindSuccess,
    FindSuccessItem,
    isNotEmpty,
    useGlobalTextStyles,
    useModal,
} from 'utils';
import { appointmentActions, httpRequestsOnSuccessActions } from 'store';
import { modalsStyle } from '../../../../components/modal/styles';
import { reasonEnums, reasonList } from './apptDetail/constants';
import { FormControlLabel, Radio } from '@material-ui/core';
import { MinLoader, UserTextArea } from 'components';


export const CancelAppt = ({ afterSuccess, edit }) => {
    const { item } = useSelector((state) => ({
        item: state.appointment.appointmentById,
    }));
    const SET_APPOINTMENT_STATUS = 'SET_APPOINTMENT_STATUS';
    const ACTION_TYPE = edit ? 'CHANGE_CANCEL_REASON' : 'SET_APPOINTMENT_STATUS_CANCEL';
    const classes = modalsStyle();
    const globalText = useGlobalTextStyles();
    const loader = FindLoad(ACTION_TYPE);
    const success = FindSuccessItem(SET_APPOINTMENT_STATUS);
    const dispatch = useDispatch();
    const { close } = useModal();
    const [comment, setComment] = React.useState('');
    const [error, setError] = React.useState('');
    const [reason, setReason] = useState(reasonEnums.OTHER);

    useEffect(() => {
        if (item && edit) {
            setReason(item?.cancellation?.type);
            setComment(item?.cancellation?.reason);
        }
    }, [item]);

    const changeReason = (e) => {
        setReason(e.target.value);
        if (error) {
            setError('');
        }
    };

    useEffect(() => {
        if (success) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(SET_APPOINTMENT_STATUS));
            setComment('');
            setError('');

            if (afterSuccess) {
                afterSuccess();
            }
        }
    }, [success]);

    const handleSubmit = () => {
        const params = {
            type: reason,
            reason: comment,
        };

        if (reason) {
            if (edit) {
                dispatch(appointmentActions.editCancelReason(item?.id, params));
            } else {
                dispatch(appointmentActions.setAppointmentStatus(item?.id, 'cancel', params));
            }
        }
    };

    return (
        <div className={classes.customDeleteModal}>
            <div>

                <p className={globalText.modalTitle}>{edit ? 'Edit Cancellation Reason' : 'Cancel Appointment?'}</p>
                    <p className={globalText.modalText}>{edit ? '' : 'Are you sure you want to cancel this appointment? This actioncannot be undone.' }</p>
                <div className="reasons-wrapper">
                    <p className="reason-for">Select a reason for cancellation</p>
                    {reasonList.map((i, j) => (
                        <FormControlLabel
                            key={j}
                            onChange={changeReason}
                            name="generateType"
                            value={i?.value}
                            label={
                                <span style={{ fontWeight: reason === i?.value ? 600 : 400 }}>
                                {i?.title}
                            </span>
                            }
                            control={
                                <Radio
                                    style={{ color: reason === i?.value ? '#347AF0' : '#D0D5DD' }}
                                    checked={reason === i?.value}
                                />
                            }
                        />
                    ))}
                </div>

                <div>
                    <UserTextArea
                        margin={'0'}
                        id={'comment'}
                        name={'comment'}
                        label={`Additional Reason`}
                        value={comment ? comment : ' '}
                        onChange={(e) => {
                            setComment(e.target.value);
                            error && setError('');
                        }}
                        max={100}
                        hasText={!!comment}
                        maxCharsLabel={`${comment.length}/100`}
                        typeError={error === 'removeNote' && `Comment ${ErrorText.isRequired}`}
                        placeholder={'Enter additional reason'}
                    />
                </div>

                <div className={classes.buttons}>
                    <button className="close-btn-box" onClick={() => close()}>
                        Cancel
                    </button>
                    <button className={edit ? 'confirm-btn' : 'delete-btn-box'} onClick={handleSubmit}>
                        {loader?.length ? (
                            <MinLoader margin={'0'} color={Colors.TextWhite} />
                        ) : (
                            edit ? 'Save Changes' : 'Cancel Appointment'
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};
