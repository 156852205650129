import { combineReducers } from 'redux';
import { authReducer } from '../auth';
import { adminReducer } from '../admin';
import { systemReducer } from '../system';
import { noteReducer } from '../notes';
import { payrollReducer } from '../payroll';
import { permissionsReducer } from '../permissions';
import { roleReducer } from '../role';
import { fundingSourceReducer } from '../fundingSource';
import { clientReducer } from '../client';
import { httpRequestsOnLoadReducer } from '../http_requests_on_load';
import { httpRequestsOnSuccessReducer } from '../http_requests_on_success';
import { httpRequestsOnErrorsReducer } from '../http_requests_on_errors';
import { availabilityScheduleReducer } from '../availabilitySchedule';
import { appointmentReducer } from '../appointment';
import { claimReducer, invoiceReducer } from '../billing';
import { claimPaymentReducer, invoicePaymentReducer } from '../posting';
import { historyReducer } from '../history';
import { reportsReducer } from '../reports';
import { staffPayrollReducer } from '../staffPayroll';
import { notificationReducer } from '../notification';
import { analyticsReducer } from '../analytics';
import { tagReducer } from '../tags';

const initialState = {
    isLoading: false,
    error: false,
};

const globalReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};
export const appReducer = combineReducers({
    auth: authReducer,
    global: globalReducer,
    admins: adminReducer,
    permissions: permissionsReducer,
    roles: roleReducer,
    fundingSource: fundingSourceReducer,
    client: clientReducer,
    system: systemReducer,
    note: noteReducer,
    payroll: payrollReducer,
    availabilitySchedule: availabilityScheduleReducer,
    appointment: appointmentReducer,
    claim: claimReducer,
    invoice: invoiceReducer,
    claimPayment: claimPaymentReducer,
    invoicePayment: invoicePaymentReducer,
    history: historyReducer,
    reports: reportsReducer,
    staffPayroll: staffPayrollReducer,
    notifications: notificationReducer,
    analytics: analyticsReducer,
    tags: tagReducer,

    httpOnLoad: httpRequestsOnLoadReducer,
    httpOnSuccess: httpRequestsOnSuccessReducer,
    httpOnError: httpRequestsOnErrorsReducer,
});
