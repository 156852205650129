export const renderClientName = (client) => {
    return client ?
        `${client?.code ? `(${client?.code})` : ''} ${client?.lastName ? `${client?.lastName},` : ''} ${client?.firstName ? client?.firstName : ''} ${client?.middleName ? `${client?.middleName?.slice(0, 1)}.` : ''} ` :
        'N/A';
};

export const renderStaffName = (staff) => {

    return staff ?
        `${staff?.lastName ? `${staff?.lastName},` : ''} ${staff?.firstName ? staff?.firstName : ''} ${staff?.middleName ? `${staff?.middleName?.slice(0, 1)}.` : ''} ` :
        'N/A';

};
