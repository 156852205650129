import React from 'react';
import { FullTable } from 'components';
import { useModal } from 'utils';
import { ReportsBody, ReportsHead, ReportsNotYet } from './constants';
import { GenerateReport, payrollReportEnums } from './core';

export const PayrollReports = ({}) => {
    const { open } = useModal();

    const openGenerate = (item) => {
        open(<GenerateReport info={item?.generateInfo} />);
    };

    return (
        <div>
            <FullTable
                head={ReportsHead}
                body={ReportsBody(openGenerate)}
                list={payrollReportEnums()}
                notYet={ReportsNotYet('Payroll')}
                activeRowId={''}
                margin={'0'}
            />
        </div>
    );
};