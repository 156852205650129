import {
    logIn,
    logOut,
    getRecoveryLink,
    tryAgain,
    resetPassword,
    changePassword,
    getMyProfile,
    getAccess,
    assignAccess,
    removeAccess,
    changeNotifications,
} from './auth.action';
export { authReducer } from './auth.reducer';
export { watchAuth } from './auth.saga';
export { authService } from './auth.service';

export const authActions = {
    logIn,
    logOut,
    getRecoveryLink,
    tryAgain,
    resetPassword,
    changePassword,
    changeNotifications,
    getMyProfile,
    /** Access service */
    getAccess,
    assignAccess,
    removeAccess,
    /** End */
};