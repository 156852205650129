import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import {
    adminActions, appointmentActions,
    claimActions,
    clientActions,
    fundingSourceActions,
    invoiceActions,
    staffPayrollActions,
} from 'store';
import { Images, RolePermission, useModal } from 'utils';
import { FullTable } from '../tabs';
import { fileNotYet, filesBody, filesHead } from './constants';
import { CustomDelete } from '../modal';
import { UploadModal } from './uploadModal';

export const FilesComponent = ({ currentId, onResource, documents, loadTable, permissions }) => {
    const actionType =
        onResource === 'staff' ? 'DELETE_STAFF_DOCUMENT' :
            onResource === 'fs' ? 'DELETE_FS_DOCUMENT' :
                onResource === 'client' ? 'DELETE_CLIENT_DOCUMENT' :
                    onResource === 'claim' ? 'DELETE_CLAIM_DOCUMENT' :
                        onResource === 'invoice' ? 'DELETE_INVOICE_DOCUMENT' :
                            onResource === 'payroll' ? 'DELETE_PAYROLL_DOCUMENT' :
                                onResource === 'appt' ? 'DELETE_APPT_DOCUMENT' :
                                    '';
    const dispatch = useDispatch();
    const checkPermissions = permissions ? RolePermission([...permissions]) : '';
    const { open } = useModal();
    const [selectedId, setSelectedId] = useState('');

    const handleDelete = (item) => {
        if (onResource === 'staff') {
            dispatch(adminActions.deleteStaffDocument(currentId, item?._id));
        } else if (onResource === 'fs') {
            dispatch(fundingSourceActions.deleteFsDocument(currentId, item?._id));
        } else if (onResource === 'client') {
            dispatch(clientActions.deleteClientDocument(currentId, item?._id));
        } else if (onResource === 'claim') {
            dispatch(claimActions.deleteClaimDocument(currentId, item?._id));
        } else if (onResource === 'invoice') {
            dispatch(invoiceActions.deleteInvoiceDocument(currentId, item?._id));
        } else if (onResource === 'payroll') {
            dispatch(staffPayrollActions.deletePayrollDocument(currentId, item?._id));
        } else if (onResource === 'appt') {
            dispatch(appointmentActions.deleteApptDocument(currentId, item?._id));
        }
    };

    const handleDownload = (item) => {
        const fileType =
            item?.file?.originalName ? item?.file?.originalName :
                item?.file?.format === 'svg+xml' ? 'file.svg' : `file.${item?.file?.format}`;
        setSelectedId(item?._id || item?.id);
        axios({
            url: `/files/download`,
            method: 'POST',
            responseType: 'blob',
            data: item?.file,
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileType); //or any other extension
            document.body.appendChild(link);
            link.click();
            setSelectedId('');
        }).catch(() => {
            setSelectedId('');
        });
    };

    const handleClickActions = (item, name) => {
        if (name === 'delete') {
            open(
                <CustomDelete
                    info="Are you sure you want to delete this file?"
                    text="Once deleted, this file will be permanently lost."
                    handleDel={() => handleDelete(item)}
                    handleClose={close}
                    actionType={actionType}
                    body={
                        <div className="deleting-client-info-box" style={{ alignItems: 'flex-start' }}>
                            <img style={{ marginTop: '3px' }} src={Images.uploadedFile} alt="icon" />
                            <p style={{ fontWeight: 600 }}>{item?.name}</p>
                        </div>
                    }
                />,
            );
        } else if (name === 'download') {
            setSelectedId(item?._id || item?.id);
            handleDownload(item);
        } else if (name === 'edit') {
            open(
                <UploadModal
                    defaultValue={item}
                    currentId={currentId}
                    onResource={onResource}
                />,
            );
        }
    };

    return (
        <div className="document-table-wrapper">
            <div className="dock-table-wrapper">
                <FullTable
                    head={filesHead(checkPermissions)}
                    body={filesBody(checkPermissions, selectedId)}
                    loadingType={loadTable}
                    list={documents?.length ? documents : []}
                    handleClickButton={(name, item) => handleClickActions(name, item)}
                    notYet={fileNotYet}
                    activeRowId={''}
                />
            </div>
        </div>
    );
};