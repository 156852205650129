import {
    GET_ALL_KPIS_SUCCESS, GET_CHART_DATA, GET_CHART_DATA_SUCCESS,
    GET_USER_FILTERED_KPIS_SUCCESS,
    GET_USER_KPIS_SUCCESS,
    REORDER_KPIS,
} from './analytics.types';

const initialState = {
    listKpis: [],
    allKpis: [],
    userKpis: [],
    chartData: null
};

export const analyticsReducer = (state = initialState, action) => {
    switch (action.type) {

        /** Analytics */

        case  GET_USER_FILTERED_KPIS_SUCCESS:
            return {
                ...state,
                listKpis: action?.payload,
            };

        case  GET_ALL_KPIS_SUCCESS:
            return {
                ...state,
                allKpis: action?.payload,
            };

        case  GET_USER_KPIS_SUCCESS:
            return {
                ...state,
                userKpis: action?.payload,
            };

        case REORDER_KPIS:
            return {
                ...state,
                userKpis: action?.payload?.body,
            };

        case GET_CHART_DATA:
            return {
                ...state,
                chartData: null,
            };

        case GET_CHART_DATA_SUCCESS:
            return {
                ...state,
                chartData: action.payload,
            };

        /** End */

        default:
            return state;
    }
};
