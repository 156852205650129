import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { httpRequestsOnSuccessActions, notificationActions } from 'store';
import { CloseButton, Loader, MinLoader, NoItemsYet } from 'components';
import { Colors, FindLoad, FindSuccessItem, Images, PermissionList, RolePermission, useSecondaryModal } from 'utils';
import { notificationTypesEnums, renderIcon } from './constants';
import { ScheduleDetails } from '../schedule';

const notificationEnums = {
    all: 'ALL',
    unread: 'UNREAD',
};

const ACTION_TYPE = 'GET_NOTIFICATIONS';
const MARK_ALL = 'MARK_ALL_AS_READ';
const MARK_SINGLE = 'MARK_SINGLE_AS_READ';
const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
const NoteLimit = 10;
export const NotificationList = ({ handleClose, info }) => {
    const dispatch = useDispatch();
    const [noteTypes, setNoteTypes] = useState(notificationEnums?.all);
    const [limit, setLimit] = useState(0);
    const [selectedId, setSelectedId] = useState(null);
    const noteList = useSelector((state) => state.notifications.notifications);
    const loader = FindLoad(ACTION_TYPE);
    const loadMark = FindLoad(MARK_ALL);
    const loadDelete = FindLoad(DELETE_NOTIFICATION);
    const loadMarkSingle = FindLoad(MARK_SINGLE);
    const successMark = FindSuccessItem(MARK_ALL);
    const markSingle = FindSuccessItem(MARK_SINGLE);
    const deleteNote = FindSuccessItem(DELETE_NOTIFICATION);
    const { openSecondary } = useSecondaryModal();
    const history = useHistory();
    const observerTarget = useRef(null);
    const notificationStart = useRef(null);

    const requestParams = () => {
        return {
            skip: 0,
            limit: limit,
            state: noteTypes,
        };
    };

    useEffect(() => {
        if (successMark) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(MARK_ALL));
            getNotifications('noLoad');
        }
        if (markSingle) {
            setSelectedId(null);
            dispatch(httpRequestsOnSuccessActions.removeSuccess(DELETE_NOTIFICATION));
            getNotifications('noLoad');
        }
        if (deleteNote) {
            setSelectedId(null);
            dispatch(httpRequestsOnSuccessActions.removeSuccess(MARK_SINGLE));
            getNotifications('noLoad');
        }
    }, [successMark, markSingle, deleteNote]);


    useEffect(() => {
        dispatch(notificationActions.getUnRenderNotifications());
    }, []);


    useEffect(() => {
        const loader = limit === 0 ? 'load' : 'noLoad';
        const checkCount = limit - noteList?.count

        if (noteList?.count ? checkCount < 10 : true) {
            getNotifications(loader);
        }
    }, [noteTypes, limit]);

    const getNotifications = (loading) => {
        const params = {
            ...requestParams(),
        };
        dispatch(notificationActions.getNotifications(params, loading));
    };

    const changeType = (type) => {
        dispatch(notificationActions.removeNotifications());
        setNoteTypes(type);
        setLimit(0);
        if (notificationStart?.current) {
            notificationStart.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const markAll = () => {
        dispatch(notificationActions.markAllAsRead());
    };


    const handleMarkAsRead = (item) => {
        if (!item?.isRead) {
            setSelectedId(item?.id);
            dispatch(notificationActions.markSingleAsRead(item?.id));
        }

        if ((item?.notificationType === notificationTypesEnums.APPT_CREATE ||
            item?.notificationType === notificationTypesEnums.APPT_UPDATE ||
            item?.notificationType === notificationTypesEnums.APPT_CANCEL ||
            item?.notificationType === notificationTypesEnums.APPT_LOCK ||
            item?.notificationType === notificationTypesEnums.APPT_UNLOCK ||
            item?.notificationType === notificationTypesEnums.APPT_RENDER ||
            item?.notificationType === notificationTypesEnums.APPT_UNRENDER) && item?.resourceId) {
            openSecondary(<ScheduleDetails currentId={item?.resourceId} />);
        }
        if (
            (item?.notificationType === notificationTypesEnums.LICENSE_EXPIRATION || item?.notificationType === notificationTypesEnums.CREDENTIALS_EXPIRATION) &&
            RolePermission([PermissionList.STAFF_READ.code])) {
            history.push(`/staff/${info?.id}`);
            handleClose();
        }
        if ( (item?.notificationType === notificationTypesEnums.SERVICE_ADD || item?.notificationType === notificationTypesEnums.SERVICE_DELETE ) &&
            RolePermission([PermissionList.STAFF_SERVICE_MANAGE?.code, PermissionList.STAFF_SERVICE_READ?.code])
        ) {
            history.push(`/staff/${info?.id}`, {
                activeTab: 'Services',
            });
            handleClose();
        }
    };

    const deleteNotification = (e, item) => {
        e.preventDefault();
        e.stopPropagation();
        setSelectedId(item?.id);
        dispatch(notificationActions.deleteNotification(item?.id));
    };

    const renderDuration = (time) => {
        const targetTime = moment(time);
        const currentTime = moment();
        const duration = moment.duration(targetTime.diff(currentTime));
        const daysLeft = Math.abs(duration.days());
        const hoursLeft = Math.abs(duration.hours());
        const minutesLeft = Math.abs(duration.minutes());
        let timeLeft;

        if (daysLeft > 0) {
            timeLeft = `${daysLeft} day(s) left`;
        } else if (hoursLeft > 0) {
            timeLeft = `${hoursLeft} hour(s) left`;
        } else if (minutesLeft > 0) {
            timeLeft = `${minutesLeft} mins ago`;
        }
        return timeLeft;
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting) {
                    setLimit((prevLimit) => prevLimit + NoteLimit);
                }
            },
            { threshold: 1 },
        );

        if (observerTarget.current) {
            observer.observe(observerTarget.current);
        }

        return () => {
            if (observerTarget.current) {
                observer.unobserve(observerTarget.current);
            }
        };
    }, [observerTarget]);

    return (
        <div className="notifications-wrapper">
            <div className="notifications-close-btn">
                <p>Notifications</p>
                <CloseButton styles={{ width: '35px', height: '35px' }} handleCLic={handleClose} />
            </div>
            <div className="notifications-button-wrapper">
                <button
                    className={noteTypes === notificationEnums.all ? 'note-active-btn' : 'note-passive-btn'}
                    onClick={() => changeType(notificationEnums.all)}>All
                </button>
                <button
                    className={noteTypes === notificationEnums.unread ? 'note-active-btn' : 'note-passive-btn'}
                    onClick={() => changeType(notificationEnums.unread)}>Unread
                </button>
            </div>

            <div className="mark-as-read">
                <button onClick={markAll}>
                    {loadMark?.length ?
                        <MinLoader position={'relative'} color={Colors.ThemeBlue} small={true} />
                        :
                        'Mark all as read'
                    }
                </button>
            </div>

            <div className="note-card-wrapper">

                <div ref={notificationStart} />
                {!!loader?.length &&
                    <Loader
                        styles={{ background: 'white', zIndex: 3, position: 'absolute' }}
                        height={'400px'} />
                }

                <div>
                    {noteList?.notifications?.length ?
                        noteList?.notifications?.map((note, index) => (
                            <div
                                key={index}
                                onClick={() => handleMarkAsRead(note)}
                                className="notification-card"
                                style={{
                                    background: note?.isRead ? 'transparent' : '#F5F9FE',
                                }}
                            >
                                <div className="flex-align-start" style={{ gap: 12 }}>
                                    <div className="notification-icon-box">
                                        <img src={renderIcon(note?.notificationType)} alt="icon" />
                                    </div>
                                    <div>
                                        <div className="notification-title-box">
                                            <p className="notification-title">{note?.title}</p>
                                            {renderDuration(note?.notifiedAt) &&
                                                <>
                                                    <div />
                                                    <span>
                                                {renderDuration(note?.notifiedAt)}
                                            </span>
                                                </>
                                            }
                                        </div>
                                        {note?.body &&
                                            <p className="notification-sub-title">{note?.body}</p>
                                        }
                                    </div>
                                </div>

                                {note?.isRead ?
                                    <button
                                        onClick={(e) => deleteNotification(e, note)}
                                        className="delete-notification-box"
                                    >
                                        {selectedId === note?.id && loadDelete?.length ?
                                            <MinLoader
                                                position={'relative'}
                                                color={Colors.ThemeRed}
                                                margin={'0'}
                                                small={true}
                                            />
                                            :
                                            <img src={Images.remove} alt="" />
                                        }
                                    </button>
                                    :
                                    selectedId === note?.id && loadMarkSingle?.length ?
                                        <MinLoader position={'relative'} color={Colors.ThemeGreen} small={true}
                                                   margin={'0'} />
                                        :
                                        <div className="new-notification" />
                                }
                            </div>
                        ))
                        :
                        <NoItemsYet
                            height={'300px'}
                            text={'No Notifications'}
                            subTitle={'No new updates available.'}
                            image={Images.noNotifications}
                        />
                    }


                    <div className="flex-justify-align-center" ref={observerTarget} style={{ height: 30 }} />
                    {/*{noteList?.count !== 0 &&*/}
                    {/*    <MinLoader position={'relative'} color={Colors.ThemeBlue} small={true} />*/}
                    {/*}*/}
                    {/*</div>*/}
                </div>

            </div>
        </div>
    );
};