/**Get Invoice Payment */
export const GET_INVOICE_PAYMENTS = "GET_INVOICE_PAYMENTS";
export const GET_INVOICE_PAYMENTS_SUCCESS = "GET_INVOICE_PAYMENTS_SUCCESS";

/**Get Invoice Payment by Id */
export const GET_INVOICE_PAYMENT_BY_ID = "GET_INVOICE_PAYMENT_BY_ID";
export const GET_INVOICE_PAYMENT_BY_ID_SUCCESS = "GET_INVOICE_PAYMENT_BY_ID_SUCCESS";

/**create Invoice Payment */
export const CREATE_INVOICE_PAYMENT = "CREATE_INVOICE_PAYMENT";

/**edit Invoice Payment */
export const EDIT_INVOICE_PAYMENT = "EDIT_INVOICE_PAYMENT";

/**delete Invoice Payment */
export const DELETE_INVOICE_PAYMENT = "DELETE_INVOICE_PAYMENT";

/**edit Invoice Payment Status */
export const EDIT_INVOICE_PAYMENT_STATUS = "EDIT_INVOICE_PAYMENT_STATUS";

/**add Invoice in Invoice Payment */
export const ADD_INVOICE_IN_INVOICE_PAYMENT = "ADD_INVOICE_IN_INVOICE_PAYMENT";

/**append Files to Invoice Payment */
export const APPEND_FILES_TO_INVOICE_PAYMENT = "APPEND_FILES_TO_INVOICE_PAYMENT";

/**remove Files from Invoice Payment */
export const REMOVE_FILES_FROM_INVOICE_PAYMENT = "REMOVE_FILES_FROM_INVOICE_PAYMENT";

/**edit File Name of Invoice Payment */
export const EDIT_FILE_NAME_OF_INVOICE_PAYMENT = "EDIT_FILE_NAME_OF_INVOICE_PAYMENT";





/**edit File Name of Invoice Payment */
export const EDIT_PAYMENT_INVOICE = "EDIT_PAYMENT_INVOICE";

export const PAY_IN_FULL_PAYMENT_INVOICE = "PAY_IN_FULL_PAYMENT_INVOICE";
