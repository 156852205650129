import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SimpleTabs } from 'components';
import { GeneralReports, ClientReports, BillingReports, StaffReports, PayrollReports, SignatureReports, tabsLabels,  } from './reportFragments';
import { adminActions, clientActions, fundingSourceActions, payrollActions } from 'store';
import { SystemReports } from './reportFragments/systemReports';
import { SignatureReport } from './reportFragments/signatureReport';

export const Report = ({}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clientActions.getBasicClients());
        dispatch(fundingSourceActions.getFundingSource());
        dispatch(adminActions.getAdmins());
        dispatch(payrollActions.getPayCodeGlobal());
    }, [dispatch]);

    const tabsContent = [
        {
            tabComponent: (
                <GeneralReports />
            ),
            tab: 'Generated Reports',
        },
        {
            tabComponent: (
                <ClientReports />
            ),
            tab: 'Clients',
        },
        {
            tabComponent: (
                <StaffReports />
            ),
            tab: 'Staff',
        },
        {
            tabComponent: (
                <BillingReports />
            ),
            tab: 'Billing',
        },
        {
            tabComponent: (
                <PayrollReports />
            ),
            tab: 'Payroll',
        },
        {
            tabComponent: (
                <SystemReports />
            ),
            tab: 'System',
        },
        // {
        //     tabComponent: (
        //         <SignatureReport />
        //     ),
        //     tab: 'Signature',
        // },
    ];

    return (

        <div className="system-item-wrapper">
            <SimpleTabs
                // defaultTab={'Signature'}
                defaultTab={'Generated Reports'}
                tabsLabels={tabsLabels}
                tabsContent={tabsContent}
            />
        </div>

    );
};