import React from 'react';
import { motion } from 'framer-motion';
import { LandingSvg } from 'assets';
import { animationParams, animationVariants } from 'fragments';

export const WhyTherapyCard = ({ card, active, onClick }) => {
    return (
        <motion.div
            className={`why-therapy-card-wrapper ${active ? 'active-card' : ''}`}
            onClick={onClick}
            {...animationParams}
            variants={animationVariants.cardsVariant}
        >
            <div className='card-svg-block'>
                <h4 className='why-therapy-card-title'>
                    {card?.title}
                </h4>

                <LandingSvg.InfinitySmallSvg className='why-therapy-card-svg' />
            </div>

            <p className='why-therapy-card-description'>
                {card?.description}
            </p>

            <div className='loading-bar' />
            {active &&
                <div className='progress' />
            }
        </motion.div>
    );
};

