import axios from "axios";

export const authService = {

   getGeneralRepostsService: (params) =>  axios.get("/reports/generated", { auth: true, params:{ ...params} }),

   deleteGeneralRepostService: (id) =>  axios.delete(`/reports/${id}`, { auth: true }),

   getSignatureReportsService: (params) =>  axios.get("/reports/appt/signature", { auth: true, params:{ ...params} }),

   downloadPdf: (params) =>  axios.get("/reports/appt/signature/pdf", { auth: true, params:{ ...params} }),

   getUtilizationReportService: (params) =>  axios.get("/reports/billing/auth/utilization", { auth: true, params:{ ...params} }),

   getReportService: (url, params) =>  axios.get(url, { auth: true, params:{ ...params} }),

};
