import React, { useState } from 'react';
import { CreateChancel, ErrMessage } from 'components';
import { Images, useModal } from 'utils';
import { FormControlLabel, Radio } from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';

const token = localStorage.getItem('access-token');
const typeEnums = {
    PDF: 'PDF',
    TAB: 'TAB',
}
export const FormType1500 = ({ item, batch }) => {
    const { close } = useModal();
    const [load, setLoadDownload] = useState(false);
    const [type, setType] = useState();
    const [error, setError] = useState(false);


    const handleExport = (e) => {
        if (type) {
            setLoadDownload(true);
            axios({
                url: batch ? `/claim/batch/${item?.id}/1500Forms/${type}` : `/claim/${item?.id}/1500Form/${type}`,
                method: 'GET',
                responseType: type === typeEnums.PDF ? 'blob' : 'text',
                headers: { 'access-token': `${token}` },
            }).then((response) => {

                setLoadDownload(false);
                const blob = new Blob([response?.data], { type: 'text/tab-separated-values' });
                const url = type === typeEnums.PDF ? window.URL.createObjectURL(new Blob([response?.data])) : window.URL.createObjectURL(blob) ;
                const link = document.createElement('a');
                link.href = url;
                const fileType= type === typeEnums.PDF ? 'pdf' : 'tab';
                link.setAttribute('download', `${item?.displayId ? `${item?.displayId},` : ''}${moment().format('MM-DD-YYYY')}-1500Form.${fileType}`);
                document.body.appendChild(link);
                link.click();
                close();
            }).catch(() => {
                setLoadDownload(false);
            });
        } else {
            setError(true);
        }
    };

    const handleChange = (e) => {
        setType(e.target.value);
        setError(false);
    };


    return (
        <div className="download-1500-form-modal">
            <p className="download-modal-title">Download 1500 Form</p>
            <p className="download-modal-desc">
                Please choose the file format in which you would like to download the 1500 form document.
            </p>
            <div className="download-1500form-modal-body">
                <div className={type === typeEnums.PDF ? 'form-type-box-active' : 'form-type-box-passive'}
                     style={{ marginBottom: '12px', borderColor: error ? '#F07379' : '' }}
                >
                    <FormControlLabel
                        onChange={handleChange}
                        name={typeEnums.PDF}
                        value={typeEnums.PDF}
                        label="PDF (.pdf)"
                        control={<Radio
                            style={{ color: type === typeEnums.PDF ? '#347AF0' : '#D0D5DD' }}
                            checked={type === typeEnums.PDF}
                        />}
                    />
                </div>
                <div className={type === typeEnums.TAB ? 'form-type-box-active' : 'form-type-box-passive'}
                     style={{ borderColor: error ? '#F07379' : '' }}
                >
                    <FormControlLabel
                        onChange={handleChange}
                        name={typeEnums.TAB}
                        value={typeEnums.TAB}
                        label="Tab-delimited (.tab)"
                        control={<Radio
                            style={{ color: type === typeEnums.TAB ? '#347AF0' : '#D0D5DD' }}
                            checked={type === typeEnums.TAB}
                        />}
                    />
                </div>
                <ErrMessage
                    style={{ marginBottom: '12px' }}
                    text={error ? 'Please select the file format' : ''}
                />
            </div>

            <div>
                <CreateChancel
                    loader={load}
                    create={'Download'}
                    chancel={'Cancel'}
                    onCreate={handleExport}
                    onClose={() => close()}
                    buttonWidth="300px"
                    image={Images.downloadCloudWhite}
                />
            </div>
        </div>
    );
};