import axios from 'axios';

export const systemService = {
    /** Service Type */
    createServiceGlobalService: (body) => axios.post(`/serviceType`, body, { auth: true }),

    getServicesService: (data) => axios.get(`/serviceType`, { auth: true, params: { ...data } }),

    editServiceByIdGlobalService: (id, body) => axios.patch(`/serviceType/${id}`, body, { auth: true }),

    deleteServiceByIdService: (id) => axios.delete(`/serviceType/${id}`, { auth: true }),
    /** End */

    /** Credentials */
    createCredentialGlobalService: (body) => axios.post(`/credential`, body, { auth: true }),

    createCoveredCredentialService: (id, body) => axios.post(`/credential/${id}/covered`, body, { auth: true }),

    editCoveredCredentialService: ({
                                       credentialId,
                                       id,
                                       body,
                                   }) => axios.patch(`/credential/${credentialId}/covered/${id}`, body, { auth: true }),

    getCredentialGlobalService: () => axios.get(`/credential`, { auth: true }),

    editCredentialByIdGlobalService: (id, body) => axios.patch(`/credential/${id}`, body, { auth: true }),

    deleteCredentialByIdService: (id) => axios.delete(`/credential/${id}`, { auth: true }),
    /** End */

    /** Department */
    createDepartmentGlobalService: (body) => axios.post(`/department`, body, { auth: true }),

    getDepartmentsService: () => axios.get(`/department`, { auth: true }),

    editDepartmentByIdGlobalService: (id, body) => axios.patch(`/department/${id}`, body, { auth: true }),

    deleteDepartmentByIdService: (id) => axios.delete(`/department/${id}`, { auth: true }),
    /** End */

    /** Job */
    createJobGlobalService: (body) => axios.post(`/job/title`, body, { auth: true }),

    getJobsService: () => axios.get(`/job/title`, { auth: true }),

    editJobByIdGlobalService: (id, body) => axios.patch(`/job/title/${id}`, body, { auth: true }),

    deleteJobByIdService: (id) => axios.delete(`/job/title/${id}`, { auth: true }),
    /** End */

    /** Departments */
    createPlaceGlobalService: (body) => axios.post(`/place`, body, { auth: true }),

    getPlacesService: () => axios.get(`/place`, { auth: true }),

    editPlaceByIdGlobalService: (id, body) => axios.patch(`/place/${id}`, body, { auth: true }),

    deletePlaceByIdService: (id) => axios.delete(`/place/${id}`, { auth: true }),
    /** End */

    /** Company */
    createCompanyService: (body) => axios.post(`/company/profile`, body, { auth: true }),

    getCompanyService: () => axios.get(`/company/profile`, { auth: true }),

    editCompanyService: (id, body) => axios.patch(`/company/profile`, body, { auth: true }),

    deleteCompanyService: (id) => axios.delete(`/company/profile`, { auth: true }),
    /** End */

    /** Calendars */
    getConnectCalendarsService: () => axios.get(`/staff/calendars/state`, { auth: true }),

    disconnectCalendarService: (params) => axios.delete(`/staff/calendars`, { auth: true, params: params }),

    changeCalendarStatusService: (params) => axios.post(`/staff/calendars/state`, null, { auth: true, params: params }),
    /** End */

    /** Office Ally */

    getOfficeAllyService: () => axios.get(`/officeAlly/settings`, { auth: true }),

    connectOfficeAllyService: (body) => axios.post(`/officeAlly/settings`, body, { auth: true }),

    disconnectOfficeAllyService: () => axios.delete(`/officeAlly/settings`, { auth: true }),

    editOfficeAllyService: (body) => axios.patch(`/officeAlly/settings`, body, { auth: true }),

    /** End */

};
