

export const GET_FS_SOURCES = 'GET_FUNDING_SOURCE'

export const frequencyEnumsConvert = {
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
    ENTIRE_TERM: 'ENTIRE_TERM',
};

export const frequencyList = [
    { title: 'Weekly', value: frequencyEnumsConvert.WEEKLY, count: true },
    { title: 'Monthly', value: frequencyEnumsConvert.MONTHLY, count: true },
    { title: 'Entire Term', value: frequencyEnumsConvert.ENTIRE_TERM },
];