import {
   getClaimPayments,
   appealClaimPayment,
   payInFullClaim,
   payInFullClaimBill,
   editClaimPaymentInfo,
} from "./claimPayment.action.js";

export { claimPaymentReducer } from "./claimPayment.reducer";
export { watchClaimPayment } from "./claimPayment.saga";

export const claimPaymentActions = {
   /** Claim Payment */
   getClaimPayments,
   editClaimPaymentInfo,
   appealClaimPayment,
   payInFullClaim,
   payInFullClaimBill,
   /** End */
};
