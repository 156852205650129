import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { SelectTypeAutocomplete } from 'components';
import { analyticActions } from 'store';
import { FindLoad } from 'utils';
import { checkFilter, DefaultDates, WidgetCategories } from './constants';
import { AddWidget, WidgetCard } from './widgetFragments';
import { WidgetDateSelector } from './widgetFragments/widgetDateSelector';

const ACTION_TYPE = 'GET_USER_KPIS';
export const Widgets = () => {
    const { userKpis } = useSelector((state) => ({
        userKpis: state.analytics.userKpis,
    }));
    const dispatch = useDispatch();
    const loader = FindLoad(ACTION_TYPE);
    const history = useHistory();
    const info = history?.location?.state;
    const { allDates } = DefaultDates(info);
    const [inputs, setInputs] = useState({});

    useEffect(() => {
        dispatch(analyticActions.getUserKpis(allDates));
    }, [info]);

    const handleChange = (e) => {
        const params = {
            ...inputs,
        };
        if (e.target.value === 'All' || !e.target.value) {
            delete params[e.target.name];
        } else {
            params[e.target.name] = e.target.value;
        }
        setInputs(params);
    };

    const handleOnDragEnd = (result) => {
        const { source, destination } = result;

        if (!destination) return;

        const updatedItems = Array.from(userKpis);
        const [removed] = updatedItems.splice(source.index, 1);
        updatedItems.splice(destination.index, 0, removed);

        dispatch(analyticActions.reorderKpis(updatedItems, allDates));
    };

    return (
        <div>
            <div className="widget-filters-box">
                <div className="flex-align-center" style={{ gap: 16 }}>
                    <SelectTypeAutocomplete
                        placeholder={inputs?.category ? '' : 'All'}
                        name={'category'}
                        handleSelect={handleChange}
                        defaultValue={inputs?.category}
                        list={WidgetCategories}
                        renderValue={(i) => i?.name}
                        uiType={'tableFilter'}
                        outLabel={'Category'}
                        noError={true}
                    />
                    <WidgetDateSelector
                        startName={'startDate'}
                        endName={'endDate'}
                        outLabel={'Date Range'}
                        noError={true}
                    />
                    {/*<DateTypeSelector*/}
                    {/*    noError={true}*/}
                    {/*    name={'from'}*/}
                    {/*    outLabel={'Date Range'} */}
                    {/*    // handleGetDates={handleChange}*/}
                    {/*    // values={inputs}*/}
                    {/*    // max={inputs.to ? moment.utc(inputs.to).format('YYYY-MM-DD') : ''}*/}
                    {/*/>*/}
                    {/*<SelectTypeAutocomplete*/}
                    {/*    // placeholder={pushInfo?.payer ? '' : 'All'}*/}
                    {/*    name={'payer'}*/}
                    {/*    // handleSelect={handleChange}*/}
                    {/*    // defaultValue={pushInfo?.payer}*/}
                    {/*    list={*/}
                    {/*        [{ id: 'All', name: 'All' }]*/}
                    {/*    }*/}
                    {/*    renderValue={(i) => i?.name}*/}
                    {/*    uiType={'tableFilter'}*/}
                    {/*    outLabel={'Comparison '}*/}
                    {/*    noError={true}*/}
                    {/*/>*/}
                </div>
                <AddWidget />
            </div>


            <div
                className={'widget-cards-box'}
                style={{ margin: '24px 0'}}>

                {loader?.length && !userKpis?.length ?
                    <div className="flex-align-center" style={{ gap: 16 }}>
                        <Skeleton variant="rounded" width={250} height={150} />
                        <Skeleton variant="rounded" width={250} height={150} />
                        <Skeleton variant="rounded" width={250} height={150} />
                    </div>
                    :
                    <DragDropContext
                        onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="droppable-list" direction="horizontal">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className="widget-cards-wrapper"
                                >
                                    {snapshot?.isDraggingOver && (
                                        <div className="widget-card-back-drop" />
                                    )}
                                    {userKpis?.map((item, index) => checkFilter(inputs, item) && (
                                        <Draggable
                                            key={item.id.toString()}
                                            draggableId={item.id.toString()}
                                            index={index}
                                        >
                                            {(provided, dragSnapshot) => (
                                                <>
                                                    {dragSnapshot.isDragging ? (
                                                        <>
                                                            <div className='dragged-card-back' />
                                                            <div
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                ref={provided.innerRef}
                                                                style={{
                                                                    ...provided.draggableProps.style,
                                                                }}
                                                            >
                                                                <WidgetCard item={item} snapshot={snapshot} />
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            ref={provided.innerRef}
                                                            style={{
                                                                ...provided.draggableProps.style,
                                                            }}
                                                        >
                                                            <WidgetCard item={item} snapshot={snapshot} />
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                }
            </div>
        </div>
    );
};