import React from 'react';
import { motion } from 'framer-motion';
import { AboutSomething, BillingPosting, ElectronicRecords, FeatureBanner, Solutions } from './fragments';
import {
    accessKeyInfo,
    credentialingInfo,
    customizableReportsInfo,
    dataSecurityInfo,
    payrollInfo,
    powerfulInfo,
    schedulingInfo,
} from './fragments/constants';
import { useWidth } from 'utils';
import { landingImages, LandingSvg } from 'assets';
import { animationParams, animationVariants } from 'fragments';


export const Features = () => {
    const width = useWidth();

    return (
        <div className='features-page'>
            <FeatureBanner />

            <Solutions />

            <ElectronicRecords />

            <BillingPosting />

            <div className='scheduling-wrapper'>
                <div className='container'>
                    <AboutSomething
                        title={schedulingInfo.title}
                        blueText={schedulingInfo.blueText}
                        description={schedulingInfo.description}
                        items={schedulingInfo.items}
                        img={schedulingInfo.img}
                        wrapperClassName='scheduling-box'
                        imgClassName='scheduling-img'
                        contentClassName='scheduling-content'
                    />
                </div>
            </div>


            <div className='payroll-wrapper'>
                <div className='container'>
                    <AboutSomething
                        title={payrollInfo.title}
                        blueText={payrollInfo.blueText}
                        description={payrollInfo.description}
                        items={payrollInfo.items}
                        img={payrollInfo.img}
                        wrapperClassName='payroll-box'
                        imgClassName='payroll-img'
                        contentClassName='payroll-content'
                        contentWrapperClassName='payroll-content-wrapper'
                    />
                </div>
            </div>


            <div className='credential-wrapper'>
                <div className='container'>
                    <AboutSomething
                        title={credentialingInfo.title}
                        blueText={credentialingInfo.blueText}
                        description={credentialingInfo.description}
                        items={credentialingInfo.items}
                        img={credentialingInfo.img}
                        wrapperClassName='credential-box'
                        imgClassName='credential-img'
                        contentClassName='credential-content'
                    />
                </div>
            </div>

            <div className='powerful-wrapper'>
                <div className='container'>
                    <AboutSomething
                        title={powerfulInfo.title}
                        blueText={powerfulInfo.blueText}
                        description={powerfulInfo.description}
                        items={powerfulInfo.items}
                        img={powerfulInfo.img}
                        oneLineText={true}
                        wrapperClassName='powerful-box'
                        contentWrapperClassName={'powerful-content-wrapper'}
                        imgClassName='powerful-img'
                        contentClassName='powerful-content'
                    />
                </div>
            </div>

            <div className='access-key-wrapper'>
                <div className='container'>
                    <AboutSomething
                        onClickApple={() => window.open('https://play.google.com/store/apps/details?id=com.well.mo&hl=en_US&pli=1', '_blank', 'noopener,noreferrer')}
                        onClickGoogle={() => window.open('https://apps.apple.com/us/app/therapylake/id6450555754', '_blank', 'noopener,noreferrer')}
                        title={accessKeyInfo().title}
                        blueText={accessKeyInfo().blueText}
                        description={accessKeyInfo().description}
                        items={accessKeyInfo().items}
                        img={accessKeyInfo(width).img}
                        googleAppButtons={true}
                        oneLineText={true}
                        wrapperClassName='access-key-box'
                        imgClassName='access-key-img'
                        contentWrapperClassName={'access-key-content-wrapper'}
                        contentClassName='access-key-content'
                    />
                </div>
            </div>

            <div className='data-security-wrapper'>
                <div className='container'>
                    <AboutSomething
                        title={dataSecurityInfo.title}
                        blueText={dataSecurityInfo.blueText}
                        description={dataSecurityInfo.description}
                        items={dataSecurityInfo.items}
                        img={dataSecurityInfo.img}
                        wrapperClassName='data-security-box'
                        imgClassName='data-security-img'
                        contentClassName='data-security-content'
                    />
                </div>
            </div>

            <div className='customizable-reports-wrapper'>
                <div className='container'>
                    <p className='blue-text'>
                        Insightful Analytics Tailored to Your Practice
                    </p>

                    <div style={{ overflow: 'hidden' }}>
                        <motion.h3
                            className='customizable-reports-title'
                            {...animationParams}
                            variants={animationVariants.titleVariantMobile}
                        >
                            Customizable Reports
                        </motion.h3>
                    </div>

                    <div style={{ overflow: 'hidden' }}>
                        <motion.p
                            className='customizable-reports-desc'
                            {...animationParams}
                            variants={animationVariants.titleVariantMobile}
                        >
                            Create reports tailored to your practice's unique needs
                        </motion.p>
                    </div>

                    <div className='customizable-reports-content'>
                        <div className='customizable-reports-items'>
                            {customizableReportsInfo.items1.map(({ text, textBold }, index) => (
                                <div className='single-item-wrapper' key={index}>
                                    <LandingSvg.CheckMarkSvg className='about-something-svg' />

                                    <div className='single-item-texts'>
                                        <p className='single-item-text' style={{ fontWeight: 600 }}>
                                            {`${textBold} `}
                                        </p>

                                        <p className='single-item-text'>
                                            {text}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <motion.div
                            className={'customizable_img'}
                            {...animationParams}
                            variants={animationVariants.cardsVariant}
                        >
                            <img src={width > 1280 ?
                                landingImages.customizableReports
                                : width > 768 ? landingImages.customizableReportsTablet
                                    : landingImages.customizableReportsTablet
                            } alt='placeholder' />
                        </motion.div>

                        <div className='customizable-reports-items'>
                            {customizableReportsInfo.items2.map(({ text, textBold }, index) => (
                                <motion.div
                                    key={index}
                                    className='single-item-wrapper'
                                    {...animationParams}
                                    variants={animationVariants.cardsVariant}
                                >
                                    <LandingSvg.CheckMarkSvg className='about-something-svg' />

                                    <div className='single-item-texts'>
                                        <p className='single-item-text' style={{ fontWeight: 600 }}>
                                            {`${textBold} `}
                                        </p>

                                        <p className='single-item-text'>
                                            {text}
                                        </p>
                                    </div>
                                </motion.div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

