import { GET_MY_PERMISSIONS_SUCCESS, GET_PERMISSIONS, GET_PERMISSIONS_SUCCESS } from './permissions.types';

const initialState = {
  permissionsList:[],
  myPermissionsList: [],
};

export const permissionsReducer = (state = initialState, action) => {
  switch ( action.type ) {

    case  GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissionsList: action.payload,
      }


    case GET_MY_PERMISSIONS_SUCCESS:
      return {
        ...state,
        myPermissionsList: action.payload,
      }



    default:
      return state;
  }
};
