import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CreateChancel, ValidationInput } from 'components';
import { ErrorText, FindLoad, FindSuccess, useModal } from 'utils';
import { clientActions, httpRequestsOnSuccessActions } from 'store';

const ACTION_TYPE = 'EDIT_CLIENT_AUTHORIZATION'
export const DiagnosticCode = ({ item, info, params }) => {
    const { close } = useModal();
    const loader = FindLoad(ACTION_TYPE);
    const success = FindSuccess(ACTION_TYPE)
    const dispatch = useDispatch()
    const [code, setCode] = useState();
    const [error, setError] = useState('');

    useEffect(() => {
        if(success?.length){
            close()
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE))
        }
    }, [success])

    useEffect(() => {
        if(item){
            setCode(item)
        }
    }, [item]);

    const handleSubmit = () => {
        if (code) {
            const body = {
                ...info,
            }
            if(item){
                const newList = info?.diagnosticCodes?.filter((i) => i !== item)
                body.diagnosticCodes = [...newList, code]
            }else{
                body.diagnosticCodes = [...info.diagnosticCodes, code]
            }
            dispatch(clientActions.editClientsAuthorizations(body, info.id, params.id));
        } else {
            setError('code');
        }
    };

    const handleChange = (e) => {
        setCode(e.target.value)
        if(error){
            setError('')
        }
    };

    return (
        <div>
            <p className='modal-header-title' style={{marginBottom:'40px'}}>{item ? 'Edit Diagnostic Code' : 'Add Diagnostic Code'}</p>
            <ValidationInput
                variant={'outlined'}
                onChange={handleChange}
                value={code}
                type={'text'}
                label={'Name*'}
                placeholder={'Code Name'}
                name='code'
                typeError={error === 'code' ? `Name ${ErrorText.isRequired}` : ''}
            />
            <CreateChancel
                loader={!!loader.length}
                create={item ? 'Save' : 'Add'}
                chancel={'Cancel'}
                onCreate={handleSubmit}
                onClose={() => close()}
                buttonWidth='190px'
            />
        </div>
    );
};