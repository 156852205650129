import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fundingSourceSingleStyles } from './styles';
import { Images, PermissionList, RolePermission, useModal } from 'utils';
import {
    AddButton,
    AddNotes,
    NameAndAvatar,
    DetailInfoCard, CustomDelete, UploadModal, UserInputsDropdown, CustomizedSwitch, SimpleTooltip, SearchHistory,
} from 'components';
import { FundingSourceServiceAdd } from './modals';
import { CreateFundingSource } from '../../createFundingSource';
import { fundingSourceActions } from 'store';
import { FS_STATUSES } from './constants';

const ACTION_TYPE = 'DELETE_FUNDING_SOURCE';
export const FundingSourceSingleHeader = ({}) => {
    const classes = fundingSourceSingleStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const fsSource = useSelector((state) => state.fundingSource.fundingSourceItem);
    const history = useHistory();
    const info = history?.location?.state;
    const { open } = useModal();
    const [isMerge, setIsMerge] = useState(false);

    useEffect(() => {
        if (fsSource) {
            setIsMerge(fsSource?.mergeBilling);
        }
    }, [fsSource]);

    const handleSelectionChange = (currentStatus) => {
        dispatch(fundingSourceActions.changeFundingSourceStatus(params.id, currentStatus?.id));
    };


    const handleSetMerging = () => {
        setIsMerge((prevState) => !prevState);
        const params = {
            ...fsSource,
            mergeBilling: !isMerge,
        };
        delete params?.canDelete;
        delete params?.termination;
        params.address = {
            address: fsSource?.address?.formattedAddress,
            unitNumber: fsSource?.address?.unitNumber,
        };
        dispatch(fundingSourceActions.editFundingSource(fsSource?.id, params));
    };


    return (
        <div className="tabs-wrapper">
            <div className="full-width">
                <div className="space-between-flex-start">
                    <NameAndAvatar
                        name={fsSource?.name}
                        type={'fundingSource'}
                    />
                    <div className="flex-align-start" style={{ gap: 16 }}>

                        {/*{RolePermission([PermissionList.FS_CREATE?.code]) && (info?.activeTab === 'General' || info?.activeTab === undefined) &&*/}
                        {/*    <div className="flex-align-center" style={{ marginTop: '5px', gap: '8px' }}>*/}
                        {/*        <div className={classes.infoIcon}>*/}
                        {/*            <SimpleTooltip*/}
                        {/*                title={*/}
                        {/*                    <p>{'Enabling this toggle will automatically merge all appointments in the claim.'}</p>}*/}
                        {/*                placement="top-end"*/}
                        {/*            >*/}
                        {/*                <img src={Images.infoIcon} alt={'icon'} />*/}
                        {/*            </SimpleTooltip>*/}
                        {/*        </div>*/}
                        {/*        <p className={classes.mergeApptTitleStyle}>Merge Appts.</p>*/}
                        {/*        <CustomizedSwitch*/}
                        {/*            checked={isMerge}*/}
                        {/*            handleClick={handleSetMerging}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*}*/}
                        {RolePermission([PermissionList.FS_STATUS?.code]) &&
                            <UserInputsDropdown
                                dropdownOptions={FS_STATUSES}
                                onPass={handleSelectionChange}
                                selected={fsSource?.status}
                            />
                        }

                        {(info?.activeTab === 'General' || info?.activeTab === undefined) ?
                            <div className="flex-align-center" style={{ gap: '16px' }}>
                                {RolePermission([PermissionList.FS_CREATE?.code]) &&
                                    <button
                                        className="table-action-btn"
                                        onClick={() => open(<CreateFundingSource info={fsSource} />)}>
                                        <img src={Images.edit} alt="icon" />
                                    </button>
                                }

                                {fsSource?.canDelete && RolePermission([PermissionList.FS_DELETE?.code]) &&
                                    <button
                                        className="table-action-btn"
                                        onClick={() => open(
                                            <CustomDelete
                                                text={`Deleting this funding source will permanently remove it from the system.`}
                                                info="Are you sure you want to delete this funding source?"
                                                innerText={'Delete'}
                                                handleDel={() => dispatch(fundingSourceActions.deleteFundingSource(fsSource?.id))}
                                                actionType={ACTION_TYPE}
                                                afterSuccess={() => history.push('/fundingSource')}
                                            />)
                                        }
                                    >
                                        <img src={Images.redTrash} alt={'icon'} />
                                    </button>
                                }
                            </div>
                            :
                            info?.activeTab === 'History' ? (
                                    <SearchHistory />
                                ) :
                                info?.activeTab === 'Services' && RolePermission([PermissionList.FS_SERVICE_CREATE?.code]) ?
                                    <AddButton
                                        text={'Add Service'}
                                        handleClick={() => open(<FundingSourceServiceAdd />)}
                                    />
                                    :
                                    info?.activeTab === 'Notes' && RolePermission([PermissionList.FS_NOTE_MANAGE?.code]) ?
                                        <AddButton
                                            text={'Add Note'}
                                            handleClick={() => open(<AddNotes model="Funder" id={fsSource?.id} />)}
                                        />
                                        :
                                        info?.activeTab === 'Files' && RolePermission([PermissionList.FS_FILE_MANAGE?.code]) ?
                                            <AddButton
                                                Icon={true}
                                                customIcon={Images.uploadCloud}
                                                text={'Upload Document'}
                                                handleClick={() => open(
                                                    <UploadModal
                                                        currentId={fsSource?.id}
                                                        onResource={'fs'}
                                                    />,
                                                )}
                                            />
                                            :
                                            <div/>
                        }
                    </div>
                </div>

                <div className={classes.fsSourceInfoWrapper}>
                    {fsSource?.email &&
                        <DetailInfoCard image={Images.sms} title={fsSource?.email} type={'email'} />
                    }
                    {fsSource?.phoneNumber &&
                        <DetailInfoCard image={Images.callCalling} title={fsSource?.phoneNumber} type={'phone'} />
                    }
                    {fsSource?.address?.formattedAddress &&
                        <DetailInfoCard
                            image={Images.grayLocation}
                            title={`${fsSource?.poBox ? `${fsSource?.poBox}, ` : '' } ${fsSource?.address?.formattedAddress}`}
                            type={'direction'} address={fsSource?.address}
                        />
                    }
                </div>
            </div>
        </div>
    );
};
