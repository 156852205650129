import axios from 'axios';

export const authService = {

    signIn: (user, base) => axios.post(`${base}/authn/signin`, user, { useBaseUrl: false, }),

    logOut: () => axios.post(`/authn/logout`, {}, { auth: true }),

    getLink: (email, base) => axios.get(`${base}/authn/forgotPassword/${email}`, {useBaseUrl: false}),

    confirmAccount: (passwords, base) => axios.post(`${base}/authn`,
        {
            'newPassword': passwords?.newPassword,
            'confirmation': passwords?.confirmation,
        },
        { headers: { 'registration-token': passwords?.token }, useBaseUrl: false },
    ),

    resetPass: (passwords, base) => axios.post(`${base}/authn/resetPassword`, {
            'newPassword': passwords?.newPassword,
            'confirmation': passwords?.confirmation,
        },
        { headers: { 'reset-token': passwords?.token },  useBaseUrl: false },
    ),

    changePasswordService: (data) => axios.post('/authn/changePassword', data, { auth: true }),

    changeNotificationService: (id, data) => axios.patch(`/staff/notifications`, data, { auth: true }),

    myProfileService: () => axios.get(`/staff/myProfile`, { auth: true }),

    /** Access service */
    getAccessService: (id) => axios.get(`/authn/${id}`, { auth: true }),

    addAccessService: (userId, roleId) => axios.patch(`/authn/${userId}/${roleId}/addRole`, null, { auth: true }),

    removeAccessService: (userId, roleId) => axios.patch(`/authn/${userId}/${roleId}/removeRole`, null, { auth: true }),
    /** End */

    getBaseUrlService: (email) => axios.get(`${process.env.REACT_APP_API_BASE}/user/${email}/identify`, { useBaseUrl: false }),

    /** Mailer service */
    mailer: (data) => axios.post(`${process.env.REACT_APP_API_BASE}/mailer/contact-form`, data),
    /** End */
};
