import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Popper } from '@material-ui/core';
import { Images, SaveParams } from 'utils';

export const CalendarInterval = ({userSettings}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    let history = useHistory();
    const info =  history?.location?.state;
    const slot = info?.slot ? info?.slot : userSettings?.slot ? userSettings?.slot : 30;

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleSelectCurrent = (value) => {
        const params = {
            ...info,
        };
        params.slot = value;
        setAnchorEl(null);

        const storage = {
            ...userSettings,
            slot: value,
        }
        localStorage.setItem('userSettings', JSON.stringify(storage));
        SaveParams(history, { ...params });
    };

    return (
        <div>
            <button
                style={slot ? { background: '#EBF2FD' } : {}}
                aria-describedby={id}
                className="interval-btn"
                onClick={handleClick}
            >
                <img src={Images.timelapse} alt="icon" />
                <p>Interval</p>
            </button>

            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                className="interval-selector-box"
                placement="bottom-start"
                style={{ position: 'absolute', zIndex: 1500 }}
            >
                <button className={`interval-buttons ${slot === 60  ? 'active-interval' : ''}`}
                        onClick={() => handleSelectCurrent(60)}>2 Hour
                </button>
                <button className={`interval-buttons ${slot === 30 || !slot ? 'active-interval' : ''}`}
                        onClick={() => handleSelectCurrent(30)}>1 Hour
                </button>
                <button className={`interval-buttons ${slot === 15 ? 'active-interval' : ''}`}
                        onClick={() => handleSelectCurrent(15)}>30 Minutes
                </button>
                <button className={`interval-buttons ${slot === 7.5 ? 'active-interval' : ''}`}
                        onClick={() => handleSelectCurrent(7.5)}>15 Minutes
                </button>
            </Popper>
            {open && <div className="beck-drop" onClick={() => setAnchorEl(null)} />}
        </div>
    );
};