import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
    AddButton,
    CustomDelete,
    DateTypeSelect,
    FullTable,
    SelectTypeAutocomplete,
    ValidationInput,
} from 'components';
import { ErrorText, FindLoad, FindSuccess, renderStaffName, useModal } from 'utils';
import { serviceSingleStyles } from './styles';
import { adminActions, clientActions, httpRequestsOnSuccessActions } from 'store';
import { ASSIGN_ACTION_TYPE, assignmentBody, assignmentHead, staffAssignmentNotYet } from './constants';
import { EditAssignedStaff } from './common';

const credentialBtn = {
    maxWidth: '154px',
    width: '100%',
    flex: '0 0 154px',
    padding: 0,
};

const ACTION_TYPE = 'ASSIGN_STAFF';
const REMOVE_ACTION_TYPE = 'REMOVE_STAFF';

export const StaffAssignment = ({ }) => {
    const client = useSelector((state) => state.client.clientItemInfo);
    const clientStaffs = useSelector((state) => state.client.clientStaffs);
    const classes = serviceSingleStyles();
    const dispatch = useDispatch();
    const adminsList = useSelector((state) => state.admins.adminsList);
    const [inputs, setInputs] = useState({});
    const [error, setError] = useState('');
    const loader = FindLoad(ACTION_TYPE);
    const success = FindSuccess(ACTION_TYPE);
    const { open, close } = useModal();

    useEffect(() => {
        if (success?.length) {
            close();
            setInputs('');
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    useEffect(() => {
        dispatch(adminActions.getAdmins({ status: 'ACTIVE' }));
        dispatch(clientActions.getAssignedStaffs(client?.id));
    }, []);

    const handleChange = (e) => {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        (error === e.target.name || error === ErrorText.dateError) && setError('');
    };

    const handleSubmit = () => {
        if (inputs?.staff && inputs?.role) {
            const params = {
                'staffId': inputs?.staff,
                'clientId': client?.id,
                'role': inputs?.role,
                'assign': true,
            };
            inputs?.assignmentDate && (params['assignmentDate'] = inputs?.assignmentDate);
            dispatch(clientActions.assignStaff(params));
        } else {
            setError(
                !inputs?.staff ? 'staff' :
                    !inputs?.role ? 'role' : '',
            );
        }
    };

    const handleClickButton = (item, name) => {
        if(name === 'delete') {
            open(<CustomDelete
                text={`Are you sure you want to unassign this staff from the client?`}
                info="Unassign Staff?"
                innerText={'Unassign'}
                handleDel={() => dispatch(clientActions.removeClientStaff(client?.id, item?.assignedId))}
                handleClose={() => close()}
                actionType={REMOVE_ACTION_TYPE}
            />);
        }
        if(name === 'edit'){
            open(
                <EditAssignedStaff item={item}/>
            )
        }
    };

    const filteredList = adminsList?.staffs?.length &&
        adminsList?.staffs.filter(function(array_el) {
            return (
                clientStaffs?.filter(function(anotherOne_el) {
                    return anotherOne_el?.staffId === array_el.id;
                }).length === 0
            );
        });

    return (
        <div className='assign-staff-wrapper'>
            <div className="flex-align-start" style={{ gap: 16 }}>
                <SelectTypeAutocomplete
                    customClass={error === 'staff' ? classes.smallSelectError : classes.smallSelect}
                    placeholder={'Select Staff Member*'}
                    name={'staff'}
                    handleSelect={handleChange}
                    defaultValue={inputs?.staff}
                    list={filteredList}
                    error={error}
                    typeError={error === 'staff' ? `Staff member ${ErrorText.isRequired}` : ''}
                    renderValue={(i) => `${renderStaffName(i)} (${i?.employment?.title?.name})`}
                />
                <ValidationInput
                    style={classes.staffRole}
                    onChange={handleChange}
                    value={inputs.role}
                    variant={'outlined'}
                    name={'role'}
                    type={'text'}
                    placeholder={'Enter Staff Role*'}
                    typeError={error === 'role' ? `Staff role ${ErrorText.isRequired}` : ''}
                    Length={20}
                />
                <DateTypeSelect
                    type={'modalInput'}
                    name={'assignmentDate'}
                    outLabel={'Assignment Date'}
                    handleGetDates={handleChange}
                    values={inputs?.assignmentDate ? inputs : {}}
                    max={ moment.utc().format('YYYY-MM-DD')}
                    resetValue={true}
                />
                <AddButton
                    loader={!!loader.length}
                    type={ACTION_TYPE}
                    styles={credentialBtn}
                    handleClick={handleSubmit}
                    text="Assign Staff"
                />
            </div>

            <p className={classes.title}>Assigned Staff</p>
            <FullTable
                head={assignmentHead}
                body={assignmentBody}
                loadingType={ASSIGN_ACTION_TYPE}
                list={clientStaffs}
                handleClickButton={handleClickButton}
                notYet={staffAssignmentNotYet}
                activeRowId={''}
            />
        </div>
    );
};