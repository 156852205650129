import {
    GET_ACCESS_SUCCESS, GET_MY_PROFILE_SUCCESS,
} from './auth.types';

const initialState = {
    accessToke: null,
    userInfo: null,
    permissions: '',
    accessList: [],
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {

        case  GET_MY_PROFILE_SUCCESS:
            return {
                ...state,
                userInfo: action.payload,
            };

        case GET_ACCESS_SUCCESS:
            return {
                ...state,
                accessList: action.payload,
            };

        default:
            return state;
    }
};
