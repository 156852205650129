import {
    DELETE_GENERAL_REPORT,
    DOWNLOAD_PDF,
    GET_GENERAL_REPORTS,
    GET_SIGNATURE_REPORTS,
    GET_UTILIZATION_REPORT,
} from './reports.types';

/** Reports */

export const getGeneralReports = (params) => {
    return {
        type: GET_GENERAL_REPORTS,
        payload: { params },
    };
};

export const deleteGeneralReports = (id) => {
    return {
        type: DELETE_GENERAL_REPORT,
        payload: { id },
    };
};
export const getSignatureReports = (params) => {
    return {
        type: GET_SIGNATURE_REPORTS,
        payload: { params },
    };
};

export const downloadPdf = (params) => {
    return {
        type: DOWNLOAD_PDF,
        payload: { params },
    };
};

export const getUtilizationReport = (params) => {
    return {
        type: GET_UTILIZATION_REPORT,
        payload: { params },
    };
};

/** End */