import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';
import { ButtonLanding, InputMain, MaskInputMain } from 'components';
import { authService } from 'store';

export const GetInTouch = () => {
    const [notRobot, toggleNotRobotCheckbox] = useState(false);
    const [loading, setLoading] = useState(false);
    const recaptchaRef = useRef(null);
    const { register, handleSubmit, control, reset } = useForm();

    const onSubmit = async (data) => {
        const onlyNumbers = data?.phone.replace(/\D/g, '');

        const sendData = {
            fullName: data?.fullName,
            email: data?.email,
            message: data?.message,
            phone: onlyNumbers,
            companyName: data?.companyName,
        };

        setLoading(true);
        try {
            if (notRobot) {
                await authService.mailer(sendData);
            }
            reset({
                fullName: '',
                email: '',
                phone: '',
                companyName: '',
                message: '',
            });
            toast.success('Message was sent', {
                position: 'bottom-right',
            });
        } catch (e) {
            toast.error('Something went wrong', {
                position: 'bottom-right',
            });
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    function onChange(val) {
        toggleNotRobotCheckbox(val);
    }

    return (
        <div className="get-in-touch-wrapper">
            <form onSubmit={handleSubmit(onSubmit)} className="inputs-form">
                <div className="inputs-form-display-flex">
                    <InputMain
                        {...register('fullName', { required: 'This Field is required' })}
                        control={control}
                        placeholder="Full Name*"
                        classNameWrapper="margin-bottom"
                    />
                    <InputMain
                        {...register('email', { required: 'This Field is required' })}
                        control={control}
                        placeholder="Email*"
                        type="email"
                        classNameWrapper="margin-bottom"
                        required
                    />
                </div>

                <div className="inputs-form-display-flex">
                    <MaskInputMain
                        {...register('phone', { required: 'This Field is required' })}
                        control={control}
                        placeholder="Phone Number"
                        classNameWrapper="margin-bottom"
                        type="number"
                    />
                    <InputMain
                        {...register('companyName', { required: 'This Field is required' })}
                        control={control}
                        placeholder="Company Name*"
                        classNameWrapper="margin-bottom"
                    />
                </div>

                <InputMain
                    {...register('message', { required: 'This Field is required' })}
                    control={control}
                    placeholder="Your Message*"
                    classNameWrapper="margin-bottom fullWidth"
                />

                <div className="checkbox-button-box">
                    <div className="checkboxes-wrapper">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={'6Leq6wAqAAAAABQhutLaoN5Sw0etRUz_ex7yLwEg'}
                            onChange={onChange}
                        />
                    </div>

                    <ButtonLanding
                        disabled={!notRobot}
                        load={loading}
                        circleSize={35}
                        loadingColor="#FFF"
                        arrowIcon={true}
                        text="Send Message"
                        className="send-button"
                        type="submit"
                    />
                </div>
            </form>
        </div>
    );
};

