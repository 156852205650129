import React from 'react';
import ReactPlayer from 'react-player';
import { Svg } from 'assets';
import { useWidth } from 'utils';

export const SectionBox = ({ content, isLong }) => {
    const width = useWidth();
    const version = isLong ? 'long' : 'short';

    const parts = (title, boldTexts) => boldTexts && title.split(new RegExp(`(${boldTexts.join('|')})`));

    let imageSize;

    if (width > 1279) {
        imageSize = 'desktop';
    } else if (width > 767) {
        imageSize = 'desktop';
    } else {
        imageSize = 'desktop';
    }


    return (
        <div className='help-center-content-box'>
            {content?.subMenuTitle && <p className='help-center-content-title'>{content?.subMenuTitle}</p>}

            {(content?.infoTitle || content?.infoDescription) &&
                <div className='help-center-header-info'>
                    <p className='help-center-info-title'>{content?.infoTitle}</p>
                    <p className='help-center-info-description'>{content?.infoDescription}</p>
                </div>
            }

            {content?.pageTitle && <h3 className='help-center-content-title'>{content?.pageTitle}</h3>}

            <div className='flex-gap-32'>
                {!!content?.content?.[version]?.length && content?.content?.[version]?.map((item, index) => (
                    <div className='help-center-content-list' key={index}>
                        {item?.subTitle && <h4 className='help-center-content-subTitle'>{item?.subTitle}</h4>}

                        <ol className={`${!item?.circle && !item?.decimal ? 'list-style-type-none' : item?.circle ? 'list-style-type-decimal list-style-type-circle' : 'list-style-type-decimal'} flex-gap-32`}
                        >
                            {(!!item?.list?.length || !!item?.images?.length)
                                && item?.list.map((item, index) => {
                                        if (item?.list) {
                                            return (
                                                <li key={index}>
                                                    {(item?.title && item?.boldTexts) ?
                                                        parts(item?.title, item?.boldTexts).map((part, index) => (item?.boldTexts.includes(part)
                                                                ? (
                                                                    <span
                                                                        className='help-center-content-description'
                                                                        key={index}
                                                                        style={{ fontWeight: 500 }}>
                                                                                        {part}
                                                                                    </span>
                                                                )
                                                                : (
                                                                    <span
                                                                        className='help-center-content-description'
                                                                        key={index}

                                                                    >
                                                                                        {part}
                                                                                    </span>
                                                                )
                                                        ))
                                                        : <span className='help-center-content-description'>
                                                                            {item?.title}
                                                                        </span>
                                                    }

                                                    {!!item?.list?.length &&
                                                        <ol>
                                                            {item?.list.map((item, index) => (
                                                                <li className={`${item?.circle ? 'list-style-type-circle' : 'list-style-type-none'}`}
                                                                    key={index}>
                                                                    {(item?.title && item?.boldTexts) ?
                                                                        parts(item?.title, item?.boldTexts).map((part, index) => (item?.boldTexts.includes(part)
                                                                                ? (
                                                                                    <span
                                                                                        className='help-center-content-description'
                                                                                        key={index}
                                                                                        style={{ fontWeight: 500 }}>
                                                                                        {part}
                                                                                    </span>
                                                                                )
                                                                                : (
                                                                                    <span
                                                                                        className='help-center-content-description'
                                                                                        key={index}

                                                                                    >
                                                                                        {part}
                                                                                    </span>
                                                                                )
                                                                        ))
                                                                        : <span className='help-center-content-description'>
                                                                            {item?.title}
                                                                        </span>
                                                                    }

                                                                    {!!item?.list?.length &&
                                                                        <ol>
                                                                            {item?.list.map((item, index) => (
                                                                                <li className={`${item?.circle ? 'list-style-type-circle' : 'list-style-type-none padding-18'}`}
                                                                                    key={index}>
                                                                                    {(item?.title && item?.boldTexts) ?
                                                                                        parts(item?.title, item?.boldTexts).map((part, index) => (item?.boldTexts.includes(part)
                                                                                                ? (
                                                                                                    <span
                                                                                                        className='help-center-content-description'
                                                                                                        key={index}
                                                                                                        style={{ fontWeight: 500 }}>
                                                                                                             {part}
                                                                                                      </span>
                                                                                                )
                                                                                                : (
                                                                                                    <span
                                                                                                        className='help-center-content-description'
                                                                                                        key={index}

                                                                                                    >
                                                                                                         {part}
                                                                                                     </span>
                                                                                                )
                                                                                        ))
                                                                                        : <span
                                                                                            className='help-center-content-description'>
                                                                                             {item?.title}
                                                                                            </span>
                                                                                    }

                                                                                    {!!item?.list?.length &&
                                                                                        <ol>
                                                                                            {item?.list.map((item, index) => (
                                                                                                <li className={`${item?.circle ? 'list-style-type-circle' : 'list-style-type-none padding-30'} `}
                                                                                                    key={index}>
                                                                                                    {(item?.title && item?.boldTexts) ?
                                                                                                        parts(item?.title, item?.boldTexts).map((part, index) => (item?.boldTexts.includes(part)
                                                                                                                ? (
                                                                                                                    <span
                                                                                                                        className='help-center-content-description'
                                                                                                                        key={index}
                                                                                                                        style={{ fontWeight: 500 }}>
                                                                                                             {part}
                                                                                                      </span>
                                                                                                                )
                                                                                                                : (
                                                                                                                    <span
                                                                                                                        className='help-center-content-description'
                                                                                                                        key={index}

                                                                                                                    >
                                                                                                                        {part}
                                                                                                                     </span>
                                                                                                                )
                                                                                                        ))
                                                                                                        : <span
                                                                                                            className='help-center-content-description'>
                                                                                                                {item?.title}
                                                                                                            </span>
                                                                                                    }
                                                                                                </li>
                                                                                            ))}
                                                                                        </ol>
                                                                                    }
                                                                                </li>

                                                                            ))}
                                                                        </ol>
                                                                    }
                                                                </li>
                                                            ))
                                                            }
                                                        </ol>
                                                    }

                                                </li>
                                            );
                                        } else if (item?.notes) {
                                            return (
                                                <li key={index} className='flex-gap-32' style={{ listStyleType: 'none' }}>
                                                    {item?.notes?.map((note) => (
                                                        <div className='notes_block'>
                                                            {!note.Icon ?
                                                                <Svg.NotificationsActiveSvg
                                                                    className='help-center-notes-svg' />
                                                                : <note.Icon className='help-center-notes-svg' />
                                                            }

                                                            <div>
                                                                {parts(note?.text, note?.boldTexts).map((part, index) => (note?.boldTexts.includes(part)
                                                                        ? (
                                                                            <span
                                                                                className='help-center-content-description'
                                                                                key={index}
                                                                                style={{ fontWeight: 500 }}>{part}</span>)
                                                                        : (
                                                                            <span
                                                                                className='help-center-content-description'
                                                                                key={index}

                                                                            >
                                                                        {part}
                                                                    </span>
                                                                        )

                                                                ))}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </li>
                                            );

                                        } else {
                                            return (
                                                <li key={index} className='flex-gap-32' style={{ listStyleType: 'none' }}>
                                                    {item?.images?.map((image) => (
                                                        <div
                                                            className={`${(!item?.circle && !item?.decimal) ? 'image-block image-block-m0' : 'image-block'}`}>
                                                            <img src={image[imageSize]} alt='help' />
                                                        </div>
                                                    ))}

                                                    {item?.videos?.map((video) => (
                                                        <div
                                                            className={`${(!item?.circle && !item?.decimal) ? 'image-block image-block-m0' : 'image-block'}`}>
                                                            <ReactPlayer url={video[imageSize]} width={'100%'}
                                                                         height={'100%'}
                                                                         playing={true}
                                                                         loop={true}
                                                                         muted={true}
                                                            />
                                                        </div>
                                                    ))}
                                                </li>
                                            );
                                        }

                                    },
                                )}
                        </ol>
                    </div>))}
            </div>
        </div>);
};

