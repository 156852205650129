import React, { useEffect, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { Button } from 'components';
import { navLinks } from './constants';
import { landingImages } from 'assets';
import { useSelector } from 'react-redux';

export const TopBar = () => {
    const history = useHistory();
    const location = useLocation();
    const [menuIsShown, setMenuIsShown] = useState(false);
    const [headerStyles, setHeaderStyles] = useState({
        background: 'transparent',
        boxShadow: 'none',
    });


    const navigateHome = () => {
        history.push('/');
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
        setMenuIsShown(false);
    };


    const handleScroll = () => {
        if (window.scrollY >= 10 || location.pathname !== '/') {
            setHeaderStyles({
                background: 'white',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            });
        } else {
            setHeaderStyles({
                background: 'transparent',
                boxShadow: 'none',
            });
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav className='topbar-container' style={headerStyles}>
            <div className='topbar-content '>
                <button onClick={navigateHome} className='top-bar-logo'>
                    <landingImages.BlackLogo />
                </button>

                <div className='desktop-nav'>
                    <div className='nav-links-container'>
                        <ul className='nav-links-list'>
                            {navLinks.map((navLink, index) => (
                                <li key={index}>
                                    <NavLink
                                        className={location?.pathname === navLink.path ? 'active-link' : ''}
                                        to={navLink.path}>{navLink.name}</NavLink>
                                </li>
                            ))}
                            <button
                                onClick={() => history.push('/contact-us')}
                                className='book-demo-button'
                            >
                                Book Demo
                                <img src={landingImages.ButtonArrow} alt='icon' />
                            </button>
                        </ul>
                    </div>
                </div>
                <div className='tablet-nav'>
                    <Button
                        buttonType={'button'}
                        buttonClassName={`menu-button ${menuIsShown ? 'shown' : ''}`}
                        onClickButton={() => setMenuIsShown((prevState) => !prevState)}
                    />
                    <div
                        className={`menu-container ${menuIsShown ? 'shown' : ''}`}
                        onClick={() => setMenuIsShown(false)}
                    >
                        <div className='nav-links-container'>
                            <ul className='nav-links-list column'>
                                {navLinks.map((navLink, index) => (
                                    <li key={index}>
                                        <NavLink to={navLink.path}>{navLink.name}</NavLink>
                                    </li>
                                ))}
                                <Button
                                    buttonType={'button'}
                                    buttonClassName={'book-demo-button-mobile'}
                                    onClickButton={() => history.push('/contact-us')}
                                >
                                    Book Demo
                                </Button>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};
