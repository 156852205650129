import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddButton, FilesComponent, UploadModal } from 'components';
import { Images, PermissionList, RolePermission, useModal } from 'utils';
import { appointmentActions } from 'store';

export const ApptFiles = ({ item }) => {
    const { apptDocuments } = useSelector((state) => ({
        apptDocuments: state.appointment.apptDocuments,
    }));
    const { open } = useModal();
    const dispatch = useDispatch();

    useEffect(() => {
        if( RolePermission([PermissionList.APPT_FILE_MANAGE?.code, PermissionList.APPT_FILE_READ?.code])) {
            dispatch(appointmentActions.getApptDocument(item.id));
        }
    }, []);

    return(
            <div>
                <div className='flex-end'>
                    <AddButton
                        Icon={true}
                        customIcon={Images.uploadCloud}
                        text={'Upload Document'}
                        handleClick={() => open(
                            <UploadModal
                                currentId={item?.id}
                                onResource={'appt'}
                            />,
                        )}
                    />
                </div>
                <FilesComponent
                    currentId={item?.id}
                    onResource={'appt'}
                    documents={apptDocuments}
                    loadTable={'GET_APPT_DOCUMENT'}
                    permissions={[PermissionList.APPT_FILE_MANAGE?.code]}
                />
            </div>
    )
}