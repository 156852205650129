import { call, put, takeLatest } from 'redux-saga/effects';
import { httpRequestsOnLoadActions } from '../http_requests_on_load';
import { httpRequestsOnSuccessActions } from '../http_requests_on_success';
import { ADD_TAG_TO_MEMBER, CREATE_TAG, DELETE_TAG, EDIT_TAG, GET_TAGS, GET_TAGS_SUCCESS, REMOVE_TAG_FROM_MEMBER, } from './tag.types';
import { authService } from './tag.service';
import { httpRequestsOnErrorsActions } from '../http_requests_on_errors';
import { GET_ADMINS_SUCCESS } from '../admin/admin.types';
import { GET_CLIENTS_SUCCESS } from '../client/client.types';


/** Tags */

function* getTags({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(authService.getTagsService, payload?.params);
        yield put({
            type: GET_TAGS_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type));
    }
}

function* createTag({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        yield call(authService.createTagService, payload?.body);
        const res = yield call(authService.getTagsService, payload?.params);
        yield put({
            type: GET_TAGS_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type));
    }
}

function* editTag({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        yield call(authService.editTagService, payload?.body);
        const res = yield call(authService.getTagsService, payload?.params);
        yield put({
            type: GET_TAGS_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type));
    }
}

function* deleteTag({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        yield call(authService.deleteTagService, payload?.id);
        const res = yield call(authService.getTagsService, payload?.params);
        yield put({
            type: GET_TAGS_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type));
    }
}

/** End */

/** Tag for members */
function* addTagToMember({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        if (payload?.module === 'Staff') {
            yield call(authService.addTagToStaffService, payload?.id, payload?.tagId);
        }
        if (payload?.module === 'Client') {
            yield call(authService.addTagToClientService, payload?.id, payload?.tagId);
        }
        if (payload?.module === 'Staff') {
            const res = yield call(authService.getAdminsService, payload?.requestParams);
            yield put({
                type: GET_ADMINS_SUCCESS,
                payload: { staff: res.data },
            });
        }
        if (payload?.module === 'Client') {
            const res = yield call(authService.getClientsService, payload?.requestParams);
            yield put({
                type: GET_CLIENTS_SUCCESS,
                payload: res.data,
            });
        }
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type));
    }
}

function* removeTagFromMember({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        if (payload?.module === 'Staff') {
            yield call(authService.removeTagToStaffService, payload?.id, payload?.tagId);
        }
        if (payload?.module === 'Client') {
            yield call(authService.removeTagToClientService, payload?.id, payload?.tagId);
        }
        if (payload?.module === 'Staff') {
            const res = yield call(authService.getAdminsService, payload?.requestParams);
            yield put({
                type: GET_ADMINS_SUCCESS,
                payload: { staff: res.data },
            });
        }
        if (payload?.module === 'Client') {
            const res = yield call(authService.getClientsService, payload?.requestParams);
            yield put({
                type: GET_CLIENTS_SUCCESS,
                payload: res.data,
            });
        }
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type));
    }
}

/** End */


export const watchTags = function* watchTagsAuth() {

    /** Tags  */
    yield takeLatest(GET_TAGS, getTags);
    yield takeLatest(CREATE_TAG, createTag);
    yield takeLatest(EDIT_TAG, editTag);
    yield takeLatest(DELETE_TAG, deleteTag);
    /** End */

    /** Tag for members */
    yield takeLatest(ADD_TAG_TO_MEMBER, addTagToMember);
    yield takeLatest(REMOVE_TAG_FROM_MEMBER, removeTagFromMember);
    /** End */

};
