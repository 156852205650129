import React from "react";
import { useGlobalTextStyles } from "utils";
import { modalHeadersStyle } from "./styles";
import { ModalHeaderBottom } from "./modalHeaderBottom";
import { ModalsTabs } from "components/tabs";

export const ModalHeader = ({
   className,
   title,
   headerBottom,
   steps,
   text,
   setStep,
   secondStepInfo,
}) => {
   const classes = modalHeadersStyle();
   const globalStyle = useGlobalTextStyles();

   const modalHeaderStyle = `${classes.createFoundingSourceHeader} ${className}`;

   return (
      <div className={modalHeaderStyle}>
         <p className={globalStyle.modalTitle}>{title}</p>
         {text && <p className={classes.modalText}>{text}</p>}
         {steps && (
            <ModalsTabs secondStepInfo={secondStepInfo} steps={steps} setStep={setStep} />
         )}
         {headerBottom && <ModalHeaderBottom />}
      </div>
   );
};
