import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDelete, FullTable } from 'components';
import {
    DELETE_GENERAL_REPORT,
    GENERAL_REPORT_ACTION_TYPE,
    GeneralReportNotYet,
    GeneralReportsBody,
    GeneralReportsHead,
} from './constants';
import { reportActions } from 'store';
import { useModal } from 'utils';

export const GeneralReports = ({}) => {
    const dispatch = useDispatch();
    const generalReports = useSelector((state) => state.reports.generalReports);
    const { open } = useModal();

    useEffect(() => {
        dispatch(reportActions.getGeneralReports({
            skip: 0,
            limit: 30,
        }))
    }, [dispatch])

    const openDelete = (item) => {
        open(
            <CustomDelete
                text="Are you sure you want to delete this report? This action is not reversible."
                info="Delete Report"
                handleDel={() => dispatch(reportActions.deleteGeneralReports(item?.id))}
                actionType={DELETE_GENERAL_REPORT}
                innerText={'Delete'}
            />,
        )
    }

    return(
        <div>
            <FullTable
                head={GeneralReportsHead()}
                body={GeneralReportsBody(openDelete)}
                loadingType={GENERAL_REPORT_ACTION_TYPE}
                list={ generalReports }
                notYet={GeneralReportNotYet}
                activeRowId={''}
                margin={'0'}
            />
        </div>
    )
}