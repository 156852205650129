import React, { useEffect } from 'react';
import InputMask from 'react-input-mask';
import { TextField } from '@material-ui/core';
import { useGlobalStyles } from 'utils';
import { ErrMessage } from '../messages';
import { inputsStyle } from './styles';

export const MaskInput = ({ name, label, value, disables, handleChange, error, mask, styles, className }) => {
    const globalWrappers = useGlobalStyles();
    const classes = inputsStyle();
    const [currentValue, setCurrentValue] = React.useState('');

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    return (
        <div
            className={globalWrappers.simpleInput}
            style={{ display: 'flex', flexDirection: 'column', width: '100%', ...styles }}
        >
            <InputMask
                mask={mask ? mask : '(999) 999-9999'}
                value={currentValue}
                disabled={disables}
                onChange={handleChange}
            >
                {() =>
                    <TextField
                        disabled={disables}
                        variant={'outlined'}
                        label={label}
                        name={name}
                        className={className ? className : classes.inputTextField}
                        error={error}
                    />
                }
            </InputMask>
            <div style={{ position: 'relative' }}>
                <ErrMessage text={error} />
            </div>
        </div>
    );
};