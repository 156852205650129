export const languages = [
    'Arabic',
    'Albanian',
    'Bulgarian',
    'Catalan',
    'Chinese',
    'Croatian',
    'Czech',
    'Danish',
    'German',
    'Greek',
    'English',
    'Spanish',
    'Finnish',
    'French',
    'Hebrew',
    'Hungarian',
    'Icelandic',
    'Italian',
    'Indonesian',
    'Japanese',
    'Korean',
    'Dutch',
    'Norwegian',
    'Polish',
    'Portuguese',
    'Romansh',
    'Romanian',
    'Russian',
    'Slovak',
    'Swedish',
    'Thai',
    'Turkish',
    'Urdu',
];

export const languagesById = [
    { id: 'Arabic', label: 'Arabic' },
    { id: 'Albanian', label: 'Albanian' },
    { id: 'Armenian', label: 'Armenian' },
    { id: 'Bulgarian', label: 'Bulgarian' },
    { id: 'Catalan', label: 'Catalan' },
    { id: 'Chinese', label: 'Chinese' },
    { id: 'Croatian', label: 'Croatian' },
    { id: 'Czech', label: 'Czech' },
    { id: 'Danish', label: 'Danish' },
    { id: 'German', label: 'German' },
    { id: 'Greek', label: 'Greek' },
    { id: 'English', label: 'English' },
    { id: 'Spanish', label: 'Spanish' },
    { id: 'Finnish', label: 'Finnish' },
    { id: 'French', label: 'French' },
    { id: 'Hebrew', label: 'Hebrew' },
    { id: 'Hungarian', label: 'Hungarian' },
    { id: 'Icelandic', label: 'Icelandic' },
    { id: 'Italian', label: 'Italian' },
    { id: 'Indonesian', label: 'Indonesian' },
    { id: 'Japanese', label: 'Japanese' },
    { id: 'Korean', label: 'Korean' },
    { id: 'Dutch', label: 'Dutch' },
    { id: 'Norwegian', label: 'Norwegian' },
    { id: 'Polish', label: 'Polish' },
    { id: 'Portuguese', label: 'Portuguese' },
    { id: 'Romansh', label: 'Romansh' },
    { id: 'Romanian', label: 'Romanian' },
    { id: 'Russian', label: 'Russian' },
    { id: 'Slovak', label: 'Slovak' },
    { id: 'Swedish', label: 'Swedish' },
    { id: 'Thai', label: 'Thai' },
    { id: 'Turkish', label: 'Turkish' },
    { id: 'Urdu', label: 'Urdu' }
];