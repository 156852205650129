import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RouterSwitcher } from './root/routerSwitcher';
import { Footer, TopBar } from './fragments';
import { Toast } from './components';
import { ToastFail, ToastSuccess } from 'utils';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, notificationActions } from 'store';
import { getToken } from 'firebase/messaging';
import { messaging } from './firebase';
// import { onMessage } from 'https://www.gstatic.com/firebasejs/9.0.0/firebase-messaging.js';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

// import { fetchToken, onMessageListener } from './firebase';

function App() {
    const { httpOnError, httpOnSuccess, httpOnLoad } = useSelector((state) => ({
        httpOnError: state.httpOnError,
        httpOnSuccess: state.httpOnSuccess,
        httpOnLoad: state.httpOnLoad,
    }));
    const dispatch = useDispatch();
    const route = useLocation();
    const token = localStorage.getItem('access-token');
    const success = httpOnSuccess.length && httpOnSuccess[0].type;
    const error = httpOnError.length && httpOnError[0].type;
    const errorMessage = httpOnError.length && httpOnError[0].error;
    const toastSuccess = ToastSuccess(success);
    const toastFail = ToastFail(error, errorMessage);


    useEffect(() => {
        if (!!toastSuccess) {
            // if (!!success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(success));
        }
    }, [toastSuccess]);

    useEffect(() => {
        if (!!toastFail) {
            // if (!!error) {
            dispatch(httpRequestsOnErrorsActions.removeError(error));
        }
    }, [toastFail]);


    const checkBar = () => {
        return route?.pathname === '/' ||
            route?.pathname === '/features' ||
            route?.pathname === '/about-us' ||
            route?.pathname === '/contact-us' ||
            route?.pathname === '/help-center' ||
            route?.pathname === '/help-center/getting-started' ||
            route?.pathname === '/help-center/control-panel' ||
            route?.pathname === '/terms-and-conditions' ||
            route?.pathname === '/privacy-policy';
    };

    const dontShowFooter = () => {
        return route?.pathname === '/help-center';
    };

    const registerServiceWorkerAndRequestPermission = async () => {
        if ('serviceWorker' in navigator) {
            try {
                const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
                messaging.useServiceWorker(registration);
            } catch (error) { }
        }
    };

    const requestNotificationPermission = async () => {
        try {
            const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPED });
            if (token) {
                const deviceId = getDeviceId();
                axios.patch('/staff/pnToken', {pnToken: token, deviceOS: 'WEB', deviceId: deviceId }, { auth: true}).then((res) => { }).catch((err) => {});
            }
        } catch (error) { }
    };

    useEffect(() => {
        requestNotificationPermission().then(r => {});
        registerServiceWorkerAndRequestPermission().then(r => {});

    }, []);

    // onMessage(messaging, (payload) => {
    //     dispatch(notificationActions.getUnRenderNotifications());
    // });

    const getDeviceId = () => {
        let deviceId = localStorage.getItem('deviceId');
        if (!deviceId) {
            deviceId = uuidv4();
            localStorage.setItem('deviceId', deviceId);
        }
        return deviceId;
    };

    return (
        <div>
            {checkBar() ?
                <div className="App">
                    {!token && <TopBar />}
                    <RouterSwitcher />
                    {!token && !dontShowFooter() && <Footer />}
                </div>
                :
                <RouterSwitcher />
            }

            <Toast
                type={toastSuccess ? 'success' : toastFail ? 'error' : ''}
                text={toastSuccess ? toastSuccess : toastFail ? toastFail : ''}
                info={toastSuccess ? !!toastSuccess : toastFail ? !!toastFail : ''}
            />
        </div>
    );
}

export default App;
