import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { authActions } from 'store';

const INACTIVITY_TIME = 3600000; // 1 hour in milliseconds
const ACTIVITY_EVENT = 'user-activity';

export const InactivityHandler = () => {
    const dispatch = useDispatch();
    const timerRef = useRef(null);

    const resetTimer = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            handleLogout();
        }, INACTIVITY_TIME);
    };

    const handleLogout = () => {
        dispatch(authActions.logOut());
    };

    const broadcastActivity = () => {
        localStorage.setItem(ACTIVITY_EVENT, Date.now());
    };

    const handleStorageEvent = (event) => {
        if (event.key === ACTIVITY_EVENT) {
            resetTimer();
        }
    };

    useEffect(() => {
        const events = ['mousemove', 'keydown', 'mousedown', 'touchstart'];

        const handleActivity = () => {
            resetTimer();
            broadcastActivity();
        };

        // Add event listeners for specified events
        events.forEach(event => window.addEventListener(event, handleActivity));

        // Listen for storage events to synchronize activity across tabs
        window.addEventListener('storage', handleStorageEvent);

        // Initialize the inactivity timer on component mount
        resetTimer();

        // Cleanup event listeners and timer on component unmount
        return () => {
            events.forEach(event => window.removeEventListener(event, handleActivity));
            window.removeEventListener('storage', handleStorageEvent);
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);

    return null; // This component doesn't render anything
};