import React from 'react';
import {
    AccessKey,
    CustomerTestimonials,
    EmpoweringPractices,
    FeaturesForProdMgmt,
    TechnologicalInnovations,
    WhyTherapy,
} from './core';

export const HomeFragment = () => {
    return (
        <div className='home-fragment'>
            <EmpoweringPractices />

            <FeaturesForProdMgmt />

            <WhyTherapy />

            <TechnologicalInnovations />

            <CustomerTestimonials />

            <AccessKey />
        </div>
    );
};
