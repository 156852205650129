import React from 'react';
import { wrapperStyle } from './styles';
import { DownloadCsv, SimpleModal } from '../modal';
import { Loader } from '../loader';

export const TableWrapper = ({
                                 buttonsTab,
                                 buttonsTabAddButton,
                                 children,
                                 loader,
                                 addButtonText,
                                 body,
                                 openCloseInfo,
                                 handleOpenClose,
                                 csvType,
                             }) => {
    const classes = wrapperStyle();

    return (
        <div>
            {buttonsTab && (
                <div className={classes.buttonsTabStyle}>

                        <div className={classes.addButton}>
                            {csvType &&
                                <DownloadCsv type={csvType} />
                            }
                            {buttonsTabAddButton && (
                            <SimpleModal
                                addButton={addButtonText}
                                content={body}
                                handleOpenClose={handleOpenClose}
                                openDefault={openCloseInfo}
                            />
                            )}
                        </div>

                </div>
            )}
            {loader ? (
                <div style={{ height: '85vh' }}>
                    <Loader />
                </div>
            ) : (
                children
            )}
        </div>
    );
};
