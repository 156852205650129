
/**Create Admins */
export const CREATE_PERMISSION = 'CREATE_PERMISSION';
export const CREATE_PERMISSION_SUCCESS = 'CREATE_PERMISSION_SUCCESS';

/**Get Admins */
export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';

/**Get Admin by id */
export const DELETE_PERMISSION = 'DELETE_PERMISSION';
export const DELETE_PERMISSION_SUCCESS = 'DELETE_PERMISSION_SUCCESS';

export const GET_MY_PERMISSIONS = 'GET_MY_PERMISSIONS'

export const GET_MY_PERMISSIONS_SUCCESS = 'GET_MY_PERMISSIONS_SUCCESS'

