import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { DrawerContext } from 'utils';
import { getTimeSheetDetails } from '../constants';

export const TimeSheetDetailsTab = ({ afterSuccess }) => {
    const payrollById = useSelector((state) => state.staffPayroll.payrollById);
    const filteredDetails = getTimeSheetDetails(payrollById, afterSuccess );
    const { open: drawerOpen } = useContext(DrawerContext);

    function getDetailDisplay(detail) {
        if (detail && detail !== 'Undefined Undefined') {
            return detail;
        } else {
            return 'N/A';
        }
    }

    return (
        <div>
                <div className="details-wrapper">
                    {!!filteredDetails.length && (
                        <ol className="details-list-box">
                            {filteredDetails.map((item, index) => item?.detail && (
                                <li key={index}
                                    style={{ background: item?.background ? item?.background : '#F4F5F7' }}
                                >
                                    <p className="detail-title-style"> {item.detailText}</p>
                                    <p className="detail-value-style">{getDetailDisplay(item.detail)}</p>
                                </li>
                            ))}
                        </ol>
                    )}
                </div>
            {payrollById?.description &&
                <div className="timesheet-description">
                    <p>Description:</p>
                    <span>{payrollById?.description}</span>
                </div>
            }

            {/*<div style={{marginTop:24}}>*/}
            {/*    <p className={classes.claimDetailsTitleBoxStyle}>Billing Entries</p>*/}
            {/*    <FullTable*/}
            {/*        head={claimDetailHead}*/}
            {/*        body={claimDetailBody}*/}
            {/*        list={claimDetails?.bills?.length ? claimDetails?.bills : []}*/}
            {/*        handleClick={(e) => open(*/}
            {/*            <ReceivableBillTable currentId={e} />,*/}
            {/*        )}*/}
            {/*        noText={'receivables'}*/}
            {/*        activeRowId={''}*/}
            {/*        generateTable={true}*/}
            {/*        height={'auto'}*/}
            {/*    />*/}
            {/*</div>*/}
        </div>
    );
};