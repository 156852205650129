import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { myProfileFragment } from './style';
import { Images, FindLoad, FindSuccessItem } from 'utils';
import { CustomizedSwitch, MinLoader } from 'components';
import { authActions, httpRequestsOnSuccessActions } from 'store';

const ACTION_TYPE = 'CHANGE_NOTIFICATIONS';

const notificationsEnum = {
    EMAIL: 'EMAIL',
    TEXT: 'TEXT',
    PUSH: 'PUSH',
};

export const Notification = ({ info }) => {
    const classes = myProfileFragment();
    const dispatch = useDispatch();
    const [showInputs, setShowInputs] = useState(false);
    const successType = FindSuccessItem(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const [notificationSettings, setNotificationSettings] = useState({
        push: false,
        email: false,
        text: false,
    });

    useEffect(() => {
        if (successType?.type === ACTION_TYPE) {
            setShowInputs(false);
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [successType]);

    useEffect(() => {
        if (info?.notificationSettings) {
            setNotificationSettings(info.notificationSettings);
        }
    }, [info]);

    const handleChancel = () => {
        setShowInputs(false);
    };

    const changeSettings = (name) => {
        const params = {
            ...notificationSettings,
        };
        params[name] = !notificationSettings[name];
        setNotificationSettings(params);
    };

    const handleSave = () => {
        dispatch(authActions.changeNotifications(info?.id, {
            type: notificationsEnum?.EMAIL,
            active: notificationSettings?.email,
        }));
        dispatch(authActions.changeNotifications(info?.id, {
            type: notificationsEnum?.PUSH,
            active: notificationSettings?.push,
        }));
    };

    return (
        <div className={classes.infoBoxWrapper}>
            <div className={classes.changePasswordContent}>
                <p style={{ display: 'flex', alignItems: 'center' }} className={classes.infoBoxTitle}>Notification
                    Settings <div className={classes.newChip}>NEW</div></p>
                {!showInputs &&
                    <div onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowInputs(true);
                    }}>
                        <img className={classes.editBlack} src={Images.edit} alt="edit" />
                    </div>
                }
            </div>

            {showInputs && (
                <div>

                    <div className={classes.notificationInfoBox} style={{ marginTop: 12 }}>
                        <div>
                            <p className={classes.pushTitle}>Receive Push Notifications</p>
                            <p className={classes.pushSubTitle}>Get instant updates on your device</p>
                        </div>
                        <CustomizedSwitch
                            checked={notificationSettings?.push}
                            handleClick={() => changeSettings('push', notificationsEnum?.PUSH)}
                        />
                    </div>
                    <div className={classes.notificationInfoBox}>
                        <div>
                            <p className={classes.pushTitle}>Receive Email Notifications</p>
                            <p className={classes.pushSubTitle}>Stay informed via email</p>
                        </div>
                        <CustomizedSwitch
                            checked={notificationSettings?.email}
                            handleClick={() => changeSettings('email', notificationsEnum?.EMAIL)}
                        />
                    </div>


                    <div className={classes.actionsBox} style={{ marginTop: '12px' }}>
                        <button
                            type={'button'}
                            className={classes.cancelButton}
                            onClick={handleChancel}
                        >
                            Cancel
                        </button>
                        <button
                            type={'button'}
                            className={classes.saveButton} onClick={handleSave}>
                            {!!loader.length ? (
                                <MinLoader margin={'0'} color={'white'} position={'relative'} />
                            ) : (
                                'Save'
                            )}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};
