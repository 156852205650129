import React from 'react';
import { NumericFormat } from 'react-number-format';
import { ErrMessage } from '../messages';
import { ErrorText } from 'utils';


export const CustomNumericFormat = ({
                                        handleChangeMiles,
                                        name,
                                        placeholder,
                                        label,
                                        value,
                                        error,
                                        errorName,
                                        thousandSeparator,
                                        fixedDecimalScale,
                                        decimalScale,
                                        prefix,
                                        styles
                                    }) => {
    return (
        <div className='custom-numeric-format-box'>
            <p className={value ? 'numeric-autocomplete-label-active' : 'numeric-autocomplete-label'}>{label}</p>
            <NumericFormat
                style={{...styles}}
                className={errorName === name ? 'numeric-format-input-error' : 'numeric-format-input'}
                value={value}
                onValueChange={handleChangeMiles}
                name={name}
                thousandSeparator={thousandSeparator}
                fixedDecimalScale={fixedDecimalScale}
                decimalSeparator="."
                allowNegative={false}
                isNumericString
                prefix={ prefix ? prefix : ''}
                placeholder={placeholder}
                decimalScale={decimalScale}
            />
            <ErrMessage
                text={errorName === name ? error : ''}
            />
        </div>
    );
};