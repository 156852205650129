export const ActiveInactiveStatusReverse = (status) => {
    return status === 'ACTIVE' ? 'Active' :
        status === 'INACTIVE' ? 'Inactive' :
            status === 'ON_HOLD' ? 'On Hold' :
                status === 'TERMINATED' ? 'Terminated' :
                    status === 'FBA' ? 'FBA' :
                        status === 'WAIT_LIST' ? 'Waitlist' :
                            status === 'GRADUATED' ? 'Graduated' :
                                '';
};
