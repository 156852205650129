import { SimpleTooltip } from "components";

const isNotString = (value) => typeof value !== "string";

export const handleTooltip = (tooltipTitle, text, tooltipPlace = "top-start") => {
   const forbid =
      isNotString(tooltipTitle) || isNotString(text) || isNotString(tooltipPlace);

   if (forbid) return "--";

   const currentText = text.endsWith("...") ? text.substring(0, text.length - 3) : text;

   const withTooltip = tooltipTitle.length !== currentText.length;

   const content = <span style={{ width: "100%" }}>{text}</span>;

   if (withTooltip) {
      return (
         <SimpleTooltip title={tooltipTitle} placement={tooltipPlace}>
            {content}
         </SimpleTooltip>
      );
   }

   return content;
};
