import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SwitchCustomized } from 'components';
import { SaveParams, useWidth } from 'utils';
import { Svg } from 'assets';
import { FormControlLabel } from '@material-ui/core';

export const MenuControlPanel = ({ menu, control }) => {
    const [showCurrentContent, setShowCurrentContent] = useState({
        menu: '',
        subMenu: '',
        subList: '',
    });
    // const [headerHeight, setHeaderHeight] = useState(0);
    const width = useWidth();
    const history = useHistory();
    const info = history?.location?.state;


    useEffect(() => {
        if (!info?.menu) {
            setShowCurrentContent(prev => ({
                ...prev,
                menu: 'USER_ACCOUNT',
                subMenu: 'CHANGING_PROFILE',
            }));

            SaveParams(history, { ...info, menu: 'USER_ACCOUNT', subMenu: 'CHANGING_PROFILE' });
        } else {
            setShowCurrentContent(prev => ({
                ...prev,
                menu: info?.menu,
                subMenu: info?.subMenu,
            }));
        }

    }, []);

    // useEffect(() => {
    //     setHeaderHeight(document.querySelector('.topbar-container')?.offsetHeight);
    // }, []);

    const handleClickMenuItem = (e, item) => {
        e.stopPropagation();
        if (item?.key !== showCurrentContent?.menu) {
            setShowCurrentContent(prev => ({
                ...prev,
                menu: item?.key,
            }));
        } else {
            setShowCurrentContent(prev => ({
                ...prev,
                menu: '',
            }));
        }

        if (!item?.subMenu) {
            SaveParams(history, { ...info, menu: item?.key });
        }

    };

    const handleClickSubMenuItem = (e, item) => {
        e.stopPropagation();

        if (item?.key !== showCurrentContent?.subMenu) {
            setShowCurrentContent(prev => ({
                ...prev,
                subMenu: item?.key,
            }));
        } else {
            setShowCurrentContent(prev => ({
                ...prev,
                subMenu: '',
            }));
        }


        if (!item?.subList) {
            SaveParams(history,
                {
                    ...info,
                    menu: showCurrentContent?.menu,
                    subMenu: item?.key,
                    subList: '',
                });

        }
    };

    const handleClickSubMenuList = (e, item) => {
        e.stopPropagation();

        setShowCurrentContent(prev => ({
            ...prev,
            subList: item?.key,
        }));

        SaveParams(history, {
            ...info,
            menu: showCurrentContent?.menu,
            subMenu: showCurrentContent?.subMenu,
            subList: item?.key,
        });
    };

    return (
        <div
            className='menu-control-panel-wrapper'
        >
            <div className='menu-control-panel-box'>

                {width > 767 &&
                    <div className='menu-help-center-header'
                         onClick={() => {
                             setShowCurrentContent(prev => ({
                                 ...prev,
                                 menu: 'USER_ACCOUNT',
                                 subMenu: 'CHANGING_PROFILE',
                             }));
                             SaveParams(history, { ...info, menu: 'USER_ACCOUNT', subMenu: 'CHANGING_PROFILE' });
                         }}
                    >
                        <div className='icon-help-text'>
                            <Svg.BookHelpSvg className='menu-help-center-header-icon' />
                            <p className='text-help'>
                                Help
                            </p>
                        </div>
                        <p className='text-description'>
                            Articles for Control Panel
                        </p>
                    </div>
                }

                <div>
                    <div className='form-control-long-short'>
                        <SwitchCustomized name='isLong' control={control} />
                        <p className='long-short-label'>Switch to Long Version <span>(more detail)</span></p>
                    </div>

                    <div className='menu-items-wrapper'>
                        {menu?.map((item, index) => {
                            if (item?.title) {
                                return (
                                    <div
                                        className='menu-items-box'
                                        key={index}
                                    >
                                        <div
                                            className={`menu-items ${(info?.menu === item?.key) ? 'active-menu-item' : ''} ${showCurrentContent?.menu === item?.key ? 'active_open_menu' : ''} `}
                                            onClick={(e) => {
                                                handleClickMenuItem(e, item);
                                            }}
                                        >

                                            <p className={`menu-item_text`}>
                                                {item?.title}
                                            </p>

                                            <Svg.ArrowRight2Svg
                                                className={`menu-item_svg ${!item?.subMenu?.length || !info?.menu ? 'menu-item_svg_noRotate' : ''}`}
                                            />
                                        </div>

                                        {(!!item?.subMenu?.length && showCurrentContent?.menu === item?.key) &&
                                            <div className='menu-sub-items-wrapper'>
                                                {item?.subMenu?.map((item, index) => {
                                                    if (item?.title) {
                                                        return (
                                                            <div key={index}>
                                                                <div
                                                                    className={`menu-sub-items-block 
                                                            ${showCurrentContent?.subMenu === item?.key ? 'menu-sub-items-block-open' : ''}
                                                            ${info?.subMenu === item?.key ? 'menu-sub-items-block-active' : ''}`}
                                                                    onClick={(e) => handleClickSubMenuItem(e, item)}
                                                                >
                                                                    <p
                                                                        className={`menu-sub-items-text ${info?.subMenu === item?.key ? 'active-sub-menu-item' : ''}`}
                                                                        key={index}
                                                                    >
                                                                        {item?.title}
                                                                    </p>

                                                                    {!!item?.subList && <Svg.ArrowRight2Svg
                                                                        className={`menu-item_svg ${!item?.subMenu?.length || !info?.menu ? 'menu-item_svg_noRotate' : ''}`}
                                                                    />}
                                                                </div>

                                                                {(!!item?.subList?.length && showCurrentContent?.subMenu === item?.key) &&
                                                                    <div className='menu_sublist_block'>
                                                                        {item?.subList.map((item, index) => {
                                                                            if (item?.title) {
                                                                                return (
                                                                                    <p
                                                                                        key={index}
                                                                                        className={`menu_sublist ${info?.subList === item?.key ? 'active_menu_sublist' : ''}`}
                                                                                        onClick={(e) => handleClickSubMenuList(e, item)}
                                                                                    >
                                                                                        {item?.title}
                                                                                    </p>
                                                                                );
                                                                            } else {
                                                                                return null;
                                                                            }
                                                                        })
                                                                        }
                                                                    </div>
                                                                }

                                                            </div>
                                                        );
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                            </div>
                                        }
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

