import {GET_CLAIM_PAYMENTS_SUCCESS} from "./claimPayment.type";

const initialState = {
    claimPayments: [],
};

export const claimPaymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CLAIM_PAYMENTS_SUCCESS:

            return {
                ...state,
                claimPayments: action.payload.claimPayments,
            };

        default:
            return state;
    }
};
