import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CreateChancel, ErrMessage, ValidationInput } from 'components';
import { FindLoad, FindSuccessItem, Images, useModal } from 'utils';
import { appointmentActions, httpRequestsOnSuccessActions } from 'store';

const ACTION_TYPE = 'EDIT_APPOINTMENT';
export const LinksModal = ({ link, modalDate, apptInfo }) => {
    const loader = FindLoad(ACTION_TYPE);
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const { close } = useModal();
    const success = FindSuccessItem(ACTION_TYPE);
    const [list, setList] = useState([
        {
            'title': '',
            'url': '',
        },
    ]);

    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    useEffect(() => {
        if (link) {
            setList([link]);
        }
    }, [link]);

    const removeError = () => {
        setError('');
    };

    const handleAdd = () => {
        if (!error && error !== 0) {
            const newList = [
                ...list,
            ];
            if (newList?.length === 0) {
                removeError();
                newList.push({ title: '', url: '' });
            } else {
                const last = newList.at(-1);
                if (last?.title && last?.url) {
                    removeError();
                    newList.push({ title: '', url: '' });
                } else {
                    setError(newList?.length - 1);
                }
            }
            setList(newList);
        }
    };

    const handleDelete = (index) => {
        const newList = [
            ...list,
        ];
        newList.splice(index, 1);
        setList(newList);
        removeError();
    };

    const handleChange = (e, i) => {
        const newList = [
            ...list,
        ];
        newList[i][e.target.name] = e.target.value;
        if (newList[i].title && newList[i].url) {
            removeError();
        }
        setList(newList);
    };


    const handleSave = () => {

        const params = {
            ...apptInfo,
        };

        const notFull = list?.find((i) => !i?.title || !i?.url);

        if (list?.length && !notFull) {
            if (link) {
                const current = list?.find((i) => i?._id === link?._id);

                params.links = modalDate?.links?.map((i) => {
                    if (i?._id === link?._id) {
                        return {
                            title: current?.title,
                            url: current?.url,
                        };
                    } else {
                        return i;
                    }
                });

            } else {
                params.links = [...list, ...modalDate?.links];
            }
            dispatch(appointmentActions.editAppointment(params, modalDate?.id));
        } else {
            setError('notField');
        }
    };

    return (
        <div style={{ width: 400 }}>
            <p className="modal-header-title">{link ? 'Edit Link' : 'Add Link'}</p>
            <div className="modal-body-wrapper">
                {list?.map((i, j) => (
                    <div key={j}>
                        <div style={{ gap: '16px' }}>
                            <ValidationInput
                                onChange={(e) => handleChange(e, j)}
                                value={i?.title}
                                variant={'outlined'}
                                name={'title'}
                                type={'text'}
                                label={'Enter Link Title*'}
                                typeError={error === j}
                                Length={30}
                            />
                            <ValidationInput
                                onChange={(e) => handleChange(e, j)}
                                value={i?.url}
                                variant={'outlined'}
                                name={'url'}
                                type={'text'}
                                label={'Enter Link URL*'}
                                typeError={error === j}
                                errorFalse={true}
                            />
                        </div>

                        {!link &&
                            <div className="cancel-link-btn">
                                <button onClick={() => handleDelete(j)}>
                                    Cancel
                                </button>
                            </div>
                        }
                    </div>
                ))}
                {!link &&
                    <button className="add-link-btn" onClick={handleAdd}>
                        <img src={Images.addBlackIcon} alt="icon" />
                        <p>Add More</p>
                    </button>
                }

                <ErrMessage text={error === 'notField' ? 'Title or url is empty' : ''} />

            </div>


            <CreateChancel
                loader={!!loader.length}
                create={link ? 'Save' : 'Add'}
                chancel={'Cancel'}
                buttonWidth={'100%'}
                onCreate={handleSave}
                onClose={close}
                disabled={loader.length}
            />

        </div>
    );
};