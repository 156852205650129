import React from 'react';
import { Loader } from 'components';
import { FindLoad } from 'utils';
import { LandingSvg } from 'assets';

export const ButtonLanding = (
    {
        className,
        text,
        disabled,
        actionTye,
        contained = true,
        moovable = false,
        arrowIcon = false,
        big = true,
        type = 'button',
        onClick,
        textColor,
        loadStyles,
        startAdornment,
        endAdornment,
        load,
        circleSize,
        loadingColor
    }) => {
    const loading = FindLoad(actionTye);

    return (
        <button
            type={type}
            disabled={disabled || loading || load}
            onClick={onClick}
            className={`button-landing ${big ? 'big' : 'small'} ${contained ? 'contained' : 'outlined'}  ${moovable ? 'moovable' : ''} ${className ? className : ''}`}
        >
            {loading || load ? (
                <Loader
                    circleSize={circleSize}
                    loadingColor={loadingColor}
                    styles={{ color: contained ? '#FFF' : '#AD3501', ...loadStyles }}
                />
            ) : (
                <div className='flex-all-center'>

                    {startAdornment && (
                        <div className='button-start-adornment'>{startAdornment}</div>
                    )}

                    <p
                        className={`button-text ${startAdornment ? 'with-start-adornment' : ''}`}
                        style={{ color: textColor ? textColor : '' }}
                    >
                        {text}
                    </p>

                    {arrowIcon && <LandingSvg.ArrowRightSvg className='arrow-right' />}

                    {endAdornment && (
                        <div className='button-end-adornment'>{endAdornment}</div>
                    )}
                </div>
            )}
        </button>
    );
};
