import {
    DELETE_NOTIFICATION,
    GET_NOTIFICATIONS,
    GET_UNRENDER_NOTIFICATIONS,
    MARK_ALL_AS_READ,
    MARK_SINGLE_AS_READ, REMOVE_NOTIFICATIONS,
} from './notification.types';


/** Notifications service */
export const getNotifications = (params, load) => {
    return {
        type: GET_NOTIFICATIONS,
        payload: { params, load },
    };
};

export const removeNotifications = ( ) => {
    return {
        type: REMOVE_NOTIFICATIONS,
    };
};
export const getUnRenderNotifications = ( ) => {
    return {
        type: GET_UNRENDER_NOTIFICATIONS,
    };
};

export const markAllAsRead = () => {
    return {
        type: MARK_ALL_AS_READ,
    };
};

export const markSingleAsRead = (id) => {
    return {
        type: MARK_SINGLE_AS_READ,
        payload: { id },
    };
};

export const deleteNotification = (id) => {
    return {
        type: DELETE_NOTIFICATION,
        payload: { id },
    };
};

/** End */

