import { makeStyles } from '@material-ui/core';
import { Colors, Shadow } from 'utils';

export const clientCommonStyle = makeStyles(() => ({
    enrollmentTableStyle: {
        width: '100%',
        marginTop: '32px',
        // marginLeft: "24px",
        paddingBottom: '24px',
    },
    authServiceTableStyle: {
        width: '100%',
        marginTop: '24px',
    },
    clientContactTableStyle: { marginTop: '32px' },
    activeTitle: {
        fontWeight: '700',
        fontSize: '18px',
        lineHeight: '24px',
        color: '#4B5C68',
    },
    enrolmentTab: {
        display: 'flex',
        margin: '16px 0 32px',
    },
    addRowFirst: {
        width: '100%',
        marginRight: '16px',
        '@media (min-width: 1920px)': {
            marginRight: '32px',
        },
    },
    addRow: {
        display: 'flex',
        alignItems: 'center',
        background: '#FFFFFF',
        boxShadow: '0px 0px 6px rgba(52, 122, 240, 0.2)',
        borderRadius: '8px',
        height: 'auto',
        minHeight: '145px',
        width: '100%',
        cursor: 'pointer',
        padding: '20px 16px',
    },
    addTitle: {
        marginLeft: '13px',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#347AF0',
    },
    pastEnrolmentTable: {
        marginTop: '16px',
    },
    pastEnrolmentTableHead: {
        background: '#EBF2FD',
        height: '36px',
        width: '100%',
        borderRadius: '8px',
        display: 'flex',
        padding: '8px 16px',
        '& div': {
            width: '25%',
            fontWeight: '700',
            fontSize: '14px',
            lineHeight: '19px',
            color: '#4B5C68',
        },
    },
    pastEnrolmentTableBody: {
        marginTop: '4px',
        background: '#F2F4F8',
        height: '36px',
        width: '100%',
        borderRadius: '8px',
        display: 'flex',
        padding: '8px 16px',
        '& div': {
            // width: '25%',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '19px',
            color: '#4B5C68',
        },
    },
    rowCenterWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    cardType: {
        borderRadius: '4px',
        background: '#EBF2FD',
        padding: '4px 8px',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '18px',
        color: '#347AF0',

    },
    enrolmentCardTitle: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '12px',

        '& p': {
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '18px',
            color: '#347AF0',

        },

        '& span': {
            color: '#A3B2BD',
            fontSize: '12px',
            lineHeight: '18px',
            marginLeft: '8px',
        },
    },
    enrolmentFunding: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '8px',
        '& p': {
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '28px',
            color: '#4B5C68',
        },
        '& span': {
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '18px',
            color: '#A3B2BD',
            marginLeft: '12px',
        },
    },
    enrolmentDate: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '8px',

        '& p': {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '18px',
            color: 'rgba(75, 92, 104, 0.80)',
        },
        '& span': {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '18px',
            color: 'rgba(75, 92, 104, 0.80)',
            marginLeft: '8px',
        },
    },
    moreButton: {
        background: 'transparent',
        border: 'none',
    },
    popoverWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '150px',
        height: '110px',
        background: '#FFFFFF',
        boxShadow: '0px 0px 6px rgba(138, 138, 138, 0.24)',
        borderRadius: '4px',

        '& button': {
            textAlign: 'start',
            padding: '0 16px',
            height: '36px',
            background: 'transparent',
            border: 'none',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '19px',
            color: '#4B5C68',
            '&:hover': {
                background: '#EBF2FD',
            },
        },
    },
    pastEnrolmentTableBodyWrapper: {
        height: '200px',
        overflow: 'auto',
    },
    setToPrimary: {
        width: '480px',
        background: '#FFFFFF',
        borderRadius: '8px',
        padding: '24px',
        '@media (min-width: 1919px)': {
            padding: '32px',
        },
    },
    primaryTitle: {
        fontWeight: '700',
        fontSize: '32px',
        lineHeight: '43px',
        color: '#4B5C68',
    },
    primarySubtitle: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#4B5C68',
        margin: '16px 0 40px',
    },

}));
