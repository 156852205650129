import React from 'react';
import { useSelector } from 'react-redux';
import { FilesComponent, Notes, SimpleTabs } from 'components';
import { PermissionList, RolePermission } from 'utils';
import { claimDetailTabsLabels } from './constants';
import { ClaimDetailHeader } from './claimDetailHeader';
import { ClaimDetailsTab } from './claimDetailsTab';
import { ClaimAppointments } from './claimAppointments';
import { HistoryTab } from '../../../history';

export const ClaimDetailInfo = ( ) => {
    const claimById = useSelector((state) => state.claim.claimById);
    const claimDocuments = useSelector((state) => state.claim.claimDocuments);


    const claimTabContent = [
        {
            tabComponent: <ClaimDetailsTab/>,
            tab: 'Details',
        },
        {
            tabComponent: <ClaimAppointments/>,
            tab: 'Appointments',
        },
        RolePermission([PermissionList.CLAIM_NOTE_READ?.code, PermissionList.CLAIM_NOTE_MANAGE?.code]) &&
        {
            tabComponent: (
                <Notes permissions={[PermissionList.CLAIM_NOTE_MANAGE?.code]}  onModel={'Claim'} id={claimById?.id} />
            ),
            tab: 'Notes',
        },
        RolePermission([PermissionList.CLAIM_HISTORY_READ?.code]) &&
        {
            tabComponent: <HistoryTab currentId={claimById?.id} onModel={'Claim'} />,
            tab: 'History',
        },
        RolePermission([PermissionList.CLAIM_FILE_READ?.code, PermissionList.CLAIM_FILE_MANAGE?.code]) &&
        {
            tabComponent: (
                <FilesComponent
                    currentId={claimById?.id}
                    onResource={'claim'}
                    documents={claimDocuments}
                    loadTable={'GET_CLAIM_DOCUMENT'}
                    permissions={[PermissionList.CLAIM_FILE_MANAGE?.code]}
                />
            ),
            tab: 'Files',
        },
    ];

    return (
        <div className='tab-box-wrapper'>
            <ClaimDetailHeader />
            <SimpleTabs
                defaultTab={'Details'}
                tabsLabels={claimDetailTabsLabels}
                tabsContent={claimTabContent}
            />
        </div>
    );
};