import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { CreateChancel, DateCalendarForm, Input } from 'components';
import { FindSuccess, FindLoad,  useModal } from 'utils';
import { clientActions, httpRequestsOnSuccessActions } from 'store';

export const EditAssignedStaff = ({ item }) => {
    const client = useSelector((state) => state.client.clientItemInfo);
    const ACTION_TYPE = 'EDIT_ASSIGNED_STAFF';
    const dispatch = useDispatch();
    const loader = FindLoad(ACTION_TYPE);
    const success = FindSuccess(ACTION_TYPE);
    const { close } = useModal();
    const { handleSubmit, control, clearErrors, setError, reset } = useForm();

    useEffect(() => {
        if (item) {
            const params = {
                ...item
            }
            params.role = item?.staffRole;
            reset(params);
        }
    }, [item]);

    useEffect(() => {
        if (!!success.length) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    function handleCreate(data) {
        dispatch(clientActions.editAssignedStaff(item?.assignedId, data, client?.id));
    }

    return (
        <form onSubmit={handleSubmit(handleCreate)} style={{ width: 400 }}>
            <p className="modal-header-title"> Edit Assigned Staff</p>
            <div className="modal-body-wrapper flex-align-start" style={{ gap: 24 }}>
                <div className="full-width">
                    <Input
                        name="role"
                        label="Role*"
                        variant="outlined"
                        control={control}
                        maxLength={20}
                        rules={{
                            maxLength: {
                                value: 20,
                                message: 'Max length is 20.',
                            },
                            required: 'Role is required.',
                        }}
                    />
                    <DateCalendarForm
                        label={'Assignment Date'}
                        name={'assignmentDate'}
                        control={control}
                        type={'modalInput'}
                    />
                </div>
            </div>
            <CreateChancel
                type="submit"
                loader={!!loader.length}
                create={'Save'}
                chancel={'Cancel'}
                onClose={close}
                buttonWidth="428px"
            />
        </form>
    );
};
