import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDelete, FullTable } from 'components';
import { FindSuccessItem, Images, PermissionList, RolePermission, useModal, useSecondaryModal } from 'utils';
import { appointmentActions, httpRequestsOnSuccessActions } from 'store';
import { ScheduleDetails } from './modals';
import { apptBody, apptHead, apptNotYet } from './constants';

const ACTION_TYPE = 'GET_APPOINTMENT';
const LOCK_UNLOCK = 'CHANGE_APPOINTMENT_LOCK_UNLOCK';
const CONNECT_RECONNECT = 'CONNECT_RECONNECT_TEMPLATE';

export const ScheduleList = ({ renderParams }) => {
    const { appointments } = useSelector((state) => ({
        appointments: state.appointment.appointments,
    }));
    const dispatch = useDispatch();
    const { open } = useModal();
    const { openSecondary } = useSecondaryModal();
    const history = useHistory();
    const info = history?.location?.state;
    const [selectedList, setSelectedList] = useState([]);
    const [selectAllList, setSelectAllList] = useState(false);
    const lockUnlockSuccess = FindSuccessItem(LOCK_UNLOCK);
    const showSelectedMessages = selectAllList ? true : appointments?.appts?.length === selectedList?.length;
    const successConnect = FindSuccessItem(CONNECT_RECONNECT);

    useEffect(() => {
        if (lockUnlockSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(LOCK_UNLOCK));
            setSelectedList([]);
            setSelectAllList(false);
        }
        if (info) {
            setSelectedList([]);
            setSelectAllList(false);
        }
    }, [lockUnlockSuccess, info]);

    const handleCheck = (ev, item, name) => {
        ev.stopPropagation();
        ev.preventDefault();
        setSelectAllList(false);

        if (name === 'all') {
            let allList = appointments?.appts?.length === selectedList?.length ? [] : appointments?.appts?.map((i) => i?.id);
            setSelectedList(allList);
        } else {
            const list = [...selectedList];
            const uniqSet = new Set(list);

            if (!uniqSet.has(item?.id)) {
                list.push(item?.id);
            } else {
                list.indexOf(item?.id) !== -1 && list.splice(list.indexOf(item?.id), 1);
            }
            setSelectedList(list);
        }
    };

    const lockUnlock = (name) => {
        const lockUnlockType = name === 'lock';
        const subText = name === 'lock' ?
            'Are you sure you want to lock the selected appointments? Once locked, they cannot be edited until unlocked.'
            :
            'Are you sure you want to unlock the selected appointments? Once unlocked, they can be edited again.';

        const list = {
            apptIds: selectedList,
            all: selectAllList,
        };

        open(
            <CustomDelete
                info={'Confirm Cancel All Appointments'}
                text={subText}
                innerText={name === 'lock' ? 'Lock Selected' : 'Unlock Selected'}
                handleDel={() => dispatch(appointmentActions.changeApptLockUnlock(list, lockUnlockType, renderParams))}
                handleClose={close}
                actionType={LOCK_UNLOCK}
                color={name === 'lock' ? null : 'blue'}
            />
        );
    };


    useEffect(() => {
        if (successConnect?.type === CONNECT_RECONNECT) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(CONNECT_RECONNECT));
            close();
        }
    }, [successConnect]);

    return (
        <div className="schedule-fragment-page">
            {RolePermission([PermissionList.APPT_LOCK?.code]) && selectedList?.length ?
                <div className="lock-unlock-btns">
                    <div>
                        {showSelectedMessages &&
                            <div className="appt-selected-info">
                                {selectAllList ?
                                    <p>All <b>{appointments?.count} appointments across all pages</b> have been
                                        selected.</p>
                                    :
                                    <p>All <b>{appointments?.appts?.length}</b> appointments <b>on this page</b> are
                                        selected.</p>
                                }
                                {selectAllList ?
                                    <button onClick={() => {
                                        setSelectAllList(false);
                                        setSelectedList([]);
                                    }}>Clear Selection</button>
                                    :
                                    <button onClick={() => setSelectAllList(true)}>Select
                                        all {appointments?.count} appointments across all pages</button>
                                }
                            </div>
                        }

                    </div>
                    <div className="flex-lock">
                        <button className="lock-btn" onClick={() => lockUnlock('lock')}>
                            <img src={Images.lock} alt="" />
                            Lock Selected
                        </button>
                        <button className="unlock-btn" onClick={() => lockUnlock('unlock')}>
                            <img src={Images.unlock} alt="" />
                            Unlock Selected
                        </button>
                    </div>
                </div>
                : ''
            }
            <FullTable
                head={apptHead(handleCheck, appointments?.appts, selectedList)}
                body={apptBody(handleCheck, appointments?.appts, selectedList)}
                loadingType={ACTION_TYPE}
                list={appointments?.appts}
                listCount={appointments?.count}
                handleClick={(id, item) => openSecondary(<ScheduleDetails currentId={item?.id} />)}
                notYet={apptNotYet}
                activeRowId={''}
                margin={'0'}
            />
        </div>
    );
};