export const termsAndConditionsTitle = "Terms & Conditions";

export const termsAndConditionsParagraphs = [
    `Terms of Use
This Terms of Use Agreement ("Agreement") is entered into between THERAPYLAKE LLC ("Company") and the user ("User") of the Company's practice management software ("Software"). By accessing and using the Software, the User agrees to be bound by the terms and conditions outlined in this Agreement.
1. Acceptance of Terms
By accessing and using the Software, the User agrees to comply with and be bound by this Agreement. If the User does not agree to these terms, they should not use the Software.
2. License Grant
The Company grants the User a non-exclusive, non-transferable, revocable license to access and use the Software solely for the internal business purposes of the User's behavioral therapy practice.
3. Prohibited Actions
The User agrees not to:
- Resell, sublicense, or share the Software with competitors.
- Reverse engineer, decompile, or disassemble the Software.
- Use the Software for any illegal or unauthorized purpose.
- Distribute, share, or make the Software available to third parties without the Company's prior written consent.
4. User Responsibilities
The User is responsible for:
- Ensuring the accuracy and completeness of data entered into the Software.
- Maintaining the confidentiality of their login credentials.
- Complying with all applicable laws and regulations in their use of the Software, including HIPAA requirements.
- Regularly backing up their data.
5. Data Security and Privacy
The Company will implement appropriate measures to protect the confidentiality, integrity, and availability of the User's data.
The User acknowledges and agrees that the Company may collect, use, and disclose data in accordance with the Company's Privacy Policy.
6. Intellectual Property
The Software and all associated intellectual property rights are the exclusive property of the Company. The User agrees not to infringe upon or misappropriate any intellectual property rights of the Company.
7. Software Updates
The Company may update and modify the Software at its discretion. The Company will not be liable for any changes that may affect the User's use of the Software.
8. Limitation of Liability
The Company's liability for any claims arising from the use of the Software is limited to the amount of the subscription fee paid by the User in the preceding 12 months.
The Company will not be liable for any indirect, incidental, or consequential damages arising from the use of the Software.
9. Indemnification
The User agrees to indemnify and hold the Company harmless from any claims, damages, or expenses arising from the User's use of the Software.
10. Termination
The Company may terminate this Agreement at any time if the User breaches any material term of this Agreement.
Upon termination, the User must cease all use of the Software and destroy any copies of the Software in their possession.
11. Governing Law
This Agreement will be governed by and construed in accordance with the laws of the State of California, without regard to its conflict of law principles.
12. Dispute Resolution
Any disputes arising from this Agreement will be resolved through binding arbitration in Los Angeles, CA, in accordance with the rules of the American Arbitration Association.
13. Amendments
The Company reserves the right to amend this Agreement at any time. The Company will notify the User of any significant changes by posting the amended Agreement on its website or through the Software.
14. Entire Agreement
This Agreement constitutes the entire agreement between the parties and supersedes all prior or contemporaneous understandings, agreements, negotiations, representations, and warranties, both written and oral, with respect to the subject matter hereof.

THERAPYLAKE LLC SMS Terms & Conditions
1. General
By subscribing to SMS services offered by THERAPYLAKE LLC ("Company"), you agree to receive recurring automated marketing and informational text (e.g., SMS and MMS) messages from or on behalf of THERAPYLAKE LLC, including text messages that may be sent using an automatic telephone dialing system, to the mobile telephone number you provided at the time of subscription. Consent to receive marketing text messages is not required as a condition of purchasing any goods or services.
2. Message Frequency
The number of THERAPYLAKE LLC text messages you receive will vary based on your subscription and interactions with our services.
3. Message and Data Rates
Message and data rates may apply. Contact your wireless provider for more information regarding your plan and any associated costs.
4. Opt-Out
You can opt-out of these messages at any time by texting "STOP" to 424-277-0772. You will receive one additional message confirming your choice. If you have subscribed to other THERAPYLAKE LLC mobile message programs and wish to cancel, you must opt-out of those programs separately.
5. Help
For help or information regarding SMS services, text "HELP" to 424-277-0772 or contact us at [Customer Support Contact Information]. We are available to assist you with any questions you may have.
6. Supported Carriers
Text messages from THERAPYLAKE LLC are supported by most major carriers, including AT&T, Verizon, T-Mobile, Sprint, and others. Service may not be available with all carriers. The wireless carriers are not liable for delayed or undelivered messages.
7. Privacy
THERAPYLAKE LLC respects your privacy. We will use the information you provide to transmit your text messages as described in these Terms & Conditions and our Privacy Policy.
8. Changes to Terms
THERAPYLAKE LLC may revise, modify, or amend these SMS Terms & Conditions at any time. Any such changes shall take effect when posted to our website.
`
];