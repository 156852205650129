import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { CreateChancel, InputMain } from 'components';
import { FindLoad, FindSuccessItem, Images, useModal } from 'utils';
import { httpRequestsOnSuccessActions, systemActions } from 'store';

const ACTION_TYPE = 'CONNECT_OFFICE_ALLY';
export const ConnectOfficeAlly = () => {
    const { close } = useModal();
    const { handleSubmit, control } = useForm();
    const dispatch = useDispatch();
    const loader = FindLoad(ACTION_TYPE);
    const success = FindSuccessItem(ACTION_TYPE);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (success) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);
    const handleCreate = (data) => {
        dispatch(systemActions.connectOfficeAlly({
            sftpCredentials: {
                username: data.username,
                password: data.password
            }
        }))
    };

    return (
        <div style={{ width: 400 }}>
            <div className="office-ally-header">
                <div className="office-ally-header-icons">
                    <div>
                        <img src={Images.logoSmall} alt="" />
                    </div>
                    <img src={Images.compareArrows} alt="arrow" />
                    <div>
                        <img src={Images.officeAlly} alt="" />
                    </div>
                </div>
                <p>Connect to Office Ally</p>
            </div>
            <form onSubmit={handleSubmit(handleCreate)}>
                <InputMain
                    label={'SFTP Username*'}
                    customClass={true}
                    variant="outlined"
                    name="username"
                    type="text"
                    control={control}
                    placeholder="Password"
                    rules={{
                        required: 'Username is required.',
                    }}
                />
                <InputMain
                    label={'Password*'}
                    customClass={true}
                    variant="outlined"
                    name="password"
                    type="password"
                    showPassword={showPassword}
                    control={control}
                    placeholder="Password"
                    rules={{
                        required: 'Password is required.',
                    }}
                    onClickIconEnd={() => setShowPassword(prev => !prev)}
                />
                <div style={{ marginTop: 12 }}>
                    <CreateChancel
                        type="submit"
                        loader={!!loader.length}
                        create={'Connect'}
                        chancel={'Cancel'}
                        onClose={close}
                        buttonWidth={'100%'}
                    />
                </div>
            </form>
        </div>
    );
};