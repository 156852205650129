import React from 'react';
import { Images } from 'utils';
import { logoStyle } from './styles';

export const GlobalLogo = ({ open }) => {
    const classes = logoStyle();

    return (
        <div
            style={ !open ? { alignItems:'center', justifyContent:'center', width:'auto'} : { width: '100px'}}
            className={classes.logoWrapper}
        >
            <img
                src={open ? Images.leftSideIcon : Images.logoSmall}
                alt="logo"
            />
        </div>
    );
};
