import React from 'react';
import {
    DateRow,
    IconAndTitle,
    TextRow,
    TypeRow,
    StatusRow,
    IconAndTitleSub,
    FullNameRow,
    TagComponent, StatusCard, FilterTags,
} from 'components';
import { Images, tagEnums } from 'utils';


export const clientHead = [
    { name: 'code', title: 'Code', searchKey: 'CODE', width: '120px' },
    { name: '', title: 'Full Name', custom: false, },
    {
        name: 'gender', title: 'Gender', disabled: true, custom: false,
        // filterList: [
        //     { name: 'All', id: 'All' },
        //     { name: 'Male', id: 'MALE' },
        //     { name: 'Female', id: 'FEMALE' },
        //     { name: 'Other', id: 'OTHER' },
        // ],
        width: '100px',
    },
    { name: '', title: 'DOB', icon: 'date', disabled: true, custom: false },
    { name: '', title: 'Enroll. Date', disabled: true, custom: false },
    { name: '', title: 'Payer', disabled: true, custom: false },

    { name: 'contractDateStart', title: 'Auth. Start', disabled: true, iconType: 'date' },
    { name: 'contractDateEnd', title: 'Auth. End', disabled: true, iconType: 'date' },
    { name: '', title: 'Tags', custom: false, renderView: <FilterTags/>   },

    {
        name: 'status', title: 'Status', disabled: true, width: '140px',
        filterList: [
            { name: 'All', id: 'All' },
            { name: 'Active', id: 'ACTIVE' },
            { name: 'FBA', id: 'FBA' },
            { name: 'On Hold', id: 'ON_HOLD' },
            { name: 'Waitlist', id: 'WAIT_LIST' },
            { name: 'Terminated', id: 'TERMINATED' },
            { name: 'Graduated', id: 'GRADUATED' },
        ],
    },
];

// const renderStatus = (item) => {
//     return getValueDisplay(item?.status, 'Status');
// };

export const renderBorderColor = (item) => {

    const color =
        item?.status === 'ACTIVE' ? '#6FD231' :
            item?.status === 'FBS' ? '#ADD8E6' :
                item?.status === 'FBA' ? '#ADD8E6' :
                    item?.status === 'ON_HOLD' ? '#FFCA33' :
                        item?.status === 'WAIT_LIST' ? '#FE7B43' :
                            item?.status === 'TERMINATED' ? '#FE7070' :
                                item?.status === 'GRADUATED' ? '#51439E' : 'grey';

    return {
        borderLeft: `8px solid ${color}`,
        borderRadius: '8px 0 0 8px',
    };


};

export const clientBody = (requestParams, afterSuccess) => [
    {
        rowText: (item) => <IconAndTitle
            icon={Images.clients}
            title={item?.code ? item?.code : 'N/A'}
            textWidth={7}
        />,
    },
    { rowText: (item) => <FullNameRow firstName={item?.firstName} lastName={item?.lastName} middleName={item?.middleName} /> },
    // { rowText: (item) => <TextRow name={`${item?.firstName} ${item?.lastName}`} /> },
    { rowText: (item) => item?.gender ? <TypeRow text={item?.gender} /> : 'N/A' },
    { rowText: (item) => <DateRow date={item?.birthday} /> },
    { rowText: (item) => <DateRow date={item?.registrationDate ? item.registrationDate : ''} /> },
    { rowText: (item) => <TextRow name={item?.enrollment ? item.enrollment : 'N/A'} textWidth={10} /> },
    { rowText: (item) => <DateRow date={item?.contractDates?.from} /> },
    { rowText: (item) => <DateRow date={item?.contractDates?.to} /> },
    { rowText: (item) => <TagComponent userInfo={item} module={tagEnums.CLIENT} requestParams={requestParams} afterSuccess={afterSuccess} />, notClickable: true },
    // { rowText: (item) => <StatusRow status={item?.status} /> },
    { rowText: (item) => <StatusCard status={item?.status}/> },
];

export const ACTION_TYPE = 'GET_CLIENTS';

export const clientNotYet = {
    title: 'No Clients Added Yet',
    subTitle: 'Start by adding your first client to manage their information and services.',
    image: Images.noClientYet,
};
