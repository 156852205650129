import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import { FormControlLabel } from '@mui/material';
import { Images, SaveParams } from 'utils';
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from 'react-redux';
import FormGroup from '@mui/material/FormGroup';
import { renderColor } from './colors';
import { CloseButton } from '../buttons';

export function FilterTags({}) {
    const location = useLocation();
    const navigate = useHistory();
    const info = location?.state;
    const [anchorEl, setAnchorEl] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [search, setSearch] = useState('');
    const [list, setList] = useState([]);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const { tagList } = useSelector((state) => ({
        tagList: state.tags.tagList,
    }));

    useEffect(() => {
        if (tagList) {
            setList(tagList);
        }
    }, [tagList]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const pushInfo = (list) => {
        const filteredInfo = {
            ...info,
        };
        list?.length ? filteredInfo.tags = list : delete filteredInfo.tags;
        SaveParams(navigate, { ...filteredInfo });
    };

    const handleClickRadio = (e, id) => {
        if (e.target.checked) {
            setSelectedIds([...selectedIds, id]);
            pushInfo([...selectedIds, id]);
        } else {
            const filtered = selectedIds?.filter((i) => i !== id);
            setSelectedIds(filtered);
            pushInfo(filtered);
        }
    };

    const handleFilter = (e) => {
        setSearch(e.target.value);
        const newList = tagList?.filter((el) => el?.name?.toString().toLowerCase().indexOf(e.target.value?.toString().toLowerCase()) > -1);
        setList(newList);
    };

    const removeSearched = () => {
        setSearch('');
        setList(tagList);
    };

    return (
        <div className="table-menu">
            <div className="desktop-page-view">

                <div className="search-and-filer-box">
                    <div className={info?.tags ? 'active-box' : 'passive-box'}>
                        <div onClick={handleClick} style={{ cursor: 'pointer', gap: 8 }} className="flex-align-center">
                            <p>Tags</p>
                            <button
                                aria-describedby={id}
                                className="item-button"
                                aria-haspopup="true"
                            >
                                <img
                                    src={info?.tags ? Images.tableSortBlue : Images.tableSortGray}
                                    alt={'filter icon'}
                                />
                            </button>
                        </div>
                        <div style={{ position: 'relative' }}>
                            <form autoComplete="off">
                                <input
                                    disabled={true}
                                    autoComplete={'new-password'}
                                    type="search"
                                />
                            </form>
                        </div>
                    </div>
                </div>

                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}>
                    <div className="tag-filtering-wrapper">
                        <div className="tag-filtering-search">
                            <input
                                type="text"
                                className="tag-filter-input"
                                placeholder="Tag Name"
                                onChange={handleFilter}
                                value={search}
                            />
                            {search &&
                                <CloseButton handleCLic={removeSearched} />
                            }
                        </div>

                        <div className="tag-filtering-body">
                            <FormGroup>
                                {tagList?.length ? list?.map((i, k) => (
                                        <div key={k} className="check-label">
                                            <FormControlLabel
                                                label={null}
                                                value={i?.id}
                                                control={
                                                    <div className="checkbox-wrapper">
                                                        <Checkbox
                                                            style={{ color: '#0C66E4' }}
                                                            checked={info?.tags?.includes(i?.id)}
                                                            onClick={(e) => handleClickRadio(e, i?.id)}
                                                        />
                                                        <div
                                                            style={{ background: renderColor(i?.color).background }}
                                                            className="filter-tag-style">
                                                            <p style={{ color: renderColor(i?.color).color }}>{i?.name}</p>
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        </div>
                                    ))
                                    :
                                    <p>No Tags Yet</p>
                                }
                            </FormGroup>
                        </div>
                    </div>
                </Popover>
            </div>
        </div>
    );
}
