import React from 'react';
import { motion } from 'framer-motion';
import { animationParams, animationVariants } from 'fragments';


export const BillingPostingCard = ({ img, title, description, classNameWrapper, classNameImg }) => {
    return (
        <motion.div
            className={`billing-posting-card-wrapper ${classNameWrapper ? classNameWrapper : ''}`}
            {...animationParams}
            variants={animationVariants.cardsVariant}
        >
            <div className={`billing-posting-card-img ${classNameImg ? classNameImg : ''}`}>
                <img src={img} alt='Img' />
            </div>

            <div className='billing-posting-card_content'>
                <p className='card_content-title'>
                    {title}
                </p>

                <p className='card_content-desc'>
                    {description}
                </p>
            </div>
        </motion.div>
    );
};

