import moment from "moment";
import { getLimitedVal } from ".";

export const hooksForTable = {
   addSignToValueFromStart: (value = "", sign = "$") => `${sign}${value}`,

   getFullName: (first = "", last = "", callback) => {
      if (typeof first !== "string" || typeof last !== "string") return;

      const fullName =
         !!first && !!last
            ? callback(`${first} ${last}`)
            : !!first
            ? callback(first)
            : !!last
            ? callback(last)
            : "Not Set";

      return fullName;
   },

   getTextDependsOnWidth: (innerWidth, givenSize, text = "", limit = 5) => {
      const forbid =
         typeof innerWidth !== "number" ||
         typeof givenSize !== "number" ||
         typeof text !== "string" ||
         typeof limit !== "number";

      if (forbid) return text;

      return innerWidth < givenSize ? getLimitedVal(text, limit) : text;
   },

   getValueByFixedNumber: (value = 0, number = 2) =>
      typeof value === "number" ? value.toFixed(number) : value,

   resetRadius: (corner = "") => {
      if (typeof corner !== "string") return corner;

      switch (corner) {
         case "left":
            return {
               borderTopLeftRadius: 0,
               borderBottomLeftRadius: 0,
            };
         case "right":
            return {
               borderTopRightRadius: 0,
               borderBottomRightRadius: 0,
            };

         default:
            return { borderRadius: 0 };
      }
   },

   showDashIfEmpty: (value = "") => (value || value === 0 ? value : "Not Set"),

   handleCreatedAtDate: (date) => date ? moment.utc(date).format("MM/DD/YYYY") : null,
};
