window.process = {};
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/styles/allStyles.scss';
import { store } from './store';
import {
    DrawerContextProvider,
    initAxiosInterceptors,
    AllModals,
    ModalProvider,
    SecondaryModalProvider,
    SecondaryModals,
} from 'utils';
import { ScrollToTop } from './utils/hooks';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

initAxiosInterceptors();


ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ScrollToTop />
            <Provider store={store}>
                <DrawerContextProvider>
                    <ModalProvider>
                        <SecondaryModalProvider>
                            <App />
                            <AllModals />
                            <SecondaryModals />
                        </SecondaryModalProvider>
                    </ModalProvider>
                </DrawerContextProvider>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root'),
);

// reportWebVitals();
serviceWorkerRegistration.register();
// serviceWorkerRegistration.register();
