import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    httpRequestsOnErrorsActions,
    httpRequestsOnSuccessActions,
    invoicePaymentActions,
} from 'store';
import { GET_ACTION_TYPE, invoicePaymentBody, invoicePaymentHead, invoicesNotYet } from './constants';
import { InvoiceFilters } from './invoiceFilters';
import { FindSuccessItem, useModal } from 'utils';
import { Aging, FullTable } from 'components';

const GENERATE_ACTION_TYPE = 'GENERATE_INVOICE';
const PAY_IN_FULL_PAYMENT_INVOICE = 'PAY_IN_FULL_PAYMENT_INVOICE';
const EDIT_TYPE = 'EDIT_PAYMENT_INVOICE';

export const Invoices = () => {
    const invoicePaymentsData = useSelector((state) => state.invoicePayment.invoicePayments);
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const generateSuccess = FindSuccessItem(GENERATE_ACTION_TYPE);
    const payInFullSuccess = FindSuccessItem(PAY_IN_FULL_PAYMENT_INVOICE);
    const editSuccess = FindSuccessItem(EDIT_TYPE);
    const { close } = useModal();
    const [activeRow, setActiveRow] = useState({});
    const [selectedId, setSelectedId] = useState();

    const renderParams = () => {
        const pushInfo = {
            ...info,
        };
        delete pushInfo.page;
        pushInfo.skip = info?.skip ? info?.skip : 0;
        pushInfo.limit = 50;


        if (!info?.client) {
            delete pushInfo.client;
        }
        if (!info?.status) {
            delete pushInfo.status;
        }

        // if (info?.firstDate) {
        //     pushInfo.from = info?.firstDate;
        //     delete pushInfo.firstDate;
        // } else {
        //     delete pushInfo.from;
        // }
        //
        // if (info?.lastDate) {
        //     pushInfo.to = info?.lastDate;
        //     delete pushInfo.lastDate;
        // } else {
        //     delete pushInfo.to;
        // }

        return pushInfo;
    };

    const getInvoices = (load) => {
        dispatch(invoicePaymentActions.getInvoicePayments({ ...renderParams() }, load));
    };

    useEffect(() => {
        getInvoices();
    }, [info]);

    useEffect(() => {
        if (generateSuccess) {
            getInvoices('noLoad');
            dispatch(httpRequestsOnSuccessActions.removeSuccess(GENERATE_ACTION_TYPE));
            close();
        }
        if (payInFullSuccess) {
            getInvoices('noLoad');
            dispatch(httpRequestsOnSuccessActions.removeSuccess(PAY_IN_FULL_PAYMENT_INVOICE));
        }
        if (editSuccess) {
            setActiveRow({});
            getInvoices('noLoad');
            dispatch(httpRequestsOnSuccessActions.removeSuccess(EDIT_TYPE));
        }
    }, [generateSuccess, payInFullSuccess, editSuccess]);

    const handleChange = (e) => {
        if (e.target.name === 'paidAMT') {
            const value = e.target.value;
            const convertedValue = parseFloat(value.replace(/[$,]/g, ''));

            setActiveRow((prevState) => ({ ...prevState, ['paidAMT']: convertedValue }));
        } else {
            setActiveRow((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        }
    };

    const payInFull = (i) => {
        setSelectedId(i);
        const body = {};
        activeRow?.paymentRef ? body.paymentRef = activeRow?.paymentRef : i?.paymentRef ? body.paymentRef = i?.paymentRef : '';
        activeRow?.paymentType ? body.paymentType = activeRow?.paymentType : i?.paymentType ? body.paymentType = i?.paymentType : '';
        activeRow?.paidAMT ? body.paymentAMT = activeRow?.paidAMT : i?.paidAMT ? body.paymentAMT = i?.paidAMT : '';
        dispatch(invoicePaymentActions.payInFullInvoice(i?.id, body));
    };

    const handleSave = () => {
        const body = {
            'paymentAMT': +activeRow?.paidAMT,
        };
        activeRow?.paymentRef ? body.paymentRef = activeRow?.paymentRef : '';
        activeRow?.paymentType ? body.paymentType = activeRow?.paymentType : '';

        if (activeRow?.paidAMT <= activeRow?.totalAMT) {
            dispatch(invoicePaymentActions.editPaymentInvoice(activeRow?.id, body));
        } else {
            dispatch(httpRequestsOnErrorsActions.appendError('INVOICE_AMT_IS_GREAT'));
        }
    };

    const handleCloseInputs = () => {
        setActiveRow({});
        setSelectedId('');
    };

    return (
        <div>
            <InvoiceFilters />
            <Aging aging={invoicePaymentsData?.aging} />
            <FullTable
                head={invoicePaymentHead}
                body={invoicePaymentBody(activeRow, setActiveRow, selectedId, handleChange, handleCloseInputs, payInFull, handleSave)}
                loadingType={GET_ACTION_TYPE}
                list={invoicePaymentsData?.invoices}
                handleClick={(id) => history.push(`/invoice/${id}`)}
                listCount={invoicePaymentsData?.count}
                notYet={invoicesNotYet}
                activeRowId={activeRow?.id}
            />
        </div>
    );
};
