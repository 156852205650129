import { GET_TAGS_SUCCESS } from './tag.types';

const initialState = {
    tagList: [],
};

export const tagReducer = (state = initialState, action) => {
    switch (action.type) {

        /** Tags */
        case GET_TAGS_SUCCESS:
            return {
                ...state,
                tagList: action.payload,
            };
        /** End */

        default:
            return state;
    }
};
