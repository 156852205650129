import {
    CustomizedSwitch,
    DateRow,
    InactivateBtb,
    PriceRow,
    SimpleTooltip,
    TextRow,
    ToolInfo,
    TypeRow
} from 'components';
import moment from "moment";
import {PermissionList, RolePermission} from "../../../../../utils";
import React from "react";

export const payCodeHead = [
    {name: "",  title: "Name", custom: false},
    {name: "", rowName: '', title: "Code", custom: false, disabled: true},
    {
        name: "type", title: "Type", disabled: true,
        filterList: [
            {name: "All", id: "All"},
            {name: "Hourly", id: "HOURLY"},
            {name: "Salary", id: "SALARY"},
        ],
    },
    {name: "rate", title: "Rate", custom: false},
    {name: "startDate", title: "Start Date", icon: "date", custom: false, disabled: true,},

    {
        name: "status", title: "Status", disabled: true,
        filterList: [
            {name: "All", id: "All"},
            {name: "Active", id: "ACTIVE"},
            {name: "Inactive", id: "INACTIVE"},
        ],
    },
    RolePermission([PermissionList.STAFF_PAYCODE_MANAGE?.code]) &&
    {name: "action", title: "Action", custom: false, disabled: true,},
];

export const payCodeBody = (handleOpen) => [
    {rowText: (item) => <TextRow name={item?.payCodeTypeId?.name}/>},
    {rowText: (item) => <TextRow name={item?.payCodeTypeId?.code}/>},
    {rowText: (item) => <TypeRow text={item?.payCodeTypeId?.type ? item?.payCodeTypeId?.type : ''}/>},
    {rowText: (item) => <PriceRow info={item?.rate}/>},
    {rowText: (item) => <DateRow date={item?.startDate}/>},
    {rowText: (item) => <TypeRow text={item?.terminationDate ? "Inactive" : "Active"}/>},
    RolePermission([PermissionList.STAFF_PAYCODE_MANAGE?.code]) &&
    {
        button: (cb, item) => (
            <div>
                {item?.terminationDate ?
                    <SimpleTooltip
                        placement="top-start"
                        title={
                            <div> Inactive since {
                                item?.terminationDate ? moment.utc(item?.terminationDate).format('MM/DD/YYYY') : ''
                            }</div>
                        }
                    >
                        <div className='flex-align-center' style={{gap: '8px'}}>
                            <div className='red-circle'/>
                            <p>{item?.terminationDate ? moment.utc(item?.terminationDate).format('MM/DD/YYYY') : ''}</p>
                        </div>
                    </SimpleTooltip>
                    :
                    <InactivateBtb handleClick={() => handleOpen(item)}/>
                }
            </div>
        )

        // <div
        //     style={{
        //         display: 'flex',
        //         alignItems: 'center',
        //         cursor: item?.terminationDate ? "default" : "pointer",
        //             }}
        //         >
        //             <CustomizedSwitch
        //                 checked={item?.terminationDate}
        //                 handleClick={() => cb("terminatePaycode")}
        //                 disabled={ RolePermission([PermissionList.STAFF_PAYCODE_MANAGE?.code]) ? item?.terminationDate : true}
        //                 bgColor={"#6FD231"}
        //                 checkedBgColor={"#FEB8B8"}
        //             />
        //             <div style={{marginLeft:'16px'}}>
        //                 <p>{item?.terminationDate ? moment.utc(item?.terminationDate).format('MM/DD/YYYY')  : ''}</p>
        //             </div>
        //         </div>
        //     ),
        //     notClickable: true,
    },
];

export const ACTION_TYPE = "GET_PAY_CODE";
