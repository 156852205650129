import React from 'react';
import { SERVICE } from '../../../constants';
import { Images, useModal } from 'utils';
import { apptDetailStatus } from './constants';
import { ApptActionsWithModals } from '../../apptActionsWithModals';
import { CancelAppt } from '../cancelAppt';

export const ApptActions = ({ item, setModalIsOpen, setActiveTab, afterSuccess }) => {
    const { open } = useModal();

    const editReason = () => {
        open(
            <CancelAppt
                afterSuccess={afterSuccess}
                edit={true}
            />
        )
    }

    return (
        <>
            {item?.type === SERVICE &&
                <div className="appt-detail-status-box" style={{margin: '10px 0 0 0'}}>
                    {apptDetailStatus(item?.status, item, false, editReason)}
                </div>
            }
            <div className="space-between" style={{ margin: item?.type === SERVICE ? '24px 0' : '8px 0' }}>
                <div>
                    {item?.type === SERVICE ?
                        <div className="flex-align-center" style={{ gap: '12px' }}>
                            <button className="new-action-btn-box" onClick={() => setActiveTab('Notes')}>
                                <img src={Images.manageNote} alt="icon" />
                                <p>Manage Notes</p>
                            </button>

                            <button className="new-action-btn-box" onClick={setModalIsOpen}>
                                <img src={Images.uploadSignature} alt="icon" />
                                <p>Upload Signature</p>
                            </button>
                        </div>
                        :
                        <div className="appt-detail-status-box" style={{ margin: '10px 0 0 0' }}>
                            {apptDetailStatus(item?.status, item, false, editReason)}
                        </div>
                    }
                </div>
                <ApptActionsWithModals item={item} afterSuccess={afterSuccess} />
            </div>
        </>
    );
};