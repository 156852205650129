import { NumericFormat } from 'react-number-format';
import React from 'react';


export const PriceInput = ({ value, handleChange, name, placeholder, disabled, ref }) => {

    return (
        <div ref={ref}>
            <NumericFormat
                value={value}
                onChange={handleChange}
                name={name}
                thousandSeparator={true}
                fixedDecimalScale={true}
                decimalSeparator="."
                allowNegative={false}
                isNumericString
                prefix={'$'}
                placeholder={placeholder}
                decimalScale={2}
                disabled={disabled}
            />
        </div>
    );
};