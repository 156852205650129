import React from 'react';
import { Card } from 'components';
import { getFundingSourceClients, getFundingSourceContactInfo } from './constants';
import { Images, PermissionList, RolePermission, useModal } from 'utils';
import { CreateFundingSource } from '../../createFundingSource';
import { useSelector } from 'react-redux';

export const FundingSourceSingleGeneral = ({ data }) => {
    const { open } = useModal();
    const fsClients = useSelector((state) => state.fundingSource.fsClients);

    return (
        <div className="flex-align-start" style={{ gap: '16px' }}>
            {/*<Card*/}
            {/*   cardInfo={getFundingSourceGeneralInfo(data)}*/}
            {/*   maxWidth={"510px"}*/}
            {/*   width={"32.5%"}*/}
            {/*   isBig={true}*/}
            {/*/>*/}
            <Card
                title={'General Info'}
                color={'#347AF0'}
                icon={Images.generalInfoIcon}
                showHeader={true}
                edit={RolePermission([PermissionList.FS_CREATE?.code])}
                handleEdit={() => open(<CreateFundingSource info={data} />)}
                cardInfo={getFundingSourceContactInfo(data)}
                maxWidth={'510px'}
                width={'32.5%'}
                isBig={true}
            />
            {!!fsClients?.clients  &&
            <Card
                title={'Clients'}
                color={'#FE7070'}
                icon={Images.fsClients}
                showHeader={true}
                cardInfo={getFundingSourceClients(fsClients )}
                maxWidth={'510px'}
                width={'32.5%'}
                isBig={true}
            />
            }
        </div>
    );
};
