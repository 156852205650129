import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useForm} from 'react-hook-form';
import {authActions, httpRequestsOnErrorsActions, authService} from 'store';
import {
    ErrMessage,
    SignIn, InputMain,
} from 'components';
import {EmailValidator, FindErrorItem, FindLoad} from 'utils';
import {Svg} from 'assets';

const ACTION_TYPE = 'LOG_IN';

export const LoginModal = ({}) => {
    const {handleSubmit, control, setError, clearErrors,} = useForm({
        defaultValues: {
            email: '',
            password: '',
        },
    });
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const history = useHistory();
    const backError = FindErrorItem(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const errMessageScreen = backError?.error === 'Check your email to activate your account' ? 'Check your email to activate your account' :
        backError?.error === 'Your account has been inactivated, contact an administrator.' ? 'Your account has been inactivated, contact an administrator.' :
            '';

    useEffect(() => {
        return () => {
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
            clearErrors();
        };
    }, []);


    useEffect(() => {
        if (backError?.error === 'User with this email was not found') {
            setError('email', {
                type: 'required',
                message: 'User with this email was not found.',
            });
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        }
        if (backError?.error === 'User Identification with this resource was not found.') {
            setError('email', {
                type: 'required',
                message: 'User with this email was not found.',
            });
        }
        if (backError?.error === 'user password does not match') {
            setError('password', {
                type: 'required',
                message: 'User password does not match',
            });
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        }
    }, [backError]);

    const logInRequest = (data) => {
        dispatch(authActions.logIn(data));
    };

    return (
        <div className="login-wrapper">
            <div className="login-main-content">
                <h3 className="login-title">
                    Welcome to TherapyLake
                </h3>
                <p className="login-description">
                    Please enter your email and password to sign in
                </p>
                <form className="login-form" onSubmit={handleSubmit(logInRequest)}>
                    <InputMain
                        variant="outlined"
                        name="email"
                        control={control}
                        placeholder="Enter your email address"
                        labelHeader="Email Address"
                        rules={{
                            required: 'Email address is required.',
                            pattern: {
                                value: EmailValidator,
                                message: 'Please enter a valid email address.',
                            },
                        }}
                        startAdornment={<Svg.SmsSvg/>}
                    />
                    <InputMain
                        variant="outlined"
                        name="password"
                        type="password"
                        showPassword={showPassword}
                        control={control}
                        placeholder="Password"
                        labelHeader="Password"
                        rules={{
                            required: 'Password is required.',
                        }}
                        onClickIconEnd={() => setShowPassword(prev => !prev)}
                        startAdornment={<Svg.LockPassSvg/>}
                    />
                    {errMessageScreen &&
                        <ErrMessage
                            type={'login'}
                            text={errMessageScreen}
                        />
                    }
                    <div className="flex-end">
                        <button type={'button'} onClick={() => history.push('/forgot')} className="forgot-button">
                            Forgot Password?
                        </button>
                    </div>
                    <SignIn
                        type="submit"
                        loader={!!loader?.length}
                        width={'100%'}
                        text={'Sign In'}
                    />
                </form>
            </div>

            <p className="dont-have-account">
                Don’t have an account? Please contact your admin for access
                {/*<Link className='dont-have-account' to='/' style={{ margin: '0 4px' }}>*/}
                {/*    contact*/}
                {/*</Link>*/}
                {/*your admin for access*/}
            </p>
        </div>
    );
};
