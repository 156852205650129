import { renderText, manageStatusCircle } from './constants';

export const StatusCard = ({ status }) => {
    const { backgroundColor, circleColor, color } = manageStatusCircle(status);


    return (
        <div className="status-card-box" style={{ backgroundColor: backgroundColor }}>
            <div style={{ backgroundColor: circleColor }} />
            <p style={{ color: color }}>{renderText(status)}</p>
        </div>
    );


};