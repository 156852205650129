import { getLimitedVal, handleTooltip, hooksForTable } from ".";

const { showDashIfEmpty } = hooksForTable;

export const getDisplayDependsOnWidth = (
   item,
   width,
   limit = 10,
   comparingWidth = 1440
) => {
   if (
      typeof item !== "string" &&
      typeof width !== "number" &&
      typeof limit !== "number" &&
      typeof comparingWidth !== "number"
   )
      return;
   const itemDisplay =
      width <= comparingWidth
         ? handleTooltip(
              showDashIfEmpty(item),
              getLimitedVal(showDashIfEmpty(item), limit)
           )
         : showDashIfEmpty(item);

   return itemDisplay;
};
