import { useHistory } from 'react-router-dom';


export const LoginBackBtn = ({}) => {
    const history = useHistory();

    return(
        <button
            className='back-to-sign-in'
            onClick={() => history.push('/login')}
        >
            ← Back to Sign In
        </button>
    )
}