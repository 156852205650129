import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "utils";

export const tabsStyles = makeStyles(() => ({
   root: {
      flexGrow: 1,
      backgroundColor: "white",
      position: "relative",
   },

   tabHeader: {
      background: "white",
      boxShadow: "none",
      "& .MuiTabs-indicator": {
         backgroundColor: `${Colors.BackgroundMango}`,
         height: "4px",
         borderRadius: "4px",
      },
      "& .MuiTabs-flexContainer": {
         borderBottom: `4px solid ${Colors.BackgroundWater}`,
      },

      '& .MuiSvgIcon-root':{
         filter: 'invert(98%) sepia(44%) saturate(5159%) hue-rotate(313deg) brightness(98%) contrast(105%)',
      }
   },
   tabLabel: {
      fontSize: "14px !important",
      textTransform: "capitalize !important",
      color: "#4B5C68 !important",
      fontWeight: "bold !important",
      // lineHeight: "19px !important",
      padding: "0 24px !important",
      minWidth: "unset !important",
      minHeight: "34px !important",
   },

   InfoAndImage: {
      display: "flex",
      alignItems: "center",
      "& p": {
         fontSize: "14px",
         lineHeight: "19px",
         fontWeight: "600",
         color: Colors.TextSecondary,
      },
      "& img": {
         width: "24px",
         height: "24px",
         marginRight: "4px",
         "@media (min-width: 1920px)": {
            marginRight: "8px",
         },
      },
   },


   boxStyle: {
      minHeight:'65vh',
      padding: "24px 0!important",

      "@media (min-width: 1919px)": {
         padding: "32px 0!important",
      },
   },

   smallBoxStyle:{
      minHeight:'40vh',
      padding: "24px 0!important",
      // "@media (min-width: 1919px)": {
      //    padding: "32px 0!important",
      // },
   }
}));
