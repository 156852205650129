import React, { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Checkbox, TextField } from '@material-ui/core';
import { Colors, FindLoad } from 'utils';
import { InputMinLoader } from '../../inputs';

const getNestedError = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

export const SelectAutocompleteMultiple = forwardRef((
    {
        control,
        rules,
        label,
        options = [],
        placeholder,
        name,
        disabled,
        renderValue,
        classNameWrapper,
        multiple = false,
        selectedType,
        loadType,
        fullValue,
    }, ref,
) => {
    const loader = FindLoad(loadType);

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field, formState: { errors } }) => {
                const error = getNestedError(errors, name);
                const current =
                        field.value ?
                            options?.find((i) => i.id === (
                                    field?.value?.id ? field?.value?.id :
                                        field?.value?._id ? field?.value?._id :
                                            i?.id === field?.value ? i?.id :
                                                field?.value
                                ),
                            ) : null;



                return (
                    <div style={{ width: '100%' }}>
                        <Autocomplete
                            {...field}
                            value={ multiple === true ? field.value : current}
                            ref={ref}
                            key={options || field.value}
                            loading={!!loader?.length}
                            disabled={disabled}
                            className={`autocomplete-main-wrapper ${classNameWrapper ? classNameWrapper : ''}`}
                            multiple={multiple}
                            id="checkboxes-tags-demo"
                            options={options || []}
                            disableCloseOnSelect={multiple}
                            onChange={(event, value) => field.onChange(
                                multiple ? value :
                                    fullValue ? value : value?.id || value?._id,
                            )}
                            getOptionLabel={(option) => {
                                return option ? renderValue ? renderValue(option) : option?.title : '';
                            }}
                            getOptionSelected={(option, value) => {
                                return selectedType ? option[selectedType] === value[selectedType] :
                                    option?.id === value?.id || option?._id === value?._id || option?.id === value?._id || option?._id === value?.id;
                            }
                            }
                            renderOption={(option, { selected }) => {

                                return <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {multiple &&
                                        <Checkbox
                                            style={{ marginRight: 8, color: Colors.ThemeBlue }}
                                            checked={selected}
                                        />
                                    }
                                    <div>
                                        <p style={{ width: '100%' }}>
                                            {renderValue ? renderValue(option) : option.title}
                                        </p>
                                        {name === 'permissions' &&
                                            <p style={{ color: 'gray', fontSize: '12px' }}>{option?.description}</p>
                                        }
                                    </div>
                                </div>;
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    InputProps={!!loader?.length ? {
                                        ...params.InputProps,
                                        endAdornment: (
                                            <InputMinLoader />
                                        ),
                                    } : { ...params.InputProps }}
                                    variant="outlined"
                                    label={label}
                                    placeholder={placeholder}
                                    error={!!error?.message}
                                />
                            )}
                        />
                        <p className="error-message">{error?.message}</p>
                    </div>

                );
            }}
        />
    );
});

SelectAutocompleteMultiple.displayName = 'SelectAutocompleteMultiple';


