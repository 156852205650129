import React from 'react';
import { useHistory } from 'react-router-dom';
import { ClaimFilters } from './claimFilters';
import { claimTabEnums, claimTabTypes } from './constants';
import { PendingClaims } from './pendingClaims';
import { TabSwitcher } from 'components';
import { BillableAppt } from './billableAppt';
import { SubmittedClaim } from './submittedClaim';


export const Claims = () => {
    const history = useHistory();
    const info = history?.location?.state;

    const checkTableType =
        info?.tabType === claimTabEnums?.BILLABLE ? claimTabEnums?.BILLABLE :
            info?.tabType === claimTabEnums?.PENDING ? claimTabEnums?.PENDING :
                info?.tabType === claimTabEnums?.SUBMITTED ? claimTabEnums?.SUBMITTED : claimTabEnums?.BILLABLE;

    return (
        <div>
            <div className="space-between-align-end">
                <TabSwitcher list={claimTabTypes} defaultType={claimTabEnums?.BILLABLE} />
                <ClaimFilters />
            </div>


            {checkTableType === claimTabEnums?.BILLABLE &&
                <BillableAppt />
            }

            {checkTableType === claimTabEnums?.PENDING &&
                <PendingClaims />
            }

            {checkTableType === claimTabEnums?.SUBMITTED &&
                <SubmittedClaim />
            }
        </div>
    );
};