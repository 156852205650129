import { Autocomplete } from '@material-ui/lab';
import { InputAdornment, TextField } from '@material-ui/core';
import { inputsStyle } from './styles';
import React, { useEffect, useState } from 'react';
import { ErrorText, FindLoad, Images, useGlobalStyles } from 'utils';
import { ErrMessage } from '../messages';
import { InputMinLoader } from './inputMiniLoader';
import { TextRow } from '../table';

function SearchIcon() {
    return null;
}

export const SelectTypeAutocomplete = ({
                                           list,
                                           title,
                                           defaultValue,
                                           name,
                                           handleSelect,
                                           style,
                                           disabled,
                                           error,
                                           typeError,
                                           handleType,
                                           loadType,
                                           placeholder,
                                           renderValue,
                                           customClass,
                                           uiType, outLabel, noError, outStyle, renderStatus,
                                           startIcon,
                                       }) => {
    const classes = inputsStyle();
    const globalStyles = useGlobalStyles();
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const loader = FindLoad(loadType);

    const defaultProps = {
        options: value ? list?.length && list?.filter((i) => i.id !== value.id) : list?.length ? list : [],
        getOptionLabel: (option) => renderValue(option) || '',
    };

    const handleClick = (e, ev) => {
        setValue(ev);
        if (ev) {
            let info = { target: { value: ev.id, name: name } };
            handleSelect(info);
            setValue(ev.id);
        } else {
            let info = { target: { value: '', name: name } };
            handleSelect(info);
            setValue('');
        }
    };

    useEffect(() => {
        if (defaultValue && list?.length) {
            list?.find((i) => {
                if (i.id === defaultValue || i._id === defaultValue) {
                    setValue(i);
                }
            });
        } else {
            setValue(null);
        }
    }, [defaultValue, list]);


    return (
            <div className={style ? style : ''}>
                {outLabel && <p className={classes.labelTitle} style={{ ...outStyle }}>{outLabel}</p>}
                <Autocomplete

                    loading={!!loader?.length}
                    key={list}
                    className={
                        uiType ? classes.noChipLoads :
                            disabled ? classes.inputTextFieldDisabled :
                                customClass ? customClass :
                                    error === name ? globalStyles.inputTextFieldError :
                                        defaultValue ? globalStyles.inputTextFieldBlue :
                                            classes.inputTextField
                    }
                    value={value}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    disabled={disabled}
                    {...defaultProps}
                    style={{ width: '100%' }}
                    onChange={(event, newValue) => {
                        handleClick(event, newValue);
                    }}
                    id="disable-close-on-select"
                    name={name}
                    renderOption={(option, { selected }) => (
                        <div style={{ width: '100%', gap: '4px' }} className={'selector-wrapper'}>
                            {option?.img && <img src={option?.img} alt={'icon'} />}
                            {renderStatus && <div className={option?.status === 'ACTIVE' ? 'circle-box green' : 'circle-box red'} />}
                            <TextRow name={renderValue(option)} textWidth={9} />
                        </div>
                    )}
                    renderInput={(params) => (
                        <TextField
                            style={{color:'red'}}

                            {...params}
                            InputProps={loader?.length || startIcon ? {
                                    ...params.InputProps,
                                    endAdornment: !!loader?.length && (
                                        <InputMinLoader />
                                    ),
                                    startAdornment: startIcon && <img src={startIcon} alt="icon" />,
                                } :

                                { ...params.InputProps }
                            }
                            // startIcon ? (
                            //     startAdornment  <img src={startIcon} alt="icon" />
                            //     )
                            //     :
                            //     { ...params.InputProps }}

                            // InputProps={{
                            //     ...params.InputProps,
                            //     endAdornment: !!loader?.length && (
                            //         <InputMinLoader />
                            //     ),
                            //     startAdornment: startIcon && (
                            //             <img src={startIcon} alt="icon" />
                            //     ),
                            // }}
                            placeholder={placeholder}
                            label={title}
                            variant="outlined"
                            onChange={(e) => handleType && handleType(e)}
                        />
                    )}
                />
                {!noError &&
                    <ErrMessage
                        text={error === name ? typeError ? typeError : ErrorText.field : ''}
                        style={{ position: 'relative' }}
                    />
                }
            </div>
    );
};