import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ValidationInput, CreateChancel, SimpleTooltip, SelectTypeAutocomplete, DateTypeSelect, AddressInput } from 'components';
import { createClientStyle } from './styles';
import { ErrorText, FindLoad, FindSuccess, isNotEmpty, makeEnum, useModal } from 'utils';
import { clientActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import moment from 'moment';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';

export const AddAuthorization = ({ info, allList }) => {
    const ACTION_TYPE = info ? 'EDIT_CLIENT_AUTHORIZATION' : 'CREATE_CLIENT_AUTHORIZATION';
    const classes = createClientStyle();
    const dispatch = useDispatch();
    const success = FindSuccess(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const [authStatus, setAuthStatus] = useState(info ? info?.status : 'ACTIVE');
    const [enteredAddress, setEnteredAddress] = useState(info?.location ? {
        formattedAddress: '',
        city: '',
        country: '',
        lat: '',
        lng: '',
        state: '',
        street: info?.location,
        zip: '',
    } : '');
    const enrollments = useSelector((state) => state?.client?.clientEnrollment);
    const activeEnrollments = enrollments?.active;
    const findActive = allList?.find((i) => i?.status === 'ACTIVE');
    const primaryEnrollment = activeEnrollments.find((item) => item.type === 'PRIMARY');
    const [error, setError] = useState('');
    const statusCheck = info?.status === 'ACTIVE' || !info;
    const client = useSelector((state) => state.client.clientItemInfo);
    const { close } = useModal();

    const [inputs, setInputs] = useState(
        info
            ? {
                ...info,
                funding: info?.funderId?._id,
            }
            : {
                funding: primaryEnrollment?.funderId,
            },
    );

    useEffect(() => {
        if (success?.length) {
            dispatch(httpRequestsOnErrorsActions.removeError('GET_CLIENT_AUTHORIZATION'));
            dispatch(httpRequestsOnSuccessActions.removeSuccess('GET_CLIENT_AUTHORIZATION'));
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            close();
        }
    }, [success]);

    function handleChange(e) {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        (error === e.target.name || error === ErrorText.dateError) && setError('');
    }

    function handleStatusChange(event) {
        setAuthStatus(event.target.value);
        error === 'status' && setError('');
    }

    const handleAddressChange = (value) => {
        setEnteredAddress(value);
        error === 'enteredAddress' && setError('');
    };

    function handleCreate() {
        const dateComparingIsValid =
            !!inputs.startDate &&
            !!inputs.endDate &&
            new Date(inputs.startDate).getTime() < new Date(inputs.endDate).getTime();
        const authorizationDataIsValid =
            isNotEmpty(inputs.authId) &&
            isNotEmpty(inputs.funding) &&
            dateComparingIsValid && enteredAddress?.street;


        if (authorizationDataIsValid) {
            const data = {
                authId: inputs.authId,
                startDate: inputs.startDate,
                endDate: inputs.endDate,
                location: enteredAddress?.street,
                enrollmentId: primaryEnrollment?.id,
                status: info ? makeEnum(authStatus) : findActive ? 'INACTIVE' : makeEnum(authStatus),
            };

            if (!!info) {
                dispatch(clientActions.editClientsAuthorizations(data, info.id, client.id));
            } else {
                dispatch(clientActions.createClientsAuthorizations(data, client?.id, inputs.funding));
            }
        } else {
            const dataErrorText =
                !isNotEmpty(inputs.authId) ? 'authId' :
                    !isNotEmpty(inputs.funding) ? 'funding' :
                        !inputs.startDate ? 'startDate' :
                            !inputs.endDate ? 'endDate' :
                                !dateComparingIsValid ? ErrorText.dateError :
                                    !authStatus ? 'status' :
                                        !enteredAddress?.address ? 'enteredAddress'
                                            : '';
            setError(dataErrorText);
        }
    }

    const curr = [
        {
            id: primaryEnrollment?.funderId,
            name: primaryEnrollment?.funderName,
        },
    ];

    const renderToolTip = () => {
        return <FormGroup
            style={{ marginBottom: '20px' }}
            onChange={handleStatusChange}>
            <FormControlLabel
                disabled={info ? false : !!findActive}
                control={
                    <Checkbox
                        style={{ color: '#347AF0' }}
                        value={authStatus === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE'}
                        checked={
                            info ? authStatus === 'INACTIVE' :
                                findActive === undefined ?
                                    authStatus === 'INACTIVE' :
                                    !!findActive
                        }
                    />
                } label="Past Authorization"
            />
        </FormGroup>;
    };

    return (
        <div style={{ width: 400 }}>
            <p className="modal-header-title">{info ? 'Edit Authorization' : 'Add Authorization'}</p>

            <div className="modal-body-wrapper">
                <ValidationInput
                    variant={'outlined'}
                    onChange={handleChange}
                    value={inputs.authId}
                    type={'text'}
                    label={'Authorization #*'}
                    name="authId"
                    typeError={error === 'authId' ? `Authorization ${ErrorText.isRequired}` : ''}
                    Length={20}
                />
                <SelectTypeAutocomplete
                    disabled={true}
                    title={'Funding Source*'}
                    name={'funding'}
                    handleSelect={handleChange}
                    defaultValue={inputs.funding}
                    list={curr || []}
                    error={error}
                    typeError={error === 'funding' ? `Funding source ${ErrorText.isRequired}` : ''}
                    renderValue={(i) => i?.name}
                />
                <div style={{ display: 'flex', gap: 16 }}>
                    <DateTypeSelect
                        type={'modalInput'}
                        name={'startDate'}
                        outLabel={'Start Date*'}
                        handleGetDates={handleChange}
                        values={inputs}
                        max={inputs.endDate ? moment.utc(inputs.endDate).format('YYYY-MM-DD') : ''}
                        error={error === 'startDate' ? `Start date ${ErrorText.isRequired}` : ''}
                    />
                    <DateTypeSelect
                        type={'modalInput'}
                        name={'endDate'}
                        outLabel={'End Date*'}
                        handleGetDates={handleChange}
                        values={inputs}
                        min={inputs.startDate ? moment.utc(inputs.startDate).format('YYYY-MM-DD') : ''}
                        error={error === 'endDate' ? `End date ${ErrorText.isRequired}` : error === ErrorText.dateError
                            ? ErrorText.dateError
                            : ''
                        }
                    />
                </div>
                <AddressInput
                    noSuite={true}
                    oneInput={true}
                    placeholder={'Service Location*'}
                    selectedAddress={enteredAddress}
                    setCurrentAddress={handleAddressChange}
                    errMessage={error === 'enteredAddress' ? `Service Location ${ErrorText.isRequired}` : ''}
                />

                {statusCheck &&
                !!findActive && !info ?
                    <SimpleTooltip
                        title={
                            <p className={classes.infoTextForAuthStyle}>
                                There is already an active authorization for this client. Inactivate it to set
                                this one active
                            </p>
                        }
                        placement="top-start"
                    >
                        {renderToolTip()}
                    </SimpleTooltip>
                    :
                    <>
                        {renderToolTip()}
                    </>
                }
            </div>
            <CreateChancel
                loader={!!loader.length}
                create={info ? 'Save' : 'Add'}
                chancel={'Cancel'}
                onCreate={handleCreate}
                onClose={close}
                buttonWidth="100%"
            />
        </div>
    );
};
