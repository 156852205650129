/** Types */

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const REMOVE_NOTIFICATIONS = 'REMOVE_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_UNRENDER_NOTIFICATIONS = 'GET_UNRENDER_NOTIFICATIONS';
export const GET_UNRENDER_NOTIFICATIONS_SUCCESS = 'GET_UNRENDER_NOTIFICATIONS_SUCCESS';


export const MARK_ALL_AS_READ = 'MARK_ALL_AS_READ';
export const MARK_SINGLE_AS_READ = 'MARK_SINGLE_AS_READ';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';




