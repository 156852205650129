import { v4 as uuidv4 } from 'uuid';
import {
    apptBillingEnums,
    apptEnums, scheduleTypes,
} from '../../../../fragments/schedule/core/constants';
import { activeInactiveEnums } from '../../../../utils';
import { CLIENT_ACTION, renderClientName, renderStaffName } from './constants';

const newScheduleTypes = [
    ...scheduleTypes
]
delete newScheduleTypes[0]
export const scheduleReport = () => {
    return {
        title: 'Appointment Detail Report',
        url: '/reports/appt/detail',
        list:
            [
                {
                    type: 'dos',
                    startDate: 'startDate',
                    endDate: 'endDate',
                    startLabel: 'Start Date',
                    endLabel: 'End Date',
                },
                {
                    type: 'select',
                    name: 'status',
                    label: 'Appt. Status',
                    selectList: [
                        { id: apptEnums?.COMPLETED, name: 'Completed' },
                        { id: apptEnums?.PENDING, name: 'Pending' },
                        { id: apptEnums?.CANCELLED, name: 'Cancelled' },
                    ],
                    renderValue: (option) => option?.name,
                },
                {
                    type: 'select',
                    name: 'billStatus',
                    label: 'Billing Status',
                    selectList: [
                        { id: apptBillingEnums?.BILLED, name: 'Billed' },
                        { id: apptBillingEnums.NOT_BILLED, name: 'Not Billed' },
                    ],
                    renderValue: (option) => option?.name,
                },
                {
                    type: 'selectMultiple',
                    name: 'staffs',
                    label: 'Staff',
                    loadType: 'GET_ADMINS',
                    renderValue: (option) => renderStaffName(option),
                },
                {
                    type: 'selectMultiple',
                    name: 'clients',
                    label: 'Client',
                    loadType: CLIENT_ACTION,
                    renderValue: (option) => renderClientName(option),
                },
                {
                    type: 'selectMultiple',
                    name: 'payers',
                    label: 'Payer',
                    loadType: 'GET_FUNDING_SOURCE',
                    renderValue: (option) => option?.name,
                },
                {
                    type: 'select',
                    name: 'serviceType',
                    label: 'Service Type',
                    selectList: [
                        { name: 'Direct', id: 'DIRECT' },
                        { name: 'Indirect', id: 'INDIRECT' },
                    ],
                    renderValue: (option) => option?.name,
                },
                {
                    type: 'select',
                    name: 'type',
                    label: 'Event Type',
                    selectList: [
                        ...newScheduleTypes
                    ],
                    renderValue: (option) => option?.name,
                },
            ],
    };
};

export const staffPayReport = () => {
    return {
        title: 'Staff Pay Summary Report',
        url: '/reports/payroll/staff/summary',
        list:
            [
                {
                    type: 'dos',
                    startDate: 'startDate',
                    endDate: 'endDate',
                    startLabel: 'Start Date',
                    endLabel: 'End Date',
                    required: true,
                },
                {
                    type: 'selectMultiple',
                    name: 'staffs',
                    label: 'Staff',
                    loadType: 'GET_ADMINS',
                    renderValue: (option) => renderStaffName(option),
                },
                {
                    type: 'select',
                    name: 'staffStatus',
                    label: 'Staff Status',
                    selectList: [
                        { name: 'Active', id: 'ACTIVE' },
                        { name: 'Inactive', id: 'INACTIVE' },
                    ],
                    renderValue: (option) => option?.name,
                },
                {
                    type: 'select',
                    name: 'payCodeTypeId',
                    label: 'Pay Code',
                    selectList: [
                        { name: 'Active', id: 'ACTIVE' },
                        { name: 'Inactive', id: 'INACTIVE' },
                    ],
                    renderValue: (option) => option?.name,
                },
            ],
    };
};



export const payrollDetailReport = () => {
    return {
        title: 'Payroll Detail Report',
        url: '/reports/payroll/details',
        list:
            [
                {
                    type: 'selectMultiple',
                    name: 'staffs',
                    label: 'Staff',
                    loadType: 'GET_ADMINS',
                    renderValue: (option) => renderStaffName(option),
                },
                {
                    type: 'select',
                    name: 'staffStatus',
                    label: 'Staff Status',
                    selectList: activeInactiveEnums ,
                    renderValue: (option) => option?.name,
                },
                {
                    type: 'dos',
                    startDate: 'startDate',
                    endDate: 'endDate',
                    startLabel: 'Start Date',
                    endLabel: 'End Date',
                },
                {
                    type: 'select',
                    name: 'payCodeTypeId',
                    label: 'Pay Code',
                    renderValue: (option) => option?.name,
                },
                {
                    type: 'select',
                    name: 'status',
                    label: 'Timesheet Status',
                    selectList: [
                        { name: 'Active', id: 'ACTIVE' },
                        { name: 'Processed', id: 'PROCESSED' },
                    ] ,
                    renderValue: (option) => option?.name,
                },
            ],
    };
};
export const payrollSummaryReport = () => {
    return {
        title: 'Payroll Summary Report',
        url: '/reports/payroll/summary',
        list:
            [
                {
                    type: 'selectMultiple',
                    name: 'staffs',
                    label: 'Staff',
                    loadType: 'GET_ADMINS',
                    renderValue: (option) => renderStaffName(option)
                },
                {
                    type: 'select',
                    name: 'staffStatus',
                    label: 'Staff Status',
                    selectList: activeInactiveEnums ,
                    renderValue: (option) => option?.name,
                },
                {
                    type: 'dos',
                    startDate: 'startDate',
                    endDate: 'endDate',
                    startLabel: 'Start Date',
                    endLabel: 'End Date',
                },
                {
                    type: 'select',
                    name: 'payCodeTypeId',
                    label: 'Pay Code',
                    renderValue: (option) => option?.name,
                },
                // {
                //     type: 'select',
                //     name: 'status',
                //     label: 'Timesheet Status',
                //     selectList: [
                //         { name: 'Active', id: 'ACTIVE' },
                //         { name: 'Processed', id: 'PROCESSED' },
                //     ] ,
                //     renderValue: (option) => option?.name,
                // },
            ],
    };
};

export const payrollReportEnums = () => {

    return [
        {
            id: uuidv4(),
            name: 'Appointment Detail Report',
            description: 'Detailed appointment overview including staff, client, billing, and service information.',
            generateInfo: scheduleReport(),
        },
        {
            id: uuidv4(),
            name: 'Staff Pay Summary Report',
            description: 'Summary of staff pay including rates, quantities, totals, and timesheet counts.',
            generateInfo: staffPayReport(),
        },
        {
            id: uuidv4(),
            name: 'Payroll Detail Report',
            description: 'Staff timesheets with hours, pay codes, rates, and total amounts.',
            generateInfo: payrollDetailReport(),
        },
        {
            id: uuidv4(),
            name: 'Payroll Summary Report',
            description: 'Payroll summary with staff pay rates, hours worked, and total pay amounts.',
            generateInfo: payrollSummaryReport(),
        },
    ];
};