import { ErrorText } from "utils";

export const hooksForErrors = {
   getPhoneError: (error, backError, phoneErrorMsg) => {
      if (error === "phoneNumber") {
         return `Phone number ${ErrorText.isRequired}`;
      } else if (
         (
         backError?.error?.[0] === "phoneNumber must be a valid phone number") ||
         backError?.error?.[0] === "phone must be a valid phone number" ||
         error === ErrorText.phoneError
      ) {
         return ErrorText.phoneError;
      } else if (error === phoneErrorMsg) {
         return phoneErrorMsg;
      } else {
         return "";
      }
   },

   getEmailError: (error, backError, emailErrorMsg) => {
      if (error === "email") {
         return 'Valid email address is required';
      } else if (error === 'Staff already exists') {
         return 'Staff already exists';
      } else if ((backError?.error === "User already exists") || error === ErrorText.existenceError("Staff with this email")) {
         return ErrorText.existenceError("Staff with this email");
      } else if(backError?.error === 'Funder already exists'){
         return 'Funder already exists'
      } else if (error === emailErrorMsg) {
         return emailErrorMsg;
      }  else {
         return "";
      }
   },

   getEnrollmentErrorText: (error, backError) => {
      if (error === "funding") {
         return 'Input is not field';
         // return ErrorText.selectField;
      } else if (
         backError?.error === "Can not be two active enrollment"
      ) {
         return ErrorText.enrollmentError;
      } else if (
         backError?.error ===
            "Can not set primary because enrollment have a termination date"
      ) {
         return ErrorText.enrollmentPrimaryError;
      } else {
         return "";
      }
   },

   getRoleNameErrorText: (error, backError) => {
      if (error === "role") {
         return ErrorText.field;
      } else if (
         backError?.error === "A role with this title already exists"
      ) {
         return ErrorText.existenceError("A role with this title");
      } else {
         return "";
      }
   },

   getAuthServiceDefaultErrorText: (error, backError) => {
      if (error === "modifiersPost") {
         return ErrorText.availableModifierError;
      } else if (
         backError?.error ===
            "Can not be two default modifiers with same funding service"
      ) {
         return ErrorText.authServiceDefaultError;
      } else {
         return "";
      }
   },

   getModifierNameErrorText: (error, backError) => {
      if (error === "name") {
         return ErrorText.field;
      } else if (backError?.error === "Modifier already exists") {
         return ErrorText.existenceError("Modifier with this name");
      } else {
         return "";
      }
   },

   getPaycodeActiveErrorText: (error, backError) => {
      if (error === "payCodeTypeId") {
         return ErrorText.selectField;
      } else if (
         backError?.error === "Can not be two active payCodes with same employment"
      ) {
         return ErrorText.paycodeActiveError;
      } else {
         return "";
      }
   },

   getMileageCompensationErrorText: (error, backError) => {
      if (error === "compensation") {
         return 'Mileage compensation is required and must be a number.';
      } else if (error === "can not update the mileage because mileage was terminated") {
         return "Can not update the mileage because mileage was terminated";
      } else if (backError?.error === "compensation error here") {
         return ErrorText.mileageCompensationError;
      } else if (error === "canNot") {
         return "Can not be two active mileage";
      } else {
         return "";
      }
   },
};
