import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {httpRequestsOnSuccessActions, systemActions} from "store";
import {DeleteElement, SimpleModal} from "components";
import {FindLoad, FindSuccess, Images} from "utils";
import {SystemItemAddService} from "./modals";
import {systemItemStyles} from "./styles";

export const SystemItemHeader = ({
                                     deletedName,
                                     modalInformation,
                                     handleDeletedOpenClose,
                                     deletedId,
                                     deleteModalOpened,
                                     modalType,
                                     open,
                                     handleOpenClose,
                                 }) => {
    const dispatch = useDispatch();
    const classes = systemItemStyles();

    const deleteItem = () => {
        if (modalType === "editService") {
            dispatch(systemActions.deleteServiceByIdGlobal(deletedId));
        } else if (modalType === "editCredential") {
            dispatch(systemActions.deleteCredentialByIdGlobal(deletedId));
        } else if (modalType === "editDepartment") {
            dispatch(systemActions.deleteDepartmentByIdGlobal(deletedId));
        } else if (modalType === "editJobTitles") {
            dispatch(systemActions.deleteJobByIdGlobal(deletedId));
        } else if (modalType === "editPlaceTitles") {
            dispatch(systemActions.deletePlaceByIdGlobal(deletedId));
        }
    };

    const ACTION_TYPE =
        modalType === "editService" ? 'DELETE_SERVICE_BY_ID_GLOBAL' :
            modalType === "editCredential" ? 'DELETE_CREDENTIAL_BY_ID_GLOBAL' :
                modalType === "editDepartment" ? 'DELETE_DEPARTMENT_BY_ID_GLOBAL' :
                    modalType === "editJobTitles" ? 'DELETE_JOB_BY_ID_GLOBAL' :
                        modalType === "editPlaceTitles" ? 'DELETE_PLACE_BY_ID_GLOBAL' : ''

    const success = FindSuccess(ACTION_TYPE)
    const loader = FindLoad(ACTION_TYPE)

    useEffect(() => {
        handleDeletedOpenClose();
        if (success?.length) {
            dispatch(
                httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE)
            );
        }
    }, [success]);

    return (
        <div className={[`${classes.systemHeaderStyles} ${classes.spaceBottom}`]}>
            <div className={classes.systemHeaderStyles}>
                <img src={Images.systemIcon} className={classes.systemIcon} alt="founding"/>
                <p className={classes.systemTitle}>System</p>
            </div>
            <SimpleModal
                openDefault={open}
                handleOpenClose={handleOpenClose}
                content={
                    <SystemItemAddService
                        modalInformation={modalInformation}
                        modalType={modalType}
                        handleClose={handleOpenClose}
                    />
                }
                secondary={true}
            />
            <SimpleModal
                openDefault={deleteModalOpened}
                handleOpenClose={handleDeletedOpenClose}
                content={
                    <DeleteElement
                        loader={!!loader?.length}
                        text="some information"
                        info={deletedName}
                        handleDel={deleteItem}
                        handleClose={handleDeletedOpenClose}
                    />
                }
                secondary={true}
            />
        </div>
    );
};
