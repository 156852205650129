import axios from 'axios';

export const payrollService = {
    /** PayCode types */
    createPayCodeGlobalService: (body) => axios.post(`/payCode`, body, {auth:true}),

    getPayCodeGlobalService: (info) => axios.get(`/payCode`, {auth:true, params: { ...info }}),

    editPayCodeByIdGlobalService: (id, body) => axios.patch(`/payCode/${id}`, body, {auth:true}),

    deletePayCodeByIdService: (id) => axios.delete(`/payCode/${id}`, {auth:true}),
    /** End */

    /** Overtime Settings */
    createOvertimeSettingsGlobalService: (body) => axios.post(`/overtime`, body, {auth:true}),

    getOvertimeSettingsGlobalService: (info) => axios.get(`/overtime`, {auth:true, params:{ ...info }}),

    editOvertimeSettingsByIdGlobalService: (id, body) => axios.patch(`/overtime/${id}`, body, {auth:true}),

    deleteOvertimeSettingsByIdService: (id) => axios.delete(`/overtime/${id}`, {auth:true}),
    /** End */

    /** Mileage Compensation*/
    createMileageService: (body) => axios.post("/mileage", body, { auth: true }),

    editMileageService: (id, body) => axios.patch(`/mileage/${id}`, body, { auth: true }),

    getMileagesService: () => axios.get("/mileage", { auth: true }),

    deleteMileageService: (id) => axios.delete(`/mileage/${id}`, { auth: true }),

    terminateMileageService: (mileageId) => axios.patch(`/mileage/${mileageId}/terminate`, null, { auth: true }),
    /** End */

};