import { LandingSvg } from 'assets';

export const aboutUsCard = [
    {
        Icon: LandingSvg.OurBeginningsSvg,
        title: 'Our Beginnings',
        description: 'TherapyLake was founded by a team of ABA professionals and tech experts who recognized the need ' +
            'for a comprehensive, user-friendly practice management solution',
    },
    {
        Icon: LandingSvg.GrowthSvg,
        title: 'Growth and Innovation',
        description: 'From our humble beginnings, we have continuously evolved, incorporating the latest technology to' +
            ' meet the ever-changing needs of ABA practices',
    },
];

export const coreValuesCard = [
    {
        Icon: LandingSvg.NetworkSvg,
        title: 'Client-Centered',
        description: 'We prioritize the needs of our clients, ensuring our solutions are tailored to meet their ' +
            'specific challenges',
    },
    {
        Icon: LandingSvg.InnovationSvg,
        title: 'Innovation',
        description: 'We continuously innovate, integrating advanced technologies to enhance our platform’s capabilities',
    },
    {
        Icon: LandingSvg.IntegritySvg,
        title: 'Integrity',
        description: 'We uphold the highest standards of data security and compliance, ensuring our clients\' trust and confidentiality',
    },
    {
        Icon: LandingSvg.CollaborationSvg,
        title: 'Collaboration',
        description: 'We believe in the power of teamwork, both within our company and with our clients, to achieve the best',
    },
];