import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { adminActions } from 'store';
import { CheckboxesTags } from 'components';
import { EndDateSelector } from './selector';
import { Images, renderStaffName } from 'utils';

export const ProcessModalFilter = ({filters, setFilters}) => {
    const dispatch = useDispatch();
    const staff = useSelector((state) => state.admins.adminsList);
    const info = filters

    useEffect(() => {
        dispatch(adminActions.getAdmins({ status: 'ACTIVE' }));
    }, []);

    const handleSelectValue = (list) => {
        const pushInfo = {
            ...info,
        };
        list?.length ? pushInfo.staffs = list : delete pushInfo.staffs;
        setFilters({ ...pushInfo });
    };

    return (
        <div className="generate-claim-filter-section" style={{alignItems:'flex-end'}}>
            <CheckboxesTags
                handleChange={handleSelectValue}
                name="staffs"
                renderValue={(i) => renderStaffName(i)}
                value={info?.staffs ? info?.staffs : []}
                permissionsList={staff?.staffs || []}
                outLabel={'Staff'}
                placeholder={info?.staffs?.length ? info?.staffs?.map((i) => `${i?.firstName ? i?.firstName : ''} ${i?.lastName ? i?.lastName : ''}`).join(', ') : 'All'}
                uiType={'tableFilter'}
                notShowError={true}
            />
            <EndDateSelector
                setFilters={setFilters}
                filters={filters}
            />
            <div className='generate-information-wrapper'>
                <img src={Images.generateInfoIcon} alt="icon" />
                <p>Please select a past Sunday. The date must be in the past to ensure accurate payroll processing.</p>
            </div>
        </div>
    );
};
