export const filterFromTable = (e, nameRow, list) => {
    const fullName = (el) => {

        return `${el?.firstName} ${el?.lastName ? el?.lastName : ''}`;
    };

    return (
        nameRow ?
            nameRow  === 'firstName' ?
            list?.filter((el) => el[e.target.name] && el[e.target.name][nameRow] && fullName(el[e.target.name]).toString().toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1)
            :
                list?.filter((el) => el[e.target.name] && el[e.target.name][nameRow] && el[e.target.name][nameRow].toString().toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1)
            :
            e?.target.name === 'firstName' ?
                list?.filter((el) => fullName(el) && fullName(el).toString().toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1)
                :
                list?.filter((el) => el[e.target.name] && el[e.target.name].toString().toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1)
    );
};
