import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AddButtonLight } from 'components';
import { PaycodeModal } from './modals';
import { adminActions, payrollActions } from 'store';
import { serviceSingleStyles } from 'fragments/client/clientSingle/core';
import { StaffEmpPaycodes } from './common';
import { PermissionList, RolePermission, useModal } from 'utils';

export const StaffEmployment = ({}) => {
    const classes = serviceSingleStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const { open } = useModal();
    const payCodes = useSelector((state) => state.admins.payCodes);

    useEffect(() => {
        dispatch(payrollActions.getPayCodeGlobal());
        dispatch(adminActions.getPayCode(params?.id));
    }, []);

    return (
        <div className={classes.staffGeneralWrapper}>
            <div className={classes.notesWrap}>
                <div className={classes.authorizationServices}>
                    <p className={classes.authorizationServicesTitle}>Paycodes</p>
                    {RolePermission([PermissionList.STAFF_PAYCODE_MANAGE?.code]) ?
                        <AddButtonLight
                            addButnLightClassName={classes.addAuthServiceButnStyle}
                            addButnLightInnerText={'Add Paycode'}
                            onAddButnLightClick={() => open(<PaycodeModal  payCodes={payCodes} />)}
                        />
                        :
                        <div />
                    }
                </div>
                <StaffEmpPaycodes payCodes={payCodes} />
            </div>
        </div>
    );
};
