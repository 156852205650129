import React, { createContext, useState, Fragment, useContext, useEffect } from 'react';
import { CustomModal } from '../../components/modal/modalBase';
import { CloseButton } from '../../components';

export const SecondaryModalContext = createContext();


export const SecondaryModalProvider = ({ children }) => {
    const [activeModal, setActiveModal] = useState('');
    const [params, setParams] = useState({});

    return (
        <Fragment>
            <SecondaryModalContext.Provider
                value={{
                    activeModal,
                    setActiveModal,
                    params,
                    setParams,
                }}>

                {children}
            </SecondaryModalContext.Provider>
        </Fragment>
    );
};

export const useSecondaryModal = () => {
    const { setActiveModal, params, setParams } = useContext(SecondaryModalContext);

    return {
        openSecondary: (params = {}) => {
            setActiveModal(true);
            setParams(params);
        },
        closeSecondary: () => {
            setActiveModal('');
            setParams({});
        },
        params,
    };
};


export const SecondaryModals = () => {
    const { activeModal } = useContext(SecondaryModalContext);
    const { closeSecondary, params } = useSecondaryModal();

    useEffect(() => {
        if (activeModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [activeModal]);

    return (
        <CustomModal
            handleOpenClose={closeSecondary}
            openDefault={activeModal ? activeModal : false}
            content={
                <div style={{ outline: 'none' }} className="modal-wrapper-style open-sans">
                    <div className="modal-header-style">
                        <CloseButton handleCLic={closeSecondary} />
                    </div>
                    <div className="modal-body-style-small">
                        {params}
                    </div>
                </div>
            }
        />
    );
};