import React from 'react';
import { DateRow, LinkRow, TextRow } from 'components';
import { Images, PermissionList, RolePermission } from 'utils';

export const historyHead = [
    { name: '', title: 'Date & Time', iconType: 'date', noSearch: true, width: '180px' },
    { name: 'staffName', title: 'Staff Name', custom: false, noSearch: true, width: '200px' },
    { name: 'desc', title: 'Action Description', custom: false, noSearch: true },
];

function containsSSN(text) {
    const ssnPattern = /\b\d{3}-\d{2}-\d{4}\b/;
    return ssnPattern.test(text);
}

function maskSSN(text) {
    const ssnPattern = /\b\d{3}-\d{2}-\d{4}\b/g;
    return text.replace(ssnPattern, '**********');
}

export const historyBody = [
    {
        rowText: (item) => <div className={'flex'}><DateRow date={item?.createdDate} /> {item?.time &&
            <p style={{ marginLeft: '4px', whiteSpace:'nowrap' }}>{item?.time}</p>} </div>,
    },
    {
        rowText: (item) => <LinkRow
            name={item?.user ? `${item?.user?.firstName} ${item?.user?.lastName ? item?.user?.lastName : ''}` : 'N/A'}
            href={RolePermission([PermissionList.STAFF_READ.code]) ? `/staff/${item?.user?.id || item?.user?._id}` : ''}
            checkPermission={item?.user ? RolePermission([PermissionList.STAFF_READ.code]) : null}
        />,
    },
    { rowText: (item) => <TextRow name={containsSSN(item?.title) ? maskSSN(item?.title) : item?.title} textWidth={7} /> },
];

export const ACTION_TYPE = 'GET_HISTORY';

export const historyNotYet = {
    title: 'No History Available',
    subTitle: 'All actions and updates will appear here.',
    image: Images.noHistory,
}
