import {cardStyle} from "./style";
import {Images, useGlobalStyles} from "utils";

export const CardHeader = ({color, title, icon, hideHeaderLine, edit, handleEdit, cardInfo, checkPermission, addEmployment}) => {
    const classes = cardStyle();
    const global = useGlobalStyles()
    return (
        <div className={classes.cardHeader}>
            <div className={global.spaceBetween}>
                <div className={global.centerItem}>
                    {icon &&
                    <div
                        className={classes.cardIcon}
                        style={{backgroundColor: color ? color : "gray"}}
                    >
                        <img src={icon} alt="grayIcon"/>
                    </div>
                    }
                    <span className={classes.cardTitle}>{title}</span>
                </div>

                    <div className={global.centerItem}>
                        {addEmployment && checkPermission &&
                            <button
                                style={{marginRight:'4px'}}
                                className={classes.editButton}
                                onClick={() => handleEdit('createEmployment')}
                            >
                                <img src={Images.addIcon} alt={"icon"}/>
                            </button>
                        }

                        {edit && cardInfo?.length && checkPermission ?
                        <button
                            onClick={handleEdit}
                            className={classes.editButton}
                        >
                            <img src={Images.edit} alt={'edit'}/>
                        </button>
                            :''
                        }
                    </div>

            </div>
            {hideHeaderLine && (
                <span
                    className={classes.topLine}
                    style={{backgroundColor: color ? color : "gray"}}
                />
            )}
        </div>
    );
};
