import axios from "axios";

export const invoicePaymentService = {
   getInvoicePaymentsService: (data) =>
      axios.get("/invoice", { auth: true, params: { ...data } }),

   getInvoicePaymentByIdService: (id) => axios.get(`/invoice-pmt/${id}`, { auth: true }),

   createInvoicePaymentService: (body) =>
      axios.post("/invoice-pmt", body, { auth: true }),

   editInvoicePaymentService: (id, body) =>
      axios.patch(`/invoice-pmt/${id}`, body, { auth: true }),

   deleteInvoicePaymentService: (id) =>
      axios.delete(`/invoice-pmt/${id}`, { auth: true }),

   editInvoicePaymentStatusService: (id, status, details) =>
      axios.patch(
         `/invoice-pmt/${id}/setStatus?status=${status}?details=${details}`,
         {},
         { auth: true }
      ),

   addInvoiceInInvoicePaymentService: (id, body) =>
      axios.post(`/invoice-pmt/${id}/payment`, body, { auth: true }),

   appendFilesToInvoicePaymentService: (id, body) =>
      axios.post(`/invoice-pmt/${id}/documents`, body, { auth: true }),

   removeFilesFromInvoicePaymentService: (id, docId) =>
      axios.delete(`/invoice-pmt/${id}/documents/${docId}`, {
         auth: true,
      }),

   editFileNameOfInvoicePaymentService: (id, docId, fileName) =>
      axios.patch(`/invoice-pmt/${id}/documents/${docId}/?name=${fileName}`, null, {
         auth: true,
      }),


   editPaymentInvoiceService: (id, body) => axios.patch(`/invoice-pmt/${id}`, body , {auth: true,}),

   payInFullInvoiceService: (id, body) => axios.post(`/invoice-pmt/${id}/pmt/pay`, body , {auth: true,}),
};
