import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FullTable } from 'components';
import { historyActions } from 'store';
import moment from 'moment';
import { historyBody, historyHead, ACTION_TYPE, historyNotYet } from './constants';

export const HistoryTab = ({ onModel, currentId, height, notYet }) => {
    const dispatch = useDispatch();
    const historyList = useSelector((state) => state.history.history);
    const history = useHistory();
    const info = history?.location?.state;

    useEffect(() => {
        const params = {
            ...info,
        };
        delete params.page
        delete params.activeTab
        delete params.from
        params.skip = info?.skip ? info?.skip : 0;
        params.limit = 50;
        dispatch(historyActions.getHistory(onModel, { onResource: currentId, ...params}));
    }, [info]);

    return (
        <div>
            <FullTable
                head={historyHead}
                body={historyBody}
                loadingType={ACTION_TYPE}
                list={historyList?.histories}
                listCount={historyList?.count}
                notYet={notYet === 'notShow' ? null : historyNotYet}
                noText={notYet === 'notShow' ? 'History' : ''}
                activeRowId={''}
                margin={'0'}
                height={height}
            />
        </div>
    );
};