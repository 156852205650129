import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FullTable, SelectActions, SimpleModal } from 'components';
import { FindSuccessItem, FLAGGED_VALUES, Images, PermissionList, RolePermission, SaveParams } from 'utils';
import { adminActions, appointmentActions, claimActions, httpRequestsOnSuccessActions, systemActions } from 'store';
import { scheduleModalsStyle, Service } from '../../../fragments/schedule';
import { BILLABLE_ACTION_TYPE, claimTabEnums, generateClaimBody, generateClaimHead } from './constants';

const EDIT_ACTION = 'EDIT_APPOINTMENT';
const GENERATE_ACTION = 'GENERATE_CLAIM';
export const BillableAppt = ({}) => {
    const { appointments } = useSelector((state) => ({
        appointments: state.appointment.appointments,
    }));
    const dispatch = useDispatch();
    const modalClasses = scheduleModalsStyle();
    const success = FindSuccessItem(EDIT_ACTION);
    const generateActon = FindSuccessItem(GENERATE_ACTION);
    const [selectedList, setSelectedList] = useState([]);
    const [item, setItem] = useState(null);
    const history = useHistory();
    const pushInfo = history?.location?.state;

    useEffect(() => {
        if (success) {
            getAppt('noLoad');
            dispatch(httpRequestsOnSuccessActions.removeSuccess(EDIT_ACTION));
            setItem(null);
        }
        if(generateActon) {
            getAppt();
            setSelectedList([]);
            dispatch(httpRequestsOnSuccessActions.removeSuccess(GENERATE_ACTION));
            SaveParams(history, { ...pushInfo, tabType: claimTabEnums?.PENDING });
        }
    }, [success, generateActon]);

    useEffect(() => {
        getAppt();
    }, [pushInfo]);

    const getAppt = (load) => {
        const filterInfo = {
            status: 'COMPLETED',
            type: 'SERVICE',
            billStatus: 'NOT_BILLED',
            ...pushInfo,
        };

        pushInfo?.orderType ? filterInfo.orderType = pushInfo?.orderType : delete filterInfo.orderType;
        pushInfo?.sortType ? filterInfo.sortType = pushInfo?.sortType : delete filterInfo.sortType;

        if (pushInfo?.start) {
            filterInfo.startDate = moment.utc(pushInfo?.start).format('YYYY-MM-DD');
            delete filterInfo.start;
        } else {
            delete filterInfo.start;
        }
        if (pushInfo?.end) {
            filterInfo.endDate = moment.utc(pushInfo?.end).format('YYYY-MM-DD');
            delete filterInfo.end;
        } else {
            delete filterInfo.end;
        }

        if (pushInfo?.flag) {
            if (pushInfo?.flag === FLAGGED_VALUES.REMOVED_FROM_CLAIM) {
                filterInfo.removedFromClaimFlag = true;
            } else {
                filterInfo.incompleteUnitFlag = true;
            }
        } else {
            delete filterInfo.flag;
            delete filterInfo.removedFromClaimFlag;
            delete filterInfo.incompleteUnitFlag;
        }
        dispatch(appointmentActions.getAppointment(filterInfo, load));
    };

    const handleCheck = (ev, item, name) => {
        ev.stopPropagation();
        ev.preventDefault();
        if(name === 'selectAll') {
            let allList = appointments?.appts?.map((i) => i?.id);
            setSelectedList(allList);
        }else {
            if (name === 'all') {
                let allList = appointments?.appts?.length === selectedList?.length ? [] : appointments?.appts?.map((i) => i?.id);
                setSelectedList(allList);
            } else {
                const list = [...selectedList];
                const uniqSet = new Set(list);

                if (!uniqSet.has(item?.id)) {
                    list.push(item?.id);
                } else {
                    list.indexOf(item?.id) !== -1 && list.splice(list.indexOf(item?.id), 1);
                }
                setSelectedList(list);
            }
        }
    };
    const handleEdit = (item) => {
        setItem(item);
        dispatch(adminActions.getAllPayCodes(item.staff?._id));
        dispatch(systemActions.getPlaces());
    };

    const removeSelectedList = () => {
        setSelectedList([]);
    };


    return (
        <>
            <div>
                {
                    RolePermission([PermissionList.CLAIMS_CREATE?.code]) && !!selectedList?.length &&
                    <SelectActions
                        submitText={'Prepare Claims'}
                        submitBtnIcon={Images.addCircle}
                        actionType={GENERATE_ACTION}
                        selectedList={selectedList}
                        selectAll={handleCheck}
                        submitClaims={() => dispatch(claimActions.generateClaim({ appts: selectedList }))}
                        removeSelected={removeSelectedList}
                    />
                }
                <FullTable
                    head={generateClaimHead(handleCheck, appointments?.appts, selectedList)}
                    body={generateClaimBody(handleCheck, appointments?.appts, selectedList, handleEdit)}
                    loadingType={BILLABLE_ACTION_TYPE}
                    list={appointments?.appts}
                    listCount={appointments?.count}
                    noText={'appointments'}
                />
            </div>

            <SimpleModal
                handleOpenClose={() => setItem(null)}
                openDefault={!!item}
                content={
                    <div className={modalClasses.bigModal}>
                        <Service
                            modalDate={item}
                            handleOpenClose={() => setItem(null)}
                        />
                    </div>
                }
                secondary={true}
            />
        </>
    );
};
