import {
    CREATE_CREDENTIAL_GLOBAL,
    GET_CREDENTIAL_GLOBAL,
    EDIT_CREDENTIAL_BY_ID_GLOBAL,
    CREATE_SERVICE_GLOBAL,
    GET_SERVICES,
    EDIT_SERVICE_BY_ID_GLOBAL,
    DELETE_SERVICE_BY_ID_GLOBAL,
    DELETE_CREDENTIAL_BY_ID_GLOBAL,
    GET_DEPARTMENTS,
    CREATE_DEPARTMENT_GLOBAL,
    EDIT_DEPARTMENT_BY_ID_GLOBAL,
    DELETE_DEPARTMENT_BY_ID_GLOBAL,
    CREATE_JOB_GLOBAL,
    GET_JOBS,
    EDIT_JOB_BY_ID_GLOBAL,
    DELETE_JOB_BY_ID_GLOBAL,
    CREATE_PLACE_GLOBAL,
    GET_PLACES,
    EDIT_PLACE_BY_ID_GLOBAL,
    DELETE_PLACE_BY_ID_GLOBAL,
    CREATE_COVERED_CREDENTIAL,
    EDIT_COVERED_CREDENTIAL,
    SELECTED_CREDENTIAL,
    REMOVE_SELECTED_CREDENTIAL,
    CREATE_COMPANY,
    GET_COMPANY,
    EDIT_COMPANY,
    DELETE_COMPANY,
    GET_CONNECT_CALENDARS,
    DISCONNECT_CALENDAR,
    CHANGE_CALENDAR_STATUS,
    GET_OFFICE_ALLY,
    CONNECT_OFFICE_ALLY,
    DISCONNECT_OFFICE_ALLY,
    EDIT_OFFICE_ALLY_CONNECTION,
} from './system.type';


/** Service Type */

export const createServiceGlobal = (body) => {
    return {
        type: CREATE_SERVICE_GLOBAL,
        payload: { body },
    };
};

export const getServices = (data, load) => {
    return {
        type: GET_SERVICES,
        payload: { data, load },
    };
};

export const editServiceByIdGlobal = (body, id) => {
    return {
        type: EDIT_SERVICE_BY_ID_GLOBAL,
        payload: { body, id },
    };
};

export const deleteServiceByIdGlobal = (id) => {
    return {
        type: DELETE_SERVICE_BY_ID_GLOBAL,
        payload: { id },
    };
};

/** End */

/** Credentials */

export const createCredentialGlobal = (body) => {
    return {
        type: CREATE_CREDENTIAL_GLOBAL,
        payload: { body },
    };
};

export const createCoveredCredential = (id, body) => {
    return {
        type: CREATE_COVERED_CREDENTIAL,
        payload: { id, body },
    };
};

export const getCredentialGlobal = () => {
    return {
        type: GET_CREDENTIAL_GLOBAL,
    };
};

export const editCredentialByIdGlobal = (body, id) => {
    return {
        type: EDIT_CREDENTIAL_BY_ID_GLOBAL,
        payload: { body, id },
    };
};

export const editCoveredCredential = (credentialId, id, body) => {
    return {
        type: EDIT_COVERED_CREDENTIAL,
        payload: { credentialId, id, body },
    };
};

export const deleteCredentialByIdGlobal = (id) => {
    return {
        type: DELETE_CREDENTIAL_BY_ID_GLOBAL,
        payload: { id },
    };
};

export const selectCredential = (item) => {
    return {
        type: SELECTED_CREDENTIAL,
        payload: item,
    };
};

export const removeCredential = (item) => {
    return {
        type: REMOVE_SELECTED_CREDENTIAL,
        payload: { item },
    };
};
/** End */

/** Departments */

export const createDepartmentGlobal = (body) => {
    return {
        type: CREATE_DEPARTMENT_GLOBAL,
        payload: { body },
    };
};

export const getDepartments = () => {
    return {
        type: GET_DEPARTMENTS,
    };
};

export const editDepartmentByIdGlobal = (body, id) => {
    return {
        type: EDIT_DEPARTMENT_BY_ID_GLOBAL,
        payload: { body, id },
    };
};

export const deleteDepartmentByIdGlobal = (id) => {
    return {
        type: DELETE_DEPARTMENT_BY_ID_GLOBAL,
        payload: { id },
    };
};

/** End */

/** Job */

export const createJobGlobal = (body) => {
    return {
        type: CREATE_JOB_GLOBAL,
        payload: { body },
    };
};

export const getJobs = () => {
    return {
        type: GET_JOBS,
    };
};

export const editJobByIdGlobal = (body, id) => {
    return {
        type: EDIT_JOB_BY_ID_GLOBAL,
        payload: { body, id },
    };
};

export const deleteJobByIdGlobal = (id) => {
    return {
        type: DELETE_JOB_BY_ID_GLOBAL,
        payload: { id },
    };
};

/** End */

/** Place */

export const createPlaceGlobal = (body) => {
    return {
        type: CREATE_PLACE_GLOBAL,
        payload: { body },
    };
};

export const getPlaces = () => {
    return {
        type: GET_PLACES,
    };
};

export const editPlaceByIdGlobal = (body, id) => {
    return {
        type: EDIT_PLACE_BY_ID_GLOBAL,
        payload: { body, id },
    };
};

export const deletePlaceByIdGlobal = (id) => {
    return {
        type: DELETE_PLACE_BY_ID_GLOBAL,
        payload: { id },
    };
};

/** End */


/** Company */
export const createCompany = (params) => {
    return {
        type: CREATE_COMPANY,
        payload: { params },
    };
};

export const getCompany = () => {
    return {
        type: GET_COMPANY,
    };
};

export const editCompany = (id, params) => {
    return {
        type: EDIT_COMPANY,
        payload: { id, params },
    };
};

export const deleteCompany = (id) => {
    return {
        type: DELETE_COMPANY,
        payload: { id },
    };
};

/** End */

/** Calendars */

export const getConnectCalendars = (id) => {
    return {
        type: GET_CONNECT_CALENDARS,
        payload: { id },
    };
};

export const disconnectCalendar = (params) => {
    return {
        type: DISCONNECT_CALENDAR,
        payload: params,
    };
};

export const calendarStatus = (params) => {
    return {
        type: CHANGE_CALENDAR_STATUS,
        payload: params,
    };
};

/** End */


/** Office Ally */

export const getOfficeAlly = () => {
    return {
        type: GET_OFFICE_ALLY,
    };
};

export const connectOfficeAlly = (params) => {
    return {
        type: CONNECT_OFFICE_ALLY,
        payload: params,
    };
};

export const disconnectOfficeAlly = () => {
    return {
        type: DISCONNECT_OFFICE_ALLY,
    };
};

export const editOfficeAlly = (params) => {
    return {
        type: EDIT_OFFICE_ALLY_CONNECTION,
        payload: params,
    };
};
/** End */