export const makeCapitalize = (value = "") => {
   if (typeof value !== "string") return value;

   return value.includes("_")
      ? value
           .split("_")
           .map(
              (el) => el.slice(0, 1).toUpperCase() + el.slice(1).toLowerCase()
           )
           .join(" ")
      : value
           .split(" ")
           .map(
              (el) => el.slice(0, 1).toUpperCase() + el.slice(1).toLowerCase()
           )
           .join(" ");
};

export const textResizer = ( text ) => {
    if(text) {
        const toString = text?.toString()
        if (toString?.indexOf('.') !== -1) {
            return toString.slice(0, toString.indexOf('.') + 2)
        } else {
            return toString
        }
    }
}
