import { call, put, takeLatest } from "redux-saga/effects";
import { payrollService } from "./payroll.service";
import {
   GET_PAY_CODE_GLOBAL_SUCCESS,
   CREATE_PAY_CODE_GLOBAL,
   GET_PAY_CODE_GLOBAL,
   EDIT_PAY_CODE_BY_ID_GLOBAL,
   DELETE_PAY_CODE_BY_ID_GLOBAL,
   GET_OVERTIME_SETTINGS_GLOBAL,
   GET_OVERTIME_SETTINGS_GLOBAL_SUCCESS,
   CREATE_OVERTIME_SETTINGS_GLOBAL,
   EDIT_OVERTIME_SETTINGS_BY_ID_GLOBAL,
   DELETE_OVERTIME_SETTINGS_BY_ID_GLOBAL,
   GET_MILEAGES,
   GET_MILEAGES_SUCCESS, CREATE_MILEAGE, EDIT_MILEAGE, DELETE_MILEAGE, TERMINATE_MILEAGE,
} from "./payroll.type";
import { httpRequestsOnLoadActions } from "../http_requests_on_load";
import { httpRequestsOnSuccessActions } from "../http_requests_on_success";
import { httpRequestsOnErrorsActions } from "../http_requests_on_errors";

/** PayCode types */

function* createPayCodeGlobal(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(payrollService.createPayCodeGlobalService, action.payload.body);
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* getPayCodeGlobal(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   try {
      const res = yield call(payrollService.getPayCodeGlobalService, action?.payload?.params);
      yield put({
         type: GET_PAY_CODE_GLOBAL_SUCCESS,
         payload: res.data.reverse(),
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      if (err?.data?.message === "Internal server error") {
         yield put(
            httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message)
         );
      }
   }
}

function* editPayCodeById(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(payrollService.editPayCodeByIdGlobalService, action.payload.id, action.payload.body);
      yield put({
         type: GET_PAY_CODE_GLOBAL,
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* deletePayCodeByIdGlobal(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(payrollService.deletePayCodeByIdService, action.payload.id);
      yield put({
         type: GET_PAY_CODE_GLOBAL,
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

/** End */

/** Overtime Settings */

function* createOvertimeSettingsGlobal(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(payrollService.createOvertimeSettingsGlobalService, action.payload.body);
      yield put({
         type: GET_OVERTIME_SETTINGS_GLOBAL,
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* getOvertimeSettingsGlobal(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   try {
      const res = yield call(payrollService.getOvertimeSettingsGlobalService, action?.payload?.params);
      yield put({
         type: GET_OVERTIME_SETTINGS_GLOBAL_SUCCESS,
         payload: res.data.reverse(),
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      if (err?.data?.message === "Internal server error") {
         yield put(
            httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message)
         );
      }
   }
}

function* editOvertimeSettingsById(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(payrollService.editOvertimeSettingsByIdGlobalService, action.payload.id, action.payload.body);
      yield put({
         type: GET_OVERTIME_SETTINGS_GLOBAL,
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* deleteOvertimeSettingsByIdGlobal(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(payrollService.deleteOvertimeSettingsByIdService, action.payload.id);
      yield put({
         type: GET_OVERTIME_SETTINGS_GLOBAL,
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

/** End */

/** Mileage Compensation*/

function* createMileage({ payload, type }) {
   yield put(httpRequestsOnErrorsActions.removeError(type));
   yield put(httpRequestsOnLoadActions.appendLoading(type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(type));
   try {
      yield call(payrollService.createMileageService, payload.body);
      yield put({
         type: GET_MILEAGES,
         payload: { load:'noLoad' }
      });
      yield put(httpRequestsOnLoadActions.removeLoading(type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(type));
      yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
   }
}

function* editMileage({ payload, type }) {
   yield put(httpRequestsOnErrorsActions.removeError(type));
   yield put(httpRequestsOnLoadActions.appendLoading(type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(type));
   try {
      yield call(payrollService.editMileageService, payload.id, payload.body);
      yield put({
         type: GET_MILEAGES,
         payload: { load:'noLoad' }
      });
      yield put(httpRequestsOnLoadActions.removeLoading(type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(type));
      yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
   }
}

function* getMileages({ type, payload }) {
   if(payload?.load !== 'noLoad'){
      yield put(httpRequestsOnLoadActions.appendLoading(type));
   }
   yield put(httpRequestsOnErrorsActions.removeError(type));
   try {
      const res = yield call(payrollService.getMileagesService);
      yield put({
         type: GET_MILEAGES_SUCCESS,
         payload: res.data,
      });
      yield put(httpRequestsOnLoadActions.removeLoading(type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(type));
      if (err?.data?.message === "Internal server error") {
         yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
      }
   }
}

function* deleteMileages(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(payrollService.deleteMileageService, action.payload.id);
      yield put({
         type: GET_MILEAGES
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* terminateMileage(action) {
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(payrollService.terminateMileageService, action.payload.mileageId);
      yield put({
         type: GET_MILEAGES,
         payload: { load:'noLoad' }
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}
/** End */

export const watchPayroll = function* watchPayrollSaga() {
   /** PayCode types */
   yield takeLatest(CREATE_PAY_CODE_GLOBAL, createPayCodeGlobal);
   yield takeLatest(GET_PAY_CODE_GLOBAL, getPayCodeGlobal);
   yield takeLatest(EDIT_PAY_CODE_BY_ID_GLOBAL, editPayCodeById);
   yield takeLatest(DELETE_PAY_CODE_BY_ID_GLOBAL, deletePayCodeByIdGlobal);
   /** End */

   /** Overtime Settings */
   yield takeLatest(CREATE_OVERTIME_SETTINGS_GLOBAL, createOvertimeSettingsGlobal);
   yield takeLatest(GET_OVERTIME_SETTINGS_GLOBAL, getOvertimeSettingsGlobal);
   yield takeLatest(EDIT_OVERTIME_SETTINGS_BY_ID_GLOBAL, editOvertimeSettingsById);
   yield takeLatest(DELETE_OVERTIME_SETTINGS_BY_ID_GLOBAL, deleteOvertimeSettingsByIdGlobal);
   /** End */

   /** Mileage Compensation*/
   yield takeLatest(CREATE_MILEAGE, createMileage);
   yield takeLatest(EDIT_MILEAGE, editMileage);
   yield takeLatest(GET_MILEAGES, getMileages);
   yield takeLatest(DELETE_MILEAGE, deleteMileages);
   yield takeLatest(TERMINATE_MILEAGE, terminateMileage);
   /** End */
};
