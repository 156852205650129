import { v4 as uuidv4 } from 'uuid';

export const roleManagementReport = () => {
    return {
        title: 'Role Management',
        url: '/reports/system/roles',
    };
};
export const permissionReport = () => {
    return {
        title: 'Permissions List',
        url: '/reports/system/permissions',
    };
};


export const systemReportEnums = [
    {
        id: uuidv4(),
        name: 'Role Management',
        description: 'Shows roles and their corresponding permissions with permission descriptions.',
        generateInfo: roleManagementReport(),
    },
    {
        id: uuidv4(),
        name: 'Permissions List',
        description: 'Shows all permissions in the system with their corresponding descriptions.',
        generateInfo: permissionReport(),
    },
];