import {TableCell, TableRow} from "@material-ui/core";
import {screensStyle} from "./styles";

export const TableNoInfo = ({ text, flexCenter, styles }) =>{
    const classes = screensStyle()
    return(
        <TableRow
            style={{...styles}}
            className={flexCenter ? classes.noInfoWrapperCenter :  classes.noInfoWrapper} >
            <TableCell className={classes.noInfoText} >
                {text}
            </TableCell>
        </TableRow>
    )
}