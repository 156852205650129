import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "utils";

export const breadcrumbsStyle = makeStyles((theme) => ({
   parent: {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "normal",
      color: '#667085',
   },
   child: {
      fontSize: "14px",
      fontWeight: "600",
      lineHeight: "normal",
      color: '#172B4D',
   },
}));
