import React, { useEffect, useRef } from 'react';
import { InlineWidget } from 'react-calendly';
import { Loader } from 'components';
// import { InlineWidget } from 'react-calendly';
// import {Loader} from "components";

export const MyCalendlyComponent = () => {
    const formContainerRef = useRef(null); // Ref to hold the form container

    // useEffect(() => {
    //     // Dynamically load the HubSpot script
    //     const script = document.createElement("script");
    //     script.src = "//js.hsforms.net/forms/embed/v2.js";
    //     script.type = "text/javascript";
    //     script.charset = "utf-8";
    //
    //     // Once the script loads, initialize the form
    //     script.onload = () => {
    //         if (window.hbspt) {
    //             window.hbspt.forms.create({
    //                 portalId: "48035702", // Replace with your Portal ID
    //                 formId: "41de9b5a-4078-4e1d-940b-7f7d3d3a1cf4", // Replace with your Form ID
    //                 target: `#${formContainerRef.current.id}`, // Target the container
    //             });
    //         }
    //     };
    //
    //     document.body.appendChild(script);
    //
    //     // Cleanup the script on unmount
    //     return () => {
    //         document.body.removeChild(script);
    //     };
    // }, []);


    return (
        <div className='calendly-wrapper'>
            <div id="hubspot-form-container" ref={formContainerRef}></div>
            <InlineWidget
                url="https://calendly.com/therapylake"
                styles={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                    height: '700px',
                    width: '100%',
                    position: 'relative',
                }}
                LoadingSpinner={() => <div style={{
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                    width: '100%',
                    height: '100%',
                }}><Loader styles={{width: 150, height: 150, color: '#347AF0', margin: 'auto'}}/></div>}
                pageSettings={{
                    backgroundColor: '#F8F8F8',
                    hideEventTypeDetails: true,
                    hideProfilePageViewed: true,
                    hideGdprBanner: true,
                    primaryColor: '#347AF0',
                    textColor: '#2A2F35'
                }}
            />
        </div>
    );
};

