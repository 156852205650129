import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FindSuccess, Images, useModal } from 'utils';
import { authActions, httpRequestsOnSuccessActions } from 'store';
import { CheckboxesTags, CustomDelete, NoItemsYet } from 'components';
import { roleStyles } from './styles';
import { RoleItem } from './rollItem';

const ACTION_TYPE = 'REMOVE_ACCESS';

export const Role = ({ accessList = [], sendItem, handleRemoveSelected, newList }) => {
    const { staffGeneral } = useSelector((state) => ({ staffGeneral: state.admins.adminInfoById }));

    const params = useParams();
    const dispatch = useDispatch();
    const classes = roleStyles();
    const [index, setIndex] = useState(null);
    const success = FindSuccess(ACTION_TYPE);
    const { open } = useModal();

    useEffect(() => {
        if (!!success?.length) {
            setIndex(0);
            handleRemoveSelected && handleRemoveSelected();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    const handleOpen = (item, j) => {
        setIndex(j);
        sendItem(item);
    };

    const addPermissions = (item) => {
        if (item?.length) {
            dispatch(authActions.assignAccess(params.id, item[item?.length - 1]?.id));
        }
    };

    return (
        <div className={classes.roleWrapper}>
            <CheckboxesTags
                handleChange={addPermissions}
                permissionsList={newList?.length ? newList : []}
                label={'Add Role'}
                placeholder={'Add Role'}
                notShowError={true}
            />
            <div className={classes.roleItemContainer}>
                {accessList?.roles?.length ? (
                    accessList?.roles.map((item, j) => (
                        <div key={j}>
                            <RoleItem
                                handleOpen={() => handleOpen(item, j)}
                                handleClick={() => open(
                                    <CustomDelete
                                        text="Are you sure you want to delete this role? This action is not reversible."
                                        info="Remove Role"
                                        handleDel={() => dispatch(authActions.removeAccess(staffGeneral?.id, item?.id))}
                                        handleClose={() => close()}
                                        actionType={ACTION_TYPE}
                                        body={
                                            <div className="deleting-client-info-box"
                                                 style={{ alignItems: 'flex-start' }}>
                                                <img style={{ marginTop: '3px' }} src={Images.accessManagementOutline}
                                                     alt="icon" />
                                                <p style={{ fontWeight: 400 }}>{item?.title}</p>
                                            </div>
                                        }
                                    />,
                                )}
                                roleItem={item.title}
                                roleDescription={item?.description}
                                active={index === j}
                            />
                        </div>
                    ))
                ) : (
                    <NoItemsYet
                        text={'No Roles Assigned'}
                        subTitle={'Assign roles to define this staff member’s access and permissions.'}
                        image={Images.noAccess}
                    />
                )}
            </div>
        </div>
    );
};
