import {
    GET_NOTIFICATIONS_SUCCESS,
    GET_UNRENDER_NOTIFICATIONS_SUCCESS,
    REMOVE_NOTIFICATIONS,
} from './notification.types';

const initialState = {
    notifications: [],
    unRenderNotifications: 0,
};

export const notificationReducer = (state = initialState, action) => {
    switch (action.type) {

        case  GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notifications: action.payload,
            };

            case  REMOVE_NOTIFICATIONS:
            return {
                ...state,
                notifications: [],
            };

        case  GET_UNRENDER_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                unRenderNotifications: action.payload,
            };

        default:
            return state;
    }
};
