import React from 'react';
import { Images } from 'utils';
import { roleStyles } from './styles';
import { SlicedText } from '../messages';
import { TextRow } from '../table';

export const RoleItem = ({ roleItem, handleClick, handleOpen, active, roleDescription }) => {
    const classes = roleStyles();

    return (
        <div
            onClick={handleOpen}
            className={active ? classes.roleItemHover : classes.roleItemActive}
        >
            <div className={classes.roleItem}>

                <div style={{ width: '100%' }}>
                    <img src={Images.accessManagementOutline} alt="roleManagement" />
                    <p className={classes.name}>
                        <SlicedText type={'name'} size={20} data={roleItem} />
                    </p>
                    <p className={classes.description}>
                        <TextRow name={roleDescription} textWidth={9} />
                        {/*<SlicedText type={'desc'} size={40} data={roleDescription}/>*/}
                    </p>
                </div>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <img
                        onClick={handleClick}
                        className={classes.removeIcon}
                        src={Images.remove}
                        alt="remove"
                    />
                </div>
            </div>
        </div>
    );
};
