export const renderColor = (type) => {
    switch (type) {
        case '#F8F9FC':
            return {
                background: '#F8F9FC',
                color: '#363F72',
            };
        case '#F9F5FF':
            return {
                background: '#F9F5FF',
                color: '#6941C6',
            };
        case '#EFF8FF':
            return {
                background: '#EFF8FF',
                color: '#175CD3',
            };

        case '#FFF6ED':
            return {
                background: '#FFF6ED',
                color: '#C4320A',
            };
        case '#FFF1F3':
            return {
                background: '#FFF1F3',
                color: '#C01048',
            };
        case '#FDF2FA':
            return {
                background: '#FDF2FA',
                color: '#C11574',
            };
        case '#F4F3FF':
            return {
                background: '#F4F3FF',
                color: '#5925DC',
            };
        case '#CBEAFA':
            return {
                background: '#CBEAFA',
                color: '#0D5C9A',
            };
        case '#E4EDFF':
            return {
                background: '#E4EDFF',
                color: '#1D4C9D',
            };

        default:
            return {
                background: '#F9F5FF',
                color: '#6941C6',
            };
    }
};

export const tagColors = [
    {background: '#F9F5FF', color: '#6941C6'},
    {background: '#EFF8FF', color: '#175CD3'},
    {background: '#F8F9FC', color: '#363F72'},
    {background: '#FFF6ED', color: '#C4320A'},
    {background: '#FFF1F3', color: '#C01048'},
    {background: '#FDF2FA', color: '#C11574'},
    {background: '#F4F3FF', color: '#5925DC'},
    {background: '#CBEAFA', color: '#0D5C9A'},
    {background: '#E4EDFF', color: '#1D4C9D'},
];