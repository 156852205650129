import React from 'react';
import { motion } from 'framer-motion';
import { GoogleAppButton } from 'components';
import { LandingSvg } from 'assets';
import { animationParams, animationVariants } from 'fragments';

export const AboutSomething = (
    {
        img, description, title, blueText, items,
        wrapperClassName, imgClassName, contentClassName, contentWrapperClassName,
        oneLineText = false, googleAppButtons = false, onClickGoogle, onClickApple,
    }) => {
    return (
        <div className={`about-something-wrapper ${wrapperClassName ? wrapperClassName : ''}`}>
            <motion.div
                className={`about-something_img ${imgClassName ? imgClassName : ''}`}
                {...animationParams}
                variants={animationVariants.cardsVariant}
            >
                <img src={img} alt='placeholder' />
            </motion.div>

            <div className={`about-something-content ${contentWrapperClassName ? contentWrapperClassName : ''}`}>
                <p className='blue-text'>
                    {blueText}
                </p>

                <div style={{ overflow: 'hidden' }}>
                    <motion.h3
                        className='about-something_title'
                        {...animationParams}
                        variants={animationVariants.titleVariantMobile}
                    >
                        {title}
                    </motion.h3>
                </div>

                <div style={{ overflow: 'hidden' }}>
                    <motion.p
                        className='about-something_description'
                        {...animationParams}
                        variants={animationVariants.titleVariantMobile}
                    >
                        {description}
                    </motion.p>
                </div>

                <div className={`about-something-content_items-wrapper ${contentClassName ? contentClassName : ''}`}>
                    {items.map((item, index) => (
                        <motion.div
                            className='single-item-wrapper' key={index}
                            {...animationParams}
                            variants={animationVariants.cardsVariant}
                        >
                            <LandingSvg.CheckMarkSvg className='about-something-svg' />

                            {!oneLineText ?
                                <div className='single-item-texts'>

                                    <p className='single-item-text' style={{ fontWeight: 600 }}>
                                        {`${item?.textBold} `}
                                    </p>

                                    <p className='single-item-text'>
                                        {item?.text}
                                    </p>
                                </div>
                                :
                                <div className='single-item-texts'>
                                    <p className='single-item-text'>
                                    <span style={{ fontWeight: 600 }}>
                                         {`${item?.textBold} `}
                                    </span>

                                        {item?.text}
                                    </p>
                                </div>
                            }
                        </motion.div>
                    ))}
                </div>

                {googleAppButtons &&
                    <div className='google-app-buttons'>
                        <motion.div
                            whileHover={{ scale: 1.01, translateY: 0 }}
                            whileTap={{ scale: 0.99, opacity: .8 }}
                            transition={{ duration: .1 }}
                            onClick={onClickGoogle}
                        >
                            <GoogleAppButton
                                classNameWrapper='google-app-button'

                            />
                        </motion.div>

                        <motion.div
                            whileHover={{ scale: 1.01, translateY: 0 }}
                            whileTap={{ scale: 0.99, opacity: .8 }}
                            transition={{ duration: .1 }}
                            onClick={onClickApple}
                        >
                            <GoogleAppButton
                                classNameWrapper='google-app-button' googlePlay={false}
                            />
                        </motion.div>
                    </div>
                }

            </div>
        </div>
    );
};

