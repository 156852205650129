export const animationVariants = {
    textVariant: {
        hidden: {
            y: 50,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
        },
    },
    titleVariant: {
        hidden: {
            y: 30,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
        },
    },

    titleVariantMobile: {
        hidden: {
            y: 10,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
        },
    },

    rightToLeftVariant: {
        hidden: {
            x: 50,
            opacity: 0,
        },
        visible: {
            x: 0,
            opacity: 1,
        },
    },

    leftToRightVariant: {
        hidden: {
            x: -50,
            opacity: 0,
        },
        visible: {
            x: 0,
            opacity: 1,
        },
    },

    imgVariant: {
        hidden: {
            width: '0',
            opacity: 0,
        },
        visible: {
            width: '100%',
            opacity: 1,
        },
    },

    cardsVariant: {
        hidden: {
            scale: .8,
            opacity: 0,
        },
        visible: {
            scale: 1,
            opacity: 1,
        },
    },

    footerBackground: {
        hidden: {
            // scale: .8,
            // opacity: 0,
            x: -300,
        },
        visible: {
            // scale: 1,
            // opacity: 1,
            x:0,
        },
    },
};


export const animationParams = {
    initial: 'hidden',
    whileInView: 'visible',
    viewport: {
        amount: .2,
        once: true,
    },
    transition: {
        duration: .8,
        ease: 'easeInOut',
    },
};