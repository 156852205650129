import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CreateChancel, ValidationInput } from 'components';
import { ErrorText, FindLoad, FindSuccessItem, isNotEmpty, useModal } from 'utils';
import { httpRequestsOnSuccessActions, systemActions } from 'store';

const ACTION_TYPE = 'EDIT_JOB_BY_ID_GLOBAL';

export const EditJobTitle = ({ info }) => {
    const dispatch = useDispatch();
    const loader = FindLoad(ACTION_TYPE);
    const success = FindSuccessItem(ACTION_TYPE);
    const [inputs, setInputs] = useState(info ? { ...info } : {});
    const [error, setError] = useState('');
    const { close } = useModal();

    useEffect(() => {
        if (success) {
            close()
            setInputs({});
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    const handleChange = (e) => {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value, }));
        error === e.target.name && setError('');
    }

    const handleSubmit = () => {
        if (isNotEmpty(inputs.name)) {
            const data = { name: inputs.name };
            dispatch(systemActions.editJobByIdGlobal(data, info?.id));
        } else {
            setError(!inputs.name ? "name" : "");
        }
    }

    return (
        <div style={{ width: 400 }}>
            <p className="modal-header-title">Edit Job Title</p>
            <div className="modal-body-wrapper">
                <ValidationInput
                    onChange={handleChange}
                    value={inputs?.name}
                    variant={"outlined"}
                    name={"name"}
                    type={"text"}
                    placeholder={"Job Title*"}
                    typeError={error === "name" ? `Job title ${ErrorText.isRequired}` : ""}
                    Length={50}
                />
            </div>
            <CreateChancel
                loader={!!loader.length}
                buttonWidth="100%"
                create="Save"
                chancel="Cancel"
                onClose={close}
                onCreate={handleSubmit}
            />
        </div>
    );
};