import {
    createTag,
    deleteTag,
    editTag,
    getTags,
    addTagToMember,
    removeTagFromMember,
} from './tag.action';
export { tagReducer } from './tag.reducer';
export { watchTags } from './tag.saga';

export const tagActions = {
    /** Tags */
    getTags,
    createTag,
    editTag,
    deleteTag,
    /** End */

    /** Tag for members */
    addTagToMember,
    removeTagFromMember,
    /** End */
};

