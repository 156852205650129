import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { DrawerContext, Images, PermissionList, RolePermission } from 'utils';
import { leftBarCommonStyle } from './styles';


const billingSections = [
    RolePermission([
        PermissionList.CLAIMS_READ?.code,
        PermissionList.CLAIMS_CREATE?.code,
        PermissionList.CLAIMS_UPDATE?.code,
        PermissionList.CLAIMS_CLOSE?.code,
    ]) &&
    {
        label: 'Claims', path: '/claims', name: 'Billing',
        detail: '/claims',
        multipleLinks: [
            '/claim', '/generateClaim',
        ],
    },
    // RolePermission([
    //     PermissionList.CLAIMS_READ?.code,
    //     PermissionList.CLAIMS_CREATE?.code,
    //     PermissionList.CLAIMS_UPDATE?.code,
    //     PermissionList.CLAIMS_CLOSE?.code,
    // ]) &&
    // {
    //     label: 'Pending Claims', path: '/pendingClaims', name: 'Billing',
    //     detail: '/pendingClaims',
    //     multipleLinks: [
    //         '/claim', '/generateClaim',
    //     ],
    // },
    //
    // RolePermission([
    //     PermissionList.CLAIMS_READ?.code,
    //     PermissionList.CLAIMS_CREATE?.code,
    //     PermissionList.CLAIMS_UPDATE?.code,
    //     PermissionList.CLAIMS_CLOSE?.code,
    // ]) &&
    // {
    //     label: 'Billed Claims', path: '/billedClaims', name: 'Billing',
    //     multipleLinks: [
    //         '/billedClaim',
    //     ],
    // },

    RolePermission([
        PermissionList.CLAIM_PMT_READ?.code,
        PermissionList.CLAIM_PMT_PAY?.code,
        PermissionList.CLAIM_PMT_SEND_APPEAL?.code,
        PermissionList.CLAIM_PMT_APPEAL_READ?.code,
        PermissionList.CLAIM_PMT_APPEAL_PAY?.code,
    ]) &&
    { label: 'Claim Payments', path: '/claimPayments', name: 'Billing' },

    RolePermission([
        PermissionList.INVOICE_READ?.code,
        PermissionList.INVOICE_CREATE?.code,
        PermissionList.INVOICE_UPDATE?.code,
        PermissionList.INVOICE_CLOSE?.code,
    ]) &&
    {
        label: 'Invoices', path: '/invoices', name: 'Billing',
        multipleLinks: [
            '/generateInvoice', '/invoice',
        ],
    },
];

// const postingSections = [
//
//     // RolePermission([
//     //     PermissionList.INVOICE_PMT_READ?.code,
//     //     PermissionList.INVOICE_PMT_PAY?.code,
//     //     PermissionList.INVOICE_PMT_CLOSE?.code,
//     // ]) &&
//     // { label: 'Invoice Payments', path: '/invoicePayments', name: 'Posting' },
// ];

const payrollSections = [

    RolePermission([PermissionList.TIMESHEET_READ.code]) &&
    { label: 'Active', path: '/activePayroll', name: 'Payroll', multipleLinks: ['/timesheet'] },

    RolePermission([PermissionList.TIMESHEET_READ.code]) &&
    { label: 'Processed', path: '/processedPayroll', name: 'Payroll', multipleLinks: ['/processed'] },
];

const analyticSections = [

    { label: 'Analytics', path: '/analytic', name: 'Reporting' },

    { label: 'Reports', path: '/report', name: 'Reporting' },
];

export const CustomAccordion = ({ item }) => {
    const linkInfo = window.location.pathname;
    const classes = leftBarCommonStyle();
    const accordionItems =
        item.name === 'Billing' ? billingSections :
            item.name === 'Payroll' ? payrollSections :
                item.name === 'Reporting' ? analyticSections :
                    [];
    const { open, handleDrawerOpenClose } = useContext(DrawerContext);
    const [isShown, setIsShown] = useState(null);
    const [expand, setExpand] = useState(null);
    const location = useLocation();
    const originalPath = location?.pathname?.split('/')[1];
    const sectionsListClassName = `${classes.sectionsListBoxStyle} ${open && (expand === item?.name) ? 'shown' : ''}`;

    useEffect(() => {
        const findCurrent = accordionItems?.find((i) => i?.path === `/${originalPath}` || i?.multipleLinks?.includes(`/${originalPath}`));
        setIsShown(findCurrent);
        setExpand(findCurrent?.name);
    }, [originalPath]);

    function handleAccordionClick() {
        setExpand(item?.name !== expand ? item?.name : null);
        if (!open) {
            handleDrawerOpenClose(true);
        }
    }

    return (
        <div className="router-accordion-box">
            <div className={classes.linkPathWrapper} onClick={handleAccordionClick}>
                <div className={item?.name === isShown?.name ? classes.activeBorder : classes.passiveBorder} />
                <div style={{ width: open ? '100%' : 'auto', borderRadius: open ? '4px 0px 0px 4px' : '4px' }}
                     className={`${classes.linkWrapper} ${item?.name === isShown?.name ? classes.linkWrapperActive : classes.linkWrapperPassive}`}
                >
                    <div className={classes.linkImageWrapper}>
                        <img src={item?.name === isShown?.name ? item?.activeIcon : item?.icon} alt="icon" />
                    </div>
                    {open &&
                        <>
                            <p>{item?.name}</p>
                            <div
                                className={expand === item?.name || isShown?.name === item?.name ? '' : 'router-accordion-expand-icon'}>
                                <img className={
                                    `${classes.accordArrowStyle} 
                              ${expand === item?.name ? 'rotate' : ''}`}
                                     src={isShown?.name === item?.name ? Images.accordArrowBlue : Images.accordArrowBlack}
                                     alt="icon"
                                />
                            </div>
                        </>
                    }
                </div>
            </div>
            <div className={sectionsListClassName}>
                <div>
                    {accordionItems?.map((section, index) => {
                        if (section) {
                            const path = section?.path;
                            const label = section?.label;
                            const hasSame = section?.multipleLinks?.includes(`/${originalPath}`);
                            const activeClassName = linkInfo === path || hasSame ? 'active' : '';

                            // console.log(activeClassName,'activeClassName');
                            return (
                                <NavLink
                                    onClick={() => setIsShown(item)}
                                    key={index}
                                    to={path}
                                    className={activeClassName}
                                >
                                    {label}
                                </NavLink>
                            );
                        }
                    })}
                </div>
            </div>
        </div>
    );
};
