import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Drawer } from '@material-ui/core';
import { navBarStyles } from './style';
import { Images, Images as images } from 'utils';
import { MyProfile } from '../../myProfile';
import { NotificationList } from '../../notification';

export const UserInfo = ({}) => {
    const classes = navBarStyles();
    const user = useSelector((state) => state.auth.userInfo);
    const userInfo = user || JSON.parse(localStorage.getItem('wellUserInfo'));
    const unRenderNotifications = useSelector((state) => state.notifications.unRenderNotifications);
    const [drawerPosition, setDrawerPosition] = useState({ right: false });
    const [drawerNotificationPosition, setDrawerNotificationPosition] = useState({ right: false });

    const toggleDrawer = (anchor, open, modal) => {
        setDrawerPosition({ ...drawerPosition, [anchor]: open });
    };

    const notificationDrawer = (anchor, open, modal) => {
        setDrawerNotificationPosition({ ...drawerPosition, [anchor]: open });
    };

    return (
        <div
            style={{ color: 'black', position: 'relative' }}
            className={classes.boxWrapper}
        >
            <div onClick={() => notificationDrawer('right', true)} className={classes.notificationBox}>
                {unRenderNotifications > 0 && (
                    <div className={classes.noteCount}>
                        {unRenderNotifications > 9 ? '9+' : unRenderNotifications}
                    </div>
                )}
                <img src={Images.notification} alt="Avatar" />
            </div>

            <div onClick={() => toggleDrawer('right', true)} className={classes.userInfo}>
                <img src={user?.avatar?.url ? user?.avatar?.url : images.userProfile} alt="Avatar" />
                <div className={classes.online} />
            </div>

            <Drawer
                className={classes.myProfileDrawerStyle}
                anchor={'right'}
                open={drawerPosition.right}
                onClose={() => toggleDrawer('right', false)}
            >
                <MyProfile info={userInfo} />
            </Drawer>

            <Drawer
                className={classes.myProfileDrawerStyle}
                anchor={'right'}
                open={drawerNotificationPosition.right}
                onClose={() => notificationDrawer('right', false)}
            >
                <NotificationList handleClose={() => notificationDrawer('right', false)} info={userInfo} />
            </Drawer>
        </div>
    );
};
