import React from 'react';
import { AvailabilitySchedule } from 'components';
import { serviceSingleStyles } from './styles';

export const StaffAvailability = ({}) => {
    const classes = serviceSingleStyles();

    return (
        <div className={classes.staffGeneralWrapper}>
            <AvailabilitySchedule
                onModel={'Staff'}
            />
        </div>
    );
};
