import {call, put, takeLatest} from 'redux-saga/effects';
import { notificationService } from './notification.service';
import {
    DELETE_NOTIFICATION,
    GET_NOTIFICATIONS,
    GET_NOTIFICATIONS_SUCCESS, GET_UNRENDER_NOTIFICATIONS, GET_UNRENDER_NOTIFICATIONS_SUCCESS,
    MARK_ALL_AS_READ,
    MARK_SINGLE_AS_READ,
} from './notification.types';
import {httpRequestsOnErrorsActions} from '../http_requests_on_errors';
import {httpRequestsOnLoadActions} from '../http_requests_on_load';
import {httpRequestsOnSuccessActions} from '../http_requests_on_success';

function* getNotifications({payload, type}) {
    if(payload?.load !== 'noLoad'){
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(notificationService.getNotificationsService, payload?.params);
        yield put({
            type: GET_NOTIFICATIONS_SUCCESS,
            payload: res.data,
        })
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        const error = err?.data?.message ? err?.data?.message : err?.response?.data?.message;
        yield put(httpRequestsOnErrorsActions.appendError(type, error));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getUnRenderNotifications({payload, type}) {
    if(payload?.load !== 'noLoad'){
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(notificationService.getUnRenderNotificationsService);
        yield put({
            type: GET_UNRENDER_NOTIFICATIONS_SUCCESS,
            payload: res.data,
        })
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        const error = err?.data?.message ? err?.data?.message : err?.response?.data?.message;
        yield put(httpRequestsOnErrorsActions.appendError(type, error));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* markAllAsRead({payload, type}) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(notificationService.markAllAsReadService);
        yield put({
            type: GET_UNRENDER_NOTIFICATIONS,
        })
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}
function* markSingleAsRead({payload, type}) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(notificationService.markSingleAsReadService, payload?.id)
        yield put({
            type: GET_UNRENDER_NOTIFICATIONS,
        })
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}
function* deleteNotification({payload, type}) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(notificationService.deleteNotificationService, payload?.id)
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

export const watchNotification = function* watchwatchNotificationAuth() {
    yield takeLatest(GET_NOTIFICATIONS, getNotifications);
    yield takeLatest(GET_UNRENDER_NOTIFICATIONS, getUnRenderNotifications);
    yield takeLatest(MARK_ALL_AS_READ, markAllAsRead);
    yield takeLatest(MARK_SINGLE_AS_READ, markSingleAsRead);
    yield takeLatest(DELETE_NOTIFICATION, deleteNotification);
};
