import axios from 'axios';

export const notificationService = {

    getNotificationsService: (params) => axios.get(`/notification`, { auth: true, params: params }),

    getUnRenderNotificationsService: ( ) => axios.get(`/notification/unread/count`, { auth: true }),

    markAllAsReadService: () => axios.patch(`/notification/readAll`, null, { auth: true }),

    markSingleAsReadService: (id) => axios.patch(`/notification/read/${id}`, null, { auth: true }),

    deleteNotificationService: (id) => axios.delete(`/notification/${id}`, { auth: true }),

};
