
/** Tag Types */
export const GET_TAGS = 'GET_TAGS';
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';

export const CREATE_TAG = 'CREATE_TAG';
export const EDIT_TAG = 'EDIT_TAG';
export const DELETE_TAG = 'DELETE_TAG';

export const ADD_TAG_TO_MEMBER = 'ADD_TAG_TO_MEMBER';
export const REMOVE_TAG_FROM_MEMBER = 'REMOVE_TAG_FROM_MEMBER';

/** End */
