import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FullTable } from 'components';
import { FindSuccess, FindSuccessItem, PermissionList, RolePermission, useModal } from 'utils';
import { httpRequestsOnSuccessActions, staffPayrollActions } from 'store';
import { activePayrollBody, activePayrollHead, ACTION_TYPE, payrollNotYet, activePayrollNotYet } from './constants';
import { Filters } from './fragments/filters';
import { ProcessModal } from './fragments/processModal';

const ACTION_TYPE_SUCCESS = 'CREATE_TIMESHEET';
const ACTION_TYPE_EDIT_SUCCESS = 'EDIT_TIMESHEET';
export const ActivePayroll = ({}) => {
    const { open } = useModal();
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const activePayrolls = useSelector((state) => state?.staffPayroll?.activePayroll);
    const { close } = useModal();
    const success = FindSuccessItem(ACTION_TYPE_SUCCESS);
    const editSuccess = FindSuccessItem(ACTION_TYPE_EDIT_SUCCESS);
    const renderParams = () => {
        const filteredInfo = {
            ...info,
            startDate: info?.start,
            endDate: info?.end,
        };
        filteredInfo.skip = info?.skip ? info?.skip : 0;
        filteredInfo.limit = 50;
        delete filteredInfo.page;
        delete filteredInfo.activeTab;

        if (info?.staffs?.length) {
            filteredInfo.staffs = info?.staffs?.map((i) => i.id);
        } else {
            delete filteredInfo.staffs;
        }

        return filteredInfo;
    };

    const getTimeSheet = (load) => {
        dispatch(staffPayrollActions.getActivePayroll({ ...renderParams() }, load));

    }
    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE_SUCCESS));
            getTimeSheet('noLoad');
            close();
        }
        if (editSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE_EDIT_SUCCESS));
            getTimeSheet('noLoad');
            close();
        }
    }, [success, editSuccess]);

    useEffect(() => {
        getTimeSheet()
    }, [info]);

    const handleProcess = () => {
        if(RolePermission([PermissionList.TIMESHEET_PROCESS?.code])) {
            open(<ProcessModal />)
        }
    };

    const afterSuccess = () => {
        getTimeSheet('noLoad');
    }

    return (
        <div>
            <Filters handleProcess={handleProcess} type={'active'} />
            <FullTable
                head={activePayrollHead()}
                body={activePayrollBody(afterSuccess)}
                loadingType={ACTION_TYPE}
                list={activePayrolls?.timesheets}
                listCount={activePayrolls?.count}
                handleClick={(id) => history.push(`/timesheet/${id}`)}
                notYet={activePayrollNotYet}
                activeRowId={''}
            />
        </div>
    );
};