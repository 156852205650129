import React from 'react';
import { RolePermissions } from './rolePermissions';
import { Role } from './role';
import { managementFragments } from './style';

export const AccessManagement = ({ rolesList, permissionsList, role, rolesCount, afterSuccess, info }) => {
    const classes = managementFragments();

    return (
        <div className={classes.managementFragmentsStyle}>
            <div className={classes.managementFragmentsRole}>
                <Role
                    roleInfo={rolesList}
                    permissionsList={permissionsList}
                    rolesCount={rolesCount}
                    info={info}
                    afterSuccess={afterSuccess}
                />
            </div>
            <div className={classes.managementFragmentsPermissions}>
                <RolePermissions role={role} permissionsList={permissionsList} />
            </div>
        </div>
    );
};
