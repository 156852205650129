import { Svg } from 'assets';

export const socialMediaIcons = [
    // {
    //     Icon: Svg.FacebookFooter,
    //     path: 'https://www.facebook.com/',
    // },
    //
    {
        Icon: Svg.InstagramFooter,
        path: 'https://www.instagram.com/therapylake/',
    },
    {
        Icon: Svg.LinkedinFooter,
        path: 'https://www.linkedin.com/company/therapylake/about/',
    },
    // {
    //     Icon: Svg.YoutubeFooter,
    //     path: 'https://www.youtube.com/',
    // },


    {
        Icon: Svg.TwitterFooter,
        path: 'https://x.com/i/flow/login?redirect_after_login=%2Ftherapylakeaba',
    },
];

export const firstBlock = [
    {
        title: 'Home',
        path: '/',
    },

    {
        title: 'Features',
        path: '/features',
    },
];

export const secondBlock = [
    {
        title: 'Solutions',
        path: '',
    },

    {
        title: 'Resources',
        path: '',
    },
];

export const thirdBlock = [
    {
        title: 'about us',
        path: '/about-us',
    },

    {
        title: 'contact us',
        path: '/contact-us',
    },
];




