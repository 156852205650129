import { makeStyles } from '@material-ui/core/styles';
import { Backgrounds, Colors } from 'utils';

export const tableStyle = makeStyles(() => ({
    InfoAndImage: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            fontSize: '14px',
            lineHeight: '19px',
            fontWeight: 'bold',
            color: Colors.TextSecondary,
        },
        '& img': {
            width: '24px',
            height: '24px',
            marginRight: '4px',
            '@media (min-width: 1920px)': {
                marginRight: '8px',
            },
        },
    },

    tableHead: {
        boxShadow: '0px 2px 6px #347af033',
        borderRadius: '8px',
        // background: '#EBF2FD',
        // height: 51,
        '& > tr': {
            borderRadius: '8px',
            '& > th:first-child': {
                borderTopLeftRadius: '8px',
                borderBottomLeftRadius: '8px',
            },
            '& > th:last-child': {
                borderTopRightRadius: '8px',
                borderBottomRightRadius: '8px',
            },
        },
        '& .MuiTableCell-stickyHeader': {
            background: Backgrounds.headerLightBlue,
        },
        '& .MuiTableCell-sizeSmall': {
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '19px',
            color: Colors.TextSecondary,
            padding: '16px',
            border: 'none',
        },
    },

    tableHeadGenerate: {
        boxShadow: 'none',
        borderRadius: '8px',
        height: 36,
        '& > tr': {
            borderRadius: '8px',
            '& > th:first-child': {
                borderTopLeftRadius: '8px',
                borderBottomLeftRadius: '8px',
            },
            '& > th:last-child': {
                borderTopRightRadius: '8px',
                borderBottomRightRadius: '8px',
            },
        },
        '& .MuiTableCell-stickyHeader': {
            background: '#0C66E4',
        },
        '& .MuiTableCell-sizeSmall': {
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '19px',
            color: 'white',
            padding: '6px 16px ',
            border: 'none',
        },

        '& .text-style': {
            '& p': {
                color: 'white !important',
            },
        },
    },

    modalTableHead: {
        boxShadow: 'none',
        height: 36,
        '& .searchInputTitle': {
            '& span': {
                fontSize: '14px',
                fontWeight: '700',
                color: Colors.TextSecondary,
                padding: '8px ',
                border: 'none',
            },
        },
        '& .MuiTableCell-stickyHeader': {
            background: 'transparent',
        },

        '& .MuiTableCell-sizeSmall': {
            fontSize: '14px',
            fontWeight: '700',
            color: Colors.TextSecondary,
            padding: '0 16px',
            border: 'none',
        },
    },
    tableRow: {
        boxShadow: '0px 2px 6px #347af033',
        cursor: 'pointer',
        borderRadius: '8px',
        backgroundColor: Colors.BackgroundWhite,
        position: 'relative',
        '& td': { backgroundColor: 'inherit' },
        '& > td:first-child': {
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
        },
        '& > td:last-child': {
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
        },
        '&:hover': {
            backgroundColor: '#EBF2FD80',
            '& > td': { backgroundColor: 'inherit' },
        },
        height: '50px',
        '& .makeStyles-membersTableWrapper-26 .MuiTableContainer-root': {
            background: Backgrounds.whiteModal,
            boxShadow: '0px 8px 12px #0052E01A',
        },
        '& .MuiTableCell-sizeSmall': {
            fontSize: '14px',
            lineHeight: '22px',
            color: Colors.TextPrimary,
            border: 'none',
            padding: '4px 16px',
        },
        '& .MuiTableCell-sizeSmall:last-child': {
            // paddingTop: '12px',
            // fontSize: '14px',
            // lineHeight: '30px',
            // color: Colors.TextPrimary,
        },
        '& MuiSwitch-track': {
            borderRadius: '12px',
            background: 'lightgray',
        },
        '& .MuiSwitch-colorPrimary.Mui-checked': {
            color: 'white',
        },
        '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
            background: '#387DFF',
            borderRadius: '12px',
            opacity: '1',
        },
        '&.active': {
            background: Backgrounds.grayHover,
            cursor: 'default',
        },
    },

    generateTableRow: {
        boxShadow: 'none',
        cursor: 'pointer',
        borderRadius: '8px',
        backgroundColor: '#EBF2FD',
        position: 'relative',
        '& td': { backgroundColor: 'inherit' },
        '& > td:first-child': {
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
        },
        '& > td:last-child': {
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
        },
        height: '36px',
        '& .makeStyles-membersTableWrapper-26 .MuiTableContainer-root': {
            background: Backgrounds.whiteModal,
            boxShadow: '0px 8px 12px #0052E01A',
        },
        '& .MuiTableCell-sizeSmall': {
            fontSize: '14px',
            color: '#172B4D',
            border: 'none',
            padding: '4px 16px',
        },
        '& MuiSwitch-track': {
            borderRadius: '12px',
            background: 'lightgray',
        },
        '& .MuiSwitch-colorPrimary.Mui-checked': {
            color: 'white',
        },
        '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
            background: '#387DFF',
            borderRadius: '12px',
            opacity: '1',
        },
    },

    modalTableRow: {
        boxShadow: 'none',
        cursor: 'pointer',
        borderRadius: '8px',
        backgroundColor: '#F2F4F8',
        position: 'relative',
        height: '36px',

        '& td': { backgroundColor: 'inherit' },
        '& > td:first-child': {
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
        },
        '& > td:last-child': {
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
        },
        '&:hover': {
            backgroundColor: '#F2F4F8',
            '& > td': { backgroundColor: 'inherit' },
        },

        '& .makeStyles-membersTableWrapper-26 .MuiTableContainer-root': {
            background: '#F2F4F8',
            boxShadow: 'none',
        },
        '& .MuiTableCell-sizeSmall': {
            fontSize: '14px',
            lineHeight: '19px',
            color: '#4B5C68',
            border: 'none',
            padding: '6px 16px ',
        },
        '& .MuiTableCell-sizeSmall:last-child': {
            // paddingTop: '12px',
            // fontSize: '14px',
            // color: Colors.TextPrimary,
        },
        '& MuiSwitch-track': {
            borderRadius: '12px',
            background: 'lightgray',
        },
        '& .MuiSwitch-colorPrimary.Mui-checked': {
            color: 'white',
        },
        // "& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track": {
        //    background: "#387DFF",
        //    borderRadius: "12px",
        //    opacity: "1",
        // },
        // "&.active": {
        //    background: Backgrounds.grayHover,
        //    cursor: "default",
        // },
    },
    commentTextAreaStyle: {
        '& textarea': {
            width: '100%',
            height: '115px',
        },
    },

    fullLoaded: {
        height: '8px',
        width: '100%',
        background: Colors.BackgroundBlue,
        borderRadius: '4px',
    },
    paidAmt: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    alignCenter: {
        display: 'flex',
        alignItems: 'center',
    },
    removeButton: {
        background: 'transparent',
        border: 'none',
        marginBottom: '-5px',
    },
}));
