import axios from "axios";

export const claimPaymentService = {
   /** Claim Payment */
   getClaimPaymentsService:  (data) => axios.get("/claim", { auth: true, params: { ...data } }),

   editClaimPmtInfoService: ({id, body}) => axios.patch(`/claim/${id}`, {...body}, {auth:true}),

   appealClaimPmtService: ({id, description}) => axios.post(`/claim/${id}/pmt/appeal`, {"description": description}, {auth:true}),

   payInFullClaimService: ({id, paymentRef}) => axios.post(`/claim/${id}/pmt`, {...paymentRef}, {auth:true}),

   payInFullClaimBillingService: ({id,billId,paymentRef}) => axios.post(`/claim/${id}/pmt/bill/${billId}`, {...paymentRef}, {auth:true}),
   /** End */
};