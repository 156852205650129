
/** Analytics */

export const GET_USER_FILTERED_KPIS = 'GET_USER_FILTERED_KPIS';
export const GET_USER_FILTERED_KPIS_SUCCESS = 'GET_USER_FILTERED_KPIS_SUCCESS';
export const GET_ALL_KPIS = 'GET_ALL_KPIS';
export const GET_ALL_KPIS_SUCCESS = 'GET_ALL_KPIS_SUCCESS';
export const GET_USER_KPIS = 'GET_USER_KPIS';
export const GET_USER_KPIS_SUCCESS = 'GET_USER_KPIS_SUCCESS';
export const REORDER_KPIS = 'REORDER_KPIS';
export const DELETE_USER_KPI = 'DELETE_USER_KPI';
export const ADD_USER_KPI = 'ADD_USER_KPI';
export const GET_CHART_DATA = 'GET_CHART_DATA';
export const GET_CHART_DATA_SUCCESS = 'GET_CHART_DATA_SUCCESS';

/** End */

