import {
    deleteNotification,
    getNotifications,
    getUnRenderNotifications,
    markAllAsRead,
    markSingleAsRead, removeNotifications,
} from './notification.action';
export { notificationReducer } from './notification.reducer';
export { watchNotification } from './notification.saga';

export const notificationActions = {
    getNotifications,
    removeNotifications,
    getUnRenderNotifications,
    markAllAsRead,
    markSingleAsRead,
    deleteNotification,
    /** End */
};