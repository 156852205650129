import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FLAGGED_ENUMS, renderClientName, SaveParams } from 'utils';
import { DateTypeSelector, DownloadCsv, SelectTypeAutocomplete } from 'components';
import { adminActions, clientActions, fundingSourceActions } from 'store';
import { claimTabEnums } from './constants';

export const ClaimFilters = ({ }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const pushInfo = history?.location?.state;
    const fundingSourceList = useSelector((state) => state.fundingSource.fundingSourceList);
    const clientList = useSelector((state) => state.client.basicClients);

    useEffect(() => {
        dispatch(clientActions.getBasicClients());
        dispatch(fundingSourceActions.getFundingSource());
        dispatch(adminActions.getAdmins());
    }, []);

    const handleChange = (e) => {
        const info = {
            ...pushInfo,
        };
        info.skip = 0;
        info.limit = 50;
        info.page = 1;

        if (e.target.value === 'All' || !e.target.value) {
            delete info[e.target.name];
        } else {
            info[e.target.name] = e.target.value;
        }
        SaveParams(history, { ...info });
    };

    return (
        <div>
            <div className="table-invoice-filter-section">
                <div className="billing-invoices-filter-box">
                    <SelectTypeAutocomplete
                        placeholder={pushInfo?.payer ? '' : 'All'}
                        name={'payer'}
                        handleSelect={handleChange}
                        defaultValue={pushInfo?.payer}
                        list={fundingSourceList?.funders ?
                            [{ id: 'All', name: 'All' }, ...fundingSourceList?.funders]
                            :
                            [{ id: 'All', name: 'All' }]
                        }
                        renderValue={(i) => i?.name}
                        uiType={'tableFilter'}
                        outLabel={'Payer'}
                        noError={true}
                    />
                    <SelectTypeAutocomplete
                        placeholder={pushInfo?.client ? '' : 'All'}
                        name={'client'}
                        handleSelect={handleChange}
                        defaultValue={pushInfo?.client}
                        list={clientList ?
                            [{ id: 'All', firstName: 'All' }, ...clientList]
                            :
                            [{ id: 'All', firstName: 'All' }]
                        }
                        renderValue={(i) => renderClientName(i)}
                        uiType={'tableFilter'}
                        outLabel={'Client'}
                        noError={true}
                    />
                    {pushInfo?.tabType !== claimTabEnums?.SUBMITTED &&
                        <SelectTypeAutocomplete
                            placeholder={pushInfo?.flag ? '' : 'All'}
                            name={'flag'}
                            handleSelect={handleChange}
                            defaultValue={pushInfo?.flag}
                            list={FLAGGED_ENUMS}
                            renderValue={(i) => i?.label}
                            uiType={'tableFilter'}
                            outLabel={'Flagged'}
                            noError={true}
                        />
                    }
                    <DateTypeSelector
                        startName={'from'}
                        endName={'to'}
                        outLabel={'DOS'}
                    />
                </div>

                <div className="billing-invoices-filter-box">
                    <DownloadCsv
                        alwaysSmall={true}
                        fromModal={true}
                        type={'pendingClaims'}
                    />
                    {/*{RolePermission([PermissionList.CLAIMS_CREATE?.code]) && type === 'pending' &&*/}
                    {/*    <div>*/}
                    {/*        <AddButton*/}
                    {/*            text={'Prepare Claims'}*/}
                    {/*            handleClick={() => open(<GenerateClaim />)}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*}*/}
                </div>
            </div>
        </div>
    );
};