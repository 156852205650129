import React from 'react';
import { DownloadFile, getFileType, Images, renderSize } from 'utils';
import { MinLoader, TextRow } from 'components';

export const UploadedSignature = ({ currentFile, alreadyUploaded, handleRemoveUploaded }) => {
    const { loadDownload, handleDownload } = DownloadFile();

    if (!currentFile) {
        return null;
    }

    return (
        <div style={{ height: 90 }}>
            <div className="uploaded-signature-box" style={{}}>
                <img
                    className='uploaded-signature-image'
                    src={alreadyUploaded ? currentFile?.file?.url : Images.signatureIcon} alt="icon" />
                <div className="full-width">
                    <div className="full-width">
                        <div className="space-between-align-start">
                            <p className="uploaded-signature-name" style={{ width: 230 }}>
                                <TextRow
                                    name={alreadyUploaded ? currentFile?.file?.originalName : currentFile?.file?.name} />
                            </p>
                            <div className="flex-align-center" style={{ gap: 10, height: 20 }}>
                                {alreadyUploaded &&
                                    <button className="reset-btn-no-paddings"
                                            onClick={() => handleDownload(currentFile?.file)}>
                                        {loadDownload ?
                                            <MinLoader
                                                small={true}
                                                margin={'0'} color={'#172B4D'} position={'relative'} />
                                            :
                                            <img style={{
                                                height: 20,
                                                filter: 'invert(11%) sepia(82%) saturate(1066%) hue-rotate(194deg) brightness(90%) contrast(92%)',
                                            }} src={Images.downloadCloudBlue} alt={'download'} />
                                        }
                                    </button>
                                }
                                {alreadyUploaded &&
                                    <a href={currentFile?.file?.url} target={'_blank'}>
                                        <img style={{ height: 20 }} src={Images.showEye} alt={'view'} />
                                    </a>
                                }
                                {!alreadyUploaded &&
                                    <button className="reset-btn-no-paddings" onClick={handleRemoveUploaded}>
                                        <img style={{ height: 20 }} src={Images.redTrash} alt={'icon'} />
                                    </button>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="flex-align-center uploaded-signature-information"
                         style={{ margin: '4px 0' }}>
                        <p>{alreadyUploaded ? getFileType(currentFile?.file?.originalName) : currentFile?.file?.type?.replace('image/', '')}</p>
                        {!alreadyUploaded && <p>{renderSize(currentFile?.file)}</p>}
                    </div>

                    {!alreadyUploaded &&
                        <div className="full-loaded">
                            <div className="full-loaded-line" />
                            <p>100%</p>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};