import React, { useEffect, useState } from 'react';
import { ErrMessage } from '../messages';
import { Popper } from '@material-ui/core';
import { Images } from 'utils';

const activeStyles = {
    background: '#0C66E4', color: 'white',
};
export const TimeInput = ({ errorFalse, typeError, label, onChange, name, defaultValue }) => {

    const [time, setTime] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const hours = Array.from({ length: 12 }, (_, i) => i + 1).map(i => i < 10 ? `0${i}` : `${i}`);
    const minutes = Array.from({ length: 60 }, (_, i) => i < 10 ? `0${i}` : `${i}`);
    const ampm = ['AM', 'PM'];

    useEffect(() => {
        if(defaultValue){
            let hours = defaultValue.slice(0, 2);
            let minutes = defaultValue.slice(3, 5);
            hours = parseInt(hours, 10);
            const period = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12 || 12;
            hours = String(hours).padStart(2, '0');
            minutes = String(minutes).padStart(2, '0');
            setTime({
                hour: hours,
                minute: minutes,
                type: period,
            })
        }
    }, [defaultValue])
    function handleChange(inputName, value) {
        setTime((prevState) => ({ ...prevState, [inputName]: value }));
    }

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleCloseAnchor = () => {
        setAnchorEl(null);
    };

    // value
    useEffect(() => {
        if (time?.hour && time?.minute && time?.type) {
            const sendTarget = {
                target: {
                    name: name,
                    value: renderAmPmTime(),
                },
            };
            onChange(sendTarget);
        }
    }, [time]);


    const renderAmPmTime = () => {
        if (time?.hour && time?.minute && time?.type) {
            const currentTime = `${time.hour}:${time.minute} ${time.type}`;
            let [hours, minutes] = currentTime.split(':');
            const period = currentTime.slice(-2);
            hours = parseInt(hours, 10);

            if (period === 'PM' && hours !== 12) {
                hours += 12;
            } else if (period === 'AM' && hours === 12) {
                hours = 0;
            }
            hours = String(hours).padStart(2, '0');
            minutes = minutes.slice(0, -2).padStart(2, '0');
            return `${hours}:${minutes}`;
        } else {
            return 'hh : mm';
        }
    };

    function convertToAmPm(time) {
        let [hours, minutes] = time.split(':');
        hours = parseInt(hours, 10);
        const period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        hours = String(hours).padStart(2, '0');
        minutes = String(minutes).padStart(2, '0');
        return `${hours}:${minutes} ${period}`;
    }

    const showTime = () => {
        // if (defaultValue && time === null) {
        //     return convertToAmPm(defaultValue);
        // } else {
            if(time?.hour && time?.minute && time?.type){
                return `${time.hour}:${time.minute} ${time.type}`
            }else{
                return 'hh : mm';
            }
        // }
    };

    return (
        <div className="time-input-box">
            <p style={typeError ? { color: '#f44336' } : {}} className="time-input-title">{label}</p>
            <div
                className={`time-input ${typeError ? 'time-input-error' : ''}`}
                onClick={handleClick}
            >
                <p>{showTime()}</p>
                <img src={Images.accessTime} alt="time icon" />
            </div>

            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                className="custom-select-time-box"
                placement="bottom-start"
                style={{ position: 'absolute', zIndex: 2600 }}
            >
                <div className="hour-minute-box">
                    <div>
                        {hours?.map((item, index) => (
                            <button
                                onClick={() => handleChange('hour', item)}
                                style={item === time?.hour ? activeStyles : {}}
                                key={index}
                            >
                                {item}
                            </button>
                        ))}
                    </div>
                    <div>
                        {minutes?.map((item, index) => (
                            <button
                                onClick={() => handleChange('minute', item)}
                                style={item === time?.minute ? activeStyles : {}}
                                key={index}
                            >
                                {item}
                            </button>
                        ))}
                    </div>
                    <div>
                        {ampm?.map((item, index) => (
                            <button
                                onClick={() => handleChange('type', item)}
                                style={item === time?.type ? activeStyles : {}}
                                key={index}
                            >
                                {item}
                            </button>
                        ))}
                    </div>
                </div>
            </Popper>
            {errorFalse ? null : (
                <ErrMessage
                    style={{ marginBottom: '12px' }}
                    text={typeError}
                />
            )}
            {open && <div className="beck-drop" onClick={handleCloseAnchor} />}
        </div>
    );
};