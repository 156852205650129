import {
    createPayCodeGlobal,
    getPayCodeGlobal,
    editPayCodeByIdGlobal,
    deletePayCodeByIdGlobal,
    createOvertimeSettingsGlobal,
    getOvertimeSettingsGlobal,
    editOvertimeSettingsByIdGlobal,
    deleteOvertimeSettingsByIdGlobal,
    createMileage,
    editMileage,
    getMileages,
    deleteMileage,
    terminateMileage,
} from "./payroll.action";

export {payrollReducer} from './payroll.reducer';
export {watchPayroll} from './payroll.saga';

export const payrollActions = {
    /** PayCode types */
    createPayCodeGlobal,
    getPayCodeGlobal,
    editPayCodeByIdGlobal,
    deletePayCodeByIdGlobal,
    /** End */

    /** Overtime Settings */
    createOvertimeSettingsGlobal,
    getOvertimeSettingsGlobal,
    editOvertimeSettingsByIdGlobal,
    deleteOvertimeSettingsByIdGlobal,
    /** End */

    /** Mileage Compensation*/
    createMileage,
    editMileage,
    getMileages,
    deleteMileage,
    terminateMileage,
    /** End */

}