import React from 'react';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ButtonLanding } from 'components';
import { landingImages, LandingSvg } from 'assets';
import { animationParams, animationVariants } from 'fragments';;

export const TechnologicalInnovations = () => {
    const history = useHistory();

    return (
        <div className='technological-wrapper'>
            <div className='technological-block'>
                <div className='technological-container'>
                    <div className='technological-box'>

                        <ButtonLanding
                            className='technological-button-mobile'
                            text='Request a Demo'
                            arrowIcon={true}
                            contained={true}
                            onClick={() => history.push('/contact-us')}
                        />

                        <div className='technological-image-block'>
                            <motion.img
                                src={landingImages.technologicalImg} alt='Technical Img'
                                {...animationParams}
                                variants={animationVariants.cardsVariant}
                            />
                        </div>

                        <div className='technological-content'>
                            <motion.h2
                                className='technological-title'
                                {...animationParams}
                                variants={animationVariants.titleVariant}
                            >
                                Empower Your ABA Practice
                            </motion.h2>

                            <motion.p
                                className='technological-desc'
                                {...animationParams}
                                variants={animationVariants.titleVariant}
                            >
                                Harness cutting-edge technological innovations and profound clinical expertise to
                                revolutionize every aspect of your ABA practice, fostering unprecedented growth,
                                personalization, and positive developmental outcomes for every child in your care.
                            </motion.p>

                            <div className='technological-checkbox-wrapper'>
                                <div className='technological-checkbox-block'>
                                    <LandingSvg.Checkmark />

                                    <div style={{overflow:'hidden'}}>
                                        <motion.p
                                            className='technological-checkbox-text'
                                            {...animationParams}
                                            variants={animationVariants.rightToLeftVariant}
                                        >
                                            Personalize Therapy Sessions
                                        </motion.p>
                                    </div>
                                </div>

                                <div className='technological-checkbox-block'>
                                    <LandingSvg.Checkmark />

                                    <div style={{overflow:'hidden'}}>
                                        <motion.p
                                            className='technological-checkbox-text'
                                            {...animationParams}
                                            variants={animationVariants.rightToLeftVariant}
                                        >
                                            Streamlined Data Collection

                                        </motion.p>
                                    </div>
                                </div>

                                <ButtonLanding
                                    className='technological-button'
                                    text='Request a Demo'
                                    arrowIcon={true}
                                    contained={true}
                                    onClick={() => history.push('/contact-us')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

