import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {ClaimPaymentsFragment} from "fragments";
import {claimActions, claimPaymentActions, fundingSourceActions} from "store";
import {ClaimPaymentFilters} from "./claimPaymentFilters";
import { claimEnums } from '../../../fragments/posting/claimPayments/claimPayments/constants';

export const ClaimPayments = () => {
    const claimsList = useSelector((state) => state.claimPayment.claimPayments);
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;

    const renderParams = () => {
        const sendInfo = {
            ...info
        };
        delete sendInfo.page
        sendInfo.limit = 50;
        sendInfo.skip = info?.skip ? info?.skip : 0;
        sendInfo.status = info?.tabType ? info?.tabType : claimEnums.OPEN;
        delete sendInfo.tabType
        return sendInfo;
    }

    useEffect(() => {
        dispatch(claimPaymentActions.getClaimPayments(renderParams()));
    }, [info]);

    return (
        <div>
            <ClaimPaymentFilters renderParams={renderParams()}/>
            <ClaimPaymentsFragment claimsList={claimsList} renderParams={renderParams()}/>
        </div>
    );
};
