import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Popover } from '@material-ui/core';
import { ErrorText, FindLoad, FindSuccess, Images, PermissionList, RolePermission } from 'utils';
import { clientCommonStyle } from '../styles';
import { CreateChancel, DateTypeSelect, DeleteElement, SimpleModal } from 'components';
import { clientActions, httpRequestsOnSuccessActions } from 'store';

export const EnrolmentCard = ({ type, handleOpenCreate, info, handleEdit }) => {
    const params = useParams();
    const classes = clientCommonStyle();
    const addType = type === 'PRIMARY' ? 'Primary' : 'Secondary';
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [modalIsOpen, setModalIsOpen] = React.useState(null);
    const [date, setDate] = React.useState(moment.utc().format('YYYY-MM-DD'));
    const [error, setError] = React.useState(null);
    const dispatch = useDispatch();
    const terminateLoader = FindLoad('TERMINATE_CLIENT_ENROLLMENT');
    const terminate = FindSuccess('TERMINATE_CLIENT_ENROLLMENT');
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const loader = FindLoad('CHANGE_ENROLLMENT_TYPE');
    const successType = FindSuccess('CHANGE_ENROLLMENT_TYPE');

    useEffect(() => {
        if (successType?.length) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('CHANGE_ENROLLMENT_TYPE'));
            setModalIsOpen('');
        }
    }, [successType]);

    useEffect(() => {
        if (terminate?.length) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('TERMINATE_CLIENT_ENROLLMENT'));
            setModalIsOpen('');
        }
    }, [terminate]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        if (date) {
            const sendInfo = {
                'startDate': info?.startDate,
                'endDate': new Date(date),
            };
            dispatch(clientActions.terminateClientEnrollment(params?.id, info?.id, sendInfo));
        } else {
            setError('date');
        }
    };

    const handleChangeDate = (e) => {
        setDate(e);
        if (error === 'date') setError('');
    };

    const handleCreate = () => {
        const sendInfo = {
            clientId: params?.id,
            enrolmentId: info?.id,
            type: 'PRIMARY',
        };
        dispatch(clientActions.changeEnrollmentType(sendInfo));
    };

    return (
        <div className={classes.addRow}>
            {info ?
                <div style={{ width: '100%' }}>
                    <div className={classes.enrolmentCardTitle}>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className={classes.cardType}>
                                {addType}
                            </div>
                            <span>Funding Source</span>
                        </div>

                        {RolePermission([PermissionList.CLIENT_ENROLLMENT_MANAGE?.code]) &&
                            <button className={classes.moreButton} onClick={handleClick}>
                                <img src={Images.moreInfo} alt="icon" />
                            </button>
                        }
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                        >
                            <div
                                style={{ height: type === 'SECONDARY' ? '110px' : '75px' }}
                                className={classes.popoverWrapper}
                            >
                                <button onClick={() => handleEdit(info)}>Edit</button>
                                <button onClick={() => setModalIsOpen('Terminate')}>Terminate</button>
                                {type === 'SECONDARY' &&
                                    <button onClick={() => setModalIsOpen('Primary')}>{`Set to Primary`}</button>}
                            </div>
                        </Popover>
                    </div>
                    <div className={classes.enrolmentFunding}>
                        <p>{info?.funderName}</p>

                    </div>
                    <div className={classes.enrolmentDate}>
                        <p>ID:</p>
                        <span>{info?.enrolmentId}</span>
                    </div>
                    <div className={classes.enrolmentDate} style={{ marginBottom: 0 }}>
                        <p>Enrollment Date:</p>
                        <span>{moment.utc(info?.startDate).format('MM/DD/YYYY')}</span>
                    </div>
                </div>
                :
                (RolePermission([PermissionList.CLIENT_ENROLLMENT_MANAGE?.code]) ?
                        <div onClick={handleOpenCreate} className={classes.rowCenterWrapper}>
                            <img src={Images.addBluePlus} alt="icon" />
                            <p className={classes.addTitle}>{`Add ${addType} Enrollment`}</p>
                        </div>
                        :
                        <div className={classes.rowCenterWrapper} />
                )
            }

            <SimpleModal
                openDefault={!!modalIsOpen}
                handleOpenClose={() => setModalIsOpen(null)}
                content={
                    <div>
                        {modalIsOpen === 'Terminate' ?
                            <DeleteElement
                                align={'start'}
                                info="Terminate Enrollment"
                                text={'Are you sure you want to terminate this enrollment ?'}
                                handleDel={handleDelete}
                                handleClose={() => setModalIsOpen(null)}
                                innerText={'Terminate'}
                                loader={!!terminateLoader.length}
                                body={
                                    <div>
                                        <DateTypeSelect
                                            type={'modalInput'}
                                            name={'terminationDate'}
                                            outLabel={'Termination Date*'}
                                            handleGetDates={(e) => handleChangeDate(e.target.value)}
                                            values={date ? { terminationDate: moment.utc(date).format('MM/DD/YYYY')} : null}
                                            min={moment.utc(info?.startDate).subtract(1, 'day').format('YYYY-MM-DD')}
                                            error={
                                                error === 'date' ? `Termination Date ${ErrorText.isRequired}` : ''
                                            }
                                        />
                                    </div>
                                }
                            />
                            :
                            <div className={classes.setToPrimary}>
                                <p className={classes.primaryTitle}>Set to Primary?</p>
                                <p className={classes.primarySubtitle}>
                                    Are you sure you want to set this secondary enrollment to primary? This action will
                                    result in termination of your current primary enrollment.
                                </p>
                                <CreateChancel
                                    loader={!!loader.length}
                                    create={info ? 'Save' : 'Add'}
                                    chancel={'Cancel'}
                                    onCreate={handleCreate}
                                    onClose={() => setModalIsOpen(null)}
                                    buttonWidth="224px"
                                />
                            </div>
                        }
                    </div>
                }
            />

        </div>
    );
};