import React from 'react';
import { LoginBackBtn, SignIn } from 'components';
import { Svg } from 'assets';

export const CheckYourEmail = ({ email, toggleShowing }) => {
    const checkIfMail = /mail/.test(email);

    return (
        <div className="check-your-email">
            <Svg.CheckEmailSvg />

            <h2 className="check-your-email_title">
                Check Your Email
            </h2>

            <p className="check-your-email_description">
                We’ve sent a password reset link to <br />
                <span style={{ fontWeight: 600 }}>
                    {email}
                </span>
            </p>

            <div style={{ maxWidth: '360px', width: '100%' }}>
                <SignIn
                    type="submit"
                    width={'100%'}
                    text={'Open email app'}
                    handleClick={() => window.open(checkIfMail ? 'https://mail.ru/' : 'https://mail.google.com/', '_blank', 'noopener,noreferrer')}
                />
            </div>

            <div className="receive-the-email-wrapper">
                <p className="receive-the-email">
                    Didn’t receive the email?

                </p>
                <p
                    className="receive-the-email"
                    style={{ color: '#347AF0', fontWeight: 600, cursor: 'pointer' }}
                    onClick={() => toggleShowing(false)}
                >
                    Click to resend
                </p>
            </div>

            <LoginBackBtn/>
        </div>
    );
};

