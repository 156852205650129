export const ToastFail = (fail, errorMessage) => {
   if (fail) {
      return fail === "CREATE_PAY_CODE_GLOBAL"
         ? "Something went wrong"
         : fail === "EDIT_PAY_CODE_BY_ID_GLOBAL"
         ? "Something went wrong"
         : fail === "CREATE_OVERTIME_SETTINGS_GLOBAL"
         ? "Something went wrong"
         : fail === "EDIT_OVERTIME_SETTINGS_BY_ID_GLOBAL"
         ? "Something went wrong"
         : fail === "DELETE_OVERTIME_SETTINGS_BY_ID_GLOBAL"
         ? "Something went wrong"
         : fail === "DELETE_SERVICE_BY_ID_GLOBAL"
         ? "Something went wrong"
         : fail === "DELETE_CREDENTIAL_BY_ID_GLOBAL"
         ? "Something went wrong"
         : fail === "DELETE_DEPARTMENT_BY_ID_GLOBAL"
         ? "Something went wrong"
         : fail === "EDIT_CREDENTIAL_BY_ID_GLOBAL"
         ? "Something went wrong"
         : fail === "DELETE_JOB_BY_ID_GLOBAL"
         ? "Something went wrong"
         : fail === "EDIT_JOB_BY_ID_GLOBAL"
         ? "Something went wrong"
         : fail === "EDIT_SERVICE_BY_ID_GLOBAL"
         ? "Something went wrong"
         : fail === "EDIT_DEPARTMENT_BY_ID_GLOBAL"
         ? "Something went wrong"
         : fail === "CREATE_SERVICE_GLOBAL"
         ? "Something went wrong"
         : fail === "CREATE_CREDENTIAL_GLOBAL"
         ? "Something went wrong"
         : fail === "CREATE_DEPARTMENT_GLOBAL"
         ? "Something went wrong"
         : fail === "CREATE_JOB_GLOBAL"
         ? "Something went wrong"
         : fail === "CREATE_CREDENTIAL"
         ? "Something went wrong"
         : fail === "EDIT_CREDENTIAL_BY_ID"
         ? "Something went wrong"
         : fail === "DELETE_CREDENTIAL_BY_ID"
         ? "Something went wrong"
         : fail === "CREATE_GLOBAL_NOTE"
         ? "Something went wrong"
         : fail === "EDIT_GLOBAL_NOTE"
         ? "Something went wrong"
         : fail === "DELETE_GLOBAL_NOTE"
         ? "Something went wrong"
         : fail === "CREATE_ADMIN"
         ? "Something went wrong"
         : fail === "EDIT_ADMIN_BY_ID"
         ? "Something went wrong"
         : fail === "EDIT_PAY_CODE_BY_ID_GLOBAL"
         ? "Something went wrong"
         : fail === "CREATE_OVERTIME_SETTINGS_GLOBAL"
         ? "Something went wrong"
         : fail === "EDIT_OVERTIME_SETTINGS_BY_ID_GLOBAL"
         ? "Something went wrong"
         : fail === "DELETE_OVERTIME_SETTINGS_BY_ID_GLOBAL"
         ? "Something went wrong"
         : fail === "CREATE_MILEAGE"
         ? "Something went wrong"
         : fail === "EDIT_MILEAGE"
         ? "Something went wrong"
         : fail === "CREATE_UPLOAD"
         ? "Something went wrong"
         : fail === "CREATE_STAFF_SERVICE"
         ? "Something went wrong"
         : fail === "CHANGE_FUNDING_MODIFIER_STATUS"
         ? "Something went wrong"
         : fail === "CREATE_FUNDING_MODIFIER"
         ? "Something went wrong"
         : fail === "EDIT_FUNDING_MODIFIER"
         ? "Something went wrong"
         : fail === "CREATE_ROLE"
         ? "Something went wrong"
         : fail === "TERMINATE_CLIENT_ENROLLMENT"
         ? "Something went wrong"
         : fail === 'CANT_CHANGE_APPOINTMENT' ? "You can't change this Appointment" :
         fail === 'APPOINTMENT_OVERLAPPING_TOASTER' ? "Appointment is overlapping"  :
         fail === 'PAY_IN_FULL_REF' ? "You need fill Claim Payment Reference"  :
         fail === 'NOT_GREATER' ? "Please ensure that the total sum of the Deductible, Copay, and Coinsurance amounts entered do not exceed the Billed AMT."  :
         fail === 'PAID_GREATER' ? "Allowed amount cannot exceed Paid amount."  :
         fail === 'EDIT_CLAIM_PAYMENT_INFO' ? "It is not possible to update bill, check the set values" :

         fail === 'CALENDAR_INTEGRATION_FORBIDDEN' ? "Please check the calendar permissions and try again" :
         fail === 'CALENDAR_WRONG_INTEGRATION' ?  "Something went wrong" :
         fail === 'CREATE_PROCESS_PAYROLL' ?  "Oops! Something went wrong. Unable to process the selected payrolls. Please try again later." :
         fail === 'INVOICE_AMT_IS_GREAT' ?  "The Payment AMT cannot exceed the Total AMT."
            :
         false;
   }
   if (errorMessage) {
      return errorMessage === "Internal server error" ? "ooops!! statusCode 500" : false;
   }
};
