import React from "react";
import {Checkbox, TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {inputsStyle} from "./styles";
import {ErrMessage} from "../messages";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

export const CredentialSelect = ({
                                     label,
                                     permissionsList,
                                     placeholder,
                                     handleChange,
                                     styles,
                                     typeError,
                                     noChip,
                                     name,
                                     value,
                                     disabled,
                                     renderValue
                                 }) => {
    const classes = inputsStyle();

    return (
        <>
        <Autocomplete
            name={name}
            disabled={disabled}
            className={classes.autocompleteAutoCompleteStyle}
            multiple
            value={value}
            style={styles}
            id="checkboxes-tags-demo"
            options={permissionsList}
            disableCloseOnSelect
            // defaultValue={[{
            //     id: "649d207581a776261bf7d3eb",
            //     name: "cx",
            //     type: "CLEARANCES",
            // }]}
            onChange={(event, value) => handleChange(value)}
            getOptionLabel={(option) => renderValue(option)}
            renderOption={(option, {selected}) => (
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8, color:'rgb(52, 122, 240)'}}
                        checked={selected}
                    />
                    <div>
                        <p>{renderValue(option)}</p>
                    </div>
                </div>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={typeError}
                    className={classes.inputTextFieldAutoHeight}
                    variant="outlined"
                    label={label}
                    placeholder={placeholder}
                />
            )}
        />
            <ErrMessage
                text={typeError}
            />
        </>
    );
};
