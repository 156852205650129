import React from 'react';
import { motion } from 'framer-motion';
import { ButtonLanding } from 'components';
import { featuresForProductiveMgmt } from '../constants';
import { useWidth } from 'utils';
import { LandingSvg } from 'assets';
import { animationParams, animationVariants } from 'fragments';

export const FeaturesForProdMgmt = () => {
    const width = useWidth();

    return (
        <div className='features-wrapper'>
            <div className='features-block'>
                <div className='container'>

                    <div className='features-box'>

                        {/*<div className='lorem-button'>*/}
                        {/*    Lorem Ipsum*/}
                        {/*</div>*/}

                        <div className='title-button-block'>
                            <div style={{ overflow: 'hidden' }}>
                                <motion.h2
                                    className='content-title'
                                    {...animationParams}
                                    variants={animationVariants.titleVariant}
                                >
                                    Key Features
                                </motion.h2>
                            </div>

                            {width > 768 && (
                                <ButtonLanding
                                    text='Everything we do'
                                    contained={false}
                                    moovable={true}
                                    arrowIcon={true}
                                />
                            )}
                        </div>


                        <div className='features-list-box'>
                            {featuresForProductiveMgmt.map(({ Icon, featureTitle, featureDescription }, index) => (
                                <motion.div
                                    key={index}
                                    className='feature-card-wrapper'
                                    {...animationParams}
                                    variants={animationVariants.cardsVariant}
                                >
                                    <div className='feature-card-box'>
                                        <div className='feature-icon-text'>
                                            <Icon className='feature-card-svg' />
                                            <p className='feature-card-title'>
                                                {featureTitle}
                                            </p>
                                        </div>


                                        <p className='feature-card-description'>
                                            {featureDescription}
                                        </p>
                                    </div>

                                    <div className='feature-card-arrow-block'>
                                        <LandingSvg.ArrowRightSvg className='feature-card-arrow' />
                                    </div>
                                </motion.div>
                            ))}
                        </div>
                        {width <= 768 &&
                            <ButtonLanding
                                className='everything-button-mobile'
                                text='Everything we do'
                                contained={false}
                                arrowIcon={true}
                                moovable={true}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};
