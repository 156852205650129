import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel, Radio } from '@material-ui/core';
import axios from 'axios';
import moment from 'moment/moment';
import { CreateChancel, ErrMessage, Warning } from 'components';
import { FindErrorItem, FindLoad, FindSuccessItem, token, useModal } from 'utils';
import { claimActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';

const typeEnums = {
    AUTOMATIC: 'AUTOMATIC',
    MANUAL: 'MANUAL',
    ASPDF: 'ASPDF',
    ASTAB: 'ASTAB',
};

const ACTION_TYPE = 'SUBMIT_CLAIM';
export const SelectSubmissionMethod = ({ selectedList, afterSuccess }) => {
    const { close } = useModal();
    const officeAlly = useSelector((state) => state.system.officeAlly);
    const success = FindSuccessItem(ACTION_TYPE);
    const backError = FindErrorItem(ACTION_TYPE);
    const dispatch = useDispatch();
    const [error, setError] = useState(false);
    const loader = FindLoad(ACTION_TYPE);
    const [inputs, setInputs] = useState({});

    useEffect(() => {
        dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
    }, []);

    const handleDownload = (item) => {
        axios({
            url: `/claim/${item?.id}/1500Form/${inputs?.downloadType === typeEnums.ASPDF ? 'PDF' : 'TAB'}`,
            method: 'GET',
            responseType: inputs?.downloadType === typeEnums.ASPDF ? 'blob' : 'text',
            headers: { 'access-token': `${token}` },
        }).then((response) => {
            const blob = new Blob([response?.data], { type: 'text/tab-separated-values' });
            const url = inputs?.downloadType === typeEnums.ASPDF ? window.URL.createObjectURL(new Blob([response?.data])) : window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            const fileType = inputs?.downloadType === typeEnums.ASPDF ? 'pdf' : 'tab';
            // link.setAttribute('download', `${item?.displayId ? `${item?.displayId},` : ''}${moment().format('MM-DD-YYYY')}-1500Form.${fileType}`);
            link.setAttribute('download', `${moment().format('MM-DD-YYYY')}-1500Form.${fileType}`);
            document.body.appendChild(link);
            link.click();
            close();
        });
    };

    useEffect(() => {
        if (success) {
            // if(inputs?.type === typeEnums.MANUAL){
            //     handleDownload('')
            // }
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            afterSuccess();
            close();
        }
    }, [success]);

    const handleExport = () => {
        if (inputs?.type) {
            const submitType = inputs?.type === typeEnums.AUTOMATIC ? 'officeAlly' : 'manual';
            dispatch(claimActions.submitClaim(selectedList, '', submitType));
        } else {
            setError(true);
        }
    };

    const handleChange = (e) => {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        setError(false);
    };

    return (
        <div style={{ width: 400 }}>
            <p className="download-modal-title">Select Submission Method</p>
            <p className="download-modal-desc">
                Please choose how you would like to submit the selected claims
            </p>
            <div className="download-1500form-modal-body">
                <div
                    className={inputs?.type === typeEnums.AUTOMATIC ? 'form-type-box-active' : 'form-type-box-passive'}
                    style={{
                        marginBottom: '12px', borderColor: error ? '#F07379' : '',
                    }}
                >
                    <FormControlLabel
                        style={{opacity: !officeAlly?.id ? 0.5 : 1,}}
                        disabled={!officeAlly?.id}
                        onChange={handleChange}
                        name={'type'}
                        value={typeEnums.AUTOMATIC}
                        label="Submit via Office Ally (Automatic)"
                        control={<Radio
                            style={{ color: inputs?.type === typeEnums.AUTOMATIC ? '#347AF0' : '#D0D5DD' }}
                            checked={inputs?.type === typeEnums.AUTOMATIC}
                        />}
                    />
                    {!officeAlly?.id &&
                        <p className='office-ally-warning-text'>
                            Please enable Office Ally integration in
                            <a style={{ margin: '0 4px', color: '#172B4D', fontWeight: 600 }} href={'/system'}
                               target={'_blank'}> settings </a>
                            to submit claims.
                        </p>
                    }
                </div>



                <div className={inputs?.type === typeEnums.MANUAL ? 'form-type-box-active' : 'form-type-box-passive'}
                     style={{ borderColor: error ? '#F07379' : '' }}
                >
                    <FormControlLabel
                        onChange={handleChange}
                        name={'type'}
                        value={typeEnums.MANUAL}
                        label="Manual Submission"
                        control={<Radio
                            style={{ color: inputs?.type === typeEnums.MANUAL ? '#347AF0' : '#D0D5DD' }}
                            checked={inputs?.type === typeEnums.MANUAL}
                        />}
                    />

                    {/*{inputs?.type === typeEnums.MANUAL &&*/}
                    {/*    <div className="download-manual-types-body">*/}
                    {/*        <FormControlLabel*/}
                    {/*            style={{ color: '#172B4D' }}*/}
                    {/*            onChange={handleChange}*/}
                    {/*            name={'downloadType'}*/}
                    {/*            value={typeEnums.ASPDF}*/}
                    {/*            label="Download as PDF"*/}
                    {/*            control={<Radio*/}
                    {/*                style={{*/}
                    {/*                    color: inputs?.downloadType === typeEnums.ASPDF ? '#347AF0' : '#D0D5DD',*/}
                    {/*                    fontSize: 12,*/}
                    {/*                }}*/}
                    {/*                checked={inputs?.downloadType === typeEnums.ASPDF}*/}
                    {/*            />}*/}
                    {/*        />*/}
                    {/*        <FormControlLabel*/}
                    {/*            style={{ color: '#172B4D' }}*/}
                    {/*            onChange={handleChange}*/}
                    {/*            name={'downloadType'}*/}
                    {/*            value={typeEnums.ASTAB}*/}
                    {/*            label="Tab-delimited (.tab)"*/}
                    {/*            control={<Radio*/}
                    {/*                style={{ color: inputs?.downloadType === typeEnums.ASTAB ? '#347AF0' : '#D0D5DD' }}*/}
                    {/*                checked={inputs?.downloadType === typeEnums.ASTAB}*/}
                    {/*            />}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*}*/}
                </div>
                <ErrMessage
                    style={{ marginBottom: '12px' }}
                    text={
                       error ? 'Please select method' :
                           backError?.error ? backError?.error :
                           ''}
                />
            </div>

            <div>
                <CreateChancel
                    loader={loader?.length}
                    create={'Submit'}
                    chancel={'Cancel'}
                    onCreate={handleExport}
                    onClose={close}
                    buttonWidth="300px"
                />
            </div>
        </div>
    );
};