import {call, put, takeLatest} from "redux-saga/effects";

import {httpRequestsOnErrorsActions} from "../http_requests_on_errors";
import {httpRequestsOnLoadActions} from "../http_requests_on_load";
import {httpRequestsOnSuccessActions} from "../http_requests_on_success";
import {
    DELETE_GENERAL_REPORT,
    DOWNLOAD_PDF,
    GET_GENERAL_REPORTS, GET_GENERAL_REPORTS_SUCCESS,
    GET_SIGNATURE_REPORTS,
    GET_SIGNATURE_REPORTS_SUCCESS, GET_UTILIZATION_REPORT,
} from './reports.types';
import {authService} from "./reports.service";

/** Reports */

function* getGeneralReposts(action) {
    if(action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    try {
        const res = yield call(authService.getGeneralRepostsService, action?.payload?.params);
        yield put({
            type: GET_GENERAL_REPORTS_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}
function* deleteGeneralReposts(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.deleteGeneralRepostService, action?.payload?.id);
        yield put({
            type: GET_GENERAL_REPORTS,
            payload: {skip: 0, limit: 30, load: 'noLoad'}
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    }
}

function* getSignatureReports(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    try {
        const res = yield call(authService.getSignatureReportsService, action?.payload?.params);
        yield put({
            type: GET_SIGNATURE_REPORTS_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));

    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

function* downloadPdf(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.downloadPdf, action?.payload?.params);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* getUtilizationReport(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.getUtilizationReportService, action?.payload?.params);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

/** End */

export const watchReports = function* watchReportsSaga() {
    /** Reports */
    yield takeLatest(GET_GENERAL_REPORTS, getGeneralReposts);
    yield takeLatest(DELETE_GENERAL_REPORT, deleteGeneralReposts);
    yield takeLatest(GET_SIGNATURE_REPORTS, getSignatureReports);
    yield takeLatest(DOWNLOAD_PDF, downloadPdf);
    yield takeLatest(GET_UTILIZATION_REPORT, getUtilizationReport);
    /** End */

};