import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useForm} from 'react-hook-form';
import {EmailValidator, FindErrorItem, FindLoad, FindSuccessItem} from 'utils';
import {authActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions} from 'store';
import {InputMain, LoginBackBtn, SignIn} from 'components';
import {Svg} from 'assets';
import {CheckYourEmail} from './checkYourEmail';

const ACTION_TYPE = 'GET_RECOVERY_LINK';

export const ForgotModal = ({}) => {
    const {handleSubmit, control, setError, clearErrors, watch,} = useForm({
        defaultValues: {
            email: '',
        },
    });
    const dispatch = useDispatch();
    const [showCheckEmail, toggleShowing] = useState(false);
    const success = FindSuccessItem(ACTION_TYPE);
    const backError = FindErrorItem(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);

    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            toggleShowing(true);
        }
        if (backError?.error === 'User Identification with this resource was not found.') {
            setError('email', {
                type: 'required',
                message: 'User with this email was not found.',
            });
        }
    }, [success, backError]);

    useEffect(() => {
        return () => {
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
            clearErrors();
        };
    }, []);

    const getLink = (data) => {
        dispatch(authActions.getRecoveryLink(data));
    };

    useEffect(() => {
        if (backError?.error === 'User with this email was not found') {
            setError('email', {
                type: 'required',
                message: 'User with this email was not found',
            });
        }


    }, [backError]);


    return (
        <div className='login-wrapper'>
            <div className='login-main-content'>
                {showCheckEmail ? <CheckYourEmail email={watch('email')} toggleShowing={toggleShowing}/> : <>
                    <h3 className='login-title'>
                        Forgot your Password?
                    </h3>

                    <p className='login-description'>
                        Enter your email address below, and we’ll send you a link to reset your password.
                    </p>

                    <form className='login-form' onSubmit={handleSubmit(getLink)}>
                        <InputMain
                            variant='outlined'
                            name='email'
                            control={control}
                            placeholder='Enter your email address'
                            labelHeader='Email Address'
                            rules={{
                                required: 'Input is not field',
                                pattern: {
                                    value: EmailValidator,
                                    message: 'Invalid Email Format',
                                },
                            }}
                            startAdornment={<Svg.SmsSvg/>}
                        />

                        <div className='login-buttons-block'>
                            <SignIn
                                type='submit'
                                margin={'15px 0 0 0'}
                                loader={!!loader?.length}
                                width={'100%'}
                                text={'Get Reset Link'}
                            />
                            <LoginBackBtn/>
                        </div>
                    </form>
                </>
                }
            </div>
        </div>
    );
};
