import axios from 'axios';

export const authService = {
    /** Staff */

    createAdminService: (body) => axios.post(`/staff`, body, { auth: true }),

    reinviteAdminService: (id) => axios.post(`/authn/${id}/resendInvite`, null, { auth: true }),

    createAdminDetailsService: (id, body) => axios.patch(`/staff/${id}/details`, body, { auth: true }),

    editAdminByIdService: (id, body) => axios.patch(`/staff/${id}/general`, body, { auth: true }),

    uploadStaffAvatar: (body) => axios.post(`/staff/avatar`, { ...body }, { auth: true }),

    getAdminsService: (data) => axios.get('/staff', {
        auth: true,
        params: {
            ...data,
            sortType: data?.sortType ? data?.sortType : 'fullName',
            orderType: data?.orderType ? data?.orderType : 'ASC',
        },
    }),


    getBasicAdminsService: (data) => axios.get('/staff/basic/info', { auth: true, params: { ...data } }),


    getAdminByIdService: (id) => axios.get(`/staff/${id}`, { auth: true }),

    changeAdminStatusService: (id, status) => axios.patch(`/staff/${id}/status`, null, {
        auth: true,
        params: { status: status },
    }),
    /** End */

    /** Staff Credentials */

    createCredentialService: (body) => axios.post(`/staff/credentials`, body, { auth: true }),

    getCredentialService: (id, data) => axios.get(`/staff/credentials/staff/${id}`, {
        auth: true,
        params: { ...data },
    }),

    editCredentialByIdService: (id, body) => axios.put(`/staff/credentials/${id}`, body, { auth: true }),

    deleteCredentialByIdService: (id) => axios.delete(`/staff/credentials/${id}`, { auth: true }),

    /** End */

    /** Staff Employment */

    createEmploymentService: (id, body) => axios.post(`/staff/${id}/employment`, body, { auth: true }),

    editEmploymentService: (staffId, id, body) => axios.patch(`/staff/${staffId}/employment/${id}`, body, { auth: true }),

    getEmploymentService: (id) => axios.get(`/staff/${id}/employment/history`, { auth: true }),

    deleteEmploymentService: (staffId, id) => axios.delete(`/staff/${staffId}/employment/${id}`, { auth: true }),

    /** End */

    /** Staff PayCode */

    getAllPayCodesService: (id) => axios.get(`/staffPayCode/staff/${id}`, { auth: true }),

    getPayCodeService: (id, data) => axios.get(`/staffPayCode/staff/${id}`, { auth: true, params: { ...data } }),

    createPayCodeService: (body) => axios.post(`/staffPayCode`, body, { auth: true }),

    editPayCodeService: (body, id) => axios.patch(`/staffPayCode/${id}`, body, { auth: true }),

    terminatePayCodeService: (id) => axios.patch(`/staffPayCode/${id}/inActive`, null, { auth: true }),

    /** End */

    /** Staff Service */

    getStaffServService: (id) => axios.get(`/staff/${id}/service`, { auth: true }),

    createStaffServService: (id, ids) => axios.post(`/staff/${id}/services`, { serviceIds: ids }, { auth: true }),

    deleteStaffServService: (id, serviceId) => axios.delete(`/staff/${id}/service/${serviceId}`, { auth: true }),

    /** End */

    /** Staff TimeSheet */


    getTimesheetService: (id, data) => axios.get(`/payroll/timesheet/staff/${id}`, {
        auth: true,
        params: { ...data },
    }),

    getTimesheetById: (id) => axios.get(`/payroll/timesheet/${id}`, { auth: true }),

    createTimesheetService: (body) => axios.post(`/payroll/timesheet`, body, { auth: true }),

    editTimesheetService: (body, id) => axios.patch(`/payroll/timesheet/${id}`, body, { auth: true }),

    /** End */

    isClinicianService: (id, isClinical) => axios.patch(`/staff/${id}/${isClinical}`, null, { auth: true }),

    /** Staff Documents */

    uploadService: (file, id) => axios.post(`/staff/${id}/documents`, { ...file }, { auth: true }),

    editService: ({ id, dockId, file }) => axios.patch(`/staff/${id}/documents/${dockId}`, { ...file }, { auth: true }),

    getFilesService: (id) => axios.get(`/staff/${id}/documents`, { auth: true }),

    deleteFilesService: (staffId, id) => axios.delete(`/staff/${staffId}/documents/${id}`, { auth: true }),

    /** End */

    getAllServicesService: () => axios.get(`/funding/service/all`, { auth: true }),

    getStaffForApptService: () => axios.get(`/`, { auth: true }),

};
