import React from 'react';
import { motion } from 'framer-motion';
import { animationParams, animationVariants } from 'fragments';

export const CoreValuesCard = ({ Icon, title, description, className }) => {
    return (
        <motion.div
            className={`core-values-card-wrapper ${className ? className : ''}`}
            {...animationParams}
            variants={animationVariants.cardsVariant}
        >
            <Icon className='core-values-card-icon' />

            <div className='core-values-title-description'>
                <p className='core-card-values-title'>
                    {title}
                </p>

                <p className='core-card-values-description'>
                    {description}
                </p>
            </div>
        </motion.div>
    );
};

