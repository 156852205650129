import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { DateRow, TextRow } from './tableRows';
import { Images } from 'utils';

export const filesHead = ( ) => {
    return [
        { name: 'name', title: 'Document Name', custom: false },
        { name: 'description', title: 'Document Description', custom: false },
        { name: 'date', title: 'Date Uploaded', custom: false, disabled: true },
        { name: '', title: 'File Type', custom: false, disabled: true, width: '150px' },
        { name: '', title: 'Action', custom: false, disabled: true, width: '230px' },
    ];
};

const renderFileTypeAndColor = (file) => {

    const background =
        file === 'svg+xml' ? '#FFCA33' :
            file === 'jpeg' ? '#51439E' :
                file === 'jpg' ? '#51439E' :
                    file === 'png' ? '#155EEF' :
                        file === 'pdf' ? '#CC400C' :
                            '#FFCA33';

    const text = file === 'svg+xml' ? 'SVG' :
        file === 'jpeg' ? 'JPEG' :
            file === 'jpg' ? 'JPG' :
                file === 'png' ? 'PNG' :
                    file === 'pdf' ? 'PDF' :
                        file;

    return (
        <div>
            <div className="file-type-box" style={{ background: background }}>
                {text}
            </div>
        </div>
    );
};
export const filesBody = (checkPermissions, selectedId) => {
    return [
        {
            rowText: (item) => <TextRow name={item?.name} textWidth={9} />,
        },
        {
            rowText: (item) => <TextRow name={item?.description} textWidth={9} />,
        },
        {
            rowText: (item) => <DateRow date={item?.uploadedDate} />,
        },
        {
            rowText: (item) => <div>{renderFileTypeAndColor(item?.file?.format)}</div>,
        },
        {
            button: (fun, item) => (
                <div className={'document-actions'}>
                    {checkPermissions &&
                        <button onClick={() => fun('edit')}>
                            <img src={Images.edit} alt={'icon'} />
                        </button>
                    }
                    <a href={item?.file?.url} target={'_blank'}>
                        <button>
                            <img src={Images.showEye} alt={'icon'} />
                        </button>
                    </a>
                    <button onClick={() => fun('download')} style={{ position: 'relative' }}>
                        {selectedId === item?._id || selectedId === item?.id ?
                            <CircularProgress style={{
                                color: '#172B4D',
                                width: '15px',
                                height: '15px',
                                margin: '-8px 0 0 -8px',
                                position: 'absolute',
                            }} />
                            :
                            <img src={Images.downloadPdf} alt={'icon'} />
                        }
                    </button>
                    {checkPermissions &&
                        <button onClick={() => fun('delete')}>
                            <img src={Images.trash} alt={'icon'} />
                        </button>
                    }
                </div>
            ),
            notClickable: true,
        },
    ];
};

export const fileNotYet = {
    title: 'No Files Uploaded',
    subTitle: 'Upload files to store relevant documents here.',
    image: Images.notFile,
}
