import { Images, Videos } from 'assets';
import { PermissionList, RolePermission } from 'utils';


export const helpCenterMenu = [
    {
        key: 'USER_ACCOUNT',
        title: 'User Account',
        subMenu: [
            {
                key: 'CHANGING_PROFILE',
                title: 'Changing Profile Picture',
            },
            {
                key: 'CHANGING_PASSWORD',
                title: 'Changing Password',
            },
        ],
    },


    RolePermission([PermissionList.ROLES_CREATE?.code]) &&
    {
        key: 'ROLE_MANAGEMENT',
        title: 'Role Management',
        subMenu: [
            {
                key: 'CREATING_ROLES_PERMISSIONS',
                title: 'Creating Roles and Permissions for Different Users',
            },
            {
                key: 'ADDING_DELETING_ROLE',
                title: 'Adding/Deleting Permissions to a Role',
            },
            {
                key: 'DELETING_ROLE',
                title: 'Deleting a Role',
            },
            {
                key: 'RENAMING_ROLES',
                title: 'Renaming Roles',
            },
        ],
    },


    RolePermission([
        PermissionList.FS_LIST_READ?.code,
        PermissionList.FS_CREATE?.code,
        PermissionList.FS_SERVICE_CREATE?.code,
        PermissionList.FS_STATUS?.code,
        PermissionList.FS_NOTE_MANAGE?.code,
        PermissionList.FS_FILE_MANAGE?.code,
        PermissionList.FS_FILE_READ?.code,
    ]) &&
    {
        key: 'FUNDING_SOURCE',
        title: 'Funding Source',
        subMenu: [
            RolePermission([PermissionList.FS_CREATE?.code]) &&
            {
                key: 'CREATE_FUNDING',
                title: 'Creating a Funding Source',
            },
            RolePermission([PermissionList.FS_SERVICE_CREATE?.code]) &&
            {
                key: 'ADDING_SERVICE_FUNDING',
                title: 'Adding Services to a Funding Source',
            },
            RolePermission([PermissionList.FS_SERVICE_CREATE?.code]) &&
            {
                key: 'ADDING_CHARGE_RATES',
                title: 'Adding Charge Rates to a Funding Source',
            },
            RolePermission([PermissionList.FS_STATUS?.code]) &&
            {
                key: 'ACTIVATING_INACTIVATING_FUNDING',
                title: 'Activating or Inactivating a Funding Source',
            },
            RolePermission([PermissionList.FS_CREATE?.code]) &&
            {
                key: 'MERGING_APPOINTMENTS',
                title: 'Merging Appointments',
            },
            RolePermission([PermissionList.FS_LIST_READ?.code]) &&
            {
                key: 'DOWNLOADING_CSV_FUNDING',
                title: 'Downloading a CSV of the Funding Sources',
            },
            RolePermission([PermissionList.FS_CREATE?.code]) &&
            {
                key: 'EDITING_FUNDING',
                title: 'Editing Funding Source',
            },
            RolePermission([PermissionList.FS_SERVICE_CREATE?.code]) &&
            {
                key: 'INACTIVATING_ACTIVE_FUNDING',
                title: 'Inactivating an Active Funding Source Service',
            },
            RolePermission([PermissionList.FS_SERVICE_CREATE?.code]) &&
            {
                key: 'ACTIVATING_INACTIVE_FUNDING',
                title: 'Activating an Inactive Funding Source Service',
            },
            RolePermission([PermissionList.FS_SERVICE_CREATE?.code]) &&
            {
                key: 'EDITING_FUNDING_SERVICE',
                title: 'Editing a Funding Source Service',
            },
            RolePermission([PermissionList.FS_NOTE_MANAGE?.code]) &&
            {
                key: 'ADDING_NOTES_FUNDING',
                title: 'Adding Notes to a Funding Source',
            },
            RolePermission([PermissionList.FS_NOTE_MANAGE?.code]) &&
            {
                key: 'EDITING_FUNDING_NOTES',
                title: 'Editing Funding Source Notes',
            },
            RolePermission([PermissionList.FS_NOTE_MANAGE?.code]) &&
            {
                key: 'DELETING_FUNDING_NOTES',
                title: 'Deleting Funding Source Notes',
            },
            RolePermission([PermissionList.FS_HISTORY_READ?.code]) &&
            {
                key: 'VIEWING_FUNDING_CHANGES_HISTORY',
                title: 'Viewing Funding Source Changes History',
            },
            RolePermission([PermissionList.FS_FILE_MANAGE?.code]) &&
            {
                key: 'UPLOADING_FUNDING_FILES',
                title: 'Uploading Funding Source Files',
            },
            RolePermission([PermissionList.FS_FILE_MANAGE?.code]) &&
            {
                key: 'EDITING_FUNDING_FILES',
                title: 'Editing Funding Source Files',
            },
            RolePermission([PermissionList.FS_FILE_MANAGE?.code]) &&
            {
                key: 'DELETING_FUNDING_FILES',
                title: 'Deleting Funding Source Files',
            },
            RolePermission([PermissionList.FS_FILE_MANAGE?.code]) &&
            {
                key: 'DOWNLOADING_FUNDING_FILES',
                title: 'Downloading Funding Source Files',
            },
            RolePermission([PermissionList.FS_FILE_READ?.code]) &&
            {
                key: 'VIEWING_FUNDING_FILES',
                title: 'Viewing the Funding Source Files',
            },
        ],
    },


    RolePermission([
        PermissionList.STAFF_LIST_READ.code,
        PermissionList.STAFF_CREATE.code,
        PermissionList.STAFF_PAYCODE_MANAGE.code,
        PermissionList.STAFF_TIMESHEET_CREATE.code,
        PermissionList.STAFF_CREDENTIAL_MANAGE.code,
        PermissionList.STAFF_ROLES_MANAGE.code,
        PermissionList.STAFF_SERVICE_MANAGE.code,
        PermissionList.STAFF_NOTE_MANAGE.code,
        PermissionList.STAFF_HISTORY_READ.code,
        PermissionList.STAFF_FILE_MANAGE.code,
        PermissionList.STAFF_READ.code,
        PermissionList.STAFF_CREDENTIAL_READ?.code,
        PermissionList.STAFF_STATUS.code,
    ]) &&
    {
        key: 'STAFF',
        title: 'Staff',
        subMenu: [
            RolePermission([PermissionList.STAFF_LIST_READ.code]) &&
            {
                key: 'DOWN_CSV_STAFF',
                title: 'Downloading a CSV of the Staff',
            },
            RolePermission([PermissionList.STAFF_CREATE.code]) &&
            {
                key: 'ADD_STAFF_MEMBER',
                title: 'Adding Staff Members',
            },
            RolePermission([PermissionList.STAFF_PAYCODE_MANAGE.code]) &&
            {
                key: 'ADD_STAFF_PAYCODES',
                title: 'Adding Staff Paycodes',
            },
            RolePermission([PermissionList.STAFF_TIMESHEET_CREATE.code]) &&
            {
                key: 'ADD_STAFF_TIMESHEET_PAYCODES',
                title: 'Adding Staff Timesheets (from Paycode)',
            },
            RolePermission([PermissionList.STAFF_TIMESHEET_CREATE.code]) &&
            {
                key: 'ADD_STAFF_TIMESHEET_FIXES_PAY',
                title: 'Adding Staff Timesheets (Fixed Pay)',
            },
            RolePermission([PermissionList.STAFF_CREDENTIAL_MANAGE.code]) &&
            {
                key: 'ADD_STAFF_CREDENTIALS',
                title: 'Adding Staff Credentials',
            },
            RolePermission([PermissionList.STAFF_ROLES_MANAGE.code]) &&
            {
                key: 'ADD_STAFF_ACCESS',
                title: 'Adding Staff Access',
            },
            RolePermission([PermissionList.STAFF_SERVICE_MANAGE.code]) &&
            {
                key: 'ADD_STAFF_SERVICES',
                title: 'Adding Staff Services',
            },
            RolePermission([PermissionList.STAFF_NOTE_MANAGE.code]) &&
            {
                key: 'ADD_STAFF_NOTES',
                title: 'Adding Staff Notes',
            },
            RolePermission([PermissionList.STAFF_HISTORY_READ.code]) &&
            {
                key: 'VIEW_STAFF_PROFILE_CHANGE',
                title: 'Viewing Staff Profile Change History',
            },
            RolePermission([PermissionList.STAFF_FILE_MANAGE.code]) &&
            {
                key: 'ADD_STAFF_FILES',
                title: 'Adding Staff Files',
            },
            RolePermission([PermissionList.STAFF_CREATE.code]) &&
            {
                key: 'ADD_EDIT_STAFF_INFO',
                title: 'Adding/Editing Staff General Information',
            },
            RolePermission([PermissionList.STAFF_CREATE.code]) &&
            {
                key: 'ADD_STAFF_FILES_2',
                title: 'Adding Staff Other Details',
            },
            RolePermission([PermissionList.STAFF_CREATE.code]) &&
            {
                key: 'ADD_STAFF_MEMBER_CURRENT_PAST',
                title: 'Adding Staff Member’s Current and Past Employment',
            },
            RolePermission([PermissionList.STAFF_READ.code]) &&
            {
                key: 'VIEW_STAFF_MEMBER_CURRENT',
                title: 'Viewing Staff Member’s Current Employment',
            },
            RolePermission([PermissionList.STAFF_READ.code]) &&
            {
                key: 'VIEW_STAFF_MEMBER_PAST',
                title: 'Viewing Staff Member’s Past Employment',
            },
            RolePermission([PermissionList.STAFF_PAYCODE_MANAGE.code]) &&
            {
                key: 'INACTIVE_STAFF_PAYCOD',
                title: 'Inactivating Staff Paycode',
            },
            RolePermission([PermissionList.STAFF_TIMESHEET_CREATE.code]) &&
            {
                key: 'EDIT_STAFF_TIMESHEET',
                title: 'Editing Staff Timesheets (for Fixed and Paycode-Based Timesheets)',
            },
            RolePermission([PermissionList.STAFF_CREDENTIAL_MANAGE.code]) &&
            {
                key: 'EDIT_STAFF_CREDENTIALS',
                title: 'Editing Staff Credentials',
            },
            RolePermission([PermissionList.STAFF_CREDENTIAL_MANAGE?.code, PermissionList.STAFF_CREDENTIAL_READ?.code]) &&
            {
                key: 'VIEW_STAFF_CREDENTIALS',
                title: 'Viewing Staff Credentials',
            },
            RolePermission([PermissionList.STAFF_CREDENTIAL_MANAGE.code]) &&
            {
                key: 'DELETE_STAFF_CREDENTIALS',
                title: 'Deleting Staff Credentials',
            },
            RolePermission([PermissionList.STAFF_ROLES_MANAGE?.code]) &&
            {
                key: 'DELETE_STAFF_ACCESS',
                title: 'Deleting Staff Access',
            },
            RolePermission([PermissionList.STAFF_SERVICE_MANAGE.code]) &&
            {
                key: 'DELETE_STAFF_SERVICES',
                title: 'Deleting Staff Services',
            },
            RolePermission([PermissionList.STAFF_NOTE_MANAGE.code]) &&
            {
                key: 'EDIT_STAFF_NOTES',
                title: 'Editing Staff Notes',
            },
            RolePermission([PermissionList.STAFF_NOTE_MANAGE.code]) &&
            {
                key: 'DELETE_STAFF_NOTES',
                title: 'Deleting Staff Notes',
            },
            RolePermission([PermissionList.STAFF_FILE_MANAGE.code]) &&
            {
                key: 'DELETE_STAFF_FILES',
                title: 'Deleting Staff Files',
            },
            RolePermission([PermissionList.STAFF_CREATE.code]) &&
            {
                key: 'EDIT_STAFF_PAST_EMPLOYMENT',
                title: 'Editing Staff Member’s Past Employment',
            },
            RolePermission([PermissionList.STAFF_CREATE.code]) &&
            {
                key: 'DELETE_STAFF_PAST_EMPLOYMENT',
                title: 'Deleting Staff Member’s Past Employment',
            },
            RolePermission([PermissionList.STAFF_STATUS.code]) &&
            {
                key: 'ACTIVE_INACTIVE_STAFF_MEMBER',
                title: 'Activating/Inactivating a Staff Member',
            },
        ],
    },


    RolePermission([

        PermissionList.CLIENT_LIST_READ?.code,
        PermissionList.CONNECTED_CLIENT_READ?.code,
        PermissionList.CLIENT_CREATE?.code,
        PermissionList.CLIENT_CONTACT_MANAGE?.code,
        PermissionList.CLIENT_ENROLLMENT_MANAGE?.code,
        PermissionList.CLIENT_NOTE_MANAGE?.code,
        PermissionList.CLIENT_HISTORY_READ?.code,
        PermissionList.CLIENT_STAFF_ASSIGNMENT?.code,
        PermissionList.CLIENT_STATUS?.code,
        PermissionList.CLIENT_FILE_READ?.code,
        PermissionList.CLIENT_FILE_MANAGE?.code,
        PermissionList.CLIENT_NOTE_READ?.code,
        PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code,
        PermissionList.CLIENT_AUTHORIZATION_READ?.code,
    ]) &&
    {
        key: 'CLIENT',
        title: 'Client',
        subMenu: [
            RolePermission([PermissionList.CLIENT_CREATE?.code]) &&
            {
                key: 'ADDING_CLIENTS',
                title: 'Adding Clients',
            },
            RolePermission([PermissionList.CLIENT_CONTACT_MANAGE?.code]) &&
            {
                key: 'ADDING_CONTACT_CLIENT',
                title: 'Adding a Contact to a Client',
            },
            RolePermission([PermissionList.CLIENT_ENROLLMENT_MANAGE?.code]) &&
            {
                key: 'ADDING_ENROLLMENTS_CLIENT',
                title: 'Adding Enrollments to Clients',
            },
            RolePermission([PermissionList.CLIENT_NOTE_MANAGE?.code]) &&
            {
                key: 'ADDING_NOTES_CLIENT',
                title: 'Adding Notes to a Client',
            },
            RolePermission([PermissionList.CLIENT_HISTORY_READ?.code]) &&
            {
                key: 'CLIENT_PROFILE_CHANGE',
                title: 'Viewing Client Profile’s Change History',
            },
            RolePermission([PermissionList.CLIENT_FILE_MANAGE?.code]) &&
            {
                key: 'ADDING_FILES_CLIENT',
                title: 'Adding Files to a Client',
            },
            RolePermission([PermissionList.CLIENT_STAFF_ASSIGNMENT?.code]) &&
            {
                key: 'ASSIGNING_STAFF_CLIENT',
                title: 'Assigning Staff to a Client',
            },
            RolePermission([PermissionList.CLIENT_STAFF_ASSIGNMENT?.code]) &&
            {
                key: 'DELETING_ASSIGNED_STAFF',
                title: 'Deleting Assigned Staff from a Client',
            },
            RolePermission([PermissionList.CLIENT_STAFF_ASSIGNMENT?.code]) &&
            {
                key: 'VIEW_ASSIGNED_STAFF_CLIENT',
                title: 'Viewing Assigned Staff for a Client',
            },
            RolePermission([PermissionList.CLIENT_STATUS?.code]) &&
            {
                key: 'MAKING_CLIENT_AC_IN',
                title: 'Making a Client Active/Inactive',
            },
            RolePermission([PermissionList.CLIENT_FILE_READ?.code]) &&
            {
                key: 'VIEW_CLIENT_FILES',
                title: 'Viewing Client Files',
            },
            RolePermission([PermissionList.CLIENT_FILE_MANAGE?.code]) &&
            {
                key: 'EDITING_CLIENT_FILES',
                title: 'Editing Client Files',
            },
            RolePermission([PermissionList.CLIENT_FILE_MANAGE?.code]) &&
            {
                key: 'DOWN_CLIENT_FILES',
                title: 'Downloading Client Files',
            },
            RolePermission([PermissionList.CLIENT_FILE_MANAGE?.code]) &&
            {
                key: 'DELETE_CLIENT_FILES',
                title: 'Deleting Client Files',
            },
            RolePermission([PermissionList.CLIENT_NOTE_READ?.code]) &&
            {
                key: 'VIEW_CLIENT_NOTES',
                title: 'Viewing Client Notes',
            },
            RolePermission([PermissionList.CLIENT_NOTE_MANAGE?.code]) &&
            {
                key: 'EDITING_CLIENT_NOTES',
                title: 'Editing Client Notes',
            },
            RolePermission([PermissionList.CLIENT_NOTE_MANAGE?.code]) &&
            {
                key: 'DELETE_CLIENT_NOTES',
                title: 'Deleting Client Notes',
            },
            RolePermission([PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code]) &&
            {
                key: 'ADDING_AUTH_CLIENT',
                title: 'Adding Authorization to a Client',
            },
            RolePermission([PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code]) &&
            {
                key: 'ADD_CODES_CLIENT_AUTH',
                title: 'Adding Diagnostic Codes to a Client Authorization',
            },
            RolePermission([PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code]) &&
            {
                key: 'ADD_AUTH_SERVICE_CLIENT_AUTH',
                title: 'Adding an Authorized Service to a Client\'s Authorization',
            },
            RolePermission([PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code]) &&
            {
                key: 'EDIT_CLIENT_AUTH',
                title: 'Editing a Client Authorization',
            },
            RolePermission([PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code]) &&
            {
                key: 'EDIT_CLIENT_AUTH_SERVICE',
                title: 'Editing an Authorized Service',
            },
            RolePermission([PermissionList.CLIENT_AUTHORIZATION_READ?.code, PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code]) &&
            {
                key: 'VIEW_PAST_AUTH',
                title: 'Viewing Past Authorizations',
            },
            RolePermission([PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code]) &&
            {
                key: 'MANAGE_APPT_VISIBLE',
                title: 'Managing the "Appt Visible" Toggle for Past Authorizations',
            },
            RolePermission([PermissionList.CLIENT_ENROLLMENT_MANAGE?.code]) &&
            {
                key: 'EDIT_PRIM_SECOND_ENROLL',
                title: 'Editing Primary or Secondary Enrollment',
            },
            RolePermission([PermissionList.CLIENT_ENROLLMENT_MANAGE?.code]) &&
            {
                key: 'TERMINATE_PRIM_SECOND_ENROL',
                title: 'Terminating a Primary or Secondary Enrollment',
            },
            RolePermission([PermissionList.CLIENT_ENROLLMENT_MANAGE?.code]) &&
            {
                key: 'SETTING_SECOND_ENROLL_PRIMARY',
                title: 'Setting a Secondary Enrollment as Primary',
            },
            RolePermission([PermissionList.CLIENT_CONTACT_MANAGE?.code]) &&
            {
                key: 'EDIT_CLIENT_CONTACT',
                title: 'Editing Client Contacts',
            },
            RolePermission([PermissionList.CLIENT_CONTACT_MANAGE?.code]) &&
            {
                key: 'DELETE_CLIENT_CONTACT',
                title: 'Deleting Client Contacts',
            },
            RolePermission([PermissionList.CLIENT_CREATE?.code]) &&
            {
                key: 'ADD_ADDRESS_CLIENT',
                title: 'Adding an Address for a Client',
            },
            RolePermission([PermissionList.CLIENT_CREATE?.code]) &&
            {
                key: 'EDIT_INFO_CLIENT',
                title: 'Editing General Info for a Client',
            },
            RolePermission([PermissionList.CLIENT_CREATE?.code]) &&
            {
                key: 'EDIT_OTHER_INFO_CLIENT',
                title: 'Editing Other Details for a Client',
            },
            RolePermission([PermissionList.CLIENT_CREATE?.code]) &&
            {
                key: 'DELETE_ADDRESS_CLIENT',
                title: 'Deleting an Address for a Client',
            },
            RolePermission([PermissionList.CLIENT_CREATE?.code]) &&
            {
                key: 'EDIT_ADDRESS_CLIENT',
                title: 'Editing an Address for a Client',
            },
            RolePermission([PermissionList.CLIENT_CREATE?.code]) &&
            {
                key: 'SETTING_ADDRESS_DEFAULT',
                title: 'Setting an Address as the Default Address',
            },
            RolePermission([PermissionList.CLIENT_LIST_READ?.code, PermissionList.CONNECTED_CLIENT_READ?.code]) &&
            {
                key: 'DOWNLOAD_CLIENT_CSV',
                title: 'Downloading Client List CSV',
            },
        ],
    },


    {
        key: 'SCHEDULE',
        title: 'Schedule',
        subMenu: [
            {
                key: 'VIEW_SCHEDULE_IN_LIST',
                title: 'Viewing the Schedule in List View or Calendar View',
            },
            {
                key: 'view_APPOINTMENT_DETAILS_SCHEDULE_VIEW',
                title: 'Viewing Appointment Details from the Schedule View',
            },
            RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_SERVICE_CREATE?.code, PermissionList.APPT_CONNECTED_CREATE?.code]) &&
            {
                key: 'STEPS_TO_ADD_SERVICE_APPOINT',
                title: 'Steps to Add a Service Appointment',
            },
            RolePermission([PermissionList.APPT_RECURE?.code]) &&
            {
                key: 'ADD_MULTIPLE_SERVICE_APPOINT',
                title: 'Adding Multiple Service Appointments (Series)',
            },
            RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_STAFF_CREATE?.code]) &&
            {
                key: 'ADD_STAFF_APPOINT_SINGLE',
                title: 'Adding a Staff Appointment (single)',
            },
            RolePermission([PermissionList.APPT_RECURE?.code]) &&
            {
                key: 'ADD_MULTIPLE_STAFF_APPOINT',
                title: 'Adding Multiple Staff Appointments',
            },
            RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_SICK_TIME_CREATE?.code]) &&
            {
                key: 'ADD_SICK_TIME_APPOINT_SINGLE',
                title: 'Adding a Sick Time Appointment (Single Appointment)',
            },
            RolePermission([PermissionList.APPT_RECURE?.code]) &&
            {
                key: 'ADD_SICK_TIME_APPOINT_MULTIPLE',
                title: 'Adding Sick Time Appointments (Multiple Appointments)',
            },
            RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_BREAK_CREATE?.code]) &&
            {
                key: 'STEPS_ADD_BREAK_TIME_APPOINT_SINGLE',
                title: 'Steps to Add a Break Time Appointment (Single Appointment)',
            },
            RolePermission([PermissionList.APPT_RECURE?.code]) &&
            {
                key: 'STEPS_ADD_MULTIPLE_BREAK_TIME_APPOINT_RECURRING',
                title: 'Steps to Add Multiple Break Time Appointments (Recurring Appointments)',
            },
            RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_DRIVE_CREATE?.code]) &&
            {
                key: 'ADD_DRIVE_TIME_APPOINT_SINGLE',
                title: 'Adding a Drive Time Appointment (single)',
            },
            RolePermission([PermissionList.APPT_RECURE?.code]) &&
            {
                key: 'ADD_MULTIPLE_DRIVE_TIME_APPOINT',
                title: 'Adding Multiple Drive Time Appointment',
            },
            RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_PTO_CREATE?.code]) &&
            {
                key: 'ADD_PAID_TIME_APPOINT_SINGLE',
                title: 'Adding a Paid Time Off Appointment (single)',
            },
            RolePermission([PermissionList.APPT_RECURE?.code]) &&
            {
                key: 'ADD_MULTIPLE_PAID_APPOINT',
                title: 'Adding Multiple Paid Time Off Appointments',
            },
            RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_UNPAID_CREATE?.code]) &&
            {
                key: 'ADD_UNPAID_TIME_APPOINT_SINGLE',
                title: 'Adding an Unpaid Time Off Appointment (single)',
            },
            RolePermission([PermissionList.APPT_RECURE?.code]) &&
            {
                key: 'ADD_MULTIPLE_UNPAID_TIME_APPOINT',
                title: 'Adding Multiple Unpaid Time Off Appointments',
            },
            RolePermission([PermissionList.REPORT_MANAGE?.code]) &&
            {
                key: 'DOWN_SCHEDULE_CSV',
                title: 'Downloading the Schedule as a CSV',
            },
            RolePermission([PermissionList.APPT_LOCK?.code]) &&
            {
                key: 'LOCKING_UNLOCKING_SINGLE_APPOINT',
                title: 'Locking/Unlocking Single Appointments',
            },
            RolePermission([PermissionList.APPT_LOCK?.code]) &&
            {
                key: 'LOCKING_UNLOCKING_MULTIPLE_APPOINT',
                title: 'Locking/Unlocking Multiple Appointments',
            },
            RolePermission([PermissionList.APPT_RECURE?.code]) &&
            {
                key: 'RECURRING_APPOINT',
                title: 'Recurring an Appointment',
            },
            RolePermission([PermissionList.APPT_RECURE?.code]) &&
            {
                key: 'DISCONNECT_APPOINT_TEMPLATE',
                title: 'Disconnect Appointment from Template',
            },
            RolePermission([PermissionList.APPT_CANCEL?.code]) &&
            {
                key: 'CANCEL_NON_SERVICE_APPOINT',
                title: 'Canceling a Non-Service Appointment',
            },
            RolePermission([PermissionList.APPT_RENDER_COMPLETE?.code]) &&
            {
                key: 'COMPLETING_NON_SERVICE_APPOINT',
                title: 'Completing a Non-Service Appointment',
            },
            RolePermission([PermissionList.APPT_RENDER_COMPLETE?.code]) &&
            {
                key: 'RENDER_SERVICE_APPOINT',
                title: 'Rendering a Service Appointment',
            },
            RolePermission([PermissionList.APPT_UNRENDER?.code]) &&
            {
                key: 'UN_RENDER_SERVICE_APPOINT',
                title: 'Un-rendering a Service Appointment',
            },
            {
                key: 'UPLOAD_SIGNATURE_SERVICE_APPOINT',
                title: 'Uploading a Signature on a Service Appointment',
            },
            {
                key: 'ADD_NOTE_SERVICE_APPOINT',
                title: 'Adding a Note to a Service Appointment',
            },
            {
                key: 'EDIT_NOTE_IN_AN_APPOINT',
                title: 'Editing a Note in an Appointment',
            },
            {
                key: 'VIEW_NOTE_APPOINT',
                title: 'Viewing Notes on Appointments',
            },
            RolePermission([PermissionList.APPT_FILE_READ?.code, PermissionList.APPT_FILE_MANAGE?.code]) &&
            {
                key: 'UPLOAD_DOC_ON_APPOINT',
                title: 'Uploading Documents on Appointments',
            },
            RolePermission([PermissionList.APPT_FILE_MANAGE?.code]) &&
            {
                key: 'EDIT_FILES_IN_APPOINT',
                title: 'Editing Files in Appointments',
            },
            RolePermission([PermissionList.APPT_FILE_READ?.code, PermissionList.APPT_FILE_MANAGE?.code]) &&
            {
                key: 'VIEW_FILES_IN_APPOINT',
                title: 'Viewing Files in Appointments',
            },
            RolePermission([PermissionList.APPT_FILE_MANAGE?.code]) &&
            {
                key: 'DOWN_FILES_IN_APPOINT',
                title: 'Downloading Files in Appointments',
            },
            RolePermission([PermissionList.APPT_FILE_MANAGE?.code]) &&
            {
                key: 'DELETE_FILES_IN_APPOINT',
                title: 'Deleting Files in Appointments',
            },
            RolePermission([
                PermissionList.APPT_SELF_UPDATE?.code &&
                PermissionList.APPT_CONNECTED_CREATE?.code,
                PermissionList.APPT_DRIVE_CREATE?.code,
                PermissionList.APPT_BREAK_CREATE?.code,
                PermissionList.APPT_PTO_CREATE?.code,
                PermissionList.APPT_STAFF_CREATE?.code,
                PermissionList.APPT_SELF_CREATE?.code,
                PermissionList.APPT_SICK_TIME_CREATE?.code,
            ]) &&
            {
                key: 'EDIT_APPOINT_EXCEPT_SERVICE_APPOINT',
                title: 'Editing Appointments (Except Service Appointments)',
            },
            RolePermission([
                PermissionList.APPT_SERVICE_CREATE?.code,
                PermissionList.APPT_SELF_UPDATE?.code]) &&
            {
                key: 'EDIT_SERVICE_APPOINTMENT',
                title: 'Editing a Service Appointment',
            },
            RolePermission([PermissionList.APPT_RECURE?.code]) &&
            {
                key: 'EDIT_TEMPLATE_EXCEPT_EXCEPT_SERVICE_APPOINT',
                title: 'Editing a Template/Series of Appointments (Except Service Appointments)',
            },
            RolePermission([PermissionList.APPT_RECURE?.code]) &&
            {
                key: 'EDIT_TEMPLATE_SERVICE_APPOINT',
                title: 'Editing a Template/Series for Service Appointments',
            },
            RolePermission([PermissionList.APPT_HISTORY_READ?.code]) &&
            {
                key: 'VIEW_CHANGE_HISTORY_IN_APPOINT',
                title: 'Viewing Change History in Appointments',
            },
        ],
    },


    RolePermission([
        PermissionList.CLAIMS_READ?.code,
        PermissionList.INVOICE_READ?.code,
        PermissionList.CLAIMS_CREATE?.code,
        PermissionList.CLAIM_NOTE_MANAGE?.code,
        PermissionList.CLAIM_HISTORY_READ?.code,
        PermissionList.CLAIM_FILE_MANAGE?.code,
        PermissionList.CLAIM_FILE_READ?.code,
        PermissionList.CLAIMS_UPDATE?.code,

    ]) &&
    {
        key: 'BILLING',
        title: 'Billing',
        subMenu: [
            RolePermission([PermissionList.CLAIMS_READ?.code]) &&
            {
                key: 'PENDING_CLAIMS',
                title: 'Pending Claims',
                subList: [
                    RolePermission([PermissionList.CLAIMS_READ?.code]) &&
                    {
                        key: 'VIEW_PENDING_CLAIMS',
                        title: 'Viewing Pending Claim List',
                    },
                    RolePermission([PermissionList.CLAIMS_CREATE?.code]) &&
                    {
                        key: 'GENERATE_CLAIM',
                        title: 'Generating Claims',
                    },
                    RolePermission([PermissionList.CLAIMS_READ?.code]) &&
                    {
                        key: 'VIEW_PENDING_CLAIM_DETAIL',
                        title: 'Viewing Pending Claim Details',
                    },
                    RolePermission([PermissionList.CLAIMS_READ?.code]) &&
                    {
                        key: 'DOWN_1500_DORM_PENDING_CLAIM',
                        title: 'Downloading the 1500 Form for a Pending Claim',
                    },
                    RolePermission([PermissionList.CLAIMS_READ?.code]) &&
                    {
                        key: 'VIEW_BILL_DETAILS_PENDING_CLAIM_PAYMENT_TRANS',
                        title: 'Viewing Bill Details in a Pending Claim (Claim Payment Transactions)',
                    },
                    RolePermission([PermissionList.CLAIMS_READ?.code]) &&
                    {
                        key: 'VIEW_BILL_DETAILS_PENDING_CLAIM_APPOINTMENTS',
                        title: 'Viewing Bill Details in a Pending Claim (Appointments)',
                    },
                    RolePermission([PermissionList.CLAIMS_READ?.code]) &&
                    {
                        key: 'VIEW_APPOINTMENTS_PENDING_CLAIM',
                        title: 'Viewing Appointments in a Pending Claim',
                    },
                    RolePermission([PermissionList.CLAIM_NOTE_MANAGE?.code]) &&
                    {
                        key: 'ADD_NOTES_PENDING_CLAIM',
                        title: 'Adding Notes to a Pending Claim',
                    },
                    RolePermission([PermissionList.CLAIM_NOTE_MANAGE?.code]) &&
                    {
                        key: 'EDITING_NOTES_PENDING_CLAIM',
                        title: 'Editing Notes in a Pending Claim',
                    },
                    RolePermission([PermissionList.CLAIM_NOTE_MANAGE?.code]) &&
                    {
                        key: 'DELETE_NOTES_PENDING_CLAIM',
                        title: 'Deleting Notes from a Pending Claim',
                    },
                    RolePermission([PermissionList.CLAIM_HISTORY_READ?.code]) &&
                    {
                        key: 'VIEW_HISTORY_PENDING_CLAIM',
                        title: 'Viewing History for a Pending Claim',
                    },
                    RolePermission([PermissionList.CLAIM_FILE_MANAGE?.code]) &&
                    {
                        key: 'UPLOAD_FILES_PENDING_CLAIM',
                        title: 'Uploading Files to Pending Claims',
                    },
                    RolePermission([PermissionList.CLAIM_FILE_MANAGE?.code]) &&
                    {
                        key: 'EDIT_FILES_PENDING_CLAIM',
                        title: 'Editing Pending Claim Files',
                    },
                    RolePermission([PermissionList.CLAIM_FILE_MANAGE?.code]) &&
                    {
                        key: 'DELETE_FILES_PENDING_CLAIM',
                        title: 'Deleting Pending Claim Files',
                    },
                    RolePermission([PermissionList.CLAIM_FILE_READ?.code]) &&
                    {
                        key: 'VIEW_FILES_PENDING_CLAIM',
                        title: 'Viewing Files in a Pending Claim',
                    },
                    RolePermission([PermissionList.CLAIM_FILE_MANAGE?.code]) &&
                    {
                        key: 'DOWN_FILES_PENDING_CLAIM',
                        title: 'Downloading Files from a Pending Claim',
                    },
                    RolePermission([PermissionList.CLAIMS_READ?.code]) &&
                    {
                        key: 'ADD_RESUBMISSION_DETAILS_PENDING_CLAIM',
                        title: 'Adding Resubmission Details to a Pending Claim',
                    },
                    RolePermission([PermissionList.CLAIMS_UPDATE?.code]) &&
                    {
                        key: 'SUBMIT_PENDING_CLAIM',
                        title: 'Submitting Pending Claims',
                    },
                ],
            },


            RolePermission([
                PermissionList.CLAIMS_READ?.code,
                PermissionList.CLAIMS_CLOSE?.code,
            ]) &&
            {
                key: 'BILLED_CLAIMS',
                title: 'Billed Claims',
                subList: [
                    RolePermission([PermissionList.CLAIMS_READ?.code]) &&
                    {
                        key: 'VIEW_BILLING_CLAIMS',
                        title: 'Viewing the Billed Claims List',
                    },
                    RolePermission([PermissionList.CLAIMS_READ?.code]) &&
                    {
                        key: 'VIEW_DETAILS_BILLED_CLAIMS',
                        title: 'Viewing the Details of a Billed Claim',
                    },
                    RolePermission([PermissionList.CLAIMS_READ?.code]) &&
                    {
                        key: 'DOWN_CSV_BILLED_CLAIMS',
                        title: 'Downloading a CSV of Billed Claims',
                    },
                    RolePermission([PermissionList.CLAIMS_CLOSE?.code]) &&
                    {
                        key: 'CLOD_BILLED_CLAIM',
                        title: 'Closing a Billed Claim',
                    },
                ],
            },


            RolePermission([
                PermissionList.CLAIM_PMT_READ?.code,
                PermissionList.CLAIM_PMT_APPEAL_READ?.code,
                PermissionList.CLAIM_PMT_PAY?.code,
                PermissionList.CLAIM_PMT_SEND_APPEAL?.code,

            ]) &&
            {
                key: 'CLAIM_PAYMENTS',
                title: 'Claim Payments',
                subList: [
                    RolePermission([PermissionList.CLAIM_PMT_READ?.code]) &&
                    {
                        key: 'VIEW_OPEN_CLAIM_PAYMENTS',
                        title: 'Viewing Open Claim Payments',
                    },

                    RolePermission([PermissionList.CLAIM_PMT_APPEAL_READ?.code, PermissionList.CLAIM_PMT_SEND_APPEAL?.code]) &&
                    {
                        key: 'VIEW_APPEALED_CLAIM_PAYMENTS',
                        title: 'Viewing Appealed Claim Payments',
                    },
                    RolePermission([PermissionList.CLAIM_PMT_READ?.code]) &&
                    {
                        key: 'VIEW_COMPLETED_CLAIM_PAYMENTS',
                        title: 'Viewing Completed Claim Payments',
                    },
                    RolePermission([PermissionList.CLAIM_PMT_PAY?.code]) &&
                    {
                        key: 'INPUTTING_CLAIM_PAYMENT_STATUS_OPEN',
                        title: 'Inputting Claim Payments When the Claim Payment Status Is Open',
                    },

                    RolePermission([PermissionList.CLAIM_PMT_SEND_APPEAL?.code]) &&
                    {
                        key: 'APPEALING_CLAIM_PAYMENTS',
                        title: 'Appealing a Claim Payments',
                    },

                    RolePermission([PermissionList.CLAIM_PMT_APPEAL_READ?.code, PermissionList.CLAIM_PMT_SEND_APPEAL?.code]) &&
                    {
                        key: 'PAYING_CLAIM_PAYMENTS',
                        title: 'Paying Appealed Claims',
                    },

                    RolePermission([PermissionList.CLAIM_PMT_PAY?.code]) &&
                    {
                        key: 'EDIT_CLOSED_PAID_CLAIM_COMPLETED_TAB',
                        title: 'Editing Closed or Paid Claims in the Completed Tab',
                    },
                    RolePermission([PermissionList.CLAIM_PMT_READ?.code]) &&
                    {
                        key: 'ADD_NOTES_CLAIM_FROM_PAYMENTS',
                        title: 'Adding Notes to a Claim from Claim Payments',
                    },
                    RolePermission([PermissionList.CLAIM_PMT_READ?.code]) &&
                    {
                        key: 'VIEW_NOTES_CLAIM_PAYMENTS_CLAIM',
                        title: 'Viewing Notes on a Claim from Claim Payments',
                    },
                ],
            },


            RolePermission([
                PermissionList.INVOICE_READ?.code,
                PermissionList.INVOICE_CREATE?.code,
                PermissionList.INVOICE_CLOSE?.code,
                PermissionList.INVOICE_NOTE_READ?.code,
                PermissionList.INVOICE_NOTE_MANAGE?.code,
                PermissionList.INVOICE_HISTORY_READ?.code,
                PermissionList.INVOICE_FILE_READ?.code,
                PermissionList.INVOICE_FILE_MANAGE?.code,
            ]) &&
            {
                key: 'INVOICES',
                title: 'Invoices',
                subList: [
                    RolePermission([PermissionList.INVOICE_READ?.code]) &&
                    {
                        key: 'VIEW_INVOICES',
                        title: 'Viewing Invoices',
                    },
                    RolePermission([PermissionList.INVOICE_READ?.code]) &&
                    {
                        key: 'DOWN_INVOICE_CSV',
                        title: 'Downloading Invoice CSV',
                    },
                    RolePermission([PermissionList.INVOICE_READ?.code]) &&
                    {
                        key: 'VIEW_INVOICES_DETAILS',
                        title: 'Viewing Invoice Details',
                    },
                    RolePermission([PermissionList.INVOICE_CREATE?.code]) &&
                    {
                        key: 'GENERATE_INVOICES',
                        title: 'Generating Invoices',
                    },
                    RolePermission([PermissionList.INVOICE_READ?.code]) &&
                    {
                        key: 'PAY_INVOICE_FULL',
                        title: 'Paying Invoices in Full',
                    },
                    RolePermission([PermissionList.INVOICE_READ?.code]) &&
                    {
                        key: 'EDIT_INVOICE',
                        title: 'Editing an Invoice',
                    },
                    RolePermission([PermissionList.INVOICE_CLOSE?.code]) &&
                    {
                        key: 'CLOSE_INVOICE',
                        title: 'Closing an Invoice',
                    },
                    RolePermission([PermissionList.INVOICE_READ?.code]) &&
                    {
                        key: 'DOWN_INVOICE_PDF',
                        title: 'Downloading an Invoice PDF',
                    },
                    RolePermission([PermissionList.INVOICE_READ?.code]) &&
                    {
                        key: 'VIEW_APPOINTMENT_ASSOC_INVOICE',
                        title: 'Viewing Appointments Associated with an Invoice',
                    },
                    RolePermission([PermissionList.INVOICE_NOTE_READ?.code]) &&
                    {
                        key: 'VIEW_NOTES_INVOICE',
                        title: 'Viewing Notes on an Invoice',
                    },
                    RolePermission([PermissionList.INVOICE_NOTE_MANAGE?.code]) &&
                    {
                        key: 'EDIT_NOTES_INVOICE',
                        title: 'Editing Notes on an Invoice',
                    },
                    RolePermission([PermissionList.CLAIM_NOTE_MANAGE?.code]) &&
                    {
                        key: 'ADD_NOTES_INVOICE',
                        title: 'Adding Notes to an Invoice',
                    },
                    RolePermission([PermissionList.INVOICE_NOTE_MANAGE?.code]) &&
                    {
                        key: 'DELETE_NOTES_INVOICE',
                        title: 'Deleting Notes on an Invoice',
                    },
                    RolePermission([PermissionList.INVOICE_HISTORY_READ?.code]) &&
                    {
                        key: 'VIEW_INVOICE_CHANGE_HISTORY',
                        title: 'Viewing Invoice Change History',
                    },
                    RolePermission([PermissionList.INVOICE_FILE_READ?.code]) &&
                    {
                        key: 'VIEW_FILES_INVOICE',
                        title: 'Viewing Files in Invoices',
                    },
                    RolePermission([PermissionList.INVOICE_FILE_MANAGE?.code]) &&
                    {
                        key: 'DELETE_FILES_INVOICE',
                        title: 'Deleting Files in Invoices',
                    },
                    RolePermission([PermissionList.INVOICE_FILE_MANAGE?.code]) &&
                    {
                        key: 'EDIT_FILES_INVOICE',
                        title: 'Editing Files in Invoices',
                    },
                    RolePermission([PermissionList.INVOICE_FILE_MANAGE?.code]) &&
                    {
                        key: 'DOWN_FILES_INVOICE',
                        title: 'Downloading Files in Invoices',
                    },
                    RolePermission([PermissionList.INVOICE_FILE_MANAGE?.code]) &&
                    {
                        key: 'UPLOAD_FILES_INVOICE',
                        title: 'Uploading Files in Invoices',
                    },
                ],
            },
        ],
    },

    RolePermission([
        PermissionList.TIMESHEET_READ.code,
        PermissionList.TIMESHEET_CREATE?.code,
        PermissionList.TIMESHEET_PROCESS?.code,
        PermissionList.TIMESHEET_NOTE_MANAGE?.code,
        PermissionList.TIMESHEET_HISTORY_READ?.code,
        PermissionList.TIMESHEET_FILE_MANAGE?.code,
        PermissionList.TIMESHEET_FILE_READ?.code,
    ]) &&
    {
        key: 'PAYROLL',
        title: 'Payroll',
        subMenu: [
            {
                key: 'ACTIVE_PAYROLL',
                title: 'Active',
                subList: [
                    RolePermission([PermissionList.TIMESHEET_READ?.code]) &&
                    {
                        key: 'VIEW_ACTIVE_PAYROLL_TIMESHEETS',
                        title: 'Viewing Active Payroll Timesheets List',
                    },
                    RolePermission([PermissionList.TIMESHEET_CREATE?.code]) &&
                    {
                        key: 'ADD_FIX_PAY_TIMESHEET',
                        title: 'Adding a Fixed Pay Timesheet',
                    },
                    RolePermission([PermissionList.TIMESHEET_PROCESS?.code]) &&
                    {
                        key: 'PROCESSING_PAYROLL_SUBLIST',
                        title: 'Processing Payroll',
                    },
                    RolePermission([PermissionList.TIMESHEET_READ?.code]) &&
                    {
                        key: 'DOWNLOAD_CSV_ACTIVE_TIME',
                        title: 'Downloading a CSV of Active Timesheets',
                    },
                    RolePermission([PermissionList.TIMESHEET_READ?.code]) &&
                    {
                        key: 'VIEW_ACTIVE_TIME_DETAILS',
                        title: 'Viewing Active Timesheet Details',
                    },
                    RolePermission([PermissionList.TIMESHEET_NOTE_MANAGE?.code]) &&
                    {
                        key: 'EDIT_NOTE_ACTIVE_TIME',
                        title: 'Editing a Note for an Active Timesheet',
                    },
                    RolePermission([PermissionList.TIMESHEET_NOTE_MANAGE?.code]) &&
                    {
                        key: 'DELETE_NOTE_ACTIVE_TIME',
                        title: 'Deleting a Note from an Active Timesheet',
                    },
                    RolePermission([PermissionList.TIMESHEET_HISTORY_READ?.code]) &&
                    {
                        key: 'VIEW_CHANGE_HISTORY_ACTIVE_TIME',
                        title: 'Viewing Change History on an Active Timesheet',
                    },
                    RolePermission([PermissionList.TIMESHEET_FILE_MANAGE?.code]) &&
                    {
                        key: 'EDIT_FILES_ACTIVE_TIME',
                        title: 'Editing Files in Active Timesheets',
                    },
                    RolePermission([PermissionList.TIMESHEET_FILE_READ?.code]) &&
                    {
                        key: 'VIEW_FILES_ACTIVE_TIME',
                        title: 'Viewing Files in Active Timesheets',
                    },
                    RolePermission([PermissionList.TIMESHEET_FILE_MANAGE?.code]) &&
                    {
                        key: 'DOWNLOAD_FILES_ACTIVE_TIME',
                        title: 'Downloading Files in Active Timesheets',
                    },
                    RolePermission([PermissionList.TIMESHEET_FILE_MANAGE?.code]) &&
                    {
                        key: 'ADD_FILES_ACTIVE_TIME',
                        title: 'Adding Files in Active Timesheets',
                    },
                    RolePermission([PermissionList.TIMESHEET_FILE_MANAGE?.code]) &&
                    {
                        key: 'DELETE_FILES_ACTIVE_TIME',
                        title: 'Deleting Files in Active Timesheets',
                    },
                    RolePermission([PermissionList.TIMESHEET_CREATE?.code]) &&
                    {
                        key: 'ADD_TIME_PAYROLL_PAYCODES',
                        title: 'Adding Timesheets in Payroll from Paycodes',
                    },
                ],
            },

            RolePermission([PermissionList.TIMESHEET_READ.code]) &&
            {
                key: 'PROCESSED_PAYROLL',
                title: 'Processed',
                subList: [
                    {
                        key: 'VIEW_PROCESSED_PAYROLL_TIMESHEETS',
                        title: 'Viewing Timesheets Within a Processed Payroll Batch',
                    },

                    {
                        key: 'DOWNLOAD_PROCESSED_PAYROLL',
                        title: 'Downloading the CSV for a Processed Payroll Batch',
                    },
                ],
            },
        ],
    },


    RolePermission([
        PermissionList.SYSTEM_SERVICE_TYPES?.code,
        PermissionList.SYSTEM_PAYCODE_TYPES?.code,
        PermissionList.SYSTEM_CREDENTIALS?.code,
        PermissionList.SYSTEM_DEPARTMENTS?.code,
        PermissionList.SYSTEM_JOB_TITLES?.code,
        PermissionList.SYSTEM_PLACE_OF_SERVICE?.code,
        PermissionList.SYSTEM_MILEAGE?.code,
        PermissionList.SYSTEM_OVERTIME?.code,
    ]) &&
    {
        key: 'SYSTEM',
        title: 'System',
        subMenu: [
            {
                key: 'CREATING_COMPANY',
                title: 'Creating a Company Profile',
            },
            {
                key: 'EDITING_COMPANY',
                title: 'Editing a Company Profile',
            },
            RolePermission([PermissionList.SYSTEM_SERVICE_TYPES?.code]) &&
            {
                key: 'CREATING_SERVICE_TYPES',
                title: 'Creating Service Types',
            },
            RolePermission([PermissionList.SYSTEM_SERVICE_TYPES?.code]) &&
            {
                key: 'EDITING_SERVICE_TYPES',
                title: 'Editing Service Types',
            },
            RolePermission([PermissionList.SYSTEM_CREDENTIALS?.code]) &&
            {
                key: 'CREATING_CREDENTIALS',
                title: 'Creating Credentials',
            },
            RolePermission([PermissionList.SYSTEM_CREDENTIALS?.code]) &&
            {
                key: 'ADDING_COVERED_CREDENTIALS',
                title: 'Adding Covered Credentials',
            },
            RolePermission([PermissionList.SYSTEM_CREDENTIALS?.code]) &&
            {
                key: 'EDITING_CREDENTIALS',
                title: 'Editing Credentials',
            },
            RolePermission([PermissionList.SYSTEM_CREDENTIALS?.code]) &&
            {
                key: 'DELETING_COVERED_CREDENTIALS',
                title: 'Deleting Covered Credentials',
            },
            RolePermission([PermissionList.SYSTEM_DEPARTMENTS?.code]) &&
            {
                key: 'ADDING_DEPARTMENTS',
                title: 'Adding Departments',
            },
            RolePermission([PermissionList.SYSTEM_DEPARTMENTS?.code]) &&
            {
                key: 'EDITING_DEPARTMENTS',
                title: 'Editing Departments',
            },
            RolePermission([PermissionList.SYSTEM_JOB_TITLES?.code]) &&
            {
                key: 'ADDING_JOB_TITLES',
                title: 'Adding Job Titles',
            },
            RolePermission([PermissionList.SYSTEM_JOB_TITLES?.code]) &&
            {
                key: 'EDITING_JOB_TITLES',
                title: 'Editing Job Titles',
            },
            RolePermission([PermissionList.SYSTEM_SERVICE_TYPES?.code]) &&
            {
                key: 'ADDING_PAYCODE_TYPES',
                title: 'Adding Paycode Types',
            },
            RolePermission([PermissionList.SYSTEM_SERVICE_TYPES?.code]) &&
            {
                key: 'EDITING_PAYCODE_TYPES',
                title: 'Editing Paycode Types',
            },
            RolePermission([PermissionList.SYSTEM_MILEAGE?.code]) &&
            {
                key: 'ADDING_MILEAGE',
                title: 'Adding Mileage Compensation',
            },
            RolePermission([PermissionList.SYSTEM_MILEAGE?.code]) &&
            {
                key: 'EDITING_MILEAGE',
                title: 'Editing Mileage Compensation',
            },
            RolePermission([PermissionList.SYSTEM_MILEAGE?.code]) &&
            {
                key: 'INACTIVATING_MILEAGE',
                title: 'Inactivating Mileage Compensation',
            },
            RolePermission([PermissionList.SYSTEM_PLACE_OF_SERVICE?.code]) &&
            {
                key: 'ADDING_PLACE',
                title: 'Adding a Place of Service',
            },
            RolePermission([PermissionList.SYSTEM_PLACE_OF_SERVICE?.code]) &&
            {
                key: 'EDITING_PLACE',
                title: 'Editing a Place of Service',
            },
        ],
    },
];


export const helpCenterContent = {
    'ROLE_MANAGEMENT': {
        'CREATING_ROLES_PERMISSIONS': {
            infoTitle: 'Role Management',
            pageTitle: 'Creating Roles and Permissions for Different Users',
            content: {
                short: [
                    {
                        subTitle: 'Creating Roles and Permissions for Different Users',
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Role Management > Add Role.',
                                        boldTexts: ['Role Management > Add Role'],
                                    },

                                    {
                                        title: '2. Set the role name and permissions.',
                                        boldTexts: [''],
                                    },

                                    {
                                        title: '3. Click Add to create the new role.',
                                        boldTexts: ['Add'],
                                    },

                                    {
                                        title: '4. Click on the role to view the assigned permissions.',
                                        boldTexts: [''],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.createRoleDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Navigate to Role Management:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the left pane and click Role Management > Add Role.',
                                        boldTexts: ['Role Management', 'Add Role'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Enter Role Details:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Set Role Name: Enter the name of the role (e.g., "HR Manager" or "Billing Specialist").',
                                        boldTexts: ['Set Role Name'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Set Permissions: Choose the permissions you want to assign to this role from a list.',
                                        boldTexts: ['Set Permissions'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Add the Role:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click Add to create the new role.',
                                        boldTexts: ['Add'],
                                        circle: true,
                                    },

                                    {
                                        title: 'The new role will appear in the left table labeled Role.',
                                        boldTexts: ['Role'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. View Assigned Permissions:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the role in the left table to see the permissions assigned to it, in the right table.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.createRoleDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

        },
        'ADDING_DELETING_ROLE': {
            pageTitle: 'Adding/Deleting Permissions to a Role',
            content: {
                'short': [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Role Management and select the role to edit.',
                                        boldTexts: ['Role Management'],
                                    },

                                    {
                                        title: '2. Click + Add Permissions, select the permission, and click Add Permissions.',
                                        boldTexts: ['Add Permissions'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addPermDesktop,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '3. To delete, click the red X next to the permission and confirm by clicking Remove.\n',
                                        boldTexts: ['red X', 'Delete'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deletingPermDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],

                'long': [
                    {
                        subTitle: '1. Navigate to Role Management',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the left pane and click >Role Management.',
                                        boldTexts: ['Role Management'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Select the Role to Edit',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the left table labeled >Role, find the role you want to add permissions to.',
                                        boldTexts: ['Role'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Add Permissions',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the + Add Permissions button in the Role Permissions table on the right.',
                                        boldTexts: ['Add Permissions', 'Role Permissions'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click into the Select Permissions field.',
                                        boldTexts: ['Select Permissions'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Select the permission you would like to assign to your selected role.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click Add Permissions. You will see the permission added in the right table.',
                                        boldTexts: ['Add Permissions'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addPermDesktop,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Deleting Permissions',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Select the role from which you want to delete permissions.',
                                        boldTexts: ['Role Permissions'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Navigate to the right table where the permissions assigned to that role are listed.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click on the red X button next to the permission you want to delete.',
                                        boldTexts: ['X button'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click Remove once the confirmation modal pops up.',
                                        boldTexts: ['Remove'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deletingPermDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'DELETING_ROLE': {
            pageTitle: 'Deleting a Role',
            content: {
                short: [

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Role Management.',
                                        boldTexts: ['Role Management'],
                                    },

                                    {
                                        title: '2. Find the role to delete, click the red X, and confirm by clicking Delete.',
                                        boldTexts: ['red X', 'Delete'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deletingRoleDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Navigate to Role Management',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the left pane and click > Role Management.',
                                        boldTexts: ['Role Management'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Find the Role to Delete',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the left table labeled >Role, locate the role you want to delete.',
                                        boldTexts: ['Role'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Delete the Role',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the red X button next to the role.',
                                        boldTexts: ['red X button'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click Delete on the confirmation modal to confirm the deletion.',
                                        boldTexts: ['Delete'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deletingRoleDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

        },
        'RENAMING_ROLES': {
            pageTitle: 'Renaming Roles',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Role Management',
                                        boldTexts: ['Role Management'],
                                    },

                                    {
                                        title: '2. Select the role you want to rename, and click the pencil icon.',
                                        boldTexts: ['pencil icon'],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.renamingRolesSelect,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '3. Update the role name, then click Save.',
                                        boldTexts: ['Save'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.renamingRolesEdit,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Navigate to Role Management',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the left pane and click > Role Management.',
                                        boldTexts: ['Role Management'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Select the Role to Edit',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the left table labeled Role, find the role you want to rename.',
                                        boldTexts: ['Role'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.renamingRolesSelect,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Edit Role Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the pencil icon next to the role.',
                                        boldTexts: ['pencil icon'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Make the necessary changes to the role name.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.renamingRolesEdit,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: 'Save Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click Save to apply the changes.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

        },
    },
    'SYSTEM': {
        'CREATING_COMPANY': {
            pageTitle: 'Creating a Company Profile',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to System > Company Profile.',
                                        boldTexts: ['System', 'Company Profile'],
                                    },

                                    {
                                        title: '2. Enter company details (e.g., name, logo, address, NPI, Tax ID).',
                                        boldTexts: ['System', 'Company Profile'],
                                    },

                                    {
                                        title: '3. Click Save to create the profile.',
                                        boldTexts: ['Save'],
                                    },
                                ],
                            },
                        ],
                    },


                    {
                        list: [

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.saveCompanyProfDesktop,
                                    },
                                ],
                            },
                        ],

                    },
                ],
                long: [
                    {
                        subTitle: '1. Navigate to System',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the left pane and click > System.',
                                        boldTexts: ['System'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Select the Company Profile Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the >Company Profile tab to open the profile settings.',
                                        boldTexts: ['Company Profile'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Enter Company Information',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Company Name: Type in the company\'s name.',
                                        boldTexts: ['Company Name'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Company Logo: Drag and drop or click to choose a company logo file.',
                                        boldTexts: ['Company Logo'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Company Address: Enter the company\'s physical address.',
                                        boldTexts: ['Company Address'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Phone Number: Enter the company\'s primary contact number.',
                                        boldTexts: ['Phone Number'],
                                        circle: true,
                                    },

                                    {
                                        title: 'NPI Number: Enter the National Provider Identifier (NPI) number.',
                                        boldTexts: ['NPI Number'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Tax ID: Enter the company\'s tax identification number.',
                                        boldTexts: ['Tax ID'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Save the Company Profile',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Scroll down and click Save to create the company profile.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.saveCompanyProfDesktop,
                                    },
                                ],
                            },
                        ],

                    },
                ],
            },

        },
        'EDITING_COMPANY': {
            pageTitle: 'Editing a Company Profile',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to System > Company Profile.',
                                        boldTexts: ['System', 'Company Profile'],
                                    },

                                    {
                                        title: '2. Click the pencil icon to edit the profile.',
                                        boldTexts: ['pencil icon'],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingCompPropDesktop,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '3. Make changes to the fields and click Save.',
                                        boldTexts: ['Save'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.saveChangesDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Company Profile Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the -> System section in the left pane and click on the >Company Profile tab.',
                                        boldTexts: ['System', 'Company Profile'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Edit Company Profile',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the blue pencil icon located to the right of the >Company Profile title, above the Company Name field.',
                                        boldTexts: ['blue pencil icon', 'Company Profile'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingCompPropDesktop,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Update Company Information',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Make any necessary changes to the fields such as the company name, address, phone number, NPI number, Tax ID, or upload a new logo file.',
                                        boldTexts: ['X button'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Save Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'After making the desired changes, scroll down and click Save to apply the updates to the company profile.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.saveChangesDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

        },
        'CREATING_SERVICE_TYPES': {
            pageTitle: 'Creating Service Types',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to System > Service Types.',
                                        boldTexts: ['System', 'Service Types'],
                                    },

                                    {
                                        title: '2. Enter the service name, display code, and category.',
                                        boldTexts: ['System', 'Service Types'],
                                    },

                                    {
                                        title: '3. Click + Add Service Type to save.',
                                        boldTexts: ['Add Service Type'],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.saveServiceTypeDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Service Types Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the ->System section in the left pane and click on the > Service Types tab.',
                                        boldTexts: ['Service Types'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Add a New Service Type',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click into the Service Name field and type the service name (e.g., "Parent Training," "Direct Service," "Functional Behavioral Analysis," etc.).',
                                        boldTexts: ['Service Name'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click into the Display Code field and type a code for the service (e.g., "PT," "DS," "FBA," etc.).',
                                        boldTexts: ['Display Code'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click into the Category field and type the category for the service (e.g., "ABA," "FBA," etc.).',
                                        boldTexts: ['Category'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Save the New Service Type',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the blue + Add Service Type button.',
                                        boldTexts: ['Add Service Type'],
                                        circle: true,
                                    },

                                    {
                                        title: 'The newly added service type will appear in the table at the bottom.',
                                        boldTexts: ['Delete'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.saveServiceTypeDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

        },
        'EDITING_SERVICE_TYPES': {
            pageTitle: 'Editing Service Types',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to System > Service Types.',
                                        boldTexts: ['System', 'Service Types'],
                                    },

                                    {
                                        title: '2. Click the pencil icon next to the service to edit.',
                                        boldTexts: ['pencil icon'],
                                    },

                                    {
                                        title: '3. Make changes and click Save.',
                                        boldTexts: ['Save'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingServiceDesktop1,
                                    },

                                    {
                                        type: 'img',
                                        desktop: Images.editingServiceDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Edit a Service Type',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Locate the service type you want to edit in the bottom table.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click on the pencil icon next to the service type.',
                                        boldTexts: ['pencil icon'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingServiceDesktop1,
                                    },
                                ],
                            },

                            {
                                list: [
                                    {
                                        title: 'Make the necessary changes to the Service Name, Display Code, or Category fields.',
                                        boldTexts: ['Service Name', ' Display Code', 'Category fields'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click Save to apply the changes',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [

                                    {
                                        type: 'img',
                                        desktop: Images.editingServiceDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

        },
        'CREATING_CREDENTIALS': {
            pageTitle: 'Creating Credentials',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to System > Credentials.',
                                        boldTexts: ['System', 'Credentials'],
                                    },

                                    {
                                        title: '2. Click + Add Credentials, enter credential details, and click Add.',
                                        boldTexts: ['Add Credentials', 'Add'],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.creatingCredDesktop1,
                                    },

                                    {
                                        type: 'img',
                                        desktop: Images.creatingCredDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Credentials Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the -> System section in the left pane and click on the > Credentials tab.',
                                        boldTexts: ['System', 'Credentials'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Add a New Credential',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the + Add Credentials button on the left table.',
                                        boldTexts: ['Add Credentials'],
                                        circle: true,
                                    },

                                    {
                                        title: 'A modal will appear where you need to fill in the following:',
                                        boldTexts: [''],
                                        circle: true,
                                        list: [
                                            {
                                                title: 'Credential Name: Enter the name of the credential (e.g., "Registered Behavior Technician," "Licensed Behavior Analyst").',
                                                boldTexts: ['Credential Name'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Credential Type: Select the type of credential from the dropdown menu:',
                                                boldTexts: ['Credential Type'],
                                                circle: true,
                                                list: [
                                                    {
                                                        title: 'Degree: Examples include Bachelor\'s (BA/BS) or Master\'s Degree (MA/MS).',
                                                        boldTexts: ['Degree'],
                                                        circle: true,
                                                    },

                                                    {
                                                        title: 'Clearance: An example is Registered Behavior Technician (RBT)',
                                                        boldTexts: ['Clearance'],
                                                        circle: true,
                                                    },

                                                    {
                                                        title: 'License: Examples include Licensed Behavior Analyst (LBA) or Licensed Clinical Social Worker (LCSW).',
                                                        boldTexts: ['License'],
                                                        circle: true,
                                                    },
                                                ],
                                            },
                                        ],
                                    },

                                    {
                                        title: 'Click Add on the modal.',
                                        boldTexts: ['Add'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Once added, the credential will appear in the left table.',
                                        boldTexts: ['Add'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.creatingCredDesktop1,
                                    },

                                    {
                                        type: 'img',
                                        desktop: Images.creatingCredDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

        },
        'ADDING_COVERED_CREDENTIALS': {
            pageTitle: 'Adding Covered Credentials',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to System > Credentials.',
                                        boldTexts: ['System', 'Credentials'],
                                    },

                                    {
                                        title: '2. Select a credential, then click + Add Covered Credential.',
                                        boldTexts: ['Add Covered Credential'],
                                    },

                                    {
                                        title: '3. Select the covered credential and click Add.',
                                        boldTexts: ['Add'],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addingCovCredDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Credentials Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > System section in the left pane and click on the > Credentials tab.',
                                        boldTexts: ['System', 'Credentials'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Add a Covered Credential:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Find an existing credential in the left table (e.g., "Master\'s Degree").',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click on the credential to select it.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'On the right table, click the + Add Covered Credential button.',
                                        boldTexts: ['Add Covered Credential'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click into the Select Covered Credential field.',
                                        boldTexts: ['Select Covered Credential'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Select the existing credential that this selected credential covers (e.g., "Bachelor\'s Degree," "High School Diploma," etc.).',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'The selected covered credentials will appear in the right table.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addingCovCredDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

        },
        'EDITING_CREDENTIALS': {
            pageTitle: 'Editing Credentials',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to System > Credentials.',
                                        boldTexts: ['System', 'Credentials'],
                                    },

                                    {
                                        title: '2. Click the pencil icon next to the credential to edit.',
                                        boldTexts: ['pencil icon'],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editCredNameDesktop1,
                                    },
                                ],
                            },

                            {
                                list: [

                                    {
                                        title: '3. Make changes and click Save.',
                                        boldTexts: ['Save'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editCredNameDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Credentials Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > System section in the left pane and click on the > Credentials tab.',
                                        boldTexts: ['System', 'Credentials'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Edit a Credential’s Name or Type',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Find the credential you want to edit in the left table.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click on the blue pencil icon next to it.',
                                        boldTexts: ['blue pencil'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editCredNameDesktop1,
                                    },
                                ],
                            },

                            {
                                list: [
                                    {
                                        title: 'Edit the Credential Name field or select a new Credential Type.',
                                        boldTexts: ['Credential Name', 'new Credential'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click Save to apply changes.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },

                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editCredNameDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

        },
        'DELETING_COVERED_CREDENTIALS': {
            pageTitle: 'Deleting Covered Credentials',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to System > Credentials.',
                                        boldTexts: ['System', 'Credentials'],
                                    },

                                    {
                                        title: '2. Select the credential, click the red X next to the covered credential, and confirm by clicking Remove.',
                                        boldTexts: ['red X', 'Remove'],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteCovCredDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Credentials Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > System section in the left pane and click on the > Credentials tab.',
                                        boldTexts: ['System', 'Credentials'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Delete a Covered Credential',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Select the credential for which you want to delete a covered credential.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'Go to the right table and find the covered credential you want to delete.',
                                        boldTexts: ['blue pencil'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click on the red X icon next to the covered credential.',
                                        boldTexts: ['red X icon'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Confirm deletion by clicking Remove on the confirmation modal.',
                                        boldTexts: ['Remove'],
                                        circle: true,
                                    },

                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteCovCredDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

        },
        'ADDING_DEPARTMENTS': {
            pageTitle: 'Adding Departments',
            content: {
                short: [

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to System > Departments.',
                                        boldTexts: ['System', 'Departments'],
                                    },

                                    {
                                        title: '2. Enter the Department Name and Click + Add Department.',
                                        boldTexts: ['Add Department'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addingNewDepDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Departments Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > System section in the left pane and click on the > Departments tab.',
                                        boldTexts: ['System', 'Departments'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Add a New Department',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Enter the department name in the Department Name field.',
                                        boldTexts: ['Department Name'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click the + Add Department button.',
                                        boldTexts: ['Add Department'],
                                        circle: true,
                                    },

                                    {
                                        title: 'The newly added department will appear in the list under Departments.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addingNewDepDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

        },
        'EDITING_DEPARTMENTS': {
            pageTitle: 'Editing Departments',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to System > Departments.',
                                        boldTexts: ['System', 'Departments'],
                                    },

                                    {
                                        title: '2. Click the pencil icon next to the Department to edit.',
                                        boldTexts: ['pencil icon'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingDepDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '3. Make changes and click Save.',
                                        boldTexts: ['Save'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingDepDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Departments Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > System section in the left pane and click on the > Departments tab.',
                                        boldTexts: ['System', 'Departments'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingDepDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Access the Departments Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Find the department you want to edit in the list under Departments.',
                                        boldTexts: ['Departments'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click on the blue pencil icon next to the department name.',
                                        boldTexts: ['blue pencil'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Edit the Department Name field with the new name.',
                                        boldTexts: ['Department Name'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click Save to apply the changes.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },

                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingDepDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

        },
        'ADDING_JOB_TITLES': {
            pageTitle: 'Adding Job Titles',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to System > Job Titles.',
                                        boldTexts: ['System', 'Job Titles'],
                                    },

                                    {
                                        title: '2. Enter the Job Title  and Click + Add Job Title.',
                                        boldTexts: ['Add Job Title'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addingNewJobDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: 'Creating Credentials',
                    },
                    {
                        subTitle: '1. Access the Job Titles Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the  > System section in the left pane and click on the > Job Titles tab.',
                                        boldTexts: ['System', 'Job Titles'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Add a New Job Title',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Enter the job title in the Job Title field.',
                                        boldTexts: ['Job Title'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click the + Add Job Title button.',
                                        boldTexts: ['Add Job Title'],
                                        circle: true,
                                    },

                                    {
                                        title: 'The newly added job title will appear in the list under Job Titles.',
                                        boldTexts: ['Job Titles'],
                                        circle: true,
                                    },

                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addingNewJobDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

        },
        'EDITING_JOB_TITLES': {
            pageTitle: 'Editing Job Titles',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to System > Job Titles.',
                                        boldTexts: ['System', 'Job Titles'],
                                    },

                                    {
                                        title: '2. Click the pencil icon next to the Job Title,  to edit.',
                                        boldTexts: ['pencil icon'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingJobTitleDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '3. Make changes and click Save.',
                                        boldTexts: ['Save'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingJobTitleDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Job Titles Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > System section in the left pane and click on the > Job Titles tab.',
                                        boldTexts: ['System', 'Job Titles'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingJobTitleDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Edit an Existing Job Title',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Find the job title you want to edit in the list under Job Titles.',
                                        boldTexts: ['Job Titles'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click on the blue pencil icon next to the job title.',
                                        boldTexts: ['blue pencil icon'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Edit the Job Title field with the new name.',
                                        boldTexts: ['Job Title'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click Save to apply the changes',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingJobTitleDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

        },
        'ADDING_PAYCODE_TYPES': {
            pageTitle: 'Adding Paycode Types',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to System > Payroll Setup.',
                                        boldTexts: ['System', 'Payroll Setup'],
                                    },

                                    {
                                        title: '2. Enter the Paycode Type Details(e.g., name, Code, Type, etc).',
                                        boldTexts: [''],
                                    },

                                    {
                                        title: '3. Click + Add Paycode Type to save.',
                                        boldTexts: ['Add Paycode Type'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addingPayTypesDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Payroll Setup Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the >System section in the left pane and click on the Payroll Setup tab.',
                                        boldTexts: ['System', 'Payroll Setup'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Add a New Paycode Type',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Ensure the Paycode Types toggle is selected on the left side of the table.',
                                        boldTexts: ['Paycode Types'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Under Add New Paycode Type, fill in the following fields:',
                                        boldTexts: ['Add New Paycode Type'],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Name: Enter the name for the paycode (e.g., "BCBA Hourly," "Drive Time," "Meal Break," etc.).',
                                                boldTexts: ['Name'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Code: Enter a code for the paycode (e.g., "BCBA H," "DT," "MB," etc.).',
                                                boldTexts: ['Code'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Type: Select between Hourly or Salary depending on the usage of this paycode.',
                                                boldTexts: ['Type'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Apply Overtime?: Choose Yes or No.',
                                                boldTexts: ['Apply Overtime'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Apply PTO?: Choose Yes or No.',
                                                boldTexts: ['Apply PTO', 'No'],
                                                circle: true,
                                            },

                                        ],
                                    },

                                    {
                                        title: 'Click the blue Add Paycode Type button. The new paycode type will appear in the table on the right.',
                                        boldTexts: ['Add Paycode Type'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addingPayTypesDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

        },
        'EDITING_PAYCODE_TYPES': {
            pageTitle: 'Editing Paycode Types',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to System > Paycode types.',
                                        boldTexts: ['System', 'Paycode types'],
                                    },

                                    {
                                        title: '2. Click the pencil icon next to the Paycode Type,  to edit.',
                                        boldTexts: ['pencil icon', 'Paycode Type'],
                                    },

                                ],
                            },
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editPayTypeDesktop1,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '3. Make changes and click Save.',
                                        boldTexts: ['Save'],
                                    },

                                ],
                            },
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editPayTypeDesktop2,
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Payroll Setup Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the >System section in the left pane and click on the Payroll Setup tab.',
                                        boldTexts: ['System', 'Payroll Setup'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Select Paycode Types',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Ensure the Paycode Types toggle is selected on the left side of the table.',
                                        boldTexts: ['Paycode Types'],
                                        circle: true,
                                    },

                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Edit a Paycode Type',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Navigate to the right table and find the paycode type you want to edit.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click on the blue pencil icon next to the desired paycode type.',
                                        boldTexts: ['blue pencil icon'],
                                        circle: true,
                                    },

                                ],
                            },
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editPayTypeDesktop1,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Modify Paycode Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Edit any of the following fields as needed:',
                                        boldTexts: [''],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Name: Change the name of the paycode.',
                                                boldTexts: ['Name'],
                                                circle: true,
                                            },
                                            {
                                                title: 'Code: Update the code associated with the paycode.',
                                                boldTexts: ['Code'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Type: Switch between Hourly or Salary.',
                                                boldTexts: ['Type'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Apply Overtime?: Choose Yes or No.',
                                                boldTexts: ['Apply Overtime', 'Yes', 'No'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Apply PTO?: Choose Yes or No.',
                                                boldTexts: ['Apply PTO', 'Yes', 'No'],
                                                circle: true,
                                            },

                                        ],
                                    },

                                ],
                            },
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editPayTypeDesktop2,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '5. Save Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click Save to apply the changes.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },
                                ],
                            },
                            {
                                notes: [
                                    {
                                        text: 'Note: Changes made to a paycode will only affect future payroll calculations and paycodes. Past, already processed paycodes will remain unchanged.',
                                        boldTexts: ['Note'],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'ADDING_MILEAGE': {
            pageTitle: 'Adding Mileage Compensation',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to System > Payroll Setup > Milage Compensation.',
                                        boldTexts: ['System', 'Payroll Setup', 'Milage Compensation'],
                                    },

                                    {
                                        title: '2. Enter the Milage Compensation Details(e.g., name, Start Date).',
                                        boldTexts: [''],
                                    },

                                    {
                                        title: '3. Click + Add Milage Compensation to save.',
                                        boldTexts: ['Add Milage'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addingMileageDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Payroll Setup Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the >System section in the left pane and click on the Payroll Setup tab.',
                                        boldTexts: ['System', 'Payroll Setup'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Add a New Mileage Compensation',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Ensure the Mileage Compensation toggle is selected on the left side of the table.',
                                        boldTexts: ['Mileage Compensation'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Under Add New Mileage Compensation, fill in the following fields:',
                                        boldTexts: ['Add New Paycode Type'],
                                        circle: true,

                                        list: [
                                            {
                                                title: '$ Mileage Compensation: Enter the amount to be compensated per mile of driving.',
                                                boldTexts: ['Mileage Compensation'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Start Date: Enter the start date for this compensation (this date is for record-keeping purposes only, as only one active mileage compensation is allowed at a time).',
                                                boldTexts: ['Start Date'],
                                                circle: true,
                                            },

                                        ],
                                    },

                                    {
                                        title: 'Click the blue Add Mileage Compensation button. The new mileage compensation will appear in the table on the right.',
                                        boldTexts: ['Add Mileage Compensation'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Note: If there is already an active mileage compensation, you must deactivate it before adding a new one.',
                                        boldTexts: ['Note'],
                                    },

                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addingMileageDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

        },
        'EDITING_MILEAGE': {
            pageTitle: 'Editing Mileage Compensation',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to System > Milage Compensation.',
                                        boldTexts: ['System', 'Milage Compensation'],
                                    },

                                    {
                                        title: '2. Click the pencil icon next to the active Milage Compensation,  to edit.',
                                        boldTexts: ['pencil icon', 'Milage Compensation'],
                                    },

                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingMilDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '3. Make changes and click Save.',
                                        boldTexts: ['Save'],
                                    },

                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingMilDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Payroll Setup Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the System section in the left pane and click on the Payroll Setup tab.',
                                        boldTexts: ['System', 'Payroll Setup'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Select Mileage Compensation',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Ensure the Mileage Compensation toggle is selected on the left side of the table.',
                                        boldTexts: ['Mileage Compensation'],
                                        circle: true,
                                    },

                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Edit the Active Mileage Compensation',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Navigate to the right table and click the blue pencil icon next to the active mileage compensation.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingMilDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Modify Mileage Compensation Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Edit the Mileage Compensation field (e.g., the amount per mile).',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'Update the Start Date field as needed.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Save Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click Save to apply your changes. The updated details will reflect on the active mileage compensation line.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingMilDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

        },
        'INACTIVATING_MILEAGE': {
            pageTitle: 'Inactivating Mileage Compensation',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to System > Milage Compensation.',
                                        boldTexts: ['System', 'Milage Compensation'],
                                    },

                                    {
                                        title: '2. Click the Inactivate button to the active Milage Compensation.',
                                        boldTexts: ['Inactivate', 'Milage Compensation'],
                                    },

                                    {
                                        title: '3. Confirm by clicking Inactivate.',
                                        boldTexts: ['Inactivate'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.inactivatingMilDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Payroll Setup Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the System section in the left pane and click on the Payroll Setup tab.',
                                        boldTexts: ['System', 'Payroll Setup'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Select Mileage Compensation',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Ensure the Mileage Compensation toggle is selected on the left side of the table.',
                                        boldTexts: ['Mileage Compensation'],
                                        circle: true,
                                    },

                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Inactivate the Active Mileage Compensation',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Navigate to the right table and click the red Inactivate button next to the active mileage compensation.',
                                        boldTexts: ['red Inactivate button'],
                                        circle: true,
                                    },

                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Confirm Inactivation',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click Inactivate on the confirmation modal.',
                                        boldTexts: ['Inactivate'],
                                        circle: true,
                                    },

                                    {
                                        title: 'You will see a date appear in place of the recently inactivated mileage compensation, indicating the date it was inactivated',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.inactivatingMilDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

        },
        'ADDING_PLACE': {
            pageTitle: 'Adding a Place of Service',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to System > Place of Service.',
                                        boldTexts: ['System', 'Place of Service'],
                                    },

                                    {
                                        title: '2. Enter the Place Details(e.g., name, code, modifier).',
                                        boldTexts: [''],
                                    },

                                    {
                                        title: '3. Click + Add Place of Service to save.',
                                        boldTexts: ['Add Place of Service'],
                                    },
                                ],
                            },
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.referenceCommonDesktop,
                                    },
                                ],
                            },

                        ],
                    },

                ],
                long: [
                    {
                        subTitle: '1. Access the Place of Service Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the System section in the left pane and click on the Place of Service tab.',
                                        boldTexts: ['System', 'Place of Service'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Fill in the Place of Service Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Enter the required details in the three fields at the top of the table:',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'Under Add New Paycode Type, fill in the following fields:',
                                        boldTexts: ['Add New Paycode Type'],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Name: Enter the name of the place (e.g., "School," "Telehealth," "In Home," etc.).',
                                                boldTexts: ['Name'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Code: Enter the code that will appear on the 1500 form (e.g., "01," "02," "11," etc.).',
                                                boldTexts: ['Code'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Modifier: Enter the modifier for the Place of Service that will go on the 1500 form (e.g., "25," "95," "GT," etc.).',
                                                boldTexts: ['Modifier'],
                                                circle: true,
                                            },

                                        ],
                                    },

                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Reference Common Places of Service',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Use the table of examples below for Places of Service, Codes, and Modifiers.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'Keep in mind this table is an example check with rules and regulations regarding your industry to insure compliance.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                ],
                            },
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.referenceCommonDesktop,
                                    },
                                ],
                            },

                        ],
                    },

                ],
            },

        },
        'EDITING_PLACE': {
            pageTitle: 'Editing a Place of Service',
            content: {
                short: [

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to System > Place of Service.',
                                        boldTexts: ['System', 'Place of Service'],
                                    },

                                    {
                                        title: '2. Click the pencil icon next to the Place of Service,  to edit.',
                                        boldTexts: ['pencil icon', 'Place of Service'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingPlaceDesktop1,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '3. Make changes and click Save.',
                                        boldTexts: ['Save'],
                                    },

                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingPlaceDesktop2,
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Place of Service Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the >System section in the left pane and click on the Place of Service tab.',
                                        boldTexts: ['System', 'Place of Service'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Select the Place of Service to Edit',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'ENavigate to the table where the previously added places of service are listed.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingPlaceDesktop1,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Edit the Place of Service Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the blue pencil icon next to the place of service you want to edit.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Modify Paycode Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Edit any of the following fields as needed:',
                                        boldTexts: [''],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Name: Update the name of the place (e.g., "School," "Telehealth," "In Home," etc.).',
                                                boldTexts: ['Name'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Code: Change the code that appears on the 1500 form (e.g., "01," "02," "11," etc.).',
                                                boldTexts: ['Code'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Modifier: Edit the modifier associated with the Place of Service for the 1500 form (e.g., "25," "95," "GT," etc.).',
                                                boldTexts: ['Modifier'],
                                                circle: true,
                                            },

                                        ],
                                    },

                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingPlaceDesktop2,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '5. Save Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click Save to apply the changes. The updated details will reflect in the table.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },

                                ],
                            },
                        ],
                    },

                ],
            },

        },
    },
    'FUNDING_SOURCE': {
        'CREATE_FUNDING': {
            pageTitle: 'Creating a Funding Source',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Funding Source in the left pane.',
                                        boldTexts: ['Funding Source'],
                                    },

                                    {
                                        title: '2. Click + Add Funding Source.',
                                        boldTexts: ['Add Funding Source'],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.createFundingDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '3. Fill in the details (e.g., Funding Source Name, Email, Phone, Payor ID).',
                                        boldTexts: [''],
                                    },

                                    {
                                        title: '4. Click Add to save the new funding source.',
                                        boldTexts: [''],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.createFundingDesktop2,
                                    },
                                ],
                            },
                        ],


                    },

                ],
                long: [
                    {
                        subTitle: '1. Access the Funding Source Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the Funding Source section in the left pane.',
                                        boldTexts: ['Funding Source'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Add a New Funding Source',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the blue + Add Funding Source button at the top right of the page.',
                                        boldTexts: ['Add Funding Source'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.createFundingDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Fill Out the Funding Source Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Funding Source Name: Enter the name of the insurance company (e.g., "Blue Shield," "Molina," etc.).',
                                        boldTexts: ['Funding Source Name'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Type: Choose from the options:',
                                        boldTexts: ['Type'],
                                        circle: true,
                                        list: [
                                            {
                                                title: 'Private Insurance',
                                                boldTexts: ['Private Insurance'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Public Insurance',
                                                boldTexts: ['Public Insurance'],
                                                circle: true,
                                            },

                                            {
                                                title: 'School',
                                                boldTexts: ['School'],
                                                circle: true,
                                            },
                                        ],
                                    },

                                    {
                                        title: 'Contact Person: Enter the name,title & Email address of the contact person at the funding source.',
                                        boldTexts: ['Contact Person'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Phone Number: Enter the contact phone number.',
                                        boldTexts: ['Phone Number'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Payor ID: Enter the Payor ID provided by the insurance company.',
                                        boldTexts: ['Payor ID'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Vendor ID: Enter the Vendor ID provided by the insurance company.',
                                        boldTexts: ['Vendor ID'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Email Address: Provide the general email address for the funding source ',
                                        boldTexts: ['Email Address'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Website: Enter the website of the funding source.',
                                        boldTexts: ['Website'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Street Address: Provide the address of the insurance company.',
                                        boldTexts: ['Street Address'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Unit/Suite #: Enter the suite or unit number if applicable.',
                                        boldTexts: ['Unit', '/', 'Suite', '#'],
                                        circle: true,
                                    },

                                    {
                                        title: 'P.O.Box:',
                                        boldTexts: ['P.O.Box'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.createFundingDesktop2,
                                    },
                                ],
                            },
                        ],


                    },

                    {
                        subTitle: '4. Save the Funding Source',

                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Add button at the bottom of the form.',
                                        boldTexts: ['Add', 'button'],
                                        circle: true,
                                    },

                                    {
                                        title: 'The new funding source will appear in the table.',
                                        boldTexts: ['funding'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },
                ],
            },
        },
        'ADDING_SERVICE_FUNDING': {
            pageTitle: 'Adding Services to a Funding Source',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Funding Source, select a funding source.',
                                        boldTexts: ['Funding Source'],
                                    },

                                    {
                                        title: '2. Click the Services tab and click + Add Service.',
                                        boldTexts: ['Services', 'Add Service'],
                                    },

                                    {
                                        title: '3. Fill in the service details (e.g., Service, CPT Code, Unit Size).',
                                        boldTexts: ['Services', 'Add Service'],
                                    },

                                    {
                                        title: '4. Click Add to save.',
                                        boldTexts: ['Services', 'Add Service'],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addServFundDesktop,
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Services Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the >Funding Source section in the left pane.',
                                        boldTexts: ['Funding Source'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Select the funding source to which you want to add a service.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Add a New Service',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Select the Services tab at the top of the page.',
                                        boldTexts: ['Services'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click the blue + Add Service button at the top right of the page.',
                                        boldTexts: ['Add Service'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Fill in and select the appropriate fields in the Add New Service modal:',
                                        boldTexts: ['Add New Service'],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Service: Choose the type of service',
                                                boldTexts: ['Service'],
                                                circle: true,
                                            },

                                            {
                                                title: 'CPT Coder: Enter the CPT code',
                                                boldTexts: ['CPT Coder'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Type: Choose the service type.',
                                                boldTexts: ['Type'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Unit Size (Minutes): Enter how many minutes are in 1 unit for this service type (e.g., if 1 unit = 15 minutes, type “15”).',
                                                boldTexts: ['Unit Size', 'Minutes'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Min Unit and Max Unit: Enter the minimum and maximum number of units allowed in one appointment (e.g., Min = 1, Max = 4 with a unit size of 15 minutes means an appointment must be between 15 to 60 minutes).',
                                                boldTexts: ['Min Unit and Max Unit'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Require Signature: Toggle to enable if every appointment with this service type will require a signature to be rendered.',
                                                boldTexts: ['Require Signature'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Require Notes: Toggle to enable if every appointment with this service type will require a note to be rendered.',
                                                boldTexts: ['Require Notes'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Save the Service',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Add button at the bottom of the modal.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'The new service type will appear in the table.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                ],
                            },


                        ],
                    },

                    {
                        subTitle: '4. Add Charge Rates to the Service',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Once the service is added, you will need to add charge rates to it.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addServFundDesktop,
                                    },
                                ],
                            },

                        ],
                    },
                ],
            },

        },
        'ADDING_CHARGE_RATES': {
            pageTitle: 'Adding Charge Rates to a Funding Source',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Funding Source, select a funding source.',
                                        boldTexts: ['Funding Source'],
                                    },

                                    {
                                        title: '2. Click the Services tab and click Charge Rates next to a service.',
                                        boldTexts: ['Services', 'Charge Rates'],
                                    },

                                    {
                                        title: '3. Fill in the charge rate details (e.g., Title, Billed Rate, Credentials).',
                                        boldTexts: [''],
                                    },

                                    {
                                        title: '4. Click Add to save the charge rate.',
                                        boldTexts: ['Add'],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [

                            {
                                videos: [
                                    {
                                        desktop: Videos.addChargeRateDesktop,
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Services Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the >Funding Source section in the left pane.',
                                        boldTexts: ['Funding Source'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Select the funding source to which you want to add a service.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Add Charge Rates to the Service',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Select the Services tab at the top of the page.',
                                        boldTexts: ['Services'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Find the service for which you want to add charge rates in the table and click the blue Charge Rates button next to it..',
                                        boldTexts: ['blue Charge Rates'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Fill Out the Charge Rates',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the [Service Name] Charge Rates modal, click on the blue + Add Charge Rate button at the top right.',
                                        boldTexts: ['Service Name', 'Add Charge Rate'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Fill in and select the following fields:',
                                        boldTexts: [''],
                                        circle: true,
                                        list: [
                                            {
                                                title: 'Title: Enter the title of the charge rate (e.g., "BCBA," "BI," "CM," etc.).',
                                                boldTexts: ['Title'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Modifier Name: Enter the modifier name (e.g., "HM," "HO," "HT," etc.).',
                                                boldTexts: ['Modifier Name'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Billed Rate ($): Enter the rate you would like to bill the insurance.',
                                                boldTexts: ['Billed Rate', '($)'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Allowed Rate ($): Enter the rate you expect to be paid.',
                                                boldTexts: ['Allowed Rate'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Region: Specify the region (e.g., "Los Angeles").',
                                                boldTexts: ['Region'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Credentials: Choose the corresponding credentials from the list of credentials added under System > Credentials.',
                                                boldTexts: ['Credentials', 'System', 'Credentials'],
                                                circle: true,
                                            },

                                        ],
                                    },

                                ],
                            },


                        ],
                    },

                    {
                        subTitle: '4. Save the Charge Rate',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Add button to save the charge rate. It will appear in the list for that service.',
                                        boldTexts: ['Add'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                videos: [
                                    {
                                        desktop: Videos.addChargeRateDesktop,
                                    },
                                ],
                            },

                        ],
                    },
                ],
            },

        },
        'ACTIVATING_INACTIVATING_FUNDING': {
            pageTitle: 'Activating or Inactivating a Funding Source',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Funding Source.',
                                        boldTexts: ['Funding Source'],
                                    },

                                    {
                                        title: '2. Select the funding source.',
                                        boldTexts: [''],
                                    },

                                    {
                                        title: '3. Click the Active or Inactive button on the top right to toggle the status.\n',
                                        boldTexts: [' Active', 'Inactive'],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.activeInactiveFundDesktop,
                                    },
                                ],
                            },
                        ],


                    },
                ],
                long: [
                    {
                        subTitle: '1. Navigate to Role Management',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the left pane and click >Role Management',
                                        boldTexts: ['Role Management'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Select the Funding Source',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the funding source you want to activate or inactivate from the list.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Activate or Inactivate the Funding Source',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'On the top right of the page, you will see a button labeled Active or Inactive, depending on the current state of the funding source.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click on this button and select the desired option to change the status of the funding source.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.activeInactiveFundDesktop,
                                    },
                                ],
                            },
                        ],


                    },
                ],
            },

        },
        'MERGING_APPOINTMENTS': {
            pageTitle: 'Merging Appointments',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Funding Source, select a funding source.',
                                        boldTexts: ['Funding Source'],
                                    },

                                    {
                                        title: '2. Toggle Merge Appts. On/Off to enable or disable merging.',
                                        boldTexts: ['Merge Appts'],
                                    },
                                ],
                            },
                        ],
                    },


                    {
                        list: [
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.mergingAppointmentsDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Funding Source Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Funding Source section in the left pane.',
                                        boldTexts: ['Funding Source'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Select the Funding Source',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the funding source for which you would like to enable merging appointments.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Toggle Merge Appointments On/Off',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Navigate to the top right of the page and toggle the Merge Appts. option On or Off.',
                                        boldTexts: ['Merge Appts', 'On', 'Off'],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Effect of Toggling On Merge Appointments',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Toggling On Merge Appts. will merge appointments on the claims for this funding source.',
                                        boldTexts: [''],
                                    },
                                ],
                            },

                            {
                                notes: [
                                    {
                                        text: 'Note: This will only affect appointments going forward and not the appointments already submitted in claims.',
                                        boldTexts: ['Note'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.mergingAppointmentsDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

        },
        'DOWNLOADING_CSV_FUNDING': {
            pageTitle: 'Downloading a CSV of the Funding Sources',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Funding Source.',
                                        boldTexts: ['Funding Source'],
                                    },

                                    {
                                        title: '2. Click Download CSV at the top right.',
                                        boldTexts: ['Download CSV'],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.downloadingCsvDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Funding Source Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the >Funding Source section in the left pane.',
                                        boldTexts: ['Funding Source'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Locate the Download CSV Button',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Once in the Funding Source section, navigate to the top right of the page.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Download the CSV',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Download CSV button.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Save the CSV File',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The CSV file containing the funding source details will automatically download to your device\'s default download location.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.downloadingCsvDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

        },
        'EDITING_FUNDING': {
            pageTitle: 'Editing Funding Source',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Funding Source.',
                                        boldTexts: ['Funding Source'],
                                    },

                                    {
                                        title: '2. Click the pencil icon to edit the funding source details.',
                                        boldTexts: ['pencil icon'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingFundingDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '3. Click Save to apply changes.',
                                        boldTexts: ['Save'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingFundingDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Funding Source Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the Funding Source section in the left pane.',
                                        boldTexts: ['Funding Source'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Select the Funding Source',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the funding source for which you would like to Edit.',
                                        boldTexts: ['Services'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Open the Edit Funding Source Modal',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the blue pencil icon at the top right of the screen.',
                                        boldTexts: ['blue pencil'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingFundingDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Edit the Funding Source Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Edit Funding Source modal, make the necessary changes to the fields.',
                                        boldTexts: ['Edit Funding Source'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Save Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click Save to apply the changes.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },
                                ],
                            },
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingFundingDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'INACTIVATING_ACTIVE_FUNDING': {
            pageTitle: 'Inactivating an Active Funding Source Service',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Funding Source > Services.',
                                        boldTexts: ['Funding Source', 'Services'],
                                    },

                                    {
                                        title: '2. Click the pencil icon next to a service.',
                                        boldTexts: ['Funding Source', 'pencil icon'],
                                    },

                                    {
                                        title: '3. Toggle the Service Status to Off and click Save.',
                                        boldTexts: ['Service Status', 'Off', 'Save'],
                                    },
                                ],
                            },

                            {
                                videos: [
                                    {
                                        desktop: Videos.inactiveActiveFundDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Funding Source Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the Funding Source section in the left pane.',
                                        boldTexts: ['Funding Source'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Select the Funding Source',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the funding source for which you want to inactivate a service.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Services Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Services tab at the top of the page.',
                                        boldTexts: ['Services'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. View Active Services',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Ensure the Active tab is selected to view active funding source services.',
                                        boldTexts: ['Active'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Open the Edit Service Modal',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the blue pencil icon next to the active service you want to inactivate.',
                                        boldTexts: ['blue pencil icon'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Toggle Service Status Off',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Edit Service modal, toggle the Service Status to Off at the bottom. This action will move the funding source service to the Inactive tab.',
                                        boldTexts: ['Edit Service', 'Service Status', 'Off', 'Inactive'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. Save Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click Save at the bottom of the modal to apply the changes.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                videos: [
                                    {
                                        desktop: Videos.inactiveActiveFundDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

        },
        'ACTIVATING_INACTIVE_FUNDING': {
            pageTitle: 'Activating an Inactive Funding Source Service',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Funding Source > Services.',
                                        boldTexts: ['Funding Source', 'Services'],
                                    },

                                    {
                                        title: '2. Click the Inactive tab, select a service, and click the pencil icon.',
                                        boldTexts: ['Inactive', 'pencil icon'],
                                    },

                                    {
                                        title: '3. Toggle Service Status to On and click Save.',
                                        boldTexts: ['Service Status', 'Save'],
                                    },
                                ],
                            },

                            {
                                videos: [
                                    {
                                        desktop: Videos.activeInactiveFundingDesktop,
                                    },
                                ],
                            },
                        ],

                    },

                ],
                long: [
                    {
                        subTitle: '1. Access the Funding Source Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Funding Source section in the left pane.',
                                        boldTexts: ['Funding Source'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Select the Desired Funding Source',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the funding source for which you want to activate a service.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Ensure the Inactive Toggle is Selected',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Make sure the Inactive tab is selected to view the currently inactive services for the funding source.',
                                        boldTexts: ['Inactive'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Edit the Service',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Find the inactive service you want to activate, click the blue pencil icon next to it to open the Edit Service modal.',
                                        boldTexts: ['blue pencil icon'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Toggle Service Status On',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Edit Service modal, toggle the Service Status to On at the bottom.',
                                        boldTexts: ['Service Status', 'On'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Save Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click Save at the bottom of the modal to apply the changes.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                videos: [
                                    {
                                        desktop: Videos.activeInactiveFundingDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

        },
        'EDITING_FUNDING_SERVICE': {
            pageTitle: 'Editing a Funding Source Service',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Funding Source > Services.',
                                        boldTexts: ['Funding Source', 'Services'],
                                    },

                                    {
                                        title: '2. Click the pencil icon next to a service.',
                                        boldTexts: ['pencil icon'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingFundServiceDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '3. Make changes and click Save.',
                                        boldTexts: ['Save'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingFundServiceDesktop2,
                                    },
                                ],
                            },
                        ],
                    },


                ],
                long: [
                    {
                        subTitle: '1. Access the Funding Source Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the Funding Source section in the left pane.',
                                        boldTexts: ['Funding Source'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Select the Funding Source',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the funding source for which you want to Edit a service.',
                                        boldTexts: ['Services'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Services Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Services tab at the top of the page.',
                                        boldTexts: ['Services'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingFundServiceDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Open the Edit Service Modal',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the blue pencil icon next to the service you want to edit.',
                                        boldTexts: ['blue pencil icon'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Edit Service Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Edit Service modal, make the necessary changes to the service fields. You can also toggle the Service Status on or off if needed.',
                                        boldTexts: ['Edit Service'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingFundServiceDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Save Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click Save at the bottom of the modal to apply the changes.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                ],
            },
        },
        'ADDING_NOTES_FUNDING': {
            pageTitle: 'Adding Notes to a Funding Source',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Funding Source > Notes.',
                                        boldTexts: ['Funding Source', 'Notes'],
                                    },

                                    {
                                        title: '2. Click + Add Note, fill in the subject and body, then click Save.',
                                        boldTexts: ['Add Note', 'Save'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addNotFundDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Funding Source Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Funding Source section in the left pane.',
                                        boldTexts: ['Funding Source'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Select the Funding Source',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the funding source to which you want to add a note.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Notes Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Notes tab at the top of the page.',
                                        boldTexts: ['Notes'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Add a New Note',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the + Add Note button on the top right of the page.',
                                        boldTexts: ['Add Note'],
                                        circle: true,
                                    },

                                    {
                                        title: 'In the Add Note modal, fill in the following fields:',
                                        boldTexts: ['Add Note'],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Subject: Enter the subject of the note.',
                                                boldTexts: ['Subject'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Body: Enter the body content of the note.',
                                                boldTexts: ['Body'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Save the Note',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click Save on the bottom right of the modal.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },

                                    {
                                        title: 'The new note will appear in the notes table.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addNotFundDesktop,
                                    },
                                ],
                            },
                        ],
                    },

                ],
            },
        },
        'EDITING_FUNDING_NOTES': {
            pageTitle: 'Editing Funding Source Notes',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Funding Source > Notes.',
                                        boldTexts: ['Funding Source', 'Notes'],
                                    },

                                    {
                                        title: '2. Click the pencil icon next to a note, edit the fields, and click Save.',
                                        boldTexts: [' pencil icon', 'Save'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingFundNotesDesktop,
                                    },
                                ],
                            },
                        ],
                    },

                ],
                long: [
                    {
                        subTitle: '1. Access the Funding Source Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the >Funding Source section in the left pane.',
                                        boldTexts: ['Funding Source'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Select the Funding Source',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the funding source whose notes you want to edit.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Notes Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Notes tab at the top of the page.',
                                        boldTexts: ['Notes'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Edit the Note',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Find the note you want to edit in the notes table and click on the blue pencil icon to the right of it.',
                                        boldTexts: ['blue pencil icon'],
                                        circle: true,
                                    },
                                ],
                            },


                        ],
                    },

                    {
                        subTitle: '5. Update the Fields',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Edit the Subject and/or Body fields as needed.',
                                        boldTexts: ['Subject', 'Body'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Save Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click Save to apply the changes. The updated note will reflect in the notes table.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editingFundNotesDesktop,
                                    },
                                ],
                            },
                        ],
                    },

                ],
            },
        },
        'DELETING_FUNDING_NOTES': {
            pageTitle: 'Deleting Funding Source Notes',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Funding Source > Notes.',
                                        boldTexts: ['Funding Source', 'Notes'],
                                    },

                                    {
                                        title: '2. Click the X next to a note and confirm deletion.',
                                        boldTexts: ['X'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteFundNotesDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Funding Source Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Funding Source section in the left pane.',
                                        boldTexts: ['Funding Source'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Select the Funding Source',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the funding source whose note you want to delete.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Notes Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Notes tab at the top of the page.',
                                        boldTexts: ['Notes'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Delete the Note',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Locate the note you want to delete in the notes table.',
                                        boldTexts: ['blue pencil icon'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click on the X button next to the note.',
                                        boldTexts: ['X button'],
                                        circle: true,
                                    },
                                ],
                            },


                        ],
                    },

                    {
                        subTitle: '5. Confirm Deletion',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click Delete on the confirmation modal. The note will be removed from the notes table.',
                                        boldTexts: ['Delete'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteFundNotesDesktop,
                                    },
                                ],
                            },
                        ],
                    },

                ],
            },
        },
        'VIEWING_FUNDING_CHANGES_HISTORY': {
            pageTitle: 'Viewing Funding Source Changes History',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Funding Source > History.',
                                        boldTexts: ['Funding Source', 'History'],
                                    },

                                    {
                                        title: '2. Review the change history of the selected funding source.',
                                        boldTexts: [''],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.viewFundChangeHistoryDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Funding Source Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Funding Source section in the left pane.',
                                        boldTexts: ['Funding Source'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Select the Funding Source',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the funding source for which you would like to see the change history',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the History Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the History tab at the top of the page.',
                                        boldTexts: ['History'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. View Change History',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Review the history of changes made to the funding source, which may include edits to information, status changes, and other relevant modifications.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.viewFundChangeHistoryDesktop,
                                    },
                                ],
                            },

                        ],
                    },
                ],
            },
        },
        'UPLOADING_FUNDING_FILES': {
            pageTitle: 'Uploading Funding Source Files',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Funding Source > Files.',
                                        boldTexts: ['Funding Source', 'Files'],
                                    },

                                    {
                                        title: '2. Click Upload, select or drag-and-drop files, and click Add.',
                                        boldTexts: ['Upload', 'Add'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.uploadFundFilesDesktop1,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        list: [
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.uploadFundFilesDesktop2,
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Funding Source Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Funding Source section in the left pane.',
                                        boldTexts: ['Funding Source'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Select the Funding Source',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the funding source for which you would like to upload files.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Files Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Files tab at the top of the page.',
                                        boldTexts: ['Files'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Upload Files',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Upload Document button to add relevant documents or files associated with the funding source.',
                                        boldTexts: ['Upload Document'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Once the button is clicked, the Upload Document modal will appear.',
                                        boldTexts: ['Upload Document'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.uploadFundFilesDesktop1,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '5. Fill in the Fields and Upload Files',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Fill in the required fields in the Upload Document modal.',
                                        boldTexts: ['Upload Document'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click to upload or drag and drop the file into the drag and drop box.',
                                        boldTexts: ['drag and drop'],
                                        circle: true,
                                    },
                                ],
                            },
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.uploadFundFilesDesktop2,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '6. Review Uploaded Files in the Modal',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Once the file is dragged or selected, it will appear at the bottom of the modal.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'Repeat this process for each additional file you would like to upload.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. Save the Uploaded Files',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'After adding all the files, click the Add button on the bottom right of the modal.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '8. Save the Uploaded Files',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'You will see the uploaded files listed in the table under the Files tab.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'EDITING_FUNDING_FILES': {
            pageTitle: 'Editing Funding Source Files',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Funding Source > Files.',
                                        boldTexts: ['Funding Source', 'Files'],
                                    },

                                    {
                                        title: '2. Click the pencil icon next to a file, edit the fields, and click Save.',
                                        boldTexts: ['pencil icon', 'Save'],
                                    },
                                ],
                            },
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editFundFilesDesktop,
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Funding Source Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Funding Source section in the left pane.',
                                        boldTexts: ['Funding Source'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Select the Funding Source',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the funding source for which you want to edit a file.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Files Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Files tab at the top of the page to view all files associated with the funding source.',
                                        boldTexts: ['Files'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Locate the File to Edit',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the files table, find the file you wish to edit.',
                                        boldTexts: ['Files'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Edit the File',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the pencil icon on the right side of the file\'s line in the table.',
                                        boldTexts: ['Files'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Make Necessary Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The Edit File modal will appear. Make the necessary changes to the fields or replace the file as needed.',
                                        boldTexts: ['Files'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. Save Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'After making the desired edits, click Save at the bottom of the modal.',
                                        boldTexts: ['Files'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '8. View Updated File Information',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The updated details of the file will be reflected in the files table.',
                                        boldTexts: ['Upload'],
                                        circle: true,
                                    },
                                ],
                            },
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editFundFilesDesktop,
                                    },
                                ],
                            },

                        ],
                    },
                ],
            },
        },
        'DELETING_FUNDING_FILES': {
            pageTitle: 'Deleting Funding Source Files',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Funding Source > Files.',
                                        boldTexts: ['Funding Source', 'Files'],
                                    },

                                    {
                                        title: '2. Click the trashcan icon next to a file and confirm deletion.',
                                        boldTexts: ['trashcan icon'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deletingFundFilesDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Funding Source Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Funding Source section in the left pane.',
                                        boldTexts: ['Funding Source'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Select the Funding Source',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the funding source from which you want to delete files.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Files Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Files tab at the top of the page to view all files associated with the funding source.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Locate the File to Edit',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the files table, find the file you wish to delete.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Delete the File',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the trashcan icon on the right side of the file\'s line in the table.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Confirm Deletion',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'A confirmation modal will appear. Click Delete to confirm the removal of the file.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. View Updated File List',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The file will be removed from the table, and the updated list of files will be displayed.',
                                        boldTexts: ['Files'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deletingFundFilesDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'DOWNLOADING_FUNDING_FILES': {
            pageTitle: 'Downloading Funding Source Files',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Funding Source > Files.',
                                        boldTexts: ['Funding Source', 'Files'],
                                    },

                                    {
                                        title: '2. Click the cloud icon to download a file.',
                                        boldTexts: ['cloud icon'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.downFundFilesDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Funding Source Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Funding Source section in the left pane.',
                                        boldTexts: ['Funding Source'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Select the Funding Source',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the funding source from which you want to download a file.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Files Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Files tab at the top of the page to view all files associated with the funding source.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Locate the File to Download',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the files table, find the file you wish to download.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Download the File',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the cloud icon with a small arrow pointing down on the right side of the file\'s line in the table.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Save the File',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The file will be downloaded to your device. Choose the location where you want to save the file.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.downFundFilesDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'VIEWING_FUNDING_FILES': {
            pageTitle: 'Viewing the Funding Source Files',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Funding Source > Files.',
                                        boldTexts: ['Funding Source', 'Files'],
                                    },

                                    {
                                        title: '2. Click the eye icon to view a file preview.',
                                        boldTexts: ['eye icon'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.viewFundFilesDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Funding Source Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Funding Source section in the left pane.',
                                        boldTexts: ['Funding Source'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Select the Funding Source',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the funding source for which you want to view a file.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Files Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Files tab at the top of the page to see all files associated with the funding source.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Locate the File to View',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the files table, find the file you wish to view',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. View the File',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the eye icon on the right side of the file\'s line in the table',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. File Preview',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'A preview of the file will open, allowing you to view its contents without downloading it.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.viewFundFilesDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
    },
    'USER_ACCOUNT': {
        'CHANGING_PROFILE': {
            pageTitle: 'Changing Profile Picture',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Click on the avatar icon in the top-right corner.',
                                        boldTexts: [''],
                                    },

                                    {
                                        title: '2. In the dropdown, click Upload Profile Picture.',
                                        boldTexts: ['Upload Profile Picture'],
                                    },

                                    {
                                        title: '3. Click the upload area or drag and drop your image (JPEG, PNG, or JPG, max 2MB).',
                                        boldTexts: ['Upload Profile Picture'],
                                    },

                                    {
                                        title: '4. Click Save to upload the new profile picture.',
                                        boldTexts: ['Save'],
                                    },
                                ],

                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.changeProfDesktop,
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access Profile Settings',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the avatar icon in the top-right corner of the screen.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Open Upload Profile Picture Modal',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the dropdown that appears, click on the "Upload Profile Picture" button.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Upload a New Profile Picture',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the upload area labeled "Click to upload or drag and drop" or' +
                                            ' drag and drop your image file (JPEG, PNG, or JPG with a max size of 2MB) into the upload box.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Save the Profile Picture',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'After selecting the file, click the Save button to upload the new profile picture.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '5. Completion',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Your profile picture will now be updated.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],

                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.changeProfDesktop,
                                    },
                                ],
                            },

                        ],
                    },
                ],
            },

        },
        'CHANGING_PASSWORD': {
            pageTitle: 'Changing Password',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Click on the avatar icon in the top-right corner.',
                                        boldTexts: [''],
                                    },

                                    {
                                        title: '2. In the dropdown, click Change Password.',
                                        boldTexts: ['Change Password'],
                                    },

                                    {
                                        title: '3. Enter your current password, new password, and retype the new password.',
                                        boldTexts: [''],
                                    },

                                    {
                                        title: '4. Click Save to update your password.',
                                        boldTexts: ['Save'],
                                    },
                                ],

                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.userChangePassDesktop,
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access Profile Settings',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the avatar icon in the top-right corner of the screen.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Open Change Password Modal',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the dropdown that appears, click on the "Change Password" button.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Fill in the Password Fields',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Enter your current password in the Current Password field.',
                                        boldTexts: ['Current Password'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Enter your new password in the New Password field.',
                                        boldTexts: ['New Password'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Retype the new password in the Retype New Password field to confirm.',
                                        boldTexts: ['Retype New Password'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Save the New Password',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Save button to change your password.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '5. Completion',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Your password will now be updated, and you can use the new password for future logins.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],

                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.userChangePassDesktop,
                                    },
                                ],
                            },

                        ],
                    },
                ],
            },

        },
    },
    'CLIENT': {
        'ADDING_CLIENTS': {
            pageTitle: 'Adding Clients',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client > + Add Client.',
                                        boldTexts: ['Client', 'Add Client'],
                                    },

                                    {
                                        title: '2. Enter the Client General Info and Click Next.',
                                        boldTexts: ['Next'],
                                    },

                                    {
                                        title: '3. Enter the Client Other Details Info and Click Add.',
                                        boldTexts: ['Add'],
                                    },
                                ],

                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addClientDesktop,
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access Profile Settings',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Clients section in the left pane.',
                                        boldTexts: ['Clients'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Add a New Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the + Add Client button.',
                                        boldTexts: ['Add Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Fill in the Basic Information',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Complete the following fields:',
                                        boldTexts: [''],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'First Name',
                                                boldTexts: ['First Name'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Last Name',
                                                boldTexts: ['Last Name'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Email Address',
                                                boldTexts: ['Email Address'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Rendering Provider: Choose the staff member who will be the rendering provider for the client.',
                                                boldTexts: ['Rendering Provider'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Proceed to Fill Out Other Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click Next to move to the next section and fill out additional details.',
                                        boldTexts: ['Next'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '5. Fill in Other Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Complete the following fields:',
                                        boldTexts: [''],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Gender',
                                                boldTexts: ['Gender'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Date of Birth',
                                                boldTexts: ['Date of Birth'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Ethnicity',
                                                boldTexts: ['Ethnicity'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Language',
                                                boldTexts: ['Language'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Family Language',
                                                boldTexts: ['Family Language'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Registration Date',
                                                boldTexts: ['Registration Date'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],

                            },
                        ],
                    },
                    {
                        subTitle: '6. Add the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Add button to save the client.',
                                        boldTexts: ['Add'],
                                        circle: true,
                                    },
                                ],

                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addClientDesktop,
                                    },
                                ],
                            },

                        ],
                    },
                ],
            },

        },
        'ADDING_CONTACT_CLIENT': {
            pageTitle: 'Adding a Contact to a Client',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the Client and go to the Contacts tab.',
                                        boldTexts: ['Contacts'],
                                    },

                                    {
                                        title: '3. Click on the  + Add Contact button and fill in the details (Name, Phone Number, Email, Relationship, etc).',
                                        boldTexts: ['Add Contact button'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addContactClientDesktop1,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '4. Click Add to save.',
                                        boldTexts: ['Add'],
                                    },
                                ],

                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addContactClientDesktop2,
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Clients section in the left pane.',
                                        boldTexts: ['Clients'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client for whom you want to add a contact.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Contacts Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Contacts tab at the top of the client’s page.',
                                        boldTexts: ['Contacts'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Add a New Contact',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the + Add Contact button at the top right of the page.',
                                        boldTexts: ['Add Contact'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addContactClientDesktop1,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '5. Fill in the Contact Information',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Add Contact modal that appears, fill in the required fields:',
                                        boldTexts: [''],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'First Name: Enter the first name of the contact.',
                                                boldTexts: ['First Name'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Last Name: Enter the last name of the contact.',
                                                boldTexts: ['Last Name'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Phone Number: Enter the phone number of the contact.',
                                                boldTexts: ['Phone Number'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Email: Enter the email address of the contact.',
                                                boldTexts: ['Email'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Relationship: Specify the relationship of the contact to the client (e.g., Parent, Guardian, etc.).',
                                                boldTexts: ['Relationship'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Street Address: Enter the street address of the contact.',
                                                boldTexts: ['Street Address'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Unit/Suite #: Enter the unit or suite number if applicable.',
                                                boldTexts: ['Unit', 'Suite'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],

                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addContactClientDesktop2,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '6. Add the Contact',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Add button at the bottom right of the modal to save the contact.',
                                        boldTexts: ['Add'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. View the New Contact',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The newly added contact will appear in the Contacts table for the selected client.',
                                        boldTexts: ['contact', 'Contacts'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

        },
        'ADDING_ENROLLMENTS_CLIENT': {
            pageTitle: 'Adding Enrollments to Clients',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '2. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the Client and go to the Enrollments tab.',
                                        boldTexts: ['Enrollments'],
                                    },

                                    {
                                        title: '3. To add a Primary Enrollment, click on the + Add Primary Enrollment button.',
                                        boldTexts: ['Primary Enrollment', 'Add Primary Enrollment'],
                                    },

                                    {
                                        title: '4. To add a Secondary Enrollment, click on the + Add Secondary Enrollment button.',
                                        boldTexts: ['Secondary Enrollment', 'Add Secondary Enrollment'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addEnrolClientDesktop1,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '5. Click Add to save.',
                                        boldTexts: ['Add'],
                                    },
                                ],

                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addEnrolClientDesktop2,
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client for whom you want to add an enrollment.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Enrollments Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Enrollments tab at the top of the client’s page.',
                                        boldTexts: ['Enrollments'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Add a New Enrollment',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'To add a Primary Enrollment, click on the + Add Primary Enrollment button in the top left box.',
                                        boldTexts: ['Add Primary Enrollment'],
                                        circle: true,
                                    },

                                    {
                                        title: 'To add a Secondary Enrollment, click on the + Add Secondary Enrollment button in the top right box.',
                                        boldTexts: ['Add Secondary Enrollment', 'Add Secondary Enrollment'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addEnrolClientDesktop1,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '5. Fill in the Enrollment Information',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Add Enrollment modal that appears, fill in the required fields:',
                                        boldTexts: ['Add Enrollment'],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Funding Source: Select the funding source associated with the enrollment.',
                                                boldTexts: ['Funding Source'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Enrollment ID: Enter the enrollment ID.',
                                                boldTexts: ['Enrollment ID'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Enrollment Date: Enter the enrollment date in MM/DD/YYYY format.',
                                                boldTexts: ['Enrollment Date'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],

                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addEnrolClientDesktop2,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '6. Add the Enrollment',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Add button at the bottom right of the modal to save the enrollment.',
                                        boldTexts: ['Add'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. View the New Enrollment',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The newly added Primary Enrollment will appear in the left box, and the ' +
                                            'Secondary Enrollment will appear in the right box under the Enrollments tab for the selected client.',
                                        boldTexts: ['Primary Enrollment', 'left box', 'Secondary Enrollment', 'right box', 'Enrollments'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

        },
        'ADDING_NOTES_CLIENT': {
            pageTitle: 'Adding Notes to a Client',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the client and go to the Notes tab.',
                                        boldTexts: ['Notes'],
                                    },

                                    {
                                        title: '3. Click on the  + Add Notes button and fill in the details (Subject, Body).',
                                        boldTexts: ['Add Notes'],
                                    },

                                    {
                                        title: '4. Click Save.',
                                        boldTexts: ['Save'],
                                    },
                                ],
                            },


                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addNoteClientDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the >Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client for whom you want to add a note.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Notes Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Notes tab at the top of the client’s page.',
                                        boldTexts: ['Notes'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Add a New Note',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the + Add Note button at the top right of the page.',
                                        boldTexts: ['Add Note'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Fill in the Note Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Add Note modal that appears, fill in the required fields:',
                                        boldTexts: [''],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Subject: Enter the subject of the note.',
                                                boldTexts: ['Subject'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Body: Enter the content of the note.',
                                                boldTexts: ['Body'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],

                            },

                        ],
                    },

                    {
                        subTitle: '6. Save the Note',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Save button at the bottom right of the modal.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. View the New Note',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The newly added note will appear in the Notes table for the selected client.',
                                        boldTexts: ['Notes'],
                                        circle: true,
                                    },
                                ],
                            },


                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addNoteClientDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

        },

        'VIEW_CLIENT_NOTES': {
            pageTitle: 'Viewing Client Notes',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the client and go to the Notes tab.',
                                        boldTexts: ['Notes'],
                                    },

                                    {
                                        title: '3. View Client Notes.',
                                        boldTexts: ['Notes'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editClientFilesDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client whose notes you want to view.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Notes Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Notes tab at the top of the client’s page',
                                        boldTexts: ['Notes'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. View Client Notes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Notes tab, you will see a list of notes created for the client. Details such as the Created Date, Creator Name, Subject, and Body of each note are displayed.',
                                        boldTexts: ['Notes', 'Created Date', 'Creator Name', 'Subject', 'Body'],
                                        circle: true,
                                    },

                                    {
                                        title: 'If part of the Body text is not fully visible, hover over the text to see the full content in a tooltip.',
                                        boldTexts: ['Body', 'tooltip'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editClientFilesDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'EDITING_CLIENT_NOTES': {
            pageTitle: 'Editing Client Notes',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the Client and go to the Notes tab.',
                                        boldTexts: ['Notes'],
                                    },

                                    {
                                        title: '3. Locate the note you want to edit and Click on the pencil icon next to it.',
                                        boldTexts: ['pencil icon'],
                                    },

                                    {
                                        title: '4.  Edit the Subject or Body fields  and Click Save.',
                                        boldTexts: ['Subject', 'Body'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editClientNoteDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client whose note you want to edit.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Notes Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Notes tab at the top of the client’s page.',
                                        boldTexts: ['Notes'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Edit a Note',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Locate the note you want to edit and click on the pencil icon next to it under the Action column.',
                                        boldTexts: ['pencil icon', 'Action'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Make Changes to the Note',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Edit the Subject or Body fields in the Edit Note modal that appears.',
                                        boldTexts: ['Subject', 'Body', 'Edit Note'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Save Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click Save to update the note.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editClientNoteDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'DELETE_CLIENT_NOTES': {
            pageTitle: 'Deleting Client Notes',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the Client and go to the Notes tab.',
                                        boldTexts: ['Notes'],
                                    },

                                    {
                                        title: '3. Locate the Note you want to delete and Click on the red X  icon next to it.',
                                        boldTexts: ['Note', 'red X  icon'],
                                    },

                                    {
                                        title: '4. Click Delete to confirm the removal of the note.',
                                        boldTexts: ['Delete'],
                                    },
                                ],
                            },
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteClientNoteDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client whose note you want to delete.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Notes Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Notes tab at the top of the client’s page.',
                                        boldTexts: ['Notes'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Delete a Note',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Locate the note you want to delete and click on the trash can icon next to it under the Action column.',
                                        boldTexts: ['Action'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Confirm Deletion',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'A confirmation modal will appear. Click Delete to confirm the removal of the note.',
                                        boldTexts: ['Delete'],
                                        circle: true,
                                    },
                                ],
                            },
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteClientNoteDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'ADDING_FILES_CLIENT': {
            pageTitle: 'Adding Files to a Client',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the Client and go to the Files tab.',
                                        boldTexts: ['Files'],
                                    },

                                    {
                                        title: '3. Click on the  Upload Document  button and fill in the details (Name, Description).',
                                        boldTexts: ['Upload Document  button'],
                                    },

                                    {
                                        title: '4. Click to upload or drag and drop the file into the drag and drop box.',
                                        boldTexts: ['drag and drop'],
                                    },

                                    {
                                        title: '5. Click Add.',
                                        boldTexts: ['Add'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addFileClientDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the >Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client for whom you want to add files.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Files Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Files tab at the top of the client’s page.',
                                        boldTexts: ['Files'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Add a New File',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Upload Document button to add relevant documents or files associated with the client.',
                                        boldTexts: ['Upload'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Upload the File',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Upload Document modal that appears, fill in any necessary details.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click to upload or drag and drop the file into the drag and drop box.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Review Uploaded Files',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Once the file is uploaded, it will appear at the bottom of the modal. You can continue uploading more files as needed.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                ],
                            },
                        ],
                    }, {
                        subTitle: '7. Save the Uploaded Files',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'After adding all the files, click the Add button on the bottom right of the modal.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                ],
                            },
                        ],
                    }, {
                        subTitle: '8. View Uploaded Files',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The uploaded files will be listed in the Files tab for the selected client.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addFileClientDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'VIEW_CLIENT_FILES': {
            pageTitle: 'Viewing Client Files',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the Client and go to the Files tab.',
                                        boldTexts: ['Files'],
                                    },

                                    {
                                        title: '3. View Client Files.',
                                        boldTexts: ['Client'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.viewClientFileDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the >Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client whose status you want to change.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Files Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Files tab at the top of the client’s page.',
                                        boldTexts: ['Files'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. View Client Files',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Files tab, locate the file you want to view and click on the eye icon next to it. This will open a preview or display the file details.',
                                        boldTexts: ['Files'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.viewClientFileDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'EDITING_CLIENT_FILES': {
            pageTitle: 'Editing Client Files',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the Client and go to the Files tab.',
                                        boldTexts: ['Save'],
                                    },

                                    {
                                        title: '3. Locate the file you want to edit and Click on the pencil icon next to it, Edit the file details and  Click Save.',
                                        boldTexts: ['pencil icon', 'Save'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editClientFileDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the >Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client whose file you want to edit.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Files Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Files tab at the top of the client’s page.',
                                        boldTexts: ['Files'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Edit a File',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Locate the file you want to edit and click on the pencil icon next to it.',
                                        boldTexts: ['pencil icon'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Make Changes to the File Information',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Edit the file details in the Edit File modal that appears.',
                                        boldTexts: ['Edit File'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Save Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click Save to update the file information.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editClientFileDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'DOWN_CLIENT_FILES': {
            pageTitle: 'Downloading Client Files',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the Client and go to the Files tab.',
                                        boldTexts: ['Files'],
                                    },

                                    {
                                        title: '3. Locate the file you want to download and Click on the cloud icon with a down arrow next to it.',
                                        boldTexts: ['cloud icon'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.downClientFilesDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the >Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client whose file you want to edit.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Files Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Files tab at the top of the client’s page.',
                                        boldTexts: ['Files'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Download a File',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Locate the file you want to download and click on the cloud icon with a down arrow next to it.',
                                        boldTexts: ['cloud icon with a down arrow'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. File Downloads Automatically',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The file will be downloaded automatically to your device.',
                                        boldTexts: ['Edit File'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.downClientFilesDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'DELETE_CLIENT_FILES': {
            pageTitle: 'Deleting Client Files',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the Client and go to the Files tab.',
                                        boldTexts: ['Files'],
                                    },

                                    {
                                        title: '3. Locate the file you want to delete and Click on the trash can icon next to it.',
                                        boldTexts: ['trash can icon'],
                                    },

                                    {
                                        title: '4. Click Delete to confirm the removal of the file.',
                                        boldTexts: ['Delete'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteClientFilesDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the >Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client whose file you want to edit.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Files Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Files tab at the top of the client’s page.',
                                        boldTexts: ['Files'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Delete a File',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'A confirmation modal will appear. Click Delete to confirm the removal of the file.',
                                        boldTexts: ['Delete'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. File Downloads Automatically',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The file will be downloaded automatically to your device.',
                                        boldTexts: ['Edit File'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteClientFilesDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'ASSIGNING_STAFF_CLIENT': {
            pageTitle: 'Assigning Staff to a Client',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the client and go to the Staff Assignment tab.',
                                        boldTexts: ['Staff Assignment'],
                                    },

                                    {
                                        title: '3. Select Staff Member, Enter Staff Role & Click Assign Staff.',
                                        boldTexts: ['Staff Member', 'Staff Role', 'Assign Staff'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.assignStaffClientDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the >Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client to whom you want to assign staff.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Staff Assignment Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Staff Assignment tab at the top of the client’s page.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Select Staff Member and Role',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Select Staff Member field, choose the staff member you want to assign to the client.',
                                        boldTexts: ['Staff Member'],
                                        circle: true,
                                    },

                                    {
                                        title: 'In the Enter Staff Role field, type in or select the role of the staff member (e.g., Therapist, Supervisor).',
                                        boldTexts: ['Enter Staff Role'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Assign the Staff',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Assign Staff button.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '6. View the Assigned Staff',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The newly assigned staff member will appear in the Assigned Staff table below with their respective role.',
                                        boldTexts: ['Assigned Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.assignStaffClientDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'DELETING_ASSIGNED_STAFF': {
            pageTitle: 'Deleting Assigned Staff from a Client',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the client and go to the Staff Assignment tab.',
                                        boldTexts: ['Staff Assignment'],
                                    },

                                    {
                                        title: '3. View Assigned Staff.',
                                        boldTexts: [''],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deletAssignStaffClientDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the >Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client from whom you want to delete an assigned staff member.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Staff Assignment Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Staff Assignment tab at the top of the client’s page.',
                                        boldTexts: ['Staff Assignment'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Locate the Assigned Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Assigned Staff table, find the staff member you want to delete.',
                                        boldTexts: ['Assigned Staff'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Delete the Assigned Staff',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the red X button next to the assigned staff member under the Action column.',
                                        boldTexts: ['X button', 'Action'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '6. Confirm Unassignment',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'A confirmation modal will appear. Click Unassign to confirm the removal of the staff member from the client’s assignment.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '7. View Updated Staff Assignment',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The staff member will be removed from the Assigned Staff table for the selected client.',
                                        boldTexts: ['Assigned Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deletAssignStaffClientDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'VIEW_ASSIGNED_STAFF_CLIENT': {
            pageTitle: 'Viewing Assigned Staff for a Client',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the client and go to the Staff Assignment tab.',
                                        boldTexts: ['Staff Assignment'],
                                    },

                                    {
                                        title: '3. View Assigned Staff.',
                                        boldTexts: [''],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.viwAssignStaffClientDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client whose assigned staff you want to view.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Staff Assignment Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Staff Assignment tab at the top of the client’s page',
                                        boldTexts: ['Staff Assignment'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. View Assigned Staff',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Assigned Staff table, you will see a list of all staff members currently assigned to the client, along with their respective roles.',
                                        boldTexts: ['Assigned Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.viwAssignStaffClientDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'ADDING_AUTH_CLIENT': {
            pageTitle: 'Adding Authorization to a Client',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the Client and go to the Authorization tab.',
                                        boldTexts: ['Authorization'],
                                    },

                                    {
                                        title: '3. Click on the + Add Authorization Button and fill in the details (Authorization Id, Funding Source, etc).',
                                        boldTexts: ['Authorization'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addAuthClientDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '4. Click Add to save.',
                                        boldTexts: ['Add'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addAuthClientDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                notes: [
                                    {
                                        text: 'Note: Ensure that the client has an enrollment setup in the system.' +
                                            ' Without an enrollment, you will not be able to add an authorization for the client. ' +
                                            'Additionally, without an authorization, you will not be able to add any authorized services.' +
                                            ' Make sure to complete these steps in the correct order to ensure smooth setup and management of client services.',
                                        boldTexts: ['Note'],
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the >Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client for whom you want to add an authorization.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Authorizations Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Authorizations tab at the top of the client’s page.',
                                        boldTexts: ['Authorizations'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Add a New Authorization',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the + Add Authorization button at the top right of the page.',
                                        boldTexts: ['Add Authorization'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addAuthClientDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Fill in the Authorization Information',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Add Authorization modal that appears, fill in the required fields:',
                                        boldTexts: ['Add Authorization'],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Authorization #: Enter the authorization number.',
                                                boldTexts: ['Authorization'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Funding Source: This field will be pre-filled with the funding source associated with the client.',
                                                boldTexts: ['Funding Source'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Start Date: Enter the start date of the authorization in MM/DD/YYYY format.',
                                                boldTexts: ['Start Date', 'MM/DD/YYYY'],
                                                circle: true,
                                            },

                                            {
                                                title: 'End Date: Enter the end date of the authorization in MM/DD/YYYY format.',
                                                boldTexts: ['End Date', 'MM/DD/YYYY'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Service Location: Enter the service location where the authorization applies.',
                                                boldTexts: ['Service Location'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addAuthClientDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Optional - Mark as Past Authorization',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'If this is a past authorization, select the Past Authorization checkbox.',
                                        boldTexts: ['Past Authorization'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '7. Add the Authorization',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Add button at the bottom right of the modal to save the authorization.',
                                        boldTexts: ['Add'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '8. View the New Authorization',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The newly added authorization will appear under the Authorization tab with details such as Diagnostic Codes and Authorized Services.',
                                        boldTexts: ['Authorization', 'Diagnostic Codes', 'Authorized Services'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                notes: [
                                    {
                                        text: 'Note: Ensure that the client has an enrollment setup in the system. Without an enrollment, you will not be ' +
                                            'able to add an authorization for the client. Additionally, without an authorization, you will not be able to add' +
                                            ' any authorized services. Make sure to complete these steps in the correct order to ensure smooth setup and management ' +
                                            'of client services.',
                                        boldTexts: ['Note'],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'ADD_CODES_CLIENT_AUTH': {
            pageTitle: 'Adding Diagnostic Codes to a Client Authorization',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the Client and go to the Authorization tab.',
                                        boldTexts: ['Authorization'],
                                    },

                                    {
                                        title: '3. Click on the + Add Code button located under the Diagnostic Codes section.',
                                        boldTexts: ['Add Code', 'Diagnostic Codes'],
                                    },

                                    {
                                        title: '4. Enter the name and Click Add to save.',
                                        boldTexts: ['Add'],
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the >Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client for whom you want to add a diagnostic code.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Authorizations Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Authorizations tab at the top of the client’s page.',
                                        boldTexts: ['Authorizations'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Select the Authorization',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Find the authorization to which you want to add a diagnostic code.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Add a New Diagnostic Code',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the + Add Code button located under the Diagnostic Codes section.',
                                        boldTexts: ['Add Code', 'Diagnostic Codes'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Fill in the Diagnostic Code Information',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Add Diagnostic Code modal that appears, fill in the required field:',
                                        boldTexts: ['Add Diagnostic Code'],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Name: Enter the name or code of the diagnostic code.',
                                                boldTexts: ['Name'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. Add the Diagnostic Code',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Add button at the bottom right of the modal to save the diagnostic code.',
                                        boldTexts: ['Add'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '8. View the New Diagnostic Code',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The newly added diagnostic code will appear under the Diagnostic Codes section for the selected authorization.',
                                        boldTexts: ['Diagnostic Codes'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'ADD_AUTH_SERVICE_CLIENT_AUTH': {
            pageTitle: 'Adding an Authorized Service to a Client\'s Authorization',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the Client and go to the Authorization tab.',
                                        boldTexts: ['Authorization'],
                                    },

                                    {
                                        title: '3. Click on the + Add Authorized Service button located under the Authorized Services section.',
                                        boldTexts: ['Add', 'Authorized Service'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addAuthServClientDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '4. Select a Service code from the dropdown.',
                                        boldTexts: ['Service code'],
                                    },

                                    {
                                        title: '5.  Select the Charge rates you would like to add.',
                                        boldTexts: ['Charge rates'],
                                    },

                                    {
                                        title: '6. Enter the Number of Units authorized for this service (e.g., "1000" units) and Click Add to save.',
                                        boldTexts: ['Add', 'Number of Units'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addAuthServClientDesktop2,
                                    },
                                ],
                            },
                        ],
                    },


                    {
                        list: [
                            {
                                notes: [
                                    {
                                        text: 'Note: Ensure that the client has an enrollment setup in the system. Without an enrollment, you will not' +
                                            ' be able to add an authorization for the client. Additionally, without an authorization, you will not be' +
                                            ' able to add any authorized services. Make sure to complete these steps in the correct order to ensure smooth' +
                                            ' setup and management of client services.',
                                        boldTexts: ['Note'],
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the >Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client for whom you want to add an authorized service.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Authorizations Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Authorizations tab at the top of the client’s page.',
                                        boldTexts: ['Authorizations'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Select the Authorization',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Find the specific authorization to which you want to add an authorized service.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Add a New Authorized Service',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the + Add Authorized Service button located under the Authorized Services section.',
                                        boldTexts: ['Add Authorized', 'Authorized Services'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addAuthServClientDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Select the Service Code',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Add Diagnostic Code modal that appears, fill in the required field:',
                                        boldTexts: ['Add Authorized Service'],
                                        circle: true,

                                    },

                                    {
                                        title: 'Select a service code from the dropdown list (e.g., "Parent Training - 12345").',
                                        boldTexts: [''],
                                        circle: true,

                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. Select Charge Rates',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'After selecting the service code, available Charge Rates will appear.' +
                                            ' Select the charge rates you would like to add. These could include options' +
                                            ' like BCBA, BI, etc. You can choose one or multiple charge rates as needed.',
                                        boldTexts: ['Charge Rates', 'BCBA', 'BI'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '8. Enter Total Available Units',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Total Available Units field, enter the number of units authorized for this ' +
                                            'service (e.g., "1000" units). This field accepts a maximum of 10,000 units.',
                                        boldTexts: ['Total Available Units'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addAuthServClientDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '9. Add the Authorized Service',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Add button at the bottom right of the modal to save the authorized service.',
                                        boldTexts: ['Add'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '10. View the New Authorized Service',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The newly added authorized service will appear in the Authorized Services ' +
                                            'table for the selected authorization, along with details such as Service Code, Charge Rates, Total' +
                                            ' Units, Completed, Reserved, Available, Billed, and % Utilization.',
                                        boldTexts: ['Service Code', 'Charge Rates', 'Total Units', 'Completed', 'Reserved', 'Available', 'Billed', 'Utilization'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                notes: [
                                    {
                                        text: 'Note: Ensure that the client has an enrollment setup in the system. Without an enrollment, you will not' +
                                            ' be able to add an authorization for the client. Additionally, without an authorization, you will not be' +
                                            ' able to add any authorized services. Make sure to complete these steps in the correct order to ensure smooth' +
                                            ' setup and management of client services.',
                                        boldTexts: ['Note'],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'EDIT_CLIENT_AUTH': {
            pageTitle: 'Editing a Client Authorization',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the Client and go to the Authorization tab.',
                                        boldTexts: ['Authorization'],
                                    },

                                    {
                                        title: '3. Click on the pencil icon next to the authorization details.',
                                        boldTexts: ['pencil icon'],
                                    },


                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editClientAuthDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '4. Make changes and click Save.',
                                        boldTexts: ['Save'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editClientAuthDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the >Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client whose authorization you want to edit.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Authorizations Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Authorizations tab at the top of the client’s page.',
                                        boldTexts: ['Authorizations'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Select the Authorization to Edit',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Find the specific authorization you want to edit from the list.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Edit the Authorization',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the pencil icon next to the authorization details to open the Edit Authorization modal.',
                                        boldTexts: ['pencil icon', 'Edit Authorization'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editClientAuthDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Update Authorization Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Make the necessary changes to the authorization fields, such as Authorization #, Start Date, End Date, or Service Location.',
                                        boldTexts: ['Authorization', 'Start Date', 'End Date', 'Service Location'],
                                        circle: true,

                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editClientAuthDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. Save Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Save button at the bottom right of the modal to apply the changes.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '8. Review Updated Authorization',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Ensure the updated authorization details are correctly reflected in the Authorizations tab.',
                                        boldTexts: ['Authorizations'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'EDIT_CLIENT_AUTH_SERVICE': {
            pageTitle: 'Editing an Authorized Service',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the Client and go to the Authorization tab.',
                                        boldTexts: ['Authorization'],
                                    },

                                    {
                                        title: '3. Click on the pencil icon next to the authorized service.',
                                        boldTexts: ['pencil icon'],
                                    },

                                    {
                                        title: '4. Make changes and click Save.',
                                        boldTexts: ['Save'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editAuthServiceDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client whose authorization you want to edit.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Authorizations Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Authorizations tab at the top of the client’s page.',
                                        boldTexts: ['Authorizations'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Select the Authorization to Edit',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Find the specific authorization that contains the authorized service you want to edit.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Locate the Authorized Service to Edit',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Under the Authorized Services section, find the service you want to edit.',
                                        boldTexts: ['Authorized'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editAuthServiceDesktop,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Edit the Authorized Service',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the pencil icon next to the authorized service in the Action column to open the Edit Authorized Service modal.',
                                        boldTexts: ['Action', 'Edit Authorized Service'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. Update Service Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Make the necessary changes to the fields, such as Service Code, Charge Rates, or Total Available Units.',
                                        boldTexts: ['Service Code', 'Charge Rates', 'Total Available Units'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '8. Save Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Save button at the bottom right of the modal to apply the changes.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '9. Review Updated Authorized Service',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Ensure the updated authorized service details are correctly reflected in the Authorized Services table.',
                                        boldTexts: ['Authorized Services'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'VIEW_PAST_AUTH': {
            pageTitle: 'Viewing Past Authorizations',
            content: {
                short: [

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the Client and go to the Authorization tab.',
                                        boldTexts: ['Authorization'],
                                    },

                                    {
                                        title: '3. Click on the history icon (often represented by a clock or a similar symbol) next to the authorization details.',
                                        boldTexts: ['history icon'],
                                    },

                                ],
                            },


                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.viewPastAuthDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '4. View Past Authorizations.',
                                        boldTexts: ['Past Authorizations'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.viewPastAuthDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client for whom you want to view past authorizations',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Authorizations Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Authorizations tab at the top of the client’s page',
                                        boldTexts: ['Authorizations'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Open Past Authorizations',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the history icon (often represented by a clock or a similar symbol) next to the authorization details.',
                                        boldTexts: ['history icon'],
                                        circle: true,
                                    },
                                ],
                            },


                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.viewPastAuthDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. View Past Authorizations',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The Past Authorizations modal will appear, showing a list of previous ' +
                                            'authorizations with details such as Number, Date Range, Status, and Appt Visible toggle.',
                                        boldTexts: ['Past Authorizations', 'Number', 'Date Range', 'Status', 'Appt Visible'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.viewPastAuthDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'MANAGE_APPT_VISIBLE': {
            pageTitle: 'Managing the "Appt Visible" Toggle for Past Authorizations',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the Client and go to the Authorization tab.',
                                        boldTexts: ['Authorization'],
                                    },

                                    {
                                        title: '3. Click on the history icon (often represented by a clock or a similar symbol) next to the authorization details.',
                                        boldTexts: ['history icon'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.manageApptAuthDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '4. Enable "Appt Visible" for Past Authorizations.',
                                        boldTexts: ['Appt Visible'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.manageApptAuthDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client for whom you want to manage the visibility of past authorizations.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Authorizations Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Authorizations tab at the top of the client’s page.',
                                        boldTexts: ['Authorizations'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Open Past Authorizations',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the clock icon with an arrow around it next to the authorization details on the top right of the page to open the Past Authorizations modal.',
                                        boldTexts: ['clock icon with an arrow around'],
                                        circle: true,
                                    },
                                ],
                            },


                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.manageApptAuthDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Enable "Appt Visible" for Past Authorizations',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Locate the past authorization for which you want to make appointments visible.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'Toggle the Appt Visible switch to the ON position. This will allow even inactive past authorizations to be used for creating appointments.',
                                        boldTexts: ['Appt Visible', 'ON'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.manageApptAuthDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Confirm Visibility Status',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Ensure the Appt Visible toggle is turned on, confirming that the past authorization can be used for scheduling appointments.',
                                        boldTexts: ['Appt Visible'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },
                ],
            },
        },
        'EDIT_CLIENT_CONTACT': {
            pageTitle: 'Editing Client Contacts',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the Client and go to the Contacts tab.',
                                        boldTexts: ['Contacts'],
                                    },

                                    {
                                        title: '3. Locate the Contact you want to delete and click on the red X icon next to it.',
                                        boldTexts: ['Contact', 'red X icon'],
                                    },

                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editClientContDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '4. Click Delete for Confirmation.',
                                        boldTexts: ['Delete'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.manageApptAuthDesktop1,
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client whose contact information you want to edit.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Contacts Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Contacts tab at the top of the client’s page.',
                                        boldTexts: ['Contacts'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Locate the Contact to Edit',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Find the contact you want to edit in the Contacts table.',
                                        boldTexts: ['Contacts'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Open the Edit Contact Modal',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the pencil icon under the Action column next to the contact\'s details.',
                                        boldTexts: ['pencil icon', 'Action'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editClientContDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Edit the Contact Information',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The Edit Contact modal will appear.',
                                        boldTexts: ['Edit Contact'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Update the necessary fields such as:',
                                        boldTexts: [''],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'First Name',
                                                boldTexts: ['First Name'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Last Name',
                                                boldTexts: ['Last Name'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Phone Number',
                                                boldTexts: ['Phone Number'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Email Address',
                                                boldTexts: ['Email Address'],
                                                circle: true,
                                            },


                                            {
                                                title: 'Relationship',
                                                boldTexts: ['Relationship'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Street Address',
                                                boldTexts: ['Street Address'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Unit/Suite #',
                                                boldTexts: ['Unit', 'Suite'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.manageApptAuthDesktop1,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '7. Save Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Save button at the bottom right of the modal to apply changes.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '8. Review Updated Contact Information',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The updated contact information will now appear in the Contacts table.',
                                        boldTexts: ['Contacts'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },
                ],
            },
        },
        'DELETE_CLIENT_CONTACT': {
            pageTitle: 'Deleting Client Contacts',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the client and go to the Contacts tab.',
                                        boldTexts: ['Contacts'],
                                    },

                                    {
                                        title: '3. Locate the Contact you want to delete and Click on the red X  icon next to it.',
                                        boldTexts: ['red X  icon', 'Contact'],
                                    },

                                    {
                                        title: '4. Click Delete to confirm the removal of the contact.',
                                        boldTexts: ['Delete'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deletClientContDesktop,
                                    },
                                ],
                            },
                        ],
                    },

                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client whose contact you want to delete.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Contacts Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Contacts tab at the top of the client’s page.',
                                        boldTexts: ['Contacts'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Locate the Contact to Delete',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Find the contact you want to delete in the Contacts table.',
                                        boldTexts: ['Contacts'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Delete the Contact',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the red X icon under the Action column next to the contact\'s details.',
                                        boldTexts: ['red X icon', 'Action'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deletClientContDesktop,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Confirm Deletion',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'A confirmation modal will appear. Click Delete to confirm the deletion of the contact.',
                                        boldTexts: ['Delete'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '7. Review Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The contact will be removed from the Contacts table.',
                                        boldTexts: ['Contacts'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                ],
            },
        },
        'EDIT_INFO_CLIENT': {
            pageTitle: 'Editing General Info for a Client',
            content: {
                short: [

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the Client and go to the General tab.',
                                        boldTexts: ['Client', 'General'],
                                    },

                                    {
                                        title: '3. In the General Info  box on the right side, click on the blue pencil icon.',
                                        boldTexts: ['blue pencil icon'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editGenInfoClientDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '4. Edit the General Information and click Save.',
                                        boldTexts: ['Save'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editGenInfoClientDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client whose general information you want to edit.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the General Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Ensure you are on the General tab on the client\'s page.',
                                        boldTexts: ['General'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Open the Edit General Info Modal',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the General Info box on the left side, click on the pencil icon to open the Edit General Info modal.',
                                        boldTexts: ['General Info', 'pencil icon', 'Edit General'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editGenInfoClientDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Edit the General Information',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Update the required fields such as:',
                                        boldTexts: [''],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Rendering Provider',
                                                boldTexts: ['Rendering Provider'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Status',
                                                boldTexts: ['Status'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Other relevant fields as needed.',
                                                boldTexts: ['Other relevant fields as needed.'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editGenInfoClientDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Save Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Save button at the bottom right of the modal to apply the changes.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '7. Review Updated General Info',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The updated information will be reflected in the General Info box.',
                                        boldTexts: ['General Info'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                ],
            },
        },
        'EDIT_OTHER_INFO_CLIENT': {
            pageTitle: 'Editing Other Details for a Client',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the Client and go to the General tab.',
                                        boldTexts: ['General'],
                                    },
                                    {
                                        title: '3. In the Other Details box on the right side, click on the blue pencil icon.',
                                        boldTexts: ['Other Details', 'blue pencil icon'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editOtherInfoClientDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '4. Edit the Other Details and click Save.',
                                        boldTexts: ['Save'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editOtherInfoClientDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client whose other information you want to edit.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the General Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Ensure you are on the General tab on the client’s page.',
                                        boldTexts: ['General'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Open the Edit Other Details Modal',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Other Details section, click on the Edit button (often represented by an icon or text).',
                                        boldTexts: ['Other Details', 'Edit'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editOtherInfoClientDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Edit the Other Information',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Update the relevant fields, such as:',
                                        boldTexts: [''],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Drivers License details',
                                                boldTexts: ['Drivers License details'],
                                                circle: true,
                                            },

                                            {
                                                title: 'NPI Number',
                                                boldTexts: ['NPI Number'],
                                                circle: true,
                                            },

                                            {
                                                title: 'SSN Number',
                                                boldTexts: ['SSN Number'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Gender',
                                                boldTexts: ['Gender'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Date of Birth',
                                                boldTexts: ['Date of Birth'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Languages Spoken',
                                                boldTexts: ['Languages Spoken'],
                                                circle: true,
                                            },

                                            {
                                                title: 'DOJ and TB Details (include description as needed for clarification).',
                                                boldTexts: ['DOJ and TB Details'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editOtherInfoClientDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Save Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Save button at the bottom right of the modal to apply the changes.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '7. Review Updated Other Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The updated information will be reflected in the Other Details section of the client’s profile.',
                                        boldTexts: ['Other Details'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                ],
            },
        },
        'DELETE_ADDRESS_CLIENT': {
            pageTitle: 'Deleting an Address for a Client',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the Client and go to the General tab.',
                                        boldTexts: ['Client', 'General'],
                                    },

                                    {
                                        title: '3. In the Address box on the right side, click on the red X icon and confirm by clicking Delete.',
                                        boldTexts: ['Address', 'red X icon', 'Delete'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteAddressClientDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client whose other information you want to edit.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the General Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Ensure you are on the General tab on the client\'s page.',
                                        boldTexts: ['General'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Locate the Address to Delete',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Address section, find the address you wish to remove.',
                                        boldTexts: ['Address'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Delete the Address',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the red X icon next to the address you want to delete.',
                                        boldTexts: ['red X icon'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Confirm Deletion',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'A confirmation modal will appear. Click Delete to confirm and remove the address from the client\'s profile.',
                                        boldTexts: ['Delete'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteAddressClientDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'EDIT_ADDRESS_CLIENT': {
            pageTitle: 'Editing an Address for a Client',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the Client and go to the General tab.',
                                        boldTexts: ['Client', 'General'],
                                    },

                                    {
                                        title: '3. In the Address box on the right side, click on the blue pencil icon  and edit address.',
                                        boldTexts: ['Address', 'blue pencil icon'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editAddressClientDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '4. Click Save for Confirmation.',
                                        boldTexts: ['Save'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editAddressClientDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the >Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client whose address you want to edit.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the General Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Ensure you are on the General tab on the client\'s page.',
                                        boldTexts: ['General tab'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Locate the Address to Edit',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Address section, find the address you wish to edit.',
                                        boldTexts: ['Address'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Open the Edit Address Modal',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the pencil icon next to the address you want to edit.',
                                        boldTexts: ['pencil icon'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editAddressClientDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Edit the Address Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Update the necessary fields in the Edit Address modal:',
                                        boldTexts: ['Edit Address'],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Name',
                                                boldTexts: ['Name'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Street Address',
                                                boldTexts: ['Street Address'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Unit/Suite #',
                                                boldTexts: ['Unit/Suite #', 'Unit', 'Suite'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editAddressClientDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. Save the Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Save button at the bottom of the modal to apply the changes.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '8. Review the Updated Address',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The updated address will be reflected in the  Address section of the client\'s profile.',
                                        boldTexts: ['Address'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'SETTING_ADDRESS_DEFAULT': {
            pageTitle: 'Setting an Address as the Default Address',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the Client and go to the General tab.',
                                        boldTexts: ['Client', 'General'],
                                    },

                                    {
                                        title: '3. In the Address box on the right side, click on the blue pencil icon.',
                                        boldTexts: ['Address', 'blue pencil icon'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.settingAddressAsDefDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '4. In the Edit Address modal, click on the Set as Default checkbox.',
                                        boldTexts: ['Edit Address', 'Set as Default'],
                                    },

                                    {
                                        title: '5. Click Save for Confirmation.',
                                        boldTexts: ['Save'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.settingAddressAsDefDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client for whom you want to set a default address.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the General Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Ensure you are on the General tab on the client\'s page.',
                                        boldTexts: ['General tab'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Locate the Address to Set as Default',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Other Addresses section, find the address you wish to set as the default.',
                                        boldTexts: ['Other Address'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.settingAddressAsDefDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Open the Edit Address Modal',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the pencil icon next to the address you want to set as default.',
                                        boldTexts: ['pencil icon'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Set the Address as Default',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Edit Address modal, click on the Set as Default checkbox.',
                                        boldTexts: ['Edit Address', 'Set as Default'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.settingAddressAsDefDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. Save the Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Save button at the bottom of the modal to apply the changes.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '8. Review the Default Address',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The selected address will now appear as the default in the Address section. The previously set default address will move to the "Other addresses" section.',
                                        boldTexts: ['Address'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'DOWNLOAD_CLIENT_CSV': {
            pageTitle: 'Downloading Client List CSV',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Address'],
                                    },

                                    {
                                        title: '2. On the top right of the Client section page, click on the Download CSV button.',
                                        boldTexts: ['Client', 'Download CSV'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.downClientCsvDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Locate the Download CSV Button',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'On the top right of the Client section page, find the Download CSV button.',
                                        boldTexts: ['Client', 'Download CSV'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Download the Client List',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Download CSV button. The system will automatically generate and download a CSV file containing the client list.',
                                        boldTexts: ['Download CSV'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Review the Downloaded File',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Open the downloaded CSV file to view the list of clients and their details.',
                                        boldTexts: ['Address'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.downClientCsvDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'CLIENT_PROFILE_CHANGE': {
            pageTitle: 'Viewing Client Profile’s Change History',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the Client and go to the History tab.',
                                        boldTexts: ['History'],
                                    },

                                    {
                                        title: '3. View Change History.',
                                        boldTexts: ['History'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.viewClientPprofChangeDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the >Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client whose profile change history you want to view.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the History Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the History tab at the top of the client’s page.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. View Change History',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Review the list of changes made to the client’s profile, including edits to information, added or removed notes, and other modifications.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.viewClientPprofChangeDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'MAKING_CLIENT_AC_IN': {
            pageTitle: 'Making a Client Active/Inactive',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the Client and go to the General tab.',
                                        boldTexts: ['Client', 'General'],
                                    },

                                    {
                                        title: '3. Locate the Client Status Toggle.',
                                        boldTexts: ['Client Status'],
                                    },

                                    {
                                        title: '4. Click the Active or Inactive button.',
                                        boldTexts: ['Active', 'Inactive'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.clientActiveInActiveDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the >Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client whose status you want to change.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Locate the Client Status Toggle',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'At the top of the client’s page, there is a status toggle button labeled Active or Inactive, depending on the current status of the client.',
                                        boldTexts: ['Active', 'Inactive'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Toggle Client Status',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Active or Inactive button to change the status of the client.',
                                        boldTexts: ['Active', 'Inactive'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '5. View Updated Status',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The client\'s status will be updated, and you will see either Active or Inactive reflected in the system.',
                                        boldTexts: ['Active', 'Inactive'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.clientActiveInActiveDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'EDIT_PRIM_SECOND_ENROLL': {
            pageTitle: 'Editing Primary or Secondary Enrollment',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the Client and go to the Enrollments tab.',
                                        boldTexts: ['Enrollments'],
                                    },

                                    {
                                        title: '3. Click on the three-dot menu and select Edit from the dropdown menu.',
                                        boldTexts: ['three-dot menu', 'Edit'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editPrimSecondEnrollDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '4. Make changes and click Save.',
                                        boldTexts: ['Save'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editPrimSecondEnrollDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the >Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client whose enrollment you want to edit.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Enrollments Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Enrollments tab at the top of the client’s page.',
                                        boldTexts: ['Active', 'Inactive'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Identify the Enrollment to Edit',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Active or Inactive button to change the status of the client.',
                                        boldTexts: ['Active', 'Inactive'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '5. Edit the Enrollment',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the three-dot menu (often represented by three vertical dots or a similar icon) next to the enrollment details.',
                                        boldTexts: ['three-dot menu'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Select Edit from the dropdown menu that appears.',
                                        boldTexts: ['three-dot menu'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editPrimSecondEnrollDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Update Enrollment Information',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'An Edit Enrollment modal will appear. Update the necessary fields, such as:',
                                        boldTexts: [''],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Funding Source',
                                                boldTexts: ['Funding Source'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Enrollment ID',
                                                boldTexts: ['Enrollment ID'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Enrollment Date',
                                                boldTexts: ['Enrollment Date'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '7. Save Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Save button at the bottom of the modal to apply the changes.',
                                        boldTexts: ['three-dot menu'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editPrimSecondEnrollDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '8. Review Updated Enrollment',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Ensure that the edited enrollment details are correctly reflected under the Active Enrollments section.',
                                        boldTexts: ['Active', 'Enrollments'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },
                ],
            },
        },
        'TERMINATE_PRIM_SECOND_ENROL': {
            pageTitle: 'Terminating a Primary or Secondary Enrollment',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the Client and go to the Enrollments tab.',
                                        boldTexts: ['Enrollments'],
                                    },

                                    {
                                        title: '3. Click on the three-dot menu and select Terminate from the dropdown menu.',
                                        boldTexts: ['three-dot menu', 'Terminate'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.termPrimSecondEnrolDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [

                                    {
                                        title: '4. Enter Termination Date and click Save.',
                                        boldTexts: ['Termination Date', 'Save'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.termPrimSecondEnrolDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client whose enrollment you want to terminate.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Enrollments Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Enrollments tab at the top of the client’s page.',
                                        boldTexts: ['Enrollments'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Locate the Enrollment to Terminate',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Under Active Enrollments, find the Primary or Secondary enrollment you want to terminate.',
                                        boldTexts: ['Active Enrollments', 'Primary', 'Secondary'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '5. Open Terminate Option',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the three-dot menu next to the enrollment details.',
                                        boldTexts: ['three-dot menu'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Select Terminate from the dropdown menu.',
                                        boldTexts: ['three-dot menu'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.termPrimSecondEnrolDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Confirm Termination',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'A confirmation modal may appear. Confirm the action to terminate the enrollment.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.termPrimSecondEnrolDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. Review Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The terminated enrollment will now move to the Past Enrollments section.',
                                        boldTexts: ['Past Enrollments'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'SETTING_SECOND_ENROLL_PRIMARY': {
            pageTitle: 'Setting a Secondary Enrollment as Primary',
            content: {
                short: [

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the Client and go to the Enrollments tab.',
                                        boldTexts: ['Enrollments'],
                                    },

                                    {
                                        title: '3. Locate the Secondary Enrollment, click on the three-dot menu and select Set to Primary from the dropdown menu.',
                                        boldTexts: ['three-dot menu', 'Set to Primary'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.settingSecondEnrollPrimDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '4. Click Save for confirmation.',
                                        boldTexts: ['Client'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.settingSecondEnrollPrimDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client for whom you want to set a new primary enrollment.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Enrollments Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Enrollments tab at the top of the client’s page.',
                                        boldTexts: ['Enrollments'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Locate the Secondary Enrollment',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'From the Right Active Enrollments, find the Secondary enrollment you want to set as primary',
                                        boldTexts: ['Active Enrollments', 'Secondary'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '5. Open Set to Primary Option',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the three-dot menu next to the secondary enrollment details.',
                                        boldTexts: ['three-dot menu'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Select Set to Primary from the dropdown menu.',
                                        boldTexts: ['Set to Primary'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.settingSecondEnrollPrimDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Confirm the Change',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The system will automatically update the selected secondary enrollment to primary.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'The current primary enrollment will be moved to the Past Enrollments section.',
                                        boldTexts: ['Past Enrollments'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '7. Review Updated Enrollments',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Ensure that the newly set primary enrollment is displayed under Active Enrollments with the label Primary.',
                                        boldTexts: ['Active Enrollments', 'Primary'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.settingSecondEnrollPrimDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'ADD_ADDRESS_CLIENT': {
            pageTitle: 'Adding an Address for a Client',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Client in the left pane.',
                                        boldTexts: ['Client'],
                                    },

                                    {
                                        title: '2. Select the Client and go to the General tab.',
                                        boldTexts: ['General'],
                                    },

                                    {
                                        title: '3. In the Address box on the right side, click on the blue + button and fill in the details (Name, Street Address,Unit/Suite #).',
                                        boldTexts: ['blue', 'button'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addAddresClientDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '4. Click Add to save.',
                                        boldTexts: ['Add'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addAddresClientDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Clients Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Client section in the left pane.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Client',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the client for whom you want to add an address.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the General Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Ensure you are on the General tab on the client\'s page.',
                                        boldTexts: ['General'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Open the Address Modal',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Address box on the right side, click on the blue + button.',
                                        boldTexts: ['Address'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addAddresClientDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Fill in the Address Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The Add Address modal will appear. Fill in the following fields',
                                        boldTexts: ['Add Address'],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Name: Enter a name for the address.',
                                                boldTexts: ['Name'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Street Address: Enter the street address.',
                                                boldTexts: ['Street Address'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Unit/Suite #: Enter the unit or suite number if applicable.',
                                                boldTexts: ['Unit', 'Suite'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Set as Default: Check this box if you want this address to be the default.',
                                                boldTexts: ['Set as Default'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addAddresClientDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Save the Address',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Add button at the bottom of the modal to save the new address.',
                                        boldTexts: ['Add'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '7. Review the Added Address',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The newly added address will appear in the Address section on the client’s profile.',
                                        boldTexts: ['Address'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addAddresClientDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
    },
    'STAFF': {
        'DOWN_CSV_STAFF': {
            pageTitle: 'Downloading a CSV of the Staff',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Click Download CSV at the top right.',
                                        boldTexts: ['Download CSV'],
                                    },

                                    {
                                        title: '3. The file will automatically download.',
                                        boldTexts: ['Download CSV'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.downCsvStaffDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Locate the Download CSV Button',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Once in the Staff section, navigate to the top right of the page.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Download the CSV',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Download CSV button.',
                                        boldTexts: ['Download CSV'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. CSV File Download',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The CSV file containing the staff details will automatically download to your device\'s default download location.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.downCsvStaffDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'ADD_STAFF_MEMBER': {
            pageTitle: 'Adding Staff Members',
            content: {
                short: [

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Click + Add Staff Member.',
                                        boldTexts: ['Add Staff Member'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffDesktop1,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '3. Fill in the required fields (First Name, Last Name, Primary Email, etc.).',
                                        boldTexts: [''],
                                    },

                                    {
                                        title: '4. Click Add to save.',
                                        boldTexts: ['Add'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffDesktop2,
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Add a New Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the blue + Add Staff Member button at the top right of the page.',
                                        boldTexts: ['Add Staff Member'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffDesktop1,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Fill in the General Information',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The Add General Info modal will appear. Fill in the required fields.',
                                        boldTexts: ['Add General Info'],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'First Name',
                                                boldTexts: ['First Name'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Middle Name',
                                                boldTexts: ['Middle Name'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Last Name',
                                                boldTexts: ['Last Name'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Primary Email',
                                                boldTexts: ['Primary Email'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Primary Phone Number',
                                                boldTexts: ['Primary Phone Number'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Secondary Phone Number',
                                                boldTexts: ['Secondary Phone Number'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Street Address',
                                                boldTexts: ['Street Address'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Unit/Suite #',
                                                boldTexts: ['Unit', 'Suite', '#'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffDesktop2,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Save the Staff Member:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Add button at the bottom right of the modal.',
                                        boldTexts: ['Add'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        subTitle: '5. View the New Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The newly added staff member will appear in the staff table.',
                                        boldTexts: ['Add'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'ADD_STAFF_PAYCODES': {
            pageTitle: 'Adding Staff Paycodes',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Select the staff member and go to the Paycodes tab.',
                                        boldTexts: ['Paycodes'],
                                    },

                                    {
                                        title: '3. Click + Add Paycode, fill in the details (Paycode Name, Rate, Start Date).',
                                        boldTexts: ['Add Paycode'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffPayDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [

                                    {
                                        title: '4. Click Add to save.',
                                        boldTexts: ['Add'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffPayDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffPayDesktop3,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the staff member to whom you would like to add paycodes.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Paycodes Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Paycodes tab at the top of the page.',
                                        boldTexts: ['Paycodes'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Add a New Paycode',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the + Add Paycode button at the top right of the page.',
                                        boldTexts: ['Add Paycode'],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'The Add New Paycode modal will appear.',
                                                boldTexts: ['Add New Paycode'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffPayDesktop1,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        subTitle: '5. Fill in the Paycode Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Complete the following fields',
                                        boldTexts: ['Add'],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Paycode Name: Choose a paycode name set by your organization.',
                                                boldTexts: ['Paycode Name'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Rate: Enter the rate the staff member will be paid for this paycode.',
                                                boldTexts: ['Rate'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Start Date: Enter the start date for this paycode',
                                                boldTexts: ['Start Date'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Save the Paycode',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click Add at the bottom right of the modal.',
                                        boldTexts: ['Add'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffPayDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. View the New Paycode',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The newly added paycode will appear in the paycodes table for the selected staff member.',
                                        boldTexts: ['Add'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffPayDesktop3,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'ADD_STAFF_TIMESHEET_PAYCODES': {
            pageTitle: 'Adding Staff Timesheets (from Paycode)',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Select the staff member and go to the Timesheet tab.',
                                        boldTexts: ['Timesheet'],
                                    },

                                    {
                                        title: '3. Click + Add Timesheet, select From Paycodes.',
                                        boldTexts: ['Add Timesheet'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffTimePayDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '4. Fill in the timesheet details (Paycode, Hours, Timesheet Date).',
                                        boldTexts: [''],
                                    },

                                    {
                                        title: '5. Click Add to save.',
                                        boldTexts: ['Add'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffTimePayDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],

                long: [
                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the staff member for whom you would like to add timesheets.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Timesheet Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Timesheet tab at the top of the page.',
                                        boldTexts: ['Timesheet'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Add a New Timesheet',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the + Add Timesheet button at the top right of the page.',
                                        boldTexts: ['Add Timesheet'],
                                        circle: true,
                                    },

                                    {
                                        title: 'The Add Timesheet modal will appear.',
                                        boldTexts: ['Add Timesheet'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffTimePayDesktop1,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        subTitle: '5. Choose Timesheet from Paycodes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Select From Paycodes to add a timesheet based on a paycode already created in the system and used by this staff member.',
                                        boldTexts: ['From Paycodes'],
                                        circle: true,

                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Fill in the Timesheet Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The Add Paycode Timesheet modal will appear. Fill in the following fields',
                                        boldTexts: ['Add Paycode Timesheet'],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Paycode: Choose the paycode under which the staff member worked (these paycodes are pre-created by your organization’s administrators in the system settings and added to the staff member\'s profile).',
                                                boldTexts: ['Paycode'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Description: Enter a description for the timesheet entry.',
                                                boldTexts: ['Description'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Hours: Enter the number of hours worked.',
                                                boldTexts: ['Hours'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Minutes: Enter the number of minutes worked.',
                                                boldTexts: ['Minutes'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Timesheet Date: Enter the date the staff member worked.',
                                                boldTexts: ['Timesheet Date'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffTimePayDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. Save the Timesheet',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click Add at the bottom right of the modal.',
                                        boldTexts: ['Add'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '8. View the New Timesheet',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The newly added timesheet will appear in the timesheet table for the selected staff member.',
                                        boldTexts: ['Add'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'ADD_STAFF_TIMESHEET_FIXES_PAY': {
            pageTitle: 'Adding Staff Timesheets (Fixed Pay)',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Select the staff member and go to the Timesheet tab.',
                                        boldTexts: ['Timesheet'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffTimeFixedPayDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '3. Click + Add Timesheet, fill in the amount and timesheet date.',
                                        boldTexts: ['Add Timesheet'],
                                    },

                                    {
                                        title: '4. Click Add to save.',
                                        boldTexts: ['Add'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffTimeFixedPayDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the >Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the staff member for whom you would like to add timesheets.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Timesheet Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Timesheet tab at the top of the page.',
                                        boldTexts: ['Timesheet'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Add a New Timesheet',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the + Add Timesheet button at the top right of the page.',
                                        boldTexts: ['Add Timesheet'],
                                        circle: true,
                                    },

                                    {
                                        title: 'The Add Timesheet modal will appear.',
                                        boldTexts: ['Add Timesheet'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffTimeFixedPayDesktop1,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        subTitle: '5. Fill in the Timesheet Details (Fixed Pay)',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The Add Fixed Pay Timesheet modal will appear. Fill in the following fields',
                                        boldTexts: ['From Paycodes'],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Amount ($): Enter the fixed amount to be paid',
                                                boldTexts: ['Amount', '($)'],
                                                circle: true,

                                            },

                                            {
                                                title: 'Description: Enter a description for the timesheet entry.',
                                                boldTexts: ['Description'],
                                                circle: true,

                                            },

                                            {
                                                title: 'Timesheet Date: Enter the date the staff member worked.',
                                                boldTexts: ['Timesheet Date'],
                                                circle: true,

                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Fill in the Timesheet Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The Add Paycode Timesheet modal will appear. Fill in the following fields',
                                        boldTexts: ['Add Paycode Timesheet'],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Paycode: Choose the paycode under which the staff member worked (these paycodes are pre-created by your organization’s administrators in the system settings and added to the staff member\'s profile).',
                                                boldTexts: ['Paycode'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Description: Enter a description for the timesheet entry.',
                                                boldTexts: ['Description'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Hours: Enter the number of hours worked.',
                                                boldTexts: ['Hours'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Minutes: Enter the number of minutes worked.',
                                                boldTexts: ['Minutes'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Timesheet Date: Enter the date the staff member worked.',
                                                boldTexts: ['Timesheet Date'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffTimeFixedPayDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. Save the Timesheet',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click Add at the bottom right of the modal.',
                                        boldTexts: ['Add'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '8. View the New Timesheet',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The newly added timesheet will appear in the timesheet table for the selected staff member.',
                                        boldTexts: ['Add'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'ADD_STAFF_CREDENTIALS': {
            pageTitle: 'Adding Staff Credentials',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Select the staff member and go to the Credentials tab.',
                                        boldTexts: ['Credentials'],
                                    },

                                    {
                                        title: '3. Click + Add Credential and fill in the details (Credential, License Number, Expiration Date).',
                                        boldTexts: ['Add Credential'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffCredDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '4. Click Add to save.',
                                        boldTexts: ['Add'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffCredDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the >Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the staff member for whom you would like to add credentials.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Credentials Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Credentials tab at the top of the page.',
                                        boldTexts: ['Credentials'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffCredDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Add a Credential',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the + Add Credential button at the top right of the page.',
                                        boldTexts: ['Add Credential'],
                                        circle: true,
                                    },

                                    {
                                        title: 'The Add Credential modal will appear.',
                                        boldTexts: ['Add Credential'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        subTitle: '5. Fill in the Credential Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Complete the following fields in the Add a New Credential modal',
                                        boldTexts: ['Add a New Credential'],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Select Credential: Choose the credential from the list created by your organization’s administrators. Select the credential that the staff member holds.',
                                                boldTexts: ['Select Credential'],
                                                circle: true,

                                            },

                                            {
                                                title: 'License Number: If the credential is a license, enter the license number. If not, leave this field blank.',
                                                boldTexts: ['License Number'],
                                                circle: true,

                                            },

                                            {
                                                title: 'Select Expiration: Choose whether the credential is Expiring or Non-Expiring.',
                                                boldTexts: ['Select Expiration', 'Expiring', 'Non-Expiring'],
                                                circle: true,

                                            },

                                            {
                                                title: 'Expiration Date: If the credential is Expiring, select the expiration date.',
                                                boldTexts: ['Expiration Date', 'Expiring'],
                                                circle: true,

                                            },
                                        ],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffCredDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Save the Credential',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Add button at the bottom right of the modal.',
                                        boldTexts: ['Add'],
                                        circle: true,

                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. View the New Credential',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The newly added credential will appear in the selected staff member’s credentials table.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'ADD_STAFF_NOTES': {
            pageTitle: 'Adding Staff Notes',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Select the staff member and go to the Notes tab.',
                                        boldTexts: ['Notes'],
                                    },

                                    {
                                        title: '3. Click + Add Note, fill in the subject and body.',
                                        boldTexts: ['Add Note'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffNotesDesktop1,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '4. Click Save.',
                                        boldTexts: ['Save'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffNotesDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the staff member for whom you would like to add notes.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Notes Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Notes tab at the top of the page.',
                                        boldTexts: ['Notes'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Add a New Note',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the + Add Note button at the top right of the page.',
                                        boldTexts: ['Add Note'],
                                        circle: true,
                                    },

                                    {
                                        title: 'The Add Note modal will appear.',
                                        boldTexts: ['Add Note'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffNotesDesktop1,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        subTitle: '5. Fill in the Note Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Complete the following fields',
                                        boldTexts: [''],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Subject: Enter the subject of the note',
                                                boldTexts: ['Subject'],
                                                circle: true,

                                            },

                                            {
                                                title: 'Body: Enter the body content of the note.',
                                                boldTexts: ['Body'],
                                                circle: true,

                                            },
                                        ],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffNotesDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Save the Note',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click Save on the bottom right of the modal.',
                                        boldTexts: ['Save'],
                                        circle: true,

                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. View the New Note',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The newly added note will appear in the Notes table for the selected staff member.',
                                        boldTexts: ['Notes'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'VIEW_STAFF_PROFILE_CHANGE': {
            pageTitle: 'Viewing Staff Profile Change History',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Select the staff member and go to the History tab to view the changes made.',
                                        boldTexts: ['History'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.viewStaffProfChangeHistoryDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the staff member for whom you would like to view the change history.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Notes Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the History tab at the top of the page.',
                                        boldTexts: ['History'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. View Change History:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Review the history of changes made to the staff member\'s profile, including edits to information, role changes, added or removed credentials, and other modifications.',
                                        boldTexts: ['Add Note'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.viewStaffProfChangeHistoryDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'ADD_STAFF_FILES': {
            pageTitle: 'Adding Staff Files',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Select the staff member and go to the Files tab.',
                                        boldTexts: ['Files'],
                                    },

                                    {
                                        title: '3. Click Upload, fill in the fields, and upload the files.',
                                        boldTexts: ['Upload'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffFilesDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '4. Click Add to save.',
                                        boldTexts: ['Add'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffFilesDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffFilesDesktop3,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the staff member for whom you would like to upload files.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Files Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Files tab at the top of the page.',
                                        boldTexts: ['Files'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Upload Files',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Upload Document button to add relevant documents or files associated with the staff member.',
                                        boldTexts: ['Upload Document'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Once the button is clicked, the Upload Document modal will appear.',
                                        boldTexts: ['Upload Document'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffFilesDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Fill in the Fields and Upload Files',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Fill in the required fields in the Upload Document modal.',
                                        boldTexts: ['Upload Document'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click to upload or drag and drop the file into the drag and drop box.',
                                        boldTexts: ['drag and drop'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Review Uploaded Files in the Modal',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Once the file is dragged or selected, it will appear at the bottom of the modal.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'Repeat this process for each additional file you would like to upload.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. Save the Uploaded Files',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'After adding all the files, click the Add button on the bottom right of the modal.',
                                        boldTexts: ['Add'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffFilesDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '8. View Uploaded Files',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'You will see the uploaded files listed in the table under the Files tab for the selected staff member.',
                                        boldTexts: ['Files'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffFilesDesktop3,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'ADD_EDIT_STAFF_INFO': {
            pageTitle: 'Adding/Editing Staff General Information',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Select the staff member and ensure you are on the General tab.',
                                        boldTexts: ['General'],
                                    },

                                    {
                                        title: '3. Click the blue pencil icon to edit general information (First Name, Email, etc.).',
                                        boldTexts: ['General'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addEditStaffGenInfoDesktoop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '4. Click Save to apply changes.',
                                        boldTexts: ['Save'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addEditStaffGenInfoDesktoop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addEditStaffGenInfoDesktoop3,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the staff member for whom you would like to add or edit general information.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Ensure You Are on the General Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Make sure that you are on the General tab. If not, click on the General tab at the top of the page.',
                                        boldTexts: ['General'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Edit General Information',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Navigate to the left table and click on the blue pencil icon at the top right of the leftmost table next to General Info.',
                                        boldTexts: ['blue pencil icon', 'General Info'],
                                        circle: true,
                                    },

                                    {
                                        title: 'The Edit General Info modal will appear.',
                                        boldTexts: ['Edit General Info'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addEditStaffGenInfoDesktoop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Update General Information Fields',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Edit or add information as needed to the following fields',
                                        boldTexts: ['Upload Document'],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'First Name',
                                                boldTexts: ['First Name'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Middle Name',
                                                boldTexts: ['Middle Name'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Last Name',
                                                boldTexts: ['Last Name'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Primary Phone Number',
                                                boldTexts: ['Primary Phone Number'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Secondary Phone Number',
                                                boldTexts: ['Secondary Phone Number'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Street Address',
                                                boldTexts: ['Street Address'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Unit/Suite #',
                                                boldTexts: ['Unit', 'Suite'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Save Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Save button on the bottom right of the modal.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addEditStaffGenInfoDesktoop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. View Updated Information',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The updated information will populate on the page. Some information will be displayed under the staff member\'s name for quick viewing, while other information will be in the General Info box.',
                                        boldTexts: ['General Info'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addEditStaffGenInfoDesktoop3,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'ADD_STAFF_FILES_2': {
            pageTitle: 'Adding Staff Other Details',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Select the staff member and go to the General tab.',
                                        boldTexts: ['General'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffFiles2Desktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '3. Click + Add Other Details and fill in the details (Driver License, NPI Number, SSN Number, etc).',
                                        boldTexts: ['Add Other Details'],
                                    },

                                    {
                                        title: '4. Click Save to save.',
                                        boldTexts: ['Save'],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffFiles2Desktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffFiles2Desktop3,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the staff member for whom you would like to add or edit other information.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Ensure You Are on the General Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Make sure that you are on the General tab. If not, click on the General tab at the top of the page.',
                                        boldTexts: ['General'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Add Other Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Navigate to the box in the middle of the page labeled Other Details.',
                                        boldTexts: ['Other Details'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click on the + Add Other Details button in the middle of the box.',
                                        boldTexts: ['Add Other Details'],
                                        circle: true,
                                    },

                                    {
                                        title: 'The Add Other Details modal will appear.',
                                        boldTexts: ['Add Other Details'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffFiles2Desktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Fill in Other Information Fields',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Select or fill in the fields as needed',
                                        boldTexts: [''],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Driver\'s License',
                                                boldTexts: ['Driver\'s License'],
                                                circle: true,
                                            },

                                            {
                                                title: 'License Name',
                                                boldTexts: ['License Name'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Issuing State',
                                                boldTexts: ['Issuing State'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Expiration Date',
                                                boldTexts: ['Expiration Date'],
                                                circle: true,
                                            },

                                            {
                                                title: 'NPI Number: National Provider Identifier needed for claims and billing purposes.',
                                                boldTexts: ['NPI Number'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Residency Status',
                                                boldTexts: ['Residency Status'],
                                                circle: true,
                                            },

                                            {
                                                title: 'SSN Number: Social Security Number.',
                                                boldTexts: ['SSN Number'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Gender',
                                                boldTexts: ['Gender'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Date of Birth',
                                                boldTexts: ['Date of Birth'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Languages Spoken',
                                                boldTexts: ['Languages Spoken'],
                                                circle: true,
                                            },

                                            {
                                                title: 'DOJ ( Department of Justice background check ): Select the checkbox and add the date. This date is needed to create appointments for this staff member.',
                                                boldTexts: ['DOJ', 'Department of Justice background check'],
                                                circle: true,
                                            },

                                            {
                                                title: 'TB (Tuberculosis Test): Select the checkbox and add the Start Date and Expiration Date. These dates are required to ensure the staff member meets health regulations for appointments.',
                                                boldTexts: ['TB', 'Tuberculosis Test'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Save Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Save button on the bottom right of the modal.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffFiles2Desktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. View Updated Information',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The information will populate in the Other Details box in the middle of the page.',
                                        boldTexts: ['Other Details'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffFiles2Desktop3,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'ADD_STAFF_MEMBER_CURRENT_PAST': {
            pageTitle: 'Adding Staff Member’s Current and Past Employment',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Select the staff member and go to the General tab.',
                                        boldTexts: ['General'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffCurrPastEmplDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '3. Click the blue  + Add Employment and fill in the details (Title, Supervisor, Department, etc).',
                                        boldTexts: ['Add Employment'],
                                    },

                                    {
                                        title: '4. Click Add to save.',
                                        boldTexts: ['Add'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffCurrPastEmplDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffCurrPastEmplDesktop3,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the staff member for whom you would like to add employment information.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Ensure You Are on the General Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Make sure that you are on the General tab. If not, click on the General tab at the top of the page.',
                                        boldTexts: ['General'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Add Employment Information:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Navigate to the rightmost box labeled Employment.',
                                        boldTexts: ['Employment'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click on the + Add Employment button on the right of the Employment box.',
                                        boldTexts: ['Add Employment', 'Employment'],
                                        circle: true,
                                    },

                                    {
                                        title: 'The Add Employment modal will appear.',
                                        boldTexts: ['Add Employment'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffCurrPastEmplDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Fill in Employment Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Select the values for the fields',
                                        boldTexts: [''],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Title: Choose a title for the staff member.',
                                                boldTexts: ['Title'],
                                                circle: true,
                                            },


                                            {
                                                title: 'Supervisor: Select the supervisor for this staff member during the employment period.',
                                                boldTexts: ['Supervisor'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Department: Select the Department',
                                                boldTexts: ['Department'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Employment Type: Choose whether they were Full-Time, Part-Time, Intern, or Contractor.',
                                                boldTexts: ['Employment Type'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Start Date: Enter the start date of employment.',
                                                boldTexts: ['Start Date'],
                                                circle: true,
                                            },

                                            {
                                                title: 'End Date: Enter the end date of employment. If this is a current position, leave this blank.',
                                                boldTexts: ['End Date'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Past Employment: Select the checkbox if this is a past employment position.',
                                                boldTexts: ['Past Employment'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Save Employment Information:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click Add on the bottom right of the Add Employment modal.',
                                        boldTexts: ['Add'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffCurrPastEmplDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. View Updated Employment Information:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The new employment details will be added to the system and displayed in the Employment section.',
                                        boldTexts: ['Employment'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.addStaffCurrPastEmplDesktop3,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'VIEW_STAFF_MEMBER_CURRENT': {
            pageTitle: 'Viewing Staff Member’s Current Employment',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Select the staff member and go to the General tab.',
                                        boldTexts: ['General'],
                                    },

                                    {
                                        title: '3. Navigate to the rightmost box labeled Employment  and Review the information in the fields',
                                        boldTexts: ['Employment'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.viewStaffCurrentEmpDesktop1,
                                    },

                                    {
                                        type: 'img',
                                        desktop: Images.viewStaffCurrentEmpDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the staff member for whom you would like to view current employment information.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Ensure You Are on the General Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Make sure that you are on the General tab. If not, click on the General tab at the top of the page.',
                                        boldTexts: ['General'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. View Current Employment Information',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Navigate to the rightmost box labeled Employment.',
                                        boldTexts: ['Employment'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Review the information in the fields. If there is no information displayed, it means there is no current employment recorded for the staff member.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.viewStaffCurrentEmpDesktop1,
                                    },

                                    {
                                        type: 'img',
                                        desktop: Images.viewStaffCurrentEmpDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'VIEW_STAFF_MEMBER_PAST': {
            pageTitle: 'Viewing Staff Member’s Past Employment',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Select the staff member and go to the General tab.',
                                        boldTexts: ['General'],
                                    },

                                    {
                                        title: '3. Navigate to the rightmost box labeled Employment',
                                        boldTexts: ['Employment'],
                                    },

                                    {
                                        title: '4. Click the View History button next to the Past Employments field.',
                                        boldTexts: ['View History', 'Past Employments'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.viewStaffPastEmplDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the staff member for whom you would like to view current employment information.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Ensure You Are on the General Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Make sure that you are on the General tab. If not, click on the General tab at the top of the page.',
                                        boldTexts: ['General'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. View Past Employment Information',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Navigate to the rightmost box labeled Employment.',
                                        boldTexts: ['Employment'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click the View History button next to the Past Employments field.',
                                        boldTexts: ['View History', 'Past Employments'],
                                        circle: true,
                                    },

                                    {
                                        title: 'If this button does not exist, it means that no past employments have been added for this staff member.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.viewStaffPastEmplDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'INACTIVE_STAFF_PAYCOD': {
            pageTitle: 'Inactivating Staff Paycode',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Select the staff member and go to the Paycodes tab.',
                                        boldTexts: ['Paycodes'],
                                    },

                                    {
                                        title: '3. Click the Inactivate Button.',
                                        boldTexts: ['Inactivate'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.inactiveStaffPayCodeDesktop1,
                                    },
                                ],
                            },
                        ],
                    },


                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '4. Select the Inactivation Date and  Confirm by clicking Inactivate.',
                                        boldTexts: ['Inactivation Date', 'Inactivate'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.inactiveStaffPayCodeDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.inactiveStaffPayCodeDesktop3,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the staff member for whom you want to inactivate a paycode.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Paycodes Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Paycodes tab at the top of the page.',
                                        boldTexts: ['Paycodes'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Locate the Paycode to Inactivate',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Paycodes table, find the paycode you want to inactivate.',
                                        boldTexts: ['Paycodes'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.inactiveStaffPayCodeDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Inactivate the Paycode',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the red Inactivate button to the right of the selected paycode.',
                                        boldTexts: ['red Inactivate button'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Confirm Inactivation',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'A confirmation modal will appear. Click Inactivate again on the modal to confirm the action.',
                                        boldTexts: ['Inactivate'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.inactiveStaffPayCodeDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. View Inactivation Date',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'After confirming, the system will display a date, which is the inactivation date for the paycode. This confirms the paycode has been successfully inactivated.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.inactiveStaffPayCodeDesktop3,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'EDIT_STAFF_TIMESHEET': {
            pageTitle: 'Editing Staff Timesheets (for Fixed and Paycode-Based Timesheets)',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Select the staff member and go to the Timesheet tab.',
                                        boldTexts: ['Timesheet'],
                                    },

                                    {
                                        title: '3. Click the blue pencil icon next to the timesheet you want to edit.',
                                        boldTexts: [''],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editStaffCredentialDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '4. Edit the fields and click Save.',
                                        boldTexts: ['Save'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editStaffCredentialDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the staff member whose timesheets you want to edit.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Timesheet Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Timesheet tab at the top of the page.',
                                        boldTexts: ['Timesheet'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Edit the Timesheet',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Locate the timesheet (whether fixed or paycode-based) you want to edit and click the blue pencil icon next to it.',
                                        boldTexts: ['blue pencil icon'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editStaffTimesheetDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Update Timesheet Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Edit the relevant fields in the Edit Timesheet modal that appears.',
                                        boldTexts: ['Edit Timesheet'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Save Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click Save at the bottom right of the modal to apply the changes.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editStaffTimesheetDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'EDIT_STAFF_CREDENTIALS': {
            pageTitle: 'Editing Staff Credentials',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Select the staff member and go to the Credentials tab.',
                                        boldTexts: ['Credentials'],
                                    },

                                    {
                                        title: '3. Click the blue pencil icon next to the credential you want to edit.',
                                        boldTexts: [''],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editStaffCredentialDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '4. Make the changes and click Save.',
                                        boldTexts: ['Save'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editStaffCredentialDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [

                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the staff member whose credentials you want to edit.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Credentials Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Credentials tab at the top of the page.',
                                        boldTexts: ['Credentials'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Edit the Credential',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the blue pencil icon next to the credential you wish to edit.',
                                        boldTexts: ['blue pencil icon'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editStaffCredentialDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Update Credential Information',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Credentials tab at the top of the page.',
                                        boldTexts: ['Edit Credential'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '6. Save Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click Save to apply the changes.',
                                        boldTexts: ['Save'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editStaffCredentialDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'VIEW_STAFF_CREDENTIALS': {
            pageTitle: 'Viewing Staff Credentials',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Select the staff member and go to the Credentials tab.',
                                        boldTexts: ['Credentials'],
                                    },

                                    {
                                        title: '3. Review the list of Credentials.',
                                        boldTexts: ['Credentials'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.viewStaffCredDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the staff member whose credentials you want to view.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Credentials Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Credentials tab at the top of the page.',
                                        boldTexts: ['Credentials'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. View Credentials',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Review the list of credentials displayed for the selected staff member.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.viewStaffCredDesktop,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'DELETE_STAFF_CREDENTIALS': {
            pageTitle: 'Deleting Staff Credentials',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Select the staff member and go to the Credentials tab.',
                                        boldTexts: ['Credentials'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteStaffCredDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '3. Click the red X next to the credential and confirm deletion.',
                                        boldTexts: [''],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteStaffCredDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the staff member whose credential you want to delete.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Credentials Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Credentials tab at the top of the page.',
                                        boldTexts: ['Credentials'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Delete the Credential',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the red X button next to the credential you wish to delete.',
                                        boldTexts: ['red X button'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteStaffCredDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Confirm Deletion',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'A confirmation modal will appear. Click Delete to confirm.',
                                        boldTexts: ['Delete'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteStaffCredDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'DELETE_STAFF_ACCESS': {
            pageTitle: 'Deleting Staff Access',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Select the staff member and go to the Access tab.',
                                        boldTexts: ['Access'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteStaffAccessDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '3. Click the red X next to the role and confirm removal.',
                                        boldTexts: ['Delete'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteStaffAccessDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],

                long: [
                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the staff member whose access you want to delete.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Access Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Access tab at the top of the page.',
                                        boldTexts: ['Access'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Delete the Access Role',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'On the right side of the role, click the red X button.',
                                        boldTexts: ['red X button'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteStaffAccessDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Confirm Removal',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'A confirmation modal will appear. Click Delete to confirm.',
                                        boldTexts: ['Delete'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteStaffAccessDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'DELETE_STAFF_SERVICES': {
            pageTitle: 'Deleting Staff Services',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Select the staff member and go to the Services tab.',
                                        boldTexts: ['Services'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteStaffServiceDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '3. Click the red X next to the service and confirm deletion.',
                                        boldTexts: ['red X'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteStaffServiceDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the staff member whose services you want to delete.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Services Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Services tab at the top of the page.',
                                        boldTexts: ['Access'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Delete the Service',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the red X button next to the service you wish to delete.',
                                        boldTexts: ['red X button'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteStaffServiceDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Confirm Deletion',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'A confirmation modal will appear. Click Delete to confirm.',
                                        boldTexts: ['Delete'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteStaffServiceDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'EDIT_STAFF_NOTES': {
            pageTitle: 'Editing Staff Notes',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Select the staff member and go to the Notes tab.',
                                        boldTexts: ['Notes'],
                                    },

                                    {
                                        title: '3. Click the blue pencil icon next to the note, edit the fields.',
                                        boldTexts: [''],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editStaffNotesDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '4. Click Save.',
                                        boldTexts: ['Save'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editStaffNotesDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the >Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the staff member whose notes you want to edit.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Notes Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Notes tab at the top of the page.',
                                        boldTexts: ['Notes'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Edit the Note',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the blue pencil icon next to the note you wish to edit.',
                                        boldTexts: ['blue pencil icon'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editStaffNotesDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Update Note Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Edit the fields in the Edit Note modal.',
                                        boldTexts: ['Edit Note'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Save Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click Save to apply the changes.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editStaffNotesDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'DELETE_STAFF_NOTES': {
            pageTitle: 'Deleting Staff Notes',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Select the staff member and go to the Notes tab.',
                                        boldTexts: ['Notes'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteStaffNotesDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '3. Click the X next to the note and confirm deletion.',
                                        boldTexts: [''],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteStaffNotesDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the >Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the staff member whose notes you want to delete.',
                                        boldTexts: ['delete'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Notes Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Notes tab at the top of the page.',
                                        boldTexts: ['Notes'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Delete the Note',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the X button next to the note you wish to delete.',
                                        boldTexts: ['X button'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteStaffNotesDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Confirm Deletion',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'A confirmation modal will appear. Click Delete to confirm.',
                                        boldTexts: ['Delete'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteStaffNotesDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'DELETE_STAFF_FILES': {
            pageTitle: 'Deleting Staff Files',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Select the staff member and go to the Files tab.',
                                        boldTexts: ['Files'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteStaffDileDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '3. Click the trashcan icon next to the file and confirm deletion.',
                                        boldTexts: ['trashcan icon'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteStaffDileDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the staff member whose files you want to delete.',
                                        boldTexts: ['delete'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Files Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Files tab at the top of the page.',
                                        boldTexts: ['Files'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Delete the File',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the trashcan icon next to the file you wish to delete.',
                                        boldTexts: ['trashcan icon'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteStaffDileDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Confirm Deletion',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'A confirmation modal will appear. Click Delete to confirm.',
                                        boldTexts: ['Delete'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteStaffDileDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'EDIT_STAFF_PAST_EMPLOYMENT': {
            pageTitle: 'Editing Staff Member’s Past Employment',
            content: {
                short: [

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Select the staff member and go to the General tab.',
                                        boldTexts: ['General'],
                                    },

                                    {
                                        title: '3. Navigate to the rightmost box labeled Employment.',
                                        boldTexts: ['Employment'],
                                    },

                                    {
                                        title: '4. Click the View History button next to the Past Employments field.',
                                        boldTexts: ['View History'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editStaffPastMemberDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '5. Click the blue pencil icon to edit Past Employment information.',
                                        boldTexts: ['Past Employment'],
                                    },

                                    {
                                        title: '6. Click Save to apply changes.',
                                        boldTexts: ['Save'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editStaffPastMemberDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editStaffPastMemberDesktop3,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the staff member whose past employment you want to edit.',
                                        boldTexts: ['edit'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Ensure You Are on the General Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Make sure that you are on the General tab. If not, click on the General tab at the top of the page.',
                                        boldTexts: ['General'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. View Past Employment History',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the View History button next to the Past Employments field.',
                                        boldTexts: ['View History', 'Past Employments'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editStaffPastMemberDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Edit the Employment Record',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the blue pencil icon next to the employment record you wish to edit.',
                                        boldTexts: ['blue pencil icon'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editStaffPastMemberDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Update Employment Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Edit the necessary fields in the Edit Employment modal.',
                                        boldTexts: ['Edit Employment'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        subTitle: '7. Save Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click Save to apply the changes.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.editStaffPastMemberDesktop3,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'DELETE_STAFF_PAST_EMPLOYMENT': {
            pageTitle: 'Deleting Staff Member’s Past Employment',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Select the staff member and go to the General tab.',
                                        boldTexts: ['General'],
                                    },

                                    {
                                        title: '3. Navigate to the rightmost box labeled Employment.',
                                        boldTexts: ['Employment'],
                                    },

                                    {
                                        title: '4. Click the View History button next to the Past Employments field.',
                                        boldTexts: ['Past Employments'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteStaffMemberEmployDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '5. Click the red X icon',
                                        boldTexts: ['red X icon'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteStaffMemberEmployDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '6. Click Delete to confirm.',
                                        boldTexts: ['Delete'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteStaffMemberEmployDesktop3,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the staff member whose past employment you want to delete.',
                                        boldTexts: ['edit'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Ensure You Are on the General Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Make sure that you are on the General tab. If not, click on the General tab at the top of the page.',
                                        boldTexts: ['General'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. View Past Employment History',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the View History button next to the Past Employments field.',
                                        boldTexts: ['View History', 'Past Employments'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteStaffMemberEmployDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Delete the Employment Record',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the red X button next to the employment record you wish to delete.',
                                        boldTexts: ['red X button'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteStaffMemberEmployDesktop2,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Confirm Deletion',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'A confirmation modal will appear. Click Delete to confirm.',
                                        boldTexts: ['Delete'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.deleteStaffMemberEmployDesktop3,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },

        'ACTIVE_INACTIVE_STAFF_MEMBER': {
            pageTitle: 'Activating/Inactivating a Staff Member',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Select the staff member.',
                                        boldTexts: ['Staff'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.activeInactiveStaffDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '3. Toggle the Active/Inactive button at the top right of the page to change the status.',
                                        boldTexts: ['Active', 'Inactive'],
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.activeInactiveStaffDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the staff member you want to activate or inactivate.',
                                        boldTexts: ['edit'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Locate the Activation Toggle',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'At the top right of the page, there is a button labeled Active or Inactive, depending on the current status of the staff member.',
                                        boldTexts: ['Active', 'Inactive'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Toggle Staff Member Status',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Active or Inactive button to change the status of the staff member.',
                                        boldTexts: ['Active', 'Inactive'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.activeInactiveStaffDesktop1,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. View Updated Status',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The staff member\'s status will be updated, and you will see either Active or Inactive reflected in the system.',
                                        boldTexts: ['Active', 'Inactive'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                images: [
                                    {
                                        type: 'img',
                                        desktop: Images.activeInactiveStaffDesktop2,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'ADD_STAFF_ACCESS': {
            pageTitle: 'Adding Staff Access',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Select the staff member and go to the Access tab.',
                                        boldTexts: ['Access'],
                                    },

                                    {
                                        title: '3. Add roles by typing or selecting from the dropdown.',
                                        boldTexts: [''],
                                    },

                                    {
                                        title: '4. Roles will appear in the left table, and their permissions in the right table.',
                                        boldTexts: [''],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the staff member for whom you would like to add access roles.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Access Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Access tab at the top of the page.',
                                        boldTexts: ['Access'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Add a Role',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Add Role field.',
                                        boldTexts: ['Add Role'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Begin typing or select a role from the dropdown list that appears.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'You can add multiple roles by clicking into the Add Role field and selecting additional roles.',
                                        boldTexts: ['Add Role'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Understanding Roles',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'These roles are predefined by your organization’s administrators.' +
                                            ' If you don’t see any roles, it means that roles have not been added by your organization yet.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. View Assigned Roles',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The newly selected roles will appear in the left table.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. View Role Permissions',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'You can select any role in the left table to view its associated permissions, which will be displayed in the right table.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'ADD_STAFF_SERVICES': {
            pageTitle: 'Adding Staff Services',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Staff in the left pane.',
                                        boldTexts: ['Staff'],
                                    },

                                    {
                                        title: '2. Select the staff member and go to the Services tab.',
                                        boldTexts: ['Services'],
                                    },

                                    {
                                        title: '3. Select the service(s) from the list and click Add Services.',
                                        boldTexts: ['Add Services'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Staff Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the > Staff section in the left pane.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Staff Member',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose the staff member for whom you would like to add services.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Navigate to the Services Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Services tab at the top of the page.',
                                        boldTexts: ['Services'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Add Services',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click into the Services field at the top of the page and begin typing or select the service you would like to add from the list.',
                                        boldTexts: ['Services'],
                                        circle: true,
                                    },

                                    {
                                        title: 'A list of available services will appear. These services are predefined by your organization’s' +
                                            ' administrators and are tied to funding sources. If you don’t see any services, it means that no services ' +
                                            'have been added to the system by your organization.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Select Multiple Services',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'You can add multiple services by clicking into the Services field again and selecting additional services.',
                                        boldTexts: ['Services'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Add Selected Services',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Once you have selected all the services you wish to add, click on the Add Services button at the' +
                                            ' bottom right of the services list dropdown.',
                                        boldTexts: ['Add Services'],
                                        circle: true,
                                    },

                                    {
                                        title: 'If you do not see this button, click on the Services field again, and the dropdown will reappear, ' +
                                            'allowing you to click on the Add Services button.',
                                        boldTexts: ['Add Services', 'Services'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. View Added Services',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'After adding the services, you will see them appear in the Services list for the selected staff member.',
                                        boldTexts: ['Services'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
    },
    'PAYROLL': {
        'ACTIVE_PAYROLL': {
            'VIEW_ACTIVE_PAYROLL_TIMESHEETS': {
                pageTitle: 'Viewing Active Payroll Timesheets List',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Payroll > Active from the left pane.',
                                            boldTexts: ['Payroll', 'Active'],
                                        },

                                        {
                                            title: '2. Review the columns including Timesheet ID, Date, Appointment ID, CPT Code, Staff Name, Type, Paycode, Rate, Quantity, and Total Amount.',
                                            boldTexts: ['Timesheet ID', 'Date', 'Appointment ID', 'CPT Code', 'Staff Name', 'Type', 'Paycode', 'Rate', 'Quantity', 'Total Amount'],
                                        },

                                        {
                                            title: '3. Sort and filter the list as needed, using Date or Type filters.',
                                            boldTexts: ['Date', 'Type filters'],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Payroll Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the left-side pane, click >Payroll.',
                                            boldTexts: ['Payroll'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Select Active Payroll',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Under the Payroll section, click Active to view the list of active timesheets.',
                                            boldTexts: ['Payroll', 'Active'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Understand the Columns',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Timesheet ID: The unique identifier for each timesheet.',
                                            boldTexts: ['Timesheet ID'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Date: The date the service or work was completed.',
                                            boldTexts: ['Date'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Appointment ID (Appt ID): The identifier for the associated appointment.',
                                            boldTexts: ['Appointment ID', 'Appt ID'],
                                            circle: true,
                                        },

                                        {
                                            title: 'CPT Code: Standardized codes used to describe and bill for services.',
                                            boldTexts: ['CPT Code'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Staff Name: The name of the staff member linked to the timesheet.',
                                            boldTexts: ['Staff Name'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Type: Indicates whether the timesheet is Hourly or Fixed.',
                                            boldTexts: ['Type'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Paycode: Designates how the staff member is compensated for specific services.',
                                            boldTexts: ['Paycode'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Rate: The hourly or fixed pay rate.',
                                            boldTexts: ['Rate'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Quantity (Qty): The number of units worked, such as hours or miles.',
                                            boldTexts: ['Quantity', 'Qty'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Total Amount (Total AMT): The total payment amount for the timesheet.',
                                            boldTexts: ['Total Amount', 'Total AMT'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '4. Sorting and Filtering',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Date can be sorted in ascending or descending order.',
                                            boldTexts: ['Date'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Type can be filtered to show specific types of entries.',
                                            boldTexts: ['Type'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'ADD_FIX_PAY_TIMESHEET': {
                pageTitle: 'Adding a Fixed Pay Timesheet',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Payroll > Active from the left pane.',
                                            boldTexts: ['Payroll', 'Active'],
                                        },

                                        {
                                            title: '2. Click on the Add Timesheet button in the top-right corner.',
                                            boldTexts: ['Add Timesheet'],
                                        },

                                        {
                                            title: '3. Select the Fixed Pay option in the modal.',
                                            boldTexts: ['Fixed Pay'],
                                        },

                                        {
                                            title: '4. Fill in the details: Staff Member, Amount, Description, and Timesheet Date.',
                                            boldTexts: ['Staff Member', 'Amount', 'Description', 'Timesheet Date'],
                                        },

                                        {
                                            title: '5. Click Add to save the fixed pay timesheet.',
                                            boldTexts: ['Add'],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Payroll Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the left-side pane, click >Payroll.',
                                            boldTexts: ['Payroll'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Select Active Timesheets',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Under the Payroll section, click Active to view the list of active timesheets.',
                                            boldTexts: ['Payroll', 'Active'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Add a Timesheet',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the top-right corner of the page, click on the Add Timesheet button.',
                                            boldTexts: ['Add Timesheet'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '4. Select Fixed Pay Timesheet Type',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the modal that pops up, choose the Fixed Pay option.',
                                            boldTexts: ['Fixed Pay'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Fill in Timesheet Information',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Staff Member: Select the staff member for whom you are adding the timesheet.',
                                            boldTexts: ['Staff Member'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Amount ($): Enter the fixed pay amount.',
                                            boldTexts: ['Amount', '($)'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Description: Optionally, add a description for the timesheet.',
                                            boldTexts: ['Description'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Timesheet Date: Select the date the timesheet is associated with.',
                                            boldTexts: ['Timesheet Date'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. Save the Timesheet',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'After entering all the required information, click Add to save the fixed pay timesheet.',
                                            boldTexts: ['Add'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '7. View the Added Timesheet',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'The new timesheet will now appear in the Active timesheet table.',
                                            boldTexts: ['Active'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'PROCESSING_PAYROLL_SUBLIST': {
                pageTitle: 'Processing Payroll',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Payroll > Active from the left pane.',
                                            boldTexts: ['Payroll', 'Active'],
                                        },

                                        {
                                            title: '2. Click on the Process Payroll button in the top-right corner.',
                                            boldTexts: ['Process Payroll'],
                                        },

                                        {
                                            title: '3. Select Staff and Date Range to filter the timesheets.',
                                            boldTexts: ['Staff'],
                                        },

                                        {
                                            title: '4. Review the timesheets in the list, including Timesheet ID, Staff, Appointment ID, Rate, and Total Amount.',
                                            boldTexts: ['Timesheet ID', 'Staff', 'Appointment ID', 'Rate', 'Total Amount'],
                                        },

                                        {
                                            title: '5. Click Process Payroll to finalize and view the processed timesheets in the Processed section.',
                                            boldTexts: ['Process Payroll'],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Payroll Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the left-side pane, click >Payroll.',
                                            boldTexts: ['Payroll'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Go to the Active Payroll Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Select Active under the Payroll menu to view the timesheets available for processing.',
                                            boldTexts: ['Active'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Click on Process Payroll',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the top-right corner, click the Process Payroll button.',
                                            boldTexts: ['Process Payroll'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '4. Select Staff and Date Range',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Use the Staff dropdown to filter the staff members you want to include.',
                                            boldTexts: ['Staff'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Set the Date Range by selecting the start and end dates, which must be Sundays, to highlight the appropriate weeks for processing.',
                                            boldTexts: ['Date Range'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Review Timesheets to Process',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'A list of matching timesheets will appear based on the selected filters.',
                                            boldTexts: ['Staff Member'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Review the Timesheet ID, Timesheet Date, Staff, Appointment ID, Type, Rate, Quantity, and Total Amount for each timesheet.',
                                            boldTexts: ['Timesheet ID', 'Timesheet Date', 'Staff', 'Appointment ID', 'Type, Rate', 'Quantity', 'Total Amount'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. Click Process Payroll',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once reviewed, click the Process Payroll button in the bottom-right corner.',
                                            boldTexts: [' Process Payroll'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '7. View Processed Timesheets in the Processed Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'After processing, go to the Processed section under Payroll on the left-side pane.',
                                            boldTexts: ['Processed', 'Payroll'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Click on the batch you just processed to see all the timesheets associated with that payroll batch.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'VIEW_ACTIVE_TIME_DETAILS': {
                pageTitle: 'Viewing Active Timesheet Details',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Payroll > Active from the left pane.',
                                            boldTexts: ['Payroll', 'Active'],
                                        },

                                        {
                                            title: '2. Locate the specific timesheet and click on the Timesheet ID.',
                                            boldTexts: ['Timesheet ID'],
                                        },

                                        {
                                            title: '3. Review the details such as Timesheet ID, Timesheet Date, Staff Name, Appointment ID, Rate, and Total Amount.',
                                            boldTexts: ['Timesheet ID', 'Timesheet Date', 'Staff Name', 'Appointment ID', 'Rate', 'Total Amount'],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Payroll Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the left-side pane, click >Payroll.',
                                            boldTexts: ['Payroll'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Select the Active Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'From the sub-sections under Payroll, click on Active.',
                                            boldTexts: ['Active', 'Payroll'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Locate the Timesheet',
                            circle: true,
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the list of timesheets, find the specific timesheet you want to view.',
                                            boldTexts: ['Process Payroll'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '4. Click on the Timesheet ID',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click the Timesheet ID in the first column to open the details for that timesheet.',
                                            boldTexts: ['Timesheet ID'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. View Details',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'You will be directed to the Details tab by default, where you can see the following information:',
                                            boldTexts: ['Staff Member'],
                                            circle: true,

                                            list: [
                                                {
                                                    title: 'Timesheet ID',
                                                    boldTexts: ['Timesheet ID'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Timesheet Date',
                                                    boldTexts: ['Timesheet Date'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Created Date',
                                                    boldTexts: ['Created Date'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Staff Name',
                                                    boldTexts: ['Staff Name'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Appointment ID',
                                                    boldTexts: ['Appointment ID'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'CPT Code',
                                                    boldTexts: ['CPT Code'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Paycode',
                                                    boldTexts: ['Paycode'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Type',
                                                    boldTexts: ['Type'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Rate',
                                                    boldTexts: ['Rate'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Quantity (Qty)',
                                                    boldTexts: ['Quantity', 'Qty'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Total Amount (AMT)',
                                                    boldTexts: ['Total Amount', 'AMT'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Description',
                                                    boldTexts: ['Description'],
                                                    circle: true,
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. Click Process Payroll',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once reviewed, click the Process Payroll button in the bottom-right corner.',
                                            boldTexts: [' Process Payroll'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '7. View Processed Timesheets in the Processed Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'After processing, go to the Processed section under Payroll on the left-side pane.',
                                            boldTexts: ['Processed', 'Payroll'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Click on the batch you just processed to see all the timesheets associated with that payroll batch.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'EDIT_NOTE_ACTIVE_TIME': {
                pageTitle: 'Editing a Note for an Active Timesheet',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Payroll > Active from the left pane.',
                                            boldTexts: ['Payroll', 'Active'],
                                        },

                                        {
                                            title: '2. Select the Timesheet ID to open the timesheet details.',
                                            boldTexts: ['Timesheet ID'],
                                        },

                                        {
                                            title: '3. Click on the Notes tab.',
                                            boldTexts: ['Notes'],
                                        },

                                        {
                                            title: '4. Click the Edit (Pencil Icon) next to the note you want to modify.',
                                            boldTexts: ['Edit', 'Pencil Icon'],
                                        },

                                        {
                                            title: '5. Make changes to the Subject and Body, then click Save.',
                                            boldTexts: ['Subject', 'Body', 'Save'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Payroll Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the left-side pane, click >Payroll.',
                                            boldTexts: ['Payroll'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Select the Active Timesheets',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'From the Payroll sub-sections, click on Active to view all active timesheets.',
                                            boldTexts: ['Active', 'Payroll'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Select the Timesheet',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Active Timesheets list, click on the Timesheet ID for which you want to edit the note.',
                                            boldTexts: ['Active Timesheets', 'Timesheet ID'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '4. Click on the Notes Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once in the Timesheet Details screen, click on the Notes tab at the top of the page.',
                                            boldTexts: ['Timesheet Details', 'Notes'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Edit the Note',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Action column of the notes list, click the Edit (Pencil Icon) next to the note you want to modify.',
                                            boldTexts: ['Action', 'Edit', 'Pencil Icon'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. Modify the Note',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Update the Subject and Body fields in the modal that pops up.',
                                            boldTexts: ['Subject', 'Body'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '7. Save the Changes',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once you have finished editing the note, click Save. The updated note will now be reflected in the notes list.',
                                            boldTexts: ['Save'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'DELETE_NOTE_ACTIVE_TIME': {
                pageTitle: 'Deleting a Note from an Active Timesheet',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Payroll > Active from the left pane.',
                                            boldTexts: ['Payroll', 'Active'],
                                        },

                                        {
                                            title: '2. Select the Timesheet ID to open the timesheet details.',
                                            boldTexts: ['Timesheet ID'],
                                        },

                                        {
                                            title: '3. Click on the Notes tab.',
                                            boldTexts: ['Notes'],
                                        },

                                        {
                                            title: '4. Click the Delete (Red X Icon) next to the note you want to remove.',
                                            boldTexts: ['Delete', 'ed X Icon'],
                                        },

                                        {
                                            title: '5. Confirm the deletion in the modal by clicking Delete.',
                                            boldTexts: ['Delete'],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Payroll Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the left-side pane, click >Payroll.',
                                            boldTexts: ['Payroll'],
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '2. Select the Active Timesheets',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'From the Payroll sub-sections, click on Active to view all active timesheets.',
                                            boldTexts: ['Active', 'Payroll'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Select the Timesheet',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Active Timesheets list, click on the Timesheet ID for which you want to delete the note.',
                                            boldTexts: ['Timesheet ID', 'Active Timesheets'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '4. Click on the Notes Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once in the Timesheet Details screen, click on the Notes tab at the top of the page.',
                                            boldTexts: ['Timesheet Details', 'Notes'],
                                            circle: true,
                                        },

                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Delete the Note',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Action column of the notes list, click the Delete (Red X Icon) next to the note you want to delete',
                                            boldTexts: ['Action', 'Delete', 'Red X Icon'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. Confirm Deletion',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'A confirmation modal will appear. Review the note and click Delete to confirm. This action cannot be undone, and the note will be permanently deleted.',
                                            boldTexts: ['Delete'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'VIEW_CHANGE_HISTORY_ACTIVE_TIME': {
                pageTitle: 'Viewing Change History on an Active Timesheet',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Payroll > Active from the left pane.',
                                            boldTexts: ['Payroll', 'Active'],
                                        },

                                        {
                                            title: '2. Click on the Timesheet ID to open its details.',
                                            boldTexts: ['Timesheet ID'],
                                        },

                                        {
                                            title: '3. Select the History tab to view the timesheet\'s change history, including dates, staff names, and actions.',
                                            boldTexts: ['History'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Go to Payroll',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Navigate to the Payroll section from the left pane.',
                                            boldTexts: ['Payroll'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Click on Active to view the list of active timesheets.',
                                            boldTexts: ['Active'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Open Timesheet Details',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Locate the timesheet you want to view.',
                                            boldTexts: [''],
                                            circle: true,
                                        },

                                        {
                                            title: 'Click on the timesheet to open its details.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. View the Change History',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the timesheet details window, click on the History tab.',
                                            boldTexts: ['History'],
                                            circle: true,
                                        },

                                        {
                                            title: 'This will display the history of all actions and updates made to the timesheet.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                },
            },
            'VIEW_FILES_ACTIVE_TIME': {
                pageTitle: 'Viewing Files in Active Timesheets',

                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Payroll > Active from the left pane.',
                                            boldTexts: ['Payroll', 'Active'],
                                        },

                                        {
                                            title: '2. Click on the Timesheet ID to open its details.',
                                            boldTexts: ['Timesheet ID'],
                                        },

                                        {
                                            title: '3. Go to the Files tab to view all associated files.',
                                            boldTexts: ['Files'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Access the Payroll Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'From the left pane, click on Payroll and select Active to view active timesheets.',
                                            boldTexts: ['Payroll', 'Active'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Open the Timesheet',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Active payroll list, click on the timesheet you want to view.',
                                            boldTexts: ['Active'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Navigate to the Files Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once inside the timesheet details, click on the Files tab at the top.',
                                            boldTexts: ['Files'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '4. View Files',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'All files related to the timesheet will be listed here, along with details like the document name, description, and date uploaded.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                },
            },
            'EDIT_FILES_ACTIVE_TIME': {
                pageTitle: 'Editing Files in Active Timesheets',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Payroll > Active from the left pane.',
                                            boldTexts: ['Payroll', 'Active'],
                                        },

                                        {
                                            title: '2. Select the Timesheet ID to open its details.',
                                            boldTexts: ['Timesheet ID'],
                                        },

                                        {
                                            title: '3. Go to the Files tab.',
                                            boldTexts: ['Files'],
                                        },

                                        {
                                            title: '4. Click the blue pencil icon next to the file you want to edit.',
                                            boldTexts: ['blue pencil icon'],
                                        },

                                        {
                                            title: '5. Modify the Document Name or Description, then click Save.',
                                            boldTexts: ['Document Name', 'Description'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Access the Payroll Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'From the left pane, click on Payroll and select Active.',
                                            boldTexts: ['Payroll', 'Active'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Open the Timesheet',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Find and click on the timesheet you want to edit the file for.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Go to the Files Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Inside the timesheet details, click on the Files tab.',
                                            boldTexts: ['Files'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '4. Edit a File',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Locate the file you want to edit and click on the blue pencil icon next to it.',
                                            boldTexts: [''],
                                            circle: true,
                                        },

                                        {
                                            title: 'Modify the Document Name or Description fields as needed.',
                                            boldTexts: ['Document Name', 'Description'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '5. Save Changes',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'After editing, click Save to apply the changes to the file.',
                                            boldTexts: ['Save'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                },
            },
            'DOWNLOAD_FILES_ACTIVE_TIME': {
                pageTitle: 'Downloading Files in Active Timesheets',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Payroll > Active from the left pane.',
                                            boldTexts: ['Payroll', 'Active'],
                                        },

                                        {
                                            title: '2. Click on the Timesheet ID to open its details.',
                                            boldTexts: ['Timesheet ID'],
                                        },

                                        {
                                            title: '3. Go to the Files tab and click the Download icon next to the file you want to download.',
                                            boldTexts: ['Files', 'Download icon'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Access the Payroll Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'From the left pane, click on Payroll and select Active.',
                                            boldTexts: ['Payroll', 'Active'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Open the Timesheet',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on the timesheet from which you want to download the file.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Go to the Files Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Inside the timesheet details, click on the Files tab.',
                                            boldTexts: ['Files'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '4. Download a File',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on the download icon next to the file you want to download. The file will begin downloading to your device.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                },
            },
            'ADD_FILES_ACTIVE_TIME': {
                pageTitle: 'Adding Files in Active Timesheets',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Payroll > Active from the left pane.',
                                            boldTexts: ['Payroll', 'Active'],
                                        },

                                        {
                                            title: '2. Click on the Timesheet ID to open its details.',
                                            boldTexts: ['Timesheet ID'],
                                        },

                                        {
                                            title: '3. Go to the Files tab and click the Upload Document button.',
                                            boldTexts: ['Files', 'Upload Document'],
                                        },

                                        {
                                            title: '4. Fill in the Document Name and Description, then select the file to upload.',
                                            boldTexts: ['Document Name', 'Description'],
                                        },

                                        {
                                            title: '5. Click Upload to add the file to the timesheet.',
                                            boldTexts: ['Upload'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Access the Payroll Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'From the left pane, click on Payroll and select Active.',
                                            boldTexts: ['Payroll', 'Active'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Open the Timesheet',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on the timesheet for which you want to add a new file.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Go to the Files Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Inside the timesheet details, click on the Files tab.',
                                            boldTexts: ['Files'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '4. Upload a New File',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click the Upload Document button located in the top-right corner of the Files tab.',
                                            boldTexts: ['Files', 'Upload Document'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '5. Fill in File Details',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Select the file to upload from your device.',
                                            boldTexts: [''],
                                            circle: true,
                                        },

                                        {
                                            title: 'Provide a Document Name and a Description for the file.',
                                            boldTexts: ['Document Name', 'Description'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '6. Submit the File',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once everything is filled in, click Upload to add the file to the timesheet.',
                                            boldTexts: ['Upload'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                    ],
                },
            },
            'DELETE_FILES_ACTIVE_TIME': {
                pageTitle: 'Deleting Files in Active Timesheets',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Payroll > Active from the left pane.',
                                            boldTexts: ['Payroll', 'Active'],
                                        },

                                        {
                                            title: '2. Click on the Timesheet ID to open its details.',
                                            boldTexts: ['Timesheet ID'],
                                        },

                                        {
                                            title: '3. Go to the Files tab and click the Trash Icon next to the file you want to delete.',
                                            boldTexts: ['Files', 'Trash Icon'],
                                        },

                                        {
                                            title: '4. Confirm the deletion in the modal.',
                                            boldTexts: [''],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Access the Payroll Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'From the left pane, click on Payroll and select Active.',
                                            boldTexts: ['Payroll', 'Active'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Open the Timesheet',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Find and click on the timesheet from which you want to delete the file.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Go to the Files Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Inside the timesheet details, click on the Files tab.',
                                            boldTexts: ['Files'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '4. Delete a File',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on the trash bin icon next to the file you want to delete.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '5. Confirm Deletion',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'A confirmation prompt will appear. Click Delete to confirm the action.',
                                            boldTexts: ['Delete'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                },
            },
            'ADD_TIME_PAYROLL_PAYCODES': {
                pageTitle: 'Adding Timesheets in Payroll from Paycodes',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Payroll > Active from the left pane.',
                                            boldTexts: ['Payroll', 'Active'],
                                        },

                                        {
                                            title: '2. Click on the Add Timesheet button and select From Paycodes.',
                                            boldTexts: ['Add Timesheet', 'From Paycodes'],
                                        },

                                        {
                                            title: '3. Fill in the fields: Staff Member, Paycode, Amount, Description, Hours and Minutes, and Timesheet Date.',
                                            boldTexts: ['Staff Member', 'Paycode', 'Amount', 'Description', 'Hours and Minutes', 'Timesheet Date'],
                                        },

                                        {
                                            title: '4. Click Add to save the timesheet.',
                                            boldTexts: ['Add'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to Payroll',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Payroll section in the left pane and click on Active.',
                                            boldTexts: ['Payroll', 'Active'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Open the Add Timesheet Modal',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on the blue Add Timesheet button at the top right.',
                                            boldTexts: ['Add Timesheet'],
                                            circle: true,
                                        },

                                        {
                                            title: 'A modal will appear with two options: Fixed Pay and From Paycodes.',
                                            boldTexts: ['Fixed Pay', 'From Paycodes'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Select From Paycodes to proceed.',
                                            boldTexts: ['From Paycodes'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Enter Timesheet Details',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'A modal titled Add Paycode Timesheet will appear. Fill in the following fields:',
                                            boldTexts: ['Files'],
                                            circle: true,

                                            list: [
                                                {
                                                    title: 'Staff Member: Choose the staff member for whom you are adding the timesheet.',
                                                    boldTexts: ['Staff Member'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Paycode: Select the appropriate paycode for the work done (e.g., BI Hourly, BCBA Hourly, etc.).',
                                                    boldTexts: ['Paycode'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Amount: The amount field will automatically populate based on the paycode selected, but you can edit it if necessary.',
                                                    boldTexts: ['Amount'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Description: Provide a brief description of the work or timesheet.',
                                                    boldTexts: ['Description'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Hours and Minutes: Input the hours and minutes worked for the timesheet.',
                                                    boldTexts: ['Hours and Minutes'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Timesheet Date: Select the date for the timesheet entry.',
                                                    boldTexts: ['Timesheet Date'],
                                                    circle: true,
                                                },
                                            ],
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '4. Save the Timesheet',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'After entering all the required details, click the blue Add button to save the timesheet.',
                                            boldTexts: ['Add'],
                                            circle: true,
                                        },

                                        {
                                            title: 'The timesheet will now appear in the Active timesheets list.',
                                            boldTexts: ['Active'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                },
            },
            'DOWNLOAD_CSV_ACTIVE_TIME': {
                pageTitle: 'Downloading a CSV of Active Timesheets',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Payroll > Active from the left pane.',
                                            boldTexts: ['Payroll', 'Active'],
                                        },

                                        {
                                            title: '2. Click the cloud icon at the top-right of the page to open the Download Payroll CSV modal.',
                                            boldTexts: ['cloud icon', 'Download Payroll CSV'],
                                        },

                                        {
                                            title: '3. Select filters like Staff and Date Range (optional).',
                                            boldTexts: ['Staff', 'Date Range'],
                                        },

                                        {
                                            title: '4. Click Download CSV to generate and download the file.',
                                            boldTexts: ['Staff', 'Date Range'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Payroll Section:',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the left-side pane, click Payroll.',
                                            boldTexts: ['Payroll'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Select the Active Section:',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'From the sub-sections under Payroll, click on Active.',
                                            boldTexts: ['Payroll', 'Active'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Open the CSV Download Modal:',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click the cloud icon with an arrow pointing down located at the top-right of the page, next to the Add Timesheet button.',
                                            boldTexts: ['cloud icon', 'Add Timesheet'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '4. Select Filters (Optional):',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Download Payroll CSV modal that appears, you can choose the following:',
                                            boldTexts: ['Download Payroll CSV'],
                                            circle: true,

                                            list: [
                                                {
                                                    title: 'Staff: Select one or multiple staff members to limit the download to their timesheets. You can leave this field blank to include all staff.',
                                                    boldTexts: ['Staff'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Start Date/End Date: Specify the date range of the timesheets to download. If left blank, all timesheets will be downloaded.',
                                                    boldTexts: ['Start Date', 'End Date'],
                                                    circle: true,
                                                },
                                            ],
                                        },

                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '5. Download the CSV:',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once your selections are made, click Download CSV.',
                                            boldTexts: ['Download CSV'],
                                            circle: true,
                                        },

                                        {
                                            title: 'A CSV file containing the selected timesheets will be downloaded to your device.',
                                            boldTexts: ['cloud icon', 'Add Timesheet'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                },
            },
        },
        'PROCESSED_PAYROLL': {
            'VIEW_PROCESSED_PAYROLL_TIMESHEETS': {
                pageTitle: 'Viewing Timesheets Within a Processed Payroll Batch',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Payroll > Processed from the left pane.',
                                            boldTexts: ['Payroll', 'Processed'],
                                        },

                                        {
                                            title: '2. Locate the payroll batch you want to view.',
                                            boldTexts: ['Payroll', 'Processed'],
                                        },

                                        {
                                            title: '3. Click the arrow icon to expand and view the processed timesheets in that batch.',
                                            boldTexts: ['arrow icon'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Payroll Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the left sidebar, click on Payroll.',
                                            boldTexts: ['Payroll'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Access the Processed Payroll Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Under Payroll, click on Processed to view the list of processed payroll batches.',
                                            boldTexts: ['Payroll', 'Processed'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Select a Payroll Batch',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Locate the batch you want to view and click the arrow icon on the far right of the batch row to expand it.',
                                            boldTexts: ['arrow icon'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '4. View Processed Timesheets',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'The row will expand, displaying all the timesheets that have been processed within that batch,' +
                                                ' along with details such as the Timesheet ID, Date, Appt ID, Staff Name, Type, Paycode, CPT Code, Rate, and Total AMT.',
                                            boldTexts: ['Timesheet ID', 'Date, Appt ID', 'Staff Name', 'Type, Paycode', 'CPT Code', 'Rate', 'Total AMT'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                },
            },
            'DOWNLOAD_PROCESSED_PAYROLL': {
                pageTitle: 'Downloading the CSV for a Processed Payroll Batch',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Payroll > Processed from the left pane.',
                                            boldTexts: ['Payroll', 'Processed'],
                                        },

                                        {
                                            title: '2. Locate the payroll batch and click the Download link next to the batch ID.',
                                            boldTexts: ['Download link'],
                                        },

                                        {
                                            title: '3. The CSV file will be downloaded with the processed timesheets.',
                                            boldTexts: [''],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Payroll Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the left sidebar, click on Payroll.',
                                            boldTexts: ['Payroll'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Access the Processed Payroll Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Under Payroll, click on Processed to view the list of processed payroll batches.',
                                            boldTexts: ['Payroll', 'Processed'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Locate the Payroll Batch',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Find the specific batch you want to download the CSV for.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '4. Download CSV for the Batch',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Batch CSV column, click the Download link next to the batch ID. This will immediately download' +
                                                ' the processed payroll timesheets for that specific batch as a CSV file to your local device.',
                                            boldTexts: ['Batch CSV', 'Download'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                },
            },
        },
    },
    'SCHEDULE': {
        'VIEW_SCHEDULE_IN_LIST': {
            pageTitle: 'Viewing the Schedule in List View or Calendar View',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Click on the Schedule option in the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. At the top of the Schedule page, select either the List View icon or the Calendar View icon.',
                                        boldTexts: ['List View', 'Calendar View'],
                                    },

                                    {
                                        title: '3. Click the List View icon to see the schedule in a list.',
                                        boldTexts: ['List View'],
                                    },

                                    {
                                        title: '4. Click the Calendar View icon to view the schedule in a calendar format.',
                                        boldTexts: ['Calendar View'],
                                    },

                                    {
                                        title: '5. Use the navigation arrows to move between days, weeks, or months.',
                                        boldTexts: [''],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Schedule Section:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Schedule option in the left pane to navigate to the schedule section.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Choose the View Type:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'At the top of the Schedule page, you will see two icons next to the Filters button:',
                                        boldTexts: ['Filters'],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'List View Icon: Displays the schedule in a list format, showing appointments in a table with columns for ID, Date/Time, Staff, Client, Event, Status, and Actions.',
                                                boldTexts: ['List View Icon'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Calendar View Icon: Displays the schedule in a calendar format, organizing appointments by date and time visually.',
                                                boldTexts: ['Calendar View Icon'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Switch Between Views:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the List View Icon to see the schedule as a list (this is the default view).',
                                        boldTexts: ['List View Icon'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click on the Calendar View Icon to view the schedule in a calendar format.',
                                        boldTexts: ['Calendar View Icon'],
                                        circle: true,
                                    },

                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Interacting with the Schedule:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In List View, you can see more details for each appointment, such as the appointment\'s ID, date, time, staff, client, and status.',
                                        boldTexts: ['List View'],
                                        circle: true,
                                    },

                                    {
                                        title: 'In Calendar View, you can see the appointments distributed across the days of the selected day, week or month.',
                                        boldTexts: ['In Calendar View'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Navigation',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Use the navigation arrows next to the date range at the top to move between different days, weeks or months in the Calendar View.',
                                        boldTexts: ['Calendar View'],
                                        circle: true,
                                    },

                                    {
                                        title: 'You can also filter appointments using the Filters button to customize what is displayed in either view.',
                                        boldTexts: ['Filters'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'view_APPOINTMENT_DETAILS_SCHEDULE_VIEW': {
            pageTitle: 'Viewing Appointment Details from the Schedule View',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to the Schedule section in the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Click on the appointment to open its details.',
                                        boldTexts: [''],
                                    },

                                    {
                                        title: '3. Review details such as date, time, participants, and status.',
                                        boldTexts: [''],
                                    },

                                    {
                                        title: '4. Click Edit to make changes, then click Save or Exit.',
                                        boldTexts: ['Edit', 'Save', 'Exit'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Navigate to the Schedule Section:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the Schedule section in the left pane.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Appointment:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the calendar, find the appointment you wish to view.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click on the appointment to open its details.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. View Appointment Details:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The appointment details page will display relevant information about the appointment, such as date, time, participants, and status.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'You may also see additional notes or attachments related to the appointment.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Edit or Update the Appointment:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'If changes are needed, click the Edit button to modify appointment details.',
                                        boldTexts: ['Edit'],
                                        circle: true,
                                    },

                                    {
                                        title: 'After making changes, click Save to update the appointment, or click Exit to leave without saving.',
                                        boldTexts: ['Save', 'Exit'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'STEPS_TO_ADD_SERVICE_APPOINT': {
            pageTitle: 'Steps to Add a Service Appointment',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Click on the Schedule tab in the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Click the Add Appointment button at the top right.',
                                        boldTexts: ['Add Appointment'],
                                    },

                                    {
                                        title: '3. Select Service Appointment from the modal.',
                                        boldTexts: ['Service Appointment'],
                                    },

                                    {
                                        title: '4. Fill out the required fields (Client, Staff, Authorization, etc.).',
                                        boldTexts: [''],
                                    },

                                    {
                                        title: '5. Optionally add a link or toggle Require Signature and Require Notes.',
                                        boldTexts: ['Require Signature', 'Require Notes'],
                                    },

                                    {
                                        title: '6. Click Add to create the appointment.',
                                        boldTexts: ['Add'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Navigate to Schedule View:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Schedule tab located in the left pane to access the schedule view.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Open Add Appointment Modal:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Add Appointment button located at the top right corner of the schedule view.',
                                        boldTexts: ['Add Appointment'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Select Event Type:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'A modal labeled Select the Event Type will appear. Choose Service Appointment from the list of event types.',
                                        boldTexts: ['Select the Event Type', 'Service Appointment'],
                                        circle: true,
                                    },

                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Fill in Appointment Details:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Add Service Appointment modal, fill out the necessary fields:',
                                        boldTexts: ['Add Service Appointment'],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Client: Select the client from the dropdown list.',
                                                boldTexts: ['Client'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Authorization: Select the authorization for the service appointment.',
                                                boldTexts: ['Authorization'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Service: Select the service to be provided.',
                                                boldTexts: ['Service'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Type: Select the type of service from the dropdown options.',
                                                boldTexts: ['Type'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Place of Service: Choose the location where the service will be provided.',
                                                boldTexts: ['Place of Service'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Staff List: Choose the staff member who will be providing the service.',
                                                boldTexts: ['Staff List'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Staff Paycode: Choose the paycode for the staff member.',
                                                boldTexts: ['Staff Paycode'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Start Date: Select the date when the service appointment will take place.',
                                                boldTexts: ['Start Date'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Start Time: Enter the start time of the service appointment.',
                                                boldTexts: ['Start Time'],
                                                circle: true,
                                            },

                                            {
                                                title: 'End Time: Enter the end time of the service appointment.',
                                                boldTexts: ['End Time'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Optional: Require Signature and Notes:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Toggle the Require Signature and Require Notes switches if the appointment needs a signature or additional notes.',
                                        boldTexts: ['Require Signature', 'Require Notes'],
                                        circle: true,
                                    },

                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '6. Add the Appointment:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Once all details are filled in, click the Add button to create the service appointment.',
                                        boldTexts: ['Add'],
                                        circle: true,
                                    },

                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '7. View the Newly Added Appointment:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The appointment will now appear in the schedule view. You can see it either in the List View or the Calendar View according to the selected date and time.',
                                        boldTexts: ['List View', 'Calendar View'],
                                        circle: true,
                                    },

                                ],
                            },

                        ],
                    },
                ],
            },
        },
        'ADD_MULTIPLE_SERVICE_APPOINT': {
            subMenuTitle: 'Adding Multiple Service Appointments (Series)',
            pageTitle: 'To add multiple service appointments (a series of appointments) for a client, follow these steps:',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Click on the Schedule option in the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Click the Add Appointment button.',
                                        boldTexts: ['Add Appointment'],
                                    },

                                    {
                                        title: '3. Select Service Appointment and toggle on Create Multiple.',
                                        boldTexts: ['Service Appointment', 'Create Multiple'],
                                    },

                                    {
                                        title: '4. Fill in the necessary fields.',
                                        boldTexts: [''],
                                    },

                                    {
                                        title: '5. Click on Repeat Pattern to set the series’ frequency (Daily, Weekly, Monthly).',
                                        boldTexts: ['Repeat Pattern'],
                                    },

                                    {
                                        title: '6. Review the number of occurrences and click Add X Appointments.',
                                        boldTexts: ['Add X Appointments'],
                                    },

                                    {
                                        title: '7. Handle any overlapping appointments if necessary.',
                                        boldTexts: [''],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Navigate to the Schedule Section:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Schedule option from the left-hand side menu.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '2. Open the Add Appointment Modal:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Add Appointment button in the upper right corner.',
                                        boldTexts: ['Add Appointment'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Select the Event Type:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'A modal labeled "Select the Event Type" will pop up.',
                                        boldTexts: ['Select the Event Type'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Choose Service Appointment to proceed.',
                                        boldTexts: ['Service Appointment'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Activate the Create Multiple Toggle:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Add Service Appointment (Series) modal, toggle on the Create Multiple option at the' +
                                            ' top of the screen. This will switch the modal to the series mode where you can create recurring appointments.',
                                        boldTexts: ['Add Service Appointment', 'Series', 'Create Multiple'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Fill in Appointment Details:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Client: Select the client from the dropdown menu.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Authorization: Choose the relevant authorization for the client.',
                                        boldTexts: ['Authorization'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Service: Select the service you want to schedule.',
                                        boldTexts: ['Service'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Type: Choose the type of service (e.g., Direct, Indirect).',
                                        boldTexts: ['Type'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Place of Service: Select where the service will take place.',
                                        boldTexts: ['Place of Service'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Staff List: Choose the staff member who will conduct the appointment.',
                                        boldTexts: ['Staff List'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Staff Paycode: Select the appropriate paycode for the staff.',
                                        boldTexts: ['Staff Paycode'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Credentials: This will be automatically displayed based on the selected staff.',
                                        boldTexts: ['Credentials'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Signature/Notes Requirements: Toggle on if the appointment requires a signature or notes.',
                                        boldTexts: ['Signature/Notes Requirements'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        subTitle: '6. Set the Repeat Pattern:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'After filling in the appointment details, click on Repeat Pattern.',
                                        boldTexts: ['Repeat Pattern'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Date Range: Select the start and end date for the series.',
                                        boldTexts: ['Date Range'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Time: Set the start and end time for each appointment.',
                                        boldTexts: ['Time'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. Select Recurrence Mode:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Daily: Set up appointments to recur every day or every weekday.',
                                        boldTexts: ['Daily'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Weekly: Set up appointments to recur on specific days of the week. Define the interval in weeks.',
                                        boldTexts: ['Weekly'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Monthly: Set up appointments to recur on a specific day of every month.',
                                        boldTexts: ['Monthly'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '8. Confirm and Review Appointments:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The modal will display the total number of occurrences (appointments) based on the selected pattern.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click Continue to proceed.',
                                        boldTexts: ['Continue'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '9. Review Overlapping Appointments (if any):',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'If there are any overlapping appointments in the selected pattern, a Confirmation modal will appear.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'You can either Confirm to skip the overlapping ones or go back and adjust the schedule.',
                                        boldTexts: ['Continue'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '10. Add Appointments:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Once you are satisfied with the details and the number of appointments to be created, ' +
                                            'click the Add X Appointments button (where X is the number of non-overlapping appointments).',
                                        boldTexts: ['Add X Appointments'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '11. View Scheduled Appointments:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'After successfully creating the series of appointments, you can view them in both the List View and Calendar View under the Schedule section.',
                                        boldTexts: ['List View', 'Calendar View', 'Schedule'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'ADD_STAFF_APPOINT_SINGLE': {
            pageTitle: 'Adding a Staff Appointment (single)',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Click on the Schedule tab in the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Click the Add Appointment button.',
                                        boldTexts: ['Add Appointment'],
                                    },

                                    {
                                        title: '3. Select Staff Appointment from the modal.',
                                        boldTexts: ['Staff Appointment'],
                                    },

                                    {
                                        title: '4. Fill in details like the Staff Member and Staff Paycode.',
                                        boldTexts: ['Staff Member', 'Staff Paycode'],
                                    },

                                    {
                                        title: '5. Click Add to create the appointment.',
                                        boldTexts: ['Add'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Navigate to the Schedule',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the left-hand navigation pane, click on Schedule.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Click on Add Appointment',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'At the top right corner, click the Add Appointment button.',
                                        boldTexts: ['Add Appointment'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Select Event Type:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'A modal will appear. Select Staff Appointment from the available options.',
                                        boldTexts: ['Staff Appointment'],
                                        circle: true,
                                    },

                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Fill in Appointment Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Staff Member: Select the staff member from the dropdown list.',
                                        boldTexts: ['Staff Member'],
                                        circle: true,

                                    },

                                    {
                                        title: 'Staff Paycode: Choose the paycode that applies for this appointment.',
                                        boldTexts: ['Staff Paycode'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Start Date: Enter the start date for the appointment.',
                                        boldTexts: ['Start Date'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Start Time and End Time: Enter the start and end time for the appointment.',
                                        boldTexts: ['Start Time and End Time'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Click Add',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Once all details are filled in, click the Add button to create the staff appointment.',
                                        boldTexts: ['Add'],
                                        circle: true,
                                    },

                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '6. View the Appointment',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The appointment will now be visible in the schedule list or calendar view.',
                                        boldTexts: ['Add'],
                                        circle: true,
                                    },

                                ],
                            },

                        ],
                    },
                ],
            },
        },
        'ADD_MULTIPLE_STAFF_APPOINT': {
            pageTitle: 'Adding Multiple Staff Appointments',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Click on the Schedule tab in the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Click the Add Appointment button.',
                                        boldTexts: ['Add Appointment'],
                                    },

                                    {
                                        title: '3. Select Staff Appointment and toggle on Create Multiple.',
                                        boldTexts: ['Staff Appointment', 'Create Multiple'],
                                    },

                                    {
                                        title: '4. Fill in the necessary fields and click Continue.',
                                        boldTexts: ['Continue'],
                                    },

                                    {
                                        title: '5. Set the repeat pattern and click Add X Appointments.',
                                        boldTexts: ['Add X Appointments'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Navigate to the Schedule:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the left-hand navigation pane, click on Schedule.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Click on Add Appointment:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'At the top right corner, click the Add Appointment button.',
                                        boldTexts: ['Add Appointment'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Select Event Type:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'A modal will appear. Select Staff Appointment from the available options.',
                                        boldTexts: ['Staff Appointment'],
                                        circle: true,
                                    },

                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Enable Create Multiple:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Toggle the Create Multiple option to ON.',
                                        boldTexts: ['Create Multiple'],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Fill in Appointment Details:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Staff Member: Select the staff member from the dropdown list.',
                                        boldTexts: ['Staff Member'],
                                    },

                                    {
                                        title: 'Staff Paycode: Choose the paycode that applies for this appointment.',
                                        boldTexts: ['Staff Paycode'],
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '6. Click Continue:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Once all details are filled in, click the Continue button.',
                                        boldTexts: ['Continue'],
                                        circle: true,
                                    },

                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. Set Repeat Pattern:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Date Range: Set the start and end dates for the recurring appointments.',
                                        boldTexts: ['Date Range'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Start Time and End Time: Enter the start and end time for each appointment.',
                                        boldTexts: ['Start Time', 'End Time'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Mode: Choose the recurrence mode - Daily, Weekly, or Monthly.',
                                        boldTexts: ['Mode'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Patterns:',
                                        boldTexts: ['Patterns'],
                                        circle: true,
                                        list: [
                                            {
                                                title: 'For Daily, set how many days it should recur.',
                                                boldTexts: ['Daily'],
                                                circle: true,
                                            },

                                            {
                                                title: 'For Weekly, select the days of the week and the interval of weeks.',
                                                boldTexts: ['Weekly'],
                                                circle: true,
                                            },

                                            {
                                                title: 'For Monthly, select the day and the interval of months.',
                                                boldTexts: ['Monthly'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '8. Add Appointments:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Review the recurrence settings, and click the Add X Appointments button.',
                                        boldTexts: ['Add X Appointments'],
                                        circle: true,
                                    },

                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '9. Handle Overlapping Schedules:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'If there are overlapping schedules, a confirmation screen will appear' +
                                            ' indicating which appointments overlap. Confirm to proceed without adding the overlapping ones.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '10. View the Appointments:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The appointments will now be visible in the schedule list or calendar view.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'ADD_SICK_TIME_APPOINT_SINGLE': {
            pageTitle: 'Adding a Sick Time Appointment (Single Appointment)',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1 Go to Schedule from the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Click the Add Appointment button.',
                                        boldTexts: ['Add Appointment'],
                                    },

                                    {
                                        title: '3. Select Sick Time from the modal.',
                                        boldTexts: ['Sick Time'],
                                    },

                                    {
                                        title: '4. Fill in the required fields and click Add to create the appointment.',
                                        boldTexts: ['Add'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Navigate to the Schedule Section:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on "Schedule" from the left-side navigation pane.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Open the \'Add Appointment\' Modal:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the "Add Appointment" button located in the top right corner of the schedule view.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Select \'Sick Time\' Event Type:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the "Select the Event Type" modal, choose "Sick Time" from the list.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '4. Fill in the Sick Time Appointment Details:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Staff Member: Select the staff member who is taking the sick time.',
                                        boldTexts: ['Staff Member'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Staff Paycode: Choose the appropriate pay code for this sick time.',
                                        boldTexts: ['Staff Paycode'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Start Date: Enter the start date for the sick time.',
                                        boldTexts: ['Start Date'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Start Time: Set the start time for the break.',
                                        boldTexts: ['Start Time'],
                                        circle: true,
                                    },

                                    {
                                        title: 'End Time: Set the end time for the break.',
                                        boldTexts: ['End Time'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Add the Appointment:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'After filling in all the required fields, click the Add button at the bottom right corner.',
                                        boldTexts: ['Add'],
                                        circle: true,
                                    },

                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '6. Confirmation:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The appointment will be successfully added, and you can now see it in the Schedule List or Calendar View.',
                                        boldTexts: ['Schedule List', 'Calendar View'],
                                        circle: true,
                                    },

                                ],
                            },

                        ],
                    },
                ],
            },
        },
        'ADD_SICK_TIME_APPOINT_MULTIPLE': {
            pageTitle: 'Adding Sick Time Appointments (Multiple Appointments)',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Schedule from the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Click the Add Appointment button.',
                                        boldTexts: ['Add Appointmen'],
                                    },

                                    {
                                        title: '3. Select Sick Time and toggle on Create Multiple.',
                                        boldTexts: ['Sick Time', 'Create Multiple'],
                                    },

                                    {
                                        title: '4. Fill in the details and set the repeat pattern.',
                                        boldTexts: [''],
                                    },

                                    {
                                        title: '5. Click Add X Appointments to create the series.',
                                        boldTexts: ['Add X Appointments'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Navigate to the Schedule Section:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on "Schedule" from the left-side navigation pane.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Open the \'Add Appointment\' Modal:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the "Add Appointment" button located in the top right corner of the schedule view.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Select \'Sick Time\' Event Type:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the "Select the Event Type" modal, choose "Sick Time" from the list.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Toggle \'Create Multiple\' Option:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Toggle on the "Create Multiple" switch at the top of the modal. This will change the form to the "Add Sick Time Appointment (Series)" view.',
                                        boldTexts: ['Staff Member'],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Fill in the Appointment Details:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Staff Member: Select the staff member for whom you are creating multiple sick time entries.',
                                        boldTexts: ['Staff Member'],
                                    },

                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '6. Click \'Continue\' to Define the Repeat Pattern:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'After filling in the initial details, click "Continue."',
                                        boldTexts: ['Continue'],
                                        circle: true,
                                    },

                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '7. Set the Repeat Pattern:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Date Range: Specify the start and end date for the series of sick time entries.',
                                        boldTexts: ['Date Range'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Time: Set the start and end time for each entry in the series.',
                                        boldTexts: ['Time'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Daily: Set how often it should repeat daily.',
                                        boldTexts: ['Daily'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Weekly: Choose the weeks and specific days of the week for repetition.',
                                        boldTexts: ['Weekly'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Monthly: Set the day of the month and recurrence frequency.',
                                        boldTexts: ['Monthly'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '8. Add the Appointments:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click "Add X Appointments," where "X" is the number of appointments being ' +
                                            'created. A confirmation modal will appear if any appointments overlap with existing schedules.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '9. Confirm Overlaps:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Review any overlaps highlighted in the confirmation modal. Click "Confirm" to proceed with creating ' +
                                            'non-overlapping appointments.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '10. View the Created Appointments:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The new series of sick time appointments will be visible in the schedule list' +
                                            ' or calendar view, depending on your selection.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'STEPS_ADD_BREAK_TIME_APPOINT_SINGLE': {
            pageTitle: 'Steps to Add a Break Time Appointment (Single Appointment)',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Click on the Schedule tab from the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Click Add Appointment and select Break.',
                                        boldTexts: ['Add Appointment', 'Break'],
                                    },

                                    {
                                        title: '3. Fill in the required details and click Add.',
                                        boldTexts: ['Add'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Navigate to the Schedule View:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'From the left pane, click on Schedule.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Open the Add Appointment Modal:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Add Appointment button located at the top right corner of the screen.',
                                        boldTexts: ['Add Appointment'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Select \'Break\' as the Event Type:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'A modal titled Select the Event Type will appear.',
                                        boldTexts: ['Select the Event Type'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click on Break to proceed to the \'Add Break Appointment\' screen.',
                                        boldTexts: ['Break'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Fill Out the Break Appointment Details:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Staff Member: Select the staff member from the dropdown.',
                                        boldTexts: ['Staff Member'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Staff Paycode: Choose the appropriate pay code from the dropdown.',
                                        boldTexts: ['Staff Paycode'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Start Date: Select the start date of the break.',
                                        boldTexts: ['tart Date'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Start Time: Set the start time for the break.',
                                        boldTexts: ['Start Time'],
                                        circle: true,
                                    },

                                    {
                                        title: 'End Time: Set the end time for the break.',
                                        boldTexts: ['End Time'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Add the Appointment:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'After filling in all the required fields, click the Add button at the bottom right corner.',
                                        boldTexts: [''],
                                    },

                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '6. Confirmation:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The appointment will be successfully added, and you can now see it in the Schedule List or Calendar View.',
                                        boldTexts: ['Schedule List', 'Calendar View'],
                                        circle: true,
                                    },

                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '7. Set the Repeat Pattern:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Date Range: Specify the start and end date for the series of sick time entries.',
                                        boldTexts: ['Date Range'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Time: Set the start and end time for each entry in the series.',
                                        boldTexts: ['Time'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Daily: Set how often it should repeat daily.',
                                        boldTexts: ['Daily'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Weekly: Choose the weeks and specific days of the week for repetition.',
                                        boldTexts: ['Weekly'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Monthly: Set the day of the month and recurrence frequency.',
                                        boldTexts: ['Monthly'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '8. Add the Appointments:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click "Add X Appointments," where "X" is the number of appointments being ' +
                                            'created. A confirmation modal will appear if any appointments overlap with existing schedules.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '9. Confirm Overlaps:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Review any overlaps highlighted in the confirmation modal. Click "Confirm" to proceed with creating ' +
                                            'non-overlapping appointments.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '10. View the Created Appointments:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The new series of sick time appointments will be visible in the schedule list' +
                                            ' or calendar view, depending on your selection.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'STEPS_ADD_MULTIPLE_BREAK_TIME_APPOINT_RECURRING': {
            pageTitle: 'Steps to Add Multiple Break Time Appointments (Recurring Appointments)',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Click on the Schedule tab from the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Click Add Appointment and select Break.',
                                        boldTexts: ['Add Appointment', 'Break'],
                                    },

                                    {
                                        title: '3. Toggle on Create Multiple and fill in the fields.',
                                        boldTexts: ['Create Multiple'],
                                    },

                                    {
                                        title: '4. Set the repeat pattern and click Add X Appointments.',
                                        boldTexts: ['Add X Appointments'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Navigate to the Schedule View:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'From the left pane, click on Schedule.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Open the Add Appointment Modal:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Add Appointment button located at the top right corner of the screen.',
                                        boldTexts: ['Add Appointment'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Select \'Break\' as the Event Type:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'A modal titled Select the Event Type will appear.',
                                        boldTexts: ['Select the Event Type'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click on Break to proceed to the \'Add Break Appointment\' screen.',
                                        boldTexts: ['Break'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Enable Multiple Appointments:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Toggle the Create Multiple switch to the ON position.',
                                        boldTexts: ['Create Multiple', 'ON'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Fill Out the Initial Break Appointment Details:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Staff Member: Select the staff member from the dropdown.',
                                        boldTexts: ['Staff Member'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Staff Paycode: Choose the appropriate pay code from the dropdown.',
                                        boldTexts: ['Staff Paycode'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '6. Proceed to the Next Step:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Continue button at the bottom right corner to proceed.',
                                        boldTexts: ['Continue'],
                                        circle: true,
                                    },

                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '7. Set the Recurrence Pattern:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Date Range: Select the start and end date for the range during which the break appointments will recur.',
                                        boldTexts: ['Date Range'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Time: Set the start and end time for the recurring break appointments.',
                                        boldTexts: ['Time'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Mode: Select the recurrence mode (Daily, Weekly, or Monthly).',
                                        boldTexts: ['Daily'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Patterns:',
                                        boldTexts: ['Patterns'],
                                        circle: true,
                                        list: [
                                            {
                                                title: 'If Daily, set the recurrence interval.',
                                                boldTexts: ['If Daily'],
                                                circle: true,
                                            },

                                            {
                                                title: 'If Weekly, select the days of the week and the recurrence interval in weeks.',
                                                boldTexts: ['If Weekly'],
                                                circle: true,
                                            },

                                            {
                                                title: 'If Monthly, set the specific day of the month and the recurrence interval in months.',
                                                boldTexts: ['If Monthly'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '8. Add the Recurring Appointments:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Review the total number of occurrences generated based on the recurrence pattern.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click Add X Appointments (where X is the number of non-overlapping appointments) at the bottom right corner.\n',
                                        boldTexts: ['Add X Appointments'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '9. Handle Overlaps (If Any):',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'If there are overlapping appointments, a confirmation screen will notify you.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'Review the conflicting appointments and click Confirm to skip those and create the remaining appointments.',
                                        boldTexts: ['Confirm'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '10. Confirmation:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The recurring break time appointments will be successfully added' +
                                            ', and you can now see them in the Schedule List or Calendar View.',
                                        boldTexts: ['Schedule List', 'Calendar View'],
                                        circle: true,
                                    },

                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'ADD_DRIVE_TIME_APPOINT_SINGLE': {
            pageTitle: 'Adding a Drive Time Appointment (single)',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Schedule from the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Click Add Appointment and select Drive Time.',
                                        boldTexts: ['Add Appointment', 'Drive Time'],
                                    },

                                    {
                                        title: '3. Fill in the required fields, including miles.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '4. Click Add to create the appointment.',
                                        boldTexts: ['Add'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Navigate to Schedule:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Schedule tab from the left sidebar.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Click on Add Appointment:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Add Appointment button located at the top-right corner of the schedule page.',
                                        boldTexts: ['Add Appointment'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Select Drive Time:',
                        circle: true,
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the pop-up modal, choose Drive Time as the event type.',
                                        boldTexts: ['Drive Time'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Fill in Appointment Details:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the "Add Drive Appointment" modal, fill in the required details:',
                                        boldTexts: ['Create Multiple', 'ON'],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Staff Member: Select the staff member from the dropdown list.',
                                                boldTexts: ['Staff Member'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Staff Paycode: Select the appropriate paycode.',
                                                boldTexts: ['Staff Paycode'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Start Date: Choose the date from the date picker.',
                                                boldTexts: ['Start Date'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Start Time: Enter the start time.',
                                                boldTexts: ['Start Time'],
                                                circle: true,
                                            },

                                            {
                                                title: 'End Time: Enter the end time.',
                                                boldTexts: ['End Time'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Miles: Enter the number of miles.',
                                                boldTexts: ['Miles'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Add Appointment:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Add button to save the appointment.',
                                        boldTexts: ['Add'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '6. Confirmation:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The new drive appointment will now appear in the schedule list or calendar view.',
                                        boldTexts: ['Continue'],
                                        circle: true,
                                    },

                                ],
                            },

                        ],
                    },
                ],
            },
        },
        'ADD_MULTIPLE_DRIVE_TIME_APPOINT': {
            pageTitle: 'Adding Multiple Drive Time Appointment',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Schedule from the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Click Add Appointment and select Drive Time.',
                                        boldTexts: ['Add Appointment', 'Drive Time'],
                                    },

                                    {
                                        title: '3. Toggle on Create Multiple and set the recurrence pattern.',
                                        boldTexts: ['Multiple'],
                                    },

                                    {
                                        title: '4. Click Add X Appointments to create the series.',
                                        boldTexts: ['Add X Appointments'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Navigate to Schedule:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the Schedule tab from the left sidebar.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Click on Add Appointment:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Add Appointment button located at the top-right corner of the schedule page.',
                                        boldTexts: ['Add Appointment'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Select Drive Time:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the pop-up modal, choose Drive Time as the event type.',
                                        boldTexts: ['Drive Time'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Enable Create Multiple Option:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Toggle the Create Multiple switch to the on position.',
                                        boldTexts: ['Create Multiple'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Fill in Appointment Details:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the "Add Drive Appointment (Series)" modal, fill in the required details:',
                                        boldTexts: [''],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Staff Member: Select the staff member from the dropdown list.',
                                                boldTexts: ['Staff Member'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Staff Paycode: Select the appropriate paycode.',
                                                boldTexts: ['Staff Paycode'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Miles: Enter the number of miles.',
                                                boldTexts: ['Miles'],
                                                circle: true,
                                            },
                                        ],
                                    },

                                    {
                                        title: 'Click Continue to proceed.',
                                        boldTexts: ['Click'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Set Repeat Pattern:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the next modal, set the repeat pattern:',
                                        boldTexts: ['Continue'],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Date Range: Choose the start and end dates.',
                                                boldTexts: ['Date Range'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Time: Enter the start and end times.',
                                                boldTexts: ['Time'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Mode: Select the recurrence mode (Daily, Weekly, Monthly).',
                                                boldTexts: ['Mode'],
                                                circle: true,

                                                list: [
                                                    {
                                                        title: 'For Daily mode: Enter the number of days to recur or select "Recur every weekday".',
                                                        boldTexts: ['Daily'],
                                                        circle: true,
                                                    },

                                                    {
                                                        title: 'For Weekly mode: Enter the number of weeks to recur and select the days of the week.',
                                                        boldTexts: ['Weekly'],
                                                        circle: true,
                                                    },

                                                    {
                                                        title: 'For Monthly mode: Specify the day of the month and the number of months to recur.',
                                                        boldTexts: ['Monthly'],
                                                        circle: true,
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        title: 'The occurrence count will update based on your selected pattern.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. Add Multiple Appointments:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Add X Appointments button (where X is the number of appointments to be added).',
                                        boldTexts: ['Add X Appointments'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '8. Handle Conflicts (if any):',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'If there are overlapping schedules, a confirmation modal will appear listing the conflicts.',
                                        boldTexts: ['Add X Appointments'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click Confirm to skip the conflicting appointments or Cancel to go back and adjust.',
                                        boldTexts: ['Confirm', 'Cancel'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '9. Confirmation:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The series of drive appointments will now appear in the schedule list or calendar view.',
                                        boldTexts: ['Add X Appointments'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'ADD_PAID_TIME_APPOINT_SINGLE': {
            pageTitle: 'Adding a Paid Time Off Appointment (single)',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Schedule from the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Click Add Appointment and select Paid Time Off.',
                                        boldTexts: ['Add Appointment', 'Paid Time Off'],
                                    },

                                    {
                                        title: '3. Fill in the required details and click Add.',
                                        boldTexts: ['Add'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Navigate to the Schedule Section:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'On the left pane, click on "Schedule."',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Click Add Appointment:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'On the top right of the screen, click the "Add Appointment" button.',
                                        boldTexts: ['Add Appointment'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Select Event Type:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'From the modal that appears, select Paid Time Off.',
                                        boldTexts: ['Paid Time Off'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Enable Multiple Appointments:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Toggle the Create Multiple option to turn it on.',
                                        boldTexts: ['Create Multiple'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Fill in Appointment Details:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the "Add Drive Appointment (Series)" modal, fill in the required details:',
                                        boldTexts: [''],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Staff Member: Select the staff member who will take the paid time off.',
                                                boldTexts: ['Staff Member'],
                                            },

                                            {
                                                title: 'Staff Paycode: Choose the appropriate pay code for the appointment.',
                                                boldTexts: ['Staff Paycode'],
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Click Continue:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Move to the Repeat Pattern section.',
                                        boldTexts: ['Continue'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. Set the Repeat Pattern:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Date Range: Select the start and end date for the recurring paid time off appointments.',
                                        boldTexts: ['Date Range'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Time: Set the start and end times for the appointments.',
                                        boldTexts: ['Time'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Mode: Choose whether the appointments should repeat Daily, Weekly, or Monthly.',
                                        boldTexts: ['Mode', 'Daily', 'Weekly', 'Monthly'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Patterns: Depending on the mode selected, set the recurrence pattern:',
                                        boldTexts: ['Patterns'],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'For Daily, specify the interval in days.',
                                                boldTexts: ['Daily'],
                                                circle: true,
                                            },

                                            {
                                                title: 'For Weekly, specify the interval in weeks and select the days.',
                                                boldTexts: ['Weekly'],
                                                circle: true,
                                            },

                                            {
                                                title: 'For Monthly, set the recurrence day and interval in months.\n',
                                                boldTexts: ['Monthly'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '8. Review and Confirm:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click Add [X] Appointments. If there are overlapping schedules, a confirmation modal will appear allowing you to confirm or adjust as needed.',
                                        boldTexts: ['Add', 'X', 'Appointments'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '9. Finish:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The series of paid time off appointments will be added and can be viewed in the schedule in list or calendar view.',
                                        boldTexts: ['Add X Appointments'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'ADD_MULTIPLE_PAID_APPOINT': {
            pageTitle: 'Adding Multiple Paid Time Off Appointments',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Schedule from the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Click Add Appointment and select Paid Time Off.',
                                        boldTexts: ['Add Appointment', 'Paid Time Off'],
                                    },

                                    {
                                        title: '3. Toggle on Create Multiple and set the recurrence pattern.',
                                        boldTexts: ['Create Multiple'],
                                    },

                                    {
                                        title: '4. Click Add X Appointments to create the series.',
                                        boldTexts: ['Add X Appointments'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Navigate to the Schedule Section:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'On the left pane, click on "Schedule."',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Click Add Appointment:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'On the top right of the screen, click the "Add Appointment" button.',
                                        boldTexts: ['Add Appointment'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Select Event Type:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'From the modal that appears, select Paid Time Off.',
                                        boldTexts: ['Paid Time Off'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Enable Multiple Appointments:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Toggle the Create Multiple option to turn it on.',
                                        boldTexts: ['Create Multiple'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Fill in Appointment Details:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Staff Member: Select the staff member who will take the paid time off.',
                                        boldTexts: ['Staff Member'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Staff Paycode: Choose the appropriate pay code for the appointment.',
                                        boldTexts: ['Staff Paycode'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Click Continue:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Move to the Repeat Pattern section.',
                                        boldTexts: ['Continue'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. Set the Repeat Pattern:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Date Range: Select the start and end date for the recurring paid time off appointments.',
                                        boldTexts: ['Date Range'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Time: Set the start and end times for the appointments.',
                                        boldTexts: ['Time'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Mode: Choose whether the appointments should repeat Daily, Weekly, or Monthly.',
                                        boldTexts: ['Mode', 'Daily', 'Weekly', 'Monthly'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Patterns: Depending on the mode selected, set the recurrence pattern:',
                                        boldTexts: ['Patterns'],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'For Daily, specify the interval in days.',
                                                boldTexts: ['Daily'],
                                                circle: true,
                                            },

                                            {
                                                title: 'For Weekly, specify the interval in weeks and select the days.',
                                                boldTexts: ['Weekly'],
                                                circle: true,
                                            },

                                            {
                                                title: 'For Monthly, set the recurrence day and interval in months.',
                                                boldTexts: ['Monthly'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '8. Review and Confirm:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click Add [X] Appointments. If there are overlapping schedules, a confirmation modal will appear allowing you to confirm or adjust as needed.',
                                        boldTexts: ['Add', 'X', 'Appointments'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '9. Finish:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The series of paid time off appointments will be added and can be viewed in the schedule in list or calendar view.',
                                        boldTexts: ['Add X Appointments'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'ADD_MULTIPLE_UNPAID_TIME_APPOINT': {
            pageTitle: 'Adding Multiple Unpaid Time Off Appointments',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Schedule from the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Click Add Appointment and select Unpaid Time Off.',
                                        boldTexts: ['Add Appointment', 'Unpaid Time Off'],
                                    },

                                    {
                                        title: '3. Toggle on Create Multiple and set the recurrence pattern.',
                                        boldTexts: ['Create Multiple'],
                                    },

                                    {
                                        title: '4. Click Add X Appointments to create the series.',
                                        boldTexts: ['Add X Appointments'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Navigate to the Schedule Section:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'On the left pane, click on "Schedule."',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Click Add Appointment:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'On the top right of the screen, click the "Add Appointment" button.',
                                        boldTexts: ['Add Appointment'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Select Event Type:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'From the modal that appears, select Unpaid Time Off.',
                                        boldTexts: ['Unpaid Time Off'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Enable Multiple Appointments:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Toggle the Create Multiple option to turn it on.',
                                        boldTexts: ['Create Multiple'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Fill in Appointment Details:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Staff Member: Select the staff member who will take the unpaid time off.',
                                        boldTexts: ['Staff Member'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Staff Paycode: Choose the appropriate pay code for the appointment.',
                                        boldTexts: ['Staff Paycode'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Click Continue:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Move to the Repeat Pattern section.',
                                        boldTexts: ['Continue'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. Set the Repeat Pattern:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Date Range: Select the start and end date for the recurring unpaid time off appointments.',
                                        boldTexts: ['Date Range'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Time: Set the start and end times for the appointments.',
                                        boldTexts: ['Time'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Mode: Choose whether the appointments should repeat Daily, Weekly, or Monthly.',
                                        boldTexts: ['Mode', 'Daily', 'Weekly', 'Monthly'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Patterns: Depending on the mode selected, set the recurrence pattern:',
                                        boldTexts: ['Patterns'],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'For Daily, specify the interval in days.',
                                                boldTexts: ['Daily'],
                                                circle: true,
                                            },

                                            {
                                                title: 'For Weekly, specify the interval in weeks and select the days.',
                                                boldTexts: ['Weekly'],
                                                circle: true,
                                            },

                                            {
                                                title: 'For Monthly, set the recurrence day and interval in months.',
                                                boldTexts: ['Monthly'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '8. Review and Confirm:',
                        list: [
                            {
                                list: [
                                    {
                                        title: ' Click Add [X] Appointments. If there are overlapping schedules, a confirmation modal will appear allowing you to confirm or adjust as needed.',
                                        boldTexts: ['Add', 'X', 'Appointments'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '9. Finish:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The series of unpaid time off appointments will be added and can be viewed in the schedule in list or calendar view.',
                                        boldTexts: ['Add X Appointments'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'ADD_UNPAID_TIME_APPOINT_SINGLE': {
            pageTitle: 'Adding an Unpaid Time Off Appointment (single)',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Schedule from the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Click Add Appointment and select Unpaid Time Off.',
                                        boldTexts: ['Add Appointment', 'Unpaid Time Off'],
                                    },

                                    {
                                        title: '3. Fill in the required details and click Add.',
                                        boldTexts: ['Add'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Navigate to the Schedule Section:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'On the left pane, click on "Schedule."',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Click Add Appointment:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'On the top right of the screen, click the "Add Appointment" button.',
                                        boldTexts: ['Add Appointment'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Select Event Type:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'From the modal that appears, select Unpaid Time Off.',
                                        boldTexts: ['Unpaid Time Off'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Fill in the Details:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Staff Member: Select the staff member who will take the unpaid time off.',
                                        boldTexts: ['Staff Member'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Staff Paycode: Choose the appropriate pay code for the appointment.',
                                        boldTexts: ['Staff Paycode'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Start Date: Enter the start date for the unpaid time off.',
                                        boldTexts: ['Start Date'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Start Time: Enter the start time for the unpaid time off.',
                                        boldTexts: ['Start Time'],
                                        circle: true,
                                    },

                                    {
                                        title: 'End Time: Enter the end time for the unpaid time off.',
                                        boldTexts: ['End Time'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Save the Appointment:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the "Add" button.',
                                        boldTexts: ['Staff Member'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Confirm Appointment:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The unpaid time off appointment will now appear in the schedule in list or calendar view.',
                                        boldTexts: ['Continue'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'DOWN_SCHEDULE_CSV': {
            pageTitle: 'Downloading the Schedule as a CSV',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to the Schedule section from the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Click the Download CSV button to generate the file.',
                                        boldTexts: ['Download CSV'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Schedule Section:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the Schedule section in the left pane.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Download the CSV File',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Download CSV button located at the top right of the screen.',
                                        boldTexts: ['Download CSV'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Select filters for the CSV export:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Staff: Filter by specific Staff.',
                                        boldTexts: ['Staff'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Client: Filter by specific clients.',
                                        boldTexts: ['Client'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Event Type: Filter by specific event types.',
                                        boldTexts: ['Event Type'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Event Status: Filter by specific Event Status.',
                                        boldTexts: ['Event Status'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Start Date and End Date: Set the date range for which you want to download Appointments.',
                                        boldTexts: ['Start Date and End Date'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Flagged: Filter by specific Flags.',
                                        boldTexts: ['Flagged'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Confirm and Save:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The CSV file will be automatically generated and downloaded to your device.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Review the Downloaded File:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Open the downloaded CSV file to review the schedule details.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'LOCKING_UNLOCKING_SINGLE_APPOINT': {
            pageTitle: 'Locking/Unlocking Single Appointments',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Schedule in the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Find the appointment and click the Lock or Unlock icon.',
                                        boldTexts: ['Lock', 'Unlock'],
                                    },

                                    {
                                        title: '3. Confirm the action in the modal.',
                                        boldTexts: ['Schedule'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Schedule Section:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the Schedule section in the left pane.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Appointment',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Identify the appointment you want to lock or unlock.',
                                        boldTexts: ['Download CSV'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Lock the Appointment',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the green Unlock icon (a green lock) under the Actions column for the appointment.',
                                        boldTexts: ['Unlock', 'Actions'],
                                        circle: true,
                                    },

                                    {
                                        title: 'A Confirm Lock Action modal will appear.',
                                        boldTexts: ['Confirm Lock Action'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click Lock to confirm. The icon will change to a red lock, indicating the appointment is locked.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Unlock the Appointment',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the red Lock icon (a closed red lock) under the Actions column for the appointment.',
                                        boldTexts: ['Lock', 'Actions'],
                                        circle: true,
                                    },

                                    {
                                        title: 'A Confirm Unlock Action modal will appear.',
                                        boldTexts: ['Confirm Unlock Action'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click Unlock to confirm. The icon will change back to a green lock, indicating the appointment is unlocked.',
                                        boldTexts: ['Unlock'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Review the Downloaded File:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Open the downloaded CSV file to review the schedule details.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'LOCKING_UNLOCKING_MULTIPLE_APPOINT': {
            pageTitle: 'Locking/Unlocking Multiple Appointments',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Schedule in the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Select multiple appointments using the checkboxes.',
                                        boldTexts: [''],
                                    },

                                    {
                                        title: '3. Click Lock Selected or Unlock Selected and confirm.',
                                        boldTexts: ['Lock Selected', 'Unlock Selected'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Schedule Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the Schedule section in the left pane.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select Multiple Appointments',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'You have several options to select appointments:',
                                        boldTexts: ['Download CSV'],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Manually Select: Check the checkboxes to the left of each appointment you want to lock or unlock.',
                                                boldTexts: ['Manually Select'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Select All on Page: Click the checkbox next to the ID column title to select all appointments on the current page.',
                                                boldTexts: ['Select All on Page', 'ID'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Select Across All Pages: After clicking the checkbox to select all appointments on the page,' +
                                                    ' a message will appear above the table indicating that all appointments on the current page are selected.' +
                                                    ' You will also see an option to "Select all appointments across all pages." Click this option to select all ' +
                                                    'appointments across all pages; these appointments will be narrowed down based on the filters you have applied.',
                                                boldTexts: ['Select Across All Pages', 'Select all appointments across all pages'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Lock Selected Appointments',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Once you have selected the appointments, click the Lock Selected button (red) at the top right of the table.',
                                        boldTexts: ['Selected'],
                                        circle: true,
                                    },

                                    {
                                        title: 'A Confirm Lock Selected modal will appear.',
                                        boldTexts: ['Confirm Lock Action'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click Lock Selected to confirm. The selected appointments will be locked, and their icons will turn to red locks.',
                                        boldTexts: ['Lock Selected'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Unlock Selected Appointments',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Once you have selected the appointments, click the Unlock Selected button (green) at the top right of the table.',
                                        boldTexts: ['Unlock Selected'],
                                        circle: true,
                                    },

                                    {
                                        title: 'A Confirm Unlock Selected modal will appear.',
                                        boldTexts: ['Confirm Unlock Selected'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click Unlock Selected to confirm. The selected appointments will be unlocked, and their icons will turn back to green locks.',
                                        boldTexts: ['Unlock Selected'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Use Filters to Narrow Down Selection (Optional)',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'You can use the Filters at the top of the schedule to narrow down the list of appointments ' +
                                            'by Staff Member, Client, Event Type, Event Status, Date of Service, or Flagged status before making your selections.',
                                        boldTexts: ['Filters', 'Staff Member', 'Client', 'Event Type', 'vent Status', 'Date of Service', 'Flagged'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'RECURRING_APPOINT': {
            pageTitle: 'Recurring an Appointment',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to Schedule in the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Click the Recur Event icon next to the appointment.',
                                        boldTexts: ['Recur Event'],
                                    },

                                    {
                                        title: '3. Set the recurrence pattern and click Add X Appointments.',
                                        boldTexts: ['Add X Appointments'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Schedule Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the Schedule section in the left pane to view the list of appointments.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Click on the Recur Event Icon',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Actions column, locate the Recur Event button (circular arrow icon) for the desired appointment.',
                                        boldTexts: ['Download CSV'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Set the Recurrence Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'A Recur Event modal will pop up. Fill in the following fields',
                                        boldTexts: ['Selected'],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Date Range: Select the start and end dates for the recurrence.',
                                                boldTexts: ['Date Range'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Mode: Choose between Daily, Weekly, or Monthly recurrence patterns.',
                                                boldTexts: ['Mode'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Patterns',
                                                boldTexts: ['Patterns'],
                                                circle: true,

                                                list: [
                                                    {
                                                        title: 'Daily: Specify if the event should recur every day or every weekday.',
                                                        boldTexts: ['Daily'],
                                                        circle: true,
                                                    },

                                                    {
                                                        title: 'Weekly: Choose the day(s) of the week for the event to recur.',
                                                        boldTexts: ['Weekly'],
                                                        circle: true,
                                                    },

                                                    {
                                                        title: 'Monthly: Specify the day of the month for the recurrence.',
                                                        boldTexts: ['Monthly'],
                                                        circle: true,
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Review Occurrences',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The system will automatically calculate the number of occurrences based on your selections and display it at the bottom of the modal.',
                                        boldTexts: ['Unlock Selected'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Confirm the Recurrence',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Add [X] Appointments button to confirm the recurrence.',
                                        boldTexts: ['Add', 'X', 'Appointments'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Review Overlapping Appointments',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'If there are any conflicting appointments, a Confirmation modal will appear, listing any overlapping appointments.' +
                                            ' Review the conflicts, and if you are okay with skipping those conflicting appointments, click Confirm.',
                                        boldTexts: ['Confirmation', 'Confirm'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. Review the Recurring Appointments',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The newly created recurring appointments will now appear in the Schedule list, based on the set pattern and date range.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'DISCONNECT_APPOINT_TEMPLATE': {
            pageTitle: 'Disconnect Appointment from Template',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. In the Schedule, click the blue Link icon to disconnect the appointment from the template.',
                                        boldTexts: ['Schedule', 'Link'],
                                    },

                                    {
                                        title: '2. Confirm the disconnection in the modal.',
                                        boldTexts: ['Schedule', 'Actions'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Identify the Appointment with a Template Connection',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Schedule table, look for the blue link icon under the Actions column.' +
                                            ' This indicates that the appointment is part of a recurring template.',
                                        boldTexts: ['Schedule', 'Actions'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Initiate Disconnection',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the blue link icon. A modal titled Disconnect Appointment from Template will appear.',
                                        boldTexts: ['Disconnect Appointment from Template'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Confirm Disconnection',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Review the message in the modal. Click Disconnect to proceed. This will remove the appointment from ' +
                                            'its recurring template, making it independent. Once disconnected, the appointment cannot be reconnected to its original template.',
                                        boldTexts: ['Disconnect'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Observe Changes:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'After disconnection, the blue link icon will be replaced by the Recur Event icon. ' +
                                            'The appointment will no longer follow the recurring pattern. You can use the Recur Event button to create a new ' +
                                            'series of recurring appointments, but it will be different from the original template.',
                                        boldTexts: ['Recur Event', 'Recur Event'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                ],
            },
        },
        'CANCEL_NON_SERVICE_APPOINT': {
            pageTitle: 'Canceling a Non-Service Appointment',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Navigate to the Schedule section from the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Locate the staff appointment you wish to cancel from the list.',
                                        boldTexts: [''],
                                    },

                                    {
                                        title: '3. Click on the appointment row to open the appointment details modal.',
                                        boldTexts: ['appointment details'],
                                    },

                                    {
                                        title: '4. Click the Cancel Appointment button at the bottom right.',
                                        boldTexts: ['Cancel Appointment'],
                                    },

                                    {
                                        title: '5. A modal titled “Cancel Appointment?” will appear. Click Cancel Appointment to confirm the cancellation.',
                                        boldTexts: ['Cancel Appointment'],
                                    },

                                    {
                                        title: '6. The appointment will be marked as canceled, and its status will update in the schedule.',
                                        boldTexts: [''],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Schedule Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Navigate to the Schedule section from the left pane.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Appointment',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Locate the staff appointment you wish to cancel from the list.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Open the Appointment Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the appointment row to open the appointment details modal.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Initiate Cancellation',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the modal, click the Cancel Appointment button at the bottom right.',
                                        boldTexts: ['Cancel Appointment'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Confirm Cancellation',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'A modal titled Cancel Appointment? will appear. Click Cancel Appointment to confirm the cancellation.',
                                        boldTexts: ['Cancel Appointment'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Review Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The appointment will be marked as canceled, and its status will update in the schedule.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'COMPLETING_NON_SERVICE_APPOINT': {
            pageTitle: 'Completing a Non-Service Appointment',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Navigate to the Schedule section from the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Find the staff appointment you want to complete.',
                                        boldTexts: [''],
                                    },

                                    {
                                        title: '3. Click the appointment row to open its details in the modal.',
                                        boldTexts: ['details'],
                                    },

                                    {
                                        title: '4. Click the blue Complete Appointment button at the bottom right of the modal.',
                                        boldTexts: ['Complete Appointment'],
                                    },

                                    {
                                        title: '5. A modal titled "Complete This Appointment?" will appear. Click Complete Appointment to confirm.',
                                        boldTexts: ['Complete This Appointment'],
                                    },

                                    {
                                        title: '6. The appointment will now be marked as Completed in the schedule.',
                                        boldTexts: ['Completed'],
                                    },

                                    {
                                        title: '7. Timesheets for the completed appointment are automatically created. Go to Payroll > Active to view them.',
                                        boldTexts: ['Timesheets', 'Payroll', 'Active'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Schedule Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Navigate to the Schedule section from the left pane.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Appointment',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Find the staff appointment you want to complete.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Open the Appointment Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the appointment row to open its details in the modal.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Initiate Completion',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the blue Complete Appointment button at the bottom right of the modal.',
                                        boldTexts: ['Cancel Appointment'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Confirm Completion',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'A modal titled Complete This Appointment? will appear. Click Complete Appointment to confirm.',
                                        boldTexts: ['This Appointment', 'Complete Appointment'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Review Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The appointment will now be marked as Completed in the schedule.',
                                        boldTexts: ['Completed'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. View Created Timesheets',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Timesheets for the completed appointment are automatically created. Go to Payroll > Active to view them.',
                                        boldTexts: ['Payroll', 'Active'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'RENDER_SERVICE_APPOINT': {
            pageTitle: 'Rendering a Service Appointment',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to the Schedule section in the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Locate the service appointment you want to render from the list.',
                                        boldTexts: ['appointment'],
                                    },

                                    {
                                        title: '3. Click on the appointment row to open the details in the modal.',
                                        boldTexts: ['details'],
                                    },

                                    {
                                        title: '4. Click the blue Render Appointment button at the bottom right of the modal.',
                                        boldTexts: ['Render Appointment'],
                                    },

                                    {
                                        title: '5. A modal titled “Render This Appointment?” will appear. Click Render Appointment to confirm.',
                                        boldTexts: ['Render This Appointment', 'Render Appointment'],
                                    },

                                    {
                                        title: '6. The appointment will be marked as Rendered in the schedule.',
                                        boldTexts: ['Rendered'],
                                    },

                                    {
                                        title: '7. After rendering, a timesheet will be created automatically. You can find this timesheet in Payroll > Active.',
                                        boldTexts: ['timesheet', 'Payroll', 'Active'],
                                    },

                                    {
                                        title: '8. Navigate to Billing > Pending Claims > Prepare Claims to include this rendered appointment in a claim.',
                                        boldTexts: ['Billing', 'Pending Claims', 'Prepare Claims'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Schedule Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the Schedule section in the left pane.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Appointment',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Locate the service appointment you want to render from the list.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Open the Appointment Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click on the appointment row to open the details in the modal.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Initiate Rendering',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the blue Render Appointment button at the bottom right of the modal.',
                                        boldTexts: ['Render Appointment'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Confirm Rendering',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'A modal titled Render This Appointment? will appear. Click Render Appointment to confirm.',
                                        boldTexts: ['Render This Appointment', 'Render Appointment'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Review Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The appointment will be marked as Rendered in the schedule.',
                                        boldTexts: ['Rendered'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. View Timesheets',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'After rendering, a timesheet will be created automatically. You can find this timesheet in Payroll > Active.',
                                        boldTexts: ['Payroll', 'Active'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '8. Prepare Claims for Rendered Appointment',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Navigate to Billing > Pending Claims > Prepare Claims to include this rendered appointment in a claim.',
                                        boldTexts: ['Billing', 'Pending Claim', 'Prepare Claims'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'UN_RENDER_SERVICE_APPOINT': {
            pageTitle: 'Un-rendering a Service Appointment',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to the Schedule section in the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Select the rendered appointment you want to un-render from the list.',
                                        boldTexts: ['rendered appointment'],
                                    },

                                    {
                                        title: '3. Click the appointment row to open its details in the modal.',
                                        boldTexts: ['details'],
                                    },

                                    {
                                        title: '4. Click the Un-Render Appointment button at the bottom right of the modal.',
                                        boldTexts: ['Un-Render Appointment'],
                                    },

                                    {
                                        title: '5. A modal titled Un-render Appointment will appear. Click Un-Render to confirm.',
                                        boldTexts: ['Un-render Appointment'],
                                    },

                                    {
                                        title: '6. Active Timesheets linked to this appointment will be deleted, and Processed Timesheets will be flagged and require resolution.',
                                        boldTexts: ['Active Timesheets ', 'Processed Timesheets'],
                                    },

                                    {
                                        title: '7. Processed Claims associated with the appointment will be flagged and require attention.',
                                        boldTexts: ['Processed Claims'],
                                    },

                                    {
                                        title: '8. Go to the relevant sections such as Payroll or Billing to resolve any flagged timesheets or claims.',
                                        boldTexts: ['Payroll', 'Billing'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Schedule Section',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the Schedule section in the left pane.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Rendered Appointment',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Find the rendered appointment you want to un-render from the list.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Open the Appointment Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the appointment row to open its details in the modal.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Initiate Un-rendering',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Un-Render Appointment button at the bottom right of the modal.',
                                        boldTexts: ['Un-Render Appointment'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Confirm Un-rendering',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'A modal titled Un-render Appointment will appear. Click Un-Render to confirm.',
                                        boldTexts: ['Un-render Appointment', 'Un-Render'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Impact on Timesheets and Claims',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Active Timesheets: Any active timesheets linked to this appointment will be deleted.',
                                        boldTexts: ['Active Timesheets'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Processed Timesheets: Processed timesheets will be flagged and require resolution.',
                                        boldTexts: ['Processed Timesheets'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Processed Claims: Claims associated with the appointment will be flagged and require attention.',
                                        boldTexts: ['Processed Claims'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. Resolve Flags',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'After un-rendering, go to the relevant sections such as Payroll or Billing to resolve any flagged timesheets or claims.',
                                        boldTexts: ['Payroll', 'Billing'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'UPLOAD_SIGNATURE_SERVICE_APPOINT': {
            pageTitle: 'Uploading a Signature on a Service Appointment',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Navigate to the Schedule section on the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Locate and click on the service appointment where you want to upload a signature. This will open the appointment details modal.',
                                        boldTexts: ['service appointment', 'appointment details'],
                                    },

                                    {
                                        title: '3. In the appointment details modal, locate the Upload Signature button at the top left of the modal (next to the Manage Notes button).',
                                        boldTexts: ['Upload Signature', 'Manage Notes'],
                                    },

                                    {
                                        title: '4. Click on Upload Signature. The Upload Signature modal will appear.',
                                        boldTexts: ['Upload Signature'],
                                    },

                                    {
                                        title: '5. Click Upload Signature again to choose a file from your device.',
                                        boldTexts: ['Upload Signature'],
                                    },

                                    {
                                        title: '6. After the file is uploaded, its name will appear in the modal, confirming the upload was successful. Click Done to confirm.',
                                        boldTexts: ['Done'],
                                    },

                                    {
                                        title: '7. The uploaded signature will be listed under Signature Details in the appointment section. You can download or view the signature file as needed.',
                                        boldTexts: ['Signature Details', 'download'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Appointment',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Navigate to the Schedule section on the left pane.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Locate and click on the service appointment where you want to upload a signature. This will open the appointment details modal.',
                                        boldTexts: ['service appointment'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Open the Upload Signature Modal',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the appointment details modal, locate the Upload Signature button at the top left of the modal (next to the Manage Notes button).',
                                        boldTexts: ['Upload Signature', 'Manage Notes'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click on Upload Signature. The Upload Signature modal will appear.',
                                        boldTexts: ['Upload Signature'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Upload the Signature',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the modal, click Upload Signature again to choose a file from your device.',
                                        boldTexts: ['Upload Signature'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Browse your device and select the file that contains the signature.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Confirm the Uploaded File',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'After the file is uploaded, its name will appear in the modal, confirming the upload was successful.',
                                        boldTexts: ['Un-Render Appointment'],
                                        circle: true,
                                    },

                                    {
                                        title: 'If the uploaded file is correct, click Done to confirm.',
                                        boldTexts: ['Done'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Verify the Signature',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'After confirmation, the uploaded signature will be listed under Signature Details in the appointment section.',
                                        boldTexts: ['Signature Details'],
                                        circle: true,
                                    },

                                    {
                                        title: 'You can download or view the signature file as needed.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'ADD_NOTE_SERVICE_APPOINT': {
            pageTitle: 'Adding a Note to a Service Appointment',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Navigate to the Schedule section on the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Locate the appointment you want to add a note to and click on it.',
                                        boldTexts: ['appointment'],
                                    },

                                    {
                                        title: '3. In the appointment details modal, click on the Notes tab to switch from the details view to notes.',
                                        boldTexts: ['appointment details', 'Notes'],
                                    },

                                    {
                                        title: '4. Click the + Add Note button.',
                                        boldTexts: ['Add Note'],
                                    },

                                    {
                                        title: '5. Provide a subject and the content of your note in the fields provided.',
                                        boldTexts: ['subject'],
                                    },

                                    {
                                        title: '6. Click the Save button to save the note or Cancel to discard it.',
                                        boldTexts: ['Save', 'Cancel'],
                                    },

                                    {
                                        title: '7. The newly added note will be visible on the left side of the Notes section.',
                                        boldTexts: ['Notes'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Appointment',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Navigate to the Schedule section on the left pane.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Locate the appointment you want to add a note to and click on it.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Switch to the Notes Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'After clicking the appointment, the appointment details modal will open, defaulting to the Details tab.',
                                        boldTexts: ['Details'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click on the Notes tab to switch from the details view to notes.',
                                        boldTexts: ['Notes'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Add a New Note',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'If no notes exist, click the + Add Note button in the center of the screen.',
                                        boldTexts: ['Add Note'],
                                        circle: true,
                                    },

                                    {
                                        title: 'If there are existing notes, the + Add Note button will be available in the top-right corner.',
                                        boldTexts: ['Add Note'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Enter Note Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'After clicking Add Note, provide a subject and the content of your note in the fields provided.',
                                        boldTexts: ['Add Note'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Save or Cancel the Note',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Once you\'ve entered the note details, click the Save button in the bottom-right corner to save the note.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },

                                    {
                                        title: 'If you change your mind, click Cancel to discard the note.',
                                        boldTexts: ['Cancel'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Review the Added Note',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The newly added note will now be visible on the left side of the Notes section.',
                                        boldTexts: ['Notes'],
                                        circle: true,
                                    },

                                    {
                                        title: 'You can click on the note to review its details on the right side.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'EDIT_NOTE_IN_AN_APPOINT': {
            pageTitle: 'Editing a Note in an Appointment',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Navigate to the Schedule section on the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Locate the appointment where the note is to be edited and click on it.',
                                        boldTexts: ['appointment'],
                                    },

                                    {
                                        title: '3. In the appointment details modal, click the Notes tab.',
                                        boldTexts: ['appointment details', 'Notes'],
                                    },

                                    {
                                        title: '4. Click on the note you want to edit to load its details.',
                                        boldTexts: [''],
                                    },

                                    {
                                        title: '5. Click the Edit button (pencil icon) to enter edit mode.',
                                        boldTexts: ['Edit'],
                                    },

                                    {
                                        title: '6. Update the subject or body of the note as needed.',
                                        boldTexts: ['subject', 'body'],
                                    },

                                    {
                                        title: '7. Click Save to apply the changes or Cancel to exit without saving.',
                                        boldTexts: ['Save', 'Cancel'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Appointment',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Navigate to the Schedule section on the left pane.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Locate the appointment you want to edit the note for and click on it to open the details modal.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Switch to the Notes Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the appointment details modal, click on the Notes tab to view any existing notes for the appointment.',
                                        boldTexts: ['Notes'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Select the Note to Edit',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'On the left side of the Notes tab, you\'ll see a list of existing notes.',
                                        boldTexts: ['Notes'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click on the note you want to edit to load its details on the right side.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Enter Edit Mode',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Edit button (pencil icon) located on the right side of the note details.',
                                        boldTexts: ['Edit'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Modify the Note:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Update the Subject or the Body of the note as needed.',
                                        boldTexts: ['Subject', 'Body'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Save or Cancel the Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'After making the necessary changes, click Save in the bottom-right corner to apply the changes.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },

                                    {
                                        title: 'If you decide not to make any changes, click Cancel to exit edit mode without saving.',
                                        boldTexts: ['Cancel'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'VIEW_NOTE_APPOINT': {
            pageTitle: 'Viewing Notes on Appointments',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to the Schedule section in the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Find the appointment for which you want to view notes and click on it.',
                                        boldTexts: ['appointment'],
                                    },

                                    {
                                        title: '3. In the appointment details modal, click the Notes tab.',
                                        boldTexts: ['appointment details', 'Notes'],
                                    },

                                    {
                                        title: '4. Review the list of notes displayed on the left side.',
                                        boldTexts: [''],
                                    },

                                    {
                                        title: '5. Click on a note to see its full details, including subject, body, and the creator\'s name.',
                                        boldTexts: ['subject', 'body', 'creator', 's', 'name'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Appointment',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the Schedule section in the left pane.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Find the appointment for which you want to view notes and click on it to open the appointment details modal.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Switch to the Notes Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the appointment details modal, click on the Notes tab. This will display any notes associated with the selected appointment.',
                                        boldTexts: ['Notes'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. View Existing Notes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'On the left side of the Notes tab, you will see a list of all notes related to the appointment.',
                                        boldTexts: ['Notes'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click on a note from the list to view its full details on the right side of the modal.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Review Note Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'The note details will display the Subject, Body, Creator Name, and the Created Date/Time.',
                                        boldTexts: ['Subject', 'Body', 'Creator Name', 'Created Date/Time'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'UPLOAD_DOC_ON_APPOINT': {
            pageTitle: 'Uploading Documents on Appointments',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Navigate to the Schedule section from the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Find the appointment where you wish to upload documents and click to open its details.',
                                        boldTexts: ['appointment'],
                                    },

                                    {
                                        title: '3. In the appointment details modal, go to the Files tab.',
                                        boldTexts: ['appointment details', 'Files'],
                                    },

                                    {
                                        title: '4. Click the Upload Document button to open the document upload modal.',
                                        boldTexts: ['Upload Document'],
                                    },

                                    {
                                        title: '5. Enter a Document Name and optional Description.',
                                        boldTexts: ['Document Name'],
                                    },

                                    {
                                        title: '6. Click Click to Upload or drag and drop your file into the upload area.',
                                        boldTexts: ['Click to Upload '],
                                    },

                                    {
                                        title: '7. After uploading all files, click Add to save them to the appointment.',
                                        boldTexts: ['Add'],
                                    },

                                    {
                                        title: '7. The uploaded documents will now be listed under the Files tab.',
                                        boldTexts: ['Files'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Appointment',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Navigate to the Schedule section from the left pane.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Find the appointment where you wish to upload documents and click to open its details.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Switch to the Files Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the appointment details modal, go to the Files tab.',
                                        boldTexts: ['Files'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Open the Upload Document Modal',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Upload Document button located on the right side of the Files tab to open the document upload modal.',
                                        boldTexts: ['Upload Document', 'Files'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Input Document Information',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Upload Document modal, provide a Document Name and an optional Description for each file.',
                                        boldTexts: ['Upload Document', 'Document Name', 'Description'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click on Click to Upload or drag and drop the file(s) from your device into the provided area.',
                                        boldTexts: ['Click to Upload'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Upload and Add Multiple Documents',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Once the first file is uploaded, it will appear in the list below.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'You can continue to upload additional documents by repeating the upload steps (dragging or clicking Click to Upload again).',
                                        boldTexts: ['Click to Upload'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Each file can have its own Document Name and Description.',
                                        boldTexts: ['Document Name'],
                                        circle: true,
                                    },

                                    {
                                        title: 'To delete a file, click the trash icon next to it.',
                                        boldTexts: ['Document Name'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Complete the Upload',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'After uploading all desired documents, click the Add button to add the uploaded files to the appointment.',
                                        boldTexts: ['Add'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '7. Review Uploaded Documents',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Once the documents are added, you will see them listed under the Files tab. You can view, download, or delete them from this section.',
                                        boldTexts: ['Files'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'EDIT_FILES_IN_APPOINT': {
            pageTitle: 'Editing Files in Appointments',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to the Schedule section in the left navigation pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Select the appointment you want to edit.',
                                        boldTexts: ['appointment'],
                                    },

                                    {
                                        title: '3. In the Files tab of the appointment details modal, click the Edit icon next to the file you want to modify.',
                                        boldTexts: ['Files', 'appointment details', 'Edit'],
                                    },

                                    {
                                        title: '4. Update the Document Name or Description as needed.',
                                        boldTexts: ['Document Name', 'Description'],
                                    },

                                    {
                                        title: '5. Click Save to apply the changes.',
                                        boldTexts: ['Save'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Navigate to Appointments',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the Schedule section in the left navigation pane.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click on the appointment you want to edit.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Access the Files Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the appointment modal that opens, click on the Files tab at the top.',
                                        boldTexts: ['Files'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Click the Edit Icon',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Next to the file you want to edit, click on the pencil/edit icon. A modal will pop up.',
                                        boldTexts: ['pencil', 'edit'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Modify the File Information',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Update the Document Name or Description as needed.',
                                        boldTexts: ['Document Name', 'Description'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Save Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click the Save button to apply the updates.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'VIEW_FILES_IN_APPOINT': {
            pageTitle: 'Viewing Files in Appointments',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to the Schedule section in the left navigation pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Select the appointment whose files you want to view.',
                                        boldTexts: ['appointment'],
                                    },

                                    {
                                        title: '3. In the Files tab of the appointment details modal, click the View icon next to the file.',
                                        boldTexts: ['Files', 'appointment details', 'View'],
                                    },

                                    {
                                        title: '4. The file will open in a preview window or viewer.',
                                        boldTexts: ['appointment'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Navigate to Appointments',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the Schedule section in the left navigation pane.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Select the appointment you want to view.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Access the Files Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the appointment modal, click on the Files tab.',
                                        boldTexts: ['Files'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Click the View Icon',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Next to the file you want to view, click the eye/view icon.',
                                        boldTexts: ['eye', 'view'],
                                        circle: true,
                                    },

                                    {
                                        title: 'The file will open in a preview window or be shown in the appropriate viewer for the file type.',
                                        boldTexts: [],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'DOWN_FILES_IN_APPOINT': {
            pageTitle: 'Downloading Files in Appointments',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Navigate to the Schedule section from the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Select the appointment you want to download the file from.',
                                        boldTexts: ['appointment'],
                                    },

                                    {
                                        title: '3. In the Files tab, click the Download icon next to the file.',
                                        boldTexts: ['Files', 'Download'],
                                    },

                                    {
                                        title: '4. The file will be automatically downloaded to your local device.',
                                        boldTexts: [''],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Navigate to Appointments',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the Schedule section in the left navigation pane.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Select the appointment you want to download the file from.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Access the Files Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the appointment modal, click on the Files tab.',
                                        boldTexts: ['Files'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Click the Download Icon',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Next to the file you want to download, click the cloud/download icon (downward arrow).',
                                        boldTexts: ['cloud', 'download'],
                                        circle: true,
                                    },

                                    {
                                        title: 'The file will immediately download to your local device.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'DELETE_FILES_IN_APPOINT': {
            pageTitle: 'Deleting Files in Appointments',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to the Schedule section in the left navigation pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Select the appointment whose file you want to delete.',
                                        boldTexts: ['appointment'],
                                    },

                                    {
                                        title: '3. In the Files tab, click the Delete icon next to the file.',
                                        boldTexts: ['Files', 'Delete'],
                                    },

                                    {
                                        title: '4. A confirmation modal will appear. Click Delete to confirm.',
                                        boldTexts: ['Delete'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Navigate to Appointments',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to the Schedule section in the left navigation pane.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Select the appointment you want to delete the file from.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Access the Files Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the appointment modal, click on the Files tab.',
                                        boldTexts: ['Files'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Click the Delete Icon',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Next to the file you want to delete, click the trash bin/delete icon. A confirmation modal will appear.',
                                        boldTexts: ['trash bin', 'delete'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Confirm Deletion',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Type any necessary information (if required), and then click Delete to remove the file.',
                                        boldTexts: ['Delete'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'EDIT_APPOINT_EXCEPT_SERVICE_APPOINT': {
            infoTitle: 'Editing Appointments (Except Service Appointments)',

            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Navigate to the Schedule section from the left-hand menu.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Locate the appointment you want to edit from the list.',
                                        boldTexts: ['appointment'],
                                    },

                                    {
                                        title: '3. Click the Edit icon (pencil) next to the appointment.',
                                        boldTexts: ['Edit'],
                                    },

                                    {
                                        title: '4. Update fields such as Staff Member, Staff Paycode, Start Date, Start Time, or End Time.',
                                        boldTexts: ['Staff Member', 'Staff Paycode', 'Start Date', 'Start Time', 'End Time'],
                                    },

                                    {
                                        title: '5. For Drive Time appointments, enter the Mileage.',
                                        boldTexts: ['Drive Time', 'Mileage'],
                                    },

                                    {
                                        title: '6. Click Save to update the appointment, or click Cancel to exit without saving.',
                                        boldTexts: ['Save', 'Cancel'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Navigate to the Schedule',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to Schedule from the left-hand menu.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Appointment to Edit',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Locate the appointment you want to edit from the list.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click on the Edit icon (pencil icon) next to the appointment. This will open the Edit Appointment modal.',
                                        boldTexts: ['Edit'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Edit Appointment Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Edit Appointment modal, you can modify the following fields',
                                        boldTexts: ['History'],
                                        circle: true,
                                        list: [
                                            {
                                                title: 'Staff Member: Select the staff member assigned to this appointment.',
                                                boldTexts: ['Staff Member'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Staff Paycode: Choose the appropriate paycode (e.g., hourly, salary).',
                                                boldTexts: ['Staff Paycode'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Start Date: Set or change the start date.',
                                                boldTexts: ['Start Date'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Start Time: Adjust the start time.',
                                                boldTexts: ['Start Time'],
                                                circle: true,
                                            },

                                            {
                                                title: 'End Time: Adjust the end time.',
                                                boldTexts: ['End Time'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Additional Fields for Specific Appointment Types',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Drive Time Appointment: If you are editing a Drive Time appointment,' +
                                            ' there will be an additional field for Mileage. Enter the mileage associated with the drive time.',
                                        boldTexts: ['Drive Time Appointment', 'Mileage'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Save Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'After making the desired changes, click the Save button to update the appointment.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Cancel Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'If no changes are needed, click the Cancel button to exit without saving.',
                                        boldTexts: ['Cancel'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '• Important Notes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'If no changes are needed, click the Cancel button to exit without saving.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'Service Appointments are handled differently and are not covered in this section.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'The Drive Time Appointment includes an extra field for Mileage that other appointment types do not have.',
                                        boldTexts: ['Drive Time Appointment', 'Mileage'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'EDIT_SERVICE_APPOINTMENT': {
            infoTitle: 'Editing a Service Appointment',
            infoDescription: 'Editing a Service Appointment involves updating client-specific and service-specific' +
                ' details, which is more complex than editing other types of appointments. Here is how to edit a Service Appointment.',
            pageTitle: 'Steps to Edit a Service Appointment',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Navigate to the Schedule section from the left-hand menu.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Select the service appointment you want to edit from the list.',
                                        boldTexts: ['service appointment'],
                                    },

                                    {
                                        title: '3. Click the Edit icon (pencil) next to the appointment.',
                                        boldTexts: ['Edit'],
                                    },

                                    {
                                        title: '4. Modify fields such as Client, Staff List, Authorization, Credentials, Service, Type, Place of Service, Staff Paycode, Start Date, Start Time, and End Time.',
                                        boldTexts: ['Client', 'Staff List', 'Authorization', 'Credentials', 'Service', 'Type', 'Place of Service', 'Staff Paycode', 'Start Date', 'Start Time', 'End Time'],
                                    },

                                    {
                                        title: '5. If the units fall outside the allowed range, a warning will appear. Adjust the units accordingly.',
                                        boldTexts: ['units'],
                                    },

                                    {
                                        title: '6. Click Save to apply the changes or Cancel to exit without saving.',
                                        boldTexts: ['Save'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Navigate to the Schedule',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Go to Schedule from the left-hand menu.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Select the Service Appointment to Edit',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Locate the service appointment you want to edit from the list.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click on the Edit icon (pencil icon) next to the appointment. This will open the Edit Service Appointment modal.',
                                        boldTexts: ['Edit'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Edit Service Appointment Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Edit Service Appointment modal, you can modify the following fields',
                                        boldTexts: ['Edit Service Appointment'],
                                        circle: true,
                                        list: [
                                            {
                                                title: 'Client: Select or change the client for the appointment.',
                                                boldTexts: ['Client'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Staff List: Choose the staff member assigned to this appointment.',
                                                boldTexts: ['Staff List'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Authorization: Select the authorization applicable to this appointment.',
                                                boldTexts: ['Authorization'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Credentials: Displays the required credentials for the selected service.',
                                                boldTexts: ['Credentials'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Service: Choose the type of service being provided.',
                                                boldTexts: ['Service'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Type: Select the service type (e.g., Direct, Supervision, etc.).',
                                                boldTexts: ['Type'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Place of Service: Indicate the location where the service is being provided.',
                                                boldTexts: ['Place of Service'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Staff Paycode: Choose the paycode relevant to the staff member.',
                                                boldTexts: ['Staff Paycode'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Start Date: Set or change the start date.',
                                                boldTexts: ['Start Date'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Start Time: Adjust the start time.',
                                                boldTexts: ['Start Time'],
                                                circle: true,
                                            },

                                            {
                                                title: 'End Time: Adjust the end time.',
                                                boldTexts: ['End Time'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Additional Alerts and Warnings',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Units Warning: If the selected units fall outside the allowed range for' +
                                            ' the funding source, a warning will appear (e.g., "Selected 8.00 units is outside the' +
                                            ' allowed range of 1 - 5 units..."). Make sure to adjust the units accordingly.',
                                        boldTexts: ['Units Warning'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Rendering Warning: If the appointment is already rendered, a warning will ' +
                                            'indicate that "claims and processed timesheets will not be updated." Be mindful when' +
                                            ' editing already rendered appointments as it might affect downstream processes.',
                                        boldTexts: ['Rendering Warning'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Save Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'After making the desired changes, click the Save button to update the appointment.',
                                        boldTexts: ['Save'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Cancel Changes',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'If no changes are needed, click the Cancel button to exit without saving.',
                                        boldTexts: ['Cancel'],
                                        circle: true,
                                    },
                                ],

                            },

                            {
                                notes: [
                                    {
                                        text: 'Note: Service Appointments have more fields to consider compared to other' +
                                            ' appointment types, as they are tied to specific clients, authorizations, and service requirements.',
                                        boldTexts: ['Note'],
                                    },
                                ],
                            },
                        ],
                    },

                ],
            },
        },
        'EDIT_TEMPLATE_EXCEPT_EXCEPT_SERVICE_APPOINT': {
            infoTitle: 'Editing a Template/Series of Appointments (Except Service Appointments)',
            infoDescription: 'To edit a template or a series of appointments' +
                ' (such as Staff, Break, Sick Time, Paid Time Off, Unpaid Time Off, Drive Time) in the system, follow these steps',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Navigate to the Schedule section.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Find the appointment series you want to edit.',
                                        boldTexts: ['appointment series'],
                                    },

                                    {
                                        title: '3. Click the Edit icon (pencil) next to the appointment.',
                                        boldTexts: ['Edit'],
                                    },

                                    {
                                        title: '4. Toggle the Edit Template option on.',
                                        boldTexts: ['Edit Template'],
                                    },
                                    {
                                        title: '5. Adjust fields such as Staff Member, Staff Paycode, Start Date, Start Time, and End Time.',
                                        boldTexts: ['Staff Member', 'Staff Paycode', 'Start Date', 'Start Time', 'End Time'],
                                    },

                                    {
                                        title: '6. For Drive Time, update the Mileage as needed.',
                                        boldTexts: ['Drive Time', 'Mileage'],
                                    },

                                    {
                                        title: '7. Choose whether to Cancel or Delete all appointments associated with the template.',
                                        boldTexts: ['Cancel', 'Delete'],
                                    },

                                    {
                                        title: '8. Click Update to apply the changes.',
                                        boldTexts: ['Update'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Open the Edit Modal:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Navigate to the appointment you want to edit.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click the edit icon (pencil) to open the Edit [Appointment Type] Appointment modal.',
                                        boldTexts: ['edit icon', 'pencil', 'Edit', 'Appointment'],
                                        circle: true,
                                    },

                                    {
                                        title: 'In this initial screen, you can edit details like Staff Member, Staff Paycode, Start Date, and Time.',
                                        boldTexts: ['Staff Member', 'Date', 'Time'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Toggle \'Edit Template\'',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'If you want to edit the entire template series, toggle the Edit Template option.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'This will change the modal to Edit [Appointment Type] Appointment (Series) mode, allowing for batch modifications.',
                                        boldTexts: ['Edit', 'Appointment Type', 'Appointment', 'Series'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Adjust Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Adjust the required fields, such as Start Time and End Time, to apply changes to all appointments in the series.',
                                        boldTexts: ['Start Time', 'End Time'],
                                        circle: true,
                                    },

                                    {
                                        title: 'For Drive Time appointments, an additional field for Mileage will be available to edit. Make sure to update the mileage value if needed.',
                                        boldTexts: ['Mileage'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Select Options',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Choose either Cancel All Appointments or Delete All Appointments associated with ' +
                                            'this template if needed. These options allow you to either cancel or delete the entire series.',
                                        boldTexts: ['Cancel All Appointments', 'Delete All Appointments'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Update the Series',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'After making all necessary changes, click Update to apply them to all associated appointments in the series.',
                                        boldTexts: ['Update'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'EDIT_TEMPLATE_SERVICE_APPOINT': {
            infoTitle: 'Editing a Template/Series for Service Appointments',
            infoDescription: 'To edit a template (series) of Service Appointments, follow these steps',

            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to the Schedule section.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Select the service appointment you want to edit.',
                                        boldTexts: ['service appointment'],
                                    },

                                    {
                                        title: '3. Click the Edit icon (pencil) next to the appointment.',
                                        boldTexts: ['Edit'],
                                    },

                                    {
                                        title: '4. Toggle the Edit Template option on to switch to series mode.',
                                        boldTexts: ['Edit Template'],
                                    },

                                    {
                                        title: '5. Adjust fields like Client, Staff List, Authorization, Service, Type, Place of Service, Staff Paycode, Start Date, Start Time, and End Time for the entire series.',
                                        boldTexts: ['Client', 'Staff List', 'Authorization', 'Service', 'Type', 'Place of Service', 'Staff Paycode', 'Start Date', 'Start Time', ' End Time'],
                                    },

                                    {
                                        title: '6. Click Continue to proceed to additional options such as canceling or deleting appointments in the series.',
                                        boldTexts: ['Continue'],
                                    },

                                    {
                                        title: '7. Click Update to save the changes.',
                                        boldTexts: ['Update'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Open the Edit Screen',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Navigate to the specific Service Appointment you want to edit.',
                                        boldTexts: ['Service Appointment'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Click the Edit icon (pencil) next to the appointment to open the Edit Service Appointment modal.',
                                        boldTexts: ['Edit', 'Edit Service Appointment'],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Toggle to Edit Template',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Edit Service Appointment modal, you\'ll see a toggle labeled Edit Template. ' +
                                            'Turn this toggle on to switch from editing a single appointment to editing the entire template (series) of appointments.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'When you toggle this on, the modal will change to Edit Service Appointment (Series), as shown in the screenshot.',
                                        boldTexts: ['Edit Service Appointment', 'Series'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Review the Details:',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the Edit Service Appointment (Series) modal, the fields available for editing include',
                                        boldTexts: ['Start Time', 'End Time'],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Client: Select the client associated with the service.',
                                                boldTexts: ['Client'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Staff List: Choose the staff member assigned to this appointment series.',
                                                boldTexts: ['Client'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Authorization: Ensure the correct authorization is selected.',
                                                boldTexts: ['Authorization'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Service: Set the type of service.',
                                                boldTexts: ['Service'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Type: Choose the type (e.g., Direct, Indirect).',
                                                boldTexts: ['Type'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Place of Service: Specify the location where the service is provided.',
                                                boldTexts: ['Place of Service'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Staff Paycode: Enter the staff pay code.',
                                                boldTexts: ['Staff Paycode'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Start Date, Start Time, and End Time: Set the new start date, start time, and end time for the entire series.\n',
                                                boldTexts: ['Staff Paycode'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Continue Editing',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Click Continue to proceed to the next step where additional options are available, such as canceling or deleting all appointments in the series.',
                                        boldTexts: ['Continue'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '5. Cancel or Delete Appointments',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'If needed, you can choose to Cancel All Appointments or Delete All Appointments associated with this template.',
                                        boldTexts: ['Cancel All Appointments', 'Delete All Appointments'],
                                        circle: true,

                                        list: [
                                            {
                                                title: 'Cancel All Appointments will cancel all pending appointments in the series.',
                                                boldTexts: ['Cancel All Appointments'],
                                                circle: true,
                                            },

                                            {
                                                title: 'Delete All Appointments will remove all pending appointments in the series.',
                                                boldTexts: ['Delete All Appointments'],
                                                circle: true,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '6. Update the Template',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'After making the necessary changes and confirming the options, click Update' +
                                            ' to save your changes and update the entire template (series) of service appointments.',
                                        boldTexts: ['Update'],
                                        circle: true,
                                    },
                                ],
                            },

                            {
                                notes: [
                                    {
                                        text: 'Note: Editing a service appointment template will disconnect it from the ' +
                                            'original template series. If you want to keep the appointments connected, ensure the Edit ' +
                                            'Template toggle is off when editing.',
                                        boldTexts: ['Edit Template', 'off', 'Note'],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        'VIEW_CHANGE_HISTORY_IN_APPOINT': {
            pageTitle: 'Viewing Change History in Appointments',
            content: {
                short: [
                    {
                        list: [
                            {
                                list: [
                                    {
                                        title: '1. Go to the Schedule section in the left pane.',
                                        boldTexts: ['Schedule'],
                                    },

                                    {
                                        title: '2. Find and click on the appointment you want to view the history for.',
                                        boldTexts: ['appointment'],
                                    },

                                    {
                                        title: '3. In the appointment details modal, click the History tab at the top.',
                                        boldTexts: ['appointment details', 'History'],
                                    },

                                    {
                                        title: '4. Review the list of all changes made to the appointment, including Date & Time, Staff Name, and Action Description.',
                                        boldTexts: ['Date', 'Time', 'Staff Name', 'Action Description'],
                                    },
                                ],
                            },

                        ],
                    },
                ],
                long: [
                    {
                        subTitle: '1. Access the Appointment',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'Navigate to the Schedule section on the left pane.',
                                        boldTexts: ['Schedule'],
                                        circle: true,
                                    },

                                    {
                                        title: 'Find and click on the appointment you want to view the history for.',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },

                        ],
                    },

                    {
                        subTitle: '2. Open the Appointment Details',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'When you click on the appointment, its details modal will open.',
                                        boldTexts: ['Files'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '3. Switch to the History Tab',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'In the appointment details modal, click on the History tab at the top.',
                                        boldTexts: ['History'],
                                        circle: true,
                                    },

                                    {
                                        title: 'This tab will display a list of all changes made to the appointment, including the Date & Time of the changes, Staff Name, and Action Description.',
                                        boldTexts: ['Date', 'Time', 'Staff Name', 'Action Description'],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        subTitle: '4. Review the History',
                        list: [
                            {
                                list: [
                                    {
                                        title: 'If there is any history available, all actions (such as edits, status updates, and other changes) will be listed in this section.',
                                        boldTexts: [''],
                                        circle: true,
                                    },

                                    {
                                        title: 'If no changes have been made, the tab will display a message saying, "No History Available."',
                                        boldTexts: [''],
                                        circle: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
    },
    'BILLING': {
        'PENDING_CLAIMS': {
            'VIEW_PENDING_CLAIMS': {
                subMenuTitle: 'Pending Claims',
                infoTitle: 'Viewing Pending Claim List',
                infoDescription: 'To view the list of pending claims',

                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Billing > Pending Claims from the left sidebar.',
                                            boldTexts: ['Billing', 'Pending Claims'],
                                        },

                                        {
                                            title: '2. Understand the Pending Claims section, which shows claims awaiting submission or processing.',
                                            boldTexts: ['Pending Claims'],
                                        },

                                        {
                                            title: '3. If modifications are made to an appointment already in a pending claim, it will regenerate the claim and appear again in this list.',
                                            boldTexts: [''],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to Pending Claims',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the left sidebar and click on Billing > Pending Claims.',
                                            boldTexts: ['Billing', 'Pending Claims'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Understand the Pending Claims List',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'The Pending Claims section shows a temporary list of claims that are awaiting submission or processing.',
                                            boldTexts: [''],
                                            circle: true,
                                        },

                                        {
                                            title: 'If modifications are made to an appointment after it has been added to a pending claim, you can regenerate ' +
                                                'a claim and it will appear in this list again. Note that it will not prevent you from generating claims even if ' +
                                                'the appointment is already part of a claim in Pending Claims.',
                                            boldTexts: ['Pending Claims'],
                                            circle: true,
                                        },
                                    ],
                                },

                                {
                                    images: [
                                        {
                                            type: 'img',
                                            desktop: Images.viewPendClaimDesktop,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'GENERATE_CLAIM': {
                subMenuTitle: 'Pending Claims',
                infoTitle: 'Generating Claims',
                infoDescription: 'To generate claims from the pending claims list',

                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Billing > Pending Claims from the left sidebar.',
                                            boldTexts: ['Billing', 'Pending Claims'],
                                        },

                                        {
                                            title: '2. Click Prepare Claims at the top right of the screen.',
                                            boldTexts: ['Prepare Claims'],
                                        },

                                        {
                                            title: '3. Select the appointments by checking the boxes next to each Appointment ID.',
                                            boldTexts: ['Appointment ID'],
                                        },

                                        {
                                            title: '4. Click Prepare Claims at the bottom right of the modal.',
                                            boldTexts: ['Prepare Claims'],
                                        },

                                        {
                                            title: '5. The generated claim will now appear in the Pending Claims list.',
                                            boldTexts: [''],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to Billing > Pending Claims',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the left sidebar and click on Billing > Pending Claims.',
                                            boldTexts: ['Billing', 'Pending Claims'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Click on Prepare Claims',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click the Prepare Claims button located at the top right of the screen.',
                                            boldTexts: ['Prepare Claims'],
                                            circle: true,
                                        },
                                    ],
                                },

                                {
                                    images: [
                                        {
                                            type: 'img',
                                            desktop: Images.generateClaimDesktop1,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Select Appointments',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the modal that appears, select one or multiple appointments by checking the checkboxes next to each Appointment ID.',
                                            boldTexts: ['Appointment ID'],
                                            circle: true,

                                        },

                                        {
                                            title: 'To select all appointments, click the checkbox next to the Appointment ID header.',
                                            boldTexts: ['Appointment ID'],
                                            circle: true,

                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Prepare Claims',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once the desired appointments are selected, click the Prepare Claims button at the bottom right of the modal.',
                                            boldTexts: ['Appointment ID'],
                                            circle: true,

                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. View Generated Claims',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'The newly generated claim will now appear in the Pending Claims list, ready for further processing or submission.',
                                            boldTexts: ['Pending Claims'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'VIEW_PENDING_CLAIM_DETAIL': {
                subMenuTitle: 'Pending Claims',
                infoTitle: 'Viewing Pending Claim Details',
                infoDescription: 'To view the details of a specific claim',

                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Billing > Pending Claims from the left sidebar.',
                                            boldTexts: ['Billing', 'Pending Claims'],
                                        },

                                        {
                                            title: '2. Select a claim by clicking on it.',
                                            boldTexts: [''],
                                        },

                                        {
                                            title: '3. View the details in the Details tab, including Staff, Client, Funding Source, Status, DOS, and Financial details.',
                                            boldTexts: ['Details', 'Staff', 'Client', 'Funding Source', 'Status', 'DOS', 'Financial details'],
                                        },

                                        {
                                            title: '4. Review the Billing Entries section for service breakdowns like DOS, CPT Code, Modifier, Units, and Financial amounts.',
                                            boldTexts: ['Billing Entries', 'DOS', 'CPT Code', 'Modifier', 'Units'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to Billing > Pending Claims',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the left sidebar and click on Billing > Pending Claims.',
                                            boldTexts: ['Billing', 'Pending Claims'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Select a Claim',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on the desired claim from the list to open its details.',
                                            boldTexts: [''],
                                            circle: true,

                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. View Claim Details',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'The Details tab will be selected by default. Here, you can view the key details of the claim, including',
                                            boldTexts: ['Details'],
                                            circle: true,

                                            list: [
                                                {
                                                    title: 'Staff: The staff member associated with the claim.',
                                                    boldTexts: ['Staff'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Client: The client associated with the claim.',
                                                    boldTexts: ['Client'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Funding Source: The funding source for the claim.',
                                                    boldTexts: ['Funding Source'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Status: Current status of the claim (e.g., Pending).',
                                                    boldTexts: ['Status'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'DOS (Date of Service): The date range for the services included in the claim.',
                                                    boldTexts: ['DOS', 'Date of Service'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Billed Amount, Allowed Amount, Paid Amount, Balance: Financial details related to the claim.',
                                                    boldTexts: ['Billed Amount', 'Allowed Amount', 'Paid Amount', 'Balance'],
                                                    circle: true,
                                                },

                                                {
                                                    title: '1500 Form Download: You can download the form for review or submission purposes.',
                                                    boldTexts: ['1500 Form Download'],
                                                    circle: true,
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Billing Entries',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Below the claim details, you will see the Billing Entries section. This section provides a breakdown of each service within the claim, including',
                                            boldTexts: ['Appointment ID'],
                                            circle: true,

                                            list: [
                                                {
                                                    title: 'DOS (Date of Service): The specific dates for each service.',
                                                    boldTexts: ['DOS', 'Date of Service'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Place of Service, CPT Code, Modifier, Total Units: Details about the service provided.',
                                                    boldTexts: ['Place of Service', 'CPT Code', 'Modifier', 'Total Units'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Billed Amount, Allowed Amount, Paid Amount, Balance: Financial breakdown for each entry.',
                                                    boldTexts: ['Billed Amount', ' Allowed Amount', 'Paid Amount', 'Balance'],
                                                    circle: true,
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'DOWN_1500_DORM_PENDING_CLAIM': {
                subMenuTitle: 'Pending Claims',
                infoTitle: 'Downloading the 1500 Form for a Pending Claim',
                infoDescription: 'To download the 1500 form for a specific pending claim, follow these steps',

                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Billing > Pending Claims.',
                                            boldTexts: ['Billing', 'Pending Claims'],
                                        },

                                        {
                                            title: '2. Select a claim and click the Download icon next to the 1500 Form.',
                                            boldTexts: ['Download', '1500 Form'],
                                        },

                                        {
                                            title: '3. Choose between PDF or Tab-delimited format.',
                                            boldTexts: ['PDF', 'Tab-delimited'],
                                        },

                                        {
                                            title: '4. Click Download to save the form.',
                                            boldTexts: ['Download'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to Billing > Pending Claims',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the left sidebar and click on Billing > Pending Claims.',
                                            boldTexts: ['Billing', 'Pending Claims'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Select the Claim',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on the desired claim from the list to view its details.',
                                            boldTexts: ['Prepare Claims'],
                                            circle: true,

                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Click on the Download Icon',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Details tab of the claim, click on the Download icon next to the "1500 Form" label.',
                                            boldTexts: ['Details', 'Download'],
                                            circle: true,

                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Choose the File Format',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'A modal titled Download 1500 Form will appear. You will see two file format options',
                                            boldTexts: ['Appointment ID'],
                                            circle: true,

                                            list: [
                                                {
                                                    title: 'PDF (.pdf)',
                                                    boldTexts: ['PDF', '.pdf'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Tab-delimited (.tab)',
                                                    boldTexts: ['Tab-delimited', '.tab'],
                                                    circle: true,
                                                },
                                            ],
                                        },

                                        {
                                            title: 'Select your preferred format.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Download the 1500 Form',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click the Download button. The form will be downloaded in the selected format, and you can use it for submission or review purposes.',
                                            boldTexts: ['Download'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'VIEW_BILL_DETAILS_PENDING_CLAIM_PAYMENT_TRANS': {
                subMenuTitle: 'Pending Claims',
                infoTitle: 'Viewing Bill Details in a Pending Claim (Claim Payment Transactions)',
                infoDescription: 'When you are reviewing a pending claim, you can view the bill details associated with the claim, including any transactions related to claim payments.',
                pageTitle: 'Steps to View Claim Payment Transactions',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Open a claim from the Pending Claims section.',
                                            boldTexts: ['Pending Claims'],
                                        },

                                        {
                                            title: '2. In the Billing Entries section, click on the Bill Details.',
                                            boldTexts: ['Billing Entries', 'Bill Details'],
                                        },

                                        {
                                            title: '3. In the Claim Payment Transactions tab, view transactions like Allowed Amount, Deductible, Coinsurance, Paid Amount, and Status.',
                                            boldTexts: ['Claim Payment Transactions', 'Allowed Amount', 'Deductible', 'Coinsurance', 'Paid Amount', 'Status'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Open a Pending Claim',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Navigate to Billing > Pending Claims.',
                                            boldTexts: ['Billing', 'Pending Claims'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Select the desired claim from the list to open the Claim Details view.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Access Bill Details',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Claim Details view, locate the section with billing entries.',
                                            boldTexts: ['Claim Details'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Click on the Billing Entries section to open the Bill Details modal.',
                                            boldTexts: ['Billing Entries'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. View Claim Payment Transactions',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Bill Details modal, the Claim Payment Transaction tab will be selected by default.',
                                            boldTexts: ['Bill Details', 'Claim Payment Transaction'],
                                            circle: true,
                                        },

                                        {
                                            title: 'This section will display any payment transactions associated with the claim, including ' +
                                                'the ID, Username, Created Date, Voided Date, Allowed Amount, Deductible, Coinsurance, Copay, Paid Amount, and Status.',
                                            boldTexts: [''],
                                            circle: true,
                                        },

                                        {
                                            title: 'If there are no transactions, a message will display stating, "No Claim payment transaction yet."',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'VIEW_BILL_DETAILS_PENDING_CLAIM_APPOINTMENTS': {
                subMenuTitle: 'Pending Claims',
                infoTitle: 'Viewing Bill Details in a Pending Claim (Appointments)',
                infoDescription: 'You can also view the specific appointments linked to a pending claim within the Bill Details.',
                pageTitle: 'Steps to View Appointments Linked to a Bill',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Open a claim from the Pending Claims section.',
                                            boldTexts: ['Pending Claims'],
                                        },

                                        {
                                            title: '2. In the Billing Entries section, click on the Bill Details.',
                                            boldTexts: ['Billing Entries'],
                                        },

                                        {
                                            title: '3. In the Appointments tab, view linked appointments, including Appointment ID, Date of Service, Units, and Signature.',
                                            boldTexts: ['Appointment ID', 'Date of Service', 'Units', 'Signature'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Open a Pending Claim',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Navigate to Billing > Pending Claims.',
                                            boldTexts: ['Billing', 'Pending Claims'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Click on a claim from the list to open the Claim Details view.',
                                            boldTexts: ['Claim Details'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Access Bill Details',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Claim Details view, locate the section with billing entries.',
                                            boldTexts: ['Claim Details'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Click on the Billing Entries section to open the Bill Details modal.',
                                            boldTexts: ['Billing Entries', 'Bill Details'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. View Linked Appointments',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Bill Details modal, click on the Appointments tab.',
                                            boldTexts: ['Bill Details', 'Appointments'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Here, you\'ll see a list of appointments associated with the claim. Each entry includes the Appointment ID,' +
                                                ' Date of Service (DOS), Units, and a column for Signature.',
                                            boldTexts: ['Signature'],
                                            circle: true,
                                        },

                                        {
                                            title: 'For each appointment, you can click View to preview the signature or Download to download the signature file associated with that appointment.',
                                            boldTexts: ['View', 'Download'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'VIEW_APPOINTMENTS_PENDING_CLAIM': {
                subMenuTitle: 'Pending Claims',
                infoTitle: 'Viewing Appointments in a Pending Claim',
                pageTitle: 'To view all the appointments included in a Pending Claim, follow these steps',

                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Billing > Pending Claims.',
                                            boldTexts: ['Billing', 'Pending Claims'],
                                        },

                                        {
                                            title: '2. Select a claim and open its Appointments tab.',
                                            boldTexts: ['Appointments'],
                                        },

                                        {
                                            title: '3. Review the list of appointments, including details like ID, Date/Time, Staff Member, CPT Code, and Place of Service.',
                                            boldTexts: ['ID', 'Date', 'Time', 'Staff Member', 'CPT Code', 'Place of Service'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Billing Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the left-side menu, click on Billing.',
                                            boldTexts: ['Billing'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Go to Pending Claims',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Billing sub-menu, select Pending Claims.',
                                            boldTexts: ['Billing', 'Pending Claims'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Open the Claim Details',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Pending Claims list, click on the desired Claim ID to open its details.',
                                            boldTexts: ['Pending Claims', 'Claim ID'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Select the Appointments Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once inside the Claim Details screen, click on the Appointments tab at the top.',
                                            boldTexts: ['Claim Details', 'Appointments'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Review the List of Appointments',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Here, you will see a list of all appointments associated with the pending claim. The list includes',
                                            boldTexts: [''],
                                            circle: true,

                                            list: [
                                                {
                                                    title: 'ID: The unique identifier for each appointment.',
                                                    boldTexts: ['ID'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Date/Time: The scheduled date and time of the appointment.',
                                                    boldTexts: ['Date', 'Time'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Staff Member: The name of the staff member linked to the appointment.',
                                                    boldTexts: ['Staff Member'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'CPT Code (Mod.): The CPT code and any modifiers.',
                                                    boldTexts: ['CPT Code', 'Mod.'],
                                                    circle: true,
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'ADD_NOTES_PENDING_CLAIM': {
                subMenuTitle: 'Pending Claims',
                pageTitle: 'Adding Notes to a Pending Claim',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Go to Billing > Pending Claims.',
                                            boldTexts: ['Billing', 'Pending'],
                                        },

                                        {
                                            title: '2. Select a claim and open the Notes tab.',
                                            boldTexts: ['Notes'],
                                        },

                                        {
                                            title: '3. Click Add Note, enter the Subject and Body, then click Save.',
                                            boldTexts: ['Add Note', 'Subject', 'Body', 'Save'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Billing Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the left-side pane, click Billing.',
                                            boldTexts: ['Billing'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Select Pending Claims',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'From the sub-menu under Billing, click on Pending Claims.',
                                            boldTexts: ['Billing', 'Pending Claims'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Select the Pending Claim',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Pending Claims list, click on the specific claim you want to add a note to.',
                                            boldTexts: ['Pending Claims'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Click on the Notes Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once in the Claim Details screen, click the Notes tab at the top of the page.',
                                            boldTexts: ['Claim Details', 'Notes'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Add a New Note',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click the Add Note button located in the top-right corner of the Notes section.',
                                            boldTexts: ['Add Note'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. Enter Note Information',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'A modal will pop up where you can enter the Subject and Body of your note.',
                                            boldTexts: ['Subject', 'Body'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '7. Save the Note',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once you\'ve filled in the necessary information, click Save. ' +
                                                'The note will now appear in the Notes list, showing the created date, creator name, subject, and body.',
                                            boldTexts: ['Notes', 'Save'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'EDITING_NOTES_PENDING_CLAIM': {
                subMenuTitle: 'Pending Claims',
                pageTitle: 'Editing Notes in a Pending Claim',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Go to Billing > Pending Claims.',
                                            boldTexts: ['Billing', 'Pending'],
                                        },

                                        {
                                            title: '2. Select a claim and open the Notes tab.',
                                            boldTexts: ['Notes'],
                                        },

                                        {
                                            title: '3. Find the note to edit, click the Edit icon, modify the Subject or Body, and click Save.',
                                            boldTexts: ['Edit', 'Subject', 'Subject', 'Save'],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Billing Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the left-side pane, click Billing.',
                                            boldTexts: ['Billing'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '2. Select Pending Claims',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'From the sub-menu under Billing, click on Pending Claims.',
                                            boldTexts: ['Billing', 'Pending Claims'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Select the Pending Claim',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Pending Claims list, click on the specific claim where you want to edit a note.',
                                            boldTexts: ['Pending Claims'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Click on the Notes Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once in the Claim Details screen, click the Notes tab at the top of the page.',
                                            boldTexts: ['Claim Details', 'Notes'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Find the Note to Edit',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Locate the note you want to edit in the list of notes.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. Click the Edit Icon',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Action column next to the note, click the pencil/edit icon.',
                                            boldTexts: ['Action'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '7. Modify the Note',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the modal that pops up, you can edit the Subject and Body of the note.',
                                            boldTexts: ['Subject', 'Body'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '8. Save the Changes',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'After making the necessary updates, click Save. Your changes will now be reflected in the note list.',
                                            boldTexts: ['Save'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'DELETE_NOTES_PENDING_CLAIM': {
                subMenuTitle: 'Pending Claims',
                pageTitle: 'Deleting Notes from a Pending Claim',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Go to Billing > Pending Claims.',
                                            boldTexts: ['Billing', 'Pending'],
                                        },

                                        {
                                            title: '2. Select a claim and open the Notes tab.',
                                            boldTexts: ['Notes'],
                                        },

                                        {
                                            title: '3. Find the note to delete, click the Delete icon, and confirm by clicking Delete.',
                                            boldTexts: ['Delete'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Billing Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the left-side pane, click Billing',
                                            boldTexts: ['Billing'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Select Pending Claims',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'From the sub-menu under Billing, click on Pending Claims.',
                                            boldTexts: ['Billing', 'Pending Claims'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Select the Pending Claim',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Pending Claims list, click on the specific claim where the note you want to delete exists.',
                                            boldTexts: ['Pending Claims'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Click on the Notes Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once in the Claim Details screen, click the Notes tab at the top of the page.',
                                            boldTexts: ['Claim Details', 'Notes'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Find the Note to Delete',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Locate the note you want to remove in the list of notes.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. Click the Delete Icon',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Action column next to the note, click the red X icon.',
                                            boldTexts: ['Action'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '7. Confirm Deletion',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'A confirmation modal will appear. Verify that you want to delete the note, ' +
                                                'then click Delete. This action cannot be undone, and the note will be permanently removed from the claim.',
                                            boldTexts: ['Delete'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'VIEW_HISTORY_PENDING_CLAIM': {
                subMenuTitle: 'Pending Claims',
                pageTitle: 'Viewing History for a Pending Claim',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Go to Billing > Pending Claims.',
                                            boldTexts: ['Billing', 'Pending'],
                                        },

                                        {
                                            title: '2. Select a claim and open the History tab.',
                                            boldTexts: ['History'],
                                        },

                                        {
                                            title: '3. View the actions taken on the claim, including Date & Time, Staff Name, and Action Description.',
                                            boldTexts: ['Date', 'Time', 'Staff Name', 'Action Description'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Billing Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the left-side pane, click Billing',
                                            boldTexts: ['Billing'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Select Pending Claims',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'From the sub-menu under Billing, click on Pending Claims.',
                                            boldTexts: ['Billing', 'Pending Claims'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Select the Pending Claim',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Pending Claims list, click on the specific claim for which you want to view the history.',
                                            boldTexts: ['Pending Claims'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Click on the History Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once in the Claim Details screen, click the History tab at the top of the page.',
                                            boldTexts: ['Claim Details', 'Notes'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. View the History',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'The history of actions and updates related to this claim will appear ' +
                                                'in the table. The columns will show the Date & Time, Staff Name, and Action Description of each ' +
                                                'action taken on the claim.',
                                            boldTexts: ['Date', 'Time', 'Staff Name', 'Action Description'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'UPLOAD_FILES_PENDING_CLAIM': {
                subMenuTitle: 'Pending Claims',
                pageTitle: 'Uploading Files to Pending Claims',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Go to Billing > Pending Claims.',
                                            boldTexts: ['Billing', 'Pending'],
                                        },

                                        {
                                            title: '2. Open the Files tab and click Upload Document.',
                                            boldTexts: ['Files', 'Upload Document'],
                                        },

                                        {
                                            title: '3. Fill in the required fields, drag or upload your file, and click Add.',
                                            boldTexts: ['Add'],
                                        },

                                        {
                                            title: '4. The files will now appear under the Files tab.',
                                            boldTexts: ['Files'],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Access the Billing Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Billing section in the left pane.',
                                            boldTexts: ['Billing'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '2. Select Pending Claims',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Choose Pending Claims from the submenu.',
                                            boldTexts: ['Billing', 'Pending Claims'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Navigate to the Files Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on the Files tab at the top of the page.',
                                            boldTexts: ['Pending Claims'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Upload Files',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click the Upload Document button to add relevant documents or files associated with the claim.',
                                            boldTexts: ['Upload Document'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Once the button is clicked, the Upload Document modal will appear.',
                                            boldTexts: ['Upload Document'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Fill in the Fields and Upload Files',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Fill in the required fields in the Upload Document modal.',
                                            boldTexts: ['Upload Document'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Click to upload or drag and drop the file into the drag and drop box.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. Review Uploaded Files in the Modal',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once the file is dragged or selected, it will appear at the bottom of the modal.',
                                            boldTexts: [''],
                                            circle: true,
                                        },

                                        {
                                            title: 'Repeat this process for each additional file you would like to upload.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '7. Save the Uploaded Files',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'After adding all the files, click the Add button on the bottom right of the modal.',
                                            boldTexts: ['Add'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '8. View Uploaded Files',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'You will see the uploaded files listed in the table under the Files tab.',
                                            boldTexts: ['Files'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'EDIT_FILES_PENDING_CLAIM': {
                subMenuTitle: 'Pending Claims',
                pageTitle: 'Editing Pending Claim Files',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Go to Billing > Pending Claims.',
                                            boldTexts: ['Billing', 'Pending'],
                                        },

                                        {
                                            title: '2. Open the Files tab, click the Edit icon next to the file.',
                                            boldTexts: ['Files', 'Upload Document'],
                                        },

                                        {
                                            title: '3. Modify the Document Name or Description, or replace the file.',
                                            boldTexts: ['Add'],
                                        },

                                        {
                                            title: '4. Click Save to apply the changes.',
                                            boldTexts: ['Files'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Access the Pending Claims Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Billing section in the left pane.',
                                            boldTexts: ['Billing'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Select Pending Claims from the sub-menu.',
                                            boldTexts: ['Pending Claims'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Select the Claim',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Choose the claim for which you would like to edit files from the list of pending claims.',
                                            boldTexts: ['Billing', 'Pending Claims'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Navigate to the Files Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on the Files tab at the top of the page.',
                                            boldTexts: ['Files'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Edit the File',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the list of uploaded files, click the Edit (pencil) icon next to the file you want to modify.',
                                            boldTexts: ['Edit', 'pencil'],
                                            circle: true,
                                        },

                                        {
                                            title: 'The Edit Document modal will appear.',
                                            boldTexts: ['Edit Document'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Modify File Details',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Edit the Document Name or Description as needed.',
                                            boldTexts: ['Document Name', 'Description'],
                                            circle: true,
                                        },

                                        {
                                            title: 'You can also replace the file by clicking to upload or dragging and dropping a new file into the drag and drop box.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. Save Changes',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'After making the necessary edits, click the Save button on the bottom right of the modal.',
                                            boldTexts: ['Save'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '7. View Edited Files',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'The updated file will appear in the list under the Files tab.',
                                            boldTexts: ['Files'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'DELETE_FILES_PENDING_CLAIM': {
                subMenuTitle: 'Pending Claims',
                pageTitle: 'Deleting Pending Claim Files',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Go to Billing > Pending Claims.',
                                            boldTexts: ['Billing', 'Pending'],
                                        },

                                        {
                                            title: '2. Open the Files tab and click the Delete icon next to the file.',
                                            boldTexts: ['Files', 'Delete'],
                                        },

                                        {
                                            title: '3. Confirm the deletion by clicking Delete.',
                                            boldTexts: ['Delete'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Access the Pending Claims Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Billing section in the left pane.',
                                            boldTexts: ['Billing'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Select Pending Claims from the sub-menu.',
                                            boldTexts: ['Pending Claims'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Select the Claim',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Choose the claim for which you would like to delete files from the list of pending claims.',
                                            boldTexts: ['Billing', 'Pending Claims'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Navigate to the Files Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on the Files tab at the top of the page.',
                                            boldTexts: ['Files'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Delete the File',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the list of uploaded files, click the Delete (trash) icon next to the file you want to remove.',
                                            boldTexts: ['Delete', 'trash'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Confirm Deletion',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'A confirmation modal will appear asking if you\'re sure you want to delete the file. Click Delete to confirm.',
                                            boldTexts: ['Delete'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. View Updated Files',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'The file will be removed from the list under the Files tab.',
                                            boldTexts: ['Files'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'VIEW_FILES_PENDING_CLAIM': {
                subMenuTitle: 'Pending Claims',
                pageTitle: 'Viewing Files in a Pending Claim',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Go to Billing > Pending Claims.',
                                            boldTexts: ['Billing', 'Pending'],
                                        },

                                        {
                                            title: '2. Open the Files tab in the claim details.',
                                            boldTexts: ['Files'],
                                        },

                                        {
                                            title: '3. Click the eye icon next to the file to view it.',
                                            boldTexts: ['eye'],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Billing Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the left-side pane, click Billing.',
                                            boldTexts: ['Billing'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '2. Select Pending Claims',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'From the sub-menu under Billing, click on Pending Claims.',
                                            boldTexts: ['Billing', 'Pending Claims'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Select the Pending Claim',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Pending Claims list, click on the specific claim where you want to view a file.',
                                            boldTexts: ['Pending Claims'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Click on the Files Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once in the Claim Details screen, click the Files tab at the top of the page.',
                                            boldTexts: ['Claim Details', 'Files'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Locate the File',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the list of files under the Files tab, locate the file you want to view.',
                                            boldTexts: ['Files'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. View the File',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Action column on the far right, click the eye icon next to the file to view it. The file will open in a viewer, allowing you to see the contents of the document.',
                                            boldTexts: ['Action', 'eye icon'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'DOWN_FILES_PENDING_CLAIM': {
                subMenuTitle: 'Pending Claims',
                pageTitle: 'Downloading Files from a Pending Claim',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Go to Billing > Pending Claims.',
                                            boldTexts: ['Billing', 'Pending Claims'],
                                        },

                                        {
                                            title: '2. Open the Files tab in the claim details.',
                                            boldTexts: ['Files'],
                                        },

                                        {
                                            title: '3. Click the cloud icon next to the file to download it.',
                                            boldTexts: ['cloud icon'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Billing Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the left-side pane, click Billing.',
                                            boldTexts: ['Billing'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Select Pending Claims',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'From the sub-menu under Billing, click on Pending Claims',
                                            boldTexts: ['Billing', 'Pending Claims'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Select the Pending Claim',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Pending Claims list, click on the specific claim where you want to download a file.',
                                            boldTexts: ['Pending Claims'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Click on the Files Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once in the Claim Details screen, click the Files tab at the top of the page.',
                                            boldTexts: ['Claim Details', 'Files'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Locate the File',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the list of files under the Files tab, locate the file you want to download.',
                                            boldTexts: ['Files'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. Download the File',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Action column on the far right, click the cloud with arrow icon next to the file to download it. The file will be saved to your local device.',
                                            boldTexts: ['Action', 'cloud with arrow icon'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'ADD_RESUBMISSION_DETAILS_PENDING_CLAIM': {
                subMenuTitle: 'Pending Claims',
                pageTitle: 'Adding Resubmission Details to a Pending Claim',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Go to Billing > Pending Claims.',
                                            boldTexts: ['Billing', 'Pending Claims'],
                                        },

                                        {
                                            title: '2. Select a claim and click the Edit button in the top-right corner.',
                                            boldTexts: ['Edit'],
                                        },

                                        {
                                            title: '3. Enter details for Reason (Box 19), Resubmission Code (Box 22), and Original Ref. No (Box 22).',
                                            boldTexts: ['Reason', 'Box 19', 'Resubmission Code', 'Original Ref. No', 'Box 22'],
                                        },

                                        {
                                            title: '4. Click Save to apply the changes.',
                                            boldTexts: ['Save'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Access the Billing Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Navigate to the Billing section from the left pane.',
                                            boldTexts: ['Billing'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Click on Pending Claims to view the list of claims that are not yet submitted.',
                                            boldTexts: ['Pending Claims'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Select the Pending Claim',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Locate the claim that requires additional resubmission details from the list.',
                                            boldTexts: [''],
                                            circle: true,
                                        },

                                        {
                                            title: 'Click on the claim to open the Claim Details view.',
                                            boldTexts: ['Claim Details'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Open the Edit Modal',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Claim Details screen, click the Edit button (pencil icon) in the top-right corner.',
                                            boldTexts: ['Claim Details', 'Edit'],
                                            circle: true,
                                        },

                                        {
                                            title: 'A modal titled Edit Claim will appear, providing fields to input the following details',
                                            boldTexts: ['Claim Details', 'Edit'],
                                            circle: true,

                                            list: [
                                                {
                                                    title: 'Reason (Box 19)',
                                                    boldTexts: ['Reason', 'Box 19'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Resubmission Code (Box 22)',
                                                    boldTexts: ['Resubmission', 'Code', 'Box 22'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Original Ref. No (Box 22)',
                                                    boldTexts: ['Original Ref. No', 'Box 22'],
                                                    circle: true,
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Enter Resubmission Details',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Reason (Box 19) field, input the reason for needing to adjust or resubmit the claim.',
                                            boldTexts: ['Reason', 'Box 19'],
                                            circle: true,
                                        },

                                        {
                                            title: 'In Resubmission Code (Box 22), enter the relevant code for resubmitting the claim.',
                                            boldTexts: ['Resubmission Code', 'Box 22'],
                                            circle: true,
                                        },

                                        {
                                            title: 'In Original Ref. No (Box 22), provide the original reference number related to the claim.',
                                            boldTexts: ['Original Ref. No', 'Box 22'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Save the Information',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once the necessary information is filled out, click the Save button to apply the changes.',
                                            boldTexts: ['Save'],
                                            circle: true,
                                        },

                                        {
                                            title: 'The resubmission details will now be displayed in the Claim Details view with a red background to highlight the changes.',
                                            boldTexts: ['Claim Details'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. Review the Updated Claim',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'After saving, you will see the new resubmission details (Reason,' +
                                                ' Resubmission Code, and Original Ref. No) reflected under the Additional Claim Info section in the claim details.',
                                            boldTexts: ['Additional Claim Info'],
                                            circle: true,
                                        },

                                        {
                                            title: 'These fields will not yet submit the claim but will update the record with the necessary information for future actions.',
                                            boldTexts: ['Additional Claim Info'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'SUBMIT_PENDING_CLAIM': {
                subMenuTitle: 'Pending Claims',
                pageTitle: 'Submitting Pending Claims',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Go to Billing > Pending Claims.',
                                            boldTexts: ['Billing', 'Pending Claims'],
                                        },

                                        {
                                            title: '2. Select a claim and review the details in the Details tab.',
                                            boldTexts: ['Details'],
                                        },

                                        {
                                            title: '3. Click Submit Claim in the top-right corner.',
                                            boldTexts: [''],
                                        },

                                        {
                                            title: '4. Confirm submission by clicking Submit.',
                                            boldTexts: ['Submit'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Access the Pending Claims Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Navigate to the Billing section on the left pane.',
                                            boldTexts: ['Billing'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Select Pending Claims from the menu to view a list of pending claims.',
                                            boldTexts: ['Pending Claims'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Select the Claim',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Locate the claim you wish to submit and click on it to open its details.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Review Claim Details',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once inside the claim, review all the details on the Details tab, including billing entries, units, and amounts.',
                                            boldTexts: ['Details'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Submit the Claim',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on the Submit Claim button located at the top-right corner of the page.',
                                            boldTexts: ['Reason', 'Box 19'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Confirm Submission',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'A modal will appear asking for confirmation. Click Submit to confirm.',
                                            boldTexts: ['Submit'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. View the Submitted Claim',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'After submission, the claim will move from the Pending Claims section to the Billed Claims section.',
                                            boldTexts: ['Pending Claims', 'Billed Claims'],
                                            circle: true,
                                        },

                                        {
                                            title: 'The claim will also appear in the Claim Payments section where payment tracking can be managed.',
                                            boldTexts: ['Claim Payments'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
        },

        'BILLED_CLAIMS': {
            'VIEW_BILLING_CLAIMS': {
                subMenuTitle: 'Billed Claims',
                pageTitle: 'Viewing the Billed Claims List',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Billing > Billed Claims from the left pane.',
                                            boldTexts: ['Billing', 'Billed Claims'],
                                        },

                                        {
                                            title: '2. Review the table, which shows details such as Batch ID, Submitted Date, Client Code, and Billed Amount.',
                                            boldTexts: ['Batch ID', 'Submitted Date', 'Client Code', 'Billed Amount'],
                                        },

                                        {
                                            title: '3. Download the claim form by clicking Download under the Claims Form column.',
                                            boldTexts: ['Download', 'Claims Form'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Access the Billing Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Navigate to the Billing section from the left pane.',
                                            boldTexts: ['Billing'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Select Billed Claims',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on Billed Claims to view the list of all billed claims.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. View Billed Claims List',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'The system will display a table showing details such as',
                                            boldTexts: ['Details'],
                                            circle: true,

                                            list: [
                                                {
                                                    title: 'Batch ID',
                                                    boldTexts: ['Batch ID'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Submitted Date',
                                                    boldTexts: ['Submitted Date'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Submitted By',
                                                    boldTexts: ['Submitted By'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Date of Service (DOS)',
                                                    boldTexts: ['Date of Service', 'DOS'],
                                                    circle: true,
                                                },
                                            ],
                                        },
                                        {
                                            title: 'You can also download the claim form by clicking the Download link in the right column under "Claims Form."',
                                            boldTexts: ['Download'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'VIEW_DETAILS_BILLED_CLAIMS': {
                subMenuTitle: 'Billed Claims',
                pageTitle: 'Viewing the Details of a Billed Claim',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Billing > Billed Claims from the left pane.',
                                            boldTexts: ['Billing', 'Billed Claims'],
                                        },

                                        {
                                            title: '2. Locate the Batch ID and click on the row to expand more detailed claims.',
                                            boldTexts: ['Batch ID'],
                                        },

                                        {
                                            title: '3. Click on the Claim ID to view its details.',
                                            boldTexts: ['Claim ID'],
                                        },

                                        {
                                            title: '4. Review the detailed information including Staff, Client, CPT Code, and Billing Entries.',
                                            boldTexts: ['Staff', 'Client', 'CPT Code', 'Billing Entries'],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Access the Billing Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Navigate to the Billing section from the left pane.',
                                            boldTexts: ['Billing'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '2. Select Billed Claims',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on Billed Claims to view the list of billed claims.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Expand the Claim Batch',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Locate the Batch ID for the claim you want to view.',
                                            boldTexts: ['Batch ID'],
                                            circle: true,
                                        },
                                        {
                                            title: 'Click on the row to expand it, showing a more detailed breakdown of individual claims in the batch.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. View a Specific Claim',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on the specific Claim ID to open its details.',
                                            boldTexts: ['Batch ID'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Claim Details Page',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'The system will navigate to the Claim Details page.',
                                            boldTexts: ['Claim Details'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Here, you can see detailed information including',
                                            boldTexts: ['Claim Details'],
                                            circle: true,

                                            list: [
                                                {
                                                    title: 'Staff, Client, and Funding Source',
                                                    boldTexts: [''],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Status (Open, Pending, etc.)',
                                                    boldTexts: [''],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Billing entries (e.g., CPT Code, Units, Billed, Allowed, Balance)',
                                                    boldTexts: [''],
                                                    circle: true,
                                                },
                                            ],
                                        },

                                        {
                                            title: 'Additional claim information (such as Resubmission Code and Original Reference No.) ' +
                                                'will also appear in case of resubmissions.',
                                            boldTexts: ['Claim Details'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'DOWN_CSV_BILLED_CLAIMS': {
                subMenuTitle: 'Billed Claims',
                pageTitle: 'Downloading a CSV of Billed Claims',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Billing > Billed Claims from the left pane.',
                                            boldTexts: ['Billing', 'Billed Claims'],
                                        },

                                        {
                                            title: '2. Click the cloud icon at the top right to download a CSV.',
                                            boldTexts: ['cloud icon'],
                                        },

                                        {
                                            title: '3. Select either General Report or Detail Report.',
                                            boldTexts: ['General Report', 'Detail Report'],
                                        },

                                        {
                                            title: '4. Use filters like Staff or Date Range to narrow the data.',
                                            boldTexts: ['Staff', 'Date Range'],
                                        },

                                        {
                                            title: '5. Click Download CSV to generate and download the file.',
                                            boldTexts: ['Download CSV'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Access the Billing Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Navigate to the Billing section from the left pane.',
                                            boldTexts: ['Billing'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Open Billed Claims',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on Billed Claims to view the list of all claims that have been billed.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Click the Cloud Download Icon',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'At the top right of the Billed Claims section, click the cloud icon with an arrow to open the Download Billing CSV modal.',
                                            boldTexts: ['Billed Claims', 'with an arrow', 'Download Billing CSV'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Select Report Type',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the modal, choose between a General Report or a Detail Report',
                                            boldTexts: ['General Report', 'Detail Report'],
                                            circle: true,
                                            list: [
                                                {
                                                    title: 'General Report: Provides an overview of claims.',
                                                    boldTexts: ['General Report'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Detail Report: Provides more specific details about each claim.',
                                                    boldTexts: ['Detail Report'],
                                                    circle: true,
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Filter the Data',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Select filters for the CSV export',
                                            boldTexts: [''],
                                            circle: true,

                                            list: [
                                                {
                                                    title: 'Staff: Choose a staff member from the dropdown if you want to filter claims by staff.',
                                                    boldTexts: ['Staff'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Start Date and End Date: Set the date range for which you want to download claims.',
                                                    boldTexts: ['Start Date and End Date'],
                                                    circle: true,
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. Download CSV',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once you\'ve set your filters and selected the report type, click Download CSV.',
                                            boldTexts: [''],
                                            circle: true,
                                        },

                                        {
                                            title: 'The CSV file will be generated and downloaded with the billing data based on your selected filters.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'CLOD_BILLED_CLAIM': {
                subMenuTitle: 'Billed Claims',
                pageTitle: 'Closing a Billed Claim',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Billing > Billed Claims from the left pane.',
                                            boldTexts: ['Billing', 'Billed Claims'],
                                        },

                                        {
                                            title: '2. Expand the claim and click the Claim ID.',
                                            boldTexts: ['Claim ID'],
                                        },

                                        {
                                            title: '3. Click the Close Claim button in the top right.',
                                            boldTexts: ['Close Claim'],
                                        },

                                        {
                                            title: '4. Enter a reason for closing the claim in the modal and click Close.',
                                            boldTexts: [''],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Access the Billing Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Navigate to the Billing section from the left pane.',
                                            boldTexts: ['Billing'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '2. Go to Billed Claims',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on Billed Claims to view the list of all billed claims.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Open the Claim Details',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Find the claim you want to close. Click on the claim’s row to expand it, then click the claim ID to open its details.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Initiate the Close Process',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the top right of the Claim Details page, click the red Close Claim button.',
                                            boldTexts: ['General Report', 'Detail Report'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Enter the Closing Reason',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'A modal titled Close this claim? will appear.',
                                            boldTexts: ['Close this claim'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Add a reason for closing the claim in the text box.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. Confirm Claim Closure',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once you’ve entered the reason, click the Close button to confirm.',
                                            boldTexts: ['Close'],
                                            circle: true,
                                        },

                                        {
                                            title: 'The claim will now be marked as Closed in the system.',
                                            boldTexts: ['Closed'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
        },

        'CLAIM_PAYMENTS': {
            'VIEW_OPEN_CLAIM_PAYMENTS': {
                subMenuTitle: 'Claim Payments',
                pageTitle: 'Viewing Open Claim Payments',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Billing > Claim Payments from the left pane.',
                                            boldTexts: ['Billing', 'Claim Payments'],
                                        },

                                        {
                                            title: '2. The default view will show Open claims.',
                                            boldTexts: ['Open claims'],
                                        },

                                        {
                                            title: '3. Review the list of open claims, including details like CPT Code, Billed Amount, and Balance.',
                                            boldTexts: ['CPT Code', 'Billed Amount', 'Balance'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Access the Billing Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Navigate to the Billing section from the left pane.',
                                            boldTexts: ['Billing'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Go to Claim Payments',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on Claim Payments to view all payment-related claims.',
                                            boldTexts: ['Claim Payments'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. View Open Claims',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'The default view will show Open claims. These are claims that have not been fully paid or resolved.',
                                            boldTexts: ['Open'],
                                            circle: true,
                                        },
                                        {
                                            title: 'Review the list of open claim payments, including the CPT code, billed amounts, allowed amounts, and balance.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'VIEW_APPEALED_CLAIM_PAYMENTS': {
                subMenuTitle: 'Claim Payments',
                pageTitle: 'Viewing Appealed Claim Payments',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Billing > Claim Payments in the left pane.',
                                            boldTexts: ['Billing', 'Claim Payments'],
                                        },

                                        {
                                            title: '2. Click on the Appeals tab at the top of the page.',
                                            boldTexts: ['Appeals'],
                                        },

                                        {
                                            title: '3. Review the list of appealed claims, including details such as Claim ID, Billed Amount, and Allowed Amount.',
                                            boldTexts: ['Claim ID', 'Billed Amount', 'Allowed Amount'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Claim Payments Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Claim Payments section in the left pane.',
                                            boldTexts: ['Billing'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Click on the Appeals Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'At the top of the page, click the Appeals tab to view the list of claims that have been appealed.',
                                            boldTexts: ['Appeals'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Review Appealed Claims',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In this section, you will see a list of claims that are currently in appeal status.',
                                            boldTexts: [''],
                                            circle: true,
                                        },

                                        {
                                            title: 'You can view details such as Claim ID, Date of Service (DOS), Billed Amount, Allowed Amount, and other relevant fields.',
                                            boldTexts: ['Claim ID', 'Date of Service', 'DOS', 'Billed Amount', 'Allowed Amount'],
                                            circle: true,
                                        },

                                        {
                                            title: 'You can click on the Claim ID to view further details or manage the appeal.',
                                            boldTexts: ['Claim ID'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'VIEW_COMPLETED_CLAIM_PAYMENTS': {
                subMenuTitle: 'Claim Payments',
                pageTitle: 'Viewing Completed Claim Payments',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Billing > Claim Payments from the left pane.',
                                            boldTexts: ['Billing', 'Claim Payments'],
                                        },

                                        {
                                            title: '2. Click on the Completed tab to view claims that are Paid or Closed.',
                                            boldTexts: ['Completed', 'Paid', 'Closed'],
                                        },

                                        {
                                            title: '3. Review the completed claims, including details like Claim ID, Billed Amount, and Paid Amount.',
                                            boldTexts: ['Claim ID', 'Billed Amount', 'Paid Amount'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Claim Payments Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Claim Payments section in the left pane.',
                                            boldTexts: ['Billing'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Click on the Completed Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'At the top of the page, click the Completed tab to view the list of claims that have been fully paid or closed.',
                                            boldTexts: ['Completed'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Review Completed Claims',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In this section, you will see a list of claims that have been marked as Paid in Full or Closed.',
                                            boldTexts: ['Paid in Full', 'Closed'],
                                            circle: true,
                                        },

                                        {
                                            title: 'You can view details such as Claim ID, Date of Service (DOS), Billed Amount, Paid Amount, and Balance.',
                                            boldTexts: ['Claim ID', 'Date of Service', 'DOS', 'Billed Amount', 'Paid Amount', 'Balance'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Click on the Claim ID to view more details or check the status of the payments.',
                                            boldTexts: ['Claim ID'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'INPUTTING_CLAIM_PAYMENT_STATUS_OPEN': {
                subMenuTitle: 'Claim Payments',
                pageTitle: 'Inputting Claim Payments When the Claim Payment Status Is Open',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Billing > Claim Payments from the left pane and select the Open tab.',
                                            boldTexts: ['Billing', 'Claim Payments'],
                                        },

                                        {
                                            title: '2. Locate the claim you want to process in the list.',
                                            boldTexts: ['pencil icon'],
                                        },

                                        {
                                            title: '3. Go to the Claim Payments section in the left pane and click on the Open tab to view open claims.',
                                            boldTexts: ['Allowed', 'Deductible', 'Copay', 'Paid'],
                                        },

                                        {
                                            title: '4. Locate the claim you want to process in the list.',
                                            boldTexts: ['Save', ' Pay in Full'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Find the Claim',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Claim Payments section in the left pane and click on the Open tab to view open claims.',
                                            boldTexts: ['Claim Payments'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Locate the claim you want to process in the list.',
                                            boldTexts: ['Claim Payments'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Edit the Claim Payment',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click the blue pencil icon next to the claim to edit the payment details.',
                                            boldTexts: ['blue pencil icon'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Input the Payment Details',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Enter the amounts provided by the funding source in the available fields:',
                                            boldTexts: ['Paid in Full', 'Closed'],
                                            circle: true,

                                            list: [
                                                {
                                                    title: 'Allowed: This field auto-populates based on the Funding Source > Services > Charge Rates but can be edited if necessary.',
                                                    boldTexts: ['Allowed', 'Services', 'Charge Rates'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Deduct.: Input the deductible amount.',
                                                    boldTexts: ['Deduct'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Copay: Enter any copayment amount.',
                                                    boldTexts: ['Copay'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'CoINS: Input the co-insurance amount.',
                                                    boldTexts: ['CoINS'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Paid: Enter the amount paid by the insurance.',
                                                    boldTexts: ['Paid'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Balance: This will automatically calculate based on the other fields.',
                                                    boldTexts: ['Balance'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Billed: The billed amount is auto-filled from the Funding Source > Services > Charge Rates.',
                                                    boldTexts: ['Billed'],
                                                    circle: true,
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Save or Exit',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'To exit without saving, press the X button next to the Save button.',
                                            boldTexts: ['X', 'Save'],
                                            circle: true,
                                        },

                                        {
                                            title: 'To save the changes, press the Save button.',
                                            boldTexts: ['Save'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Mark the Claim as Paid in Full',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'If no further edits are needed for the bill, click Pay in Full next to the bill in the claim. The system will auto-calculate the paid amount.',
                                            boldTexts: ['Pay in Ful'],
                                            circle: true,

                                            list: [
                                                {
                                                    title: 'A confirmation modal will appear. Press Confirm to complete the process.',
                                                    boldTexts: ['A confirmation modal', 'Confirm'],
                                                    circle: true,
                                                },
                                            ],
                                        },

                                        {
                                            title: 'Alternatively, if no edits are needed for the entire claim, click the Pay in Full button on ' +
                                                'the top right of the claim line (next to Appeal) to mark all bills inside the claim as paid.',
                                            boldTexts: ['Pay in Ful', 'Appeal'],
                                            circle: true,

                                            list: [
                                                {
                                                    title: 'A confirmation modal will appear for the entire claim. Press Confirm to mark the whole claim as paid.',
                                                    boldTexts: ['A confirmation modal', 'Confirm'],
                                                    circle: true,
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. Claim Completion',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once all bills are marked as paid, the claim will be moved to the Completed tab automatically.',
                                            boldTexts: ['Completed'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'APPEALING_CLAIM_PAYMENTS': {
                subMenuTitle: 'Claim Payments',
                pageTitle: 'Appealing a Claim Payments',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Billing > Claim Payments from the left pane and select the Open tab.',
                                            boldTexts: ['Billing', 'Claim Payments', 'Open'],
                                        },

                                        {
                                            title: '2. Locate the claim and click the Appeal button.',
                                            boldTexts: ['Appeal'],
                                        },

                                        {
                                            title: '3. Enter the reason for the appeal in the modal and click Appeal to submit.',
                                            boldTexts: ['Appeal'],
                                        },

                                        {
                                            title: '4. The claim will be moved to the Appeals tab for further action.',
                                            boldTexts: ['Appeals'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Find the Claim',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Claim Payments section in the left pane and click on the Open tab to view the list of open claims.',
                                            boldTexts: ['Claim Payments'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Locate the claim you want to appeal.',
                                            boldTexts: ['Claim Payments'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Initiate the Appeal',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on the red Appeal button next to the claim or bill you want to appeal.',
                                            boldTexts: ['Appeal'],
                                            circle: true,
                                        },

                                        {
                                            title: 'A modal titled "Appeal this claim?" will appear, prompting you to provide a reason for the appeal.',
                                            boldTexts: ['Appeal this claim'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Input the Appeal Reason',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Reason for appeal field, type the reason for the appeal (maximum of 100 characters).',
                                            boldTexts: ['Reason for appeal'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Submit the Appeal',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once the reason is filled out, click the Appeal button at the bottom of the modal to submit your appeal.',
                                            boldTexts: ['Appeal'],
                                            circle: true,
                                        },

                                        {
                                            title: 'If you need to cancel, click the Cancel button to exit the modal without submitting.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Claim Moves to Appeals Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'After submitting the appeal, the claim will automatically be moved to the Appeals tab for further processing.',
                                            boldTexts: ['Appeals'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'PAYING_CLAIM_PAYMENTS': {
                subMenuTitle: 'Claim Payments',
                pageTitle: 'Paying Appealed Claims',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Billing > Claim Payments and click the Appeals tab.',
                                            boldTexts: ['Appeals'],
                                        },

                                        {
                                            title: '2. Locate the claim and click the pencil icon to edit payment details.',
                                            boldTexts: [''],
                                        },

                                        {
                                            title: '3. Input the Allowed, Deductible, Copay, and Paid amounts.',
                                            boldTexts: ['Allowed', 'Deductible', 'Copay', 'Paid'],
                                        },

                                        {
                                            title: '4. Click Save or Pay in Full to process the payment.',
                                            boldTexts: ['Save', 'Pay in Full'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Claim Payments Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Claim Payments section in the left pane.',
                                            boldTexts: ['Claim Payments'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Click on the Appeals Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click the Appeals tab at the top of the page to view the claims currently in appeal status.',
                                            boldTexts: ['Appeals'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Locate the Claim',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Find the claim you want to process in the Appeals list.',
                                            boldTexts: ['Appeals'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Edit the Claim Payment',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click the blue pencil icon next to the claim to edit the payment details',
                                            boldTexts: ['Appeal'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Input the Payment Details',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Enter the amounts provided by the funding source in the available fields',
                                            boldTexts: [''],
                                            circle: true,

                                            list: [
                                                {
                                                    title: 'Allowed: This field auto-populates from the Funding Source > Services > Charge Rates but can be edited if necessary.',
                                                    boldTexts: ['Allowed', 'Funding Source', 'Services', 'Charge Rates'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Deduct.: Input the deductible amount.',
                                                    boldTexts: ['Deduct'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Copay: Enter any copayment amount.',
                                                    boldTexts: ['Copay'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'CoINS: Input the co-insurance amount.',
                                                    boldTexts: ['CoINS'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Paid: Enter the amount paid by the insurance.',
                                                    boldTexts: ['Paid'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Balance: This will automatically calculate based on the other fields.',
                                                    boldTexts: ['Balance'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Billed: The billed amount is auto-filled from the Funding Source > Services > Charge Rates.',
                                                    boldTexts: ['Billed', 'Funding Source', 'Services', 'Charge Rates'],
                                                    circle: true,
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. Save or Exit',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'To exit without saving, press the X button next to the Save button.',
                                            boldTexts: ['X', 'Save'],
                                            circle: true,
                                        },

                                        {
                                            title: 'To save the changes, press the Save button.',
                                            boldTexts: ['Save'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '7. Mark the Claim as Paid in Full',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'If no further edits are needed for the bill, click Paid in Full next to the bill, and the system will auto-calculate the paid amount.',
                                            boldTexts: ['Paid in Ful'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Alternatively, if no edits are needed for the entire claim, click the Pay in Full button on the top right of the claim line to mark all bills inside the claim as paid.',
                                            boldTexts: ['Pay in Full'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '8. Confirmation Modal',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'A confirmation modal will appear asking, “Are you sure you want to pay in full this bill/claim?” Click Confirm to proceed.',
                                            boldTexts: ['confirmation modal', 'Confirm'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '9. Claim Status Update',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'If partial payment is made or further action is required, the claim will be moved back to the Open tab.',
                                            boldTexts: ['If partial payment is made', 'Open'],
                                            circle: true,
                                        },

                                        {
                                            title: 'If fully paid, the claim will be automatically moved to the Completed tab.',
                                            boldTexts: ['If fully paid', 'Completed'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '10. Closing the Claim',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Alternatively, click on the Claim Number to be taken to the Claim Details page.',
                                            boldTexts: ['Claim Number', 'Claim Details'],
                                            circle: true,
                                        },

                                        {
                                            title: 'On the top right of the Claim Details page, click the Close Claim button.',
                                            boldTexts: ['Claim Details'],
                                            circle: true,
                                        },

                                        {
                                            title: 'The claim will be closed, and it will automatically be moved to the Completed tab.',
                                            boldTexts: ['Completed'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'EDIT_CLOSED_PAID_CLAIM_COMPLETED_TAB': {
                subMenuTitle: 'Claim Payments',
                pageTitle: 'Editing Closed or Paid Claims in the Completed Tab',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Billing > Claim Payments and click the Completed tab.',
                                            boldTexts: ['Billing', 'Claim Payments', 'Completed'],
                                        },

                                        {
                                            title: '2. Find the claim and click the pencil icon to make changes (if not fully closed).',
                                            boldTexts: ['pencil icon'],
                                        },

                                        {
                                            title: '3. If closed, click the folder icon to view details (editing is restricted for closed claims).',
                                            boldTexts: ['folder icon'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Completed Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Claim Payments section in the left pane and click on the Completed tab to view claims that are fully paid or closed.',
                                            boldTexts: ['Claim Payments'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Find the Claim',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Look for the claim you want to edit from the list of completed claims.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Edit the Claim',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click the blue pencil icon next to the claim to make any changes.',
                                            boldTexts: ['blue pencil'],
                                            circle: true,
                                        },

                                        {
                                            title: 'If the claim is closed, the blue folder icon is available to view ' +
                                                'details of the claim but no further edits can be made on closed claims.',
                                            boldTexts: ['blue folder icon'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'ADD_NOTES_CLAIM_FROM_PAYMENTS': {
                subMenuTitle: 'Claim Payments',
                pageTitle: 'Adding Notes to a Claim from Claim Payments',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Billing > Claim Payments and select the appropriate tab (Open, Appeals, or Completed).',
                                            boldTexts: ['Billing', 'Claim Payments', 'Open', 'Appeals', 'Completed'],
                                        },

                                        {
                                            title: '2. Click on the folder icon next to the claim.',
                                            boldTexts: ['folder icon'],
                                        },

                                        {
                                            title: '3. Click the Add Note button, enter your note, and click Save.',
                                            boldTexts: ['Add Note', 'Save'],
                                        },
                                    ],
                                },

                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Claim Payments Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the left pane, click on Claim Payments and select the appropriate tab ' +
                                                '(Open, Appeals, or Completed) based on the claim status.',
                                            boldTexts: ['Claim Payments'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Select the Claim',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on the blue folder icon next to the claim to access additional claim details.',
                                            boldTexts: ['blue folder icon'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Add a Note',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the claim details, click on the Add Note button on the top right of the modal.',
                                            boldTexts: ['Add Note'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Enter your note in the provided text box and click Save to attach the note to the claim.',
                                            boldTexts: ['Save'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'VIEW_NOTES_CLAIM_PAYMENTS_CLAIM': {
                subMenuTitle: 'Claim Payments',
                pageTitle: 'Viewing Notes on a Claim from Claim Payments',
                content: {
                    short: [
                        {
                            list: [
                                {
                                    list: [
                                        {
                                            title: '1. Navigate to Billing > Claim Payments from the left pane.',
                                            boldTexts: ['Billing', 'Claim Payments'],
                                        },

                                        {
                                            title: '2. Click on the folder icon next to the claim.',
                                            boldTexts: ['folder'],
                                        },

                                        {
                                            title: '3. Select the note you wish to view, and it will display in a modal.',
                                            boldTexts: [''],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Access Claim Notes',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'From the Claim Payments section, click on the blue folder icon next to the claim where you want to view notes.',
                                            boldTexts: ['Claim Payments', 'blue folder icon'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '2. View Notes',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once inside the claim details, select the note you want to view, and ' +
                                                'it will display in a modal. You can see who added the note and the date/time it was created.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
        },

        'INVOICES': {
            'VIEW_INVOICES': {
                subMenuTitle: 'Invoices',
                pageTitle: 'Viewing Invoices',
                content: {
                    short: [
                        {
                            subTitle: '1. Navigate to the Invoices Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Billing section in the left pane and click on Invoices.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. View the Invoice List',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Invoices will be listed with details such as Invoice ID, Invoice Date,' +
                                                ' Client, Total Amount, Payment Amount, Balance, and Status (Open, Paid, etc.).',
                                            boldTexts: ['Claim Payments'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Filter Invoices (Optional)',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Use the filters at the top of the page to sort by Client, Date of Service (DOS),' +
                                                ' or Invoice Date. These filters will help you narrow down the list of invoices to find specific ones.',
                                            boldTexts: ['Open'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Invoices Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Billing section in the left pane and click on Invoices.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. View the Invoice List',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Invoices will be listed with details such as Invoice ID, Invoice Date,' +
                                                ' Client, Total Amount, Payment Amount, Balance, and Status (Open, Paid, etc.).',
                                            boldTexts: ['Claim Payments'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Filter Invoices (Optional)',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Use the filters at the top of the page to sort by Client, Date of Service (DOS),' +
                                                ' or Invoice Date. These filters will help you narrow down the list of invoices to find specific ones.',
                                            boldTexts: ['Open'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'DOWN_INVOICE_CSV': {
                subMenuTitle: 'Invoices',
                pageTitle: 'Downloading Invoice CSV',
                content: {
                    short: [
                        {
                            subTitle: '1. Navigate to the Invoices Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Billing section in the left pane and click on Invoices.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Download Invoice CSV',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the top right of the Invoices screen, click the Download CSV button.',
                                            boldTexts: ['Download CSV'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Select Filters for CSV Download',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'A modal will appear to filter the data you want to include in the CSV. ' +
                                                'The fields are pre-filled based on your current filters.',
                                            boldTexts: [''],
                                            circle: true,
                                        },

                                        {
                                            title: 'You can set',
                                            boldTexts: [''],
                                            circle: true,

                                            list: [
                                                {
                                                    title: 'Client: Filter by specific clients.',
                                                    boldTexts: ['Client'],
                                                },

                                                {
                                                    title: 'Status: Select between Open, Paid, or other statuses.',
                                                    boldTexts: ['Status'],
                                                },

                                                {
                                                    title: 'Start Date and End Date: Define the invoice date range for the download.',
                                                    boldTexts: ['Start Date and End Date'],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Download',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'After selecting your filters, click the Download CSV button to generate the file.',
                                            boldTexts: ['Download CSV'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Invoices Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Billing section in the left pane and click on Invoices.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Download Invoice CSV',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the top right of the Invoices screen, click the Download CSV button.',
                                            boldTexts: ['Download CSV'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Select Filters for CSV Download',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'A modal will appear to filter the data you want to include in the CSV. ' +
                                                'The fields are pre-filled based on your current filters.',
                                            boldTexts: [''],
                                            circle: true,
                                        },

                                        {
                                            title: 'You can set',
                                            boldTexts: [''],
                                            circle: true,

                                            list: [
                                                {
                                                    title: 'Client: Filter by specific clients.',
                                                    boldTexts: ['Client'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Status: Select between Open, Paid, or other statuses.',
                                                    boldTexts: ['Status'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Start Date and End Date: Define the invoice date range for the download.',
                                                    boldTexts: ['Start Date and End Date'],
                                                    circle: true,
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Download',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'After selecting your filters, click the Download CSV button to generate the file.',
                                            boldTexts: ['Download CSV'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'VIEW_INVOICES_DETAILS': {
                subMenuTitle: 'Invoices',
                pageTitle: 'Viewing Invoice Details',
                content: {
                    short: [
                        {
                            subTitle: '1. Navigate to the Invoices Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Billing section in the left pane and click on Invoices.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Click on the Invoice Line',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Find the invoice you want to view from the list of invoices.',
                                            boldTexts: [''],
                                            circle: true,
                                        },

                                        {
                                            title: 'Click on the Invoice ID to open the Invoice Details page.',
                                            boldTexts: ['Invoice ID', 'Invoice Details'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. View Invoice Details',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'The Invoice Details page will show important information such as',
                                            boldTexts: [''],
                                            circle: true,

                                            list: [
                                                {
                                                    title: 'Client and Date of Service (DOS)',
                                                    boldTexts: ['Client and Date of Service', 'DOS'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Total Hours, Total Amount, Paid Amount, and Amount Due',
                                                    boldTexts: ['Total Hours', 'Total Amount', 'Paid Amount', 'Amount Due'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Invoice Date, Term, and Due Date',
                                                    boldTexts: ['Invoice Date', 'Term', 'Due Date'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'The Status of the invoice (Open, Paid, etc.)',
                                                    boldTexts: ['Status'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'You can also download the PDF version of the invoice.',
                                                    boldTexts: ['PDF'],
                                                    circle: true,
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. View the Bills',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'The Bills section displays the individual line items for services provided, ' +
                                                'including Bill ID, Dos, Service Code, Description, Hours, Total Billed, Copay, Coinsurance,' +
                                                ' Deductible, and Amount Due.',
                                            boldTexts: ['Bill ID', 'Dos', 'Service Code', 'Description', 'Hours', 'Total Billed', 'Copay', 'Coinsurance', 'Deductible', 'Amount Due'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Invoices Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Billing section in the left pane and click on Invoices.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Click on the Invoice Line',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Find the invoice you want to view from the list of invoices.',
                                            boldTexts: [''],
                                            circle: true,
                                        },

                                        {
                                            title: 'Click on the Invoice ID to open the Invoice Details page.',
                                            boldTexts: ['Invoice ID', 'Invoice Details'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. View Invoice Details',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'The Invoice Details page will show important information such as',
                                            boldTexts: [''],
                                            circle: true,

                                            list: [
                                                {
                                                    title: 'Client and Date of Service (DOS)',
                                                    boldTexts: ['Client and Date of Service', 'DOS'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Total Hours, Total Amount, Paid Amount, and Amount Due',
                                                    boldTexts: ['Total Hours', 'Total Amount', 'Paid Amount', 'Amount Due'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'Invoice Date, Term, and Due Date',
                                                    boldTexts: ['Invoice Date', 'Term', 'Due Date'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'The Status of the invoice (Open, Paid, etc.)',
                                                    boldTexts: ['Status'],
                                                    circle: true,
                                                },

                                                {
                                                    title: 'You can also download the PDF version of the invoice.',
                                                    boldTexts: ['PDF'],
                                                    circle: true,
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. View the Bills',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'The Bills section displays the individual line items for services provided, ' +
                                                'including Bill ID, Dos, Service Code, Description, Hours, Total Billed, Copay, Coinsurance,' +
                                                ' Deductible, and Amount Due.',
                                            boldTexts: ['Bill ID', 'Dos', 'Service Code', 'Description', 'Hours', 'Total Billed', 'Copay', 'Coinsurance', 'Deductible', 'Amount Due'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'GENERATE_INVOICES': {
                subMenuTitle: 'Invoices',
                pageTitle: 'Generating Invoices',
                content: {
                    short: [
                        {
                            subTitle: '1. Access the Invoices Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Billing section in the left pane and click on Invoices.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Initiate the Invoice Generation',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the top right of the Invoices list, click the + Generate Invoice button.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Review the Ungenerated Invoices',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'A modal will appear displaying ungenerated invoices, which can consist of multiple bills and claims combined for each client.',
                                            boldTexts: [''],
                                            circle: true,
                                        },

                                        {
                                            title: 'Review the list of services, dates, and amounts.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Select the Invoices to Generate',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Ensure the checkboxes are selected for the invoices you want to generate.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Generate the Invoices',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once everything is reviewed, click Generate Invoices to complete the process.',
                                            boldTexts: ['Generate Invoices'],
                                            circle: true,
                                        },

                                        {
                                            title: 'The newly generated invoices will now appear in the Invoices list.',
                                            boldTexts: ['Invoices'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Access the Invoices Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Billing section in the left pane and click on Invoices.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Initiate the Invoice Generation',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the top right of the Invoices list, click the + Generate Invoice button.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Review the Ungenerated Invoices',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'A modal will appear displaying ungenerated invoices, which can consist of multiple bills and claims combined for each client.',
                                            boldTexts: [''],
                                            circle: true,
                                        },

                                        {
                                            title: 'Review the list of services, dates, and amounts.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Select the Invoices to Generate',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Ensure the checkboxes are selected for the invoices you want to generate.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Generate the Invoices',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once everything is reviewed, click Generate Invoices to complete the process.',
                                            boldTexts: ['Generate Invoices'],
                                            circle: true,
                                        },

                                        {
                                            title: 'The newly generated invoices will now appear in the Invoices list.',
                                            boldTexts: ['Invoices'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'PAY_INVOICE_FULL': {
                subMenuTitle: 'Invoices',
                pageTitle: 'Paying Invoices in Full',
                content: {
                    short: [
                        {
                            subTitle: '1. Access the Invoices Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Billing section on the left pane and click on Invoices to view the list of all invoices.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Find the Invoice to Pay',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Locate the invoice you want to pay in full from the list.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Pay the Invoice',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click the blue Pay in Full button in the Actions column next to the invoice.',
                                            boldTexts: ['Pay in Full', 'Actions'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Invoice Status Update',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'After clicking Pay in Full, the system will update the status of the invoice to Paid.',
                                            boldTexts: ['Pay in Ful', 'Paid'],
                                            circle: true,
                                        },

                                        {
                                            title: 'The Pmt. AMT (Payment Amount) will be adjusted to match the Total AMT (Total Amount).',
                                            boldTexts: ['Pmt. AMT'],
                                            circle: true,
                                        },

                                        {
                                            title: 'The Balance will automatically become $0.',
                                            boldTexts: ['Balance', '$', '0'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Access the Invoices Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Billing section on the left pane and click on Invoices to view the list of all invoices.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Find the Invoice to Pay',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Locate the invoice you want to pay in full from the list.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Pay the Invoice',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click the blue Pay in Full button in the Actions column next to the invoice.',
                                            boldTexts: ['Pay in Full', 'Actions'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Invoice Status Update',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'After clicking Pay in Full, the system will update the status of the invoice to Paid.',
                                            boldTexts: ['Pay in Ful', 'Paid'],
                                            circle: true,
                                        },

                                        {
                                            title: 'The Pmt. AMT (Payment Amount) will be adjusted to match the Total AMT (Total Amount).',
                                            boldTexts: ['Pmt. AMT'],
                                            circle: true,
                                        },

                                        {
                                            title: 'The Balance will automatically become $0.',
                                            boldTexts: ['Balance', '$', '0'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'EDIT_INVOICE': {
                subMenuTitle: 'Invoices',
                pageTitle: 'Editing an Invoice',
                content: {
                    short: [
                        {
                            subTitle: '1. Access the Invoice List',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Navigate to the Billing section and click on Invoices.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Locate the Invoice',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Find the invoice you want to edit from the list and click the blue pencil icon under the Actions column.',
                                            boldTexts: ['blue pencil icon', 'Actions'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Edit Payment Amount',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Pmt. AMT field, update the payment amount.',
                                            boldTexts: ['Pmt. AMT'],
                                            circle: true,
                                        },

                                        {
                                            title: 'o mark the invoice as fully paid, set the Pmt. AMT to match the Total AMT and click Save.',
                                            boldTexts: ['Pmt. AMT', 'Total AMT', 'Save'],
                                            circle: true,
                                        },

                                        {
                                            title: 'If you received a partial payment, enter the partial amount in the Pmt. AMT field and click Save.',
                                            boldTexts: ['Pmt. AMT', 'Save'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. IInvoice Status',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'If the Pmt. AMT equals the Total AMT, the invoice will automatically be marked as Paid.',
                                            boldTexts: ['Pmt. AMT', 'Total AMT', 'Paid'],
                                            circle: true,
                                        },

                                        {
                                            title: 'The Pmt. AMT (Payment Amount) will be adjusted to match the Total AMT (Total Amount).',
                                            boldTexts: ['Pmt. AMT'],
                                            circle: true,
                                        },

                                        {
                                            title: 'If a partial payment is entered, the invoice will remain Open until fully paid or manually closed.',
                                            boldTexts: ['Open'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Access the Invoice List',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Navigate to the Billing section and click on Invoices.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Locate the Invoice',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Find the invoice you want to edit from the list and click the blue pencil icon under the Actions column.',
                                            boldTexts: ['blue pencil icon', 'Actions'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Edit Payment Amount',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Pmt. AMT field, update the payment amount.',
                                            boldTexts: ['Pmt. AMT'],
                                            circle: true,
                                        },

                                        {
                                            title: 'o mark the invoice as fully paid, set the Pmt. AMT to match the Total AMT and click Save.',
                                            boldTexts: ['Pmt. AMT', 'Total AMT', 'Save'],
                                            circle: true,
                                        },

                                        {
                                            title: 'If you received a partial payment, enter the partial amount in the Pmt. AMT field and click Save.',
                                            boldTexts: ['Pmt. AMT', 'Save'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. IInvoice Status',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'If the Pmt. AMT equals the Total AMT, the invoice will automatically be marked as Paid.',
                                            boldTexts: ['Pmt. AMT', 'Total AMT', 'Paid'],
                                            circle: true,
                                        },

                                        {
                                            title: 'The Pmt. AMT (Payment Amount) will be adjusted to match the Total AMT (Total Amount).',
                                            boldTexts: ['Pmt. AMT'],
                                            circle: true,
                                        },

                                        {
                                            title: 'If a partial payment is entered, the invoice will remain Open until fully paid or manually closed.',
                                            boldTexts: ['Open'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'CLOSE_INVOICE': {
                subMenuTitle: 'Invoices',
                pageTitle: 'Closing an Invoice',
                content: {
                    short: [
                        {
                            subTitle: '1. Open the Invoice Details',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Navigate to the Billing section and click on Invoices.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Click on the Invoice ID to open its details.',
                                            boldTexts: ['Invoice ID'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Click Close Invoice',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the Details tab of the invoice, locate the Close Invoice button in the top-right corner.',
                                            boldTexts: ['Details'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Confirmation Modal',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'A confirmation modal will appear asking for the reason for closing the invoice.',
                                            boldTexts: [''],
                                            circle: true,
                                        },

                                        {
                                            title: 'Type your reason or comment in the text box.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Finalize Closing',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click Close again to finalize closing the invoice. The status will now be changed to Closed.',
                                            boldTexts: ['PClose', 'Closed'],
                                            circle: true,
                                        },

                                        {
                                            title: 'It can be reopened if the Pmt. AMT is changed and Saved.',
                                            boldTexts: ['Pmt. AMT'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Open the Invoice Details',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Navigate to the Billing section and click on Invoices.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Click on the Invoice ID to open its details.',
                                            boldTexts: ['Invoice ID'],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Click Close Invoice',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the Details tab of the invoice, locate the Close Invoice button in the top-right corner.',
                                            boldTexts: ['Details'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Confirmation Modal',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'A confirmation modal will appear asking for the reason for closing the invoice.',
                                            boldTexts: [''],
                                            circle: true,
                                        },

                                        {
                                            title: 'Type your reason or comment in the text box.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Finalize Closing',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click Close again to finalize closing the invoice. The status will now be changed to Closed.',
                                            boldTexts: ['PClose', 'Closed'],
                                            circle: true,
                                        },

                                        {
                                            title: 'It can be reopened if the Pmt. AMT is changed and Saved.',
                                            boldTexts: ['Pmt. AMT'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'DOWN_INVOICE_PDF': {
                subMenuTitle: 'Invoices',
                pageTitle: 'Downloading an Invoice PDF',
                content: {
                    short: [
                        {
                            subTitle: '1. Open the Invoice Details',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Navigate to the Billing section and click on Invoices.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Click anywhere on the invoice line to open its details.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Locate the PDF Download Option',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Details tab, find the PDF Document: Download button.',
                                            boldTexts: ['Details', 'PDF Document', 'Download'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Download the Invoice',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on the Download button. The PDF version of the invoice will automatically download to your device.',
                                            boldTexts: ['Download'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Open the Invoice Details',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Navigate to the Billing section and click on Invoices.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Click anywhere on the invoice line to open its details.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. Locate the PDF Download Option',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the Details tab, find the PDF Document: Download button.',
                                            boldTexts: ['Details', 'PDF Document', 'Download'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Download the Invoice',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on the Download button. The PDF version of the invoice will automatically download to your device.',
                                            boldTexts: ['Download'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'VIEW_APPOINTMENT_ASSOC_INVOICE': {
                subMenuTitle: 'Invoices',
                pageTitle: 'Viewing Appointments Associated with an Invoice',
                content: {
                    short: [
                        {
                            subTitle: '1. Open the Invoice Details',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Navigate to the Billing section and click on Invoices.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Click anywhere on the invoice line to open its details.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. View Appointments',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once in the Invoice Details page, click on the Appointments tab.',
                                            boldTexts: ['Invoice Details', 'Appointments'],
                                            circle: true,
                                        },

                                        {
                                            title: 'You will see a list of all appointments that contributed to the creation of ' +
                                                'the selected invoice, including the date, time, and staff member associated with each appointment.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                    ],
                    long: [
                        {
                            subTitle: '1. Open the Invoice Details',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Navigate to the Billing section and click on Invoices.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },

                                        {
                                            title: 'Click anywhere on the invoice line to open its details.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },

                            ],
                        },

                        {
                            subTitle: '2. View Appointments',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once in the Invoice Details page, click on the Appointments tab.',
                                            boldTexts: ['Invoice Details', 'Appointments'],
                                            circle: true,
                                        },

                                        {
                                            title: 'You will see a list of all appointments that contributed to the creation of ' +
                                                'the selected invoice, including the date, time, and staff member associated with each appointment.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                    ],
                },
            },
            'VIEW_NOTES_INVOICE': {
                subMenuTitle: 'Invoices',
                pageTitle: 'Viewing Notes on an Invoice',
                content: {
                    short: [
                        {
                            subTitle: '1. Navigate to the Invoice List',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Billing section and click on Invoices from the left-side menu.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },

                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '2. Select an Invoice',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on any part of the invoice row you want to view.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Go to the Notes Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once inside the invoice details, select the Notes tab to view the list of notes attached to this invoice.',
                                            boldTexts: ['Notes'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Invoice List',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Billing section and click on Invoices from the left-side menu.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },

                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '2. Select an Invoice',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on any part of the invoice row you want to view.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Go to the Notes Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once inside the invoice details, select the Notes tab to view the list of notes attached to this invoice.',
                                            boldTexts: ['Notes'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'EDIT_NOTES_INVOICE': {
                subMenuTitle: 'Invoices',
                pageTitle: 'Editing Notes on an Invoice',
                content: {
                    short: [
                        {
                            subTitle: '1. Navigate to the Invoice List',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Billing section and click on Invoices from the left-side menu.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },

                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '2. Select an Invoice',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on any part of the invoice row you want to edit.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Go to the Notes Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once inside the invoice details, select the Notes tab.',
                                            boldTexts: ['Notes'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Click the Edit Icon',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Next to the note you wish to edit, click the blue pencil icon.',
                                            boldTexts: ['pencil icon'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Edit the Note',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'A modal will pop up allowing you to edit the Subject and Body of the note. Make your changes.',
                                            boldTexts: ['Subject', 'Body'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. Save the Changes',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once you\'ve made the necessary edits, click the Save button to update the note.',
                                            boldTexts: ['Save'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Invoice List',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Billing section and click on Invoices from the left-side menu.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },

                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '2. Select an Invoice',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on any part of the invoice row you want to edit.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Go to the Notes Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once inside the invoice details, select the Notes tab.',
                                            boldTexts: ['Notes'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Click the Edit Icon',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Next to the note you wish to edit, click the blue pencil icon.',
                                            boldTexts: ['pencil icon'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Edit the Note',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'A modal will pop up allowing you to edit the Subject and Body of the note. Make your changes.',
                                            boldTexts: ['Subject', 'Body'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. Save the Changes',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once you\'ve made the necessary edits, click the Save button to update the note.',
                                            boldTexts: ['Save'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'ADD_NOTES_INVOICE': {
                subMenuTitle: 'Invoices',
                pageTitle: 'Adding Notes to an Invoice',
                content: {
                    short: [
                        {
                            subTitle: '1. Navigate to the Invoice List',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Billing section and click on Invoices from the left-side menu.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '2. Select an Invoice',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on any part of the invoice row to open the invoice details.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Go to the Notes Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once inside the invoice details, select the Notes tab.',
                                            boldTexts: ['Notes'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Click the Add Note Button',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the top-right corner of the Notes tab, click the + Add Note button.',
                                            boldTexts: ['Notes', 'Add Note'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Fill in the Note Details',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'A modal will appear. Enter the Subject and Body of the note.',
                                            boldTexts: ['Subject', 'Body'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. Save the Note',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click the Save button to add the note to the invoice.',
                                            boldTexts: ['Save'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Invoice List',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Billing section and click on Invoices from the left-side menu.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '2. Select an Invoice',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on any part of the invoice row to open the invoice details.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Go to the Notes Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once inside the invoice details, select the Notes tab.',
                                            boldTexts: ['Notes'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Click the Add Note Button',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the top-right corner of the Notes tab, click the + Add Note button.',
                                            boldTexts: ['Notes', 'Add Note'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Fill in the Note Details',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'A modal will appear. Enter the Subject and Body of the note.',
                                            boldTexts: ['Subject', 'Body'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. Save the Note',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click the Save button to add the note to the invoice.',
                                            boldTexts: ['Save'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'DELETE_NOTES_INVOICE': {
                subMenuTitle: 'Invoices',
                pageTitle: 'Deleting Notes on an Invoice',
                content: {
                    short: [
                        {
                            subTitle: '1. Navigate to the Invoice List',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Billing section and click on Invoices from the left-side menu.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '2. Select an Invoice',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on any part of the invoice row to open the invoice details.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Go to the Notes Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once inside the invoice details, select the Notes tab.',
                                            boldTexts: ['Notes'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Click the Delete Icon',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Next to the note you wish to delete, click the red X icon.',
                                            boldTexts: ['X icon'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Confirm Deletion',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'A confirmation modal will appear. To proceed, check the confirmation ' +
                                                'box (if applicable) and click the Delete button to permanently remove the note.',
                                            boldTexts: ['Delete'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Invoice List',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Billing section and click on Invoices from the left-side menu.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '2. Select an Invoice',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click on any part of the invoice row to open the invoice details.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Go to the Notes Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once inside the invoice details, select the Notes tab.',
                                            boldTexts: ['Notes'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Click the Delete Icon',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Next to the note you wish to delete, click the red X icon.',
                                            boldTexts: ['X icon'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Confirm Deletion',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'A confirmation modal will appear. To proceed, check the confirmation ' +
                                                'box (if applicable) and click the Delete button to permanently remove the note.',
                                            boldTexts: ['Delete'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'VIEW_INVOICE_CHANGE_HISTORY': {
                subMenuTitle: 'Invoices',
                pageTitle: 'Viewing Invoice Change History',
                content: {
                    short: [
                        {
                            subTitle: '1. Navigate to the Invoice List',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Billing section and click on Invoices from the left-side menu.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },

                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '2. Select an Invoice',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click anywhere on the invoice line to open the invoice details.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Go to the History Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once inside the invoice details, select the History tab.',
                                            boldTexts: ['Notes'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Review Changes',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the History tab, you will see a list of all the changes made to the invoice, including' +
                                                ' the Date & Time, Staff Name, and a description of each action performed, such as updating ' +
                                                'payment amounts, changing statuses, or creating the invoice.',
                                            boldTexts: ['History', 'Date', 'Time', 'Staff Name'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Invoice List',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Go to the Billing section and click on Invoices from the left-side menu.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },

                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '2. Select an Invoice',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click anywhere on the invoice line to open the invoice details.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Go to the History Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Once inside the invoice details, select the History tab.',
                                            boldTexts: ['Notes'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Review Changes',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the History tab, you will see a list of all the changes made to the invoice, including' +
                                                ' the Date & Time, Staff Name, and a description of each action performed, such as updating ' +
                                                'payment amounts, changing statuses, or creating the invoice.',
                                            boldTexts: ['History', 'Date', 'Time', 'Staff Name'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'VIEW_FILES_INVOICE': {
                subMenuTitle: 'Invoices',
                pageTitle: 'Viewing Files in Invoices',
                content: {
                    short: [
                        {
                            subTitle: '1. Navigate to the Billing Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the left sidebar, click on Billing.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },

                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '2. Access the Invoices Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Under Billing, click on Invoices.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Select an Invoice',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click anywhere on the invoice line you want to view.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Open the Files Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the invoice detail view, click on the Files tab.',
                                            boldTexts: ['Files'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. View Files',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'You will see the list of uploaded files for that invoice, including the document name, description, date uploaded, and file type.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Billing Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the left sidebar, click on Billing.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },

                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '2. Access the Invoices Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Under Billing, click on Invoices.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Select an Invoice',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click anywhere on the invoice line you want to view.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Open the Files Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the invoice detail view, click on the Files tab.',
                                            boldTexts: ['Files'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. View Files',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'You will see the list of uploaded files for that invoice, including the document name, description, date uploaded, and file type.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'DELETE_FILES_INVOICE': {
                subMenuTitle: 'Invoices',
                pageTitle: 'Deleting Files in Invoices',
                content: {
                    short: [
                        {
                            subTitle: '1. Navigate to the Billing Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the left sidebar, click on Billing.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },

                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '2. Access the Invoices Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Under Billing, click on Invoices.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Select an Invoice',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click anywhere on the invoice line you want to view.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Open the Files Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the invoice detail view, click on the Files tab.',
                                            boldTexts: ['Files'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Click on Delete Icon',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Next to the file you want to delete, click the Delete (Trash) icon.',
                                            boldTexts: ['Delete', 'Trash'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. Confirm Deletion',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'A confirmation modal will appear. Click Delete to confirm.',
                                            boldTexts: ['Delete'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Billing Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the left sidebar, click on Billing.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },

                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '2. Access the Invoices Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Under Billing, click on Invoices.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Select an Invoice',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click anywhere on the invoice line you want to view.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Open the Files Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the invoice detail view, click on the Files tab.',
                                            boldTexts: ['Files'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Click on Delete Icon',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Next to the file you want to delete, click the Delete (Trash) icon.',
                                            boldTexts: ['Delete', 'Trash'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. Confirm Deletion',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'A confirmation modal will appear. Click Delete to confirm.',
                                            boldTexts: ['Delete'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'EDIT_FILES_INVOICE': {
                subMenuTitle: 'Invoices',
                pageTitle: 'Editing Files in Invoices',
                content: {
                    short: [
                        {
                            subTitle: '1. Navigate to the Billing Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the left sidebar, click on Billing.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },

                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '2. Access the Invoices Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Under Billing, click on Invoices.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Select an Invoice',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click anywhere on the invoice line you want to view.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Open the Files Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the invoice detail view, click on the Files tab.',
                                            boldTexts: ['Files'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Click on Edit Icon',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Next to the file you want to edit, click the Edit (Pencil) icon.',
                                            boldTexts: ['Edit', 'Pencil'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. Edit File Details',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the modal that appears, edit the Document Name and Document Description as needed.',
                                            boldTexts: ['Document Name', 'Document Description'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '7. Save Changes',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click Save to update the file information.',
                                            boldTexts: ['Save'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Billing Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the left sidebar, click on Billing.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },

                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '2. Access the Invoices Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Under Billing, click on Invoices.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Select an Invoice',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click anywhere on the invoice line you want to view.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Open the Files Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the invoice detail view, click on the Files tab.',
                                            boldTexts: ['Files'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Click on Edit Icon',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Next to the file you want to edit, click the Edit (Pencil) icon.',
                                            boldTexts: ['Edit', 'Pencil'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. Edit File Details',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the modal that appears, edit the Document Name and Document Description as needed.',
                                            boldTexts: ['Document Name', 'Document Description'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '7. Save Changes',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click Save to update the file information.',
                                            boldTexts: ['Save'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'DOWN_FILES_INVOICE': {
                subMenuTitle: 'Invoices',
                pageTitle: 'Downloading Files in Invoices',
                content: {
                    short: [
                        {
                            subTitle: '1. Navigate to the Billing Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the left sidebar, click on Billing.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },

                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '2. Access the Invoices Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Under Billing, click on Invoices.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Select an Invoice',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click anywhere on the invoice line you want to view.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Open the Files Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the invoice detail view, click on the Files tab.',
                                            boldTexts: ['Files'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Click the Cloud Download Icon',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Next to the file you want to download, click the Download (Cloud with Arrow Down) icon. The file will immediately download to your local device.',
                                            boldTexts: ['Download', 'Cloud with Arrow Down'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Billing Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the left sidebar, click on Billing.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },

                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '2. Access the Invoices Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Under Billing, click on Invoices.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Select an Invoice',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click anywhere on the invoice line you want to view.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Open the Files Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the invoice detail view, click on the Files tab.',
                                            boldTexts: ['Files'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Click the Cloud Download Icon',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Next to the file you want to download, click the Download (Cloud with Arrow Down) icon. The file will immediately download to your local device.',
                                            boldTexts: ['Download', 'Cloud with Arrow Down'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
            'UPLOAD_FILES_INVOICE': {
                subMenuTitle: 'Invoices',
                pageTitle: 'Uploading Files in Invoices',
                content: {
                    short: [
                        {
                            subTitle: '1. Navigate to the Billing Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the left sidebar, click on Billing.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },

                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '2. Access the Invoices Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Under Billing, click on Invoices.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Select an Invoice',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click anywhere on the invoice line you want to view.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Open the Files Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the invoice detail view, click on the Files tab.',
                                            boldTexts: ['Files'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Click the Upload Document Button',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click the Upload Document button in the top right corner of the Files section.',
                                            boldTexts: ['Upload Document'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. Fill in the Document Details',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the modal that appears, enter the Document Name and an optional Document Description.',
                                            boldTexts: ['Document Name', 'Document Description'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '7. Upload the Document',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Either click on the Click to upload area or drag and drop your file (PDF, PNG, JPG, or CSV, with a maximum size of 800x400px) into the box.',
                                            boldTexts: ['Click to upload'],
                                            circle: true,
                                        },

                                        {
                                            title: 'To upload multiple files, repeat this process by dragging and dropping more documents.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '8. Click Add',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click the Add button to upload the document.',
                                            boldTexts: ['Add'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    long: [
                        {
                            subTitle: '1. Navigate to the Billing Section',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'On the left sidebar, click on Billing.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },

                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '2. Access the Invoices Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Under Billing, click on Invoices.',
                                            boldTexts: ['Billing', 'Invoices'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '3. Select an Invoice',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click anywhere on the invoice line you want to view.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '4. Open the Files Tab',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the invoice detail view, click on the Files tab.',
                                            boldTexts: ['Files'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '5. Click the Upload Document Button',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click the Upload Document button in the top right corner of the Files section.',
                                            boldTexts: ['Upload Document'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '6. Fill in the Document Details',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'In the modal that appears, enter the Document Name and an optional Document Description.',
                                            boldTexts: ['Document Name', 'Document Description'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '7. Upload the Document',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Either click on the Click to upload area or drag and drop your file (PDF, PNG, JPG, or CSV, with a maximum size of 800x400px) into the box.',
                                            boldTexts: ['Click to upload'],
                                            circle: true,
                                        },

                                        {
                                            title: 'To upload multiple files, repeat this process by dragging and dropping more documents.',
                                            boldTexts: [''],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            subTitle: '8. Click Add',
                            list: [
                                {
                                    list: [
                                        {
                                            title: 'Click the Add button to upload the document.',
                                            boldTexts: ['Add'],
                                            circle: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
        },
    },
};