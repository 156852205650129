/**Get AVAILABILITY_SCHEDULE */
export const GET_AVAILABILITY_SCHEDULE_GLOBAL = 'GET_AVAILABILITY_SCHEDULE_GLOBAL';
export const GET_AVAILABILITY_SCHEDULE_GLOBAL_SUCCESS = 'GET_AVAILABILITY_SCHEDULE_GLOBAL_SUCCESS';

/**create AVAILABILITY_SCHEDULE */
export const CREATE_AVAILABILITY_SCHEDULE_GLOBAL = 'CREATE_AVAILABILITY_SCHEDULE_GLOBAL';
export const CREATE_AVAILABILITY_SCHEDULE_GLOBAL_SUCCESS = 'CREATE_AVAILABILITY_SCHEDULE_GLOBAL_SUCCESS';

export const GET_CLIENT_SCHEDULE = 'GET_CLIENT_SCHEDULE';
export const GET_CLIENT_SCHEDULE_SUCCESS = 'GET_CLIENT_SCHEDULE_SUCCESS';
export const REMOVE_CLIENT_SCHEDULE = 'REMOVE_CLIENT_SCHEDULE';
export const GET_STAFF_SCHEDULE = 'GET_STAFF_SCHEDULE';
export const GET_STAFF_SCHEDULE_SUCCESS = 'GET_STAFF_SCHEDULE_SUCCESS';
export const REMOVE_STAFF_SCHEDULE = 'REMOVE_STAFF_SCHEDULE';