import React, { Fragment } from 'react';
import { makeCapitalize } from 'utils';
import { UnitsRow, DetailItem, DateRow } from 'components';

export const ServiceItems = ({ item }) => {

    const serviceAppt = [
        {title: 'Authorization #', value: item?.authorizedService?.authorization?.authId},
        {title: 'Staff Paycode', value: makeCapitalize(item?.staffPayCode)},
        {title: 'Funding Source', value: item?.payer?.name},
        {title: 'Charge Rate', value: item?.chargeRateName ? item.chargeRateName : 'N/A'},
        {title: 'Units', value: item?.units ? <UnitsRow info={item?.units}/>  : 'N/A', backgroundColor: item?.incompleteUnitFlag ?  'rgb(252, 235, 231)' : 'rgba(242, 244, 248, 0.5)'},
        {title: 'Last Modified Date', value: item?.editedDate && item?.editor ? <DateRow date={item?.editedDate}/> : 'N/A'},
        {title: 'Hours', value:  item?.hours ? item.hours : 'N/A'},
        {title: 'Last Modified By', value: item?.editor ? item.editor : 'N/A'},
        {title: 'Place of Service', value: `${item?.placeService?.name} ${item?.placeService?.modifier ? `(${item?.placeService?.modifier})` : ''}`},
        {title: 'Created Date', value: item?.createdDate ? <DateRow date={item?.createdDate}/> : 'N/A'},
        {title: 'Address', value: item?.client?.address?.formattedAddress ? item?.client?.address?.formattedAddress : 'N/A'},
        {title: 'Created By', value: item?.creator ? item.creator : 'N/A'},
    ]

    return (
        <div className='appt-detail-card-items'>
            {serviceAppt.map((i, j) => (
                <Fragment key={j}>
                    <DetailItem
                        background={i?.backgroundColor}
                        title={i?.title}
                        value={i?.value}
                    />
                </Fragment>
                ))}
        </div>
    );
};