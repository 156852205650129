import React, { useEffect, useState } from 'react';
import {
    Colors,
    ErrorText,
    FindLoad, FindSuccessItem,
    isNotEmpty,
    useGlobalTextStyles, useModal,
} from 'utils';
import { modalsStyle } from './styles';
import { AddModalButton } from '../buttons';
import { ValidationInput, Textarea } from '../inputs';

export const InactiveModal = ({ name, handleSave, actionType }) => {
    const classes = modalsStyle();
    const [error, setError] = useState('');
    const [inputs, setInputs] = useState({ date: '', reason: '' });
    const loader = FindLoad(actionType);
    const success = FindSuccessItem(actionType);
    const { close } = useModal();

    useEffect(() => {
        if(success){
            close()
        }
    }, [success])

    const handleChange = (e) =>
        setInputs(
            (prevState) => ({ ...prevState, [e.target.name]: e.target.value }),
            error === e.target.name && setError(''),
        );


    const handleCreate = () => {
        const dataIsValid = isNotEmpty(inputs.date);

        if (dataIsValid) {
            const body = {
                'statusReason': inputs?.reason,
                'date': inputs?.date,
            };
           handleSave(body);
        } else {
            const dataErrorText = !isNotEmpty(inputs.date) ? 'date' : '';
            setError(dataErrorText);
        }
    };

    return (
        <div style={{width: 400}}>
            <h1 className='modal-header-title'> {name} </h1>
            <p className={classes.inactiveModalInfo}>{`${name} will be notified about the  reason `}</p>
            <ValidationInput
                variant={'outlined'}
                onChange={handleChange}
                value={inputs.date}
                type={'date'}
                label={'Date*'}
                name="date"
                typeError={error === 'date' && `Date ${ErrorText.isRequired}`}
            />
            <Textarea
                value={inputs.reason}
                maxRows={6}
                variant={'outlined'}
                onChange={handleChange}
                label={'Write  reason here...'}
                name="reason"
            />
            <AddModalButton
                btnStyles={{backgroundColor: Colors.BackgroundBlue}}
                text={'Send'}
                handleClick={handleCreate}
                loader={!!loader.length}
            />
        </div>
    );
};
