import React from 'react';
import { APPT_ENUMS, formatMiles, makeCapitalize } from 'utils';
import { DateRow, DetailItem,  } from 'components';

export const OtherItems = ({ item }) => {
    return (
        <>
            <div className="full-width">
                {item?.staff &&
                    <DetailItem
                        title={'Staff'}
                        value={makeCapitalize(`${item?.staff?.firstName} ${item?.staff?.lastName}`)}
                    />
                }
                {item?.staffPayCode &&
                    <DetailItem
                        title={'Staff Paycode'}
                        value={makeCapitalize(item?.staffPayCode)}
                    />
                }
                {item?.type === APPT_ENUMS.DRIVE &&
                    <DetailItem
                        title={'Miles'}
                        value={formatMiles(item?.miles)}
                    />
                }

                <DetailItem
                    title={'Created Date'}
                    value={item?.createdDate ? <DateRow date={item?.createdDate} /> : 'N/A'}
                />
                <DetailItem
                    title={'Created By'}
                    value={item?.creator ? item.creator : 'N/A'}
                />
                <DetailItem
                    title={'Last Modified Date'}
                    value={item?.editedDate && item?.editor ? <DateRow date={item?.editedDate} /> : 'N/A'}
                />
                <DetailItem
                    title={'Last Modified By'}
                    value={item?.editor ? item?.editor : 'N/A'}
                />
            </div>
        </>
    );
};