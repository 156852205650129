import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { faqs } from './constants';
import { LandingSvg } from 'assets';
import { animationParams, animationVariants } from 'fragments';


export const FaqSocial = () => {
    const [expanded, setExpanded] = useState(null);


    const handleChange = (id) => {
        const checkId = expanded === id ? null : id;

        if (id) {
            setExpanded(checkId);
        }
    };

    return (
        <div className='faq-social-wrapper'>
            <div className='container'>
                <div className='faq-social-box'>
                    <p className='contact-us-sub-title'>
                        FAQ
                    </p>

                    <div style={{ overflow: 'hidden' }}>
                        <motion.h2
                            className='contact-us-title'
                            {...animationParams}
                            variants={animationVariants.titleVariantMobile}
                        >
                            Do You Have Questions?
                        </motion.h2>
                    </div>

                    <div style={{ overflow: 'hidden' }}>
                        <motion.p
                            className='contact-us-description'
                            {...animationParams}
                            variants={animationVariants.titleVariantMobile}
                        >
                            We have answers! Our most frequently asked questions are available to support you.
                        </motion.p>

                    </div>

                    <div className='contact-accordion-wrapper'>
                        {faqs?.map(({ title, description }, i) => (
                            <motion.div
                                key={i}
                                {...animationParams}
                                variants={animationVariants.cardsVariant}
                            >
                                <Accordion
                                    className='contact-accordion-block'
                                    onChange={() => handleChange(i + 1)}
                                    expanded={expanded === i + 1}
                                >
                                    <AccordionSummary
                                        className='contact-accordion-summary'
                                        expandIcon={<LandingSvg.ArrowDownSvg />}
                                        aria-controls='panel-content'
                                        id='panel-header'
                                    >
                                        {title}
                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <p className='contact-accordion-description'>
                                            {description}
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

