import axios from 'axios';

const baseUrl = localStorage.getItem('baseUrl');

export const initAxiosInterceptors = () => {
    if (baseUrl) {
        axios.interceptors.request.use((config) => {
            // Condition to check whether to modify the URL or not
            if (config?.useBaseUrl !== false) {
                config.url = `${baseUrl}${config.url}`;
            }

            if (config.auth) {
                const token = localStorage.getItem('access-token');
                if (!token) {
                    throw new Error('token not found');
                }
                config.headers = {
                    ...config.headers,
                    'access-token': `${token}`,
                };
            }
            return config;
        });

        // Handle 401 Unauthorized error
        axios.interceptors.response.use((response) => response, (error) => {
                if (error?.response?.status === 401) {
                    axios.post(`/authn/logout`, {}, { auth: true }).then(() => {
                            localStorage.clear();
                            sessionStorage.clear();
                            window.location.replace('/');
                        }).catch(() => {
                            localStorage.clear();
                            sessionStorage.clear();
                            window.location.replace('/');
                        });
                }
                throw new Object({
                    data: error.response.data,
                    status: error.response.status,
                });
            }
        );
    }
};
