import {
    getAvailabilitySchedule,
    createAvailabilitySchedule, getStaffSchedule, getClientSchedule, removeStaffSchedule, removeClientSchedule,
} from './availabilitySchedule.action';

export {availabilityScheduleReducer} from './availabilitySchedule.reducer';
export {watchAvailabilitySchedule} from './availabilitySchedule.saga';

export const availabilityScheduleActions = {
    getAvailabilitySchedule,
    createAvailabilitySchedule,
    getStaffSchedule,
    removeStaffSchedule,
    getClientSchedule,
    removeClientSchedule,
}
