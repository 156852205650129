import axios from 'axios';

export const authService = {

    getAllKpisService: (params) => axios.get('/kpis', { auth: true, params: { ...params } }),

    getUserKpisService: (params) => axios.get('/kpis/users', { auth: true, params: { ...params } }),

    reorderKpisService: (body, params) => axios.patch('/kpis/users/reorder', { kpiIds: body }, {
        auth: true,
        params: params,
    }),

    deleteUserKpiService: (id) => axios.delete(`/kpis/${id}/users`, { auth: true, params: { id: id } }),

    addUserKpiService: (id) => axios.post(`/kpis/${id}/users`, null, { auth: true }),

    getChartDataService: ({ url, params }) => axios.get(url, { auth: true, params: { ...params } }),

};
