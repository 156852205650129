import axios from 'axios';

export const authService = {

    /** Tags */

    getTagsService: (params) => axios.get(`/tags`, { auth: true, params: { ...params } }),

    createTagService: (body) => axios.post(`/tags`, body, { auth: true }),

    editTagService: (body) => axios.patch(`/tags/${body?.id}`, body, { auth: true }),

    deleteTagService: (id) => axios.delete(`/tags/${id}`, { auth: true }),

    /** End */


    /** Tag for members */

    addTagToStaffService: (id, tagId) => axios.post(`/staff/${id}/tags/${tagId}`, null, { auth: true }),

    addTagToClientService: (id, tagId) => axios.post(`/client/${id}/tags/${tagId}`, null, { auth: true }),

    removeTagToStaffService: (id, tagId) => axios.delete(`/staff/${id}/tags/${tagId}`, { auth: true }),

    removeTagToClientService: (id, tagId) => axios.delete(`/client/${id}/tags/${tagId}`, { auth: true }),

    /** End */


    getAdminsService: (data) => axios.get('/staff', { auth: true, params: { ...data } }),

    getClientsService: (data) => axios.get('/client', { auth: true, params: { ...data } }),

};
