import {
    CREATE_FUNDING_SOURCE,
    GET_FUNDING_SOURCE,
    GET_FUNDING_SOURCE_BY_ID,
    GET_FUNDING_SOURCE_SERVICE_BY_ID,
    CREATE_FUNDING_SOURCE_SERVICE_BY_ID,
    EDIT_FUNDING_SOURCE,
    EDIT_FUNDING_SOURCE_SERVICE,
    SET_STATUS,
    CREATE_FUNDING_MODIFIER,
    EDIT_FUNDING_MODIFIER,
    CHANGE_FUNDING_MODIFIER_STATUS,
    CHANGE_FUNDING_SOURCE_STATUS,
    EDIT_FS_DOCUMENT,
    UPLOAD_FS_DOCUMENT,
    GET_FS_DOCUMENT,
    DELETE_FS_DOCUMENT,
    GET_FUNDING_MODIFIERS,
    CHANGE_FUNDING_SOURCE_SERVICE_STATUS,
    DELETE_FUNDING_SOURCE,
    GET_FUNDING_SOURCE_CLIENTS, EDIT_FS_SOURCE_GROUPING,
} from './fundingSource.types';

/** Founding Source */

export const createFundingSource = (body) => {
    return {
        type: CREATE_FUNDING_SOURCE,
        payload: {body},
    };
};

export const editFundingSource = (id, body) => {
    return {
        type: EDIT_FUNDING_SOURCE,
        payload: {id, body},
    };
};

export const editFsSourceGrouping = (id, body) => {
    return {
        type: EDIT_FS_SOURCE_GROUPING,
        payload: {id, body},
    };
};

export const getFundingSource = (data, load) => {
    return {
        type: GET_FUNDING_SOURCE,
        payload: {data, load},
    };
};

export const getFundingSourceById = (id) => {
    return {
        type: GET_FUNDING_SOURCE_BY_ID,
        payload: id,
    };
};

export const changeFundingSourceStatus = (id, status) => {
    return {
        type: CHANGE_FUNDING_SOURCE_STATUS,
        payload: {id, status},
    };
};
export const deleteFundingSource = (id) => {
    return {
        type: DELETE_FUNDING_SOURCE,
        payload: id,
    };
};

export const getFundingSourceClients = (id) => {
    return {
        type: GET_FUNDING_SOURCE_CLIENTS,
        payload: id,
    };
};

/** End */

/** Founding Source Service */

export const getFoundingSourceServiceById = (id, data) => {
    return {
        type: GET_FUNDING_SOURCE_SERVICE_BY_ID,
        payload: {id, data},
    };
};

export const createFoundingSourceServiceById = (id, body, reqParams) => {
    return {
        type: CREATE_FUNDING_SOURCE_SERVICE_BY_ID,
        payload: {id, body, reqParams},
    };
};

export const editFoundingSourceServiceById = (id, body, reqParams) => {
    return {
        type: EDIT_FUNDING_SOURCE_SERVICE,
        payload: {id, body, reqParams},
    };
};

export const editFoundingSourceServiceStatus = (id, body, funderId, reqParams) => {
    return {
        type: CHANGE_FUNDING_SOURCE_SERVICE_STATUS,
        payload: {id, body, funderId, reqParams},
    };
};

/** End */

/** Modifiers */

export const getServiceModifier = (serviceId) => {
    return {
        type: GET_FUNDING_MODIFIERS,
        payload: {serviceId},
    };
};

export const createFundingModifier = (fundingId, body) => {
    return {
        type: CREATE_FUNDING_MODIFIER,
        payload: {fundingId, body},
    };
};


export const editFundingModifier = (chargeRateId, body, serviceId) => {
    return {
        type: EDIT_FUNDING_MODIFIER,
        payload: {chargeRateId, body, serviceId},
    };
};

export const changeFundingModifierStatus = (serviceId, chargeRateId) => {
    return {
        type: CHANGE_FUNDING_MODIFIER_STATUS,
        payload: {serviceId, chargeRateId},
    };
};

/** End */

export const setStatus = (id, path, status, body, type) => {
    return {
        type: SET_STATUS,
        payload: {id, path, status, body, type},
    };
};


/** Fs Documents */

export const uploadFsDocument = (files, id) => {
    return {
        type: UPLOAD_FS_DOCUMENT,
        payload: {files, id}
    }
}

export const editFsDocument = (id, dockId, file) => {
    return {
        type: EDIT_FS_DOCUMENT,
        payload: {id, dockId, file}
    }
}

export const getFsDocument = (id) => {
    return {
        type: GET_FS_DOCUMENT,
        payload: {id}
    }
}

export const deleteFsDocument = (fsId, id) => {
    return {
        type: DELETE_FS_DOCUMENT,
        payload: {fsId, id}
    }
}

/** End */