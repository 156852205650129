import { makeStyles } from '@material-ui/core/styles';


export const credentialsStyles = makeStyles(() => ({
    credentialsListWrapper: {
        width: '50%',
        marginRight: '16px',
    },
    credentialsListHeaderBox:{
        padding: '0 4px'
    },
    credentialsListHeader: {
        borderRadius: '8px',
        background: '#EBF2FD',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px 32px',
    },
    credentialsListHeaderTitle: {
        color: '#347AF0',
        fontSize: '16px',
        fontWeight: 600,
    },
    coveredCredentialsWrapper: {
        width: '50%',
        borderRadius: '8px',
        boxShadow: '0px 0px 6px 0px rgba(138, 138, 138, 0.24)',
        height: '605px',
    },
    coveredCredentialsHead: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '18px 32px',
    },
    coveredCredentialsHeadTitle: {
        color: '#4B5C68',
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '24px',
        marginRight:'24px',
    },
    credentialItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 32px',
        borderRadius: '8px',
        boxShadow: '0px 0px 6px 0px rgba(138, 138, 138, 0.24)',
        cursor: 'pointer',
        marginBottom:'8px',
    },
    credentialCoveredItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 32px',
        cursor: 'pointer',
        marginBottom:'4px',
        background: '#F2F4F8',
    },
    licenseTitle: {
        color: '#4B5C68',
        fontSize: '14px',
        marginLeft: '8px',

        '& b': {
            fontWeight: 600,
        },
    },
    credentialActionBox: {
        display: 'flex',
        alignItems: 'center',

        '& button': {
            background: 'transparent',
            border: 'none',
            marginRight: '8px',
        },
    },
    createCredentialWrapper: {
        width: '480px',
    },
    createCredentialBody: {
        margin: '40px 0',
    },
    credentialsListBody: {
        height: '550px',
        overflow: 'auto',
        padding:'8px 6px',
    },
    selectCredential: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height:'100%',

        '& p': {
            color: '#4B5C68',
            fontSize: '18px',
            fontWeight: '600',
            lineHeight: '24px',
            marginTop:'16px',
        },
    },
    coveredCredentialsBody:{
        height: '470px',
        overflow: 'auto',
    },
    checkboxesTagsBox:{
        padding:'0 32px',
    }
}));