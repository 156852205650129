import { makeStyles } from '@material-ui/core/styles';
import { Backgrounds, Colors } from 'utils';

export const managementFragments = makeStyles(() => ({
    roleListHeaderBox: {
        padding: '0 4px',
    },
    roleListHeader: {
        borderRadius: '8px',
        background: '#EBF2FD',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px 32px',
    },
    roleHeaderTitle: {
        color: '#347AF0',
        fontSize: '16px',
        fontWeight: 600,
    },

    managementFragmentsStyle: {
        height: '600px',
        width: '100%',
        display: 'flex',
    },

    managementFragmentsRole: {
        width: '45%',
        marginRight: '16px',
    },

    managementFragmentsPermissions: {
        width: '55%',
    },

    tableStyle: {
        height: '550px',
        width: '100%',
    },

    tableRoleStyle: {
        height: '535px',
        width: '99%',
        margin: '4px 4px 0 4px',
        background: Backgrounds.whiteModal,
        boxShadow: '0px 0px 6px #8A8A8A3D',
        borderRadius: '8px',
    },

    tableHeadStyle: {
        height: '51px',
        display: 'flex',
        alignItems: 'center',
        background: '#EBF2FD 0% 0% no-repeat padding-box',
        boxShadow: '0px 2px 6px #347AF033',
        borderRadius: '8px',
        padding: '8px 16px 8px 16px',
        margin: '0 4px 4px 4px',
        '@media (min-width: 1920px)': {
            padding: '16px 32px 16px 32px',
        },

        '& p': {
            fontSize: '14px',
            lineHeight: '19px',
            fontWeight: '600',
            color: '#4B5C68',
            marginRight: '8px',
        },
    },

    tableRoleHeadStyle: {
        height: '51px',
        background: '#EBF2FD 0% 0% no-repeat padding-box',
        boxShadow: '0px 2px 6px #347AF033',
        borderRadius: '8px',
        padding: '8px 16px 8px 16px',
        margin: '0 4px 4px 4px',
        '@media (min-width: 1920px)': {
            padding: '8px 32px 8px 32px',
        },
    },

    tableBodyStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '50px',
        padding: '8px 10px 8px 16px',

        '@media (min-width: 1920px)': {
            padding: '16px 26px 16px 32px',
        },

        '& div:first-of-type': {
            display: 'flex',
            alignItems: 'center',
        },

        '& img': {
            // width: '24px',
            // height: '24px',
            marginRight: '4px',
            '@media (min-width: 1920px)': {
                marginRight: '8px',
            },
        },
    },
    roleTitle: {
        width: '100%',
        fontSize: '14px',
        lineHeight: '22px',
        fontWeight: '600',
        color: Colors.TextSecondary,
    },
    roleDesc: {
        width: '100%',
        marginLeft: '20px',
        fontSize: '12px',
        color: Colors.TextPrimary,
    },

    tableBodyBottom: {
        margin: '4px',
        cursor: 'pointer',
        background: Backgrounds.whiteModal,
        borderRadius: '8px',
        border: '1px solid rgba(163, 178, 189, 0.30)',
        transition: 'all .2s',
        '& :hover': {
            background: '#EBF2FD80 0% 0% no-repeat padding-box',

            '& p': {
                background: 'transparent',
            },
            '& img': {
                background: 'transparent',
            },
            '& div': {
                background: 'transparent',
            },
            '& button': {
                background: 'transparent',
            },
            '& span': {
                background: 'transparent',
            },
        },
    },

    tableBodyBottomActive: {
        transition: 'all .2s',
        margin: '4px',
        padding: '12px 0',
        borderRadius: '8px',
        cursor: 'pointer',
        border: '1px solid rgba(163, 178, 189, 0.50)',
        background: 'rgba(235, 242, 253, 0.50)',
    },

    rolePermissionsStyle: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            fontSize: '14px',
            lineHeight: '19px',
            fontWeight: 'bold',
            color: Colors.BackgroundBlue,
            textTransform: 'uppercase',
            marginLeft: '8px',
            '@media (min-width: 1920px)': {
                marginLeft: '16px',
            },
        },
    },

    permissionIcon: {
        height: '40px',
        width: '40px',
        background: Colors.ThemeMangoOrange,
        borderRadius: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
            border: '1px solid white',
            borderRadius: '24px',
            padding: '4px',
        },
    },

    tablePermissionsBodyStyleWrapper: {
        position: 'relative',
        height: '80px',
    },

    tablePermissionsBodyStyle: {
        position: 'absolute',
        padding: '0 32px 0',
        width: '100%',
        '@media (max-width: 1280px)': {
            padding: '0 16px 0',
        },
        '& .MuiAutocomplete-paper': {
            borderRight: '1px solid rgba(163, 178, 189, 0.30)',
            borderLeft: '1px solid rgba(163, 178, 189, 0.30)',
            borderTop: '1px solid rgba(163, 178, 189, 0.30)',
            borderRadius: '4px 4px 0 0',
            margin: 0,
        },
        '& .MuiPaper-elevation1': {
            boxShadow: 'none',
            // position: 'absolute',
            width: '100%',
        },

        '& .MuiAutocomplete-option': {
            padding: '4px 7px',
        },
        '& .MuiAutocomplete-option[aria-selected="true"]': {
            background: '#EBF2FD!important',
        },
        '& .MuiAutocomplete-option[data-focus="true"]': {
            background: '#EBF2FD!important',
        },
    },

    roleNameStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& span': {
            fontSize: '16px',
            lineHeight: '22px',
            fontWeight: 'bold',
            color: Colors.TextSecondary,
            textTransform: 'uppercase',
        },

        padding: '33px 32px 25px 32px',
        '@media (max-width: 1280px)': {
            padding: '25px 16px',
        },
    },

    tablePermissionsBodyContentStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '50px',
        background: '#EBF2FD 0% 0% no-repeat padding-box',
        padding: '8px 16px 8px 16px',
        marginBottom: '8px',

        '@media (min-width: 1920px)': {
            padding: '16px 32px 16px 32px',
        },

        '& div:first-of-type': {
            display: 'flex',
            alignItems: 'center',
        },

        '& img': {
            width: '18px',
            height: '18px',
        },
    },

    addRoleModalWrapper: {
        width: '500px',
        height: 'auto',
        background: Backgrounds.whiteModal,
        borderRadius: '8px',
        padding: '8px 0 40px 0',

        '@media (min-width: 1920px)': {
            width: '580px',
        },
    },

    addRoleModalWrapperClose: {
        display: 'flex',
        justifyContent: 'flex-end',
    },

    addRoleModalWrapperContent: {
        padding: '8px 32px 0 32px',

        '@media (min-width: 1920px)': {
            padding: '8px 40px 0 40px',
        },
    },

    input: {
        '& label': {
            color: '#4B5C6880 !important',
        },
    },

    deleteModal: {
        width: '500px',
        height: 'auto',
        background: Backgrounds.whiteModal,
        borderRadius: '8px',
        padding: '8px 0 40px 0',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
    },

    scroll: {
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },

    scrollPermission: {
        overflow: 'auto',
    },

    noItemPaddings: {
        padding: '25px 16px 25px 16px',
        '@media (min-width: 1920px)': {
            padding: '33px 32px 25px 32px',
        },
    },

    editBtn: {
        background: 'transparent',
        border: 'none',
    },
    tablePermissionsTitle: {
        width: '250px',
        fontSize: '14px',
        lineHeight: '19px',
        color: Colors.TextPrimary,
        marginLeft: '8px',
    },
    tablePermissionsDesc: {
        color: '#51566D',
        fontSize: '12px',
        marginLeft: '12px',
        width: '200px',
        '@media (min-width: 1920px)': {
            width: '400px',
        },
    },
}));


export const popperUseStyles = makeStyles((theme) => ({
    paper: {
        position: 'relative',
        zIndex: 10,
    },
    footer: {
        bottom: '-65px',
        position: 'absolute',
        width: '100%',
        marginTop: '11px',
        marginLeft: '-16px',
        padding: '16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: 'white',
        border: '1px solid rgba(163, 178, 189, 0.30)',
        borderRadius: '0 0 4px 4px',
        gap: '20px',
    },
    option: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    checkbox: {
        marginRight: theme.spacing(1),
        color: theme.palette.primary.main,
    },
    description: {
        color: 'gray',
        fontSize: '12px',
    },

    infoIconWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        '& div': {
            width: '24px',
            minWidth: '24px',
            height: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '40px',
            background: '#FEF0C7',
        },
        '& p': {
            color: '#A3B2BD',
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontWeight: '600',
        },
    },
}));