import React from 'react';
import { SearchAndFilerFull, TextRow } from 'components';
import { claimPostStyles } from './styles';
import { agingEnumsSelector } from '../../../../../../utils';

export const ClaimPaymentHead = ({}) => {
    const classes = claimPostStyles();

    const headerList = [
        { name: 'claimId', title: 'Claim ID', width: '10%', custom: false, noSearch: true },
        { name: 'dos', title: 'DOS', width: '20%', custom: false, noSearch: true },
        { name: 'fsSource', title: 'Funding Source', width: '15%', custom: false, noSearch: true },
        { name: 'client', title: 'Client', width: '15%', custom: false, noSearch: true },
        { name: 'agingType', title: 'Aging', width: '10%', noSearch: true,
            filterList: agingEnumsSelector,
        },
        { name: 'actions', title: ' ', width: '30%', custom: false, noSearch: true },
    ];

    return (
        <div>
            <div className={classes.postTheadStyle}>
                {headerList?.map((i, j) => i && (
                    <div key={j} style={{ width: i?.width, }} className={classes.postThStyle}>
                        <SearchAndFilerFull
                            // list={list}
                            item={i}
                            // page={page}
                            // searched={searched}
                            // searched={searched}
                            // backSearched={backSearched}
                            // handleSearch={(e) => handleChange(e, i?.nameRow, i?.searchKey, i?.icon)}
                        />

                        {/*<TextRow name={i?.name} />*/}
                    </div>
                ))}
            </div>
        </div>
    );
};