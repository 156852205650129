import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { PermissionList, renderClientName, RolePermission, SaveParams, useModal } from 'utils';
import { AddButton, DateTypeSelect, DateTypeSelector, DownloadCsv, SelectTypeAutocomplete, } from 'components';
import { clientActions } from 'store';
import { GenerateInvoice } from './generateInvoice';

export const InvoiceFilters = ({}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const pushInfo = history?.location?.state;
    const clientList = useSelector((state) => state.client.basicClients);
    const { open } = useModal();

    useEffect(() => {
        dispatch(clientActions.getBasicClients());
    }, []);

    const handleChange = (e) => {
        const info = {
            ...pushInfo,
        };
        info.skip = 0;
        info.limit = 50;
        info.page = 1;
        if (e.target.value === 'All' || !e.target.value) {
            delete info[e.target.name];
        } else {
            info[e.target.name] = e.target.value;
        }
        SaveParams(history, { ...info });
    };

    return (
        <div className="table-invoice-filter-section">
            <div className="billing-invoices-filter-box">
                <SelectTypeAutocomplete
                    placeholder={pushInfo?.client ? '' : 'All'}
                    name={'client'}
                    handleSelect={handleChange}
                    defaultValue={pushInfo?.client}
                    list={clientList ?
                        [{ id: 'All', firstName: 'All', lastName: '' }, ...clientList]
                        :
                        [{ id: 'All', firstName: 'All', lastName: '' }]
                    }
                    renderValue={(i) => renderClientName(i)}
                    uiType={'tableFilter'}
                    outLabel={'Client'}
                    noError={true}
                />
                <DateTypeSelector
                    startName={'from'}
                    endName={'to'}
                    outLabel={'DOS'}
                />
                <DateTypeSelect
                    name={'invoiceDate'}
                    noError={true}
                    outLabel={'Invoice Date'}
                />
            </div>

            <div className="billing-invoices-filter-box">
                <DownloadCsv smallSize={true} type={'billingInvoice'} fromModal={true} />

                {RolePermission([PermissionList.INVOICE_CREATE?.code]) &&
                    <AddButton
                        text={'Generate Invoice'}
                        handleClick={() => open(<GenerateInvoice />)}
                    />
                }
            </div>
        </div>
    );
};