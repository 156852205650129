import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import { CreateChancel, DateTypeSelect, SelectTypeAutocomplete } from 'components';
import { APPT_FLAGGED_ENUMS, renderClientName, renderStaffName, useModal } from 'utils';
import { CsvService } from 'store';
import { scheduleStatuses, scheduleTypes } from '../../../fragments/schedule/core/constants';
import { useHistory } from 'react-router-dom';

export const DownloadScheduleCsv = ({}) => {
    const [error, setError] = useState('');
    const [inputs, setInputs] = useState({});
    const [load, setLoad] = useState(false);
    const { close } = useModal();
    const clientList = useSelector((state) => state.client.clientList);
    const adminsList = useSelector((state) => state.admins.adminsList);
    const history = useHistory();
    const info = history?.location?.state;

    useEffect(() => {
        if (info) {
            const params = {
                staff: info?.staff,
                client: info?.client,
                type: info?.type,
                status: info?.status,
                startDate: info?.firstDate,
                endDate: info?.lastDate,
            };
            setInputs(params);
        }
    }, [info]);

    const handleChange = (e) => {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        error === e.target.name && setError('');
    };

    const handleExport = async () => {
        setLoad(true);
        let response = '';

        const params = {
            ...inputs,
            staff: inputs?.staff,
            client: inputs?.client,
            type: inputs?.type,
            status: inputs?.status,
        };

        inputs?.startDate ? params.startDate = moment.utc(inputs?.startDate).format('YYYY-MM-DD') : null;
        inputs?.endDate ? params.endDate = moment.utc(inputs?.endDate).format('YYYY-MM-DD') : null;


        try {
            response = await CsvService.getScheduleCsv(params);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `appointment.csv`);
            document.body.appendChild(link);
            link.click();
            setLoad(false);
            close();
        } catch (e) {
            setLoad(false);
            close();
        }
    };

    return (
        <div className="download-payroll-csv-modal">
            <p className="download-modal-title">Download Schedule CSV</p>
            <p className="download-modal-desc">
                Select filters for the schedule data you wish to download. The fields will be pre-filled based on your
                current filters.
            </p>
            <div className="download-modal-body">
                <SelectTypeAutocomplete
                    title={'Staff'}
                    name={'staff'}
                    handleSelect={handleChange}
                    defaultValue={inputs?.staff}
                    list={adminsList?.staffs?.length ? adminsList?.staffs : []}
                    renderValue={(i) => renderStaffName(i)}
                />
                <SelectTypeAutocomplete
                    title={'Client'}
                    name={'client'}
                    handleSelect={handleChange}
                    defaultValue={inputs?.client}
                    list={clientList?.clients ? clientList?.clients : []}
                    renderValue={(i) => renderClientName(i)}
                />
                <SelectTypeAutocomplete
                    title={'Event Type'}
                    name={'type'}
                    placeholder={inputs?.type ? '' : 'All'}
                    handleSelect={handleChange}
                    defaultValue={inputs?.type}
                    list={scheduleTypes}
                    renderValue={(i) => i?.name}
                />
                <SelectTypeAutocomplete
                    title={'Event Status'}
                    name={'status'}
                    handleSelect={handleChange}
                    defaultValue={inputs?.status}
                    list={scheduleStatuses}
                    renderValue={(i) => i?.name}
                />
                <div className="start-end-time-box">
                    <DateTypeSelect
                        type={'modalInput'}
                        name={'startDate'}
                        outLabel={'Start Date'}
                        handleGetDates={handleChange}
                        values={inputs}
                        max={inputs.endDate ? moment.utc(inputs.endDate).format('YYYY-MM-DD') : ''}
                    />
                    <DateTypeSelect
                        type={'modalInput'}
                        name={'endDate'}
                        outLabel={'End Date'}
                        handleGetDates={handleChange}
                        values={inputs}
                        min={inputs.startDate ? moment.utc(inputs.startDate).format('YYYY-MM-DD') : ''}
                    />
                </div>
                <SelectTypeAutocomplete
                    title={'Flagged'}
                    placeholder={inputs?.flag ? '' : 'All'}
                    name={'flag'}
                    handleSelect={handleChange}
                    defaultValue={inputs?.flag}
                    list={APPT_FLAGGED_ENUMS}
                    renderValue={(i) => i?.label}
                />
            </div>

            <div>
                <CreateChancel
                    loader={load}
                    create={'Download CSV'}
                    chancel={'Cancel'}
                    onCreate={handleExport}
                    onClose={() => close()}
                    buttonWidth="300px"
                />
            </div>
        </div>
    );
};