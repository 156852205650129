import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { authHeaderStyles } from './styles';
import { ActiveInactiveStatusReverse, Images, RolePermission, PermissionList, useModal, } from 'utils';
import { AddButtonLight, TextRow, CustomDelete, NoItemText, SimpleTooltip, LinkRow } from 'components';
import { clientActions } from 'store';
import { DiagnosticCode } from '../../../clientModals';
import { PastAuthorizations } from './pastAuthorizations';
import { AuthFiles } from '../authFiles';

export const AuthHeader = ({ info, setToggleModal, setAuthIndex }) => {
    const classes = authHeaderStyles();
    const { open, close } = useModal();
    const params = useParams();
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);

    const handleDelete = (item) => {
        const body = {
            ...info,
        };
        body.diagnosticCodes = info?.diagnosticCodes?.filter((i) => i !== item);
        dispatch(clientActions.editClientsAuthorizations(body, info.id, params.id));
    };

    return (
        <div className={classes.AuthHeader}>
            <div className={classes.AuthHeaderTop}>
                <div className={classes.AuthHeaderTopLeft}>
                    <p className={classes.AuthHeaderTopLeftTitle}>
                        {`# ${info?.authId}`}
                    </p>
                    <p className={classes.AuthHeaderTopLeftText}>
                        {info?.startDate &&
                            `${moment.utc(info?.startDate).format('MM/DD/YYYY')} - ${moment.utc(info?.endDate).format('MM/DD/YYYY')}`
                        }
                    </p>
                </div>
                {RolePermission([PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code]) &&
                    <div className={classes.AuthHeaderTopRight}>
                        <SimpleTooltip title={<p>{'Past Authorizations'}</p>} placement="top-start">
                            <div
                                className={classes.fileListStyle}
                                onClick={() => open(<PastAuthorizations authIndex={info}
                                                                        setAuthIndex={setAuthIndex} />)}
                            >
                                <img src={Images.timePast} alt="file" />
                            </div>
                        </SimpleTooltip>
                        <SimpleTooltip title={<p>{'Files'}</p>} placement="top-start">
                            <div className={classes.fileListStyle}
                                 onClick={() => open(
                                     <AuthFiles currentId={info?.id} />,
                                 )}
                            >
                                <img alt="file" src={Images.documentUpload} />
                            </div>
                        </SimpleTooltip>
                        {info?.status !== 'INACTIVE' &&
                            <SimpleTooltip title={<p>{'Edit Authorization'}</p>} placement="top-start">
                                <div
                                    className={classes.editIconStyle}
                                    onClick={() => {
                                        setToggleModal((prevState) => !prevState);
                                    }}
                                >
                                    <img src={Images.edit} alt="edit" />
                                </div>
                            </SimpleTooltip>
                        }
                    </div>
                }
            </div>
            <div className={classes.AuthHeaderBottom}>
                <div className={classes.AuthHeaderBottomBox}>
                    <p className={classes.AuthHeaderBottomBoxTitle}>
                        {'Funding Source:'}
                    </p>
                    <p className={classes.AuthHeaderBottomBoxText}>
                        <LinkRow
                            name={info?.funderId?.name ? info?.funderId?.name : 'N/A'}
                            href={RolePermission([PermissionList.FS_READ.code]) ? `/fundingSource/${info?.funderId?._id}` : ''}
                            checkPermission={info?.funderId?._id ? RolePermission([PermissionList.FS_READ.code]) : null}
                        />
                    </p>
                </div>
                <div className={classes.AuthHeaderBottomBox}>
                    <p className={classes.AuthHeaderBottomBoxTitle}>
                        {'Status:'}
                    </p>
                    <p className={`${classes.AuthHeaderBottomBoxText} ${'statusStyle'} ${info?.status}`}>
                        {ActiveInactiveStatusReverse(info?.status)}
                    </p>
                </div>
                <div className={classes.AuthHeaderBottomBox}>
                    <p className={classes.AuthHeaderBottomBoxTextLocation}>
                        <span
                            style={{ fontWeight: 600 }}> {'Service Location: '}</span>
                        {info?.location}
                    </p>
                </div>
            </div>

            <div className={classes.diagnosticCodesWrapper}>
                <Accordion>
                    <AccordionSummary
                        onClick={() => setShow(!show)}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className={classes.addCodeBox}>
                            <div className={classes.downUp}>
                                <p>Diagnostic Codes</p>
                                <img src={Images.dropDown} alt="icon"
                                     style={show ? { transform: 'rotate(-180deg)' } : {}} />
                            </div>
                            {RolePermission([PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code]) &&
                                <AddButtonLight
                                    addButnLightClassName={classes.addAuthServiceButnStyle}
                                    addButnLightInnerText={'Add Code'}
                                    onAddButnLightClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        open(<DiagnosticCode info={info} params={params} />);
                                    }}
                                />
                            }
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.diagnosticCodes}>
                            {info?.diagnosticCodes?.length ?
                                info?.diagnosticCodes?.map((i, j) => (
                                    <div className={classes.itemBox} key={j}>
                                        <TextRow name={i} />
                                        {RolePermission([PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code]) &&
                                            <div className={classes.itemButtonsBox}>
                                                <button onClick={() => open(
                                                    <DiagnosticCode
                                                        item={i}
                                                        info={info}
                                                        params={params}
                                                    />)}
                                                >
                                                    <img src={Images.edit} alt="edit" />
                                                </button>
                                                <button onClick={() => open(<CustomDelete
                                                    text={`Are you sure you want Delete Diagnostic Code`}
                                                    info="Delete Diagnostic Code?"
                                                    handleDel={() => handleDelete(i)}
                                                    handleClose={() => close()}
                                                    actionType={'EDIT_CLIENT_AUTHORIZATION'}
                                                />)}>
                                                    <img src={Images.remove} alt="edit" />
                                                </button>
                                            </div>
                                        }
                                    </div>
                                ))
                                :
                                <div style={{ paddingBottom: '20px' }}>
                                    <NoItemText text={'No diagnostic codes yet'} />
                                </div>
                            }
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    );
};
