import React from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from 'components';
import { serviceSingleStyles } from './styles';
import { FindLoad, useModal } from 'utils';
import { AddEnrollment } from '../../clientModals';
import { ClientEnrollmentTable } from './common';

export const ClientEnrollment = ({ info = [] }) => {
    const classes = serviceSingleStyles();
    const loader = FindLoad('GET_CLIENT_ENROLLMENT');
    const { open } = useModal();
    const params = useParams();

    const handleOpenCreate = (type) => {
        open(<AddEnrollment
            params={params}
            type={type}
            activeEnrollments={info}
        />);
    };

    const handleEdit = (item) => {
        open(<AddEnrollment
            params={params}
            info={item}
            activeEnrollments={info}
        />);
    };

    if (loader?.length) {
        return <Loader />;
    }

    return (
        <div className={classes.staffGeneralWrapper}>
            <div className={classes.notesWrap}>
                <ClientEnrollmentTable
                    handleEdit={handleEdit}
                    handleOpenCreate={handleOpenCreate}
                    enrollments={info}
                />
            </div>
        </div>
    );
};
