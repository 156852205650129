import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddButtonLight, CustomDelByNote, FullTable, Loader } from 'components';
import { serviceSingleStyles } from './styles';
import { FindLoad, FindSuccess, PermissionList, RolePermission, useModal } from 'utils';
import { claimActions, clientActions, httpRequestsOnSuccessActions } from 'store';
import { AddAuthorization, AddAuthorizationService } from '../../clientModals';
import { AUTH_ACTION_TYPE, authorizationBody, authorizationHead, authServiceNotYet } from './constants';
import { AuthHeader } from './auth/authHeader';

const DELETE_CLIENT_AUTHORIZATION = 'DELETE_CLIENT_AUTHORIZATION';
const INACTIVATE_ACTION_TYPE = 'DELETE_CLIENT_AUTHORIZATION_SERV';
export const ClientAuthorization = ({ info }) => {
    const classes = serviceSingleStyles();
    const dispatch = useDispatch();
    const services = useSelector((state) => state.client.clientsAuthorizationsServices);
    const success = FindSuccess(DELETE_CLIENT_AUTHORIZATION);
    const [authIndex, setAuthIndex] = useState('');
    const loader = FindLoad('GET_CLIENT_AUTHORIZATION_SERV');
    const { open, close } = useModal();

    useEffect(() => {
        if (info?.length) {
            const active = info.find((i) => i.status === 'ACTIVE');
            const passive = info.find((i) => i.status === 'INACTIVE');
            if (active || passive) {
                setAuthIndex(active ? active : passive);
                dispatch(clientActions.getClientsAuthorizationsServ(active ? active?.id : passive?.id));
            }
        }
    }, [info]);

    useEffect(() => {
        if (!!success.length) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(DELETE_CLIENT_AUTHORIZATION));
        }
    }, [success]);

    const handleEdit = (item, name) => {
        if (name === 'edit') {
            open(
                <AddAuthorizationService
                    currentAuthorization={authIndex}
                    info={item}
                    authId={authIndex?.id}
                    fundingId={authIndex?.funderId?._id}
                    services={services}
                />,
            );
        }
        if (name === 'inactivate') {
            open(<CustomDelByNote
                info="Deactivate Authorized Service"
                text="Are you sure you want to deactivate this authorized service? Once deactivated, this service cannot be used to create new appointments."
                handleDel={(comment) => {
                    dispatch(clientActions.deleteClientsAuthorizationServ(item?.id, authIndex?.id, comment));
                }}
                innerText={'Deactivate'}
                inputLabel={'Reason for deactivation'}
                actionType={INACTIVATE_ACTION_TYPE}
            />);
        }
    };



    if (loader?.length) {
        return <Loader />;
    }

    return (
        <div className={classes.staffGeneralWrapper}>
            <div className={classes.notesWrap}>
                <AuthHeader
                    info={authIndex}
                    setAuthIndex={(item) => {
                        setAuthIndex(item);
                        dispatch(clientActions.getClientsAuthorizationsServ(item?.id));
                    }}
                    setToggleModal={() => open(
                        <AddAuthorization
                            fundingId={authIndex?.funderId?.id}
                            info={authIndex}
                            allList={info}
                        />,
                    )}
                />
                <div className={classes.authServiceContainerStyle}>
                    <div className={classes.authorizationServices}>
                        <p className={classes.authorizationServicesTitle}>
                            Authorized Services
                        </p>
                        {RolePermission([PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code]) &&
                            <AddButtonLight
                                addButnLightClassName={classes.addAuthServiceButnStyle}
                                addButnLightInnerText={'Add Authorized Service'}
                                onAddButnLightClick={() => open(
                                    <AddAuthorizationService
                                        currentAuthorization={authIndex}
                                        authId={authIndex?.id}
                                        fundingId={authIndex?.funderId?._id}
                                        services={services}
                                    />,
                                )}
                            />
                        }
                    </div>
                    <FullTable
                        head={authorizationHead}
                        body={authorizationBody}
                        loadingType={AUTH_ACTION_TYPE}
                        list={services}
                        height={'medium'}
                        handleClickButton={handleEdit}
                        notYet={authServiceNotYet}
                        activeRowId={''}
                    />
                </div>
            </div>
        </div>
    );
};
