import { clientStatusEnums, staffStatusEnums } from '../../utils';

export const agingEnums = {
    'ZERP': '0-30',
    '31-60': '31-60',
    '61+': '61+',
};

export const renderText = (status) => {
    switch (status) {

        case 'ACTIVE':
            return 'Active';
        case 'INACTIVE':
            return 'Inactive';

        // Client
        case clientStatusEnums.FBA:
            return 'FBA';
        case clientStatusEnums.ON_HOLD:
            return 'On Hold';
        case clientStatusEnums.WAIT_LIST:
            return 'Waitlist';
        case clientStatusEnums.TERMINATED:
            return 'Terminated';
        case clientStatusEnums.GRADUATED:
            return 'Graduated';

        //Staff
        case staffStatusEnums.ACTIVE:
            return 'Active';
        case staffStatusEnums.INACTIVE:
            return 'Inactive';
        case staffStatusEnums.HOLD:
            return 'On Hold';
        case staffStatusEnums.ON_LEAVE:
            return 'On Leave';

        default:
            return '';
    }
};

export const manageStatusCircle = (status) => {
    switch (status) {
        case 'ACTIVE':
            return { backgroundColor: '#ECFDF3', circleColor: '#12B76A', color: '#027A48' };
        case 'INACTIVE':
            return { backgroundColor: '#F5F5F5', circleColor: '#717680', color: '#414651' };


        case clientStatusEnums.FBA:
            return { backgroundColor: '#F0F9FF', circleColor: '#0BA5EC', color: '#026AA2' };

        case clientStatusEnums.ON_HOLD:
            return { backgroundColor: '#FFFAEB', circleColor: '#F79009', color: '#B54708' };

        case clientStatusEnums.WAIT_LIST:
            return { backgroundColor: '#FFF6ED', circleColor: '#FB6514', color: '#C4320A' };

        case clientStatusEnums.TERMINATED:
            return { backgroundColor: '#FEF3F2', circleColor: '#F04438', color: '#B42318' };

        case clientStatusEnums.GRADUATED:
            return { backgroundColor: '#F4F3FF', circleColor: '#7A5AF8', color: '#5925DC' };

        case staffStatusEnums.ON_LEAVE:
            return { backgroundColor: '#F0F9FF', circleColor: '#0BA5EC', color: '#026AA2' };

        case staffStatusEnums.HOLD:
            return { backgroundColor: '#FFFAEB', circleColor: '#F79009', color: '#B54708' };

        default:
            return { backgroundColor: '#FF1744', color: '#FF1744' };
    }

};