import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { serviceSingleStyles } from './styles';
import { Images, makeCapitalize, PermissionList, RolePermission, useModal } from 'utils';
import {
    AddButton,
    AddNotes,
    SimpleTooltip,
    DetailInfoCard,
    NameAndAvatar,
    UploadModal, UserInputsDropdown, SearchHistory, TagBox,
} from 'components';
import { AddContact, AddAuthorization } from 'fragments/client';
import { CLIENT_STATUSES } from './constants';

export const TabsHeader = ({ data }) => {
    const client = useSelector((state) => state.client.clientItemInfo);
    const classes = serviceSingleStyles();
    const allEnrolments = useSelector((state) => state?.client?.clientEnrollment);
    const activeEnrollments = allEnrolments?.active?.filter((item) => item.type === 'PRIMARY');
    const clientsAuthorizations = useSelector((state) => state.client.clientsAuthorizations);
    let history = useHistory();
    const info = history?.location?.state;
    const { open } = useModal();

    return (
        <Fragment>
            <div className="tabs-wrapper">
                <div className="full-width">
                    <div className="space-between-flex-start">
                        <NameAndAvatar
                            src={data?.avatar}
                            name={makeCapitalize(`${data?.firstName} ${data?.lastName}`)}
                        />
                        <div className="flex-align-start" style={{ gap: 16 }}>
                            {RolePermission([PermissionList.CLIENT_STATUS?.code]) &&
                                <UserInputsDropdown
                                    dropdownOptions={CLIENT_STATUSES}
                                    selected={data?.status}
                                    type={'client'}
                                    client={client}
                                />
                            }
                            {info?.activeTab === 'History' ? (
                                    <SearchHistory  />
                                ) :
                                info?.activeTab === 'Authorization' && !activeEnrollments?.length &&
                                RolePermission([PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code]) ? (
                                    <SimpleTooltip
                                        title={
                                            <p className={classes.infoTextForAuthStyle}>
                                                You can only add Authorization if you have at least one
                                                Enrollment.
                                            </p>
                                        }
                                        placement="top-end"
                                    >
                                        <div>
                                            <AddButton text={'Add Authorization'} disabled={true} />
                                        </div>
                                    </SimpleTooltip>
                                ) : (
                                    info?.activeTab === 'Contacts' && RolePermission([PermissionList.CLIENT_CONTACT_MANAGE?.code]) ?
                                        <AddButton text={'Add Contact'}
                                                   handleClick={() => open(<AddContact />)} />
                                        :
                                        info?.activeTab === 'Authorization' &&
                                        RolePermission([
                                            PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code,
                                        ]) ?
                                            <AddButton text={'Add Authorization'}
                                                       handleClick={() => open(<AddAuthorization
                                                           allList={clientsAuthorizations} />)} />
                                            :
                                            info?.activeTab === 'Notes' && RolePermission([PermissionList.CLIENT_NOTE_MANAGE?.code]) ?
                                                <AddButton
                                                    text="Add Notes"
                                                    handleClick={() => open(<AddNotes model="Client" id={data?.id} />)}
                                                />
                                                :
                                                info?.activeTab === 'Files' && RolePermission([PermissionList.CLIENT_FILE_MANAGE?.code]) ?
                                                    <AddButton
                                                        Icon={true}
                                                        customIcon={Images.uploadCloud}
                                                        text={'Upload Document'}
                                                        handleClick={() => open(
                                                            <UploadModal
                                                                currentId={data?.id}
                                                                onResource={'client'}
                                                            />,
                                                        )}
                                                    />
                                                    : null
                                )}
                        </div>
                    </div>

                    <TagBox tags={client?.tags}/>

                    <div className={classes.staffGeneralWrapper}>
                        {data?.code &&
                            <DetailInfoCard image={Images.verify} title={data?.code} />
                        }
                        {data?.email &&
                            <DetailInfoCard image={Images.sms} title={data?.email} type={'email'} />
                        }
                        {data?.phoneNumber &&
                            <DetailInfoCard image={Images.callCalling} title={data?.phoneNumber} type={'phone'} />
                        }
                    </div>

                </div>
            </div>
        </Fragment>
    );
};
