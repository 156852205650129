import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { systemCoreCommonStyle } from './style';
import { AddButton, CreateChancel, ValidationInput } from 'components';
import { ErrorText, FindLoad, FindSuccessItem, isNotEmpty, useModal } from 'utils';
import { httpRequestsOnSuccessActions, systemActions } from 'store';

const credentialBtn = {
    maxWidth: '174px',
    width: '100%',
    flex: '0 0 174px',
    padding: 0,
};

export const CreateEditServiceType = ({ info, afterSuccess }) => {
    const classes = systemCoreCommonStyle();
    const dispatch = useDispatch();
    const ACTION_TYPE = !!info ? 'EDIT_SERVICE_BY_ID_GLOBAL' : 'CREATE_SERVICE_GLOBAL';
    const loader = FindLoad(ACTION_TYPE);
    const success = FindSuccessItem(ACTION_TYPE);
    const [inputs, setInputs] = useState(!!info ? { ...info } : {});
    const [error, setError] = useState('');
    const { close } = useModal();

    useEffect(() => {
        if (!!success) {
            afterSuccess();
            setInputs({});
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    function handleChange(e) {
        setInputs((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
        error === e.target.name && setError('');
    }

    function handleSubmit() {
        const serviceDataIsValid =
            isNotEmpty(inputs.name) &&
            isNotEmpty(inputs.displayCode) &&
            isNotEmpty(inputs.category);
        if (serviceDataIsValid) {
            const serviceData = {
                name: inputs.name,
                displayCode: inputs.displayCode,
                category: inputs.category,
            };
            if (info) {
                dispatch(systemActions.editServiceByIdGlobal(serviceData, info.id));
            } else {
                dispatch(systemActions.createServiceGlobal(serviceData));
            }

        } else {
            const serviceDataErrorText = !isNotEmpty(inputs.name)
                ? 'name'
                : !isNotEmpty(inputs.displayCode)
                    ? 'displayCode'
                    : !isNotEmpty(inputs.category)
                        ? 'category'
                        : '';
            setError(serviceDataErrorText);
        }
    }

    return (
        <div style={!info ? { display: 'flex', alignItems: 'flex-start' } : {}}>
            <ValidationInput
                style={`${classes.systemInputStyles} ${!info ? 'create' : ''}`}
                onChange={handleChange}
                value={inputs.name}
                variant={'outlined'}
                name={'name'}
                type={'text'}
                label={!!info && 'Service Name*'}
                placeholder={'Service Name*'}
                typeError={error === 'name' ? `Service name ${ErrorText.isRequired}` : ''}
                Length={50}
            />
            <ValidationInput
                style={`${classes.systemInputStyles} ${!info ? 'create' : ''}`}
                onChange={handleChange}
                value={inputs.displayCode}
                variant={'outlined'}
                name={'displayCode'}
                type={'text'}
                label={!!info && 'Display Code*'}
                placeholder={'Display Code*'}
                typeError={error === 'displayCode' ? `Display code ${ErrorText.isRequired}` : ''}
                Length={20}
            />
            <ValidationInput
                style={`${classes.systemInputStyles} ${!info ? 'create' : ''}`}
                onChange={handleChange}
                value={inputs.category}
                variant={'outlined'}
                name={'category'}
                type={'text'}
                label={!!info && 'Category*'}
                placeholder={'Category*'}
                typeError={error === 'category' ? `Category ${ErrorText.isRequired}` : ''}
                Length={50}
            />
            {!!info ? (
                <div style={{ marginTop: 12 }}>
                    <CreateChancel
                        loader={!!loader.length}
                        create={'Save'}
                        chancel={'Cancel'}
                        onCreate={handleSubmit}
                        onClose={close}
                        buttonWidth="100%"
                    />
                </div>
            ) : (
                <AddButton
                    loader={!!loader.length}
                    type={'CREATE_SERVICE_GLOBAL'}
                    styles={credentialBtn}
                    handleClick={handleSubmit}
                    text="Add Service Type"
                />
            )}
        </div>
    );
};
