import {
  deleteGeneralReports,
  downloadPdf,
  getGeneralReports,
  getSignatureReports,
  getUtilizationReport,
} from './reports.action';
export { reportsReducer } from './reports.reducer';
export { watchReports } from './reports.saga';

export const reportActions = {
  /** Reports */

  getGeneralReports,
  deleteGeneralReports,
  getSignatureReports,
  downloadPdf,
  getUtilizationReport,
  /** End */

}

