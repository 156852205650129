import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { solutionsCardsContent, solutionsTab } from './constants';
import { SolutionsCard } from './solutionsCard';
import { animationParams, animationVariants } from 'fragments';

export const Solutions = () => {
    const [value, setValue] = useState('forSmallPractices');


    return (
        <div className='solutions-wrapper'>
            <div className='container'>
                <div className='solutions-box'>
                    <p className='blue-text solutions-blue-text'>
                        MAKE IT SIMPLE
                    </p>

                    <div style={{ overflow: 'hidden' }}>
                        <motion.h3
                            className='solutions-title'
                            {...animationParams}
                            variants={animationVariants.titleVariant}
                        >
                            Comprehensive Solutions for Every ABA Practice
                        </motion.h3>
                    </div>

                    <div style={{ overflow: 'hidden' }}>
                        <motion.p
                            className='solutions-desc'
                            {...animationParams}
                            variants={animationVariants.titleVariant}
                        >
                            Discover how TherapyLake can meet the unique needs of your practice, whether you're a small
                            clinic or a large organization
                        </motion.p>
                    </div>


                    <div className='tabs-wrapper'>
                        {solutionsTab.map(({ label, path }, index) => (
                            <motion.div
                                key={index}
                                className={`single-tab-wrapper ${path === value ? 'active-tab' : ''}`}
                                onClick={() => setValue(path)}
                                whileHover={{scale: 1.01, translateY: 0}}
                                whileTap={{scale: 0.99, opacity: .8}}
                                transition={{duration: .1}}
                            >
                                <p className={`single-tab_text  ${index === 0 ? 'border-none' : ''}`}>
                                    {label}
                                </p>
                            </motion.div>
                        ))}
                    </div>

                    <div style={{ overflow: 'hidden' }}>
                        <motion.p
                            className='solutions-desc'
                            {...animationParams}
                            variants={animationVariants.titleVariantMobile}
                        >
                            {solutionsCardsContent[value]?.label}
                        </motion.p>
                    </div>

                    <div className='solutions-card-wrapper'>
                        {solutionsCardsContent[value]?.tabItems.map(({ title, description }, index) => (
                            <SolutionsCard
                                key={index}
                                classNameWrapper='solutions-card-item'
                                title={title}
                                description={description}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

