import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CustomizedSwitch, DateRow, MinLoader, PriceRow, SimpleTooltip, TextRow, TypeRow } from 'components';
import { Colors, FindLoad, getLimitedVal, Images, PermissionList, RolePermission, useModal } from 'utils';
import { hooksForTable } from 'utils';
import { fundingSourceActions } from 'store';
import { fundingSourceCommonCoreStyle } from './styles';
import { FundingSourceModifiersAdd } from '../../modals';

function getModifierData(givenData = '') {
    return hooksForTable.showDashIfEmpty(getLimitedVal(givenData));
}

export const ModifierTBody = ({ modifier, currentService, globalCredentials = [] }) => {
    const classes = fundingSourceCommonCoreStyle();
    const dispatch = useDispatch();
    const [modifierStatusIsActive, setModifierStatusIsActive] = useState(modifier?.status);
    const [statusWasChanged, setStatusWasChanged] = useState(false);
    // getModifierData(globalCredentials?.filter((elem) => elem?.id === modifier?.credentialId && elem?.id)?.name);
    const credentials = modifier?.credentials?.length ? modifier?.credentials : [];
    const newArr = credentials?.length > 1 ? credentials?.slice(1) : [];
    const loader = FindLoad('CHANGE_FUNDING_MODIFIER_STATUS');
    const [currentId, setCurrentId] = useState('');
    const { open, close } = useModal();

    useEffect(() => {
        if (statusWasChanged) {
            setCurrentId(modifier?.id);
            dispatch(fundingSourceActions.changeFundingModifierStatus(currentService?.id, modifier?.id));
        }
    }, [modifierStatusIsActive, statusWasChanged]);


    return (
        <>
            <div className={classes.tbodyContainerStyle}>
                <div className={classes.tdStyle}>
                    <TextRow name={modifier?.name} />
                </div>
                <div className={classes.tdStyle}>
                    <TextRow name={modifier?.modifierTag ? modifier?.modifierTag  : 'N/A'} />
                </div>
                <div className={classes.tdStyle}>
                    {credentials?.length ?
                        <div className="credential-item">
                            <TextRow name={modifier?.credentials?.length ? modifier?.credentials[0]?.name : ''} textWidth={8} />
                            {credentials?.length > 1 ?
                                <SimpleTooltip
                                    title={
                                        <div>
                                            {newArr?.length && newArr?.map((item, k) => (
                                                <p key={k}>{item?.name}</p>
                                            ))}
                                        </div>
                                    }
                                    placement="top-end"
                                >
                                    {modifier?.credentials?.length > 1 ? <button>+{modifier?.credentials?.length - 1}</button> : ''}
                                </SimpleTooltip>
                                : ''
                            }
                        </div>
                        :
                        <p className="not-set">Not set</p>
                    }
                </div>
                <div className={classes.tdStyle}>
                    <PriceRow info={modifier?.billedRate ? modifier?.billedRate : 0} />
                </div>
                <div className={classes.tdStyle}>
                    <PriceRow info={modifier?.allowedRate ? modifier?.allowedRate : 0} />
                </div>
                <div className={classes.tdStyle}>
                    <TypeRow text={modifier?.type ? modifier?.type : 'Not Set'} />
                </div>
                {RolePermission([PermissionList.FS_SERVICE_CREATE?.code]) &&
                    <div className={classes.tdStyle}>
                        <button
                            disabled={modifier?.status === false}
                            className={classes.editModifierIconStyle}
                            onClick={() => open(<FundingSourceModifiersAdd
                                info={modifier}
                                currentService={currentService}
                                credentials={globalCredentials}
                            />)}
                        >
                            <img src={modifier?.status === false ? Images.editGray : Images.edit} alt="edit" />
                        </button>
                    </div>
                }
                <div className={classes.tdStyle}>
                    {modifier?.status === false ?
                        <DateRow date={modifier?.inactivatedDate} />
                        :
                        currentId === modifier?.id && loader?.length ?
                            <MinLoader
                                margin={'0'}
                                color={Colors.ThemeBlue}
                                position={'relative'}
                            />
                            :
                            <CustomizedSwitch
                                disabled={!RolePermission([PermissionList.FS_SERVICE_CREATE?.code])}
                                checked={modifierStatusIsActive}
                                handleClick={() => {
                                    setModifierStatusIsActive((prevState) => !prevState);
                                    setStatusWasChanged(true);
                                }}
                            />
                    }
                </div>
            </div>
        </>
    );
};
