import { makeStyles } from "@material-ui/core/styles";
import {Colors} from "utils";

export const logoStyle = makeStyles(() => ({
  logoWrapper: {
    display:'flex',
    fontSize:'18px',
    fontWeight:'bold',
    margin:'16px',
    color:Colors.BackgroundBlue,
  },

  bigSizeLogo:{
    width: '100px',
    height: '17',
  },

}));
