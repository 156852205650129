import { SavePage } from 'utils';
import { HtmlTooltip } from './htmlTool';
import React from 'react';

export const handleSelectSort = (e, history, info) => {

    const params = {
        ...info
    }
    params.page = 1;
    params.skip = 0;
    if (e.target.value === 'All') {
        // const cleanList = {
        //     ...info
        // }
        delete params[e.target.name]
        SavePage(history, info, {...params})
    } else {
        if (!e.target.value) {
            // const filteredList = {
            //     ...info
            // }
            delete params[e.target.name]
            SavePage(history, info, {...params})
        } else {
            // const newObj = {
            //     ...info
            // }
            params[e.target.name] = e.target.value
            SavePage(history, info, {...params})
        }
    }
}



export const handleSetName = (name, history, info) => {
    const anotherInfo = {...info}
    if (name) {
        anotherInfo.sortType = name
    } else {
        delete anotherInfo.sortType
    }
    SavePage(history, info, {...anotherInfo})
}


export const bigSizeText = (item) => {
    if (item?.bigSize) {
        return <HtmlTooltip
            title={<p>{item?.title}</p>}
            placement="top-end"
        >
            <p>
                {`${item?.title.slice(0, item?.bigSize)}...`}
            </p>
        </HtmlTooltip>;
    } else {
        return <p>{item?.title}</p>;
    }
};

export const smallSizeText = (item) => {
    if (item?.smallSize) {
        return <HtmlTooltip
            title={<p>{item?.title}</p>}
            placement="top-end"
        >
            <p>
                {`${item?.title.slice(0, item?.smallSize)}...`}
            </p>
        </HtmlTooltip>;
    } else {
        return item?.title;
    }
};