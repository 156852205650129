import { call, put, takeLatest } from 'redux-saga/effects';
import { availabilityScheduleService } from './availabilitySchedule.service';
import {
    CREATE_AVAILABILITY_SCHEDULE_GLOBAL,
    GET_AVAILABILITY_SCHEDULE_GLOBAL_SUCCESS,
    GET_AVAILABILITY_SCHEDULE_GLOBAL,
    GET_STAFF_SCHEDULE,
    GET_CLIENT_SCHEDULE,
    GET_STAFF_SCHEDULE_SUCCESS,
    GET_CLIENT_SCHEDULE_SUCCESS,
} from './availabilitySchedule.type';
import { httpRequestsOnLoadActions } from '../http_requests_on_load';
import { httpRequestsOnErrorsActions } from '../http_requests_on_errors';
import { httpRequestsOnSuccessActions } from '../http_requests_on_success';

function* getAvailabilitySchedule(action) {
   if(action?.payload?.load !== 'noLoad') {
      yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   }
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(availabilityScheduleService.getAvailabilityScheduleService, action?.payload);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put({
            type: GET_AVAILABILITY_SCHEDULE_GLOBAL_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
        yield put({
            type: GET_AVAILABILITY_SCHEDULE_GLOBAL_SUCCESS,
            payload: [],
        });
    }
}

function* createAvailabilitySchedule(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(availabilityScheduleService.createAvailabilityScheduleService, action);
       yield put({
          type: GET_AVAILABILITY_SCHEDULE_GLOBAL,
          payload: { id: action.payload.id, onModel: action.payload.onModel, load: 'noLoad'},
       });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* getStaffSchedule(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    try {
       const res = yield call(availabilityScheduleService.getStaffScheduleService,  action?.payload?.id);
       yield put({
          type: GET_STAFF_SCHEDULE_SUCCESS,
          payload: res?.data,
       });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}
function* getClientSchedule(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    try {
        const res = yield call(availabilityScheduleService.getClientScheduleService, action?.payload?.id);
        yield put({
            type: GET_CLIENT_SCHEDULE_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}
export const watchAvailabilitySchedule = function* watchAvailabilityScheduleSaga() {
    yield takeLatest(GET_AVAILABILITY_SCHEDULE_GLOBAL, getAvailabilitySchedule);
    yield takeLatest(CREATE_AVAILABILITY_SCHEDULE_GLOBAL, createAvailabilitySchedule);
    yield takeLatest(GET_STAFF_SCHEDULE, getStaffSchedule);
    yield takeLatest(GET_CLIENT_SCHEDULE, getClientSchedule);
};
