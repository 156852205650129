import { Images } from '../../utils';
import { buttonsStyle } from './styles';

export const AddCircleBlue = ({ text, handleClick }) => {
    const classes = buttonsStyle()
    return (
        <button className={classes.addCircleBtn} onClick={handleClick}>
            <img src={Images.addHours} alt='icon' />
            {text }
        </button>
    )
}