import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import { ButtonLanding, MinLoader } from 'components';
import { LandingSvg, Svg } from 'assets';
import { animationParams, animationVariants } from 'fragments';

import axios from 'axios';
import { firstBlock, secondBlock, socialMediaIcons, thirdBlock } from './constants';

export const Footer = () => {
    const [loader, setLoader] = useState(false);
    const { handleSubmit, register, reset } = useForm();
    const location = useLocation();
    const history = useHistory();


    const d = new Date();
    const year = d.getFullYear();

    function onSubmit(data) {
        setLoader(true);
        axios
            .post('https://rresdx/fd', data)
            .then(() => {
                setLoader(false);
                toast('Your email was sent');
                reset();
            })
            .catch(() => {
                setLoader(false);
                toast('Something went wrong');
            });
    }

    return (
        <footer className={`footer-wrapper ${location.pathname === '/about-us' ? 'footer-wrapper-about-us' : ''}`}>
            <motion.div
                className='footer-wrapper-background'
                {...animationParams}
                variants={animationVariants.footerBackground}
            />
            <div className='container'>
                <div className='footer-box'>
                    <Svg.FooterLogo className='footer-box-svg' />
                    <div className='footer-blocks'>
                        <div className='footer-block-item-input'>
                            <form onSubmit={handleSubmit(onSubmit)} className='enter-email-form'>
                                <input
                                    {...register('email', { required: true })}
                                    className='enter-email-input'
                                    placeholder='Enter Your Email'
                                />

                                <button type='submit' className='enter-email-button'>
                                    {loader
                                        ? <MinLoader
                                            height={10}
                                            small
                                            position='static'
                                            margin='0'
                                        />
                                        : <LandingSvg.ArrowRightSvg className='enter-email-button-arrow' />
                                    }
                                </button>
                            </form>

                            <p className='inputs-block-text'>
                                We promise not to spam you!
                            </p>
                        </div>

                        <div className='footer-block-item'>
                            {firstBlock.map(({ title, path }, index) => (
                                <Link to={path} className='footer-block-item_text' key={index}>
                                    {title}
                                </Link>
                            ))}
                        </div>

                        <div className='footer-block-item'>
                            {secondBlock.map(({ title, path }, index) => (
                                <Link to={path} className='footer-block-item_text' key={index}>
                                    {title}
                                </Link>
                            ))}
                        </div>

                        <div className='footer-block-item'>
                            {thirdBlock.map(({ title, path }, index) => (
                                <Link to={path} className='footer-block-item_text' key={index}>
                                    {title}
                                </Link>
                            ))}
                        </div>
                    </div>

                    <div className='footer-info-block'>
                        <div className='footer-info-block-terms'>
                            <p className='footer-info-block-terms-text'>
                                © {year} - TherapyLake
                            </p>
                            <p className='footer-info-block-terms-text'>
                                Powered by Eachbase
                            </p>

                            <Link to='/terms-and-conditions' className='footer-info-block-terms-text'>
                                Terms & Conditions
                            </Link>

                            <Link to='/privacy-policy' className='footer-info-block-terms-text'>
                                Privacy Policy
                            </Link>
                        </div>

                        <div className='footer-info-block-social'>
                            {socialMediaIcons.map(({ Icon, path }, index) => (
                                <a
                                    key={index}
                                    href={path} target='_blank'
                                    className='footer-info-block-social-icon'
                                >
                                    <Icon className='footer-info-block-social_svg' />
                                </a>
                            ))}
                        </div>

                        <div className='footer-buttons-block_mobile'>
                            <ButtonLanding
                                className='footer-info-button-request-demo'
                                text='Request a Demo'
                                onClick={() => history.push('/contact-us')}
                            />

                            {/*<ButtonLanding*/}
                            {/*    className='footer-info-button-download-app'*/}
                            {/*    text='Download App'*/}
                            {/*/>*/}
                        </div>
                    </div>

                    <div className='footer-block-item-input footer-block-item-input_mobile'>
                        <form onSubmit={handleSubmit(onSubmit)} className='enter-email-form'>
                            <input className='enter-email-input' placeholder='Enter Your Email' />

                            <button type='submit' className='enter-email-button'>
                                {loader
                                    ? <MinLoader
                                        height={10}
                                        small
                                        position='static'
                                        margin='0'
                                    />
                                    : <LandingSvg.ArrowRightSvg className='enter-email-button-arrow' />
                                }
                            </button>
                        </form>

                        <p className='inputs-block-text'>
                            We promise not to spam you!
                        </p>
                    </div>

                    <div className='footer-info-block-terms footer-info-block-terms_mobile'>
                        <p className='footer-info-block-terms-text'>
                            © {year} - TherapyLake
                        </p>
                        <p className='footer-info-block-terms-text'>
                            Powered by Eachbase
                        </p>

                        <Link to='/terms-and-conditions' className='footer-info-block-terms-text'>
                            Terms & Conditions
                        </Link>

                        <Link to='/privacy-policy' className='footer-info-block-terms-text'>
                            Privacy Policy
                        </Link>
                    </div>

                    <div className='footer-info-buttons'>
                        <p className='footer-info-buttons-text'>
                            We are now offering management consulting services!
                        </p>

                        <div className='footer-buttons-block'>
                            <ButtonLanding
                                className='footer-info-button-request-demo'
                                text='Request a Demo'
                                onClick={() => history.push('/contact-us')}
                            />

                            {/*<ButtonLanding*/}
                            {/*    className='footer-info-button-download-app'*/}
                            {/*    text='Download App'*/}
                            {/*/>*/}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};
