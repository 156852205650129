import React from "react";

export const CardBox = ({ cardBackgroundColor, cardClassName, cardStyle, children }) => {
   return (
      <div
         style={{ backgroundColor: cardBackgroundColor, ...cardStyle }}
         className={`card ${cardClassName}`}
      >
         <div className="card-content-container content-container">{children}</div>
      </div>
   );
};
