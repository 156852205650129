import React from 'react';
import moment from 'moment/moment';
import { Images } from 'utils';
import { SimpleTooltip } from './tooltip';

export const InactivatedInfo = ({ title, date }) => {
    return (
        <SimpleTooltip
            placement="top-start"
            title={<div>{title}</div>}
        >
            <div className='flex-align-center' style={{gap: '8px', width:'120px'}}>
                <div className='red-circle'/>
                <p>{ moment.utc(date).format('MM/DD/YYYY') }</p>
                <img src={Images.toolInfoIcon} alt={'icon'}/>
            </div>
        </SimpleTooltip>
    );
}