import {
    DateRow,
    PriceRow,
    TextRow,
    SimpleTooltip,
    DisplayIdRow,
    LinkRow,
    DateRowStartEnd,
    PriceInput, MinLoader,
} from 'components';
import { agingEnumsSelector, convertHours, FindLoad, Images, PermissionList, RolePermission } from 'utils';
import React from 'react';
import { Checkbox } from '@material-ui/core';
import { AgingRow, AgingRowBackground } from '../../../components/table/tableRows';
import { manageStatusColor } from '../../../utils/hooks/manageStatus';

export const generateInvoiceHead = (handleSelect, fullList, selected) => {
    return [
        {
            renderView: <SimpleTooltip
                title={<p>Select All</p>}
                placement="top-start"
            >
                <Checkbox
                    onClick={(e) => handleSelect(e, '', 'all')}
                    name={'checkBox'}
                    style={{ padding: 0, color: 'white' }}
                    checked={fullList?.length === selected?.length}
                />
            </SimpleTooltip>, width: '50px',
        },
        { name: '', title: 'Service Code', icon: 'date', noSearch: true, custom: false },
        { name: '', title: 'Place of Service', noSearch: true, custom: false },
        { name: '', title: 'Service Date', noSearch: true, custom: false },
        { name: '', title: 'Client', noSearch: true, custom: false },
        { name: '', title: 'Hours', noSearch: true, custom: false },
        { name: '', title: 'Invoice Amount', noSearch: true, custom: false },
    ];
};

export const generateInvoiceBody = (handleSelect, fullList, selected, handleEdit) => {
    return [
        {
            button: (handle, item) => (
                <SimpleTooltip
                    title={<p>Select</p>}
                    placement="top-start"
                >
                    <Checkbox
                        onClick={(e) => handleSelect(e, item)}
                        name={'checkBox'}
                        style={{ padding: 0, color: '#0C66E4' }}
                        checked={selected?.includes(item?.id)}
                    />
                </SimpleTooltip>
            ), notClickable: true,
        },
        {
            rowText: (item) => <TextRow name={item?.serviceCode ? item?.serviceCode : 'N/A'} />,
        },

        { rowText: (item) => <TextRow name={item?.placeOfService} /> },
        { rowText: (item) => <DateRow date={item?.dateOfService} /> },
        { rowText: (item) => <TextRow name={item?.client} /> },
        { rowText: (item) => <div>{convertHours(item?.hours)}</div> },
        { rowText: (item) => <PriceRow info={item?.invoiceAmount} /> },
    ];
};

export const GENERATE_ACTION_TYPE = 'GET_INVOICES_FOR_GENERATE';


// Invoice Payment Table config
export const invoicePaymentHead = [
    { name: 'displayId', searchKey: 'DISPLAY_ID', title: 'Invoice ID', custom: false, width: '130px' },
    { name: 'invoicedDate', title: 'Invoice Date', iconType: 'date', disabled: true, width: '130px' },
    { name: 'dos', title: 'DOS', iconType: 'date', disabled: true },

    { name: 'client', rowName: 'clientCode', title: 'Client', custom: false, disabled: true },
    { name: 'totalAMT', title: 'Total AMT', custom: false },
    { name: 'paidAMT', title: 'Pmt. AMT', custom: false },
    { name: 'balance', title: 'Balance', custom: false },
    {
        name: 'status', title: 'Status', disabled: true, width: '75px',
        filterList: [
            { name: 'All', id: 'All' },
            { name: 'Open', id: 'OPEN' },
            { name: 'Paid', id: 'PAID' },
            { name: 'Closed', id: 'CLOSED' },
        ],
    },
    { name: 'aging', title: 'Aging', width: '100px', disabled: true, iconType: 'date', },
    RolePermission([PermissionList.INVOICE_PMT_PAY?.code]) &&
    { name: 'actions', title: 'Actions', custom: false, disabled: true, width: '240px' },
];

export const invoicePaymentBody = (activeRow, setActiveRow, selectedId, handleChange, handleCloseInputs, payInFull, handleSave) => {
    const loadPay = FindLoad('EDIT_PAYMENT_INVOICE');
    const loadPayInFull = FindLoad('PAY_IN_FULL_PAYMENT_INVOICE');

    return [
        { rowText: (item) => <DisplayIdRow id={item?.displayId ? item?.displayId : 'N/A'} /> },
        { rowText: (item) => <DateRow date={item?.invoiceDate} /> },
        { rowText: (item) => <DateRowStartEnd start={item?.dateRange?.early} end={item?.dateRange?.latest} /> },
        {
            rowText: (item) => <LinkRow
                name={item?.client?.clientCode ? item?.client?.clientCode : 'N/A'}
                href={RolePermission([PermissionList.CLIENT_READ?.code]) ? `/client/${item?.clientId}` : ''}
                checkPermission={item?.client?.clientId ? RolePermission([PermissionList.CLIENT_READ?.code]) : null}
            />,
        },
        { rowText: (item) => <PriceRow info={item?.totalAMT} /> },

        {
            rowText: (item) =>
                <div style={{ padding: '0 0 0 16px' }}>
                    <div
                        className={'invoice-table-input'}
                        style={{
                            background: activeRow?.id === item?.id ? 'white' : 'transparent',
                            borderBottom: activeRow?.id === item?.id ? '1px solid #347AF0' : '1px solid transparent',
                        }}
                    >
                        <PriceInput
                            placeholder={activeRow?.paidAMT === '' ? '0' : ''}
                            name={'paidAMT'}
                            handleChange={(e) => handleChange(e)}
                            value={activeRow?.id === item?.id ? activeRow?.paidAMT === '' ? 0 :
                                activeRow?.paidAMT : item?.paidAMT ? item?.paidAMT : 0}
                            disabled={activeRow?.id !== item?.id}
                        /></div>
                </div>, padding: '0', notClickable: true,
        },
        { rowText: (item) => <PriceRow info={item?.balance} /> },
        { rowText: (item) => manageStatusColor(item?.status) },
        { rowText: (item) => <AgingRow count={item?.aging} />, padding: '0' },
        {
            rowText: (item) => <div
                className="invoice-table-actions"
                style={{
                    color: '#51566D',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {activeRow?.id === item?.id ?
                    <div className="flex-align-center">
                        <button className="save-btn-box" onClick={handleSave}>
                            {activeRow?.id === item?.id && loadPay?.length ?
                                <MinLoader margin={'0'} color={'#344054'}
                                           position="relative" />
                                :
                                'Save'
                            }
                        </button>
                        <button
                            style={{ margin: 0 }}
                            onClick={handleCloseInputs} className="posting-close-btn">
                            <img src={Images.remove} alt="icon" />
                        </button>
                    </div>
                    :
                    <button
                        style={{ opacity: item?.status === postingInvoiceEnums.COMPLETED ? 0.5 : 1 }}
                        disabled={item?.status === postingInvoiceEnums.COMPLETED}
                        className={'edit'} onClick={() => setActiveRow(item)}>
                        <img src={Images.edit} alt="icon" />
                    </button>
                }

                {(item?.status !== postingInvoiceEnums.COMPLETED &&
                    item?.status !== postingInvoiceEnums.PAID
                ) ?
                    <button onClick={() => payInFull(item)} className={'pay'}>
                        {selectedId?.id === item?.id && loadPayInFull?.length ?
                            <MinLoader
                                margin={'4px 0 0'}
                                color={'white'}
                                position="relative"
                            />
                            :
                            'Pay in Full'
                        }
                    </button>
                    :
                    <SimpleTooltip title={<p>{'This invoice has already been paid.'}</p>} placement="top-start">
                        <button style={{ opacity: '0.5' }} className={'pay'}>
                            Pay in Full
                        </button>
                    </SimpleTooltip>
                }
            </div>, notClickable: true,
        },
    ];
};


export const GET_ACTION_TYPE = 'GET_INVOICE_PAYMENTS';

export const postingInvoiceEnums = {
    COMPLETED: 'COMPLETED',
    PAID: 'PAID',
};

export const invoicesNotYet = {
    title: 'No Invoices',
    subTitle: 'There are no invoices.',
    image: Images.noPendingClaim,
};
