import React from 'react';
import { useHistory } from 'react-router-dom';
import { ChartView } from './chartFragments';
import { NoItemsYet } from 'components';
import { Images } from 'utils';

export const Charts = ({}) => {
    const history = useHistory();
    const info = history?.location?.state;

    return (
        <div className="chard-wrapper">
            {info?.activeChart ?
                <ChartView />
                :
                <NoItemsYet
                    containerStyles={{ position: 'relative' }}
                    text={'Select a KPI Widget'}
                    subTitle={'Click on a KPI widget from the top to view detailed data.'}
                    image={Images.noAnalyticYet}
                />
            }
        </div>
    );
};