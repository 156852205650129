import { LandingSvg } from 'assets';


export const contactOptions = [
    {
        Icon: LandingSvg.callIconSvg,
        details: 'tel:+4242770772',
        label: '(424) 277-0772',
    },
    {
        Icon: LandingSvg.SmsStarSvg,
        details: 'mailto:info@therapylake.com',
        label: 'info@therapylake.com',
    },
    {
        Icon: LandingSvg.AddressSvg,
        details: 'https://www.google.com/maps/place/4100+W+Alameda+Ave+3rd+floor,+Burbank,+CA+91505,+USA/@34.1523756,-118.344763,17z/data=!3m1!4b1!4m6!3m5!1s0x80c2bfccddfd39b3:0xc64abf3fd887dc2f!8m2!3d34.1523756!4d-118.344763!16s%2Fg%2F11ll6tkc0q?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D',
        label: '4100 W Alameda Ave, Burbank, CA 91505',
    },
];

export const contactSocial = [
    {
        Icon: LandingSvg.InstagramContact,
        label: 'Instagram',
        details: 'https://www.instagram.com/therapylake/',
    },
    {
        Icon: LandingSvg.LinkedinContactSvg,
        label: 'Linkedin',
        details: 'https://www.linkedin.com/company/therapylake/about/',
    },

    {
        Icon: LandingSvg.TwitterContactSvg,
        label: 'Twitter',
        details: 'https://x.com/i/flow/login?redirect_after_login=%2Ftherapylakeaba',
    },
];


export const faqs = [
    {
      title: "What services does your platform provide?",
      description: "Our platform provides comprehensive solutions for managing billing, scheduling, payroll, and claims processing. We streamline administrative tasks to help your organization operate more efficiently."
    },
    {
      title: "How does the scheduling feature work?",
      description: "Our scheduling tool allows users to create, manage, and edit appointments seamlessly. You can view schedules in both list and calendar views, and customize appointments by staff member, client, or service type."
    },
    {
      title: "Is your platform HIPAA compliant?",
      description: "Yes, we take data security and privacy seriously. Our platform is fully HIPAA compliant, ensuring that all your sensitive information is protected and managed according to regulatory standards."
    },
    {
      title: "Can I integrate your platform with other tools or software we use?",
      description: "Yes, our platform offers API integrations that can work with various third-party software and tools. We also support custom integrations to make sure your workflows remain uninterrupted."
    },
    {
      title: "How can I track and manage claims within the platform?",
      description: "You can track claims from submission to payment. Our Claims section provides a detailed view of all your claims, including their current status, financial breakdown, and any necessary forms, helping you stay on top of your billing."
    },
    {
      title: "How does payroll processing work?",
      description: "Our payroll feature allows you to generate timesheets, process payroll for different staff roles, and download payroll data in CSV format. You can also manage paycodes and process hourly or fixed-pay timesheets efficiently."
    },
    {
      title: "Can I customize user permissions and roles?",
      description: "Absolutely! You have complete control over who can access which features. Our Role Management module lets you create custom roles, assign permissions, and manage access levels to suit your team’s needs."
    },
    {
      title: "Is there customer support if I have questions or need assistance?",
      description: "Yes, we offer dedicated customer support through email, chat, and phone. Our team is here to help you with any questions or troubleshooting you might need as you navigate our platform."
    },
    {
      title: "What options are available for invoicing?",
      description: "You can create, track, and manage invoices directly through our platform. We support multiple payment types and offer customizable invoice templates. Plus, you can view detailed reports and download invoices in various formats."
    },
    {
      title: "Can I import data from my current system to your platform?",
      description: "Yes, we offer data import options to help you migrate from your existing system smoothly. Our support team can guide you through the process to ensure that all your data is transferred correctly."
    },
    {
      title: "How secure is my data on your platform?",
      description: "We employ industry-standard encryption and security practices to ensure your data is secure. Regular backups and access controls are in place to prevent unauthorized access and to protect your information."
    },
    {
      title: "Do you provide training for new users?",
      description: "Yes, we offer onboarding and training sessions to help new users understand and utilize the platform effectively. Our goal is to ensure that you and your team can start using the platform with confidence."
    },
    {
      title: "Is there a mobile version or app available?",
      description: "Yes, our platform is mobile-responsive, and we also have a dedicated mobile app that lets you access key features on the go, whether you’re managing appointments or tracking timesheets."
    },
    {
      title: "Can I generate reports and analytics through the platform?",
      description: "Certainly! Our platform includes advanced reporting tools that allow you to generate reports on claims, billing, payroll, and scheduling. Customize these reports to gain insights into your practice’s performance."
    }
  ]
  





