import { CircularProgress } from "@material-ui/core";
import React from "react";

export const MinLoader = ({margin, color, style = {}, position, small, width, height }) => {
  const styles = {
    loaderStyle: {
      position: position ? position : "absolute",
      color: color,
      width: width ? width : small ? '15px' : "20px",
      height: height ? height : small ? '15px' : "20px",
      margin: margin ? margin : "5px 0 0 10px",
    },
  };
  return <CircularProgress style={{ ...styles.loaderStyle, ...style }} />;
};
