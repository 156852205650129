/** PayCode types */

export const CREATE_PAY_CODE_GLOBAL = 'CREATE_PAY_CODE_GLOBAL'

export const GET_PAY_CODE_GLOBAL = 'GET_PAY_CODE_GLOBAL'
export const GET_PAY_CODE_GLOBAL_SUCCESS = 'GET_PAY_CODE_GLOBAL_SUCCESS'

export const EDIT_PAY_CODE_BY_ID_GLOBAL = 'EDIT_PAY_CODE_BY_ID_GLOBAL'

export const DELETE_PAY_CODE_BY_ID_GLOBAL = 'DELETE_PAY_CODE_BY_ID_GLOBAL'

/** End */

/** Overtime Settings */

export const CREATE_OVERTIME_SETTINGS_GLOBAL = 'CREATE_OVERTIME_SETTINGS_GLOBAL'

export const GET_OVERTIME_SETTINGS_GLOBAL = 'GET_OVERTIME_SETTINGS_GLOBAL'
export const GET_OVERTIME_SETTINGS_GLOBAL_SUCCESS = 'GET_OVERTIME_SETTINGS_GLOBAL_SUCCESS'

export const EDIT_OVERTIME_SETTINGS_BY_ID_GLOBAL = 'EDIT_OVERTIME_SETTINGS_BY_ID_GLOBAL'

export const DELETE_OVERTIME_SETTINGS_BY_ID_GLOBAL = 'DELETE_OVERTIME_SETTINGS_BY_ID_GLOBAL'

/** End */

/** Mileage Compensation*/

export const CREATE_MILEAGE = "CREATE_MILEAGE";
export const CREATE_MILEAGE_SUCCESS = "CREATE_MILEAGE_SUCCESS";

export const EDIT_MILEAGE = "EDIT_MILEAGE";

export const GET_MILEAGES = "GET_MILEAGES";
export const GET_MILEAGES_SUCCESS = "GET_MILEAGES_SUCCESS";

export const DELETE_MILEAGE = "DELETE_MILEAGE";

export const TERMINATE_MILEAGE = "TERMINATE_MILEAGE";

/** End */