import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { FindLoad, FindSuccessItem, Images, useModal } from 'utils';
import { CreateChancel, CustomizedSwitch, EditWithIcon, InputMain, SimpleTooltip } from 'components';
import { systemItemStyles } from './styles';
import { ConnectOfficeAlly } from './modals/connectOfficeAlly';
import { httpRequestsOnSuccessActions, systemActions } from 'store';

const ACTION_TYPE = 'EDIT_OFFICE_ALLY_CONNECTION';
const DISCONNECT_ACTION_TYPE = 'DISCONNECT_OFFICE_ALLY';
export const OfficeAlly = () => {
    const { handleSubmit, control, reset } = useForm();
    const [editAlly, setEditAlly] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const officeAlly = useSelector((state) => state.system.officeAlly);
    const classes = systemItemStyles();
    const { open, close } = useModal();
    const dispatch = useDispatch();
    const loader = FindLoad(ACTION_TYPE);
    const success = FindSuccessItem(ACTION_TYPE);
    const successDisconnect = FindSuccessItem(DISCONNECT_ACTION_TYPE);
    const [connect, setConnect] = useState(false);

    useEffect(() => {
        if (officeAlly?.id) {
            setConnect(true);
            reset({
                username: officeAlly?.sftpCredentials?.username,
                password: officeAlly?.sftpCredentials?.password,
            });
        }
    }, [officeAlly]);

    useEffect(() => {
        if (success) {
            setEditAlly(false);
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
        if (successDisconnect) {
            setEditAlly(false);
            setConnect(false);
            reset({})
            dispatch(httpRequestsOnSuccessActions.removeSuccess(successDisconnect));
        }
    }, [success, successDisconnect]);

    const handleCreate = (data) => {
        dispatch(systemActions.editOfficeAlly({
            sftpCredentials: {
                username: data.username,
                password: data.password,
            },
        }));
    };

    const handleSetCalendarStatus = () => {
        setConnect(!connect);
        dispatch(systemActions.disconnectOfficeAlly());
    };

    return (
        <div>
            <div style={{ marginTop: 32 }}>
                <p className="integration-type-title">Office Ally Integration</p>
                <div className="office-ally-box">
                    <div className="space-between" style={{ padding: 16 }}>
                        <div className={classes.iconAndTitle}>
                            <img src={Images.officeAlly} alt="officeAlly" />
                            <p>Office Ally</p>
                        </div>
                        {officeAlly?.id ?
                            <div className={classes.iconAndTitle} style={{ gap: 16, height: 32 }}>
                                <EditWithIcon handleClick={() => setEditAlly(!editAlly)} />

                                <SimpleTooltip title={<p>{'Disconnect Office Ally'}</p>} placement="top-start">
                                    <CustomizedSwitch
                                        checked={connect}
                                        handleClick={handleSetCalendarStatus}
                                    />
                                </SimpleTooltip>
                            </div>
                            :
                            <button onClick={() => open(<ConnectOfficeAlly />)} className={classes.connectBtn}>
                                Connect
                            </button>
                        }
                    </div>

                    {editAlly && officeAlly?.id &&
                        <div className="edit-office-ally-connection">
                            <form onSubmit={handleSubmit(handleCreate)}>
                                <div className="flex-align-center" style={{ gap: 16 }}>
                                    <InputMain
                                        label={'SFTP Username*'}
                                        customClass={true}
                                        variant="outlined"
                                        name="username"
                                        type="text"
                                        control={control}
                                        placeholder="Password"
                                        rules={{
                                            required: 'Username is required.',
                                        }}
                                    />
                                    <InputMain
                                        label={'Password*'}
                                        customClass={true}
                                        variant="outlined"
                                        name="password"
                                        type="password"
                                        showPassword={showPassword}
                                        control={control}
                                        placeholder="Password"
                                        rules={{
                                            required: 'Password is required.',
                                        }}
                                        onClickIconEnd={() => setShowPassword(prev => !prev)}
                                    />
                                </div>
                                <div style={{ marginTop: 12 }}>
                                    <CreateChancel
                                        justifyContent={'flex-end'}
                                        type="submit"
                                        loader={!!loader.length}
                                        create={'Save'}
                                        chancel={'Cancel'}
                                        onClose={() => {
                                            setEditAlly(false)
                                            reset({
                                                username: officeAlly?.sftpCredentials?.username,
                                                password: officeAlly?.sftpCredentials?.password,
                                            })
                                        }}
                                        buttonWidth={'100px'}
                                    />
                                </div>
                            </form>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};