import React from 'react';
import { motion } from 'framer-motion';
import { billingPostingCard } from './constants';
import { BillingPostingCard } from './billingPostingCard';
import { animationParams, animationVariants } from 'fragments';


export const BillingPosting = () => {
    return (
        <div className='billing-posting-wrapper'>
            <div className='container'>
                <div className='billing-posting-box'>
                    <p className='blue-text'>
                        Streamlined Financial Management
                    </p>

                    <div style={{ overflow: 'hidden' }}>
                        <motion.h3
                            className='billing-posting-title'
                            {...animationParams}
                            variants={animationVariants.titleVariantMobile}
                        >
                            Billing & Posting
                        </motion.h3>
                    </div>

                    <div style={{ overflow: 'hidden' }}>
                        <motion.p
                            className='billing-posting-desc'
                            {...animationParams}
                            variants={animationVariants.titleVariantMobile}
                        >
                            Simplify data entry, automate reminders, and manage patient records efficiently with our
                            user-friendly EMR system. Ensure data integrity and streamline your practice’s
                            administrative
                            tasks

                        </motion.p>
                    </div>

                    <div className='billing-mini-card-wrapper'>
                        {billingPostingCard.map(
                            ({
                                 img,
                                 title,
                                 description,
                                 classNameWrapper,
                                 classNameImg,
                             }, index) => (
                                <BillingPostingCard
                                    key={index}
                                    classNameWrapper={classNameWrapper}
                                    classNameImg={classNameImg}
                                    img={img}
                                    title={title}
                                    description={description}
                                />
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

