import React from 'react';
import { PermissionList, RolePermission } from 'utils';
import { scheduleModalsStyle } from '../styles';
import { getDynamicTitle } from '../constants';
import { modalsStyle } from '../../../../../components/modal/styles';
import { CustomizedSwitch, ToolInfo } from 'components';

export const ModalHeader = ({ modalDate, type, createMultiple, setMultipleAppts, }) => {
    const title = getDynamicTitle('TITLE', modalDate, type);
    const classes = scheduleModalsStyle();
    const global = modalsStyle();

    return (
        <>
            <div style={{ marginBottom: 24 }}>
                <div className="space-between">
                    <p className={global.availableScheduleTitle}>{title}</p>

                    {!modalDate && RolePermission([PermissionList.APPT_RECURE?.code]) &&
                        <div className={classes.signatureStyle} style={{ justifyContent: 'flex-start', marginBottom: 0, gap: 12 }}>
                            <p style={{ width: 'auto' }}>Create Multiple</p>
                            <ToolInfo
                                background={'transparent'}
                                text={'This action will create multiple appoiments that will become part of a series.'}
                            />
                            <CustomizedSwitch
                                checked={createMultiple}
                                handleClick={setMultipleAppts}
                            />
                        </div>
                    }

                    {modalDate?.template &&
                        <div className={classes.signatureStyle}
                             style={{
                                 justifyContent: 'flex-start',
                                 marginBottom: 0,
                             }}>
                            <p style={{ width: '115px' }}>Edit Template </p>
                            <CustomizedSwitch
                                checked={createMultiple}
                                handleClick={setMultipleAppts}
                            />
                        </div>
                    }
                </div>

                {modalDate?.template &&
                    <div className="space-between" style={{marginTop: 24}}>
                        <div className={classes.multipleServiceNote}>
                            <ToolInfo noTool={true} />
                            <p>
                                Editing this appointment will <b>disconnect</b> it from the <b>template series.</b>
                                If you want to make changes to the entire series, please use the <b>'Edit
                                Template'</b> action.
                            </p>
                        </div>
                    </div>
                }
            </div>
        </>
    );
};