import React from 'react';
import { LandingSvg } from 'assets';

export const GoogleAppButton = ({ classNameWrapper, googlePlay = true }) => {
    return (
        googlePlay ?
            <button className={`google-app-wrapper ${classNameWrapper ? classNameWrapper : ''}`}>
                <LandingSvg.GooglePlaySvg />
                <div className='google-app-svgs'>
                    <LandingSvg.GetItOnSvg />
                    <LandingSvg.GooglePlay />
                </div>
            </button>

            : <button className={`google-app-wrapper ${classNameWrapper ? classNameWrapper : ''}`}>
                <LandingSvg.AppleSvg />
                <div className='google-app-svgs'>
                    <LandingSvg.DownloadOnThe />
                    <LandingSvg.AppStoreSvg />
                </div>
            </button>
    );
};

