import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {useHistory} from "react-router-dom";
import {
   AddModalButton,
   RadioButton,
   SelectInput,
   ValidationInput,
   CreateChancel,
} from "components";
import {
   enumValues,
   ErrorText,
   FindLoad,
   FindSuccess, FindSuccessItem,
   isNotEmpty,
   manageType,
} from 'utils';
import { payrollActions } from "store/payroll";
import { httpRequestsOnSuccessActions } from "store";
import { applyOvertimeData, checkboxStyle, payCodeBtn, ptoData } from "./constants";
import { PayrollSetupStyles } from "../styles";

export const PayCodeType = ({
   handleClose,
   editedData,
   maxWidth,
   marginRight,
   marginTop,
}) => {
   const classes = PayrollSetupStyles();
   const dispatch = useDispatch();
   const ACTION_TYPE = !!editedData ? "EDIT_PAY_CODE_BY_ID_GLOBAL" : "CREATE_PAY_CODE_GLOBAL";
   const loader =  FindLoad(ACTION_TYPE) ;
   const success = FindSuccessItem(ACTION_TYPE);
   const history = useHistory();
   const info = history?.location?.state

   useEffect(() => {
      if (success) {
         if (!!editedData) {
            handleClose();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
         } else {
            setInputs({});
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
         }
         dispatch(payrollActions.getPayCodeGlobal({ ...info }));
      }
   }, [success]);

   const [inputs, setInputs] = useState(!!editedData ? { ...editedData, type: manageType(editedData.type) } : {});
   const [error, setError] = useState("");
   const [applyOvertime, setApplyOvertime] = useState(
      !!editedData && editedData.overtime === true ? "Yes" : "No"
   );
   const [AccruePTO, setAccruePTO] = useState(
      !!editedData && editedData.pto === true ? "Yes" : "No"
   );

   function handleChange(e) {
      setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value, }));
      error === e.target.name && setError("");
   }

   function handleSubmit() {
      const dataIsValid = isNotEmpty(inputs.name) && isNotEmpty(inputs.type) && isNotEmpty(inputs.code);
      if (dataIsValid) {
         const data = {
            name: inputs.name,
            code: inputs.code,
            type: manageType(inputs.type),
            overtime: applyOvertime === "Yes",
            pto: AccruePTO === "Yes",
         };
         if (editedData) {
            dispatch(payrollActions.editPayCodeByIdGlobal(data, editedData?.id));
         } else {
            dispatch(payrollActions.createPayCodeGlobal(data));
            setApplyOvertime("No");
            setAccruePTO("No");
         }
      } else {
         const dataErrorText =
             !isNotEmpty(inputs.name) ? "name" :
                 !isNotEmpty(inputs.code) ? "code" :
                     !isNotEmpty(inputs.type) ? "type"
            : "";
         setError(dataErrorText);
      }
   }

   return (
      <div
         className={!editedData ? classes.payCodeType : {}}
         style={{
            maxWidth: maxWidth,
            marginRight: marginRight ? marginRight : 0,
            marginTop: marginTop ? marginTop : 0,
         }}
      >
         {!editedData && (
            <>
               <h1 className={classes.modalTitle}>Add New Paycode Type</h1>
            </>
         )}
         <ValidationInput
            onChange={handleChange}
            value={inputs.name}
            variant={"outlined"}
            name={"name"}
            type={"text"}
            placeholder={"Name*"}
            label={"Name*"}
            typeError={error === "name" ? `Name ${ErrorText.isRequired}` : ""}
            Length={50}
         />
         <ValidationInput
            onChange={handleChange}
            value={inputs.code}
            variant={"outlined"}
            label={"Code*"}
            name={"code"}
            type={"text"}
            placeholder={"Code*"}
            typeError={error === "code" ? `Code ${ErrorText.isRequired}` : ""}
            Length={20}
         />
         <SelectInput
            label={"Select Type*"}
            placeholder="Type"
            name={"type"}
            handleSelect={handleChange}
            value={inputs.type}
            language={enumValues.PAYMENT_TYPES}
            typeError={error === "type" ? `Type ${ErrorText.isRequiredSelect}` : ""}
         />
         <div className={classes.flexBox}>
            <div className={classes.checkboxContainer}>
               <p>Apply Overtime?</p>
               <RadioButton
                  styles={checkboxStyle}
                  value={applyOvertime}
                  onChange={(e) => setApplyOvertime(e.target.value)}
                  radioData={applyOvertimeData}
               />
            </div>
            <div className={classes.checkboxContainer}>
               <p>Accrue PTO?</p>
               <RadioButton
                  styles={checkboxStyle}
                  value={AccruePTO}
                  onChange={(e) => setAccruePTO(e.target.value)}
                  radioData={ptoData}
               />
            </div>
         </div>
         {!!editedData ? (
            <CreateChancel
               loader={!!loader.length}
               create="Save"
               chancel="Cancel"
               onClose={handleClose}
               onCreate={handleSubmit}
               buttonWidth={"192px"}
               createButnMargin={"16px"}
            />
         ) : (
            <AddModalButton
               loader={!!loader.length}
               handleClick={handleSubmit}
               text={"Add Paycode Type"}
               styles={payCodeBtn}
            />
         )}
      </div>
   );
};
