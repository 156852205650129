import React from 'react';
import { Main } from 'fragments';
import { HomeFragment } from "./fragments";

export const Home = () => {

    return (
      <Main>
         <HomeFragment />
      </Main>
   );
};
