
/** Reports */

export const GET_GENERAL_REPORTS = 'GET_GENERAL_REPORTS';
export const GET_GENERAL_REPORTS_SUCCESS = 'GET_GENERAL_REPORTS_SUCCESS';
export const DELETE_GENERAL_REPORT = 'DELETE_GENERAL_REPORT';

export const GET_SIGNATURE_REPORTS = 'GET_SIGNATURE_REPORTS';
export const GET_SIGNATURE_REPORTS_SUCCESS = 'GET_SIGNATURE_REPORTS_SUCCESS';

export const GET_UTILIZATION_REPORT = 'GET_UTILIZATION_REPORT';
export const GET_UTILIZATION_REPORT_SUCCESS = 'GET_UTILIZATION_REPORT_SUCCESS';

export const DOWNLOAD_PDF = 'DOWNLOAD_PDF';