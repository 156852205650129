import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ServiceItems } from './serviceItems';
import { OtherItems } from './otherItems';
import { APPT_ENUMS } from 'utils';
import { SignatureDetails } from './signatureDetails';
import { appointmentActions } from 'store';
import { ApptAccounting } from './apptAccounting';
import { billStatuses, scheduleStatuses, SERVICE } from '../../../constants';

export const Details = ({ item }) => {
    const dispatch = useDispatch();
    const { apptAccounting } = useSelector((state) => ({
        apptAccounting: state.appointment.apptAccounting,
    }));
    const checkAccounting = item?.billStatus === billStatuses?.BILLED || item?.status === scheduleStatuses?.COMPLETED;

    useEffect(() => {
        if ( checkAccounting && item?.type === SERVICE) {
            dispatch(appointmentActions.getAppointmentInfo(item.id));
        }
    }, []);

    return (
        <div>
            <div className="detail-information">
                {item?.type === APPT_ENUMS.SERVICE ?
                    <div>
                        <ServiceItems item={item} />

                        {(item?.signature?._id || item?.providerSignature?._id) &&
                            <SignatureDetails item={item} />
                        }

                        {checkAccounting && apptAccounting && Object.keys(apptAccounting).length &&
                            <ApptAccounting item={apptAccounting}/>
                        }
                    </div>
                    :
                    <OtherItems item={item} />
                }
            </div>
        </div>
    );
};