import React from 'react';
import { Images } from 'utils';

export const GenerateReportBtn = ({ handleClick, text }) => {
    return (
        <button className="generate-report-btn" onClick={handleClick}>
            <img src={Images.generateReport} alt="remove" />
            {text && <p>{text}</p>}
        </button>
    );
};