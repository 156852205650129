import React from "react";
import { buttonsStyle } from "./styles";
import { Images } from "utils";

export const AddCircle = ({ handleCLic, text, textStyle }) => {
   const classes = buttonsStyle();
   return (
      <div className={classes.addCircleStyle} onClick={handleCLic}>
         <img src={Images.addYellowIcon} alt={"addYellowIcon"} />
         {text && <p className={classes.addCircleTextStyle} style={{...textStyle}}>{text}</p>}
      </div>
   );
};
