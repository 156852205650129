import {
   createClient,
   createClientContact,
   createClientEnrollment,
   createClientsAuthorizations,
   createClientsAuthorizationsServ,
   deleteClient,
   deleteClientContact,
   deleteClientEnrollment,
   deleteClientsAuthorization,
   deleteClientsAuthorizationServ,
   editClient,
   editClientContact,
   editClientEnrollment,
   terminateClientEnrollment,
   editClientsAuthorizations,
   editClientsAuthorizationsServ,
   getClients,
   getClientsAuthorizations,
   getClientsAuthorizationsServ,
   getClientsAuthorizationsServModifiersCheck,
   getClientsById,
   getClientsContacts,
   getClientsEnrollment,
   // authoriztion file
   getClientsAuthorizationFile,
   createClientsAuthorizationFile,
   editClientAuthorizationFile,
   deleteClientAuthorizationFile,
   //end
   addFilesToClientAuth,
   removeFilesFromClientAuth,
   editFileNameOfClientAuth,
   changeEnrollmentType,
   uploadClientDocument,
   editClientDocument,
   getClientDocument,
   deleteClientDocument,
   addAddress,
   editAddress,
   deleteAddress,
   assignStaff,
   getAssignedStaffs,
   removeClientStaff,
   editClientStatus, editAssignedStaff, getBasicClients,
} from './client.action';

export { clientReducer } from "./client.reducer";
export { watchClient } from "./client.saga";

export const clientActions = {
   getClients,
   getBasicClients,
   createClient,
   deleteClient,
   getClientsById,
   editClient,
   getClientsContacts,
   createClientContact,
   deleteClientContact,
   editClientStatus,
   /** Client Enrollment */
   getClientsEnrollment,
   editClientEnrollment,
   createClientEnrollment,
   terminateClientEnrollment,
   deleteClientEnrollment,
   changeEnrollmentType,
   /** End */

   editClientContact,
   getClientsAuthorizations,

   createClientsAuthorizations,
   deleteClientsAuthorization,
   editClientsAuthorizations,
   getClientsAuthorizationsServ,
   createClientsAuthorizationsServ,
   editClientsAuthorizationsServ,
   deleteClientsAuthorizationServ,
   getClientsAuthorizationsServModifiersCheck,

   // authorization file file

   getClientsAuthorizationFile,
   createClientsAuthorizationFile,
   editClientAuthorizationFile,
   deleteClientAuthorizationFile,

   // end
   addFilesToClientAuth,
   removeFilesFromClientAuth,
   editFileNameOfClientAuth,

   /** Client Documents */
   uploadClientDocument,
   editClientDocument,
   getClientDocument,
   deleteClientDocument,
   /** End */

   /** Client Address */
   addAddress,
   editAddress,
   deleteAddress,
   /** End */

   /** Client Assignment */
   assignStaff,
   editAssignedStaff,
   getAssignedStaffs,
   removeClientStaff,
   /** End */
};
