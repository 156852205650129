import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fundingSourceActions } from 'store';
import { AddButtonLight, Loader, NoItemsYet, NoItemText } from 'components';
import { fundingSourceSingleStyles } from './styles';
import { FundingSourceModifiersAdd } from './modals';
import { ModifierTable } from './common';
import { FindLoad, PermissionList, RolePermission, useModal } from 'utils';

export const FundingSourceSinglePTModifiers = ({ title, globalCredentials = [], currentService }) => {
    const serviceChargeRates = useSelector((state) => state.fundingSource.serviceChargeRates);
    const classes = fundingSourceSingleStyles();
    const dispatch = useDispatch();
    const loader = FindLoad('GET_FUNDING_MODIFIERS');
    const { open } = useModal();

    useEffect(() => {
        dispatch(fundingSourceActions.getServiceModifier(currentService?.id));
    }, []);

    if (loader?.length) {
        return <Loader />;
    }

    return (
        <div className={'open-sans'}>
            <div className={classes.modifierBoxStyle}>
                <div className={classes.modifierTitleBoxStyle}>
                    <p className={classes.modifierTitleStyle}>{`${title} Charge Rates`}</p>
                    {RolePermission([PermissionList.FS_SERVICE_CREATE?.code]) &&
                        <AddButtonLight
                            addButnLightInnerText={'Add charge rate'}
                            onAddButnLightClick={() => open(<FundingSourceModifiersAdd
                                currentService={currentService}
                                credentials={globalCredentials}
                            />)}
                        />
                    }
                </div>
                {serviceChargeRates && serviceChargeRates.length ? (
                    <ModifierTable
                        modifiers={serviceChargeRates}
                        currentService={currentService}
                        globalCredentials={globalCredentials}
                    />
                ) : (
                    <div style={{ position: 'relative' }}>
                        <NoItemsYet text={'No charge rates yet'} />
                    </div>
                )}
            </div>
        </div>
    );
};
