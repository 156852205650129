import {
    ADD_USER_KPI,
    DELETE_USER_KPI,
    GET_ALL_KPIS, GET_CHART_DATA,
    GET_USER_FILTERED_KPIS,
    GET_USER_KPIS,
    REORDER_KPIS,
} from './analytics.types';

/** Analytics */

export const getUserFilteredKpis = (params) => {
    return {
        type: GET_USER_FILTERED_KPIS,
        payload: { params },
    };
};

export const getAllKpis = (params) => {
    return {
        type: GET_ALL_KPIS,
        payload: { params },
    };
};

export const getUserKpis = (params) => {
    return {
        type: GET_USER_KPIS,
        payload: { params },
    };
};

export const reorderKpis = (body, params) => {
    return {
        type: REORDER_KPIS,
        payload: { body, params },
    };
};

export const deleteUserKpi = (id, params) => {
    return {
        type: DELETE_USER_KPI,
        payload: { id, params },
    };
};

export const addUserKpi = (id, params) => {
    return {
        type: ADD_USER_KPI,
        payload: { id, params },
    };
};
export const getChartData = (url, params) => {
    return {
        type: GET_CHART_DATA,
        payload: { url, params },
    };
};

/** End */