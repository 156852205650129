import axios from 'axios';

export const availabilityScheduleService = {

    getAvailabilityScheduleService: (payload) => axios.get(`/availability/${payload.id}`, {
        auth: true,
        params: { userType: payload.onModel },
    }),

    createAvailabilityScheduleService: (action) => axios.patch(`/availability/${action.payload.id}`, action.payload.data, {
        auth: true,
        params: { userType: action.payload.onModel },
    }),

    getStaffScheduleService: (id) => axios.get(`/availability/${id}`, { auth: true, params: { userType: 'Staff' } }),

    getClientScheduleService: (id) => axios.get(`/availability/${id}`, { auth: true, params: { userType: 'Client' } }),

};