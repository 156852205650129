import moment from 'moment/moment';
import { formatAMPMNeW, Images, PermissionList, RolePermission } from '../../../../../utils';
import React from 'react';
import { billStatuses, SERVICE } from '../../../constants';
import { SimpleTooltip, TextRow } from '../../../../../components';
import toolInfoIcon from '../../../../../assets/images/icons/toolInfoIcon.svg';


export const apptDetailStatus = (status, item, fromAppt, editReason) => {

    switch (status) {
        // case 'NOTRENDERED':
        //     return <div className="appt-status-card appt-not-rendered">
        //         <div />
        //         Not Rendered </div>;
        // case 'RENDERED':
        //     return <div className="appt-status-card appt-rendered">
        //         <div />
        //         Rendered </div>;


        case 'CANCELLED':
            return <div className="flex-align-center" style={{ gap: '8px' }}>
                <div className="appt-status-card appt-cancelled">
                    <div />
                    Cancelled
                </div>
                {item?.cancellation?._id && !fromAppt &&
                    <div className="canceled-reason-wrapper">
                        <div className="canceled-reason">
                            {item?.cancellation?.reason ?
                                <SimpleTooltip title={<p>{item?.cancellation?.reason}</p>} placement="top" arrow>
                                    <p>Reason: {item?.cancellation?.reason?.length > 40 ? `${item?.cancellation?.reason.slice(0, 40)}...` : item?.cancellation?.reason}</p>
                                </SimpleTooltip>
                                :
                                <p>
                                    {`Reason: ${reasonEnumsConvert[item?.cancellation?.type]}`}
                                </p>
                            }
                            <button onClick={editReason} className="reset-btn-no-paddings">
                                <img src={Images.noteEdit} alt="icon"
                                     style={{ filter: 'invert(24%) sepia(96%) saturate(3084%) hue-rotate(209deg) brightness(95%) contrast(91%)' }} />
                            </button>
                        </div>
                    </div>

                }
            </div>;
        case 'PENDING':
            return <div
                className={`appt-status-card ${item?.type === SERVICE ? 'service-appt-pending' : 'appt-pending'}`}>
                <div />
                Pending
            </div>;
        // :
        // <div className="appt-status-card appt-pending">
        //     <div />
        //     Pending </div>;
        case 'COMPLETED':
            return item?.type === SERVICE ?
                <div className="appt-status-card service-appt-completed">
                    <div />
                    {`Completed ${item?.billStatus === billStatuses.NOT_BILLED ? `(Not Billed)` : item?.billStatus === billStatuses.BILLED ? `(Billed)` : ''}`}
                </div>
                :
                <div className="appt-status-card appt-completed">
                    <div />
                    Completed
                </div>;
        default:
            return { color: '', text: '' };
    }

    // switch (status) {
    //     case 'NOTRENDERED':
    //         return { color: '#6FD231', background: '#F1FAEA', text: 'Not Rendered' };
    //     case 'RENDERED':
    //         return { color: '#2A8E6D', background: '#EAF4F0', text: 'Rendered' };
    //     case 'CANCELLED':
    //         return { color: '#A3B2BD',  background: '#EAEAEA', text: 'Cancelled' };
    //     case 'PENDING':
    //         return { color: '#347AF080', background: '#EBF2FD',  text: 'Pending' };
    //     case 'COMPLETED':
    //         return { color: '#347AF0', background: '#EBF2FD', text: 'Completed' };
    //     default:
    //         return { color: '', text: '' };
    // }
};

export const serviceApptDetailList = (item) => [
    { title: 'Appt ID:', value: item?.displayId },
    {
        title: 'Date/Time:', value: <div className="flex-align-center" style={{ gap: '8px' }}>
            <p>{moment.utc(item?.startDate).format('MM/DD/YYYY')}</p>
            <p className="schedule-time">
                {`${formatAMPMNeW(item?.startTime)} - ${formatAMPMNeW(item?.endTime)}`}
            </p>
        </div>,
    },
    {
        title: 'Staff:', value: item?.staff ? `${item?.staff?.firstName} ${item?.staff?.lastName}` : null,
    },
    {
        title: 'Client:',
        value: item?.client ? `${item?.client?.code ? `(${item?.client.code})` : ''} ${item?.client?.firstName} ${item?.client?.lastName}` : null,
    },
    {
        title: 'Service:', value: item?.authorizedService?.service?.cptCode,
    },
];
export const apptDetailList = (item) => [
    { title: 'Appt ID:', value: item?.displayId },
    {
        title: 'Date/Time:', value: <div className="flex-align-center" style={{ gap: '8px' }}>
            <p>{moment.utc(item?.startDate).format('MM/DD/YYYY')}</p>
            <p className="schedule-time">
                {`${formatAMPMNeW(item?.startTime)} - ${formatAMPMNeW(item?.endTime)}`}
            </p>
        </div>,
    },
];

export const apptDetailTabsLabels = [
    { label: 'Details' },
    { label: 'Links' },
    { label: 'Notes' },
    RolePermission([PermissionList.APPT_FILE_READ?.code, PermissionList.APPT_FILE_MANAGE?.code]) &&
    { label: 'Files' },
    RolePermission([PermissionList.APPT_HISTORY_READ?.code]) &&
    { label: 'History' },
];

export const linksNotYet = {
    title: 'No Links Yet',
    // subTitle: 'Upload files to store relevant documents here.',
    image: Images.notFile,
    height: '200px',
};

export const reasonEnums = {
    STAFF_CANCELLATION: 'STAFF_CANCELLATION',
    CLIENT_CANCELLATION: 'CLIENT_CANCELLATION',
    CLIENT_LATE: 'CLIENT_LATE',
    OTHER: 'OTHER',
};

export const reasonEnumsConvert = {
    STAFF_CANCELLATION: 'Staff Cancellation',
    CLIENT_CANCELLATION: 'Client Cancellation',
    CLIENT_LATE: 'Client Late',
    OTHER: 'Other',
};

export const reasonList = [
    { title: 'Staff Cancellation', value: reasonEnums.STAFF_CANCELLATION },
    { title: 'Client Cancellation', value: reasonEnums.CLIENT_CANCELLATION },
    { title: 'Client Late', value: reasonEnums.CLIENT_LATE },
    { title: 'Other', value: reasonEnums.OTHER },
];