import React from 'react';
import { MinLoader } from '../loader';
import { Colors, FindLoad, Images } from 'utils';
import { HtmlTooltip } from '../tabs/fullTableCore/htmlTool';

export const SelectActions = ({
                                  selectedList,
                                  selectAll,
                                  submitClaims,
                                  removeSelected,
                                  actionType,
                                  disableSubmit,
                                  toolTitle,
                                  submitBtnIcon,
                                  submitText,
                              }) => {
    const loader = FindLoad(actionType);
    return (
        <div>
            <div className="select-action-box">
                <div className="select-count-box">
                    <div>
                        {selectedList?.length}
                    </div>
                    <p>selected</p>
                </div>
                <div className="flex-align-center" style={{ gap: 8 }}>
                    <button
                        className="select-all-btn"
                        onClick={(e) => selectAll(e, '', 'selectAll')}>
                        <img src={Images.selectAll} alt="icon" />
                        <p> Select All</p>
                    </button>

                    {submitBtnIcon ?
                        <button className="select-all-btn" onClick={submitClaims}>
                            {loader?.length ?
                                <MinLoader
                                    width={'18px'} height={'18px'}
                                    margin={'0'} color={'white'} position={'relative'} />
                                :
                                <img src={submitBtnIcon} alt="icon" />
                            }
                            {submitText}
                        </button>
                        :
                        disableSubmit ?
                            <HtmlTooltip title={<p>{toolTitle}</p>} placement="top-start">
                                <button style={{ opacity: 0.5 }} className="submit-btn-box">
                                    Submit
                                </button>
                            </HtmlTooltip>
                            :
                            <button className="submit-btn-box" onClick={submitClaims}>
                                {loader?.length ?
                                    <MinLoader margin={'0'} color={Colors.BackgroundBlue} position={'relative'} />
                                    :
                                    submitText ? submitText : 'Submit'
                                }
                            </button>
                    }
                </div>
                <button className="close-selected-btn" onClick={removeSelected}>
                    <img src={Images.closeExWhite} alt="close" />
                </button>
            </div>
        </div>
    );
};