import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { claimPaymentsStyle } from './styles';
import { Loader, NoItemsYet, PaginationFillTable } from 'components';
import { ClaimPaymentBody, ClaimPaymentHead } from './core/claimPaymentTable';
import { FindLoad, SendPageSave } from 'utils';
import { claimPaymentNotYet } from './constants';

const ACTION_TYPE = 'GET_CLAIM_PAYMENTS';

export const ClaimPaymentsFragment = ({ claimsList, renderParams }) => {
    const classes = claimPaymentsStyle();
    const loader = FindLoad(ACTION_TYPE);
    const [activeRow, setActiveRow] = useState();
    const [page, setPage] = useState(1);
    const history = useHistory();
    const info = history?.location?.state;

    const handleEdit = (id) => {
        setActiveRow(id);
    };

    const changePage = (number) => {
        setPage(number);
        SendPageSave(number, info, history);
    };

    if (loader?.length) {
        return <Loader />;
    }

    return (
        <div className={classes.postTableWrapper}>
            <>
                <ClaimPaymentHead />
                {claimsList?.claims?.length ?
                    <>
                        <div className={classes.claimPaymentBodyBox}>
                            {claimsList?.claims?.map((i, j) => (
                                <div key={j}>
                                    <ClaimPaymentBody
                                        item={i}
                                        activeRow={activeRow}
                                        setActiveRow={setActiveRow}
                                        handleEdit={handleEdit}
                                        renderParams={renderParams}
                                    />
                                </div>
                            ))}
                        </div>
                        {!!claimsList?.claims?.length > 0 &&
                            <div style={{ height: '76px' }}>
                                <PaginationFillTable
                                    listLength={claimsList?.claims?.length}
                                    page={page}
                                    handleReturn={(number) => changePage(number)}
                                    count={claimsList?.count}
                                />
                            </div>
                        }
                    </>
                    :
                    <div style={{ position: 'relative' }}>
                        <NoItemsYet
                            text={claimPaymentNotYet.title}
                            subTitle={claimPaymentNotYet.subTitle}
                            image={claimPaymentNotYet.image}
                        />
                    </div>
                }
            </>
        </div>
    );
};
