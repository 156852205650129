import { makeStyles } from '@material-ui/core/styles';
import { Colors, Shadow } from 'utils';

export const serviceSingleStyles = makeStyles(() => ({
    bcbaHeaderTitle: {
        color: '#4B5C68',
        fontSize: '18px',
        fontWeight: '700',
    },
    headerSize: {
        marginLeft: '24px',
        borderRadius: '8px',
        boxShadow: '0px 0px 6px #8A8A8A3D',
        padding: '24px',
        width: '100%',
        flexDirection: 'column',
    },
    // General
    staffGeneralWrapper: {
        display: 'flex',
        // '& > div:not(:last-of-type)': {
        //     marginRight: '24px',
        // },
    },
    // Access
    staffAccessWrapper: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    roleInformation: {
        width: '50%',
        height: '413px',
        flex: '0 0 50%',
        marginLeft: 24,
        border: `1px solid ${Colors.TextCadetBlue}`,
        borderRadius: 8,
        padding: 26,
    },
    cardIcon: {
        width: '36px',
        height: '36px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        backgroundColor: Colors.ThemeMangoOrange,
        '&::after': {
            content: `''`,
            position: 'absolute',
            left: '1px',
            top: '1px',
            width: '34px',
            height: '34px',
            backgroundColor: 'transparent',
            borderRadius: '50%',
            border: '1px solid white',
        },
    },

    roleHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    roleTitle: {
        fontSize: 24,
        color: Colors.TextSecondary,
        fontWeight: 'bold',
        lineHeight: '25px',
        paddingLeft: 16,
    },
    roleSubtitle: {
        fontSize: 16,
        color: Colors.TextSecondary,
        fontWeight: 'bold',
        margin: '25px 0 16px',
        lineHeight: '25px',
    },
    permissionsList: {
        height: '190px',
        overflow: 'auto',
    },
    roleText: {
        fontSize: 14,
        color: Colors.TextMiddleGray,
        lineHeight: '16px',
    },
    rolePermissionContainer: {
        display: 'flex',
        alignItems: 'center',
        '&:not(:last-child)': {
            marginBottom: 8,
        },
    },
    rolePermissionName: {
        fontSize: 14,
        color: Colors.TextMiddleGray,
        lineHeight: '21px',
        paddingLeft: 8,
    },

    selectRole: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        '& p': {
            color: '#4B5C68',
            fontSize: '18px',
            fontWeight: '700',
            margin: '24px 0 8px',
        },
        '& span': {
            color: '#4B5C68',
            fontSize: '14px',
            fontWeight: '400',
            textAlign: 'center',
        },
    },
    // header

    avatar: {
        width: 50,
        height: 50,
        borderRadius: '50%',
        position: 'relative',
        border: `1px solid ${Colors.BackgroundBlue}`,
        objectFit: 'cover',
        padding: 3,
    },
    nameContent: {
        marginLeft: 19,
    },
    name: {
        fontSize: 18,
        color: Colors.TextSecondary,
        fontWeight: 'bold',
        lineHeight: '25px',
        marginBottom: 8,
    },
    tagContent: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            fontSize: 12,
            color: Colors.TextSecondary,
            fontWeight: 600,
            backgroundColor: Colors.BackgroundWater,
            padding: '0 16px',
            lineHeight: '25px',
            borderRadius: 13,
        },
        '& p:not(:last-child)': {
            marginRight: 8,
        },
    },

    // timesheet

    timesheetWrapper: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    staffTimesheetTableStyle: {
        marginTop: '32px',
        width: '100%',
    },
    staffTimesheetInfoTableStyle: {
        marginTop: '24px',
    },
    bcbaWrapper: {
        marginTop: 30,
        maxWidth: '560px',
        width: '100%',
        padding: 16,
        boxShadow: Shadow.noteModalShadow,
        borderRadius: 8,
        marginLeft: 16,
        '& > p': {
            fontSize: 14,
            color: Colors.TextSecondary,
        },
        '@media(max-width: 1440px)': {
            maxWidth: '364px',
        },
    },
    bcbaHeader: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '8px',
        gap: '16px'
    },
    dateEdite: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            fontSize: 14,
            color: Colors.TextSecondary,
        },
        '& img': {
            cursor: 'pointer',
            paddingLeft: 16,
        },
    },
    amountContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        marginTop: 10,
        '& p': {
            fontSize: 14,
            color: Colors.TextSecondary,
            '& span': {
                minWidth: 65,
                display: 'inline-block',
                textAlign: 'right',
                paddingLeft: 10,
            },
        },
    },
    hours: {
        fontSize: 14,
        color: Colors.TextSecondary,
        fontWeight: '600',
    },
    amount: {
        '& span': {
            fontSize: 18,
            color: Colors.TextSecondary,
            fontWeight: 'bold',
        },
        fontSize: 18,
        color: Colors.TextSecondary,
        fontWeight: 'bold',
    },

    clinicalWrapper: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            color: Colors.ThemeBlue,
            fontWeight: 'bold',
        },
        '& div': {
            margin: '0 24px 0 8px',
        },
    },
    //  History

    searchContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& button': {
            marginLeft: 15,
        },
    },
    dateInput: {
        width: '100%',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.BackgroundBlue,
        },
        '& .MuiOutlinedInput-root': {
            height: 38,
            width: 200,
            color: Colors.TextPrimary,
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-3px',
            color: Colors.TextPrimary,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.BackgroundBlue,
        },
        '& .MuiInputBase-input::placeholder': {
            fontSize: 14,
            color: Colors.TextLightGray,
        },
    },
    headerRight: {
        display: 'flex',
    },
    inputTextField: {
        alignItems: 'flex-end',
        width: '100%',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.BackgroundBlue,
        },
        '& .MuiOutlinedInput-root': {
            height: '48px',
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-3px',
            color: Colors.TextPrimary,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.BackgroundBlue,
        },
    },

    switcher: {
        maxWidth: '209px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: Colors.BackgroundWhite,
        boxShadow: Shadow.noteModalShadow,
        borderRadius: '4px',
        padding:'2px',
        '& > p': {
            padding: '8px 24px',
            cursor: 'pointer',
            borderRadius: '4px',
            // fontSize: '',
            fontWeight: 600,
        },
    },
    switcherActive: {
        width: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        background: Colors.ThemeBlue,
        transition: '.5s',
        color: Colors.TextWhite,
    },
    switcherProcessed: {
        width: '50%',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: '.5s',
        color: Colors.TextPrimary,
    },

    staffCredentialBoxStyle: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    loaderContainerStyle: {
        minHeight: '400px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    timeAndHoursWrapper: {
        display: 'flex',
        justifyContent:'space-between',
        alignItems: 'flex-start',
        width: '100%',
    },
    titleAndPrice: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
    },

    whiteText:{
        whiteSpace: 'noWrap',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '19px',
        color: '#4B5C68'
    },
    boldText:{
        whiteSpace: 'noWrap',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '19px',
        color: '#4B5C68'
    },
    timeAndHoursBox:{
        display:'flex',
        flexDirection:'column',
        gap: '8px',
    },
    editRoleWrapper: {
        width: '480px',
    },
    inputsFormWrapper: {
        margin: '40px 0',
    },
    reinvite: {
        marginRight: '16px',
        border: '1px solid #347AF0',
        borderRadius: '4px',
        background: '#347AF0',
        display: 'flex',
        gap: '8px',
        justifyContent: 'center',
        alignItems: 'center',
        height: '36px',
        width: '131px',
        color: '#FFF',
        fontSize: '14px',
        fontWeight: '600',
    },
}));
