import {
    PermissionList,
    RolePermission,
} from '../../utils';
import {
    ApptDetailRow,
    DateRow,
    DisplayIdAnFlagRow,
    DisplayIdRow,
    LinkRow,
    PriceRow,
    TextRow,
    TypeRow,
} from '../../components';
import React from 'react';
import { FixDecimalToTree } from '../../components/table/tableRows';


export const timeSheetDetailTabsLabels = [
    { label: 'Details' },
    RolePermission([PermissionList.TIMESHEET_NOTE_READ?.code, PermissionList.TIMESHEET_NOTE_MANAGE?.code]) &&
    { label: 'Notes' },
    RolePermission([PermissionList.TIMESHEET_HISTORY_READ?.code]) &&
    { label: 'History' },
    RolePermission([PermissionList.TIMESHEET_FILE_READ?.code, PermissionList.TIMESHEET_FILE_MANAGE?.code]) &&
    { label: 'Files' },

];


export function getTimeSheetDetails(item, afterSuccess) {
    if (item?.type === "FIXED"){

        return [
            {
                detailText: 'Timesheet ID:',
                detail: <DisplayIdRow id={item?.displayId} />,
            },
            {
                detailText: 'Timesheet Date:',
                detail: <DateRow date={item?.timesheetDate} />,
            },
            {
                detailText: 'Rate:',
                detail: <PriceRow info={item?.amount} />,
            },

            {
                detailText: 'Type:',
                detail: <TypeRow text={item?.type ? item?.type : 'N/A'} />,
            },
            {
                detailText: 'Created Date:',
                detail: <DateRow date={item?.createdDate} />,
            },
            {
                detailText: 'Qty:',
                detail: <FixDecimalToTree number={item?.qty} />,
            },

            {
                detailText: 'Staff Name:',
                detail: <LinkRow
                    name={item?.staffName ? item.staffName : 'N/A'}
                    href={RolePermission([PermissionList.STAFF_READ.code]) ? `/staff/${item?.staffId}` : ''}
                    checkPermission={item?.staffId ? RolePermission([PermissionList.STAFF_READ.code]) : null}
                    textWidth={8}
                />,
            },
            {
                detailText: 'CPT Code:',
                detail: item?.cptCode ? <TextRow name={item?.cptCode} /> : 'N/A',
            },
            {
                detailText: 'Total AMT:',
                detail: <PriceRow info={item?.totalAmount} />,
            },
        ]

    }else {
        return [
            {
                detailText: 'Timesheet ID:',
                detail: <DisplayIdRow id={item?.displayId} />,
            },
            {
                detailText: 'Appointment ID:',
                detail: item?.apptDisplayId ? <ApptDetailRow name={item?.apptDisplayId} id={item?.apptId} showWidth={'100%'} afterSuccess={afterSuccess} /> : null,
            },
            {
                detailText: 'Rate:',
                detail: <PriceRow info={item?.amount} />,
            },
            {
                detailText: 'Timesheet Date:',
                detail: <DateRow date={item?.timesheetDate} />,
            },
            {
                detailText: 'CPT Code:',
                detail: item?.cptCode ? <TextRow name={item?.cptCode} /> : 'N/A',
            },
            {
                detailText: 'Qty:',
                detail: <FixDecimalToTree number={item?.qty} />,
            },
            {
                detailText: 'Created Date:',
                detail: <DateRow date={item?.createdDate} />,
            },
            {
                detailText: 'Paycode:',
                detail: item?.payCode ? <TextRow name={item?.payCode ? item?.payCode : 'N/A'} /> : null,
            },
            {
                detailText: 'Total AMT:',
                detail: <PriceRow info={item?.totalAmount} />,
            },
            {
                detailText: 'Staff Name:',
                detail: <LinkRow
                    name={item?.staffName ? item.staffName : 'N/A'}
                    href={RolePermission([PermissionList.STAFF_READ.code]) ? `/staff/${item?.staffId}` : ''}
                    checkPermission={item?.staffId ? RolePermission([PermissionList.STAFF_READ.code]) : null}
                    textWidth={8}
                />,
            },
            {
                detailText: 'Type:',
                detail: <TypeRow text={item?.type ? item?.type : 'N/A'} />,
            },
        ];
    }
}