export const residencyEnumTypes = [
    { id: 'US', label: 'U.S. Citizen' },
    { id: 'CITIZEN', label: 'Permanent Resident' },
    { id: 'PCITIZEN', label: 'Legal Immigrant' },
    { id: 'WORK', label: 'Undocumented Immigrant' },
];






