import React from 'react';
import { inputsStyle } from 'components/inputs/styles';
import {
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@material-ui/core';
import { makeCapitalize, makeEnum } from 'utils';
import { scheduleModalsStyle } from '../styles';
import { modePatternsStyle } from '../modePatterns/style';

const MODES = [
    { modeValue: 'DAILY' },
    { modeValue: 'WEEKLY' },
    { modeValue: 'MONTHLY' },
];

export const Mode = ({ inputs, handleChange }) => {
    const classes = scheduleModalsStyle();
    const inputsClasses = inputsStyle();
    const modeClasses = modePatternsStyle();

    return (
        <div className='full-width'>
            <p className={classes.recurTitle}>Mode</p>
            <div className={classes.dayWeekMounth}>
                <FormControl component="fieldset" style={{ width: '100%' }}>
                    <RadioGroup
                        className={modeClasses.formGroup}
                        // className={classes.modeClasses}
                        onChange={handleChange}
                        row
                        aria-label="gender"
                        name="mode"
                        style={{ display: 'flex', flexDirection: 'column', margin: 0 }}
                    >
                        {MODES.map((mode, index) => {
                            const isChecked = inputs.mode === makeEnum(mode.modeValue);
                            const radioClasses = {
                                root: inputsClasses.radio,
                                checked: inputsClasses.checked,
                            };
                            return (
                                <FormControlLabel
                                    key={index}
                                    className={classes.radioInputLabel}
                                    value={makeEnum(mode.modeValue)}
                                    label={makeCapitalize(mode.modeValue)}
                                    control={
                                        <Radio
                                            style={{ padding: '4px 8px' }}
                                            checked={isChecked}
                                            classes={radioClasses}
                                        />
                                    }
                                />
                            );
                        })}
                    </RadioGroup>
                </FormControl>
            </div>
        </div>
    );
};
