import {
    addUserKpi,
    deleteUserKpi,
    getAllKpis, getChartData,
    getUserFilteredKpis,
    getUserKpis,
    reorderKpis,
} from './analytics.action';

export { analyticsReducer } from './analytics.reducer';
export { watchAnalytics } from './analytics.saga';

export const analyticActions = {

    /** Analytics */

    getUserFilteredKpis,
    getAllKpis,
    getUserKpis,
    reorderKpis,
    deleteUserKpi,
    addUserKpi,
    getChartData,

    /** End */

};

