import {
    CREATE_AVAILABILITY_SCHEDULE_GLOBAL_SUCCESS,
    GET_AVAILABILITY_SCHEDULE_GLOBAL_SUCCESS,
    GET_CLIENT_SCHEDULE_SUCCESS,
    GET_STAFF_SCHEDULE_SUCCESS,
    REMOVE_CLIENT_SCHEDULE, REMOVE_STAFF_SCHEDULE,
} from './availabilitySchedule.type';

const initialState = {
    availabilitySchedule: [],
    staffSchedule: {},
    clientSchedule: {},
};

export const availabilityScheduleReducer = (state = initialState, action) => {
    switch (action.type) {
        case  GET_AVAILABILITY_SCHEDULE_GLOBAL_SUCCESS :
            return {
                ...state,
                availabilitySchedule: action.payload,
            };

        case  CREATE_AVAILABILITY_SCHEDULE_GLOBAL_SUCCESS:
            return {
                ...state,
                availabilitySchedule: action.payload,
            };

        case GET_STAFF_SCHEDULE_SUCCESS:
            return {
                ...state,
                staffSchedule: action.payload,
            };

        case REMOVE_STAFF_SCHEDULE:
            return {
                ...state,
                staffSchedule: {},
            };

        case GET_CLIENT_SCHEDULE_SUCCESS:
            return {
                ...state,
                clientSchedule: action.payload,
            };

        case REMOVE_CLIENT_SCHEDULE:
            return {
                ...state,
                clientSchedule: {},
            };

        default:
            return state;
    }
};