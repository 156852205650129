import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Colors, filterFromTable, Images, SavePage, SaveParams } from 'utils';
import { MenuTool } from './fullTableCore/menu';
import { MinLoader } from '../loader';
import { bigSizeText, handleSelectSort, smallSizeText } from './fullTableCore/constants';
import { TextRow } from '../table';
import { useDebouncedCallback } from 'use-debounce';

export const SearchAndFilerFull = ({
                                       width,
                                       item,
                                       loader,
                                       setListInfo,
                                       list,
                                       searched,
                                       setSearched,
                                       generateTable

                                   }) => {

    const [backSearched, setBackSearched] = useState({ searchKey: '', searchValue: '' });
    const history = useHistory();
    const info = history?.location?.state;
    const active =
        info?.[item?.name] === item?.name ? true :
            info?.hasOwnProperty(item?.name) ? true :
                info?.sortType === item?.name ? true :
                    ((searched?.name === item?.name) && searched?.value) ||
                    ((backSearched?.searchKey === item?.searchKey) && backSearched?.searchValue);



    useEffect(() => {
        if (info?.searchKey) {
            setBackSearched({
                searchKey: info?.searchKey,
                searchValue: info?.searchValue,
            });
        }else {
            setBackSearched({
                searchKey: '',
                searchValue: '',
            });
        }
    }, [info]);


    const debounced = useDebouncedCallback((name, value, searchKey) => {
            const params = {
                ...info,
            };
            params.limit = 100;
            params.skip = 0;
            params.page = 1;
            if (value) {
                params.searchKey = searchKey;
                params.searchValue = value;
            } else {
                delete params.searchKey;
                delete params.searchValue;
            }
            SaveParams(history, params);
        },
        1000,
        { maxWait: 2000 },
    );

    const handleSearch = (e, nameRow, searchKey, type) => {
        if (searchKey) {
            setBackSearched({
                searchKey: searchKey,
                searchValue: e.target.value,
            });
            debounced(e.target.name, e.target.value, searchKey);
        } else {
            setSearched({
                name: e.target.name,
                value: e.target.value,
            });

            if (e.target.name) {
                if (!e.target.value) {
                    setListInfo(list);
                } else {
                    const info = filterFromTable(e, nameRow, list, type);
                    setListInfo(info);
                }
            }
        }
    };


    const handleClickSort = (name) => {
        if (item?.custom !== false) {
            const anotherInfo = {
                ...info,
            };
            anotherInfo.sortType = name;
            if (info?.orderType === 'DESC') {
                anotherInfo.orderType = 'ASC';
            } else {
                anotherInfo.orderType = 'DESC';
            }
            SavePage(history, info, { ...anotherInfo });
        }
    };


    const handleFilter = (e) => {
        handleSelectSort(e, history, info);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission
    };

    return (
        <div className="search-and-filer-box">
            <div
                className={active ? 'active-box' : 'passive-box'}
                style={width ? { width: width } : {}}
            >
                {!item?.filterList &&
                    <div
                        onClick={() => handleClickSort(item?.name)}
                        className="search-input-title"
                        style={{ cursor: item?.custom !== false ? 'pointer' : 'default', whiteSpace:'nowrap' }}
                    >
                        <TextRow name={item?.title} textWidth={8} />
                        {item?.custom !== false &&
                            <div className={generateTable ? "sort-btn-box-generate" : "sort-btn-box"}>
                                <img
                                    style={(info?.orderType && info?.sortType === item?.name) ? { opacity: 1 } : { opacity: 0.6 }}
                                    src={
                                        item?.iconType === 'date' ?
                                            info?.sortType === item?.name && info?.orderType === 'DESC' ?
                                                Images.latestEarliest : Images.earliestLatestBlue
                                            :

                                            info?.sortType === item?.name && info?.orderType === 'DESC' ?
                                                Images.zToA : Images.aToZ
                                    }
                                    alt={'filter icon'}
                                />
                            </div>
                        }
                    </div>
                }

                {item?.custom !== false && item?.filterList &&
                    <MenuTool
                        item={item}
                        handleFilter={handleFilter}
                        selectedFilter={info?.type ? info?.type : 'All'}
                    />
                }

                {!item?.noSearch &&
                    <div style={{ position: 'relative' }}>
                        <form autoComplete="off" onSubmit={handleSubmit} >
                            <input
                                autoComplete={'new-password'}
                                disabled={item?.disabled || !item?.name}
                                name={item?.name}
                                value={
                                    backSearched?.searchKey === item?.searchKey ? backSearched?.searchValue :
                                        searched?.name === item?.name ? searched?.value : ''}
                                // defaultValue={null}
                                // onChange={handleSearch}
                                onChange={(e) => handleSearch(e, item?.nameRow, item?.searchKey, item?.icon)}

                                type="search"
                            />
                        </form>
                            {!!loader && <MinLoader margin={'6px 0 0 -20px'} color={Colors.BackgroundBlue} />}
                    </div>
                }
            </div>
        </div>
    );
};