import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer } from '@material-ui/core';
import { AddButton, CloseButton, Loader, MinLoader, SelectTypeAutocomplete } from 'components';
import { FindLoad, Images } from 'utils';
import { analyticActions } from 'store';
import { RenderWidgetImage } from './constnats';
import { checkFilter, DefaultDates, WidgetCategories } from '../constants';
import { useHistory } from 'react-router-dom';

const ACTION_TYPE = 'GET_USER_FILTERED_KPIS';
const ADD_ACTION_TYPE = 'ADD_USER_KPI';

export const AddWidget = () => {
    const { listKpis } = useSelector((state) => ({
        listKpis: state.analytics.listKpis,
    }));
    let history = useHistory();
    const info = history?.location?.state;
    const dispatch = useDispatch();
    const [openDrawer, setOpenDrawer] = useState(false);
    const loader = FindLoad(ACTION_TYPE);
    const addLoad = FindLoad(ADD_ACTION_TYPE);
    const [selectedId, setSelectedId] = useState(null);
    const [inputs, setInputs] = useState({});
    const { allDates } = DefaultDates(info);

    useEffect(() => {
        dispatch(analyticActions.getUserFilteredKpis());
    }, [dispatch]);

    useEffect(() => {
        if(openDrawer) {
            setInputs({});
        }
    }, [openDrawer]);
    const closeDrawer = () => {
        setOpenDrawer(false);
    };
    const addToUser = (id) => {
        setSelectedId(id);
        dispatch(analyticActions.addUserKpi(id, allDates));
    };

    const handleChange = (e) => {
        const params = {
            ...inputs,
        };
        if (e.target.value === 'All' || !e.target.value) {
            delete params[e.target.name];
        } else {
            params[e.target.name] = e.target.value;
        }
        setInputs(params);
    };


    return (
        <div>

            <AddButton
                text={'Add Widget'}
                handleClick={() => setOpenDrawer(true)}
            />

            <Drawer
                className={'add-widget-modal'}
                anchor={'right'}
                open={openDrawer}
                onClose={closeDrawer}
            >
                <div>
                    <div className="add-widget-header">
                        <p>Add Widget</p>
                        <CloseButton handleCLic={closeDrawer} />
                    </div>
                    <SelectTypeAutocomplete
                        placeholder={inputs?.category ? '' : 'All'}
                        defaultValue={inputs?.category}
                        name={'category'}
                        handleSelect={handleChange}
                        list={WidgetCategories}
                        renderValue={(i) => i?.name}
                        uiType={'tableFilter'}
                        noError={true}
                    />


                    <div className="add-widget-body">
                        {loader?.length ?
                            <Loader />
                            :
                            listKpis?.map((i, j) => checkFilter(inputs, i) && (
                                <div className="widget-list-card-box" key={j}>
                                    <div className="widget-list-card-info">
                                        <div className="widget-list-card-icon-name-box">
                                            <img src={RenderWidgetImage(i?.category)} alt="icon" />
                                            <p>{i?.name}</p>
                                        </div>

                                        {i?.added ?
                                            <button className="added-widget-btn">
                                                <img src={Images.added} alt="add" />
                                                <p>Added</p>
                                            </button>
                                            :
                                            <button className="add-widget-btn" onClick={() => addToUser(i?.id)}>

                                                <div>
                                                    {selectedId === i?.id && addLoad?.length ?
                                                        <MinLoader
                                                            width={19}
                                                            height={19}
                                                            position={'relative'}
                                                            color={'#0C66E4'}
                                                            margin={'0'}
                                                        />
                                                        :
                                                        <img src={Images.addCircleBlue} alt="add" />
                                                    }
                                                </div>

                                                <p>Add</p>
                                            </button>
                                        }
                                    </div>
                                    <p className="widget-list-card-desc">{i?.description}</p>
                                </div>
                            ))}

                    </div>
                </div>

            </Drawer>

        </div>
    );
};