import moment from 'moment/moment';

export const ChartTypes = {
    BAR: 'BAR',
    LINE: 'LINE',
    PIE: 'PIE',
};

export const ChartViewEnums = {
    MONTHLY: 'MONTHLY',
    DAILY: 'DAILY',
};
export const ChartViewTypes = [
    { name: 'Monthly', id: ChartViewEnums.MONTHLY },
    { name: 'Daily', id: ChartViewEnums.DAILY },
];

export function getMonthsBetween(startDateStr, endDateStr) {
    const start = moment(startDateStr, 'YYYY-MM-DD');
    const end = moment(endDateStr, 'YYYY-MM-DD');
    const months = [];

    while (start.isSameOrBefore(end, 'month')) {
        months.push(start.format('YYYY-MM-DD'));
        start.add(1, 'month');
    }
    return months;
}

export function groupAndSumByYearAndMonth(data) {
    if (data) {
        const grouped = data?.reduce((acc, item) => {
            const date = new Date(item.date);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');

            const key = `${year}-${month}`;

            if (!acc[key]) {
                acc[key] = { count: 0, date: `${year}-${month}-01` };
            }

            acc[key].count += item.count;

            return acc;
        }, {});

        return Object.values(grouped);
    }
}


export function groupAndSumByYearMonthAndDay(data) {
    if (data) {
        const grouped = data?.reduce((acc, item) => {
            const date = new Date(item?.date);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');

            const key = `${year}-${month}-${day}`; // Include the day in the key

            if (!acc[key]) {
                acc[key] = { count: 0, date: `${year}-${month}-${day}` };
            }

            acc[key].count += item.count;

            return acc;
        }, {});

        return Object.values(grouped);
    }
}

export function mapCountsToDateRange(dateRangeList, groupedCurrent) {
    const groupedMap = groupedCurrent.reduce((acc, item) => {
        acc[item.date] = item.count;
        return acc;
    }, {});

    return dateRangeList.map((date) => groupedMap[date] || null);
}

const mergeDates = (currentDates, prevDates) => {
    const currentYear = new Date(currentDates[0]).getFullYear();

    const updatedPrevDates = prevDates.map(date => {
        const dateObj = new Date(date);
        dateObj.setFullYear(currentYear); // Update the year
        return dateObj.toISOString().split('T')[0];
    });

    const mergedDates = [...new Set([...currentDates, ...updatedPrevDates])];

    return mergedDates.sort((a, b) => new Date(a) - new Date(b));
};
const compareDates = (mergedDates, prevPeriod) => {
    const prevMapped = prevPeriod.map(item => {
        const date = new Date(item.date);

        return {
            count: item.count,
            monthDay: `${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`,
        };
    });

    return mergedDates.map(date => {
        const currentDate = new Date(date);
        const monthDay = `${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;

        // Find if current date matches any date in prevPeriod by month and day
        const match = prevMapped.find(item => item.monthDay === monthDay);
        return match ? match.count : null;
    });
};


export function BarChartData(info, currentPeriod, prevPeriod, view) {
    if (info?.startDate && info?.endDate && currentPeriod && prevPeriod) {
        // Monthly Dates
        const monthsBetween = getMonthsBetween(info?.startDate, info?.endDate);
        const monthDates = monthsBetween?.map((date) => moment(date).format('MMM YY'));

        // Current data
        const groupedCurrent = groupAndSumByYearAndMonth(currentPeriod);
        const monthCurrent = mapCountsToDateRange(monthsBetween, groupedCurrent);

        // Previous data
        const prevMonthsBetween = getMonthsBetween(info?.prevStartDate, info?.prevEndDate);
        const groupedPrev = groupAndSumByYearAndMonth(prevPeriod);
        const monthPrev = mapCountsToDateRange(prevMonthsBetween, groupedPrev);


        // Daily data
        const currentDailyDates = groupAndSumByYearMonthAndDay(currentPeriod)?.map((item) => item.date);
        const prevDailyDates = groupAndSumByYearMonthAndDay(prevPeriod)?.map((item) => item.date);
        const mergedDates = mergeDates(currentDailyDates, prevDailyDates);


        const dailyCurrent = groupAndSumByYearMonthAndDay(currentPeriod)?.map((item) => item.count || null);

        const prevDaily = compareDates(mergedDates, groupAndSumByYearMonthAndDay(prevPeriod));

        const dates = view === ChartViewEnums.MONTHLY ? monthDates : mergedDates;
        const current = view === ChartViewEnums.MONTHLY ? monthCurrent : dailyCurrent;
        const prev = view === ChartViewEnums.MONTHLY ? monthPrev : prevDaily;

        return {
            dates, current, prev,
        };
    } else {
        return {
            dates: [], current: [], prev: [],
        };
    }
}


const getValueForDate = (date, data) => {
    const item = data.find(d => d.date === date);
    return item ? item.count : 0;
};

const getValueMonthForDate = (date, data) => {
    const [targetYear, targetMonth] = date.split('-').slice(0, 2); // Extract year and month from the date
    const filteredItems = data.filter(d => {
        const [year, month] = d.date.split('-').slice(0, 2);
        return month === targetMonth;
    });

    return filteredItems.reduce((sum, item) => sum + item.count, 0);
};


const mergeLineChartDates = (currentDates, prevDates) => {
    if (currentDates?.length) {
        const currentYear = new Date(currentDates[0].date).getFullYear();

        const updatedPrevDates = prevDates.map(item => ({
            count: item.count,
            date: item.date.replace(/^\d{4}/, currentYear),
        }));

        // const mergedDates = [...currentDates, ...updatedPrevDates];
        const mergedDates = [...currentDates, ...prevDates];

        const startDate = new Date(`${currentYear}-01-01`);
        const endDate = new Date(`${currentYear}-12-31`);
        const allDates = [];
        while (startDate <= endDate) {
            allDates.push(new Date(startDate));
            startDate.setDate(startDate.getDate() + 1);
        }

        const result = allDates.map(date => {
            const dateString = date.toISOString().split('T')[0];
            const current = mergedDates.find(item => item.date === dateString && item.count ? item.count : 0) || { count: 0 };
            const previous = updatedPrevDates.find(item => item.date === dateString && item.count ? item.count : 0) || { count: 0 };

            return {
                date,
                Current: current.count ? current.count : 0,
                Previous: previous.count ? previous.count : 0,
            };
        });

        return result.filter(entry => entry.Current !== 0 || entry.Previous !== 0);
    }
};


export function LineChartData(info, currentPeriod, prevPeriod, view) {
    if (info?.startDate && info?.endDate && currentPeriod && prevPeriod) {
        // Dates
        const monthsBetween = getMonthsBetween(info?.startDate, info?.endDate);

        // Current data
        const groupedCurrent = groupAndSumByYearAndMonth(currentPeriod);
        const groupedPrev = groupAndSumByYearAndMonth(prevPeriod);

        const newArray = monthsBetween?.map(date => {
            const current = getValueForDate(date, groupedCurrent);
            const previous = getValueMonthForDate(date, groupedPrev);

            const newDate = new Date(date);
            newDate.setDate(newDate.getDate() + 2);

            return { date: newDate, Current: current, Previous: previous };
        });


        const currentDailyDates = groupAndSumByYearMonthAndDay(currentPeriod);
        const prevDailyDates = groupAndSumByYearMonthAndDay(prevPeriod);
        const mergedDates = mergeLineChartDates(currentDailyDates, prevDailyDates);

        const lineChartDates = view === ChartViewEnums.MONTHLY ? newArray : mergedDates;
        return { lineChartDates };
    } else {
        return {
            lineChartDates: [],
        };
    }
}


const renderColor = (item) => {
    switch (item) {
        case 'Pending':
            return 'rgb(111, 210, 49)';
        case 'Completed':
            return 'rgb(42, 142, 109)';
        case 'Cancelled':
            return '#344054';
        case 'Active':
            return '#00C851';
        case 'Inactive':
            return '#98A2B3';
        case 'Hold':
            return '#347AF0';
        case 'Terminated':
            return '#FE7070';
        case 'On Hold':
            return '#FFCA33';
        case 'FBA':
            return '#ADD8E6';
        case 'Wait List':
            return '#FE7B43';
        case 'Graduated':
            return '#51439E';
        case 'Appealed':
            return '#FFA500';

        default:
            return '#D3D3D3';
    }
};


export function PieChartData(chartType, info) {
    if (chartType === ChartTypes.PIE && info) {
        const pieChartData = info ? info?.map((item) => (
            {
                label: item?.label,
                value: item?.value,
                color: renderColor(item?.label),
            }
        )) : [];
        return { pieChartData };
    } else {
        return [];
    }
}