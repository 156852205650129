import React from 'react';
import { useGlobalStyles } from 'utils';

export const NoItemText = ({ text, styles }) => {
    const globalStyle = useGlobalStyles();
    return (
        <div>
           <p style={{ ...styles }} className={globalStyle.noData}>{text}</p>
        </div>
    );
};
