
/** Service Type */

export const CREATE_SERVICE_GLOBAL = 'CREATE_SERVICE_GLOBAL'

export const GET_SERVICES = 'GET_SERVICES'
export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS'

export const EDIT_SERVICE_BY_ID_GLOBAL = 'EDIT_SERVICE_BY_ID_GLOBAL'

export const DELETE_SERVICE_BY_ID_GLOBAL = 'DELETE_SERVICE_BY_ID_GLOBAL'

/** End */

/** Credentials */

export const CREATE_CREDENTIAL_GLOBAL = 'CREATE_CREDENTIAL_GLOBAL'

export const GET_CREDENTIAL_GLOBAL = 'GET_CREDENTIAL_GLOBAL'
export const GET_CREDENTIAL_GLOBAL_SUCCESS = 'GET_CREDENTIAL_GLOBAL_SUCCESS'

export const EDIT_CREDENTIAL_BY_ID_GLOBAL = 'EDIT_CREDENTIAL_BY_ID_GLOBAL'

export const DELETE_CREDENTIAL_BY_ID_GLOBAL = 'DELETE_CREDENTIAL_BY_ID_GLOBAL'

export const CREATE_COVERED_CREDENTIAL = 'CREATE_COVERED_CREDENTIAL'

export const EDIT_COVERED_CREDENTIAL = 'EDIT_COVERED_CREDENTIAL'
export const SELECTED_CREDENTIAL = 'SELECTED_CREDENTIAL'
export const REMOVE_SELECTED_CREDENTIAL = 'REMOVE_SELECTED_CREDENTIAL'

/** End */

/** Departments */

export const CREATE_DEPARTMENT_GLOBAL = 'CREATE_DEPARTMENT_GLOBAL'

export const GET_DEPARTMENTS = 'GET_DEPARTMENTS'
export const GET_DEPARTMENTS_SUCCESS = 'GET_DEPARTMENTS_SUCCESS'

export const EDIT_DEPARTMENT_BY_ID_GLOBAL = 'EDIT_DEPARTMENT_BY_ID_GLOBAL'

export const DELETE_DEPARTMENT_BY_ID_GLOBAL = 'DELETE_DEPARTMENT_BY_ID_GLOBAL'

/** End */

/** Job */

export const CREATE_JOB_GLOBAL = 'CREATE_JOB_GLOBAL'

export const GET_JOBS = 'GET_JOBS'
export const GET_JOBS_SUCCESS = 'GET_JOBS_SUCCESS'

export const EDIT_JOB_BY_ID_GLOBAL = 'EDIT_JOB_BY_ID_GLOBAL'

export const DELETE_JOB_BY_ID_GLOBAL = 'DELETE_JOB_BY_ID_GLOBAL'

/** End */

/** Place */

export const CREATE_PLACE_GLOBAL = 'CREATE_PLACE_GLOBAL'

export const GET_PLACES = 'GET_PLACES'
export const GET_PLACES_SUCCESS = 'GET_PLACES_SUCCESS'

export const EDIT_PLACE_BY_ID_GLOBAL = 'EDIT_PLACE_BY_ID_GLOBAL'

export const DELETE_PLACE_BY_ID_GLOBAL = 'DELETE_PLACE_BY_ID_GLOBAL'

/** End */

/** Company */

export const CREATE_COMPANY = 'CREATE_COMPANY'

export const GET_COMPANY = 'GET_COMPANY'
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS'

export const EDIT_COMPANY = 'EDIT_COMPANY'

export const DELETE_COMPANY = 'DELETE_COMPANY'

/** End */

/** Calendars */

export const DISCONNECT_CALENDAR = 'DISCONNECT_CALENDAR'
export const CHANGE_CALENDAR_STATUS = 'CHANGE_CALENDAR_STATUS'
export const GET_CONNECT_CALENDARS = 'GET_CONNECT_CALENDARS'
export const GET_CONNECT_CALENDARS_SUCCESS = 'GET_CONNECT_CALENDARS_SUCCESS'

/** End */

/** Office Ally */

export const GET_OFFICE_ALLY = 'GET_OFFICE_ALLY'
export const GET_OFFICE_ALLY_SUCCESS = 'GET_OFFICE_ALLY_SUCCESS'
export const CONNECT_OFFICE_ALLY = 'CONNECT_OFFICE_ALLY'
export const DISCONNECT_OFFICE_ALLY = 'DISCONNECT_OFFICE_ALLY'
export const EDIT_OFFICE_ALLY_CONNECTION = 'EDIT_OFFICE_ALLY_CONNECTION'

/** End */