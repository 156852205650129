import React from "react";
import {CardItem} from "./cardItem";
import {CardHeader} from "./cardHeader";
import {cardStyle} from "./style";
import {getCardBorderTop} from "./constants";
import {Images, RolePermission} from "utils";

export const Card = ({
                         cardInfo = [],
                         color,
                         title,
                         icon,
                         showHeader,
                         width,
                         maxWidth,
                         hideHeaderLine,
                         auth,
                         click,
                         active,
                         employment,
                         isBig,
                         edit,
                         handleEdit,
                         handleShowHistory,
                         editAccess,
                         addEmployment,
                         bodyEdit
                     }) => {
    const classes = cardStyle();
    const borderTop = getCardBorderTop(title);
    const checkPermission = editAccess?.length ? RolePermission(([...editAccess])) : true

    return (
        <div
            className={classes.card}
            style={width && {width: width, maxWidth: maxWidth || 210}}
        >
            {showHeader && (
                <>
                    <div className={classes.headerBorder} style={{borderTop}}/>
                    <CardHeader
                        hideHeaderLine={hideHeaderLine}
                        color={color}
                        title={title}
                        icon={icon}
                        edit={edit}
                        handleEdit={handleEdit}
                        cardInfo={cardInfo}
                        checkPermission={checkPermission}
                        addEmployment={addEmployment}
                    />
                </>
            )}
            <div className={classes.cardBody}>
                {cardInfo?.length ? cardInfo?.map((item, index) => {
                            return (
                                <CardItem
                                    handleShowHistory={handleShowHistory}
                                    employment={employment}
                                    active={active}
                                    click={click}
                                    auth={auth}
                                    key={index}
                                    index={index}
                                    item={item}
                                    title={item.title}
                                    value={item.value}
                                    authId={item?.authId}
                                    isBig={isBig}
                                    maxWidth={maxWidth ? maxWidth : 210}
                                />
                            );
                    })
                    :
                    bodyEdit && checkPermission &&
                    <div>
                        <div onClick={handleEdit} className={classes.rowCenterWrapper}>
                            <img src={Images.addBluePlus} alt="icon"/>
                            <p className={classes.addTitle}>{`Add ${title}`}</p>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};
