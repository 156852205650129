import { Images } from 'utils';
import { WidgetEnums } from '../constants';

export const RenderWidgetImage = (type) => {
    switch (type) {
        case WidgetEnums.CLIENT:
            return Images.clientWidget;
        case WidgetEnums.PAYROLL:
            return Images.payrollWidget;
        case WidgetEnums.STAFF:
            return Images.staffWidgets;
        case WidgetEnums.FS_SOURCE:
            return Images.fsWidget;
        case WidgetEnums.APPT:
            return Images.apptWidget;
        default:
            return Images.clientWidget;
    }
};


