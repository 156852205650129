import React, { useEffect } from 'react';
import { CreateChancel, Input, TextareaCustom } from 'components';
import { FindLoad, FindSuccessItem, useModal } from 'utils';
import { useDispatch } from 'react-redux';
import { noteActions } from 'store/notes';
import { httpRequestsOnSuccessActions } from 'store';
import { useForm } from 'react-hook-form';

export const AddNotes = ({ model, info, id, afterSuccess }) => {
    const ACTION_TYPE = !!info ? 'EDIT_GLOBAL_NOTE' : 'CREATE_GLOBAL_NOTE';
    const dispatch = useDispatch();
    const loader = FindLoad(ACTION_TYPE);
    const success = FindSuccessItem(ACTION_TYPE);
    const { close } = useModal();
    const { handleSubmit, watch, control, setError, clearErrors, reset } = useForm();

    useEffect(() => {
        if (info?.id) {
            reset(info);
        }
    }, [info]);

    useEffect(() => {
        if (success) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));

            if (afterSuccess) {
                afterSuccess();
            }
        }
    }, [success]);

    const handleCreate = (date) => {
        const noteData = {
            ...date,
            resource: id,
            onModel: model,
        };
        if (!!info) {
            dispatch(noteActions.editGlobalNote(id, info?.id, noteData, model));
        } else {
            dispatch(noteActions.createGlobalNote(noteData));
        }
    };

    return (
        <div style={{ width: 400 }}>
            <p className="modal-header-title">{!!info ? 'Edit Note' : 'Add Note'}</p>
            <form onSubmit={handleSubmit(handleCreate)}>
                <div className="modal-body-wrapper">
                    <Input
                        name="subject"
                        label="Subject*"
                        variant="outlined"
                        control={control}
                        maxLength={100}
                        rules={{
                            maxLength: {
                                value: 100,
                                message: 'Max length is 100 digits.',
                            },
                            required: 'Subject is required.',
                        }}
                    />
                    <TextareaCustom
                        control={control}
                        name="text"
                        label="Body*"
                        variant={'outlined'}
                        allowCharacter={true}
                        maxLength={200}
                        rules={{
                            maxLength: {
                                value: 200,
                                message: 'Max length is 200 digits.',
                            },
                            required: 'Description is required.',
                        }}
                    />
                </div>
                <CreateChancel
                    type="submit"
                    loader={!!loader.length}
                    create={'Save'}
                    // create={info ? 'Save' : 'Add'}
                    chancel={'Cancel'}
                    onClose={close}
                    buttonWidth="100%"
                />
            </form>
        </div>
    );
};
