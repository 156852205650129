import { PhoneRow } from '../table';


export const DetailInfoCard = ({ image, title, type, address }) => {
    return (
        <div className={type ? 'detail-info-card-box-clickable' : 'detail-info-card-box'}>
            <img src={image} alt="icon" />
            {
                type === 'phone' ?
                    <a href={`tel:${title}`}>
                        <PhoneRow phone={title} />
                    </a>
                    :
                    type === 'email' ?
                        <a href={`mailto:${title}`}>
                            {title}
                        </a>
                        :
                        type === 'direction' ?
                            <a
                                href={`https://www.google.com/maps?saddr=My+Location&daddr=${address?.lat},${address?.lng}`}
                                rel="noreferrer"
                                target="_blank"
                            >
                                {title}
                            </a>

                            :
                            <p>{title}</p>
            }
        </div>
    );
};