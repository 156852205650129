import React from 'react';
import { CalendarFilters } from './calendarFilters';
import { Selectable } from '../calendar';
import { Filters } from '../filters';

export const CalendarPage = ({  handleOpen, handleOpenClose, closeFullScreen }) => {
    return(
        <div className='calendar-page-wrapper'>
            <CalendarFilters />

            <div className='calendar-view-wrapper'>
                <Filters
                    handleOpen={handleOpen}
                    openCloseFullScreen={closeFullScreen}
                    closeFullScreen={closeFullScreen}
                    fullView={true}
                />
                <Selectable
                    handleOpenClose={handleOpenClose}
                />
            </div>
        </div>
    )
}