import React, { useEffect } from 'react';
import { scheduleModalsStyle } from './styles';
import { DailyPattern, MonthlyPattern, WeeklyPattern } from './modePatterns';
import { Mode, CancellingAppts } from './common';
import moment from 'moment/moment';
import { ErrMessage } from '../../../../components';

export const CreateMultiple = ({
                                   handleClose,
                                   modalDate,
                                   state,
                                   setState,
                                   inputs,
                                   setInputs,
                                   setOccurrence,
                                   error,

                               }) => {
    const classes = scheduleModalsStyle();

    function handleChange(e) {
        e.target.name === 'mode' && setOccurrence(0);
        e.target.name === 'repeatCountCheckbox' && setOccurrence(0);
        setState([]);
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    }


    const handleChangeEvery = (e) => {
        const params = {
            ...inputs,
        };
        params[e.target.name] = e.target.value;
        if (e.target.name === 'repeatConsecutive') {
            delete params.repeatCountCheckbox;
        }
        if (e.target.name === 'repeatCountCheckbox') {
            params.repeatCount = '1';
            delete params.repeatConsecutive;
        }
        setInputs(params);
    };

    useEffect(() => {
        if (inputs?.mode === 'DAILY' && inputs?.repeatConsecutive !== 'repeatConsecutive' && inputs.startDate && inputs.endDate && inputs?.repeatCount) {
            handleDaily();
        }
        if (inputs?.mode === 'WEEKLY' && inputs.startDate && inputs.endDate && inputs?.repeatCountWeek) {
            handleWeekly();
        }
        if (inputs?.mode === 'MONTHLY' && inputs.startDate && inputs.endDate && inputs?.repeatDayMonth && inputs?.repeatMonth) {
            handleMonthly(+inputs?.repeatDayMonth, +inputs?.repeatMonth);
        }
    }, [inputs, state]);

    const handleDaily = (e) => {
        const startDate = new Date(inputs.startDate);
        const endDate = new Date(new Date(inputs.endDate).setHours(23, 59, 59));
        let count = 0;
        let dates = [],
            x;

        const enteredDateCount = e ? +e.target.value : +inputs?.repeatCount;

        for (let d = startDate; d <= endDate; d.setDate(d.getDate() + enteredDateCount)) {
            if (enteredDateCount !== 0) count++;
            if (enteredDateCount === 0) break;
            x = new Date(d.getTime());
            dates.push(x);
        }
        setOccurrence(count);
    };

    function handleChangeDay(e) {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value === '0' ? 1 : e.target.value }));
        handleDaily(e);
    }

    function handleChangeConsecutive(e) {
        const newInputs = {
            ...inputs,
            [e.target.name]: e.target.value,
        };

        delete newInputs.repeatCount;
        delete newInputs.repeatCountCheckbox;

        const startDate = new Date(inputs.startDate);
        const endDate = new Date(new Date(inputs.endDate).setHours(23, 59, 59));
        const curDate = new Date(startDate);
        let dates = [];
        while (curDate <= endDate) {
            const dayOfWeek = curDate.getUTCDay();
            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                dates.push(new Date(curDate));
            }
            curDate.setDate(curDate.getDate() + 1);
        }
        setInputs(newInputs);
        setOccurrence(dates.length);
    }

    function handleChangeWeek(e) {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value === '0' ? 1 : e.target.value }));
        handleWeekly(e);
    }

    const handleWeekly = (e) => {
        const weekdays = [...state];
        const repeatCount = e ? +e?.target?.value : inputs.repeatCountWeek ? inputs.repeatCountWeek : 1;
        let occurrences = 0;
        const momentStart = moment.utc(inputs.startDate).startOf('day');
        const momentEnd = moment.utc(inputs.endDate).endOf('day');
        const current = momentStart.clone();
        while (current.isSameOrBefore(momentEnd)) {
            const dayOfWeek = current.day();
            if (
                weekdays.includes(dayOfWeek) &&
                (repeatCount === 1 || current.diff(momentStart, 'weeks') % repeatCount === 0)
            ) {
                ++occurrences;
            }
            current.add(1, 'day');
        }
        setOccurrence(occurrences);
    };

    function handleChangeWeeks(e) {
        const newState = [...state];
        if (e.target.checked) {
            newState.push(+e.target.value);
        } else {
            for (let i = 0; i < state.length; i++) {
                if (state[i] === +e.target.value) {
                    newState.splice(i, 1);
                }
            }
        }
        setState(newState);
    }

    function handleChangeMonth(e) {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value === '0' ? 1 : e.target.value }));
    }

    const handleMonthly = (repeatDayMonth, repeatMonth) => {
        let count = 0;
        const start = moment.utc(inputs.startDate);
        const end = moment.utc(inputs.endDate);
        const current = moment.utc(start);

        while (current <= end) {
            current.date(repeatDayMonth);
            if (current > end) {
                break;
            }
            if (current >= start) {
                count++;
            }
            current.month(current.month() + repeatMonth);
        }
        setOccurrence(count);
    };

    return (
        <div className="full-width">
            <div className="flex-align-start" style={{ gap: 24, marginTop: 16 }}>
                {!modalDate?.template &&
                    <Mode inputs={inputs} handleChange={handleChange} />
                }

                {!modalDate?.template &&
                    <div className="full-width">
                        <p className={classes.recurTitle}>Patterns</p>
                        <div className={classes.dayWeekMounth}
                             style={error === 'repeat' ? { borderColor: '#F07379' } : {}}>
                            <div>
                                {inputs.mode === 'DAILY' ? (
                                    <DailyPattern
                                        handleChange={handleChangeEvery}
                                        handleChangeDay={handleChangeDay}
                                        handleChangeConsecutive={handleChangeConsecutive}
                                        inputs={inputs}
                                    />
                                ) : inputs.mode === 'WEEKLY' ? (
                                    <WeeklyPattern
                                        inputs={inputs}
                                        handleChangeWeek={handleChangeWeek}
                                        handleChangeWeeks={handleChangeWeeks}
                                    />
                                ) : inputs.mode === 'MONTHLY' ? (
                                    <MonthlyPattern
                                        inputs={inputs}
                                        handleChangeMounth={handleChangeMonth}
                                        handleChangeMounthDay={handleChangeMonth}
                                    />
                                ) : null}
                            </div>
                        </div>
                        {error === 'repeat' &&
                            <ErrMessage text={'Pattern is required'} />
                        }
                    </div>
                }

            </div>

            {modalDate && modalDate?.template &&
                <CancellingAppts
                    apptDate={modalDate}
                    handleClose={handleClose}
                />
            }
        </div>
    );
};