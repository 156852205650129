import React from 'react';
import { Images } from 'utils';

export const NameAndAvatar = ({ src, name, type }) => {

    return (
        <div className="name-and-avatar-box">
            {type === 'fundingSource' ?
                <img className='fs-source-image' src={Images.fsIcon} alt="avatar" />
                :
                <div className="user-avatar">
                    <img src={src ? src : Images.userProfile} alt="avatar" />
                </div>
            }
            <h1>
                {name}
            </h1>
        </div>
    );
};