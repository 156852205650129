import React, { useEffect } from 'react';
import { Router } from 'root/router';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useLocation } from 'react-router-dom';
import { TopBar, LeftBar } from './core';
import { navBarStyles } from './core/style';
import { authActions, notificationActions, permissionsActions, systemActions } from 'store';
import { HelpCenterPage } from 'pages';

export const MenuBar = ({}) => {
    const { accessToken } = useSelector((state) => ({
        accessToken: state.auth.accessToken,
    }));
    const classes = navBarStyles();
    const dispatch = useDispatch();
    const token = accessToken ? accessToken : localStorage.getItem('access-token');
    const location = useLocation()
    const checkHelpCenter = location?.pathname === '/help-center';

    useEffect(() => {
        if(token) {
            dispatch(authActions.getMyProfile());
            dispatch(systemActions.getCompany());
            dispatch(permissionsActions.getMyPermissions());
            dispatch(notificationActions.getUnRenderNotifications());
        }
    }, [dispatch, token]);

    return (
        <div className={classes.root}>
            <TopBar />
            {!checkHelpCenter  && <LeftBar />}

            {checkHelpCenter ?
                <Route exact path={'/help-center'} component={HelpCenterPage} />
                :

                <main style={{ height: '100%', minHeight: '100vh' }} className={classes.content}>
                    <Router />
                </main>
            }

        </div>
    );
};
