import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
    CheckboxesTags,
    CreateChancel, DateTypeSelect,
    SelectTypeAutocomplete,
    ValidationInput,
} from 'components';
import { ErrorText, FindLoad, FindSuccessItem, languagesById, useModal } from 'utils';
import { clientActions, httpRequestsOnSuccessActions } from 'store';

const list = [
    { name: 'Male', id: 'MALE' },
    { name: 'Female', id: 'FEMALE' },
    { name: 'Other', id: 'OTHER' },
];
export const ClientOtherDetails = ({ info }) => {
    const ACTION_TYPE = 'EDIT_CLIENT';
    const [error, setError] = useState('');
    const [inputs, setInputs] = useState({});
    const dispatch = useDispatch();
    const success = FindSuccessItem(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const { close } = useModal();

    useEffect(() => {
        if (info?.id) {
            const clientInfo = {
                ...info,
            };
            info?.birthday ? clientInfo.birthday = moment.utc(info?.birthday).format('YYYY-MM-DD') : '';
            info?.renderingProviderId ? clientInfo.renderingProvider = info?.renderingProviderId : '';
            info?.registrationDate ? clientInfo.registrationDate = moment.utc(info?.registrationDate).format('YYYY-MM-DD') : '';

            if (info?.languages?.length) {
                clientInfo.languages = info?.languages.map((i) => ({ id: i, label: i }));
            } else {
                delete clientInfo.languages;
            }
            setInputs(clientInfo);
        }
    }, [info]);

    useEffect(() => {
        if (success?.type === ACTION_TYPE) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    const handleChange = (e) => {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        error === e.target.name && setError('');
    };

    const handleChangeLanguages = (e) => {
        setInputs((prevState) => ({ ...prevState, ['languages']: e }));
    };

    const handleCreate = () => {
        const data = {
            firstName: inputs.firstName,
            middleName: inputs?.middleName ? inputs.middleName : null,
            lastName: inputs.lastName,
            phoneNumber: inputs?.phoneNumber,
            insuredInfo: info?.insuredInfo?.firstName ? { ...info?.insuredInfo } : null,
        };
        data.birthday = inputs?.birthday ? inputs?.birthday : null;
        data.gender = inputs?.gender ? inputs.gender : null;
        data.languages = inputs?.languages?.length ? data.languages = inputs?.languages?.map((i) => i?.id) : null;
        data.ethnicity = inputs?.ethnicity ? inputs?.ethnicity : null;
        data.familyLanguage = inputs?.familyLanguage ? inputs?.familyLanguage : null;
        info?.insuredInfo?.address ? data.insuredInfo.address = info?.insuredInfo?.address?.formattedAddress : '';
        data.code = inputs?.code ? inputs?.code : null;
        data.registrationDate = inputs?.registrationDate ? inputs?.registrationDate : null;
        data.email = inputs?.email ? inputs?.email : null;
        data.renderingProvider = inputs?.renderingProvider ? inputs?.renderingProvider : null;
        inputs.gender ? data.gender = inputs.gender : delete data.gender;

        dispatch(clientActions.editClient(data, info?.id));
    };


    return (
        <div style={{ width: 463 }}>
            <p className="modal-header-title">Edit Other Details</p>
            <div className="modal-body-wrapper">
                <SelectTypeAutocomplete
                    title={'Gender'}
                    name={'gender'}
                    handleSelect={handleChange}
                    defaultValue={inputs?.gender}
                    list={list}
                    error={error}
                    typeError={error === 'gender' ? ErrorText.selectField : ''}
                    renderValue={(i) => i?.name}
                />
                <DateTypeSelect
                    type={'modalInput'}
                    values={inputs}
                    name="birthday"
                    handleGetDates={handleChange}
                    outLabel={'Date of Birth'}
                    max={moment.utc().format('YYYY-MM-DD')}
                />
                <ValidationInput
                    variant={'outlined'}
                    onChange={handleChange}
                    value={inputs.ethnicity}
                    label={'Ethnicity'}
                    name="ethnicity"
                    typeError={error === 'ethnicity' ? ErrorText.field : ''}
                />
                <CheckboxesTags
                    name="languages"
                    handleChange={handleChangeLanguages}
                    renderValue={(i) => i?.label}
                    value={inputs?.languages || []}
                    permissionsList={languagesById || []}
                    label={'Language'}
                    uiType={'modalCheckboxSelect'}
                />
                <SelectTypeAutocomplete
                    title={'Family Language'}
                    name={'familyLanguage'}
                    handleSelect={handleChange}
                    defaultValue={inputs?.familyLanguage}
                    list={languagesById || []}
                    error={error}
                    renderValue={(i) => i?.label}
                />
                <DateTypeSelect
                    type={'modalInput'}
                    handleGetDates={handleChange}
                    values={inputs}
                    name={'registrationDate'}
                    outLabel={'Registration Date'}
                />
            </div>
            <CreateChancel
                loader={!!loader.length}
                create={'Save'}
                chancel={'Cancel'}
                onCreate={handleCreate}
                onClose={() => close()}
                buttonWidth="224px"
            />
        </div>
    );
};