import { PermissionList, RolePermission } from 'utils';


export const headerList = [
    { name: 'Bill ID', width: '8%' },
    { name: 'DOS', width: '8%' },
    { name: 'CPT Code (Mod.)', width: '10%' },
    { name: 'Units', width: '5%' },
    { name: 'Billed ', width: '8%' },
    { name: 'Allowed ', width: '8%' },
    { name: 'Deduct.', width: '8%' },
    { name: 'Copay', width: '8%' },
    { name: 'CoINS', width: '8%' },
    { name: 'Paid ', width: '8%' },
    { name: 'Balance ', width: '7%' },
    RolePermission([PermissionList.CLAIM_PMT_PAY?.code, PermissionList.CLAIM_PMT_SEND_APPEAL?.code]) &&
    { name: 'Action', width: '8%' },
];
