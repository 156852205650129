import React, { useEffect, useState } from 'react';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import { Images, newDateWithoutUtc, SaveParams } from 'utils';
import { Popper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { enGB } from 'date-fns/locale';
import { ErrMessage } from '../messages';
import { DateInput } from 'rsuite';

export const DateTypeSelect = ({
                                   outLabel,
                                   handleGetDates,
                                   values,
                                   error,
                                   type,
                                   noError,
                                   name,
                                   max,
                                   min,
                                   resetValue,
                               }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    let history = useHistory();
    const info = values ? values : history?.location?.state;
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const [state, setState] = useState();
    const valueFromRoute = info?.[name];


    // const testMin = new Date(min);
    // const testMax = new Date(max);
    //
    //
    // if (min) {
    //     const momentMin = moment(min);
    //     const year = momentMin.year();
    //     const month = momentMin.month();
    //     const day = momentMin.date();
    //     testMin.setFullYear(year);
    //     testMin.setMonth(month);
    //     testMin.setDate(day);
    //     testMin.setHours(0, 0, 0, 0);
    // }
    //
    // if (max) {
    //     const momentMax = moment(max);
    //     const year = momentMax.year();
    //     const month = momentMax.month();
    //     const day = momentMax.date();
    //     testMax.setFullYear(year);
    //     testMax.setMonth(month);
    //     testMax.setDate(day);
    //     testMax.setHours(0, 0, 0, 0);
    // }


    // console.log(testMin, 'testMin');
    // console.log(testMax, 'testMax');
    // console.log(min, 'min');


    const customLocale = {
        ...enGB,
        options: {
            ...enGB.options,
            weekStartsOn: 1,
        },
    };

    useEffect(() => {
        if (valueFromRoute) {
            setState(moment(valueFromRoute).format());
        } else {
            if (resetValue) {
                setState(null);
            }
        }
    }, [info]);

    const handleDateChange = (item) => {
        setState(moment(item).format());

        if (handleGetDates) {
            handleGetDates({ target: { value: moment(item).format('YYYY-MM-DD'), name: name } });
        } else {
            const params = {
                ...info,
            };
            params[name] = moment(item).format('YYYY-MM-DD');
            SaveParams(history, params);
        }
        setAnchorEl(null);
    };

    const handleClear = () => {
        setState(null);

        if (handleGetDates) {
            handleGetDates({ target: { value: '', name: name } });
        } else {
            const params = {
                ...info,
            };
            delete params[name];
            SaveParams(history, params);
        }
    };

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleTypeDate = (value) => {
        const date = value?.toString();

        if (isValidDate(date)) {
            const dateObject = new Date(value);
            const minDate = min ? new Date(min) : null;
            const maxDate = max ? new Date(max) : null;
            const isWithinRange = (!minDate || dateObject >= minDate) && (!maxDate || dateObject <= maxDate);

            if (isWithinRange) {
                setState(moment.utc(value).format());
            } else {
                console.warn('Date is out of range');
            }
        } else {
            console.warn('Invalid date format');
        }
    };

    const handleBlur = () => {
        const value = state;
        const checkIsNotSame =
            value && moment(value).format('YYYY-MM-DD') !== moment(info[name]).format('YYYY-MM-DD');

        if (value && checkIsNotSame) {
            if (handleGetDates) {
                handleGetDates({ target: { value: moment(value).format('YYYY-MM-DD'), name: name } });
            } else {
                const params = {
                    ...info,
                };
                params[name] = moment(value).format('YYYY-MM-DD');
                SaveParams(history, params);
            }
        }
    };

    const isValidDate = (dateString) => {
        const date = new Date(dateString);
        return !isNaN(date.getTime());
    };

    return (
        <div style={{ width: '100%' }}>
            {outLabel &&
                <p
                    style={type === 'modalInput' ? { color: error ? '#FE7070' : valueFromRoute ? '#347AF0' : '#51566D' } : {}}
                    className={type === 'modalInput' ? 'modal-date-selector-label' : 'date-selector-label'}>
                    {outLabel}
                </p>
            }
            <button
                onClick={handleClick}
                style={
                    error ? { borderColor: '#FE7070', color: '#FE7070' } :
                        type === 'modalInput' ? { width: '100%' } : { width: '165px', justifyContent: 'space-between' }}
                className={type === 'modalInput' ? 'modal-date-range-selector-dos' : 'date-range-selector'}
                aria-describedby={id}
                type={'button'}
            >
                <div className="date-range-inputs"
                     onClick={(e) => {
                         e.stopPropagation();
                         e.preventDefault();
                     }}
                >
                    <DateInput
                        onBlur={handleBlur}
                        placeholder={'mm/dd/yyyy'}
                        format="MM/dd/yyyy"
                        value={state ? moment.utc(state).toDate() : null}
                        onChange={handleTypeDate}
                    />
                </div>
                <div className="flex-align-center">
                    {state &&
                        <button className="remove-calendar-day" type={'button'} onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleClear();
                        }}>
                            <img src={Images.removeBlack} alt="remove" />
                        </button>
                    }
                    <img src={Images.calendarToday} alt="icon" />
                </div>
            </button>
            {!noError &&
                <ErrMessage text={error} />
            }
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                className={'modal-custom-date-selector-box'}
                placement="bottom-start"
                style={{ position: 'absolute', zIndex: 1400 }}
            >
                <Calendar
                    date={state}
                    onChange={handleDateChange}
                    locale={customLocale}
                    maxDate={max ? newDateWithoutUtc(max) : undefined}
                    minDate={min ? newDateWithoutUtc(min) : undefined}
                    // maxDate={max ? new Date(max) : undefined}
                    // minDate={min ? new Date(min) : undefined}
                />
                <button type={'button'} className="clear-bnt" onClick={handleClear}>Clear</button>
            </Popper>
            {open && <div className="beck-drop" onClick={() => setAnchorEl(null)} />}
        </div>
    );
};
