import React from 'react';
import { motion } from 'framer-motion';
import { LandingSvg } from 'assets';
import { animationParams, animationVariants } from 'fragments';


export const SolutionsCard = ({ title, description, classNameWrapper }) => {
    return (
        <motion.div
            className={`solutionsCard-wrapper ${classNameWrapper ? classNameWrapper : ''}`}
            {...animationParams}
            variants={animationVariants.cardsVariant}
        >
            <div className='solutions-card-circle'>
                <LandingSvg.ArrowRightSvg className='solutions-card-svg' />
            </div>
            <div className='solutions-card-title'>
                {title}
            </div>

            <div className='solutions-card-description'>
                {description}
            </div>
        </motion.div>
    );
};

