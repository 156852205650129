import {RolePermission, PermissionList} from "../hooks";
import { Images } from 'utils';

export const superAdminRouters = [
    // RolePermission([PermissionList.ROLES_READ.code]) &&
    // {
    //     name: "Role Management",
    //     icon: Images.roleTab,
    //     activeIcon: Images.roleTabActive ,
    //     path: "/management",
    // },

    RolePermission([PermissionList.FS_LIST_READ?.code]) &&
    {
        name: "Funding Source",
        icon: Images.fsTab,
        activeIcon: Images.fsTabActive ,
        path: "/fundingSource",
    },

    RolePermission([PermissionList.STAFF_LIST_READ.code]) &&
    {
        name: "Staff",
        icon: Images.staffTab,
        activeIcon: Images.staffTabActive ,
        path: "/staff",
    },
    RolePermission([PermissionList.CLIENT_LIST_READ?.code, PermissionList.CONNECTED_CLIENT_READ?.code]) &&
    {
        name: "Client",
        icon: Images.clientTab,
        activeIcon: Images.clientTabActive ,
        path: "/client",
    },
    {
        name: "Schedule",
        icon: Images.scheduleTab,
        activeIcon: Images.scheduleTabActive ,
        path: "/schedule",
    },

    RolePermission([PermissionList.CLAIMS_READ?.code, PermissionList.INVOICE_READ?.code]) &&
    {
        name: "Billing",
        icon: Images.billingTab,
        activeIcon: Images.billingTabActive ,
        path: "/billing",
        type: 'accordion'
    },
    // RolePermission([PermissionList.CLAIM_PMT_READ?.code, PermissionList.INVOICE_PMT_READ?.code]) &&
    // {
    //     name: "Posting",
    //     icon: Images.postingTab,
    //     activeIcon: Images.postingTabActive ,
    //     path: "/posting",
    // },
    RolePermission([PermissionList.STAFF_TIMESHEET_READ.code]) &&
    {
        name: "Payroll",
        icon: Images.payrollTab,
        activeIcon: Images.payrollTabActive ,
        path: "/payroll",
        type: 'accordion'
    },

    RolePermission([PermissionList.REPORT_MANAGE?.code]) &&
    {
        name: "Reporting",
        icon: Images.reportTab,
        activeIcon: Images.reportTabActive ,
        path: "/report",
        type: 'accordion'
    },
    RolePermission([
        PermissionList.SYSTEM_SERVICE_TYPES?.code,
        PermissionList.SYSTEM_PAYCODE_TYPES?.code,
        PermissionList.SYSTEM_CREDENTIALS?.code,
        PermissionList.SYSTEM_DEPARTMENTS?.code,
        PermissionList.SYSTEM_JOB_TITLES?.code,
        PermissionList.SYSTEM_PLACE_OF_SERVICE?.code,
        PermissionList.SYSTEM_MILEAGE?.code,
        PermissionList.SYSTEM_OVERTIME?.code,
    ]) &&
    {
        name: "System",
        icon: Images.systemTab,
        activeIcon: Images.systemTabActive ,
        path: "/system",
    },
];
