import {call, put, takeLatest} from "redux-saga/effects";
import {
    httpRequestsOnErrorsActions,
    httpRequestsOnSuccessActions,
    httpRequestsOnLoadActions,
} from "store";
import {claimPaymentService} from "./claimPayment.service";
import {
    GET_CLAIM_PAYMENTS,
    GET_CLAIM_PAYMENTS_SUCCESS,
    APPEAL_CLAIM_PAYMENT,
    PAY_IN_FULL_CLAIM,
    PAY_IN_FULL_CLAIM_BILLING,
    EDIT_CLAIM_PAYMENT_INFO,
} from "./claimPayment.type";

/** Claim Payment */

function* getClaimPayments(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    if (action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    try {
        const res = yield call(claimPaymentService.getClaimPaymentsService, action?.payload?.data);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put({
            type: GET_CLAIM_PAYMENTS_SUCCESS,
            payload: {claimPayments: res.data},
        });
    } catch (err) {
        yield put({
            type: GET_CLAIM_PAYMENTS_SUCCESS,
            payload: {claimPayments: {claimPmt: [], count: 0}},
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === "Internal server error") {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message)
            );
        }
    }
}

function* editClaimPaymentInfo(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(claimPaymentService.editClaimPmtInfoService, action?.payload?.body);
        const res = yield call(claimPaymentService.getClaimPaymentsService, action?.payload?.getParams);
        yield put({
            type: GET_CLAIM_PAYMENTS_SUCCESS,
            payload: {claimPayments: res.data},
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));

    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}


function* appealClaimPmt(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(claimPaymentService.appealClaimPmtService, action?.payload?.body);
        const res = yield call(claimPaymentService.getClaimPaymentsService, action?.payload?.getParams);
        yield put({
            type: GET_CLAIM_PAYMENTS_SUCCESS,
            payload: {claimPayments: res.data},
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(action.type))
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* payInFullClaim(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(claimPaymentService.payInFullClaimService, action?.payload?.body);
        const res = yield call(claimPaymentService.getClaimPaymentsService, action?.payload?.getParams);
        yield put({
            type: GET_CLAIM_PAYMENTS_SUCCESS,
            payload: {claimPayments: res.data},
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* payInFullClaimBilling(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(claimPaymentService.payInFullClaimBillingService, action?.payload?.body);
        const res = yield call(claimPaymentService.getClaimPaymentsService, action?.payload?.getParams);
        yield put({
            type: GET_CLAIM_PAYMENTS_SUCCESS,
            payload: {claimPayments: res.data},
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

/** End */

export const watchClaimPayment = function* watchClaimPaymentSaga() {
    /** Claim Payment */
    yield takeLatest(GET_CLAIM_PAYMENTS, getClaimPayments);
    yield takeLatest(EDIT_CLAIM_PAYMENT_INFO, editClaimPaymentInfo);
    yield takeLatest(APPEAL_CLAIM_PAYMENT, appealClaimPmt);
    yield takeLatest(PAY_IN_FULL_CLAIM, payInFullClaim);
    yield takeLatest(PAY_IN_FULL_CLAIM_BILLING, payInFullClaimBilling);
    /** End */
};
