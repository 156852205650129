import {CREATE_PERMISSION, DELETE_PERMISSION, GET_PERMISSIONS, GET_MY_PERMISSIONS} from "./permissions.types";

export const createPermission = (body) => {
  return {
    type: CREATE_PERMISSION,
    payload: { body }
  }
}

export const getPermissions = () => {
  return {
    type: GET_PERMISSIONS
  }
}

export const deletePermission = (id) => {
  return {
    type: DELETE_PERMISSION,
    payload: { id }
  }
}


export const getMyPermissions = ( ) =>{
  return {
    type: GET_MY_PERMISSIONS,
  }
}

