import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ValidationInput, CreateChancel, MaskInput, AddressInput } from 'components';
import { clientActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import {
    EmailValidator,
    ErrorText,
    FindErrorItem,
    FindLoad,
    FindSuccess,
    getPhoneErrorText,
    hooksForErrors,
    isNotEmpty, useModal,
} from 'utils';

export const AddContact = ({ info }) => {
    const client = useSelector((state) => state.client.clientItemInfo);
    const dispatch = useDispatch();
    const ACTION_TYPE = info ? 'EDIT_CLIENT_CONTACT' : 'CREATE_CLIENT_CONTACT';
    const success = FindSuccess(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const backError = FindErrorItem(ACTION_TYPE);
    const [error, setError] = useState('');
    const [inputs, setInputs] = useState(info ? { ...info } : {});
    const [enteredAddress, setEnteredAddress] = useState(info ? info.address : '');
    const phoneErrorMsg = getPhoneErrorText(inputs?.phoneNumber);
    const phoneErrorText = hooksForErrors.getPhoneError(error, backError, phoneErrorMsg);
    const emailErrorMsg = !EmailValidator.test(inputs.email) ? ErrorText.emailValid : '';
    const emailErrorText = hooksForErrors.getEmailError(error, backError, emailErrorMsg);
    const { close } = useModal();

    useEffect(
        () => () => {
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        },
        [],
    );

    useEffect(() => {
        if (!!success.length) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    const handleChange = (e) => {
        setInputs((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
        if (
            error === e.target.name ||
            error === phoneErrorMsg ||
            (!!backError)
        ) {
            setError('');
        }
        if (!!backError) {
            dispatch(httpRequestsOnErrorsActions.removeError(backError.type));
        }
    };
    //
    // const handleAddressChange = (selectedAddress) => {
    //     setEnteredAddress(selectedAddress);
    //     error === 'enteredAddress' && setError('');
    //     if (error === 'unableError') {
    //         setError('');
    //         dispatch(httpRequestsOnErrorsActions.removeError(backError.type));
    //     }
    // };

    const handleCreate = () => {
        const phoneIsValid = isNotEmpty(inputs.phoneNumber) && inputs.phoneNumber.trim().length >= 10 && !/[a-zA-Z]/g.test(inputs.phoneNumber);
        const emailIsValid = inputs.email ? isNotEmpty(inputs.email) && EmailValidator.test(inputs.email) : true;
        const contactDataIsValid = isNotEmpty(inputs.firstName) && isNotEmpty(inputs.lastName) && phoneIsValid && isNotEmpty(inputs.email) && emailIsValid && isNotEmpty(inputs.relationship);
        const addressIsValid = enteredAddress?.street && enteredAddress?.city && enteredAddress?.country;

        if (contactDataIsValid && addressIsValid) {
            const data = {
                firstName: inputs.firstName,
                lastName: inputs.lastName,
                phoneNumber: inputs?.phoneNumber && parseInt(inputs?.phoneNumber.replace(/[^0-9]/g, '')) + '',
                relationship: inputs.relationship,
                email: inputs?.email,
                address: enteredAddress,
            };
            if (!!info) {
                dispatch(clientActions.editClientContact(data, info?.id, client?.id));
            } else {
                dispatch(clientActions.createClientContact(data, client?.id));
            }

        } else {
            const contactDataErrorText = !isNotEmpty(inputs.firstName)
                ? 'firstName'
                : !isNotEmpty(inputs.lastName)
                    ? 'lastName'
                    : !isNotEmpty(inputs.phoneNumber) ? 'phoneNumber' : !phoneIsValid ? phoneErrorMsg :
                        !isNotEmpty(inputs.email) ? 'email' :
                            !emailIsValid ? emailErrorMsg :
                                !isNotEmpty(inputs.relationship) ? 'relationship' :
                                    !addressIsValid ? 'address' :
                                        '';
            setError(contactDataErrorText);
        }
    };

    return (
        <div className="client-contact-modal">
            <p className="modal-header-title">{info ? 'Edit Contact' : 'Add Contact'}</p>

            <div className="client-contact-modal-body">
                <div>
                    <ValidationInput
                        variant={'outlined'}
                        onChange={handleChange}
                        value={inputs.firstName}
                        type={'text'}
                        label={'First Name*'}
                        name="firstName"
                        typeError={error === 'firstName' ? ErrorText.field : ''}
                    />
                    <ValidationInput
                        variant={'outlined'}
                        onChange={handleChange}
                        value={inputs.lastName}
                        type={'text'}
                        label={'Last Name*'}
                        name="lastName"
                        typeError={error === 'lastName' ? ErrorText.field : ''}
                    />
                    <MaskInput
                        name="phoneNumber"
                        label="Phone Number*"
                        value={inputs.phoneNumber}
                        handleChange={handleChange}
                        error={phoneErrorText}
                    />
                    <ValidationInput
                        validator={EmailValidator}
                        variant={'outlined'}
                        name={'email'}
                        type={'email'}
                        label={'Email*'}
                        typeError={emailErrorText}
                        value={inputs?.email}
                        onChange={handleChange}
                    />
                    <ValidationInput
                        variant={'outlined'}
                        onChange={handleChange}
                        value={inputs.relationship}
                        type={'text'}
                        label={'Relationship*'}
                        name="relationship"
                        typeError={error === 'relationship' ? ErrorText.field : ''}
                    />
                </div>
                <div>
                    <AddressInput
                        placeholder={'Physical Address*'}
                        selectedAddress={info?.address}
                        setCurrentAddress={setEnteredAddress}
                        errMessage={error === 'address'}
                    />
                </div>
            </div>

            <div className="contact-create-cancel">
                <CreateChancel
                    loader={!!loader.length}
                    create={info ? 'Save' : 'Add'}
                    chancel={'Cancel'}
                    onCreate={handleCreate}
                    onClose={close}
                    buttonWidth="428px"
                />
            </div>
        </div>
    );
};
